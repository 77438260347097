import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DUTYS, DELETE_DUTY, ADD_DUTY, UPDATE_DUTY,GET_DUTYS_MY_DUTYS} from "./actionTypes";
import {
    getDutysSuccess, getDutysFail,
    deleteDutySuccess, deleteDutyFail,
    updateDutySuccess, updateDutyFail,
    addDutySuccess, addDutyFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDutys, //API CONNECTION
    delDuty,
    addDuty,
    updateDuty,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDutys() {
    try {
        const response = yield call(getDutys)
        yield put(getDutysSuccess(response));
    } catch (error) {
        yield put(getDutysFail(error));
    }
}

function* onDeleteDutys({payload:id}){
    try{
        const response = yield call(delDuty, id)
        yield put(deleteDutySuccess(response));
    } catch (error) {
        yield put(deleteDutyFail(error));
    }
}

function* onAddDuty({payload:duty}){
    try{
        const response = yield call(addDuty, duty)
        yield put(addDutySuccess(response));
    } catch (error) {
        yield put(addDutyFail(error));
    }
}

function* onUpdateDuty({payload:duty}){
    try{
        const response = yield call(updateDuty, duty)
        yield put(updateDutySuccess(response));
    } catch (error) {
        yield put(updateDutyFail(error));
    }
}

export function* dutysSaga() {
    yield takeEvery(GET_DUTYS, fetchDutys);
    yield takeEvery(DELETE_DUTY, onDeleteDutys);
    yield takeEvery(ADD_DUTY, onAddDuty);
    yield takeEvery(UPDATE_DUTY, onUpdateDuty);
   
    
}

export default dutysSaga;
