import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { withRouter, Link } from "react-router-dom"
import InputMask from "react-input-mask"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"

import {
  getPlansDetail as onGetPlansDetail,
  addOrientationForm as onAddOrientationForm,
  resetAppForm as onResetPlan,
  getCompanies as onGetCompanies,
  getBranch_byPlan as onGetBranch_byPlan,
  getCities as onCities,
  getDistricts as onDistricts,
  getNeighborhoods as onNeighborhoods,
  getOrientationDetail as onGetOrientationDetail,
  updateOrientationForm as onUpdateSurveyForm 
} from "store/actions"

import { isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "react-dropzone"

import classnames from "classnames"
import imgEmfpty from "./../../assets/images/empty.png"

import "./datatables.scss"

const Plans = props => {
  const ImgBase = "https://api.buqrdaisvar.com/" + "static/uploads/" //process.env.REACT_APP_BASE_API

  const dispatch = useDispatch()
  const [planObjects, setPlanObjects] = useState([])
  const [groupInputs, setGroupInputs] = useState([])
  const [form, setForm] = useState(null)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_kvkk, setconfirm_kvkk] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [selectedFiles, setselectedFiles] = useState([])
  const [modal, setModal] = useState(false)
  const [modalKvkk, setModalKvkk] = useState(false)
  const [kvkk, setKvkk] = useState(true)

  const [selectedCity, setSelectedCity] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("")
  const [cities, setCities] = useState([])
  const [districts, setDistricts] = useState([])
  const [neighborhoods, setNeighborhoods] = useState([])

  const {
    error,
    Plan,
    Form,
    success,
    Company,
    Branch,
    l_cities,
    l_districts,
    l_neighborhoods,
  } = useSelector(state => ({
    error: state.Plans?.error,
    success: state.OrientationForm?.success,
    Form: state.Orientation?.myOrientation[0],
    Plan: state.Orientation?.myOrientation[0]?.PlanObjects,
    Company: state.Companies?.data[0],
    Branch: state.Plans?.branch[0],
    l_cities: state.Locations?.cities,
    l_districts: state.Locations?.districts,
    l_neighborhoods: state.Locations?.neighborhoods,
  }))
  const {
    match: { params },
  } = props

  useEffect(() => {
    dispatch(onGetOrientationDetail(params.id))
    // dispatch(onGetBranch_byPlan(params.id))

    dispatch(onGetCompanies())
    dispatch(onCities())
  }, [dispatch])
  

  useEffect(() => {
    setCities(l_cities)
    setDistricts(l_districts)
    setNeighborhoods(l_neighborhoods)
  }, [l_cities, l_districts, l_neighborhoods])

  const toggle = () => {
    setModal(!modal)
  }
  const toggleKvkk = () => {
    setModalKvkk(!modalKvkk)
  }

  useEffect(() => {
    if (!isEmpty(Plan)) {
      let tempPlan = Plan
      tempPlan.forEach(element => {
        if (element.isType == "onayliMetin") {
        setKvkk(false)
        }
      })

      setPlanObjects(tempPlan)
    }
  }, [Plan])

  useEffect(() => {
    console.log("success", success, Form?.returnURL, new Date())
    if (success == true) {
      let url = `${process.env.REACT_APP_DOMAIN}/survey/finished/${params.id}${!isEmpty(Form?.returnURL) ? "?url=" + Form?.returnURL : ""
        }`
      console.log("success ok", success, url, new Date())
      window.location.href = `${process.env.REACT_APP_DOMAIN}/survey/finished/${params.id
        }${!isEmpty(Form?.returnURL) ? "?url=" + Form?.returnURL : ""}`
    }
  }, [success])

  let user = JSON.parse(localStorage.getItem("authUser"))

  const handleValidPlanSubmit = (e, values) => {


    const newForm = {
      name: Form?.name,
      CompanyId: Form.CompanyId,
      OrientationId: Form._id,
      AppFormId: params?.form,  
      PlanObjects: JSON.stringify(planObjects),
    }  
    
    setForm(newForm)
    setconfirm_alert(true)
    //dispatch(onAddOrientationForm(newForm))

  } 

  const handleChangeInput = (i, e, inputName, targetName, index,indexOpt) => {
    const values = [...planObjects]

    values[i][targetName] = index
    values[i][inputName] = e
    setPlanObjects(values)
  }
  const handleChangeSelectInput = (i,value) => {
    const values = [...planObjects]
    
    console.log(">>",values[i]?.isOptions[value])
    if(values[i].isPlan?.includes(values[i]?.isOptions[value])){
      let search_term = values[i]?.isOptions[value];

      for (let s=0;s<values[i].isPlan.length;s++) {
          if (values[i]?.isPlan[s] === search_term) {
            values[i]?.isPlan.splice(s, 1);
              // break;       //<-- Uncomment  if only the first term has to be removed
          }
      }
    }else{
      values[i].isPlan.push(values[i]?.isOptions[value])
    }
   
    console.log("||||||",values)
    //values[i][targetName] = index
    // values[i][inputName] = e 
    // setPlanObjects(values)
  }
  console.log(Plan)
  return (
    <React.Fragment>
      <div className="container" style={{ marginTop: "15px" }}>
        <Container>
          <Row>
            <Col md={6} className="order-md-3 offset-md-3">
              <div style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="card-body company-info">
                    <div>
                      <b>{Form?.name}</b>
                    </div>
                  </div>
                </div>
                <div>
                  <p>{Form?.description}</p>
                </div>
              </div>
            </Col>
          </Row>
          <AvForm onValidSubmit={handleValidPlanSubmit}>
            <Row form>
              <Col md={6} className="order-md-3 offset-md-3">
                <Row>
                  <div className="mb-3">
                    <div className="mb-3">{Form?.planDesc}</div>
                  </div> </Row>

                {map(Plan, (item, index) => (
                  <div className="mb-2" key={"h_" + index}>
                    {item.isType == "sadeceMetin" ? (
                      <p>{item?.isOptions}</p>
                    ) : null}
                    {item.isType == "onayliMetin" ? (
                      <div>
                        <p>{item?.isOptions}</p>
                        <AvGroup check >
                          <AvInput
                            type="checkbox"
                            label="eFaturaMukellef"
                            value="false"
                            name="eFaturaMukellef"
                            onChange={e => setKvkk(!kvkk)}
                          />
                          <Label check for="checkbox">
                            {" "}
                            <p >
                              <span >
                                Metni Okudum,Anladım
                              </span>
                            </p>
                          </Label>
                        </AvGroup>
                      </div>



                    ) : null}
                    {item.isType == "fourchoice" ? (
                      <div className="mt-3">
                        <h4 className="mt-5 mb-2">{item.title}</h4>
                         {map(item?.isOptions, (itemOpt, indexOpt) => (
                        <div className="mb-2" key={"h_" + index + "dd" + indexOpt}>
                          <Col className="col-10 mt-1">

                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={index + "exampleRadios"}
                                id={index + itemOpt + indexOpt}
                                value={index + itemOpt + indexOpt}

                                onClick={(e) => {
                                  let str = e.target.value;
                                  let editedText = str.slice(1, -1);
                                  const last = str.charAt(str.length - 1);


                                  handleChangeInput(index, editedText, "isValue", "targetValue", item?.isPlan[last])
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={index + itemOpt + indexOpt}
                              >
                                {itemOpt}
                              </label>
                            </div>

                          </Col>

                        </div>
                      ))}
                      </div>
                     
                    ) : null}
                      {item.isType == "fourSelect" ? (
                      <div className="mt-3">
                        <h4 className="mt-5 mb-2">{item.title}</h4>
                         {map(item?.isOptions, (itemOpt, indexOpt) => (
                        <div className="mb-2" key={"h_" + index + "dd" + indexOpt}>
                          <Col className="col-10 mt-1">

                          <div className="form-check mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"h_" + index + "dd" + indexOpt}
                            value="checked"
                            onClick={(e) => {
                              let str = e.target.value;
                              let editedText = str.slice(1, -1);
                              const last = str.charAt(str.length - 1);


                              handleChangeSelectInput(index,indexOpt)
                            }}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor={"h_" + index + "dd" + indexOpt}
                          >
                            {itemOpt}
                          </Label>
                        </div>

                          </Col>

                        </div>
                      ))}
                      </div>
                     
                    ) : null}


                  
                  </div>
                ))}


              </Col>
            </Row>
                          {console.log("kvkk",kvkk)}

            <Row>
              <Col lg={6} className="order-md-3 offset-md-3">
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user"
                  disabled={kvkk==false?true:false}
                  >
                    {props.t("Ok")}
                    
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>


          {success_dlg || (!isEmpty(success) && success == true) ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
                dispatch(onResetPlan())
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Do you want to submit your survey?")}
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("GoBack")}
              confirmBtnText={props.t("Send")}
              onConfirm={() => {
                setconfirm_alert(false)
                //setsuccess_dlg(true);
                setdynamic_title(props.t(""))
                setdynamic_description("")
                dispatch(onUpdateSurveyForm(form, selectedFiles))
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {/* {props.t("DeleteWarning")} */}
            </SweetAlert>
          ) : null}



          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
