import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_BRANCHS,GET_BRANCH_DETAIL, DELETE_BRANCH, ADD_BRANCH, UPDATE_BRANCH,GET_COMPANY_BRANCHES,ADD_COMPANY_BRANCH,UPDATE_COMPANY_BRANCH,DELETE_COMPANY_BRANCH, GET_BRANCH_CHILDS} from "./actionTypes";
import {
    getBranchsSuccess, getBranchsFail,
    deleteBranchSuccess, deleteBranchFail,
    updateBranchSuccess, updateBranchFail,
    addBranchSuccess, addBranchFail,
    getCompanyBranchesSuccess,getCompanyBranchesFail,
    addCompanyBranchSuccess,addCompanyBranchFail,
    deleteCompanyBranchSuccess,deleteCompanyBranchFail,
    updateCompanyBranchSuccess,updateCompanyBranchFail, 
    getBranchChildsSuccess, getBranchChildsFail,  
    getBranchByIdSuccess,getBranchByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getBranchs, //API CONNECTION
    getBranchChilds,
    delBranch,
    addBranch,
    updateBranch,
    getCompanyBranches,
    delCompanyBranch,
    getBranchById
}
    from "../../helpers/fakebackend_helper"; 

function* fetchBranchs() {
    try {
        const response = yield call(getBranchs)
        yield put(getBranchsSuccess(response));
    } catch (error) {
        yield put(getBranchsFail(error));
    }
}
function* fetchBranchById({payload:id}) {
  try {
      const response = yield call(getBranchById, id)
      yield put(getBranchByIdSuccess(response));
  } catch (error) {
      yield put(getBranchByIdFail(error));
  }
}
function* fetchBranchChilds({payload:id}) {
  try {
      const response = yield call(getBranchChilds, id)
      yield put(getBranchChildsSuccess(response));
  } catch (error) {
      yield put(getBranchChildsFail(error));
  }
}

function* onDeleteBranchs({payload:id}){
    try{
        const response = yield call(delBranch, id)
        yield put(deleteBranchSuccess(response));
    } catch (error) {
        yield put(deleteBranchFail(error));
    }
}

function* onAddBranch({payload:branch, file: file}){
    try{
        const response = yield call(addBranch, branch, file)
        yield put(addBranchSuccess(response));
    } catch (error) {
        yield put(addBranchFail(error));
    }
}

function* onUpdateBranch({payload:branch, file: file }){ 
    try{
        const response = yield call(updateBranch, branch, file)
        yield put(updateBranchSuccess(response));
    } catch (error) {
        yield put(updateBranchFail(error));
    }
}
function* onGetCompanyBranches({ payload: {id, branchId}}) {
    try { 
      const response = yield call(getCompanyBranches, id, branchId)
      yield put(getCompanyBranchesSuccess(response))
    } catch (error) {
      yield put(getCompanyBranchesFail(error))
    }
  }
function* onAddCompanyBranch({ payload: branch }) {
    try {
      const response = yield call(addBranch, branch)
      yield put(addCompanyBranchSuccess(response))
    } catch (error) {
      yield put(addCompanyBranchFail(error))
    }
  }
  function* onDeleteCompanyBranch({ payload: id }) {
    try {
      const response = yield call(delCompanyBranch, id)
      yield put(deleteCompanyBranchSuccess(response))
    } catch (error) {
      yield put(deleteCompanyBranchFail(error))
    }
  }
  function* onUpdateCompanyBranch({ payload: x }) {
    try {
      const response = yield call(updateBranch, x)
      yield put(updateCompanyBranchSuccess(response))
    } catch (error) {
      yield put(updateCompanyBranchFail(error))
    }
  }

export function* branchsSaga() {
    yield takeEvery(GET_BRANCHS, fetchBranchs);
    yield takeEvery(GET_BRANCH_CHILDS, fetchBranchChilds);
    yield takeEvery(DELETE_BRANCH, onDeleteBranchs);
    yield takeEvery(ADD_BRANCH, onAddBranch);
    yield takeEvery(UPDATE_BRANCH, onUpdateBranch);
    yield takeEvery(GET_COMPANY_BRANCHES, onGetCompanyBranches);
    yield takeEvery(ADD_COMPANY_BRANCH, onAddCompanyBranch);
    yield takeEvery(DELETE_COMPANY_BRANCH, onDeleteCompanyBranch);
    yield takeEvery(UPDATE_COMPANY_BRANCH, onUpdateCompanyBranch);
     yield takeEvery(GET_BRANCH_DETAIL, fetchBranchById);
}

export default branchsSaga;
