import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PARTNERSHIPS, DELETE_PARTNERSHIP, ADD_PARTNERSHIP, UPDATE_PARTNERSHIP,GET_PARTNERSHIPS_MY_PARTNERSHIPS,GET_PARTNERSHIPS_FOR_QR} from "./actionTypes"; 
import {
    getPartnershipsSuccess, getPartnershipsFail, 
    deletePartnershipSuccess, deletePartnershipFail,
    updatePartnershipSuccess, updatePartnershipFail,
    addPartnershipSuccess, addPartnershipFail,
    getPartnershipsForQrSuccess,getPartnershipsForQrFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPartnerships, //API CONNECTION
    delPartnership,
    addPartnership,
    updatePartnership,
    getPartnershipsForQr
    
}
    from "../../helpers/fakebackend_helper"; 

    function* fetchPartnershipsForQr({ payload: id }) {
        try {
            const response = yield call(getPartnershipsForQr,id)
            yield put(getPartnershipsForQrSuccess(response));
        } catch (error) {
            yield put(getPartnershipsForQrFail(error));
        }
    }
function* fetchPartnerships() {
    try {
        const response = yield call(getPartnerships)
        yield put(getPartnershipsSuccess(response));
    } catch (error) {
        yield put(getPartnershipsFail(error));
    }
}

function* onDeletePartnerships({payload:id}){
    try{
        const response = yield call(delPartnership, id)
        yield put(deletePartnershipSuccess(response));
    } catch (error) {
        yield put(deletePartnershipFail(error));
    }
}

function* onAddPartnership({payload:partnership, files: files}){
    try{
        const response = yield call(addPartnership, partnership, files)
        yield put(addPartnershipSuccess(response));
    } catch (error) {
        yield put(addPartnershipFail(error));
    }
}

function* onUpdatePartnership({payload:partnership, file: file}){
    try{
        const response = yield call(updatePartnership, partnership,file)
        yield put(updatePartnershipSuccess(response));
    } catch (error) {
        yield put(updatePartnershipFail(error));
    }
}

export function* partnershipsSaga() {
    yield takeEvery(GET_PARTNERSHIPS, fetchPartnerships);
    yield takeEvery(DELETE_PARTNERSHIP, onDeletePartnerships);
    yield takeEvery(ADD_PARTNERSHIP, onAddPartnership);
    yield takeEvery(UPDATE_PARTNERSHIP, onUpdatePartnership);
    yield takeEvery(GET_PARTNERSHIPS_FOR_QR, fetchPartnershipsForQr);
   
    
}

export default partnershipsSaga;
