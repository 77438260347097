import {
    GET_SURVEY_FORMS,
    GET_SURVEY_FORMS_SUCCESS,
    GET_SURVEY_FORMS_FAIL,
    GET_SURVEY_FORM_DETAIL,
    GET_SURVEY_FORM_DETAIL_SUCCESS,
    GET_SURVEY_FORM_DETAIL_FAIL,
    ADD_SURVEY_FORM,
    ADD_SURVEY_FORM_SUCCESS,
    ADD_SURVEY_FORM_FAIL,
    UPDATE_SURVEY_FORM,
    UPDATE_SURVEY_FORM_SUCCESS,
    UPDATE_SURVEY_FORM_FAIL,
    DELETE_SURVEY_FORM,
    DELETE_SURVEY_FORM_SUCCESS,
    DELETE_SURVEY_FORM_FAIL,
    RESET_SURVEY_FORM,
    
    
} from "./actionTypes";


export const resetSurveyForm = () => ({
    type: RESET_SURVEY_FORM,
});

export const getSurveyForms = (appFormId) => ({
    type: GET_SURVEY_FORMS,
    payload: appFormId,
});

export const getSurveyFormsSuccess = (surveyForm) => ({
    type: GET_SURVEY_FORMS_SUCCESS,
    payload: surveyForm,
});


export const getSurveyFormsFail = (error) => ({
    type: GET_SURVEY_FORMS_FAIL,
    payload: error,
});

export const deleteSurveyForm = (id) => ({
    type:  DELETE_SURVEY_FORM,
    payload: id
});

export const deleteSurveyFormSuccess = (data) => ({
    type: DELETE_SURVEY_FORM_SUCCESS,
    payload: data
});

export const deleteSurveyFormFail = (error) => ({
    type: DELETE_SURVEY_FORM_FAIL,
    payload: error,
});


export const addSurveyForm = (surveyForm) => ({
    type: ADD_SURVEY_FORM,
    payload: surveyForm
});

export const addSurveyFormSuccess = (data) => ({
    type: ADD_SURVEY_FORM_SUCCESS,
    payload: data
});

export const addSurveyFormFail = (error) => ({
    type: ADD_SURVEY_FORM_FAIL,
    payload: error,
});


export const updateSurveyForm = (surveyForm) => ({
    type: UPDATE_SURVEY_FORM,
    payload: surveyForm
});

export const updateSurveyFormSuccess = (data) => ({
    type: UPDATE_SURVEY_FORM_SUCCESS,
    payload: data
});

export const updateSurveyFormFail = (error) => ({
    type: UPDATE_SURVEY_FORM_FAIL,
    payload: error,
});