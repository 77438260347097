export const GET_QRCODES = "GET_QRCODES";
export const GET_QRCODES_SUCCESS = "GET_QRCODES_SUCCESS";
export const GET_QRCODES_FAIL = "GET_QRCODES_FAIL";
 
  
export const ADD_QRCODE = "ADD_QRCODE";
export const ADD_QRCODE_SUCCESS = "ADD_QRCODE_SUCCESS";
export const ADD_QRCODE_FAIL = "ADD_QRCODE_FAIL";

 
export const DELETE_QRCODE = "DELETE_QRCODE";
export const DELETE_QRCODE_SUCCESS = "DELETE_QRCODE_SUCCESS";
export const DELETE_QRCODE_FAIL = "DELETE_QRCODE_FAIL";


export const ADD_TAG_QRCODE = "ADD_TAG_QRCODE";
export const ADD_TAG_QRCODE_SUCCESS = "ADD_TAG_QRCODE_SUCCESS";
export const ADD_TAG_QRCODE_FAIL = "ADD_TAG_QRCODE_FAIL";

export const DELETE_TAG_QRCODE = "DELETE_TAG_QRCODE";
export const DELETE_TAG_QRCODE_SUCCESS = "DELETE_TAG_QRCODE_SUCCESS";
export const DELETE_TAG_QRCODE_FAIL = "DELETE_TAG_QRCODE_FAIL";

export const RESET_QRCODE = "RESET_QRCODE";