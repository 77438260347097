import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
  DropdownItem,Nav,
  NavItem,TabContent,
  NavLink,TabPane
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback,
     AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone, 
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    getCompanies as onGetCompanies,
    deleteCompany as onDeleteCompany,
    getUsers as onGetUsers,
    deleteUser as onDeleteUser
} from "store/actions" 
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment'
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from "classnames"
 


const Users = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const dispatch = useDispatch()
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [selectedId, setSelectedId] = useState();
  const [selectedIdUsers, setSelectedIdUsers] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [success_dlgUsers, setsuccess_dlgUsers] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirm_alertUsers, setconfirm_alertUsers] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [isEdit, setIsEdit] = useState(false);
  const { SearchBar } = Search;
  useEffect(() => { 
    dispatch(onGetCompanies());
    dispatch(onGetUsers());  
    
    }, [dispatch]);
   
    const { error, Companies,Users } = useSelector(state => ({
        error: state.Dashboard?.error,
        Companies : state.Companies?.data,
        Users: state.Users?.data
        
    }))
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab)
        }
      }
  const columns = [
    {
        dataField: 'name',
        text:  props.t("Name"),
        sort: true
        
    },  
    {
        dataField: 'email',
        text:  props.t("Email"),
        sort: true
    },
    {
        dataField: "createdAt", 
        text: props.t("Created Date"),
        sort: true,
        formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
      },
      {
        dataField: "getData", 
        text: props.t("Bilgi Alma Linki"),
        sort: true,
        formatter: (cell, row) => 
       
        <a
        className="me-3 btn btn-link"
        //href={`${process.env.REACT_APP_DOMAIN}/register2/${row._id}`}
        onClick={()=>{
          window.open(`${process.env.REACT_APP_DOMAIN}/register2/${row._id}`)
        }}
      >
        <i className="mdi mdi-trending-up font-size-18"></i>
      </a>,
      },
     
   
    {
        dataField: '_id',
        text: props.t("Action"),
        sort: false,
        formatter: (cell, row) => (
            <UncontrolledDropdown style={{ position: "unset" }}>
                <DropdownToggle href="#" className="card-drop" tag="i">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownToggle>
                <div className="drop-absolute">
                <DropdownMenu className="dropdown-menu-end">
                   
                    <DropdownItem href="#"
                        onClick={() => {
                            setSelectedId(cell);
                            setconfirm_alert(true);
                        }}>
                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                        {props.t("Delete")}
                    </DropdownItem>
                </DropdownMenu>
                </div>
            </UncontrolledDropdown>
        )
    }];
    const Userscolumns = [
        {
            dataField: 'name',
            text:  props.t("Name"),
            sort: true
            
        }, 
        {
            dataField: 'email',
            text:  props.t("Email"),
            sort: true
        },
        {
            dataField: 'companyId',
            text:  props.t("Company Name"),
            sort: true,
            formatter: (cell, row) => (
                <span>{cell?.name}</span>
              )
        },
        {
            dataField: "createdAt",
            text: props.t("Created Date"),
            sort: true,
            formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
          },
         
        
      
        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                    <DropdownMenu className="dropdown-menu-end">
                       
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedIdUsers(cell);
                                setconfirm_alertUsers(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];
   
    const defaultSorted = [{ 
        dataField: 'createdAt',
        order: 'desc'
      }];
      
      const pageOptions = {
        sizePerPage: 10,
        totalSize:  Companies?.size, // replace later with size(customers),
        custom: true,
      }
    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }
    const handleValidDate = date => {
        if (date) {
          const date1 = moment(new Date(date)).format("DD.MM.YYYY")
          return date1
        } else {
          return ""
        }
      }


  return (
    <React.Fragment>
    <div className="page-content">
        <MetaTags>
            <title>{props.t("Şirketler")}</title>
        </MetaTags>
        <Container fluid>
        <Card>
        <CardBody>
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1")
              }}
            >
              <span className="d-block d-sm-none">
                <i className="bx bx-buildings"></i>
              </span>
              <span className="d-none d-sm-block">{props.t("Şirketler")}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2")
              }}
            >
              <span className="d-block d-sm-none">
                <i className="bx bx-clipboard"></i>
              </span>
              <span className="d-none d-sm-block">{props.t("Kullanıcılar")}</span>
            </NavLink>
          </NavItem>
         
        
        </Nav>

        <TabContent
          activeTab={customActiveTab}
          className="p-3 text-muted"
        >
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
              <Breadcrumb
            title={props.t("Şirketler")}
            breadcrumbItem={props.t("Şirketler")}
          />

              <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Companies}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Companies}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
              <Card>
              <h4>Kullanıcılar</h4>
                <CardBody> 
                   
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={Userscolumns}
                        data={Users}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField='_id'
                                columns={Userscolumns}
                                data={Users}
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>

                                        <Row className="mb-2">
                                            <Col md="4">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitProps.searchProps}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div> 
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={"_id"}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                                }
                            </ToolkitProvider>
                        )
                        }</PaginationProvider>
                </CardBody>
            </Card>
              </Col>
            </Row>
          </TabPane>
         
        
        </TabContent> 
        </CardBody>
              </Card>
            {/* Render Breadcrumb */}
            {/* <Breadcrumb title={props.t("Şirketler")} breadcrumbItem={props.t("Şirketler")}  addBtnCallBack={() => {
                setIsEdit(false);
                setSelectedPayment(
                    {
                        companyId: "", 
                        name: "",
                        description: "" ,
                        amount: "",
                       // PaymentPlanObjects: "",
                       
                    }
                );
              
                     
                toggle();

            }} /> */}

            {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

           
          

            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}
            {success_dlgUsers ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlgUsers(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"  
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteCompany(selectedId));
                        setSelectedId(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}
             {confirm_alertUsers ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alertUsers(false);
                        setsuccess_dlgUsers(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteUser(selectedIdUsers));
                        setSelectedIdUsers(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}


            {/* {error && error ? (
                <SweetAlert
                    title={props.t("Error")}
                    warning
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        dispatch(onResetPayment())
                    }}
                >
                    {JSON.stringify(error)}
                </SweetAlert>
            ) : null} */}

        </Container>
    </div>
</React.Fragment>
  )
}


export default withRouter(withTranslation()(Users))
