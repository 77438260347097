import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
  FormGroup
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
  addProcessListPayment as OnAddProcessListPayment,

} from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const qrModal = (props, cb) => {

  function random(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
  //const [modal, setModal] = useState(false)
  const downloadQR = () => {
    const canvas = document.getElementById("myQrCode");
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      if (pngUrl) {
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = random(8) + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(props.t("Downloaded"))
      }
    }
  };

  const copyToClipboard = (txt) => {
    var textField = document.createElement('textarea')
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }

  return (
    <Modal isOpen={props.modal} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Qr Code")}
      </ModalHeader>
      <ModalBody>
        <div style={{ textAlign: "center" }}>
          <ToastContainer
            autoClose={3000}
          />
          <div><h5>{props.infoQr} <a className="me-1" style={{fontSize:24}} onClick={() => { copyToClipboard("https://buqrdaisvar.com/" + props.infoQr) }}>
            <i className="bx bx-copy text-success"></i></a> </h5></div>
          <div><QRCode
            id="myQrCode"
            level={"M"}
            size={256}
            fgColor={"#2A3042"}
            value={"https://buqrdaisvar.com/" + props.infoQr} /></div>
        </div>
        <a onClick={downloadQR} className="btn btn-success float-end">{props.t("Download")}</a>
      </ModalBody>
    </Modal>
  )
}


qrModal.propTypes = {

  modal: PropTypes.bool,

  t: PropTypes.any,
  toggle: PropTypes.func,
  infoQr: PropTypes.string,

}


export default withRouter(
  (withTranslation()(qrModal))
)
