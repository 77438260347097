/** Get FOREIGN_LANGUAGE */
export const GET_FOREIGN_LANGUAGES = "GET_FOREIGN_LANGUAGES";
export const GET_FOREIGN_LANGUAGES_SUCCESS = "GET_FOREIGN_LANGUAGES_SUCCESS";
export const GET_FOREIGN_LANGUAGES_FAIL = "GET_FOREIGN_LANGUAGES_FAIL";

export const GET_FOREIGN_LANGUAGE_DETAIL = "GET_FOREIGN_LANGUAGE_DETAIL";
export const GET_FOREIGN_LANGUAGE_DETAIL_SUCCESS = "GET_FOREIGN_LANGUAGE_DETAIL_SUCCESS";
export const GET_FOREIGN_LANGUAGE_DETAIL_FAIL = "GET_FOREIGN_LANGUAGE_DETAIL_FAIL";


export const ADD_FOREIGN_LANGUAGE = "ADD_FOREIGN_LANGUAGE";
export const ADD_FOREIGN_LANGUAGE_SUCCESS = "ADD_FOREIGN_LANGUAGE_SUCCESS";
export const ADD_FOREIGN_LANGUAGE_FAIL = "ADD_FOREIGN_LANGUAGE_FAIL";

export const UPDATE_FOREIGN_LANGUAGE = "UPDATE_FOREIGN_LANGUAGE";
export const UPDATE_FOREIGN_LANGUAGE_SUCCESS = "UPDATE_FOREIGN_LANGUAGE_SUCCESS";
export const UPDATE_FOREIGN_LANGUAGE_FAIL = "UPDATE_FOREIGN_LANGUAGE_FAIL";

export const DELETE_FOREIGN_LANGUAGE = "DELETE_FOREIGN_LANGUAGE";
export const DELETE_FOREIGN_LANGUAGE_SUCCESS = "DELETE_FOREIGN_LANGUAGE_SUCCESS";
export const DELETE_FOREIGN_LANGUAGE_FAIL = "DELETE_FOREIGN_LANGUAGE_FAIL"; 



export const RESET_FOREIGN_LANGUAGE= "RESET_FOREIGN_LANGUAGE";