import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SURVEYS, DELETE_SURVEY, ADD_SURVEY, UPDATE_SURVEY,GET_SURVEY_DETAIL} from "./actionTypes";
import {
    getSurveysSuccess, getSurveysFail,
    deleteSurveySuccess, deleteSurveyFail,
    updateSurveySuccess, updateSurveyFail,
    addSurveySuccess, addSurveyFail,
    getSurveyDetailSuccess,getSurveyDetailFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSurveys, //API CONNECTION
    delSurvey,
    addSurvey,
    updateSurvey,
    getSurveyById
    
}
    from "../../helpers/fakebackend_helper"; 

    function* fetchPlansDetail({ payload: id}) {
        try {
            const response = yield call(getSurveyById,id)
            yield put(getSurveyDetailSuccess(response));
        } catch (error) {
            yield put(getSurveyDetailFail(error));
        }
    }
function* fetchSurveys() {
    try {
        const response = yield call(getSurveys)
        yield put(getSurveysSuccess(response));
    } catch (error) {
        yield put(getSurveysFail(error));
    }
}

function* onDeleteSurveys({payload:id}){
    try{
        const response = yield call(delSurvey, id)
        yield put(deleteSurveySuccess(response));
    } catch (error) {
        yield put(deleteSurveyFail(error));
    }
}

function* onAddSurvey({payload:survey}){
    try{
        const response = yield call(addSurvey, survey)
        yield put(addSurveySuccess(response));
    } catch (error) {
        yield put(addSurveyFail(error));
    }
}

function* onUpdateSurvey({payload:survey}){
    try{
        const response = yield call(updateSurvey, survey)
        yield put(updateSurveySuccess(response));
    } catch (error) {
        yield put(updateSurveyFail(error));
    }
}

export function* surveysSaga() {
    yield takeEvery(GET_SURVEYS, fetchSurveys);
    yield takeEvery(DELETE_SURVEY, onDeleteSurveys);
    yield takeEvery(ADD_SURVEY, onAddSurvey);
    yield takeEvery(UPDATE_SURVEY, onUpdateSurvey);
    yield takeEvery(GET_SURVEY_DETAIL, fetchPlansDetail);
    
    
}

export default surveysSaga;
