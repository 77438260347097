import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
//Modals
import QrModal from "./Modals/qrModal"
import PreparedModulesModal from "./Modals/preparedModulesModal"
import ApplicationPlanModal from "./Modals/applicationPlanModal"
import { authorize, Role } from "./../../components/helpers/authorize"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  getPlans as onGetPlans,
  getPlans_MyPlans as onGetMyPlans,
  getCompanyBranches as onGetCompanyBranches,
  addPlan as onAddMyPlans,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  deletePlan as onDeletePlans_MyPlans,
  updatePlan as onUpdatePlans_MyPlans,
  getBranchs as onGetBranches,
  getPlansDown as onGetPlansDown,
  addQRCode as onAddQRCode,
  getDutys as onGetDuty,
  getForeignLanguages as onGetForeignLanguages,
  getPrograms as onGetPrograms,
  getBackUrls as onGetBackUrls,
  getSectorForms as onGetSectorForms,
  getDutyForms as onGetDutyForms,
  getBranchById as onGetBranchById,
  getDutiesForms as onGetDutiesForms,
  updatePlanActive as onUpdatePlanActive,
  addVisit as onAddVisit

} from "store/actions"

import { defaultsDeep, isEmpty, map } from "lodash"
import QrListModal from "./../Branches/Modals/qrListModal"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { select } from "redux-saga/effects"
import types from "react-cropper"
import {
  ASKERLIK_DURUMU_SEC,
  AYRILMA_NEDENI_SEC,
  CINSIYET_SEC,
  DIL_SEC,
  EGITIM_DURUMU_SEC,
  EHLIYET_BILGISI_SEC,
  GOREV_SEC,
  MEDENI_DURUM_SEC,
  PROGRAM_BILGISI_SEC,
  SEKTOR_SEC,
  SEVIYE_SEC,
  SURE_SEC_YIL,
  MAAS_BEKLENTISI
} from "./helper/options"
import QRRefresher from "pages/CompanyQr/v2/components/QRRefresher"

const Plans = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)
  const [modalPreparedModules, setModalPreparedModules] = useState(false)
  const [modalQr, setModalQr] = useState(false)
  const [AddQrModal, setAddQrModal] = useState(false)
  const [infoQr, setInfoQr] = useState()
  const [selectedId, setSelectedId] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [copyPlan, setCopyPlan] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [copy_alert, setCopy_alert] = useState(false)

  const [isGroupButton, setIsGroupButton] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState([])
  const [selectedWorkType, setSelectedWorkType] = useState()

  const [selectedPosition, setSelectedPosition] = useState()
  const [selectedDuty, setSelectedDuty] = useState()
  const [selectedDutiesForm, setSelectedDutiesForm] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [moduleInfo, setModuleInfo] = useState([])
  const [qrBranch, setQrBranch] = useState(false)
  const [qrListData, setQrListData] = useState()
  const [selectBranchName, setSelectBranchName] = useState()
  const [qrListOpen, setQRListOpen] = useState(false)
  const [clickPassive, setClickPassive] = useState(false)
  const [planObjects, setPlanObjects] = useState([
    // {
    //     title: "Cinsiyet",
    //     isType: "dropdown", //date, string, number, vs
    //     isValue: "",
    //     isPlan: null,
    //     isOptions: "Belirtilmedi,Kadın,Erkek",
    //     key: "cinsiyet",
    //     targetValue: ""
    // },
    // {
    //     title: "Eğitim Derecesi",
    //     isType: "dropdown", //date, string, number, vs
    //     isValue: "",
    //     isPlan: null,
    //     isOptions: "Belirtilmedi,İlk Okul,Orta Okul,Lise,Ön Lisans,Lisans,Yüksek Lisans",
    //     key: "egitim-derecesi",
    //     targetValue: ""
    // },
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },
  ])

  const { error, Plans, Branches, ForeignLanguages, Programs, SectorForms, DutyForms, BranchName} = useSelector(
    state => ({
      error: state.Plans?.error,
      ForeignLanguages: state.ForeignLanguage?.data,
      Plans: state.Plans?.data,
      Branches: state.Branchs?.Branches,
      Programs: state.Program?.data,
      SectorForms: state.SectorForm?.data,
      DutyForms: state.DutyForm?.data,
      BranchName: state.Branchs.branchDetail?.name,
     
    })
  )
  let user = JSON.parse(localStorage.getItem("authUser"))
  let tempForeignLanguages = "Dil Seç"
  let tempProgram = "Program Seç"
  let tempSectorForms = "Sektör Seç"
  let tempDutyForms = "Görev Seç"
  useEffect(() => {
    dispatch(getUserByMail(user.email))
    dispatch(onGetForeignLanguages())
    dispatch(onGetPrograms())
    dispatch(onGetBackUrls())
    dispatch(onGetSectorForms())
    dispatch(onGetDutyForms())
    dispatch(onGetDuty())
    dispatch(onGetDutiesForms());  
    if (authorize([Role.Admin])) {
      dispatch(onGetPlans())
      dispatch(onGetCompanyBranches(user.companyId))
    } else {
      dispatch(onGetPlansDown(user.branchId))
      dispatch(onGetCompanyBranches(user.companyId, user.branchId))
    }
    const visitDetailArr =[{name:"İlan Havuzu",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
   
  }, [dispatch])
  useEffect(() => {
    
    if (authorize([Role.Admin])) {
      dispatch(onGetPlans())
      dispatch(onGetCompanyBranches(user.companyId))
    } else {
      dispatch(onGetPlansDown(user.branchId))
      dispatch(onGetCompanyBranches(user.companyId, user.branchId))
    }
   
  }, [clickPassive])
  useEffect(() => {
    if (qrListData?.BranchId) {
      dispatch(onGetBranchById(qrListData?.BranchId))
    }
   
  }, [qrListData])

  if (!isEmpty(DutyForms)) {
    DutyForms.map((item, index) => {
      tempDutyForms = tempDutyForms + "," + item.name.name
    })
  } else {
    tempDutyForms = "Görev Seç,Satış Danışmanı,Karşılama Hostesi,Kasa Elemanı,Depo Elemanı,Ütücü,Servis Elemanı,Barista,Komi / Garson,Mutfak Elemanı,Usta,Müdür Yardımcısı,Diğer"
  }
  if (!isEmpty(ForeignLanguages)) {
    ForeignLanguages.map((item, index) => {
      tempForeignLanguages = tempForeignLanguages + "," + item.name.name
    })
  } else {
    tempForeignLanguages = "Dil Seç,İngilizce,Almanca,Çince"
  }
  if (!isEmpty(SectorForms)) {
    SectorForms.map((item, index) => {
      tempSectorForms = tempSectorForms + "," + item.name.name
    })
  } else {
    tempSectorForms = "Sektör Seç,Hazır Giyim,Ev Tekstili,Kişisel Bakım,Elektronik Ürünler,Telekomünikasyon,Süpermarket,Mobilya,Kitap / Kırtasiye,Otel,Restorant / Kafe,Diğer"
  }
  if (!isEmpty(Programs)) {
    Programs.map((item, index) => {
      tempProgram = tempProgram + "," + item.name.name
    })
  } else {
    tempProgram = "Program Seç,Excel,Word,Outlook,Perakende Yazılımı Perakende Yazılımı / Nebim,Perakende Yazılımı / Epos,Diğer"
  }
  const toggle = () => {
    setModal(!modal)
  }
  const togglePreparedModules = () => {
    if (modalPreparedModules) {
      // InputAddReset();
    }
    setModalPreparedModules(!modalPreparedModules)
  }
  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
  const toggleAddQr = () => {
    setAddQrModal(!AddQrModal)
  }
  const toggleQr = () => {
    setModalQr(!modalQr)
  }

  //Dynamic Inputs START
  const handleValidSubmit = (e, v) => {

    dispatch(
      onAddQRCode({
        BranchId: v.BranchId,
        CompanyId: user?.companyId,
        qrCode: v.qrCode,
        title: v.title,
        description: v.description,
        tag: v.tag,
      })
    )
    toggleAddQr()
  }
  const branchReset = () => {
    setSelectedBranchId("")
  }


  const InputAddReset = () => {
    let tempInput = [
      {
        title: "Cinsiyet",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: CINSIYET_SEC,
        key: "cinsiyet",
        targetValue: "",
      },
      {
        title: "Aile ve Medeni Durum",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MEDENI_DURUM_SEC,
        key: "medeniDurum",
        targetValue: "",
      },
      {
        title: "Eğitim Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: EGITIM_DURUMU_SEC,
        key: "egitimDurumu",
        targetValue: "",
      },
      {
        title: "Askerlik Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: tempForeignLanguages,
        key: "askerlikDurumu",
        targetValue: "",
      },
      {
        title: "Program Bilgisi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Program Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: tempProgram,
          },
          {
            title: "Bilgi Derecesi",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],

        key: "programBilgisi",
        targetValue: "",
      },
      {
        title: "Yabancı Dil",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Dil",
            isType: "dropdown",
            isValue: "",
            isOptions: DIL_SEC,
          },
          {
            title: "Konuşma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Yazma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Okuma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],
        key: "yabanciDil",
        targetValue: "",
      },
      {
        title: "Ehliyet",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Ehliyet Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: EHLIYET_BILGISI_SEC,
          },
          {
            title: "Süre Seç",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
        ],
        key: "ehliyet",
        targetValue: "",
      },
      {
        title: "İş Deneyimi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Firma Unvanı",
            isType: "text",
            isValue: "",
            isOptions: "",
          },
          {
            title: "Görev",
            isType: "dropdown",
            isValue: "",
            isOptions: tempDutyForms,
          },
          {
            title: "Sektör",
            isType: "dropdown",
            isValue: "",
            isOptions: tempSectorForms,
          },
          {
            title: "Süre",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
          {
            title: "Ayrılma Nedeni",
            isType: "dropdown",
            isValue: "",
            isOptions: AYRILMA_NEDENI_SEC,
          },
          {
            title: "Giriş Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
          {
            title: "Çıkış Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
        ],
        key: "isDeneyimi",
        targetValue: "",
      },
      {
        title: "Maaş Beklentisi",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MAAS_BEKLENTISI,
        key: "maasBeklentisi",
        targetValue: "",
      },
    ]
    // let tempInput =[
    //   {
    //         title: "",
    //         isType: "",
    //         isValue: "",
    //         isPlan: "",
    //         isOptions:"" ,
    //         key: "",
    //         targetValue: "",
    //       },
    // ]
    setPlanObjects(tempInput)
  }
  const toggleQrBranch = () => {
    setQrBranch(!qrBranch)
  }
  const toggleQrList = () => {
    setQRListOpen(!qrListOpen)
  }
  //Dynamic Inputs END
  const columns = [
    
    {
      dataField: "DutiesFormId",
      text: props.t("Görev Formu"),
      formatter: (cell, row) => 
      <span>{cell?.description}</span> 
     
      ,
    },
    {
      dataField: "DutyId",
      text: props.t("Position Name"),
      formatter: (cell, row) => <span>{cell?.description}</span>,
    },
    // {
    //   dataField: "description",
    //   text: props.t("Pozisyon Tanımı"),
    //   formatter: (cell, row) => (
    //     <span style={{ whiteSpace: "normal" }}>{cell}</span>
    //   ),
    // },
    {
      dataField: "workType",
      text: props.t("Way Of Work"),
    },

    {
      dataField: "BranchId",
      text: props.t("Unit to be Announced"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },

    {
      dataField: "CreatorUserId",
      text: props.t("Creator User Name"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },

    {
      dataField: "dummy",
      text: props.t("qrCode"),
      dummy: true,
      sort: false,
      formatter: (cell, row) => (
        <a
          className="btn btn-link"
          href="#"
          onClick={() => {
            toggleQrBranch()


          }}
        >
          <i className="mdi mdi-qrcode font-size-16 text-success me-1"></i>
          {props.t("qrCode")}
        </a>
      ),
    },
    {
      dataField: "planInfo",
      text: props.t("Plan Info"),
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            //href={"/appForm/" + row._id}
            onClick={() => {
              window.open("/ik/" + row._id)
              //window.open(config.baseApi+"appForm/"+ row._id);
            }}
          >
            {props.t("View Form")}
          </Button>
        </div>
      ),
    },
    {
      dataField: 'isActive',
      text: props.t("Aktif/Pasif"),
      sort: true,
      formatter: (cell, row) => ( 
      
          <div>
            
              <Col
              
                    className="form-check form-switch form-switch-lg mb-3"
                  >
                      {console.log("cell",cell)}
                    <label
                      className="form-check-label"
                      htmlFor="photo"
                      style={{ marginRight: '54px' }}
                    >
                     
                    </label>
                    <input  style={{backgroundColor:cell?"#34c38f":null,borderColor:cell?"#34c38f":null}}
                      type="checkbox"
                      name="photo"
                      id="photo"
                      className="form-check-input"
                      checked={cell}
                      onChange={(e) => {
                          const updateBrand = {
                              _id: row._id,
                              };
              
                          dispatch(onUpdatePlanActive(updateBrand));
                          setClickPassive(!clickPassive) 
                      }}
                    />

                  </Col>
          </div>
      ),

  },
    {
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  setCopyPlan(row)
                  //console.log(row)
                  let copyPlans = {
                    name: row.name + "_kopya",
                    description: "",
                    CompanyId: row.CompanyId._id,
                    BranchId: row.BranchId._id,
                    CreatorUserId: row.CreatorUserId._id,
                    PlanObjects: JSON.stringify(row.PlanObjects),
                    planDesc: row.planDesc,
                  }

                  setCopyPlan(copyPlans)
                  setCopy_alert(true)
                  //dispatch(onAddMyPlans(copyPlan))
                }}
              >
                <i className="bx bx-copy font-size-16 text-warning me-1"></i>
                {props.t("Copy")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)
                
                  setSelectedWorkType(row.workType)
                  setSelectedPosition(row.position)
                  setSelectedDuty(row?.DutyId?._id)
                  setSelectedDutiesForm(row.DutiesFormId?._id)
                  setSelectedBranchId(row.BranchId)

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                    setPlanDescription(row.planDesc)
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Plans ? Plans.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Plans ? Plans.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidPlanSubmit = (e, values) => {
    if (isEdit) {
      const updatePlan = {
        _id: selectedPlan._id,
        name: values.name,
        description: values["description"],
        CompanyId: user.companyId,
        CreatorUserId: user.uid,
        BranchId: BranchId.value,
        workType: values["workType"],
        returnURL: values["returnURL"],
        PlanObjects:
          document.getElementById("PlanObjects").value ||
          JSON.stringify(planObjects),
        planDesc: document.getElementById("planDesc")?.value || planDescription,
        //PlanObjects:JSON.stringify(planObjects)
      }
      // update Plan

      dispatch(onUpdatePlans_MyPlans(updatePlan))
    } else {

      const newPlan = {
        name: values["name"],
        description: values["description"],
        CompanyId: user.companyId,
        workType: values["workType"],
        returnURL: values["returnURL"],
        CreatorUserId: user.uid,
        PlanObjects: document.getElementById("PlanObjects").value,
        BranchId: BranchId.value,
        planDesc: document.getElementById("planDesc").value,
      }
      // save new Plan

      dispatch(onAddMyPlans(newPlan))


    }
    toggle()
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Application Post")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Application Post")}
            breadcrumbItem={props.t("Add Application Post")}
            addBtn={true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedPlan({
                name: "",
                description: "",

                PlanObjects: [],
              })
              branchReset()
              InputAddReset()

              toggle()
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Plans}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Plans}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <div className="float-end">
                          <QRRefresher/> 
                        </div>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={props.t(
                                  "You Don't Have a Plan Yet"
                                )}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          <Modal isOpen={AddQrModal} toggle={toggleAddQr}>
            <ModalHeader toggle={toggleAddQr} tag="h4">
              {props.t("Add Qr Code")}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidSubmit}>
                <Row form>
                  <Col xs={12}>

                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="BranchId"
                        value={props.selectedBranchId}
                        label={props.t("Select Req Unit")}

                      >
                        <option>{props.t("Select Req Unit")}</option>
                        {Branches?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="qrCode"
                        label={props.t("qrCode")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label={props.t("Title")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="description"
                        label={props.t("Description")}
                        type="textarea"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        {props.t("Save")}
                      </button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggleAddQr()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={qrBranch} toggle={toggleQrBranch}>
            <ModalHeader toggle={toggleQrBranch} tag="h4">
              {props.t("Add Qr Code")}
            </ModalHeader>
            <ModalBody>
              <AvForm>
                <Row form>
                  <Col xs={12}>

                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="BranchId"
                        value={qrListData?.BranchId || ""}
                        label={props.t("Select Req Unit")}
                        onChange={(e) => {
                          setQrListData({
                            BranchId: e.target.value,
                          })

                        }}
                      >
                        <option>{props.t("Select Req Unit")}</option>
                        {Branches?.map((item, index) => (
                          <option key={index} value={item._id} aa={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>




                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user"
                        disabled={!isEmpty(qrListData) ? false : true}
                        onClick={() => {
                          toggleQrBranch()
                          toggleQrList()
                        }}>
                        {props.t("Continue")}
                      </button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggleQrBranch()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <QrListModal 
            comeFrom ="plan"
            modal={qrListOpen}
            modalData={qrListData}
            branchName={BranchName}
            toggle={() => {
              toggleQrList()
            }}
          /> 
          
          <ApplicationPlanModal
            modal={modal} 
            isEdit={isEdit}
            selectedPlan={selectedPlan}
            selectedBranchId={selectedBranchId}
            selectedWorkType={selectedWorkType}
            selectedPosition={selectedPosition}
            selectedDutyId={selectedDuty}
            selectedDutiesFormId = {selectedDutiesForm}
            planObjects={planObjects}
            foreignLang={tempForeignLanguages} 
            dutyForm={tempDutyForms}
            sectorForm={tempSectorForms}  
            program={tempProgram}
            comeFrom={"plan"}
            toggle={() => {
              toggle()
            }}
            save={(e, _body) => {
              console.log("_bodyy",_body)
              if (e == true) {
                if (_body?.branchLength > 1) {
                  null
                } else {
                  console.log("buraya g")
                  setQrListData({
                    BranchId: _body.BranchId
                  }) 
                  toggleQrBranch()
                }
               }
             
            }}
          />

          <QrModal
            modal={modalQr}
            infoQr={infoQr}
            toggle={() => {
              toggleQr()
            }}
          />

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeletePlans_MyPlans(selectedId))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {copy_alert ? (
            <SweetAlert
              //title={props.t("Kopyalamak istediğinize emin misiniz?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Copy")}
              onConfirm={() => {
                setCopy_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Copied"))
                setdynamic_description("")

                dispatch(onAddMyPlans(copyPlan))

                setSelectedId(null)
              }}
              onCancel={() => setCopy_alert(false)}
            >
              {props.t("Copy Plan?")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
