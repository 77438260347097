import {
  GET_BRANCHS,
  GET_BRANCHS_SUCCESS,
  GET_BRANCHS_FAIL,
  GET_BRANCH_DETAIL,
  GET_BRANCH_DETAIL_SUCCESS,
  GET_BRANCH_DETAIL_FAIL,
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  RESET_BRANCH,
  GET_COMPANY_BRANCHES_SUCCESS,
  GET_COMPANY_BRANCHES_FAIL,
  ADD_COMPANY_BRANCH_SUCCESS,
  ADD_COMPANY_BRANCH_FAIL,
  UPDATE_COMPANY_BRANCH_SUCCESS,
  UPDATE_COMPANY_BRANCH_FAIL,
  DELETE_COMPANY_BRANCH_SUCCESS,
  DELETE_COMPANY_BRANCH_FAIL,
  GET_BRANCH_CHILDS_SUCCESS,
  GET_BRANCH_CHILDS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: null,
  Branches: [],
  main:{},
  branchDetail:{},
}

const Branchs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCHS_SUCCESS:
    case GET_BRANCH_CHILDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        main: action.payload.main
      }
      case GET_BRANCH_DETAIL_SUCCESS:
        return {
          ...state,
          branchDetail: action.payload.data,
        }
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          branch => branch._id.toString() !== action.payload.data.toString()
        ),
      }
    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case GET_COMPANY_BRANCHES_SUCCESS:
      return {
        ...state,
        Branches: action.payload.data,
      }
    case ADD_COMPANY_BRANCH_SUCCESS:
      return {
        ...state,

        Branches: [action.payload.data, ...state.Branches],
      }
    case UPDATE_COMPANY_BRANCH_SUCCESS:
      return {
        ...state,

        Branches: state.Branches.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_COMPANY_BRANCH_SUCCESS:
      return {
        ...state,

        Branches: state.Branches.filter(
          branch => branch._id.toString() !== action.payload.data.toString()
        ),
      }
    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }

    //FAILS
    case GET_BRANCHS_FAIL:
    case GET_BRANCH_CHILDS_FAIL:
    case DELETE_BRANCH_FAIL:
    case ADD_BRANCH_FAIL:
    case GET_COMPANY_BRANCHES_FAIL:
    case UPDATE_BRANCH_FAIL:
    case ADD_COMPANY_BRANCH_FAIL:
    case UPDATE_COMPANY_BRANCH_FAIL:
    case DELETE_COMPANY_BRANCH_FAIL:
    case GET_BRANCH_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        main:{}
      }

    case RESET_BRANCH:
      return {
        ...state,
        error: null,
        main:{}
      }
    default:
      return state
  }
}

export default Branchs
