import {
    GET_DUTY_FORMS,
    GET_DUTY_FORMS_SUCCESS,
    GET_DUTY_FORMS_FAIL,
    GET_DUTY_FORM_DETAIL,
    GET_DUTY_FORM_DETAIL_SUCCESS,
    GET_DUTY_FORM_DETAIL_FAIL,
    ADD_DUTY_FORM,
    ADD_DUTY_FORM_SUCCESS,
    ADD_DUTY_FORM_FAIL,
    UPDATE_DUTY_FORM,
    UPDATE_DUTY_FORM_SUCCESS,
    UPDATE_DUTY_FORM_FAIL,
    DELETE_DUTY_FORM,
    DELETE_DUTY_FORM_SUCCESS,
    DELETE_DUTY_FORM_FAIL,
    RESET_DUTY_FORM,
   
} from "./actionTypes";


export const resetDutyForm = () => ({
    type: RESET_DUTY_FORM,
});
 
export const getDutyForms = () => ({
    type: GET_DUTY_FORMS,
});

export const getDutyFormsSuccess = (dutyForm) => ({
    type: GET_DUTY_FORMS_SUCCESS,
    payload: dutyForm,
});


export const getDutyFormsFail = (error) => ({
    type: GET_DUTY_FORMS_FAIL,
    payload: error,
});

export const deleteDutyForm = (id) => ({
    type:  DELETE_DUTY_FORM,
    payload: id
});

export const deleteDutyFormSuccess = (data) => ({
    type: DELETE_DUTY_FORM_SUCCESS,
    payload: data
});

export const deleteDutyFormFail = (error) => ({
    type: DELETE_DUTY_FORM_FAIL,
    payload: error,
});


export const addDutyForm = (dutyForm) => ({
    type: ADD_DUTY_FORM,
    payload: dutyForm
});

export const addDutyFormSuccess = (data) => ({
    type: ADD_DUTY_FORM_SUCCESS,
    payload: data
});

export const addDutyFormFail = (error) => ({
    type: ADD_DUTY_FORM_FAIL,
    payload: error,
});


export const updateDutyForm = (dutyForm) => ({
    type: UPDATE_DUTY_FORM,
    payload: dutyForm
});

export const updateDutyFormSuccess = (data) => ({
    type: UPDATE_DUTY_FORM_SUCCESS,
    payload: data
});

export const updateDutyFormFail = (error) => ({
    type: UPDATE_DUTY_FORM_FAIL,
    payload: error,
});