import {
    GET_BRANCHS,
    GET_BRANCHS_SUCCESS,
    GET_BRANCHS_FAIL,
    GET_BRANCH_DETAIL,
    GET_BRANCH_DETAIL_SUCCESS,
    GET_BRANCH_DETAIL_FAIL,
    ADD_BRANCH,
    ADD_BRANCH_SUCCESS,
    ADD_BRANCH_FAIL,
    UPDATE_BRANCH,
    UPDATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_FAIL,
    DELETE_BRANCH,
    DELETE_BRANCH_SUCCESS,
    DELETE_BRANCH_FAIL,
    RESET_BRANCH,
    GET_COMPANY_BRANCHES,
    GET_COMPANY_BRANCHES_SUCCESS,
    GET_COMPANY_BRANCHES_FAIL,
    ADD_COMPANY_BRANCH,
    ADD_COMPANY_BRANCH_SUCCESS,
    ADD_COMPANY_BRANCH_FAIL,
    UPDATE_COMPANY_BRANCH,
    UPDATE_COMPANY_BRANCH_SUCCESS,
    UPDATE_COMPANY_BRANCH_FAIL,
    DELETE_COMPANY_BRANCH,
    DELETE_COMPANY_BRANCH_SUCCESS, 
    DELETE_COMPANY_BRANCH_FAIL,
    GET_BRANCH_CHILDS,
    GET_BRANCH_CHILDS_SUCCESS,
    GET_BRANCH_CHILDS_FAIL,

} from "./actionTypes";

export const resetBranch = () => ({
    type: RESET_BRANCH,
});
export const getBranchById = (id) => ({
    type: GET_BRANCH_DETAIL, 
    payload:id
});
export const getBranchByIdSuccess = (data) => ({ 
    type: GET_BRANCH_DETAIL_SUCCESS,
    payload: data
});
export const getBranchByIdFail = (error) => ({
    type: GET_BRANCH_DETAIL_FAIL,
    payload: error,
});
export const getBranchs = () => ({
    type: GET_BRANCHS,
});
 
export const getBranchsSuccess = (branchs) => ({
    type: GET_BRANCHS_SUCCESS,
    payload: branchs,
});


export const getBranchsFail = (error) => ({
    type: GET_BRANCHS_FAIL,
    payload: error,
}); 


export const getBranchChilds = (id) => ({
    type: GET_BRANCH_CHILDS,
    payload: id
});
export const getBranchChildsSuccess = (branchs) => ({
    type: GET_BRANCH_CHILDS_SUCCESS,
    payload: branchs,
});
export const getBranchChildsFail = (error) => ({
    type: GET_BRANCH_CHILDS_FAIL,
    payload: error,
});

export const deleteBranch = (id) => ({
    type: DELETE_BRANCH,
    payload: id
});

export const deleteBranchSuccess = (data) => ({
    type: DELETE_BRANCH_SUCCESS,
    payload: data
});

export const deleteBranchFail = (error) => ({
    type: DELETE_BRANCH_FAIL,
    payload: error,
});


export const addBranch = (branch, file) => ({
    type: ADD_BRANCH,
    payload: branch,
    file: file
});

export const addBranchSuccess = (data) => ({
    type: ADD_BRANCH_SUCCESS,
    payload: data
});

export const addBranchFail = (error) => ({
    type: ADD_BRANCH_FAIL,
    payload: error,
});


export const updateBranch = (branch, file) => ({
    type: UPDATE_BRANCH,
    payload: branch, 
    file: file
});

export const updateBranchSuccess = (data) => ({
    type: UPDATE_BRANCH_SUCCESS,
    payload: data
});

export const updateBranchFail = (error) => ({
    type: UPDATE_BRANCH_FAIL,
    payload: error,
});
//----BRANCHES
export const getCompanyBranches = (id, branchId = '') => ({
    type: GET_COMPANY_BRANCHES,
    payload: { id, branchId }
});
export const getCompanyBranchesSuccess = (data) => ({
    type: GET_COMPANY_BRANCHES_SUCCESS,
    payload: data
});
export const getCompanyBranchesFail = (error) => ({
    type: GET_COMPANY_BRANCHES_FAIL,
    payload: error,
});

export const addCompanyBranch = (id) => ({
    type: ADD_COMPANY_BRANCH,
    payload: id
});
export const addCompanyBranchSuccess = (data) => ({
    type: ADD_COMPANY_BRANCH_SUCCESS,
    payload: data
});
export const addCompanyBranchFail = (error) => ({
    type: ADD_COMPANY_BRANCH_FAIL,
    payload: error,
});

export const deleteCompanyBranch = (id) => ({
    type: DELETE_COMPANY_BRANCH,
    payload: id
});
export const deleteCompanyBranchSuccess = (data) => ({
    type: DELETE_COMPANY_BRANCH_SUCCESS,
    payload: data
});
export const deleteCompanyBranchFail = (error) => ({
    type: DELETE_COMPANY_BRANCH_FAIL,
    payload: error,
});


export const updateCompanyBranch = (x) => ({
    type: UPDATE_COMPANY_BRANCH,
    payload: x
});
export const updateCompanyBranchSuccess = (data) => ({
    type: UPDATE_COMPANY_BRANCH_SUCCESS,
    payload: data
});
export const updateCompanyBranchFail = (error) => ({
    type: UPDATE_COMPANY_BRANCH_FAIL,
    payload: error,
});