import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_LOGIN_LOGS,ADD_VISIT, DELETE_LOGIN_LOG, ADD_LOGIN_LOG, UPDATE_LOGIN_LOG,GET_LOGIN_LOGS_MY_LOGIN_LOGS} from "./actionTypes";
import {
    getLoginLogsSuccess, getLoginLogsFail,
    addVisitSuccess,addVisitFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getLoginLog, //API CONNECTION
    addVisit
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchLoginLogs() {
    try {
        const response = yield call(getLoginLog)
        yield put(getLoginLogsSuccess(response));
    } catch (error) {
        yield put(getLoginLogsFail(error));
    }
}  
function* fetchVisit({payload:plan}) {
    try {
        const response = yield call(addVisit,plan)
        yield put(addVisitSuccess(response));
    } catch (error) {
        yield put(addVisitFail(error));
    }
} 



export function* loginLogsSaga() {
    yield takeEvery(GET_LOGIN_LOGS, fetchLoginLogs);   
    yield takeEvery(ADD_VISIT, fetchVisit);  
}

export default loginLogsSaga;
