import {
    GET_DEV_FOREIGN_LANGUAGES,
    GET_DEV_FOREIGN_LANGUAGES_SUCCESS,
    GET_DEV_FOREIGN_LANGUAGES_FAIL,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL_SUCCESS,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL_FAIL,
    ADD_DEV_FOREIGN_LANGUAGE,
    ADD_DEV_FOREIGN_LANGUAGE_SUCCESS,
    ADD_DEV_FOREIGN_LANGUAGE_FAIL,
    UPDATE_DEV_FOREIGN_LANGUAGE,
    UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    UPDATE_DEV_FOREIGN_LANGUAGE_FAIL,
    DELETE_DEV_FOREIGN_LANGUAGE,
    DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    DELETE_DEV_FOREIGN_LANGUAGE_FAIL,
    RESET_DEV_FOREIGN_LANGUAGE,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevForeignLanguages = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_FOREIGN_LANGUAGES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devForeignLanguage => devForeignLanguage._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(devForeignLanguage =>
                    devForeignLanguage._id.toString() === action.payload.data._id.toString() ?
                        { devForeignLanguage, ...action.payload.data } :
                        devForeignLanguage
                )
            }

        //FAILS
        case GET_DEV_FOREIGN_LANGUAGES_FAIL:
        case DELETE_DEV_FOREIGN_LANGUAGE_FAIL:
        case ADD_DEV_FOREIGN_LANGUAGE_FAIL:
        case UPDATE_DEV_FOREIGN_LANGUAGE_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_FOREIGN_LANGUAGE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevForeignLanguages;