import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
  FormGroup,Alert
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
  addProcessListPayment as OnAddProcessListPayment,

} from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const button = (props, cb) => {
const [infoArea,setInfoArea] =useState(false)

 

 

  return (
    <Col md="3">
    
    <div  onMouseEnter={() => setInfoArea(true)}
     onMouseLeave={() =>setInfoArea(false)}>
        <label className="card-radio-label mb-2">
            <input
                type="radio"
                name="productType"
                className="card-radio-input"
                value={"Deneme"}
                defaultChecked={props.checked}
                onChange={rate => {
                    let _body = {
                        isValue: props.name,
                     
                        
                      };
                    props.isValue(true,_body )
                }}
            />

            <div className="card-radio">
                <span>
                  {props.name}
                </span>
            </div>
        </label>
    </div>
    {infoArea && props.name=="Yeni Başvurular"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
                                        {props.t("Formu Yeni Başlayanlar klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="Elenenler"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
                          {props.t("Formu Elenenler klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
       {infoArea && props.name=="IK Havuzu"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
                  {props.t("Formu IK Havuzu klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="Görüşülecekler"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
      {props.t("Formu Görüşülecekler klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="Görüşülenler"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
      {props.t("Formu Görüşülenler klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="İş Teklif Edilecekler"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
      {props.t("Formu İş Teklif Edilecekler klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="Teklifi Reddedenler"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
      {props.t("Formu Teklifi Reddedenler klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
      {infoArea && props.name=="İşe Alınanlar"? <Alert color="info" style={{position:"absolute",zIndex:"4564"}}>
      {props.t("Formu İşe Alınanlar klasörüne Taşımak için seçiniz")}
                                    </Alert>:null
      }
</Col>

  )
}


button.propTypes = {

  name: PropTypes.name,
  isValue: PropTypes.func,
  t: PropTypes.any,
  checked:PropTypes.bool


}


export default withRouter(
  (withTranslation()(button))
)
