import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import logodark from "../../assets/images/bqr1.png"
import "./datatables.scss"
const CarouselPage = () => {
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  
  const rndInt = randomIntFromInterval(1, 10)

   
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="py-5 px-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-1">
                        
                     
                         <img  id="preview-date"
                         src={logodark}
                         alt=""   
                         height="490"
                         style={{position:"relative", right:"-80px" ,bottom:"210px"}}
                       />
                      </h4>
                      <div >
                        {/* <Carousel showThumbs={false} className="slider_css" >
                          <div>
                            <div className="item">
                              <div className="py-3">
                               
                               {rndInt ==1?(
                                 <h4>Eğer kalbinizi dinler ve sevdiğiniz şeyi yaparsanız <br/>
                                   her seferinde daha başarılı olursunuz. <br/>
                                   Sundar Pichai ( Google CEO )</h4>
                               ):rndInt ==2?(
                                <h4>O kadar iyi olun ki sizi görmezden gelemesinler. <br/>
                                  (Marc Andressen)
                               </h4>
                              ):rndInt ==3?(
                                <h4>En büyük risk, hiç risk almamaktır. <br/>
                                  Böylesine değişen bir dünyada <br/>
                                  herşeyi mahvedecek tek şey <br/>
                                  stratejik iş risklerini almamaktır. <br/>
                                  Mark Zuckerberg ( Facebook )</h4>
                              ):rndInt ==4?(
                                <h4>Denetlemeye hazır hiçbir birliğin <br/>
                                  muhabereden başarı ile çıktığı,<br/>
                                   muhabereye hazır bir birliğin ise<br/>
                                   denetlemeden geçtiği görülmemiştir.</h4>
                              ):rndInt ==5?(
                                <h4>Kuyuyu susamadan önce kaz.</h4>
                              ):rndInt ==6?(
                                <h4>Ne kadar çok çalışırsam, o kadar şanslı oluyorum.<br/>
                                   (Gary Player)</h4>
                              ):rndInt ==7?(
                                <h4>Asker bile olsan her zaman rica etmek emir vermekten daha etkilidir.</h4>
                              ):rndInt ==8?(
                                <h4>Şampiyonlar, başarana kadar oynamaya devam edenlerdir.<br/>
                                  (Billie Jean King)</h4>
                              ):rndInt ==9?(
                                <h4>İnsanlar hoşlandıkları biriyle iş yapmak isterler. <br/>
                                  Eğer insanlar sizi severse, <br/>
                                  sizinle iş yapmak isteyeceklerdir.<br/>
                                  (Barbara Corcoran)</h4>
                              ):
                             
                                <h4>Önce ateş edip sonra nişan almaya kalkmayın. <br/>
                                  Sürekli nişan alıp bir türlü ateş edemeyen birisi de olmayın. <br/>
                                  (H. Jackson Brown) </h4>
                              }
                             

                               
                              </div>
                            </div>
                          </div>
                         
                        </Carousel> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
