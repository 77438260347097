import {
    GET_ORIENTATION_PERIODS,
    GET_ORIENTATION_PERIODS_SUCCESS,
    GET_ORIENTATION_PERIODS_FAIL,
    GET_ORIENTATION_PERIOD_DETAIL,
    GET_ORIENTATION_PERIOD_DETAIL_SUCCESS,
    GET_ORIENTATION_PERIOD_DETAIL_FAIL,
    ADD_ORIENTATION_PERIOD,
    ADD_ORIENTATION_PERIOD_SUCCESS,
    ADD_ORIENTATION_PERIOD_FAIL,
    UPDATE_ORIENTATION_PERIOD,
    UPDATE_ORIENTATION_PERIOD_SUCCESS,
    UPDATE_ORIENTATION_PERIOD_FAIL,
    DELETE_ORIENTATION_PERIOD,
    DELETE_ORIENTATION_PERIOD_SUCCESS,
    DELETE_ORIENTATION_PERIOD_FAIL,
    RESET_ORIENTATION_PERIOD,
   
} from "./actionTypes";


export const resetOrientationPeriod = () => ({
    type: RESET_ORIENTATION_PERIOD,
});

export const getOrientationPeriods = () => ({ 
    type: GET_ORIENTATION_PERIODS,
});

export const getOrientationPeriodsSuccess = (orientationPeriod) => ({
    type: GET_ORIENTATION_PERIODS_SUCCESS,
    payload: orientationPeriod,
});


export const getOrientationPeriodsFail = (error) => ({
    type: GET_ORIENTATION_PERIODS_FAIL,
    payload: error,
});

export const deleteOrientationPeriod = (id) => ({
    type:  DELETE_ORIENTATION_PERIOD,
    payload: id
});

export const deleteOrientationPeriodSuccess = (data) => ({
    type: DELETE_ORIENTATION_PERIOD_SUCCESS,
    payload: data
});

export const deleteOrientationPeriodFail = (error) => ({
    type: DELETE_ORIENTATION_PERIOD_FAIL,
    payload: error,
});


export const addOrientationPeriod = (orientationPeriod) => ({
    type: ADD_ORIENTATION_PERIOD,
    payload: orientationPeriod
});

export const addOrientationPeriodSuccess = (data) => ({
    type: ADD_ORIENTATION_PERIOD_SUCCESS,
    payload: data
});

export const addOrientationPeriodFail = (error) => ({
    type: ADD_ORIENTATION_PERIOD_FAIL,
    payload: error,
});


export const updateOrientationPeriod = (orientationPeriod) => ({
    type: UPDATE_ORIENTATION_PERIOD,
    payload: orientationPeriod
});

export const updateOrientationPeriodSuccess = (data) => ({
    type: UPDATE_ORIENTATION_PERIOD_SUCCESS,
    payload: data
});

export const updateOrientationPeriodFail = (error) => ({
    type: UPDATE_ORIENTATION_PERIOD_FAIL,
    payload: error,
});