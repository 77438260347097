import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Input,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal, 
    ModalHeader,
    ModalBody,
    Label,
    TabContent,
    Nav,
    Form,
    FormGroup,
    TabPane,
    CardTitle,
    CardSubtitle,
    NavItem,
    NavLink,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import CashCard from "./Cards/CashCard"
import { withRouter } from "react-router-dom"
const moment = require("moment");
//i18n
import { withTranslation } from "react-i18next"
import config from "config"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {

    getAppForms as onGetAppForms,
    addAppForm as onAddAppForm,
    deleteAppForm as onDeleteAppForm,
    updateAppForm as onUpdateForm,
    getMyForms as onGetMyForms,
    getMyFormsStatusFalse as onGetMyFormsStatusFalse,
    getMyFormsStatusTrue as onGetMyFormStatusTrue,
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import Table from "./Tables/Tables"
import "./datatables.scss"

import defaultProfilePic from "../../assets/images/users/default-user-pic.png"

const Plans = props => {
    const dispatch = useDispatch()
    const [editInfo, setEditInfo] = useState([]);
    const [selectedPlanObject, setSelectedPlanObject] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const [selectedForm, setSelectedForm] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [checkFormModal, setCheckFormModal] = useState(false)
    const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
    const [isEdit, setIsEdit] = useState(false);
    const [selectedBranchId, setSelectedBranchId] = useState()



    const { error, AppForm, MyForms, MyFormsStatusFalse,MyFormStatusTrue } = useSelector(state => ({
        error: state.Plans?.error,
        MyForms: state.AppForm?.myForms,
        AppForm: state.AppForm?.data,
        MyFormsStatusFalse: state.AppForm?.myFormsStatusFalse,
        MyFormStatusTrue:state.AppForm?.myFormsStatusTrue,

    }))
    let user = JSON.parse(localStorage.getItem("authUser"))

    useEffect(() => {

        dispatch(onGetAppForms())
        dispatch(onGetMyFormsStatusFalse(user.companyId))
        dispatch(onGetMyFormStatusTrue(user.companyId))
       // dispatch(onGetMyForms(user.companyId))


    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const toggleForm = () => {
        setCheckFormModal(!checkFormModal)
    }
    const toggleIconCustom = tab => {
        if (customIconActiveTab !== tab) {
          setcustomIconActiveTab(tab)
        }
      }
    const columns = [
        {
            dataField: 'firstName',
            text: props.t("First Name"),
            sort: true,
            formatter: (cell, row) => (
                row.status ? <span>{cell}</span> : <b>{cell}</b>
            )

        }, {
            dataField: 'lastName',
            text: props.t("Last Name"),
            sort: true,
            formatter: (cell, row) => (
                row.status ? <span>{cell}</span> : <b>{cell}</b>
            )
        },
        {
            dataField: 'email',
            text: props.t("email"),
            sort: true,
            formatter: (cell, row) => (
                row.status ? <span>{cell}</span> : <b>{cell}</b>
            )

        },
        {
            dataField: 'BranchId',
            text: props.t("Branch Name"),
            sort: true,

             formatter: (cell, row) => (
               
                <span>{cell?.name}</span>
            )

        },

        {
            dataField: 'ik_notes',
            text: props.t("HR Notes"),
            sort: true,
           

        },



        {
            dataField: 'planInfo',
            text: props.t("Applications Forms"),
            formatter: (cell, row) => (

                <div >
                    <Button
                        color="primary"
                        className="btn-rounded "
                        onClick={() => {
                            setSelectedForm(row);
                            setSelectedPlanObject(row.PlanObjects)
                            setEditInfo(row)
                            toggleForm()
                        }}

                    >
                        {props.t("Check The Form")}
                    </Button>

                </div>

            )
        },


        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                <DropdownToggle href="#" className="card-drop" tag="i">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownToggle>
                <div class="drop-absolute"> 
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedForm(row);
                                setSelectedBranchId(row.BranchId?._id)
                                setEditInfo(row)
                               
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: AppForm ? AppForm.length : 0, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: AppForm ? AppForm.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidPlanSubmit = (e, values) => {

        const updatePlan = {
            _id: selectedForm._id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            ik_notes: values.ik_notes,
            CompanyId: editInfo.CompanyId, 
            BranchId: editInfo.BranchId._id,
            CreatorUserId: user.uid,
            PlanObjects: JSON.stringify(editInfo.PlanObjects),
            status: editInfo.status,
            AppPlanId: editInfo.AppPlanId,
        };


        dispatch(onUpdateForm(updatePlan));



        toggle()
    }
    const handleValidFormSubmit = (e, values) => {

        const updatePlan = {
            _id: selectedForm._id,
            firstName: editInfo.firstName,
            lastName: editInfo.lastName,
            email: editInfo.email,
            ik_notes: editInfo.ik_notes,
            CompanyId: editInfo.CompanyId,
            BranchId: editInfo.BranchId._id,
            CreatorUserId: user.uid,
            PlanObjects: JSON.stringify(editInfo.PlanObjects),
            status: true,
            AppPlanId: editInfo.AppPlanId,
        };


        dispatch(onUpdateForm(updatePlan));



        toggleForm()
    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Application Forms")}</title>
                </MetaTags>
                <Container fluid>
                <div className="mt-2">
            <Row>

              <Col sm="2">
                <CashCard title={props.t("Total Job Application")} price={AppForm.length} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Unexamined Forms")} price={MyFormsStatusFalse.length} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Examined Forms")} price={MyFormStatusTrue.length} />
              </Col>

             


            </Row>
          </div>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Application Forms")} breadcrumbItem={props.t("Application Forms")} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedForm(
                            {
                                name: "",
                                description: "",

                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                  

                       <Card>
                <CardBody>
             

                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="bx bx-intersect"></i>{props.t("Unexamined Job Application")}
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="bx bx-intersect"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="bx bx-comment-minus"></i> {props.t("Examined Forms")}
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="bx bx-comment-minus"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                   
                  </Nav>

                  <TabContent
                    activeTab={customIconActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                      <Col sm="12" className="mt-2">
                      <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={MyFormsStatusFalse}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={MyFormsStatusFalse}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                noDataIndication={props.t("You Don't Have a Form Yet")}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider> 
                         </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                      <Col sm="12" className="mt-2">
                      <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={MyFormStatusTrue}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={MyFormStatusTrue}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                noDataIndication={props.t("You Don't Have a Form Yet")}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider> 
                           </Col>
                      </Row>
                    </TabPane>
                    
                    
                  </TabContent>
                </CardBody>
                      </Card>             
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Edit Job Application Plan") : props.t("Add Job Application Plan")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidPlanSubmit}>
                                <Row form>
                                    <Col xs={12}>
                                        <input type={"hidden"} name="photo" value={selectedForm?.photo} />
                                        <div className="mb-3">
                                            {selectedForm?.photo ? <img src={selectedForm?.photo} style={{ width: "75px", height: "75px", objectFit: "contain" }} />
                                                : <img src={defaultProfilePic} style={{ width: "75px", height: "75px", objectFit: "contain" }} />}
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="firstName"
                                                label={props.t("First Name")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedForm?.firstName || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="lastName"
                                                label={props.t("Last Name")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedForm?.lastName || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="email"
                                                label={props.t("email")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedForm?.email || ""}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="ik_notes"
                                                label={props.t("HR Notes")}
                                                type="textarea"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedForm?.ik_notes || ""}
                                            />
                                        </div>



                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggle() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={checkFormModal} toggle={toggleForm}>
                        <ModalHeader toggle={toggleForm} tag="h4">
                            {props.t("Job Application Form")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidFormSubmit}>
                                <Row form>
                                    <Col xs={12}>
                                    <div style={{ fontSize: "14px",fontWeight: "500"}}>{props.t("Photo")}</div>
                                        <div className="mb-3"> 
                                            {selectedForm?.photo ? <img src={config.baseImg +selectedForm?.photo} style={{ width: "75px", height: "75px", objectFit: "contain" }} />
                                                : <img src={defaultProfilePic} style={{ width: "75px", height: "75px", objectFit: "contain" }} />}
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="firstName"
                                                label={props.t("First Name")}
                                                type="text"
                                                disabled

                                                value={selectedForm?.firstName || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="lastName"
                                                label={props.t("Last Name")}
                                                type="text"
                                                disabled

                                                value={selectedForm?.lastName || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="email"
                                                label={props.t("email")}
                                                type="text"
                                                disabled

                                                value={selectedForm?.email || ""}
                                            />
                                        </div> 
                                        {map(selectedPlanObject, (item, index) => (
                                            <div className="mb-3" key={"h_" + index}>
                                                {item.isType === "string" ? (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="text"
                                                        disabled
                                                        label={item.title}

                                                        value={item.isValue}

                                                    />
                                                ) : item.isType === "longText" ? (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="textarea"
                                                        disabled
                                                        label={item.title}

                                                        value={item.isValue}

                                                    />
                                                ) : item.isType === "title" ? (
                                                    <div>
                                                        <br />
                                                        <h4>{item.title}</h4>
                                                        <hr />
                                                    </div>


                                                ) 
                                                : item.isType === "group" || item.isType === "singleGroup"? (
                                                    <Row>
                                                    <Col md={12}>
                                                        
                                                        <b> {item.title}</b>
                                                         <hr />
                                                         
                                                        
                                                       
                                                       </Col>
                                                       { item.isPlan?.map((groupItem, groupIndex) => (
                                                          item.isValue[groupIndex]?.map((groupItem2, groupIndex2) => (
                                                            <Col  md={4} className ="mb-3">
                                                            {groupItem2.isType === "boolean" ?(
                                                                <AvField
                                                                key={"h1_" + index}
                                                                id={"planObjects" + index + "__isValue"}
                                                                name={"planObjects[" + index + "].isValue"}
                                                                type="checkbox"
                                                                disabled
                                                                label={groupItem2.title}
        
                                                                value={groupItem2.isValue == "" ? false : groupItem2.isValue}
        
                                                            />
                                                            ): <AvField
                                                            key={"h1_" + groupIndex2}
                                                            id={"planObjects" + groupIndex2 + "__isValue"}
                                                            name={"planObjects[" + groupIndex2 + "].isValue"}
                                                            type="text"
                                                            disabled
                                                            label={item.isValue[groupIndex][groupIndex2].title}
                                                            value={item.isValue[groupIndex][groupIndex2].isValue}
  
                                                          />}
                                                            
                                                            
                                                     
                                                           </Col>
                                                            ))
                                                            
                                                         ))}
                                                        
                                                        <hr />
                                                       </Row>

                                                ): item.isType === "date" ? (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="date"
                                                        disabled
                                                        label={item.title}

                                                        value={handleValidDateAvField(item.isValue)}

                                                    />
                                                ) : item.isType === "number" ? (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="number"
                                                        disabled
                                                        label={item.title}

                                                        value={item.isValue}

                                                    />
                                                ) : item.isType === "boolean" ? (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="checkbox"
                                                        disabled
                                                        label={item.title}

                                                        value={item.isValue == "" ? false : item.isValue}

                                                    />
                                                ) : item.isType === "dropdown" ? (
                                                    <AvField
                                                    key={"h1_" + index}
                                                    id={"planObjects" + index + "__isValue"}
                                                    name={"planObjects[" + index + "].isValue"}
                                                    type="text"
                                                    disabled
                                                    label={item.title}

                                                    value={item.isValue}

                                                />
                                                ) : (
                                                    <AvField
                                                        key={"h1_" + index}
                                                        id={"planObjects" + index + "__isValue"}
                                                        name={"planObjects[" + index + "].isValue"}
                                                        type="text"
                                                        label={item.title}
                                                        disabled
                                                        value={item.isValue}

                                                    />
                                                )}
                                            </div>
                                        ))}



                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Reviewed")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggleForm() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>



                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteAppForm(selectedId))
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetPlan())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Plans))
