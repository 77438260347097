import {
    GET_SURVEY_SENTS,
    GET_SURVEY_SENTS_SUCCESS,
    GET_SURVEY_SENTS_FAIL,
    GET_SURVEY_SENT_DETAIL,
    GET_SURVEY_SENT_DETAIL_SUCCESS,
    GET_SURVEY_SENT_DETAIL_FAIL,
    ADD_SURVEY_SENT,
    ADD_SURVEY_SENT_SUCCESS,
    ADD_SURVEY_SENT_FAIL,
    UPDATE_SURVEY_SENT,
    UPDATE_SURVEY_SENT_SUCCESS,
    UPDATE_SURVEY_SENT_FAIL,
    DELETE_SURVEY_SENT,
    DELETE_SURVEY_SENT_SUCCESS,
    DELETE_SURVEY_SENT_FAIL,
    RESET_SURVEY_SENT,
    SEND_SURVEY_MAIL,
    SEND_SURVEY_MAIL_SUCCESS,
    SEND_SURVEY_MAIL_FAIL,
    
} from "./actionTypes";

export const sendSurveyMail = (email) => {
    return {
      type: SEND_SURVEY_MAIL,
      payload: email, 
    }
  }
  
  export const sendSurveyMailSuccess = (data) => {
    return {
      type: SEND_SURVEY_MAIL_SUCCESS,
      payload: data,
    }
  } 
  
  export const sendSurveyMailFail = (message) => {
    return {
      type: SEND_SURVEY_MAIL_FAIL,
      payload: message,
    }}
export const resetSurveySent = () => ({
    type: RESET_SURVEY_SENT,
});

export const getSurveySents = (appSentId) => ({
    type: GET_SURVEY_SENTS,
    payload: appSentId,
});

export const getSurveySentsSuccess = (surveySent) => ({
    type: GET_SURVEY_SENTS_SUCCESS,
    payload: surveySent,
});
 

export const getSurveySentsFail = (error) => ({
    type: GET_SURVEY_SENTS_FAIL,
    payload: error,
});

export const deleteSurveySent = (id) => ({
    type:  DELETE_SURVEY_SENT,
    payload: id
});

export const deleteSurveySentSuccess = (data) => ({
    type: DELETE_SURVEY_SENT_SUCCESS,
    payload: data
});

export const deleteSurveySentFail = (error) => ({
    type: DELETE_SURVEY_SENT_FAIL,
    payload: error,
});


export const addSurveySent = (surveySent) => ({
    type: ADD_SURVEY_SENT,
    payload: surveySent
});

export const addSurveySentSuccess = (data) => ({
    type: ADD_SURVEY_SENT_SUCCESS,
    payload: data
});

export const addSurveySentFail = (error) => ({
    type: ADD_SURVEY_SENT_FAIL,
    payload: error,
});


export const updateSurveySent = (surveySent) => ({
    type: UPDATE_SURVEY_SENT,
    payload: surveySent
});

export const updateSurveySentSuccess = (data) => ({
    type: UPDATE_SURVEY_SENT_SUCCESS,
    payload: data
});

export const updateSurveySentFail = (error) => ({
    type: UPDATE_SURVEY_SENT_FAIL,
    payload: error,
});