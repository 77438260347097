/** Get PARTNERSHIP */
export const GET_PARTNERSHIPS = "GET_PARTNERSHIPS";
export const GET_PARTNERSHIPS_SUCCESS = "GET_PARTNERSHIPS_SUCCESS";
export const GET_PARTNERSHIPS_FAIL = "GET_PARTNERSHIPS_FAIL";

export const GET_PARTNERSHIPS_FOR_QR = "GET_PARTNERSHIPS_FOR_QR";
export const GET_PARTNERSHIPS_FOR_QR_SUCCESS = "GET_PARTNERSHIPS_FOR_QR_SUCCESS";
export const GET_PARTNERSHIPS_FOR_QR_FAIL = "GET_PARTNERSHIPS_FOR_QR_FAIL";

export const GET_PARTNERSHIP_DETAIL = "GET_PARTNERSHIP_DETAIL";
export const GET_PARTNERSHIP_DETAIL_SUCCESS = "GET_PARTNERSHIP_DETAIL_SUCCESS";
export const GET_PARTNERSHIP_DETAIL_FAIL = "GET_PARTNERSHIP_DETAIL_FAIL";


export const ADD_PARTNERSHIP = "ADD_PARTNERSHIP";
export const ADD_PARTNERSHIP_SUCCESS = "ADD_PARTNERSHIP_SUCCESS";
export const ADD_PARTNERSHIP_FAIL = "ADD_PARTNERSHIP_FAIL";

export const UPDATE_PARTNERSHIP = "UPDATE_PARTNERSHIP";
export const UPDATE_PARTNERSHIP_SUCCESS = "UPDATE_PARTNERSHIP_SUCCESS";
export const UPDATE_PARTNERSHIP_FAIL = "UPDATE_PARTNERSHIP_FAIL";

export const DELETE_PARTNERSHIP = "DELETE_PARTNERSHIP";
export const DELETE_PARTNERSHIP_SUCCESS = "DELETE_PARTNERSHIP_SUCCESS";
export const DELETE_PARTNERSHIP_FAIL = "DELETE_PARTNERSHIP_FAIL"; 



export const RESET_PARTNERSHIP= "RESET_PARTNERSHIP";