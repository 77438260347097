import {
  
    GET_LOGIN_LOGS_SUCCESS,
    GET_LOGIN_LOGS_FAIL,
    RESET_LOGIN_LOG,
    ADD_VISIT_SUCCESS,
    ADD_VISIT_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    addVisit:[]
   
};

const LoginLogs = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_LOGIN_LOGS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case ADD_VISIT_SUCCESS:
            return {
                ...state, 
                addVisit: state.data.map(plan =>
                    plan._id.toString() === action.payload.data._id.toString() ?
                        { plan, ...action.payload.data } :
                        plan
                )
            }
      

        //FAILS
        case GET_LOGIN_LOGS_FAIL:
        case ADD_VISIT_FAIL:
       
      
            return {
                ...state,
                error: action.payload
            }

        case RESET_LOGIN_LOG: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default LoginLogs;