import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DUTY_FORMS, DELETE_DUTY_FORM, ADD_DUTY_FORM, UPDATE_DUTY_FORM,GET_DUTY_FORMS_MY_DUTY_FORMS} from "./actionTypes";
import {
    getDutyFormsSuccess, getDutyFormsFail,
    deleteDutyFormSuccess, deleteDutyFormFail,
    updateDutyFormSuccess, updateDutyFormFail,
    addDutyFormSuccess, addDutyFormFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDutyForms, //API CONNECTION
    delDutyForm,
    addDutyForm,
    updateDutyForm,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDutyForms() {
    try {
        const response = yield call(getDutyForms)
        yield put(getDutyFormsSuccess(response));
    } catch (error) {
        yield put(getDutyFormsFail(error));
    }
}

function* onDeleteDutyForms({payload:id}){
    try{
        const response = yield call(delDutyForm, id)
        yield put(deleteDutyFormSuccess(response));
    } catch (error) {
        yield put(deleteDutyFormFail(error));
    }
}

function* onAddDutyForm({payload:dutyForm}){
    try{
        const response = yield call(addDutyForm, dutyForm)
        yield put(addDutyFormSuccess(response));
    } catch (error) {
        yield put(addDutyFormFail(error));
    }
}

function* onUpdateDutyForm({payload:dutyForm}){
    try{
        const response = yield call(updateDutyForm, dutyForm)
        yield put(updateDutyFormSuccess(response));
    } catch (error) {
        yield put(updateDutyFormFail(error));
    }
}

export function* dutyFormsSaga() {
    yield takeEvery(GET_DUTY_FORMS, fetchDutyForms);
    yield takeEvery(DELETE_DUTY_FORM, onDeleteDutyForms);
    yield takeEvery(ADD_DUTY_FORM, onAddDutyForm);
    yield takeEvery(UPDATE_DUTY_FORM, onUpdateDutyForm);
   
    
}

export default dutyFormsSaga;
