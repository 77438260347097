import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_WORK_TYPES, DELETE_DEV_WORK_TYPE, ADD_DEV_WORK_TYPE, UPDATE_DEV_WORK_TYPE,GET_DEV_WORK_TYPES_MY_DEV_WORK_TYPES} from "./actionTypes";
import {
    getDevWorkTypesSuccess, getDevWorkTypesFail,
    deleteDevWorkTypeSuccess, deleteDevWorkTypeFail,
    updateDevWorkTypeSuccess, updateDevWorkTypeFail,
    addDevWorkTypeSuccess, addDevWorkTypeFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevWorkTypes, //API CONNECTION
    delDevWorkType,
    addDevWorkType,
    updateDevWorkType,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevWorkTypes() {
    try {
        const response = yield call(getDevWorkTypes)
        yield put(getDevWorkTypesSuccess(response));
    } catch (error) {
        yield put(getDevWorkTypesFail(error));
    }
}

function* onDeleteDevWorkTypes({payload:id}){
    try{
        const response = yield call(delDevWorkType, id)
        yield put(deleteDevWorkTypeSuccess(response));
    } catch (error) {
        yield put(deleteDevWorkTypeFail(error));
    }
}

function* onAddDevWorkType({payload:devWorkType}){
    try{
        const response = yield call(addDevWorkType, devWorkType)
        yield put(addDevWorkTypeSuccess(response));
    } catch (error) {
        yield put(addDevWorkTypeFail(error));
    }
}

function* onUpdateDevWorkType({payload:devWorkType}){
    try{
        const response = yield call(updateDevWorkType, devWorkType)
        yield put(updateDevWorkTypeSuccess(response));
    } catch (error) {
        yield put(updateDevWorkTypeFail(error));
    }
}

export function* devWorkTypesSaga() {
    yield takeEvery(GET_DEV_WORK_TYPES, fetchDevWorkTypes);
    yield takeEvery(DELETE_DEV_WORK_TYPE, onDeleteDevWorkTypes);
    yield takeEvery(ADD_DEV_WORK_TYPE, onAddDevWorkType);
    yield takeEvery(UPDATE_DEV_WORK_TYPE, onUpdateDevWorkType);
   
    
}

export default devWorkTypesSaga;
