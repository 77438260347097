import {
    GET_COMPANY_FORM_BY_DATE,
    GET_COMPANY_FORM_BY_DATE_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_FAIL,

    GET_COMPANY_FORM_BY_DATE_YESTERDAY,
    GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL,

    GET_COMPANY_FORM_BY_DATE_MONTH,
    GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_MONTH_FAIL,

    GET_COMPANY_FORM_IS_MATCH_BY_DATE,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL,

    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,

    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,

    GET_COMPANY_QR_BY_DATE,
    GET_COMPANY_QR_BY_DATE_SUCCESS,
    GET_COMPANY_QR_BY_DATE_FAIL,

    GET_COMPANY_QR_BY_DATE_ALL,
    GET_COMPANY_QR_BY_DATE_ALL_SUCCESS,
    GET_COMPANY_QR_BY_DATE_ALL_FAIL,

    GET_COMPANY_QR_BY_DATE_YESTERDAY,
    GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL,

    GET_COMPANY_QR_BY_DATE_MONTH,
    GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS, 
    GET_COMPANY_QR_BY_DATE_MONTH_FAIL,

    GET_BRANCH_FORM_BY_DATE,
    GET_BRANCH_FORM_BY_DATE_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_FAIL,

    GET_BRANCH_FORM_BY_DATE_YESTERDAY,
    GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL,

    GET_BRANCH_FORM_BY_DATE_MONTH,
    GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_MONTH_FAIL,

    GET_BRANCH_FORM_IS_MATCH_BY_DATE,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL,

    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,

    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,

    GET_BRANCH_QR_BY_DATE,
    GET_BRANCH_QR_BY_DATE_SUCCESS,
    GET_BRANCH_QR_BY_DATE_FAIL,

    GET_BRANCH_QR_BY_DATE_ALL,
    GET_BRANCH_QR_BY_DATE_ALL_SUCCESS,
    GET_BRANCH_QR_BY_DATE_ALL_FAIL,

    GET_BRANCH_QR_BY_DATE_YESTERDAY,
    GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL,

    GET_BRANCH_QR_BY_DATE_MONTH,
    GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS, 
    GET_BRANCH_QR_BY_DATE_MONTH_FAIL,

    GET_COMPANY_SHARE_QR_BY_DATE,
    GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_FAIL,

    GET_COMPANY_SHARE_QR_BY_DATE_ALL,
    GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL,

    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY,
    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL,

    GET_COMPANY_SHARE_QR_BY_DATE_MONTH,
    GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS, 
    GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL,

    GET_BRANCH_SHARE_QR_BY_DATE, 
    GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_FAIL,

    GET_BRANCH_SHARE_QR_BY_DATE_ALL,
    GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL,

    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY,
    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL,

    GET_BRANCH_SHARE_QR_BY_DATE_MONTH,
    GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS, 
    GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL,
    RESET_BRAND,
   
} from "./actionTypes";
  

export const resetBrand = () => ({
    type: RESET_BRAND,
});
//----------QR SHARE NUMBERS-------------
//Branch SHAREQR All
export const getBranchShareQrByDateAll = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_ALL,
    payload: BranchId,startDate,endDate,    
});

export const getBranchShareQrByDateAllSuccess = (brand) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS,
    payload: brand,
}); 
  

export const getBranchShareQrByDateAllFail = (error) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL,
    payload: error,
});
//Branch SHAREQR Today
export const getBranchShareQrByDate = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE,
    payload: BranchId,startDate,endDate,    
});

export const getBranchShareQrByDateSuccess = (brand) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getBranchShareQrByDateFail = (error) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_FAIL,
    payload: error,
});
//Branch SHAREQr Yesterday
export const getBranchShareQrByDateYesterday = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY,
    payload: BranchId,startDate,endDate,    
});

export const getBranchShareQrByDateYesterdaySuccess = (brand) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS, 
    payload: brand,
}); 


export const getBranchShareQrByDateYesterdayFail = (error) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Branch SHAREQr This month
export const getBranchShareQrByDateMonth = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_MONTH,
    payload: BranchId,startDate,endDate,    
});

export const getBranchShareQrByDateMonthSuccess = (brand) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getBranchShareQrByDateMonthFail = (error) => ({
    type: GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL,
    payload: error,
});  

//----------QR SHARE NUMBERS-------------
//Company QR All
export const getCompanyShareQrByDateAll = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_ALL,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyShareQrByDateAllSuccess = (brand) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS,
    payload: brand,
}); 
  

export const getCompanyShareQrByDateAllFail = (error) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL,
    payload: error,
});
//Company QR Today
export const getCompanyShareQrByDate = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyShareQrByDateSuccess = (brand) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getCompanyShareQrByDateFail = (error) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_FAIL,
    payload: error,
});
//Company Qr Yesterday
export const getCompanyShareQrByDateYesterday = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyShareQrByDateYesterdaySuccess = (brand) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS, 
    payload: brand,
}); 


export const getCompanyShareQrByDateYesterdayFail = (error) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Company Qr This month
export const getCompanyShareQrByDateMonth = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_MONTH,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyShareQrByDateMonthSuccess = (brand) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getCompanyShareQrByDateMonthFail = (error) => ({
    type: GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL,
    payload: error,
});  
//---------------------FORMS IS MATCH NUMBER-------------------
//Branch Forms IsMatch Today
export const getBranchFormsIsMatchByDate = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE, 
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsIsMatchByDateSuccess = (brand) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS,
    payload: brand,
}); 
export const getBranchFormsIsMatchByDateFail = (error) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL,
    payload: error,
});
//Branch Forms IsMatch Yesterday
export const getBranchFormsIsMatchByDateYesterday = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsIsMatchByDateYesterdaySuccess = (brand) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    payload: brand,
}); 


export const getBranchFormsIsMatchByDateYesterdayFail = (error) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Branch Forms IsMatch This month
export const getBranchFormsIsMatchByDateMonth = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH,
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsIsMatchByDateMonthSuccess = (brand) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getBranchFormsIsMatchByDateMonthFail = (error) => ({
    type: GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,
    payload: error,
});

//----------QR NUMBERS-------------
//Branch QR All
export const getBranchQrByDateAll = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_QR_BY_DATE_ALL,
    payload: BranchId,startDate,endDate,    
});

export const getBranchQrByDateAllSuccess = (brand) => ({
    type: GET_BRANCH_QR_BY_DATE_ALL_SUCCESS,
    payload: brand,
}); 
  

export const getBranchQrByDateAllFail = (error) => ({
    type: GET_BRANCH_QR_BY_DATE_ALL_FAIL,
    payload: error,
});
//Branch QR Today
export const getBranchQrByDate = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_QR_BY_DATE,
    payload: BranchId,startDate,endDate,    
});

export const getBranchQrByDateSuccess = (brand) => ({
    type: GET_BRANCH_QR_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getBranchQrByDateFail = (error) => ({
    type: GET_BRANCH_QR_BY_DATE_FAIL,
    payload: error,
});
//Branch Qr Yesterday
export const getBranchQrByDateYesterday = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_QR_BY_DATE_YESTERDAY,
    payload: BranchId,startDate,endDate,    
});

export const getBranchQrByDateYesterdaySuccess = (brand) => ({
    type: GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS, 
    payload: brand,
}); 


export const getBranchQrByDateYesterdayFail = (error) => ({
    type: GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Branch Qr This month
export const getBranchQrByDateMonth = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_QR_BY_DATE_MONTH,
    payload: BranchId,startDate,endDate,    
});

export const getBranchQrByDateMonthSuccess = (brand) => ({
    type: GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getBranchQrByDateMonthFail = (error) => ({
    type: GET_BRANCH_QR_BY_DATE_MONTH_FAIL,
    payload: error,
});  

//---------------------FORMS NUMBER
//Branch Forms Today
export const getBranchFormsByDate = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_BY_DATE,
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsByDateSuccess = (brand) => ({
    type: GET_BRANCH_FORM_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getBranchFormsByDateFail = (error) => ({
    type: GET_BRANCH_FORM_BY_DATE_FAIL,
    payload: error,
});
//Branch Forms Yesterday
export const getBranchFormsByDateYesterday = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_BY_DATE_YESTERDAY,
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsByDateYesterdaySuccess = (brand) => ({
    type: GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS,
    payload: brand,
}); 


export const getBranchFormsByDateYesterdayFail = (error) => ({
    type: GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Branch Forms This month
export const getBranchFormsByDateMonth = (BranchId,startDate,endDate) => ({
    type: GET_BRANCH_FORM_BY_DATE_MONTH,
    payload: BranchId,startDate,endDate,    
});

export const getBranchFormsByDateMonthSuccess = (brand) => ({
    type: GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getBranchFormsByDateMonthFail = (error) => ({
    type: GET_BRANCH_FORM_BY_DATE_MONTH_FAIL,
    payload: error,
});


//---------------------FORMS IS MATCH NUMBER-------------------
//Company Forms IsMatch Today
export const getCompanyFormsIsMatchByDate = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE, 
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsIsMatchByDateSuccess = (brand) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS,
    payload: brand,
}); 
export const getCompanyFormsIsMatchByDateFail = (error) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL,
    payload: error,
});
//Company Forms IsMatch Yesterday
export const getCompanyFormsIsMatchByDateYesterday = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsIsMatchByDateYesterdaySuccess = (brand) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    payload: brand,
}); 


export const getCompanyFormsIsMatchByDateYesterdayFail = (error) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Company Forms IsMatch This month
export const getCompanyFormsIsMatchByDateMonth = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsIsMatchByDateMonthSuccess = (brand) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getCompanyFormsIsMatchByDateMonthFail = (error) => ({
    type: GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,
    payload: error,
});

//----------QR NUMBERS-------------
//Company QR All
export const getCompanyQrByDateAll = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_QR_BY_DATE_ALL,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyQrByDateAllSuccess = (brand) => ({
    type: GET_COMPANY_QR_BY_DATE_ALL_SUCCESS,
    payload: brand,
}); 
  

export const getCompanyQrByDateAllFail = (error) => ({
    type: GET_COMPANY_QR_BY_DATE_ALL_FAIL,
    payload: error,
});
//Company QR Today
export const getCompanyQrByDate = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_QR_BY_DATE,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyQrByDateSuccess = (brand) => ({
    type: GET_COMPANY_QR_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getCompanyQrByDateFail = (error) => ({
    type: GET_COMPANY_QR_BY_DATE_FAIL,
    payload: error,
});
//Company Qr Yesterday
export const getCompanyQrByDateYesterday = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_QR_BY_DATE_YESTERDAY,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyQrByDateYesterdaySuccess = (brand) => ({
    type: GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS, 
    payload: brand,
}); 


export const getCompanyQrByDateYesterdayFail = (error) => ({
    type: GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Company Qr This month
export const getCompanyQrByDateMonth = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_QR_BY_DATE_MONTH,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyQrByDateMonthSuccess = (brand) => ({
    type: GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getCompanyQrByDateMonthFail = (error) => ({
    type: GET_COMPANY_QR_BY_DATE_MONTH_FAIL,
    payload: error,
});  

//---------------------FORMS NUMBER
//Company Forms Today
export const getCompanyFormsByDate = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_BY_DATE,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsByDateSuccess = (brand) => ({
    type: GET_COMPANY_FORM_BY_DATE_SUCCESS,
    payload: brand,
}); 
 

export const getCompanyFormsByDateFail = (error) => ({
    type: GET_COMPANY_FORM_BY_DATE_FAIL,
    payload: error,
});
//Company Forms Yesterday
export const getCompanyFormsByDateYesterday = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_BY_DATE_YESTERDAY,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsByDateYesterdaySuccess = (brand) => ({
    type: GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS,
    payload: brand,
}); 


export const getCompanyFormsByDateYesterdayFail = (error) => ({
    type: GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL,
    payload: error,
});  
//Company Forms This month
export const getCompanyFormsByDateMonth = (CompanyId,startDate,endDate) => ({
    type: GET_COMPANY_FORM_BY_DATE_MONTH,
    payload: CompanyId,startDate,endDate,    
});

export const getCompanyFormsByDateMonthSuccess = (brand) => ({
    type: GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS,
    payload: brand,
}); 


export const getCompanyFormsByDateMonthFail = (error) => ({
    type: GET_COMPANY_FORM_BY_DATE_MONTH_FAIL,
    payload: error,
});
