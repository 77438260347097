/** Get SECTOR_FORM */
export const GET_SECTOR_FORMS = "GET_SECTOR_FORMS";
export const GET_SECTOR_FORMS_SUCCESS = "GET_SECTOR_FORMS_SUCCESS";
export const GET_SECTOR_FORMS_FAIL = "GET_SECTOR_FORMS_FAIL";

export const GET_SECTOR_FORM_DETAIL = "GET_SECTOR_FORM_DETAIL";
export const GET_SECTOR_FORM_DETAIL_SUCCESS = "GET_SECTOR_FORM_DETAIL_SUCCESS";
export const GET_SECTOR_FORM_DETAIL_FAIL = "GET_SECTOR_FORM_DETAIL_FAIL";


export const ADD_SECTOR_FORM = "ADD_SECTOR_FORM";
export const ADD_SECTOR_FORM_SUCCESS = "ADD_SECTOR_FORM_SUCCESS";
export const ADD_SECTOR_FORM_FAIL = "ADD_SECTOR_FORM_FAIL";

export const UPDATE_SECTOR_FORM = "UPDATE_SECTOR_FORM";
export const UPDATE_SECTOR_FORM_SUCCESS = "UPDATE_SECTOR_FORM_SUCCESS";
export const UPDATE_SECTOR_FORM_FAIL = "UPDATE_SECTOR_FORM_FAIL";

export const DELETE_SECTOR_FORM = "DELETE_SECTOR_FORM";
export const DELETE_SECTOR_FORM_SUCCESS = "DELETE_SECTOR_FORM_SUCCESS";
export const DELETE_SECTOR_FORM_FAIL = "DELETE_SECTOR_FORM_FAIL"; 



export const RESET_SECTOR_FORM= "RESET_SECTOR_FORM";