import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_WORK_TYPES, DELETE_WORK_TYPE, ADD_WORK_TYPE, UPDATE_WORK_TYPE,GET_WORK_TYPES_MY_WORK_TYPES} from "./actionTypes";
import {
    getWorkTypesSuccess, getWorkTypesFail,
    deleteWorkTypeSuccess, deleteWorkTypeFail,
    updateWorkTypeSuccess, updateWorkTypeFail,
    addWorkTypeSuccess, addWorkTypeFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getWorkTypes, //API CONNECTION
    delWorkType,
    addWorkType,
    updateWorkType,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchWorkTypes() {
    try {
        const response = yield call(getWorkTypes)
        yield put(getWorkTypesSuccess(response));
    } catch (error) {
        yield put(getWorkTypesFail(error));
    }
}

function* onDeleteWorkTypes({payload:id}){
    try{
        const response = yield call(delWorkType, id)
        yield put(deleteWorkTypeSuccess(response));
    } catch (error) {
        yield put(deleteWorkTypeFail(error));
    }
}

function* onAddWorkType({payload:workType}){
    try{
        const response = yield call(addWorkType, workType)
        yield put(addWorkTypeSuccess(response));
    } catch (error) {
        yield put(addWorkTypeFail(error));
    }
}

function* onUpdateWorkType({payload:workType}){
    try{
        const response = yield call(updateWorkType, workType)
        yield put(updateWorkTypeSuccess(response));
    } catch (error) {
        yield put(updateWorkTypeFail(error));
    }
}

export function* workTypesSaga() {
    yield takeEvery(GET_WORK_TYPES, fetchWorkTypes);
    yield takeEvery(DELETE_WORK_TYPE, onDeleteWorkTypes);
    yield takeEvery(ADD_WORK_TYPE, onAddWorkType);
    yield takeEvery(UPDATE_WORK_TYPE, onUpdateWorkType);
   
    
}

export default workTypesSaga;
