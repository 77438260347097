import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_FOREIGN_LANGUAGES, DELETE_FOREIGN_LANGUAGE, ADD_FOREIGN_LANGUAGE, UPDATE_FOREIGN_LANGUAGE,GET_FOREIGN_LANGUAGES_MY_FOREIGN_LANGUAGES} from "./actionTypes";
import {
    getForeignLanguagesSuccess, getForeignLanguagesFail,
    deleteForeignLanguageSuccess, deleteForeignLanguageFail,
    updateForeignLanguageSuccess, updateForeignLanguageFail,
    addForeignLanguageSuccess, addForeignLanguageFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getForeignLanguages, //API CONNECTION
    delForeignLanguage,
    addForeignLanguage,
    updateForeignLanguage,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchForeignLanguages() {
    try {
        const response = yield call(getForeignLanguages)
        yield put(getForeignLanguagesSuccess(response));
    } catch (error) {
        yield put(getForeignLanguagesFail(error));
    }
}

function* onDeleteForeignLanguages({payload:id}){
    try{
        const response = yield call(delForeignLanguage, id)
        yield put(deleteForeignLanguageSuccess(response));
    } catch (error) {
        yield put(deleteForeignLanguageFail(error));
    }
}

function* onAddForeignLanguage({payload:foreignLanguage}){
    try{
        const response = yield call(addForeignLanguage, foreignLanguage)
        yield put(addForeignLanguageSuccess(response));
    } catch (error) {
        yield put(addForeignLanguageFail(error));
    }
}

function* onUpdateForeignLanguage({payload:foreignLanguage}){
    try{
        const response = yield call(updateForeignLanguage, foreignLanguage)
        yield put(updateForeignLanguageSuccess(response));
    } catch (error) {
        yield put(updateForeignLanguageFail(error));
    }
}

export function* foreignLanguagesSaga() {
    yield takeEvery(GET_FOREIGN_LANGUAGES, fetchForeignLanguages);
    yield takeEvery(DELETE_FOREIGN_LANGUAGE, onDeleteForeignLanguages);
    yield takeEvery(ADD_FOREIGN_LANGUAGE, onAddForeignLanguage);
    yield takeEvery(UPDATE_FOREIGN_LANGUAGE, onUpdateForeignLanguage);
   
    
}

export default foreignLanguagesSaga;
