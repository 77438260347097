import {
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL,
  RESET_ANALYTICS,
  GET_MY_FORMS_LENGTH_SUCCESS,
  GET_MY_FORMS_LENGTH_FAIL,
  GET_ANALYTIC_BY_LINK_SUCCESS,
  GET_ANALYTIC_BY_LINK_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: null,
  error: null,
  myFormsLength: [],
  getAnalyticByLink: 0,
}

const Analytics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MY_FORMS_LENGTH_SUCCESS:
      return {
        ...state,
        myFormsLength: action.payload.data,
      }
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_ANALYTIC_BY_LINK_SUCCESS:
      return {
        ...state,
        getAnalyticByLink: action.payload.data,
      }
    //FAILS
    case GET_MY_FORMS_LENGTH_FAIL:
    case GET_ANALYTICS_FAIL:
    case GET_ANALYTIC_BY_LINK_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ANALYTICS:
      return {
        ...state,
        data: null,
        error: null,
        getAnalyticByLink:0
      }
    default:
      return state
  }
}

export default Analytics
