import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER2_USER_SUCCESSFUL,
  REGISTER2_USER_FAILED,
  REGISTER3_USER_SUCCESSFUL,
  REGISTER3_USER_FAILED,
  REGISTER_SUCCESS_RESET,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  registerSuccess: false,
  register2Success: false, 
  register3Success: false, 
} 

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = { 
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
        registerSuccess: true,
      } 
      break
      case REGISTER2_USER_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          user: action.payload,
          registrationError: null,
          register2Success: true,
        } 
        break
        case REGISTER3_USER_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          user: action.payload,
          registrationError: null,
          register3Success: true,
        } 
        break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload, 
      }
      break
      case REGISTER2_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
      case REGISTER3_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false, 
        registrationError: action.payload,
      }
      break
    case REGISTER_SUCCESS_RESET:
      state = {
        ...state,
        registerSuccess: false,
        register2Success: false, 
        register3Success: false, 
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
