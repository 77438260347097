import {
    GET_PLANS,
    GET_PLANS_SUCCESS,
    GET_PLANS_FAIL,
    GET_PLAN_DETAIL,
    GET_PLAN_DETAIL_SUCCESS,
    GET_PLAN_DETAIL_FAIL,
    ADD_PLAN,
    ADD_PLAN_SUCCESS,
    ADD_PLAN_FAIL,
    UPDATE_PLAN,
    UPDATE_PLAN_SUCCESS,
    UPDATE_PLAN_FAIL,
    DELETE_PLAN,
    DELETE_PLAN_SUCCESS,
    DELETE_PLAN_FAIL,
    RESET_PLAN,
    GET_PLANS_MY_PLANS_SUCCESS,
    GET_PLANS_MY_PLANS_FAIL,
    ADD_PLANS_MY_PLANS_SUCCESS,
    ADD_PLANS_MY_PLANS_FAIL,
    DELETE_PLANS_MY_PLANS_SUCCESS,
    DELETE_PLANS_MY_PLANS_FAIL,
    UPDATE_PLANS_MY_PLANS_SUCCESS,
    UPDATE_PLANS_MY_PLANS_FAIL,
    GET_USER_BY_MAIL_SUCCESS,
    GET_USER_BY_MAIL_FAIL,
    GET_PLANS_BY_BRANCH_SUCCESS,
    GET_PLANS_BY_BRANCH_FAIL,
    GET_BRANCH_PLAN_SUCCESS, 
    GET_BRANCH_PLAN_FAIL,
    GET_PLANS_DOWN_SUCCESS,
    GET_PLANS_DOWN_FAIL,
    UPDATE_PLAN_ACTIVE_SUCCESS,
    UPDATE_PLAN_ACTIVE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    myPlans: [],
    user :[],
    branch:[],
    plansDown:[],
    success:null,
    isActive:[]
};

const Plans = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_PLAN_ACTIVE_SUCCESS:
            return {
                ...state,
                isActive: state.data.map(plan =>
                    plan._id.toString() === action.payload.data._id.toString() ?
                        { plan, ...action.payload.data } :
                        plan
                )
            }
        case GET_PLANS_DOWN_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_PLAN_DETAIL_SUCCESS:
            return {
                ...state,
                myPlans: action.payload.data
            }
        case GET_USER_BY_MAIL_SUCCESS:
                return {
                    ...state, 
                    user: action.payload.data
                }
        case GET_PLANS_BY_BRANCH_SUCCESS:
            return {
                ...state,
                myPlans: action.payload.data
            }
            case GET_BRANCH_PLAN_SUCCESS:
                return {
                    ...state,
                    branch: action.payload.data
                }
        case GET_PLANS_MY_PLANS_SUCCESS:
            return {
                ...state,
                myPlans: action.payload.data
            }
        case ADD_PLANS_MY_PLANS_SUCCESS:
            return {
                ...state, 
             
                myPlans: [action.payload.data, ...state.myPlans],
            }
        case DELETE_PLANS_MY_PLANS_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myPlans: state.myPlans.filter(
                    plan => plan._id.toString() !== action.payload.data.toString()
                  ),
                }
        case UPDATE_PLANS_MY_PLANS_SUCCESS:
                return {
                    ...state,
                    myPlans: state.myPlans.map(plan =>
                        plan._id.toString() === action.payload.data._id.toString() ?
                            { plan, ...action.payload.data } :
                            plan
                    )
                    }
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PLAN_SUCCESS:
            return {
                ...state,
                data: state.data.filter(plan => plan._id.toString() !== action.payload.data.toString())
            }
        case ADD_PLAN_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true
            }
        case UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                data: state.data.map(plan =>
                    plan._id.toString() === action.payload.data._id.toString() ?
                        { plan, ...action.payload.data } :
                        plan
                )
            }
 
        //FAILS
        case GET_PLANS_FAIL:
        case DELETE_PLAN_FAIL:
        case ADD_PLAN_FAIL:
        case UPDATE_PLAN_FAIL:
        case UPDATE_PLAN_FAIL:
        case GET_PLANS_MY_PLANS_FAIL:
        case ADD_PLANS_MY_PLANS_FAIL:
        case DELETE_PLANS_MY_PLANS_FAIL:
        case UPDATE_PLANS_MY_PLANS_FAIL:
        case GET_PLAN_DETAIL_FAIL:
        case GET_USER_BY_MAIL_FAIL:
        case GET_PLANS_BY_BRANCH_FAIL: 
        case  GET_BRANCH_PLAN_FAIL:
        case GET_PLANS_DOWN_FAIL:
        case UPDATE_PLAN_ACTIVE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PLAN: return {
            ...state,
            error: null,
            success: null
        }
        default:
            return state;
    }
}


export default Plans;