import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import ApplicationPlanModal from "./../Plan/Modals/applicationPlanModal"
import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
import moment from "moment"
import { authorize, Role } from "./../../components/helpers/authorize"
import user1 from "../../assets/images/User-Avatar-Profile-PNG-Free-File-Download.png"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  getPlans as onGetPlans,
  getRequestFormPlans as onGetRequestFormPlans,
  getCompanyBranches as onGetCompanyBranches,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  addRequestFormPlan as onAddRequestFormPlan,
  updateRequestFormPlan as onUpdateRequestFormPlan,
  deleteRequestFormPlan as onDeleteRequestFormPlan,
  updateAction as onUpdateActions,
  updateStatus as onUpdateStatus,
  getReqFormsDown as onGetReqFormsDown,
  getDutys as onGetDuty,
  getForeignLanguages as onGetForeignLanguages,
  getPrograms as onGetPrograms,
  getSectorForms as onGetSectorForms,
  getDutyForms as onGetDutyForms,
  getBackUrls as onGetBackUrls,
  getDutiesForms as onGetDutiesForms,
  getDutiesFormById as onGetDutiesFormById,
  getRequestReasons as onGetRequestReason,
  addVisit as onAddVisit
} from "store/actions"

import { defaultsDeep, isEmpty, map } from "lodash"
import Multiselect from "multiselect-react-dropdown"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { select } from "redux-saga/effects"
import types from "react-cropper"
import {
  ASKERLIK_DURUMU_SEC,
  AYRILMA_NEDENI_SEC,
  CINSIYET_SEC,
  DIL_SEC,
  EGITIM_DURUMU_SEC,
  EHLIYET_BILGISI_SEC,
  GOREV_SEC,
  MEDENI_DURUM_SEC,
  PROGRAM_BILGISI_SEC,
  SEKTOR_SEC,
  SEVIYE_SEC,
  SURE_SEC_YIL,
  MAAS_BEKLENTISI
} from "pages/Plan/helper/options"

const RequestForm = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)

  const [modalQr, setModalQr] = useState(false)
  const [ınfoQr, setInfoQr] = useState()
  const [selectedId, setSelectedId] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [copyPlan, setCopyPlan] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [copy_alert, setCopy_alert] = useState(false)

  const [isGroupButton, setIsGroupButton] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [checkModal, setCheckModal] = useState(false)
  const [createPlanModal, setCreatePlanModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [selectedBranchMulti, setSelectedBranchMulti] = useState([])
  const [deletedItems, setDeletedItem] = useState([])

  const [selectedDuty, setSelectedDuty] = useState()
  const [selectedDutiesForm, setSelectedDutiesForm] = useState()
  function handleOnDragSocialMediasEnd_up(index) {
    const items = Array.from(planObjects);
    const [reorderedItem] = items.splice(index, 1);
    items.splice(index - 1, 0, reorderedItem);

    setPlanObjects(items)

  }
  function handleOnDragSocialMediasEnd_down(index) {
    const items = Array.from(planObjects);
    const [reorderedItem] = items.splice(index, 1);
    items.splice(index + 1, 0, reorderedItem);

    setPlanObjects(items)
  }
  const [planObjects, setPlanObjects] = useState([
    {
      title: "isDeneyimi",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
      targetValue: "",
    },
  ])
  const [criteriaObjects, setCriteriaObject] = useState([
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
      targetValue: "",
    },
  ])
  const [tempPlanObjects, setTempPlanObjects] = useState([])
  let tempForeignLanguages = "Dil Seç"
  let tempProgram = "Program Seç"
  let tempSectorForms = "Sektör Seç"
  let tempDutyForms = "Görev Seç"
  const { error, Plans, SectorForms, DutiesForms, formDetail, DutyForms, Branches, Programs, MyPlans, User, RequestFormsPlan, Duty, ForeignLanguages, RequestReason } =
    useSelector(state => ({
      error: state.Plans?.error,
      MyPlans: state.Plans?.myPlans,
      Plans: state.Plans?.data,
      Branches: state.Branchs?.Branches,
      User: state.Plans?.user,
      RequestFormsPlan: state.RequestFormPlan?.data,
      Duty: state.Duty?.data,
      ForeignLanguages: state.ForeignLanguage?.data,
      Programs: state.Program?.data,
      SectorForms: state.SectorForm?.data,
      DutyForms: state.DutyForm?.data,
      DutiesForms: state.DutiesForm?.data,
      formDetail: state.DutiesForm?.formDetail,
      RequestReason: state.RequestReason?.data
    }))
  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetPlans())
    dispatch(onGetDuty())
    dispatch(onGetPrograms())
    dispatch(onGetSectorForms())
    dispatch(onGetDutyForms())
    dispatch(onGetBackUrls())
    dispatch(onGetForeignLanguages())
    dispatch(onGetDutiesForms());
    dispatch(onGetRequestReason())
    if (authorize([Role.Admin])) {
      dispatch(onGetRequestFormPlans())
      dispatch(onGetCompanyBranches(user.companyId))
    } else {
      dispatch(onGetReqFormsDown(user.branchId))
      dispatch(onGetCompanyBranches(user.companyId, user.branchId))
    }
    const visitDetailArr =[{name:"Personel Talep Formları",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
    dispatch(getUserByMail(user.email))
  }, [dispatch])
  const toggle = () => {
    setModal(!modal)
  }
  const checkToggle = () => {
    setCheckModal(!checkModal)
  }
  const createPlanToggle = () => {
    setCreatePlanModal(!createPlanModal)
  }
  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }

  const toggleQr = () => {
    setModalQr(!modalQr)
  }

  //Dynamic Inputs START
  if (!isEmpty(ForeignLanguages)) {
    ForeignLanguages.map((item, index) => {
      tempForeignLanguages = tempForeignLanguages + "," + item.name.name
    })
  } else {
    tempForeignLanguages = "Dil Seç,İngilizce,Almanca,Çince"
  }
  if (!isEmpty(Programs)) {
    Programs.map((item, index) => {
      tempProgram = tempProgram + "," + item.name.name
    })
  } else {
    tempProgram = "Program Seç,Excel,Word,Outlook,Perakende Yazılımı Perakende Yazılımı / Nebim,Perakende Yazılımı / Epos,Diğer"
  }
  if (!isEmpty(DutyForms)) {
    DutyForms.map((item, index) => {
      tempDutyForms = tempDutyForms + "," + item.name.name
    })
  } else {
    tempDutyForms = "Görev Seç,Satış Danışmanı,Karşılama Hostesi,Kasa Elemanı,Depo Elemanı,Ütücü,Servis Elemanı,Barista,Komi / Garson,Mutfak Elemanı,Usta,Müdür Yardımcısı,Diğer"
  }
  if (!isEmpty(SectorForms)) {
    SectorForms.map((item, index) => {
      tempSectorForms = tempSectorForms + "," + item.name.name
    })
  } else {
    tempSectorForms = "Sektör Seç,Hazır Giyim,Ev Tekstili,Kişisel Bakım,Elektronik Ürünler,Telekomünikasyon,Süpermarket,Mobilya,Kitap / Kırtasiye,Otel,Restorant / Kafe,Diğer"
  }

  const handleChangeCriteria = (
    i,
    e,
    inputName,
    keyName,
    title,
    isType,
    options
  ) => {
    const values = [...planObjects]
    values[i]["isOptions"] = options
    values[i]["isType"] = isType
    values[i]["key"] = keyName
    values[i][inputName] = e.target.value
    setPlanObjects(values)
  }

  const handleSubtract = (i, items) => {
    const values = [...planObjects]
    const deleted = [...deletedItems]
    deleted.push(items)
    setDeletedItem(deleted)
    values.splice(i, 1)
    setPlanObjects([...values])
  }
  const handleChangeInputForGroup = (
    i,
    i2,
    e,
    title,
    isType,
    isOptions,
    inputName
  ) => {

    const values = [...planObjects]
    values[i]["isType"] = isType
    values[i]["isPlan"][i2]["isType"] = "dropdown"
    values[i]["isPlan"][i2]["isOptions"] = isOptions
    values[i]["key"] = title

    values[i]["isPlan"][i2]["targetValue"] = e.target.value
    values[i]["isPlan"][i2]["isValue"] = e.target.value
    setPlanObjects(values)
  }
  const handleChangeInputNameForGroup = (
    i,
    i2,
    e,
    title,
    isType,
    isOptions,
    inputName
  ) => {
    //console.log(i, i2, e.target.value, inputName)
    const values = [...planObjects]
    values[i]["isType"] = isType
    values[i]["isPlan"][i2]["isType"] = "dropdown"
    values[i]["isPlan"][i2]["isOptions"] = isOptions
    values[i]["key"] = title

    values[i]["isPlan"][i2]["targetValue"] = e.target.value
    values[i]["isPlan"][i2]["isValue"] = e.target.value
    setPlanObjects(values)
  }

  const handleSubtractForGroup = (i, i2) => {
    const values = [...planObjects]
    values[i]["isPlan"].splice(i2, 1)
    setPlanObjects([...values])
  }
  const handleAddGroupFirst = (index, title, isType = "", isOptions = "") => {
    let temp = [...planObjects]
    if (temp[index].isPlan == null || temp[index].isPlan == "") {
      temp[index].isPlan = []
    }

    temp[index].isPlan.splice(index + 1, 0, {
      title: title,
      isType: isType,
      isValue: "",
      targetValue: "",
      isOptions: isOptions,
    })

    setPlanObjects([...temp])
  }
  const handleAddGroup_AddGroup = (
    index,
    title,
    isType = "",
    isOptions = ""
  ) => {
    let temp = [...planObjects]
    if (temp[index].isPlan == null || temp[index].isPlan == "") {
      temp[index].isPlan = []
    }
    temp[index].isPlan.push({
      title: title,
      isType: isType,
      isValue: "",
      targetValue: "",
      isOptions: isOptions,
    })

    setPlanObjects([...temp])
  }



  const branchReset = () => {
    setSelectedBranchId("")
  }
  const InputAddReset = () => {
    let tempInput = [
      {
        title: "Cinsiyet",
        isType: "dropdown", //date, string, number, vs
        isValue: "",
        isPlan: null,
        isOptions: CINSIYET_SEC,
        key: "cinsiyet",
        targetValue: "",
      },
      {
        title: "ehliyet",
        isType: "group", //date, string, number, vs
        isValue: "",
        isPlan: [{ title: "Ehliyet Bilgisi", isType: "dropdown", isOptions: "Ehliyet Bilgisi Seç,A1,A2,B1,B2" },
        { title: "Süre", isType: "dropdown", isOptions: "Süre Seç,1 yıldan az,1-2 yıl arası,2-3 yıl arası,3-5 yıl arası,5-10 yıl arası,10 yıldan fazla" }],
        isOptions: "",
        key: "ehliyet",
        targetValue: "",
      },
      {
        title: "Medeni Durum",
        isType: "dropdown", //date, string, number, vs
        isValue: "",
        isPlan: null,
        isOptions: MEDENI_DURUM_SEC,
        key: "medeniDurum",
        targetValue: "",
      },
      {
        title: "Eğitim Durumu",
        isType: "dropdown", //date, string, number, vs
        isValue: "",
        isPlan: null,
        isOptions: EGITIM_DURUMU_SEC,
        key: "egitimDurumu",
        targetValue: "",
      },
      {
        title: "yabanciDil",
        isType: "group",
        isValue: "",
        isPlan: [{ title: "Dil", isType: "dropdown", isOptions: tempForeignLanguages },
        { title: "Okuma", isType: "dropdown", isOptions: "Seviye Seç,Yok,Az,Orta,İyi,Çok İyi" },
        { title: "Yazma", isType: "dropdown", isOptions: "Seviye Seç,Yok,Az,Orta,İyi,Çok İyi" },
        { title: "Konuşma", isType: "dropdown", isOptions: "Seviye Seç,Yok,Az,Orta,İyi,Çok İyi" }],
        isOptions: "",
        key: "yabanciDil",
        targetValue: "",
      },
      {
        title: "İş Deneyimi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Firma Unvanı",
            isType: "text",
            isValue: "",

          },
          {
            title: "Görev",
            isType: "dropdown",
            isValue: "",
            isOptions: tempDutyForms,
          },
          {
            title: "Sektör",
            isType: "dropdown",
            isValue: "",
            isOptions: tempSectorForms,
          },
          {
            title: "Süre",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
          {
            title: "Ayrılma Nedeni",
            isType: "dropdown",
            isValue: "",
            isOptions: AYRILMA_NEDENI_SEC,
          },
        ],
        key: "isDeneyimi",
        targetValue: "",
      },
      {
        title: "Askerlik Durumu",
        isType: "dropdown", //date, string, number, vs
        isValue: "",
        isPlan: null,
        isOptions: ASKERLIK_DURUMU_SEC,
        key: "askerlikDurumu",
        targetValue: "",
      },
      {
        title: "programBilgisi",
        isType: "group",
        isValue: "",
        isPlan: [{ title: "Program Bilgisi", isType: "dropdown", isOptions: tempProgram },
        { title: "Bilgi Derecesi", isType: "dropdown", isOptions: "Seviye Seç,Yok,Az,Orta,İyi,Çok İyi" }],
        isOptions: "",
        key: "programBilgisi",
        targetValue: "",
      },
      {
        title: "Maaş Beklentisi",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MAAS_BEKLENTISI,
        key: "maasBeklentisi",
        targetValue: "",
      },
    ]
    setPlanObjects(tempInput)
  }

  //Dynamic Inputs END
  const columns = [
    {
      dataField: "DutiesFormId",
      text: props.t("Görev Formu"),
      formatter: (cell, row) =>

        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell?.description}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell?.description}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell?.description}
          </span>
        ),
    },
    {
      dataField: "DutyId",
      text: props.t("Position Name"),
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell?.description}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell?.description}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell?.description}
          </span>
        ),
    },
    {
      dataField: "RequestReason",
      text: props.t("Reason of Request and Number"),
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal" }} className="item-mark">{cell?.description}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal", color: "green" }}>{cell?.description}</span>
        ) : (
          <span
            style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal", fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell?.description}
          </span>
        ),
    },
    {
      dataField: "RequestNumber",
      text: props.t("Talep Edilen Personel Sayısı"),
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal" }} className="item-mark">{cell}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal", color: "green" }}>{cell}</span>
        ) : (
          <span
            style={{ display: "inline-block", wordBreak: "break-word", tableLayout: "fixed", whiteSpace: "normal", fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell}
          </span>
        ),
    },
    {
      dataField: "createdAt",
      text: props.t("Created Date"),
      sort: true,
      formatter: (cell, row) =>

        row.status == "Reddedildi" ? (
          <span className="item-mark">{handleValidDate(cell)}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{handleValidDate(cell)}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {handleValidDate(cell)}
          </span>
        ),
      //formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell}
          </span>
        ),
    },
    {
      dataField: "workType",
      text: props.t("Way Of Work"),
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell}
          </span>
        ),
    },
    // {
    //     dataField: 'CompanyId',
    //     text: props.t("Company Name"),

    //     formatter: (cell, row) =>
    //         row.status == "Reddedildi" ? <span className="item-mark" >
    //             {cell?.name}</span> : row.status == "Oluşturuldu" ? <span style={{ color: "green" }}>{cell?.name}
    //             </span> : <span style={{ fontFamily: "Calibri", fontSize: "16px" }}>{cell?.name}
    //         </span>

    // },
    {
      dataField: "BranchId",
      text: props.t("Unit Name"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell?.name}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell?.name}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell?.name}
          </span>
        ),
    },

    {
      dataField: "UserId",
      text: props.t("Demanding"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) =>
        row.status == "Reddedildi" ? (
          <span className="item-mark">{cell?.name}</span>
        ) : row.status == "Oluşturuldu" ? (
          <span style={{ color: "green" }}>{cell?.name}</span>
        ) : (
          <span
            style={{ fontFamily: "Calibri", fontSize: "16px", color: "orange" }}
          >
            {cell?.name}
          </span>
        ),
    },

    {
      dataField: "planInfo",
      text: props.t("Action"),
      formatter: (cell, row) => (
        <div style={{ position: "relative", right: "27px" }}>
          <Button
            color="success"
            className="btn-rounded "
            onClick={() => {

              setSelectedPlan(row)
              setSelectedWorkType(row.workType)
              setSelectedDuty(row?.DutyId?._id)
              setSelectedBranchId(row.BranchId)
              setSelectedDutiesForm(row.DutiesFormId?._id)

              if (
                isEmpty(row.RequestFormPlanObjects) ||
                row.RequestFormPlanObjects.length == 0
              ) {
                InputAddReset()
              } else {
                setPlanObjects(row?.RequestFormPlanObjects)
                setCriteriaObject(row?.RequestFormCriteriaObjects)

                setPlanDescription(row.RequestFormPlanObjects)
              }
              setIsEdit(true)
              checkToggle()

            }}
          >
            {props.t("Check The Form")}
          </Button>
        </div>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: RequestFormsPlan ? RequestFormsPlan.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: RequestFormsPlan ? RequestFormsPlan.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidPlanSubmit = (e, values) => {
    if (isEdit) {
      const updatePlan = {
        _id: selectedPlan._id,
        //DutyId: values.DutyId,
        DutyId: formDetail?.DutyId,
        description: values["description"],
        CompanyId: user.companyId,
        DutiesFormId: values["DutiesFormId"],
        CreatorUserId: user.uid,
        BranchId: selectedBranchMulti[0]?._id,
        workType: values["workType"],
        PlanObjects: JSON.stringify(formDetail?.PlanObjects),
        // PlanObjects:
        //   //document.getElementById("PlanObjects").value ||
        //   JSON.stringify(criteriaObjects),
        planDesc: document.getElementById("planDesc")?.value || planDescription,
        //PlanObjects:JSON.stringify(planObjects)
        RequestReason:values["RequestReasonId"],
        RequestNumber:values["RequestNumber"]
      }
      // update Plan

      dispatch(onUpdateRequestFormPlan(updatePlan))
    } else {
      const newPlan = {

        //DutyId: values.DutyId,
        DutyId: formDetail?.DutyId,
        description: values["description"],
        CompanyId: user.companyId,
        workType: values["workType"],
        CreatorUserId: user.uid,
        //PlanObjects: JSON.stringify(planObjects),
        PlanObjects: JSON.stringify(formDetail?.PlanObjects),
        CriteriaObjects: JSON.stringify(criteriaObjects),
        //BranchId: BranchId.value,
        BranchId: selectedBranchMulti[0]?._id,
        planDesc: document.getElementById("planDesc").value,
        DutiesFormId: values["DutiesFormId"],
        RequestReason:values["RequestReasonId"],
        RequestNumber:values["RequestNumber"]
      }
      // save new Plan

      dispatch(onAddRequestFormPlan(newPlan))
    }
    toggle()
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }

  let CommentCount = 0


  console.log("selectedPlan",)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Staff Request Form")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Staff Request Form")}
            breadcrumbItem={props.t("PERSONEL TALEP FORMU EKLE")}
            addBtn={authorize([Role.Ik, Role.YetkiliIk]) ? false : true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedPlan({
                name: "",
                description: "",

                PlanObjects: [],
              })
              branchReset()
              InputAddReset()

              toggle()
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={RequestFormsPlan}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={RequestFormsPlan}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={props.t(
                                  "You Don't Have a Request Form Yet"
                                )}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit
                ? props.t("Personel Talep Formu")
                : props.t("Personel Talep Formu")}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidPlanSubmit}>
                <Row form>
                  <Col xs={12}>
                    <input
                      id="PlanObjects"
                      name="hidden"
                      type="hidden"
                    //value={JSON.stringify(planObjects) }
                    />
                    <input id="planDesc" name="hidden" type="hidden" />
                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="DutiesFormId"
                        disabled={props.version == "v2" ? true : false}
                        value={props.selectedDutiesFormId}
                        label={props.t("İlan Çıkılacak Görev Formunu Seçiniz")}
                        onChange={e => {
                          dispatch(onGetDutiesFormById(e.target.value))
                        }}
                      >
                        <option>{props.t("İlan Çıkılacak Görev Formunu Seçiniz")}</option>
                        {DutiesForms?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item?.description}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    {/* <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="DutyId"
                        value={props.selectedDutyId}
                        label={props.t("İlan Çıkılacak Görevi Seçiniz")}
                      >
                        <option>{props.t("İlan Çıkılacak Görevi Seçiniz")}</option>
                        {Duty?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item?.description}
                          </option>
                        ))}
                      </AvField>
                    </div> */}
                    {/* <div className="mb-3">
                      <AvField
                        name="name"
                        label={props.t("Type Requested Position Name")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={selectedPlan?.name || ""}
                      />
                    </div> */}
                     <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="RequestReasonId"
                        disabled={props.version == "v2" ? true : false}
                        value={props.selectedDutiesFormId}
                        label={props.t("Personel Talep Sebebini Seçiniz")}
                       
                      >
                        <option>{props.t("Personel Talep Sebebini Seçiniz")}</option>
                        {RequestReason?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item?.description}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="RequestNumber"
                        disabled={props.version == "v2" ? true : false}
                        value={props.selectedDutiesFormId}
                        label={props.t("Talep Edilen Personel Sayısını Seçiniz")}
                        
                      >
                        <option>{props.t("Talep Edilen Personel Sayısını Seçiniz")}</option>
                        <option>{props.t("1")}</option>
                        <option>{props.t("2")}</option>
                        <option>{props.t("3")}</option>
                        <option>{props.t("4")}</option>
                        <option>{props.t("5")}</option>
                        <option>{props.t("6")}</option>
                        <option>{props.t("7")}</option>
                        <option>{props.t("8")}</option>
                        <option>{props.t("9")}</option>
                        <option>{props.t("10")}</option>
                       
                      </AvField>
                    </div>
                    
                    {/* <div className="mb-3">
                      <AvField
                        name="description"
                        label={props.t(
                          "Type Reason and Number of Staff Request"
                        )}
                        type="textarea"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={selectedPlan?.description || ""}
                      />
                    </div> */}
                    <div className="mb-3 mt-2 ">
                      <b style={{ fontWeight: "500" }}>Birim Seç</b>
                      <Multiselect

                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(event) => { setSelectedBranchMulti(event) }}
                        options={Branches}
                        placeholder="İlan Çıkılacak Birim"
                        emptyRecordMsg="Seçenek Yok"
                        //selectedValues ={[selectedBranchMulti] || ""}
                        singleSelect={true}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="BranchId"
                        value={selectedBranchId}
                        label={props.t("Select Branch")}
                      >
                        <option>{props.t("Select Branch")}</option>
                        {Branches?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>  
                    </div> */}
                    {/* {user?.roles[0].name == "Admin" ?
                                            <div className="mb-3">
                                                <AvField
                                                    className="form-select"
                                                    type="select"
                                                    name="BranchId"
                                                    value={selectedBranchId}
                                                    label={props.t("Select Branch")}>
                                                    <option>{props.t("Select Branch")}</option>
                                                    {Branches?.map((item, index) => (
                                                        <option key={index} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </div>
                                            : <input
                                                id="BranchId"
                                                name="BranchId"
                                                type="hidden"
                                                value={User?.branchId}
                                            />} */}
                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="workType"
                        value={selectedWorkType}
                        label={props.t("Way Of Work")}
                      >
                        <option>{props.t("Select Way Of Work")}</option>
                        <option>{props.t("Full Time")}</option>
                        <option>{props.t("Part Time")}</option>
                        <option>{props.t("Intern")}</option>
                        <option>{props.t("Dönemsel")}</option>
                        <option>{props.t("Geçici")}</option>
                      </AvField>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className="text-end mb-3">
                      <Button
                        color="primary"
                        className="btn-rounded "
                        onClick={() => {
                          toggleInputs()
                        }}
                      >
                        {props.t("Set the Pre-selection Criteria")}
                      </Button>
                    </div>
                  </Col>
                </Row> */}

                {/* <Row>
                  <Col>
                    <div className="text-end">
                    {document.getElementById("PlanObjects")?.value ?(
                    <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  ):(
                    <button type="button" className="btn btn-primary save-user"
                    onClick={() => {
                      toggleInputs()
                    }}>
                    {props.t("Continue")}
                  </button>
                  )}
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <div className="text-end">

                      <button type="submit" className="btn btn-success save-user">
                        {props.t("Save")}
                      </button>


                      &nbsp;&nbsp;
                      <Button
                        onClick={() => { toggle() }

                        }
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={checkModal} toggle={checkToggle} size="lg">
            <ModalHeader toggle={checkToggle} tag="h4">
              {!!isEdit ? props.t("Form") : props.t("Add Request Form")}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidPlanSubmit}>
                <Row form>
                  <Col xs={12}>
                    <input
                      id="PlanObjects"
                      name="hidden"
                      type="hidden"
                    //value={JSON.stringify(planObjects) }
                    />
                    <input id="planDesc" name="hidden" type="hidden" />
                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="DutyId"
                        disabled
                        value={selectedPlan?.DutyId?._id}
                        label={props.t("İlan Çıkılacak Görevi Seçiniz")}
                      >
                        <option>{props.t("İlan Çıkılacak Görevi Seçiniz")}</option>
                        {Duty?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item?.description}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                       
                        name="RequestReasonId" 
                        disabled
                        value={selectedPlan?.RequestReason?._id}
                        label={props.t("Personel Talep Sebebini Seçiniz")}
                       
                      >
                        <option>{props.t("Personel Talep Sebebini Seçiniz")}</option>
                        {RequestReason?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item?.description}
                          </option>
                        ))}
                      </AvField>
                    </div>
                   

                    <div className="mb-3">
                      <AvField
                        name="description"
                        label={props.t("Talep Edilen Personel Sayısı")}
                        type="text"
                        disabled
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={selectedPlan?.RequestNumber || ""}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        type="select"
                        name="BranchId"
                        disabled
                        value={selectedBranchId?._id}
                        label={props.t("Branch")}
                      >
                        <option>{props.t("Select Branch")}</option>
                        {Branches?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>

                    <div className="mb-3">
                      <AvField
                        className="form-select"
                        disabled
                        type="select"
                        name="workType"
                        value={selectedWorkType}
                        label={props.t("Way Of Work")}
                      >
                        <option>{props.t("Select Way Of Work")}</option>
                        <option>{props.t("Full Time")}</option>
                        <option>{props.t("Part Time")}</option>
                        <option>{props.t("Intern")}</option>
                        <option>{props.t("Dönemsel")}</option>
                        <option>{props.t("Geçici")}</option>
                      </AvField>
                    </div>
                    <p style={{ fontWeight: "600" }}>{props.t("Criteria")}</p>
                    <hr />

                    {/* {selectedPlan?.RequestFormPlanObjects?.filter(x=>x.targetValue)?.map((item, i) => ( */}
                    {selectedPlan?.RequestFormPlanObjects?.map((item, i) => (
                      <div key={i}>
                        <input
                          id={"planObjects_" + i + "__isValue"}
                          name={"planObjects[" + i + "].isValue"}
                          type="hidden"
                        />

                        <Row className="mt-2">
                          {item.title === "Cinsiyet" ||
                            item.title === "Medeni Durum" ||
                            item.title === "Askerlik Durumu" ||
                            item.title === "Maaş Beklentisi" ||
                            item.title === "Eğitim Durumu" ? (
                            <Col>
                              <b className="me-1">{item.title}:</b>
                              <span className="badge rounded-pill bg-success font-size-12">
                                {item.targetValue}
                              </span>
                            </Col>
                          ) : item.isType === "yabanciDil" ? (
                            <div>
                              <Col md="2">
                                <b>{item.title}:</b>
                              </Col>
                              {typeof item.isPlan == "object"
                                ? item.isPlan?.map((groupItem, groupIndex) => (
                                  <div
                                    style={{
                                      margin: "5px",
                                      marginLeft: "15px",
                                    }}
                                    className="d-flex flex-flex"
                                    key={groupIndex}
                                  >
                                    <div className="me-1 font-size-14">
                                      <b>{groupItem.title}:</b>
                                    </div>
                                    {groupItem.isType ? (
                                      <div className="me-1">
                                        <span className="badge rounded-pill bg-success font-size-12">
                                          {props.t("Okuma")}:{" "}
                                          {groupItem.isType}
                                        </span>
                                      </div>
                                    ) : null}
                                    {groupItem.Exp ? (
                                      <div className="me-1">
                                        <span className="badge rounded-pill bg-success font-size-12">
                                          {props.t("Yazma")}: {groupItem.Exp}
                                        </span>
                                      </div>
                                    ) : null}
                                    {groupItem.Speak ? (
                                      <div className="me-1">
                                        <span className="badge rounded-pill bg-success font-size-12">
                                          {props.t("Konuşma")}:{" "}
                                          {groupItem.Speak}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                ))
                                : null}
                            </div>
                          ) : (
                            <div>
                              <Col md="2">
                                <b>{props.t(item.title)}:</b>
                              </Col>
                              {typeof item.isPlan == "object"
                                ? item.isPlan?.map((groupItem, groupIndex) => (
                                  <div key={groupIndex}>
                                    <div
                                      style={{
                                        margin: "5px",
                                        marginLeft: "15px",
                                      }}
                                      className="d-flex flex-flex"
                                    >
                                      <div className="me-1 font-size-14">
                                        <b>{props.t(groupItem.title)}:</b>
                                      </div>
                                      {groupItem.isType ? (
                                        <div className="me-1">
                                          <span className="badge rounded-pill bg-success font-size-12">
                                            {" "}
                                            {groupItem.targetValue}
                                          </span>
                                        </div>
                                      ) : null}
                                      {groupItem.Exp ? (
                                        <div className="me-1">
                                          <span className="badge rounded-pill bg-success font-size-12">
                                            {" "}
                                            {groupItem.Exp}
                                          </span>
                                        </div>
                                      ) : null}
                                      {groupItem.Speak ? (
                                        <div className="me-1">
                                          <span className="badge rounded-pill bg-success font-size-12">
                                            {groupItem.Speak}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                ))
                                : null}
                            </div>
                          )}
                        </Row>
                      </div>
                    ))}
                  </Col>
                </Row>

                <hr />

                {!isEmpty(selectedPlan?.actions) ? (
                  selectedPlan?.actions?.map((item, i) => (
                    <div key={"comments" + i}>
                      {item.UserId == user.uid ? (
                        <p style={{ visibility: "hidden" }}>
                          {(CommentCount += 1)}
                        </p>
                      ) : null}
                      <Row className="mt-2">
                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                          <img
                            style={{ height: "60px!important" }}
                            className="rounded-circle header-profile-comment"
                            src={user1}
                            alt="Header Avatar"
                          />
                        </Col>
                        <Col xs="10" sm="10" md="10" lg="10" xl="10">
                          <Col md="12">
                            <b>{item?.UserName}</b>
                            <small> {item?.position}</small>
                          </Col>
                          <Col md="12">
                            <Row>
                              <Col md="8">
                                {" "}
                                <p>{item.decision} </p>
                              </Col>
                              <Col md="3">
                                {" "}
                                <p>{handleValidDate(item.createdAt)}</p>{" "}
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  ))
                ) : null}



                {authorize([Role.Ik, Role.YetkiliIk, Role.Admin]) &&

                  selectedPlan?.status == "Beklemede" ? (
                  <Row>
                    <Col>
                      <div className="text-end">
                        {/* <Button
                          className="btn btn-warning"
                          onClick={() => {
                            toggleInputs()
                          }}
                        >
                          {props.t("Kriterleri Düzenle")}
                        </Button> */}
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-success save-user"
                          onClick={() => {
                            createPlanToggle()
                            checkToggle()
                          }}
                        >
                          {props.t("Create Application")}
                        </button>
                        &nbsp;&nbsp;
                        <Button
                          className="btn btn-danger"
                          onClick={() => {
                            const updateStatusPlan = {
                              _id: selectedPlan._id,

                              status: "Reddedildi",
                            }
                            // update Plan

                            dispatch(onUpdateStatus(updateStatusPlan))

                            checkToggle()
                          }}
                        >
                          {props.t("Reject")}
                        </Button>


                      </div>
                    </Col>
                  </Row>
                ) : selectedPlan?.status == "Beklemede" ? (
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn btn-success save-user"
                          onClick={() => {
                            let actions = [
                              {
                                UserId: user.uid,
                                UserName: user.userName,
                                decision: "Onaylandı",
                              },
                            ]
                            const updatePlan = {
                              _id: selectedPlan._id,

                              actions: JSON.stringify(actions),
                            }
                            // update Plan

                            dispatch(onUpdateActions(updatePlan))
                            checkToggle()
                          }}
                        >
                          {props.t("Approve")}
                        </button>
                        &nbsp;&nbsp;
                        <Button
                          className="btn btn-danger"
                          onClick={() => {
                            let actions = [
                              {
                                UserId: user.uid,
                                UserName: user.userName,
                                decision: "Reddedildi",
                              },
                            ]
                            const updatePlan = {
                              _id: selectedPlan._id,

                              actions: JSON.stringify(actions),
                            }
                            // update Plan

                            dispatch(onUpdateActions(updatePlan))
                            checkToggle()
                          }}
                        >
                          {props.t("Reject")}
                        </Button>


                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="text-end">
                    <Button
                      className="btn btn-secondary"
                      onClick={() => {
                        checkToggle()
                      }}
                    >
                      {props.t("Cancel")}
                    </Button>
                  </div>
                )}
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalInputs} toggle={toggleInputs} size="lg">
            <ModalHeader toggle={toggleInputs} tag="h4">
              {props.t("Add Job Application Plan")}
            </ModalHeader>
            <ModalBody >
              <small className="mb-3">
                {props.t("Please Enter Job Application Plan")}
              </small>
              <br /> <br />
              <AvForm>
                <Col md="4" className="mb-1">
                  {props.t("Description")}
                </Col>
                <Col md="12" className="mb-3">
                  <AvField
                    type="textarea"
                    name="planDesc"
                    placeholder={props.t("Description")}
                    value={props.selectedPlan?.planDesc || ""}
                    onChange={e => {
                      setPlanDescription(e.target.value)
                    }}
                    validate={{
                      required: { value: false },
                    }}
                  />
                </Col>
                <Col md="4" className="mb-1">
                  {props.t("Default Infos")}
                </Col>
                <Row>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Photo")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("First Name")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Last Name")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Email")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Phone Number")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Birthday")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="mb-2">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Identitiy Number")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                  <Col md="4" className="mb-4">
                    <AvField
                      type="text"
                      id={"deneme"}
                      name={"deneme"}
                      placeholder={props.t("Address")}
                      value={""}
                      disabled
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </Col>
                </Row>
                <p>Aşağıdaki sorulardan personel başvuru formunda sormak istemediklerinizi lütfen siliniz</p>
                <p>Ön eleme kriteri eklemek için lütfen modüllerden kriterlerini seçiniz</p>
                <Row style={{ position: "relative", top: "18px", left: "20px" }}>
                  <Col className="col-4"><b style={{ fontWeight: "600" }}>Başlık</b></Col>
                  <Col className="col-4"><b style={{ fontWeight: "600" }}>Ön Eleme Kriteri</b></Col>
                </Row>
                <Row form>
                  <Col className="col-12">
                    {planObjects?.map((item, i) => (
                      <div key={i} style={{
                        border: "1px solid white", borderRadius: "5px",
                        backgroundColor: item.key == "cinsiyet" ? isEmpty(item.targetValue) || item.targetValue == "Cinsiyet Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                          item.key == "medeniDurum" ? isEmpty(item.targetValue) || item.targetValue == "Medeni Durum Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                            item.key == "maasBeklentisi" ? isEmpty(item.targetValue) || item.targetValue == "Maaş Beklentisi Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                              item.key == "egitimDurumu" ? isEmpty(item.targetValue) || item.targetValue == "Eğitim Durumu Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                                item.key == "askerlikDurumu" ? isEmpty(item.targetValue) || item.targetValue == "Askerlik Durumu Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                                  item.key == "ehliyet" ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Ehliyet Bilgisi Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                                    item.key == "isDeneyimi" ? !isEmpty(item.isPlan) ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Görev Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                      item.key == "yabanciDil" ? !isEmpty(item.isPlan) ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Dil Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                        item.key == "programBilgisi" ? !isEmpty(item.isPlan) ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Program Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                          null

                      }} className="mt-4 mb-4">

                        <input
                          id={"planObjects_hidden" + i + "__isValue"}
                          name={"planObjects_hidden[" + i + "].isValue"}
                          type="hidden"
                        />

                        <Row className="dropdown-plan-item-pos mt-2 mb-2" >
                          <Col md="4" style={{ display: "flex", alignItems: "center" }} >
                            {props.t(item.title)}

                          </Col>

                          {item.key === "yabanciDil" ? (
                            <Col md="4">
                              {isEmpty(planObjects[i].isPlan) ? (
                                <Button
                                  onClick={() => {
                                    handleAddGroupFirst(
                                      i,
                                      "Dil",
                                      "dropdown",
                                      tempForeignLanguages
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Okuma",
                                      "dropdown",
                                      SEVIYE_SEC
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Yazma",
                                      "dropdown",
                                      SEVIYE_SEC
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Konuşma",
                                      "dropdown",
                                      SEVIYE_SEC
                                    )
                                    setIsGroupButton(
                                      isGroupButton ? { ...isGroupButton } : null,
                                      planObjects[i].isPlan
                                    )
                                  }}
                                  className="btn btn-warning"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}
                          {item.key === "isDeneyimi" ? (
                            <Col md="4">
                              {isEmpty(planObjects[i].isPlan) ? (
                                <Button
                                  onClick={() => {
                                    //handleAddGroupFirst
                                    handleAddGroupFirst(
                                      i,
                                      "Firma Unvanı",
                                      "text"
                                    )
                                    handleAddGroupFirst(
                                      i,
                                      "Görev",
                                      "dropdown",
                                      tempDutyForms
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Sektör",
                                      "dropdown",
                                      tempSectorForms
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Süre",
                                      "dropdown",
                                      SURE_SEC_YIL
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Ayrılma Nedeni",
                                      "dropdown",
                                      AYRILMA_NEDENI_SEC
                                    )
                                    handleAddGroupFirst(
                                      i,
                                      "Giriş Tarihi",
                                      "date"
                                    )
                                    handleAddGroupFirst(
                                      i,
                                      "Çıkış Tarihi",
                                      "date"
                                    )


                                    setIsGroupButton(
                                      isGroupButton ? { ...isGroupButton } : null,
                                      planObjects[i].isPlan
                                    )
                                  }}
                                  className="btn btn-warning"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}

                          {item.key === "programBilgisi" ? (
                            <Col md="4">
                              {isEmpty(planObjects[i].isPlan) ? (
                                <Button
                                  onClick={() => {
                                    handleAddGroupFirst(
                                      i,
                                      "Program Bilgisi",
                                      "dropdown",
                                      tempProgram
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Bilgi Derecesi",
                                      "dropdown",
                                      SEVIYE_SEC
                                    )

                                    setIsGroupButton(
                                      isGroupButton ? { ...isGroupButton } : null,
                                      planObjects[i].isPlan
                                    )

                                  }}
                                  className="btn btn-warning"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}
                          {item.key === "ehliyet" ? (

                            <Col md="4">

                              {isEmpty(planObjects[i].isPlan) ? (
                                <Button
                                  onClick={() => {
                                    handleAddGroupFirst(
                                      i,
                                      "Ehliyet Bilgisi",
                                      "dropdown",
                                      EHLIYET_BILGISI_SEC
                                    )
                                    handleAddGroup_AddGroup(
                                      i,
                                      "Süre",
                                      "dropdown",
                                      SURE_SEC_YIL
                                    )
                                    setIsGroupButton(
                                      isGroupButton ? { ...isGroupButton } : null,
                                      planObjects[i].isPlan
                                    )
                                  }}
                                  className="btn btn-warning"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}
                          {item.key === "cinsiyet" ? (
                            <Col md="4" >
                              <Row>
                                <Col md="12">
                                  <AvField
                                    type="select"
                                    id={"planObjects_cinsiyet" + i + "__isType"}
                                    name={
                                      "planObjects_cinsiyet[" + i + "].isType"
                                    }
                                    value={item.targetValue}
                                    className="form-select"
                                    onChange={e =>
                                      handleChangeCriteria(
                                        i,
                                        e,
                                        "targetValue",
                                        "cinsiyet",
                                        "Cinsiyet",
                                        "dropdown",
                                        CINSIYET_SEC
                                      )
                                    }
                                  >
                                    {CINSIYET_SEC?.split(",").map((itm, idx) => (
                                      <option key={idx}>{props.t(itm)}</option>
                                    ))}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {item.key === "medeniDurum" ? (
                            <Col md="4">
                              <Row>
                                <Col md="12">
                                  <AvField
                                    type="select"
                                    id={"planObjects_medeni" + i + "__isType"}
                                    name={"planObjects_medeni[" + i + "].isType"}
                                    value={item.targetValue}
                                    className="form-select"
                                    onChange={e =>
                                      handleChangeCriteria(
                                        i,
                                        e,
                                        "targetValue",
                                        "medeniDurum",
                                        "Medeni Durum",
                                        "dropdown",
                                        MEDENI_DURUM_SEC
                                      )
                                    }
                                  >
                                    {MEDENI_DURUM_SEC?.split(",").map(
                                      (itm, idx) => (
                                        <option key={idx}>{props.t(itm)}</option>
                                      )
                                    )}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {item.key === "egitimDurumu" ? (
                            <Col md="4">
                              <Row>
                                <Col md="12">

                                  <AvField
                                    type="select"
                                    id={"planObjects_egitim" + i + "__isType"}
                                    name={"planObjects_egitim[" + i + "].isType"}
                                    value={item.targetValue}
                                    className="form-select"
                                    onChange={e =>
                                      handleChangeCriteria(
                                        i,
                                        e,
                                        "targetValue",
                                        "egitimDurumu",
                                        "Eğitim Durumu",
                                        "dropdown",
                                        EGITIM_DURUMU_SEC
                                      )
                                    }
                                  >
                                    {EGITIM_DURUMU_SEC?.split(",").map(
                                      (itm, idx) => (
                                        <option key={idx}>{props.t(itm)}</option>
                                      )
                                    )}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {item.key === "askerlikDurumu" ? (
                            <Col md="4">
                              <Row>
                                <Col md="12">
                                  <AvField
                                    type="select"
                                    id={"planObjects_askerlik" + i + "__isType"}
                                    name={
                                      "planObjects_askerlik[" + i + "].isType"
                                    }
                                    value={item.targetValue}
                                    className="form-select"
                                    onChange={e =>
                                      handleChangeCriteria(
                                        i,
                                        e,
                                        "targetValue",
                                        "askerlikDurumu",
                                        "Askerlik Durumu",
                                        "dropdown",
                                        ASKERLIK_DURUMU_SEC
                                      )
                                    }
                                  >
                                    {ASKERLIK_DURUMU_SEC?.split(",").map(
                                      (itm, idx) => (
                                        <option key={idx}>{props.t(itm)}</option>
                                      )
                                    )}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {item.key === "maasBeklentisi" ? (
                            <Col md="4">
                              <Row>
                                <Col md="12">
                                  <AvField
                                    type="select"
                                    id={"planObjects_askerlik" + i + "__isType"}
                                    name={
                                      "planObjects_askerlik[" + i + "].isType"
                                    }
                                    value={item.targetValue}
                                    className="form-select"
                                    onChange={e =>
                                      handleChangeCriteria(
                                        i,
                                        e,
                                        "targetValue",
                                        "maasBeklentisi",
                                        "Maaş Beklentisi",
                                        "dropdown",
                                        MAAS_BEKLENTISI
                                      )
                                    }
                                  >
                                    {MAAS_BEKLENTISI?.split(",").map(
                                      (itm, idx) => (
                                        <option key={idx}>{props.t(itm)}</option>
                                      )
                                    )}
                                  </AvField>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          <Col md="3" sm="6" xs="6" style={{ position: "relative", textAlign: "end" }}>
                            <div>
                              {planObjects.length > 1 && i != 0 ? (
                                <Button
                                  onClick={() => handleOnDragSocialMediasEnd_up(i)}
                                  className="btn btn-success me-2"
                                >
                                  <i className="bx bx-up-arrow"></i>
                                </Button>
                              ) : null}

                              {planObjects.length != (i + 1) ? (
                                <Button onClick={() => handleOnDragSocialMediasEnd_down(i)}
                                  className="btn btn-info me-2">
                                  <i className="bx bx-down-arrow"></i>
                                </Button>
                              ) : null}

                            </div>
                          </Col>
                          <Col md="1" sm="6" xs="6" className="items-margin" style={{ position: "relative", top: "2px", right: "24px" }}>
                            <div>
                              {/* <Button 
                            onClick={() => handleAdd(i)}
                            className="btn btn-success me-2"
                          >
                            <i className="fas fa-plus"></i>
                          </Button> */}
                              {planObjects.length > 0 ? (
                                <Button style={{ position: "relative", top: "2px" }}
                                  onClick={() => handleSubtract(i, item)}
                                  className="btn btn-danger"
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                                // <a onClick={() => handleSubtract(i)}>
                                //   <i className="bx bx-trash" style={{ color: "red", fontSize: "24px" }}></i>
                                // </a>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        {item.key === "programBilgisi" ? (
                          <div>
                            {typeof item.isPlan == "object"
                              ? item.isPlan?.map((groupItem, groupIndex) => (
                                <div key={groupIndex}>
                                  <Row
                                    className="group-item-pos"
                                  >
                                    <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                      {props.t(groupItem.title)}
                                    </Col>
                                    <Col md="4" style={{ position: "relative", right: "5px" }}>
                                      {groupItem?.title == "Bilgi Derecesi" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program_sure" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "__isType"
                                          }
                                          name={
                                            "planObjects_program_sure[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].isType"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>

                                            handleChangeInputNameForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "programBilgisi",
                                              "group",
                                              SEVIYE_SEC,
                                              "Bilgi Derecesi"
                                            )
                                          }
                                        >
                                          {SEVIYE_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "programBilgisi",
                                              "group",
                                              tempProgram
                                            )
                                          }
                                        >
                                          {" "}
                                          {tempProgram?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      )}
                                    </Col>

                                    <Col md="1">
                                      {groupIndex % 2 == 0 ? (
                                        <Button
                                          onClick={() => {
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Program Bilgisi",
                                              "dropdown",
                                              tempProgram
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Bilgi Derecesi",
                                              "dropdown",
                                              SEVIYE_SEC
                                            )

                                          }}
                                          className="btn btn-warning"
                                        >
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                    <Col md="1" style={{ position: "relative", right: "19px" }}>
                                      {groupIndex % 2 == 0 && item.isPlan.length != 2 ? (
                                        <Button
                                          className="text-danger"
                                          onClick={() => {
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            {
                                              isEmpty(planObjects[i].isPlan)
                                                ? null
                                                : setIsGroupButton(
                                                  planObjects[i].isPlan
                                                )
                                            }
                                          }}
                                        >
                                          <i className="fas fa-minus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                              : null}
                          </div>
                        ) : null}
                        {item.key === "ehliyet" ? (
                          <div>
                            {typeof item.isPlan == "object"
                              ? item.isPlan?.map((groupItem, groupIndex) => (
                                <div key={groupIndex}>
                                  <Row className="group-item-pos"

                                  >
                                    <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                      {props.t(groupItem.title)}
                                    </Col>
                                    <Col md="4">
                                      {groupItem?.title == "Ehliyet Bilgisi" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program_sure" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "__isType"
                                          }
                                          name={
                                            "planObjects_program_sure[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].isType"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputNameForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "ehliyet",
                                              "group",
                                              EHLIYET_BILGISI_SEC
                                            )
                                          }
                                        >
                                          {EHLIYET_BILGISI_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "ehliyet",
                                              "group",
                                              SURE_SEC_YIL,
                                              "Süre"
                                            )
                                          }
                                        >
                                          {SURE_SEC_YIL?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      )}
                                    </Col>

                                    <Col md="1">
                                      {groupIndex % 2 == 0 ? (
                                        <Button
                                          onClick={() => {
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Ehliyet Bilgisi",
                                              "dropdown",
                                              EHLIYET_BILGISI_SEC
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Süre",
                                              "dropdown",
                                              SURE_SEC_YIL
                                            )
                                          }}
                                          className="btn btn-warning"
                                        >
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                    <Col md="1" style={{ position: "relative", right: "19px" }}>

                                      {groupIndex % 2 == 0 && item.isPlan.length != 2 ? (
                                        <Button
                                          className="text-danger"
                                          onClick={() => {
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            {
                                              isEmpty(planObjects[i].isPlan)
                                                ? null
                                                : setIsGroupButton(
                                                  planObjects[i].isPlan
                                                )
                                            }
                                          }}
                                        >
                                          <i className="fas fa-minus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                              : null}
                          </div>
                        ) : null}
                        {item.key === "isDeneyimi" ? (
                          <div>
                            {typeof item.isPlan == "object"
                              ? item.isPlan?.map((groupItem, groupIndex) => (

                                <div key={groupIndex}>

                                  <Row
                                    className="group-item-pos"
                                  >
                                    <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                      {groupItem.title == "Ayrılma Nedeni" || groupItem.title == "Giriş Tarihi" || groupItem.title == "Çıkış Tarihi" ? null : props.t(groupItem.title)}

                                    </Col>
                                    <Col md="4">
                                      {groupItem?.title == "Görev" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "isDeneyimi",
                                              "group",
                                              tempDutyForms,
                                              "Görev"
                                            )
                                          }
                                        >
                                          {tempDutyForms?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : groupItem?.title == "Sektör" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program_sure" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "__isType"
                                          }
                                          name={
                                            "planObjects_program_sure[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].isType"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputNameForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "isDeneyimi",
                                              "group",
                                              tempSectorForms
                                            )
                                          }
                                        >
                                          {tempSectorForms?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : groupItem?.title == "Süre" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "isDeneyimi",
                                              "group",
                                              SURE_SEC_YIL,
                                              "Süre"
                                            )
                                          }
                                        >
                                          {SURE_SEC_YIL?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : (
                                        <AvField
                                          type="hidden"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={" "}
                                          className="form-select"
                                          disabled

                                        >
                                          {AYRILMA_NEDENI_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      )}
                                    </Col>

                                    <Col md="1">
                                      {groupIndex % 7 == 0 ? (
                                        <Button
                                          onClick={() => {

                                            handleAddGroup_AddGroup(
                                              i,
                                              "Firma Unvanı",
                                              "text"

                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Görev",
                                              "dropdown",
                                              tempDutyForms
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Sektör",
                                              "dropdown",
                                              tempSectorForms
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Süre",
                                              "dropdown",
                                              SURE_SEC_YIL
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Ayrılma Nedeni",
                                              "dropdown",
                                              AYRILMA_NEDENI_SEC
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Giriş Tarihi",
                                              "date"
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Çıkış Tarihi",
                                              "date"
                                            )
                                          }}
                                          className="btn btn-warning"
                                        >
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                    <Col md="1" style={{ position: "relative", right: "19px" }}>
                                      {groupIndex % 7 == 0 && item.isPlan.length != 7 ? (
                                        <Button
                                          className="text-danger"
                                          onClick={() => {
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )

                                            {
                                              isEmpty(planObjects[i].isPlan)
                                                ? null
                                                : setIsGroupButton(
                                                  planObjects[i].isPlan
                                                )
                                            }
                                          }}
                                        >
                                          <i className="fas fa-minus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                              : null}
                          </div>
                        ) : null}
                        {item.key === "yabanciDil" ? (
                          <div>
                            {typeof item.isPlan == "object"
                              ? item.isPlan?.map((groupItem, groupIndex) => (
                                <div key={groupIndex}>
                                  <Row
                                    className="group-item-pos"
                                  >
                                    <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                      {props.t(groupItem.title)}
                                    </Col>
                                    <Col md="4">

                                      {groupItem?.title == "Dil" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program_sure" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "__isType"
                                          }
                                          name={
                                            "planObjects_program_sure[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].isType"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputNameForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "yabanciDil",
                                              "group",
                                              tempForeignLanguages
                                            )
                                          }
                                        >
                                          {tempForeignLanguages?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : groupItem?.title == "Okuma" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "yabanciDil",
                                              "group",
                                              SEVIYE_SEC,
                                              "Okuma Seviyesi"
                                            )
                                          }
                                        >
                                          {SEVIYE_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : groupItem?.title == "Yazma" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "yabanciDil",
                                              "group",
                                              SEVIYE_SEC,
                                              "Yazma Seviyesi"
                                            )
                                          }
                                        >
                                          {SEVIYE_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : groupItem?.title == "Konuşma" ? (
                                        <AvField
                                          type="select"
                                          id={
                                            "planObjects_program" +
                                            i +
                                            "_" +
                                            groupIndex +
                                            "title"
                                          }
                                          name={
                                            "planObjects_program[" +
                                            i +
                                            "][" +
                                            groupIndex +
                                            "].title"
                                          }
                                          value={groupItem.targetValue}
                                          className="form-select"
                                          onChange={e =>
                                            handleChangeInputForGroup(
                                              i,
                                              groupIndex,
                                              e,
                                              "yabanciDil",
                                              "group",
                                              SEVIYE_SEC,
                                              "Konuşma Seviyesi"
                                            )
                                          }
                                        >
                                          {SEVIYE_SEC?.split(",").map(
                                            (itm, idx) => (
                                              <option key={idx}>
                                                {props.t(itm)}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      ) : null}
                                    </Col>

                                    <Col md="1">
                                      {groupIndex % 4 == 0 ? (
                                        <Button
                                          onClick={() => {
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Dil",
                                              "dropdown",
                                              tempForeignLanguages
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Okuma",
                                              "dropdown",
                                              SEVIYE_SEC
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Yazma",
                                              "dropdown",
                                              SEVIYE_SEC
                                            )
                                            handleAddGroup_AddGroup(
                                              i,
                                              "Konuşma",
                                              "dropdown",
                                              SEVIYE_SEC
                                            )
                                          }}
                                          className="btn btn-warning"
                                        >
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                    <Col md="1" style={{ position: "relative", right: "19px" }}>
                                      {groupIndex % 4 == 0 && item.isPlan.length != 4 ? (
                                        <Button
                                          className="text-danger"
                                          onClick={() => {
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            handleSubtractForGroup(
                                              i,
                                              groupIndex
                                            )
                                            {
                                              isEmpty(planObjects[i].isPlan)
                                                ? null
                                                : setIsGroupButton(
                                                  planObjects[i].isPlan
                                                )
                                            }
                                          }}
                                        >
                                          <i className="fas fa-minus"></i>
                                        </Button>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                              : null}
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>


                </Row>

                <Row>
                  <Col>
                    <div className="text-end">

                      <Button
                        onClick={() => {
                          document.getElementById("PlanObjects").value =
                            JSON.stringify(planObjects)

                          if (planDescription == undefined) {
                            document.getElementById("planDesc").value = ""
                          } else {
                            document.getElementById("planDesc").value =
                              planDescription
                          }

                          toggleInputs()
                        }}
                        className="btn btn-success save-user"
                      >
                        {props.t("Save")}
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggleInputs()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                      &nbsp;&nbsp;
                      <Button className="btn btn-warning save-user"
                        disabled={isEmpty(deletedItems) ? true : false}
                        onClick={() => {
                          const array1 = [...planObjects];
                          const array2 = [...deletedItems];
                          const array3 = array1.concat(array2);
                          setPlanObjects(array3)
                          setDeletedItem([])
                        }}
                      >
                        {props.t("Modül Ekle")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          {console.log(">>selectedDutiesForm", selectedDutiesForm)}
          <ApplicationPlanModal
            modal={createPlanModal}
            isEdit={false}
            selectedPlan={selectedPlan}
            selectedBranchId={selectedBranchId}
            selectedWorkType={selectedWorkType}
            planObjects={planObjects}
            foreignLang={tempForeignLanguages}
            dutyForm={tempDutyForms}
            sectorForm={tempSectorForms}
            program={tempProgram}
            selectedDutyId={selectedDuty}
            selectedDutiesFormId={selectedDutiesForm}

            //planObjects={criteriaObjects}
            version={"v2"}

            toggle={() => {
              createPlanToggle()
            }} 

            payment={e => {
              if (e == true) {
                const updateStatusPlan = {
                  _id: selectedPlan._id,

                  status: "Oluşturuldu",
                }
                // update Plan

                dispatch(onUpdateStatus(updateStatusPlan))

                createPlanToggle()
                //setModal(false)
                
              }
            }}
          />
          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeleteRequestFormPlan(selectedId))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(RequestForm))
