import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Form,
  Input, Modal, ModalHeader, ModalBody, Button

} from "reactstrap"

import InputMask from "react-input-mask"
import imgEmpty from "./../../assets/images/empty.png"
// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/bu_qrda_is_var_1.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import CarouselPage from "./CarouselPage"
import Dropzone from "react-dropzone"
//i18n 
import { withTranslation } from "react-i18next"

// action
import {
  registerUser, apiError, registerSuccessReset, register3User,
  getSectors as onGetSectors,
  getDevDutys as onGetDevDuties,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import { isEmpty } from "lodash"

const Register = props => {
  const dispatch = useDispatch()
  const [country, setCountry] = useState(false)
  const [region, setRegion] = useState(false)
  const [city, setCity] = useState(false)
  const [district, setDistrict] = useState(false)
  const [neighbourhood, setNeighbourhood] = useState(false)
  const [semt, setSemt] = useState(false)
  const [modal, setModal] = useState(false);
  const [street, setStreet] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [duties, setDuties] = useState([{
    Duty: "", desc: ""
  },
  ])

  const { user, registrationError, loading, registerSuccess, Sectors, DevDuties } = useSelector(state => ({
    user: state.Account.user,
    registerSuccess: state.Account.registerSuccess3,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
    Sectors: state.Sector?.data,
    DevDuties: state.DevDuty?.data

  }))
  const {
    match: { params },
  } = props
  // handleValidSubmit
  const handleValidSubmit = values => {
    for(let i =0;i<duties.length;i++){ 
      const registerModel = {
      _id: params.id,
      sector: values.sector,
      name:duties[i]?.Duty,
      description:duties[i]?.desc
      
    }
    console.log(">>>>>>>>>>",registerModel)
   dispatch(register3User(registerModel, selectedFiles))
    }
    
 
  }
  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    dispatch(apiError(""))
    dispatch(onGetSectors())
    dispatch(onGetDevDuties());
  }, [])
  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }
  const handleSubtract = (i) => {
    const values = [...duties]
    values.splice(i, 1)
    setDuties([...values])
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const handleAdd = (grade) => {
    setDuties([...duties, { Duty: "", desc: "" }])
  }
  const handleChangeInput = (i, e) => {
    const values = [...duties]
    values[i].Duty = e.target.value
    setDuties(values)
  }
  const handleChangeDesc = (i, e) => {
    const values = [...duties]
    values[i].desc = e.target.value
    setDuties(values)
  }
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Kayıt Ol |Bu QR'da İş Var</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content  p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    {/* <div >
                      <Link to="dashboard" className="d-block auth-logo">
                        <img 
                         src={logodark}
                          alt=""
                          height="140"
                          className="auth-logo-dark me-4"
                          style={{position:"relative", left:"-13px"}}
                        
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="225"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div> */}
                    <div className="my-auto">
                    {user && user ? (
                      <div className="alert alert-success h3 text-center">
                      {props.t("Kayıt Olma Başarılı")}
                      <br />
                      <i className="fa fa-check"></i>
                      <hr />
                      <Link 
                      onClick={()=>{
                        // window.open("http://test.buqrdaisvar.com/login","_self")
                        window.open(`${process.env.REACT_APP_DOMAIN}/login` + UserCompanyId, "_self")
                      }}
                      className="font-size-13">{props.t("Continue")}</Link>
                    </div>
                              

                              ) :  <div >
                              <h5 className="text-primary">Ücresiz Kayıt</h5>
    
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                  handleValidSubmit(v)
                                }}
                                autoComplete="off"
                              >
                                <Row form>
    
                                  
    
                                  {registrationError && registrationError ? (
                                    <Alert color="danger">{registrationError}</Alert>
                                  ) : null}
    
                                  <div className="mb-3">
                                    <AvField
                                      className="form-select"
                                      type="select"
                                      name="sector"
                                      value={""}
                                      label={props.t("Sector Type")}
                                    >
                                      <option>{props.t("Sector Type")}</option>
                                      {Sectors?.map((item, index) => (
                                        <option key={index} value={item._id} >{item.name}</option>
                                      ))}
                                    </AvField>
                                  </div>
                                  <p style={{ fontSize: "13px", fontWeight: "500" }} className="mt-3 mb-2">Şirketiniz Bünyesinde Alım Yapılması Planlanan Görev ve Pozisyonlar</p>
                                  <b style={{ fontSize: "13px", fontWeight: "500" }} className="mt-3 mb-2 me-3">Görev Ekle</b> <Button
                                    className="btn btn-success save-user me-3" onClick={() => { toggle() }}>
                                    <i className="fa fa-plus"></i>
                                  </Button>
                                  {console.log("duties", duties)}
                                  {duties?.map((item, i) => (
                                    <div key={i} className="mt-3">
                                      {isEmpty(item?.desc) ? null : (
                                        <p style={{fontSize:"16px"}}>{i + 1}.{item.desc}</p>
                                      )}
    
                                    </div>
                                  ))}
    
    
    
    
    
    
                                  {/* <div className="mb-3">
                                       <AvField
                                           name="qrCode"
                                           label={props.t("Write Your Sticker Code If You Have")}
                                           type="text"
                                           errorMessage={props.t("InvalidInput")}
                                           validate={{
                                               required: { value: false },
                                           }}
                                          
                                       />
                                   </div> */}
    
    
    
                                  <Row className="mt-3">
                                    <Col md="5">
                                      <div className="mt-1">
                                        <button
                                          className="btn btn-primary btn-block "
                                          type="submit"
                                        >
                                          Kaydet
                                        </button>
                                      </div>
                                    </Col>
                                    <Col md="7">
                                      <div className="mt-1">
                                        <p>
                                          Daha önce kayıt oldunuz mu?{""}
                                          <Link
                                            onClick={()=>{
                                              //window.open("http://test.buqrdaisvar.com/login","_self")
                                              window.open(`${process.env.REACT_APP_DOMAIN}/login`, "_self")
                                            }}
                                            className="font-weight-medium text-primary"
                                          >
                                            {""}
                                            Giriş Yap
                                          </Link>{" "}
                                        </p>
    
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              </AvForm>
    
    
                            </div>}
                     

                        



                      


                    </div>

                    <Modal isOpen={modal} size="lg" toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {props.t("Add Duty")}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm  >
                          <Row form>
                            <Col xs={12}>
                              {duties?.map((item, i) => (
                                <Row key={"modal"+i}>
                                  <Col className="4">
                                    <div className="mb-3">
                                      <AvField type="select" className="form-select" name="name" label="Görev Seç" id="name" value={duties[i]?.Duty}
                                        onChange={e => handleChangeInput(i, e)}>
                                        <option>Görev Seç</option>

                                        {DevDuties?.map((item, index) => (
                                          <option key={index} value={item._id} >{item.name}</option>
                                        ))}
                                      </AvField>
                                    </div>
                                  </Col>
                                  <Col className="4">
                                    <div className="mb-3">
                                      <AvField
                                        name="description"
                                        label={props.t("Firmadaki Pozisyon Tanımı")}
                                        type="text"
                                        errorMessage={props.t("InvalidInput")}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={duties[i]?.desc}
                                        onChange={e => handleChangeDesc(i, e,)}
                                      />
                                    </div>
                                  </Col>

                                  <Col md="4" style={{ position: "relative", top: "26px" }}>
                                    {i == duties.length - 1 ?
                                      <div>
                                        <Button onClick={() => handleAdd(item.grade)} className="btn btn-success me-2">
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                        {duties.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                                          <i className="fas fa-minus"></i>
                                        </Button> : null}
                                      </div> : null
                                    }
                                  </Col>



                                </Row>
                              ))}





                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-success save-user"
                                  onClick={() => {
                                    toggle()
                                  }}
                                >
                                  {props.t("Save")}
                                </button>
                                &nbsp;&nbsp;
                                <Button onClick={() => { toggle() }}>
                                  {props.t("Cancel")}
                                </Button>

                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withTranslation()(Register))
