/** Get DUTIES_FORM */
export const GET_DUTIES_FORMS = "GET_DUTIES_FORMS";
export const GET_DUTIES_FORMS_SUCCESS = "GET_DUTIES_FORMS_SUCCESS";
export const GET_DUTIES_FORMS_FAIL = "GET_DUTIES_FORMS_FAIL";

export const GET_DUTIES_FORM_DETAIL = "GET_DUTIES_FORM_DETAIL";
export const GET_DUTIES_FORM_DETAIL_SUCCESS = "GET_DUTIES_FORM_DETAIL_SUCCESS";
export const GET_DUTIES_FORM_DETAIL_FAIL = "GET_DUTIES_FORM_DETAIL_FAIL";


export const ADD_DUTIES_FORM = "ADD_DUTIES_FORM";
export const ADD_DUTIES_FORM_SUCCESS = "ADD_DUTIES_FORM_SUCCESS";
export const ADD_DUTIES_FORM_FAIL = "ADD_DUTIES_FORM_FAIL";

export const UPDATE_DUTIES_FORM = "UPDATE_DUTIES_FORM";
export const UPDATE_DUTIES_FORM_SUCCESS = "UPDATE_DUTIES_FORM_SUCCESS";
export const UPDATE_DUTIES_FORM_FAIL = "UPDATE_DUTIES_FORM_FAIL";

export const DELETE_DUTIES_FORM = "DELETE_DUTIES_FORM";
export const DELETE_DUTIES_FORM_SUCCESS = "DELETE_DUTIES_FORM_SUCCESS";
export const DELETE_DUTIES_FORM_FAIL = "DELETE_DUTIES_FORM_FAIL"; 



export const RESET_DUTIES_FORM= "RESET_DUTIES_FORM";