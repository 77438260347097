export const GET_ANALYTICS = "GET_ANALYTICS";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAIL = "GET_ANALYTICS_FAIL"; 
export const RESET_ANALYTICS = "RESET_ANALYTICS"; 

export const GET_MY_FORMS_LENGTH = "GET_MY_FORMS_LENGTH";
export const GET_MY_FORMS_LENGTH_SUCCESS = "GET_MY_FORMS_LENGTH_SUCCESS"; 
export const GET_MY_FORMS_LENGTH_FAIL = "GET_MY_FORMS_LENGTH_FAIL"; 



export const GET_ANALYTIC_BY_LINK= "GET_ANALYTIC_BY_LINK";
export const GET_ANALYTIC_BY_LINK_SUCCESS = "GET_ANALYTIC_BY_LINK_SUCCESS"; 
export const GET_ANALYTIC_BY_LINK_FAIL = "GET_ANALYTIC_BY_LINK_FAIL"; 