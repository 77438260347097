import {
    GET_SURVEYS,
    GET_SURVEYS_SUCCESS,
    GET_SURVEYS_FAIL,
    GET_SURVEY_DETAIL,
    GET_SURVEY_DETAIL_SUCCESS,
    GET_SURVEY_DETAIL_FAIL,
    ADD_SURVEY,
    ADD_SURVEY_SUCCESS,
    ADD_SURVEY_FAIL,
    UPDATE_SURVEY,
    UPDATE_SURVEY_SUCCESS,
    UPDATE_SURVEY_FAIL,
    DELETE_SURVEY,
    DELETE_SURVEY_SUCCESS,
    DELETE_SURVEY_FAIL,
    RESET_SURVEY,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    mySurvey:[],
    success:null,
};

const Surveys = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SURVEY_DETAIL_SUCCESS:
            return {
                ...state,
                mySurvey: action.payload.data
            }
        case GET_SURVEYS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SURVEY_SUCCESS:
            return {
                ...state,
                data: state.data.filter(survey => survey._id.toString() !== action.payload.data.toString())
            }
        case ADD_SURVEY_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true 
            }
        case UPDATE_SURVEY_SUCCESS:
            return {
                ...state,
                data: state.data.map(survey =>
                    survey._id.toString() === action.payload.data._id.toString() ?
                        { survey, ...action.payload.data } :
                        survey
                )
            }

        //FAILS
        case GET_SURVEYS_FAIL:
        case DELETE_SURVEY_FAIL:
        case ADD_SURVEY_FAIL:
        case UPDATE_SURVEY_FAIL:
        case GET_SURVEY_DETAIL_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_SURVEY: return {
            ...state,
            error: null,
            success:null
        }
        default:
            return state;
    }
}


export default Surveys;