import {
    GET_DEV_DUTY_FORMS,
    GET_DEV_DUTY_FORMS_SUCCESS,
    GET_DEV_DUTY_FORMS_FAIL,
    GET_DEV_DUTY_FORM_DETAIL,
    GET_DEV_DUTY_FORM_DETAIL_SUCCESS,
    GET_DEV_DUTY_FORM_DETAIL_FAIL,
    ADD_DEV_DUTY_FORM,
    ADD_DEV_DUTY_FORM_SUCCESS,
    ADD_DEV_DUTY_FORM_FAIL,
    UPDATE_DEV_DUTY_FORM,
    UPDATE_DEV_DUTY_FORM_SUCCESS,
    UPDATE_DEV_DUTY_FORM_FAIL,
    DELETE_DEV_DUTY_FORM,
    DELETE_DEV_DUTY_FORM_SUCCESS,
    DELETE_DEV_DUTY_FORM_FAIL,
    RESET_DEV_DUTY_FORM,
   
} from "./actionTypes";


export const resetDevDutyForm = () => ({
    type: RESET_DEV_DUTY_FORM,
});

export const getDevDutyForms = () => ({
    type: GET_DEV_DUTY_FORMS,
});

export const getDevDutyFormsSuccess = (devDutyForm) => ({
    type: GET_DEV_DUTY_FORMS_SUCCESS,
    payload: devDutyForm,
});


export const getDevDutyFormsFail = (error) => ({
    type: GET_DEV_DUTY_FORMS_FAIL,
    payload: error,
});

export const deleteDevDutyForm = (id) => ({
    type:  DELETE_DEV_DUTY_FORM,
    payload: id
});

export const deleteDevDutyFormSuccess = (data) => ({
    type: DELETE_DEV_DUTY_FORM_SUCCESS,
    payload: data
});

export const deleteDevDutyFormFail = (error) => ({
    type: DELETE_DEV_DUTY_FORM_FAIL,
    payload: error,
});


export const addDevDutyForm = (devDutyForm) => ({
    type: ADD_DEV_DUTY_FORM,
    payload: devDutyForm
});

export const addDevDutyFormSuccess = (data) => ({
    type: ADD_DEV_DUTY_FORM_SUCCESS,
    payload: data
});

export const addDevDutyFormFail = (error) => ({
    type: ADD_DEV_DUTY_FORM_FAIL,
    payload: error,
});


export const updateDevDutyForm = (devDutyForm) => ({
    type: UPDATE_DEV_DUTY_FORM,
    payload: devDutyForm
});

export const updateDevDutyFormSuccess = (data) => ({
    type: UPDATE_DEV_DUTY_FORM_SUCCESS,
    payload: data
});

export const updateDevDutyFormFail = (error) => ({
    type: UPDATE_DEV_DUTY_FORM_FAIL,
    payload: error,
});