import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_COMPANY_FORM_BY_DATE,
    GET_COMPANY_FORM_BY_DATE_YESTERDAY,
    GET_COMPANY_FORM_BY_DATE_MONTH,

    GET_COMPANY_QR_BY_DATE,
    GET_COMPANY_QR_BY_DATE_YESTERDAY,
    GET_COMPANY_QR_BY_DATE_MONTH,
    GET_COMPANY_QR_BY_DATE_ALL,

    GET_COMPANY_FORM_IS_MATCH_BY_DATE,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH,

    GET_BRANCH_FORM_BY_DATE,
    GET_BRANCH_FORM_BY_DATE_YESTERDAY,
    GET_BRANCH_FORM_BY_DATE_MONTH,

    GET_BRANCH_QR_BY_DATE,
    GET_BRANCH_QR_BY_DATE_YESTERDAY,
    GET_BRANCH_QR_BY_DATE_MONTH,
    GET_BRANCH_QR_BY_DATE_ALL,

    GET_BRANCH_FORM_IS_MATCH_BY_DATE,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH,

    GET_COMPANY_SHARE_QR_BY_DATE,
    GET_COMPANY_SHARE_QR_BY_DATE_ALL,
    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY,
    GET_COMPANY_SHARE_QR_BY_DATE_MONTH,

    GET_BRANCH_SHARE_QR_BY_DATE,
    GET_BRANCH_SHARE_QR_BY_DATE_ALL,
    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY,
    GET_BRANCH_SHARE_QR_BY_DATE_MONTH



} from "./actionTypes";
import {
    getCompanyFormsByDateSuccess, getCompanyFormsByDateFail,
    getCompanyFormsByDateYesterdaySuccess,getCompanyFormsByDateYesterdayFail,
    getCompanyFormsByDateMonthSuccess,getCompanyFormsByDateMonthFail,
    getCompanyQrByDateSuccess,getCompanyQrByDateFail,
    getCompanyQrByDateYesterdaySuccess,getCompanyQrByDateYesterdayFail,
    getCompanyQrByDateMonthSuccess,getCompanyQrByDateMonthFail,
    getCompanyQrByDateAllSuccess,getCompanyQrByDateAllFail,
    getCompanyFormsIsMatchByDateSuccess,getCompanyFormsIsMatchByDateFail,
    getCompanyFormsIsMatchByDateYesterdaySuccess,getCompanyFormsIsMatchByDateYesterdayFail,
    getCompanyFormsIsMatchByDateMonthSuccess,getCompanyFormsIsMatchByDateMonthFail,
    getBranchFormsByDateSuccess, getBranchFormsByDateFail,
    getBranchFormsByDateYesterdaySuccess,getBranchFormsByDateYesterdayFail,
    getBranchFormsByDateMonthSuccess,getBranchFormsByDateMonthFail,
    getBranchQrByDateSuccess,getBranchQrByDateFail,
    getBranchQrByDateYesterdaySuccess,getBranchQrByDateYesterdayFail,
    getBranchQrByDateMonthSuccess,getBranchQrByDateMonthFail,
    getBranchQrByDateAllSuccess,getBranchQrByDateAllFail,
    getBranchFormsIsMatchByDateSuccess,getBranchFormsIsMatchByDateFail,
    getBranchFormsIsMatchByDateYesterdaySuccess,getBranchFormsIsMatchByDateYesterdayFail,
    getBranchFormsIsMatchByDateMonthSuccess,getBranchFormsIsMatchByDateMonthFail,

    getBranchShareQrByDateAllSuccess,getBranchShareQrByDateAllFail,
    getBranchShareQrByDateSuccess,getBranchShareQrByDateFail,
    getBranchShareQrByDateYesterdaySuccess,getBranchShareQrByDateYesterdayFail,
    getBranchShareQrByDateMonthSuccess,getBranchShareQrByDateMonthFail,

    getCompanyShareQrByDateAllSuccess,getCompanyShareQrByDateAllFail,
    getCompanyShareQrByDateSuccess,getCompanyShareQrByDateFail,
    getCompanyShareQrByDateYesterdaySuccess,getCompanyShareQrByDateYesterdayFail,
    getCompanyShareQrByDateMonthSuccess,getCompanyShareQrByDateMonthFail




   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getCompanyFormsByDate,
    getCompanyQrByDate,
    getCompanyFormsIsMatchByDate,
    getBranchFormsByDate,
    getBranchQrByDate,
    getBranchFormsIsMatchByDate,
    getCompanyShareQrByDate,
    getBranchShareQrByDate
   
    
}
    from "../../helpers/fakebackend_helper"; 
    //----------------BRANCH SHARE QR-------------------
function* fetchBranchShareQrByDateAll({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchShareQrByDate,BranchId, startDate, endDate)
        yield put(getBranchShareQrByDateAllSuccess(response));
    } catch (error) {
        yield put(getBranchShareQrByDateAllFail(error));
    }
}
function* fetchBranchShareQrByDate({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchShareQrByDate,BranchId, startDate, endDate)
        yield put(getBranchShareQrByDateSuccess(response));
    } catch (error) {
        yield put(getBranchShareQrByDateFail(error));
    }
}
function* fetchBranchShareQrYesterday({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchShareQrByDate,BranchId, startDate, endDate)
        yield put(getBranchShareQrByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getBranchShareQrByDateYesterdayFail(error));
    }
}
function* fetchBranchShareQrMonth({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchShareQrByDate,BranchId, startDate, endDate)
        yield put(getBranchShareQrByDateMonthSuccess(response));
    } catch (error) {
        yield put(getBranchShareQrByDateMonthFail(error));
    }
}
    //----------------COMPANY SHARE QR-------------------
function* fetchCompanyQrShareByDateAll({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyShareQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyShareQrByDateAllSuccess(response));
    } catch (error) {
        yield put(getCompanyShareQrByDateAllFail(error));
    }
}
function* fetchCompanyShareQrByDate({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyShareQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyShareQrByDateSuccess(response));
    } catch (error) {
        yield put(getCompanyShareQrByDateFail(error));
    }
}
function* fetchCompanyShareQrYesterday({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyShareQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyShareQrByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getCompanyShareQrByDateYesterdayFail(error));
    }
}
function* fetchCompanyShareQrMonth({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyShareQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyShareQrByDateMonthSuccess(response));
    } catch (error) {
        yield put(getCompanyShareQrByDateMonthFail(error));
    }
}
    //-----------FORM IS MATCH----------------
function* fetchBranchFormsIsMatchByDate({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsIsMatchByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsIsMatchByDateSuccess(response));
    } catch (error) {
        yield put(getBranchFormsIsMatchByDateFail(error));
    }
}
function* fetchBranchFormsIsMatchYesterday({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsIsMatchByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsIsMatchByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getBranchFormsIsMatchByDateYesterdayFail(error));
    }
}
function* fetchBranchFormsIsMatchMonth({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsIsMatchByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsIsMatchByDateMonthSuccess(response));
    } catch (error) {
        yield put(getBranchFormsIsMatchByDateMonthFail(error));
    }
}
//----------------QR-------------------
function* fetchBranchQrByDateAll({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchQrByDate,BranchId, startDate, endDate)
        yield put(getBranchQrByDateAllSuccess(response));
    } catch (error) {
        yield put(getBranchQrByDateAllFail(error));
    }
}
function* fetchBranchQrByDate({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchQrByDate,BranchId, startDate, endDate)
        yield put(getBranchQrByDateSuccess(response));
    } catch (error) {
        yield put(getBranchQrByDateFail(error));
    }
}
function* fetchBranchQrYesterday({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchQrByDate,BranchId, startDate, endDate)
        yield put(getBranchQrByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getBranchQrByDateYesterdayFail(error));
    }
}
function* fetchBranchQrMonth({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchQrByDate,BranchId, startDate, endDate)
        yield put(getBranchQrByDateMonthSuccess(response));
    } catch (error) {
        yield put(getBranchQrByDateMonthFail(error));
    }
}
//-----------FORMS----------------
function* fetchBranchFormsByDate({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsByDateSuccess(response));
    } catch (error) {
        yield put(getBranchFormsByDateFail(error));
    }
}
function* fetchBranchFormsYesterday({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getBranchFormsByDateYesterdayFail(error));
    }
}
function* fetchBranchFormsMonth({ payload:{BranchId, startDate, endDate}}) {
    try {
        const response = yield call(getBranchFormsByDate,BranchId, startDate, endDate)
        yield put(getBranchFormsByDateMonthSuccess(response));
    } catch (error) {
        yield put(getBranchFormsByDateMonthFail(error));
    }
}
 
//-----------FORM IS MATCH----------------
function* fetchCompanyFormsIsMatchByDate({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsIsMatchByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsIsMatchByDateSuccess(response));
    } catch (error) {
        yield put(getCompanyFormsIsMatchByDateFail(error));
    }
}
function* fetchCompanyFormsIsMatchYesterday({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsIsMatchByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsIsMatchByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getCompanyFormsIsMatchByDateYesterdayFail(error));
    }
}
function* fetchCompanyFormsIsMatchMonth({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsIsMatchByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsIsMatchByDateMonthSuccess(response));
    } catch (error) {
        yield put(getCompanyFormsIsMatchByDateMonthFail(error));
    }
}
//----------------QR-------------------
function* fetchCompanyQrByDateAll({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyQrByDateAllSuccess(response));
    } catch (error) {
        yield put(getCompanyQrByDateAllFail(error));
    }
}
function* fetchCompanyQrByDate({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyQrByDateSuccess(response));
    } catch (error) {
        yield put(getCompanyQrByDateFail(error));
    }
}
function* fetchCompanyQrYesterday({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyQrByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getCompanyQrByDateYesterdayFail(error));
    }
}
function* fetchCompanyQrMonth({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyQrByDate,CompanyId, startDate, endDate)
        yield put(getCompanyQrByDateMonthSuccess(response));
    } catch (error) {
        yield put(getCompanyQrByDateMonthFail(error));
    }
}
//-----------FORMS----------------
function* fetchCompanyFormsByDate({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsByDateSuccess(response));
    } catch (error) {
        yield put(getCompanyFormsByDateFail(error));
    }
}
function* fetchCompanyFormsYesterday({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsByDateYesterdaySuccess(response));
    } catch (error) {
        yield put(getCompanyFormsByDateYesterdayFail(error));
    }
}
function* fetchCompanyFormsMonth({ payload:{CompanyId, startDate, endDate}}) {
    try {
        const response = yield call(getCompanyFormsByDate,CompanyId, startDate, endDate)
        yield put(getCompanyFormsByDateMonthSuccess(response));
    } catch (error) {
        yield put(getCompanyFormsByDateMonthFail(error));
    }
}
 

export function* brandsSaga() {
    yield takeEvery(GET_COMPANY_FORM_IS_MATCH_BY_DATE, fetchCompanyFormsIsMatchByDate);
    yield takeEvery(GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY, fetchCompanyFormsIsMatchYesterday);
    yield takeEvery(GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH, fetchCompanyFormsIsMatchMonth);
    yield takeEvery(GET_COMPANY_QR_BY_DATE, fetchCompanyQrByDate);
    yield takeEvery(GET_COMPANY_QR_BY_DATE_YESTERDAY, fetchCompanyQrYesterday);
    yield takeEvery(GET_COMPANY_QR_BY_DATE_MONTH, fetchCompanyQrMonth);
    yield takeEvery(GET_COMPANY_FORM_BY_DATE, fetchCompanyFormsByDate);
    yield takeEvery(GET_COMPANY_FORM_BY_DATE_YESTERDAY, fetchCompanyFormsYesterday);
    yield takeEvery(GET_COMPANY_FORM_BY_DATE_MONTH, fetchCompanyFormsMonth);
    yield takeEvery(GET_COMPANY_QR_BY_DATE_ALL, fetchCompanyQrByDateAll);
    yield takeEvery(GET_BRANCH_FORM_IS_MATCH_BY_DATE, fetchBranchFormsIsMatchByDate);
    yield takeEvery(GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY, fetchBranchFormsIsMatchYesterday);
    yield takeEvery(GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH, fetchBranchFormsIsMatchMonth);
    yield takeEvery(GET_BRANCH_QR_BY_DATE, fetchBranchQrByDate);
    yield takeEvery(GET_BRANCH_QR_BY_DATE_YESTERDAY, fetchBranchQrYesterday);
    yield takeEvery(GET_BRANCH_QR_BY_DATE_MONTH, fetchBranchQrMonth);
    yield takeEvery(GET_BRANCH_FORM_BY_DATE, fetchBranchFormsByDate);
    yield takeEvery(GET_BRANCH_FORM_BY_DATE_YESTERDAY, fetchBranchFormsYesterday);
    yield takeEvery(GET_BRANCH_FORM_BY_DATE_MONTH, fetchBranchFormsMonth);
    yield takeEvery(GET_BRANCH_QR_BY_DATE_ALL, fetchBranchQrByDateAll);

    yield takeEvery(GET_BRANCH_SHARE_QR_BY_DATE_ALL, fetchBranchShareQrByDateAll);
    yield takeEvery(GET_BRANCH_SHARE_QR_BY_DATE, fetchBranchShareQrByDate);
    yield takeEvery(GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY, fetchBranchShareQrYesterday);
    yield takeEvery(GET_BRANCH_SHARE_QR_BY_DATE_MONTH, fetchBranchShareQrMonth);

    yield takeEvery(GET_COMPANY_SHARE_QR_BY_DATE, fetchCompanyShareQrByDate);
    yield takeEvery(GET_COMPANY_SHARE_QR_BY_DATE_ALL, fetchCompanyQrShareByDateAll);
    yield takeEvery(GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY, fetchCompanyShareQrYesterday);
    yield takeEvery(GET_COMPANY_SHARE_QR_BY_DATE_MONTH, fetchCompanyShareQrMonth);
   

}

export default brandsSaga;
