import {
    GET_STATUSS,
    GET_STATUSS_SUCCESS,
    GET_STATUSS_FAIL,
    GET_STATUS_DETAIL,
    GET_STATUS_DETAIL_SUCCESS,
    GET_STATUS_DETAIL_FAIL,
    ADD_STATUS,
    ADD_STATUS_SUCCESS,
    ADD_STATUS_FAIL,
    UPDATE_STATUS,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    DELETE_STATUS,
    DELETE_STATUS_SUCCESS,
    DELETE_STATUS_FAIL,
    RESET_STATUS,
   
} from "./actionTypes";


export const resetStatus = () => ({
    type: RESET_STATUS,
});

export const getStatuss = () => ({
    type: GET_STATUSS,
});

export const getStatussSuccess = (status) => ({
    type: GET_STATUSS_SUCCESS,
    payload: status,
});


export const getStatussFail = (error) => ({
    type: GET_STATUSS_FAIL,
    payload: error,
});

export const deleteStatus = (id) => ({
    type:  DELETE_STATUS,
    payload: id
});

export const deleteStatusSuccess = (data) => ({
    type: DELETE_STATUS_SUCCESS,
    payload: data
});

export const deleteStatusFail = (error) => ({
    type: DELETE_STATUS_FAIL,
    payload: error,
});


export const addStatus = (status) => ({
    type: ADD_STATUS,
    payload: status
});

export const addStatusSuccess = (data) => ({
    type: ADD_STATUS_SUCCESS,
    payload: data
});

export const addStatusFail = (error) => ({
    type: ADD_STATUS_FAIL,
    payload: error,
});


export const updateStatus = (status) => ({
    type: UPDATE_STATUS,
    payload: status
});

export const updateStatusSuccess = (data) => ({
    type: UPDATE_STATUS_SUCCESS,
    payload: data
});

export const updateStatusFail = (error) => ({
    type: UPDATE_STATUS_FAIL,
    payload: error,
});