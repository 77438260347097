import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ORIENTATION_PERIODS, DELETE_ORIENTATION_PERIOD, ADD_ORIENTATION_PERIOD, UPDATE_ORIENTATION_PERIOD,GET_ORIENTATION_PERIODS_MY_ORIENTATION_PERIODS} from "./actionTypes";
import {
    getOrientationPeriodsSuccess, getOrientationPeriodsFail,
    deleteOrientationPeriodSuccess, deleteOrientationPeriodFail,
    updateOrientationPeriodSuccess, updateOrientationPeriodFail,
    addOrientationPeriodSuccess, addOrientationPeriodFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getOrientationPeriods, //API CONNECTION
    delOrientationPeriod,
    addOrientationPeriod,
    updateOrientationPeriod,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchOrientationPeriods() {
    try {
        const response = yield call(getOrientationPeriods)
        yield put(getOrientationPeriodsSuccess(response));
    } catch (error) {
        yield put(getOrientationPeriodsFail(error));
    }
}

function* onDeleteOrientationPeriods({payload:id}){
    try{
        const response = yield call(delOrientationPeriod, id)
        yield put(deleteOrientationPeriodSuccess(response));
    } catch (error) {
        yield put(deleteOrientationPeriodFail(error));
    }
}

function* onAddOrientationPeriod({payload:orientationPeriod}){
    try{
        const response = yield call(addOrientationPeriod, orientationPeriod)
        yield put(addOrientationPeriodSuccess(response));
    } catch (error) {
        yield put(addOrientationPeriodFail(error));
    }
}

function* onUpdateOrientationPeriod({payload:orientationPeriod}){
    try{
        const response = yield call(updateOrientationPeriod, orientationPeriod)
        yield put(updateOrientationPeriodSuccess(response));
    } catch (error) {
        yield put(updateOrientationPeriodFail(error));
    }
}

export function* orientationPeriodsSaga() {
    yield takeEvery(GET_ORIENTATION_PERIODS, fetchOrientationPeriods);
    yield takeEvery(DELETE_ORIENTATION_PERIOD, onDeleteOrientationPeriods);
    yield takeEvery(ADD_ORIENTATION_PERIOD, onAddOrientationPeriod);
    yield takeEvery(UPDATE_ORIENTATION_PERIOD, onUpdateOrientationPeriod);
   
    
}

export default orientationPeriodsSaga;
