import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Badge
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"
import {
  getCompanies as onGetCompanies,
  getCompanyBranches as onGetCompanyBranches,
  getBranchs as onGetBranchs,
  getBranchChilds as onGetBranchChilds, 
  deleteBranch as onDeleteBranch,
  addBranch as onAddBranch,
  updateBranch as onUpdateBranch,
  resetBranch as onResetBranch,
  addVisit as onAddVisit
} from "store/actions"
import Dropzone from "react-dropzone"
import { isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import QrListModal from "./Modals/qrListModal"

import "./datatables.scss"

const Branchs = props => {
  const dispatch = useDispatch()

  const [selectedId, setSelectedId] = useState()
  const [selectedBranch, setSelectedBranch] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [role, setRole] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [titleLink, setTitleLink] = useState([])
  const [list, setList] = useState([])

  const [qrListOpen, setQRListOpen] = useState(false)
  const [qrListData, setQrListData] = useState()
  const [loading, setLoading] = useState(true)

  const { error, Branchs, Branches, main,Grade } = useSelector(state => ({
    error: state.Branchs?.error,
    Branchs: state.Branchs?.data,
    Branches: state.Branchs?.Branches,
    main: state.Branchs?.main,
    Grade : state.Companies?.data[0]?.grades
  }))
  let user = JSON.parse(localStorage.getItem("authUser"))

  const {
    match: { params },
  } = props

  useEffect(() => {
    setLoading(true)
    if (isEmpty(params?.id)) {
      dispatch(onGetBranchs())
      } else {
      dispatch(onGetBranchChilds(params.id))
    }
    const visitDetailArr =[{name:"Tanımlamalar/Birim Tanımla",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(main)) {
      titleLinkCalculator()
    } else {
      setTitleLink([])
    }
  }, [main])

  useEffect(() => {
    if (!isEmpty(Branchs)) {
      setList(Branchs)
    }
    setLoading(false)
  }, [Branchs])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleQrList = () => {
    setQRListOpen(!qrListOpen)
  }

  const titleLinkCalculator = () => {
    let links = []
    if (!isEmpty(main)) {
      links.push({
        link: `/branches`,
        title: props.t("Company"),
      })
      let myObj = main
      let isContinue = true
      while (isContinue) {
        isContinue = myObj.child != null ? true : false
        links.push({
          link: `/branches/${myObj._id}`,
          title: myObj.name,
        })
        myObj = myObj.child
      }
      setTitleLink(links)
    }
  }
  

  const columns = [
    {
      dataField: "photo",
      text: props.t("Photo"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          {cell ? (
            <img
              src={config.baseImg + cell}
              onClick={() => {
                setSelectedBranch(row)

                setIsEdit(true)

                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          ) : (
            <img
              src={imgEmpty}
              onClick={() => {
                setSelectedBranch(row)

                setIsEdit(true)
                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "companyId",
      text: props.t("Company Name"),
      sort: true,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },
    {
      dataField: "dummy",
      text: props.t("qrCode"),
      dummy: true,
      sort: false,
      formatter: (cell, row) => (
        <a
          className="btn btn-link"
          href="#"
          onClick={() => {
            setQrListData({
              BranchName: row.name,
              BranchId: row._id,
            })
            toggleQrList()
          }}
        >
          <i className="mdi mdi-qrcode font-size-16 text-success me-1"></i>
          {props.t("qrCode")}
        </a>
      ),
    },
    {
      dataField: "dummy2",
      text: props.t("Units"),
      dummy: true,
      sort: false,
      formatter: (cell, row) => (
        <a
          className="btn btn-link"
          href={`/branches/${row._id}`} 
        >
          <i className="mdi mdi-source-branch mdi-rotate-90 font-size-16 text-success me-1"></i>
          {props.t("Units")}
        </a>
          
      ),
    },
    {
      dataField: "PartnershipStatus",
      text: props.t("Partnership"),
     
      sort: false, 
      formatter: (cell, row) => (
        <div>
          {cell == "wait" ? (
            <Badge className="font-size-13 badge-soft bg-warning">{props.t("Waiting")}</Badge> 
          ) : cell =="reject"?(
            <Badge className="font-size-13 badge-soft bg-danger">{props.t("Rejected")}</Badge>
          ):cell =="approved"?
          <Badge className="font-size-13 badge-soft bg-success">{props.t("Approved")}</Badge>
          :null}
        </div>

      )
      
    },
    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedBranch(row)
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem> 
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]
  
  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Branchs ? Branchs.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Branchs ? Branchs.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidBranchSubmit = (e, values) => {
    if (isEdit) {
      const updateBranch = {
        _id: selectedBranch._id,
        name: values.name,
        description: values["description"],
        companyId: user.companyId,
        photo: values["photo"],
      }
      // update Branch
      dispatch(onUpdateBranch(updateBranch, selectedFiles))
    } else {
      const newBranch = {
        name: values["name"],
        description: values["description"],
        companyId: user.companyId,
        parentId: isEmpty(params?.id) ? null : params.id,
      }
      // save new Branch
      dispatch(onAddBranch(newBranch, selectedFiles))
    }
    toggle()
  }
  //IMAGE CONTROL
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
 console.log(">|||>>",selectedBranch?.photo)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Companies")}</title> 
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Companies")}
            breadcrumbItem={isEmpty(Grade)? props.t("Companies"):Grade[0]?.id+" Ekle"} 
            linkList={titleLink}
            linkType={"header"}
            addBtn={true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedBranch({ 
                name: "",
                description: "",
                companyId: "", 
              })
              setselectedFiles([])
              toggle()
              
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
             
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Branchs}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Branchs}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            {loading ? (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : null}
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={props.t(
                                  "You Don't Have a Branch Yet"
                                )}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          <Modal isOpen={modal} size="lg" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEmpty(Grade)?
              !!isEdit ? props.t("Edit Branch") :  props.t("Add Branch")
              :
              isEmpty(titleLink)? !!isEdit ? Grade[0]?.id + " Düzenle" :  Grade[0]?.id + " Ekle":
              !!isEdit ? Grade[titleLink.length-1]?.id + " Düzenle" :  Grade[titleLink.length-1]?.id + " Ekle"
              
              
              }
              
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidBranchSubmit}>
                <Row form>
                  <Col xs={12}>
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div>
                        {selectedBranch?.photo ? (
                          <img
                            src={config.baseImg + selectedBranch?.photo}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img 
                            src={imgEmpty}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                          />
                          
                        )}
                      </div>
                      <div
                        style={{
                          width: "75%",
                          border: "1px solid #ced4da",
                          borderRadius: "10px",
                          height: "150px",
                        }}
                      >
                        <Dropzone
                          multiple={false}
                          accept="image/jpeg, image/png"
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone-min">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div
                                  className="dz-message needsclick"
                                  style={{ padding: "0px" }}
                                >
                                  <div>
                                    <i className="display-5 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{props.t("Drop Image")}</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews"
                          id="file-previews"
                          style={{
                            position: "absolute",
                            zIndex: 99,
                            left: 0,
                            top: -5,
                          }}
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <div className="align-items-center">
                                    <div className="col-12">
                                      <img
                                        data-dz-thumbnail=""
                                        style={{
                                          width: "150px",
                                          height: "120px",
                                          objectFit: "contain",
                                        }}
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="name"
                        label={props.t("Name")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: true },
                        }}
                        value={selectedBranch?.name || ""}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="description"
                        label={props.t("Description")}
                        type="textarea"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={selectedBranch?.description || ""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {props.t("Save")}
                      </button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <QrListModal
            comeFrom="branch"
            modal={qrListOpen}
            modalData={qrListData} 
            toggle={() => {
              toggleQrList()
            }}
          />

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteBranch(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetBranch())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Branchs))
