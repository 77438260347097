import {
    GET_DUTYS,
    GET_DUTYS_SUCCESS,
    GET_DUTYS_FAIL,
    GET_DUTY_DETAIL,
    GET_DUTY_DETAIL_SUCCESS,
    GET_DUTY_DETAIL_FAIL,
    ADD_DUTY,
    ADD_DUTY_SUCCESS,
    ADD_DUTY_FAIL,
    UPDATE_DUTY,
    UPDATE_DUTY_SUCCESS,
    UPDATE_DUTY_FAIL,
    DELETE_DUTY,
    DELETE_DUTY_SUCCESS,
    DELETE_DUTY_FAIL,
    RESET_DUTY,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const Dutys = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DUTYS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DUTY_SUCCESS:
            return {
                ...state,
                data: state.data.filter(duty => duty._id.toString() !== action.payload.data.toString())
            }
        case ADD_DUTY_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DUTY_SUCCESS:
            return {
                ...state,
                data: state.data.map(duty =>
                    duty._id.toString() === action.payload.data._id.toString() ?
                        { duty, ...action.payload.data } :
                        duty
                )
            }

        //FAILS
        case GET_DUTYS_FAIL:
        case DELETE_DUTY_FAIL:
        case ADD_DUTY_FAIL:
        case UPDATE_DUTY_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DUTY: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Dutys;