/** Get DEV_DUTY */
export const GET_DEV_DUTYS = "GET_DEV_DUTYS";
export const GET_DEV_DUTYS_SUCCESS = "GET_DEV_DUTYS_SUCCESS";
export const GET_DEV_DUTYS_FAIL = "GET_DEV_DUTYS_FAIL";

export const GET_DEV_DUTY_DETAIL = "GET_DEV_DUTY_DETAIL";
export const GET_DEV_DUTY_DETAIL_SUCCESS = "GET_DEV_DUTY_DETAIL_SUCCESS";
export const GET_DEV_DUTY_DETAIL_FAIL = "GET_DEV_DUTY_DETAIL_FAIL";


export const ADD_DEV_DUTY = "ADD_DEV_DUTY";
export const ADD_DEV_DUTY_SUCCESS = "ADD_DEV_DUTY_SUCCESS";
export const ADD_DEV_DUTY_FAIL = "ADD_DEV_DUTY_FAIL";

export const UPDATE_DEV_DUTY = "UPDATE_DEV_DUTY";
export const UPDATE_DEV_DUTY_SUCCESS = "UPDATE_DEV_DUTY_SUCCESS";
export const UPDATE_DEV_DUTY_FAIL = "UPDATE_DEV_DUTY_FAIL";

export const DELETE_DEV_DUTY = "DELETE_DEV_DUTY";
export const DELETE_DEV_DUTY_SUCCESS = "DELETE_DEV_DUTY_SUCCESS";
export const DELETE_DEV_DUTY_FAIL = "DELETE_DEV_DUTY_FAIL"; 



export const RESET_DEV_DUTY= "RESET_DEV_DUTY";