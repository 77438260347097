/** Get DUTY */
export const GET_DUTYS = "GET_DUTYS";
export const GET_DUTYS_SUCCESS = "GET_DUTYS_SUCCESS";
export const GET_DUTYS_FAIL = "GET_DUTYS_FAIL";

export const GET_DUTY_DETAIL = "GET_DUTY_DETAIL";
export const GET_DUTY_DETAIL_SUCCESS = "GET_DUTY_DETAIL_SUCCESS";
export const GET_DUTY_DETAIL_FAIL = "GET_DUTY_DETAIL_FAIL";


export const ADD_DUTY = "ADD_DUTY";
export const ADD_DUTY_SUCCESS = "ADD_DUTY_SUCCESS";
export const ADD_DUTY_FAIL = "ADD_DUTY_FAIL";

export const UPDATE_DUTY = "UPDATE_DUTY";
export const UPDATE_DUTY_SUCCESS = "UPDATE_DUTY_SUCCESS";
export const UPDATE_DUTY_FAIL = "UPDATE_DUTY_FAIL";

export const DELETE_DUTY = "DELETE_DUTY";
export const DELETE_DUTY_SUCCESS = "DELETE_DUTY_SUCCESS";
export const DELETE_DUTY_FAIL = "DELETE_DUTY_FAIL"; 



export const RESET_DUTY= "RESET_DUTY";