import {
    GET_LOGIN_LOGS,
    GET_LOGIN_LOGS_SUCCESS,
    GET_LOGIN_LOGS_FAIL,

    ADD_VISIT,
    ADD_VISIT_SUCCESS,
    ADD_VISIT_FAIL,
  
    RESET_LOGIN_LOG,
   
} from "./actionTypes"; 


export const resetLoginLog = () => ({
    type: RESET_LOGIN_LOG,
});

export const getLoginLogs = () => ({
    type: GET_LOGIN_LOGS,
});

export const getLoginLogsSuccess = (loginLog) => ({
    type: GET_LOGIN_LOGS_SUCCESS,
    payload: loginLog,
});
 

export const getLoginLogsFail = (error) => ({
    type: GET_LOGIN_LOGS_FAIL,
    payload: error,
});
export const addVisit = (plan) => ({
    type: ADD_VISIT,  
    payload: plan,   
});

export const addVisitSuccess = (loginLog) => ({ 
    type: ADD_VISIT_SUCCESS,
    payload: loginLog,
});
 

export const addVisitFail = (error) => ({
    type: ADD_VISIT_FAIL,
    payload: error,
});

