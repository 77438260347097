import {
    GET_DEV_DUTYS,
    GET_DEV_DUTYS_SUCCESS,
    GET_DEV_DUTYS_FAIL,
    GET_DEV_DUTY_DETAIL,
    GET_DEV_DUTY_DETAIL_SUCCESS,
    GET_DEV_DUTY_DETAIL_FAIL,
    ADD_DEV_DUTY,
    ADD_DEV_DUTY_SUCCESS,
    ADD_DEV_DUTY_FAIL,
    UPDATE_DEV_DUTY,
    UPDATE_DEV_DUTY_SUCCESS,
    UPDATE_DEV_DUTY_FAIL,
    DELETE_DEV_DUTY,
    DELETE_DEV_DUTY_SUCCESS,
    DELETE_DEV_DUTY_FAIL,
    RESET_DEV_DUTY,
   
} from "./actionTypes";


export const resetDevDuty = () => ({
    type: RESET_DEV_DUTY,
});

export const getDevDutys = () => ({
    type: GET_DEV_DUTYS,
});

export const getDevDutysSuccess = (devDuty) => ({
    type: GET_DEV_DUTYS_SUCCESS,
    payload: devDuty,
});


export const getDevDutysFail = (error) => ({
    type: GET_DEV_DUTYS_FAIL,
    payload: error,
});

export const deleteDevDuty = (id) => ({
    type:  DELETE_DEV_DUTY,
    payload: id
});

export const deleteDevDutySuccess = (data) => ({
    type: DELETE_DEV_DUTY_SUCCESS,
    payload: data
});

export const deleteDevDutyFail = (error) => ({
    type: DELETE_DEV_DUTY_FAIL,
    payload: error,
});


export const addDevDuty = (devDuty) => ({
    type: ADD_DEV_DUTY,
    payload: devDuty
});

export const addDevDutySuccess = (data) => ({
    type: ADD_DEV_DUTY_SUCCESS,
    payload: data
});

export const addDevDutyFail = (error) => ({
    type: ADD_DEV_DUTY_FAIL,
    payload: error,
});


export const updateDevDuty = (devDuty) => ({
    type: UPDATE_DEV_DUTY,
    payload: devDuty
});

export const updateDevDutySuccess = (data) => ({
    type: UPDATE_DEV_DUTY_SUCCESS,
    payload: data
});

export const updateDevDutyFail = (error) => ({
    type: UPDATE_DEV_DUTY_FAIL,
    payload: error,
});