import {
    GET_PROGRAMS,
    GET_PROGRAMS_SUCCESS,
    GET_PROGRAMS_FAIL,
    GET_PROGRAM_DETAIL,
    GET_PROGRAM_DETAIL_SUCCESS,
    GET_PROGRAM_DETAIL_FAIL,
    ADD_PROGRAM,
    ADD_PROGRAM_SUCCESS,
    ADD_PROGRAM_FAIL,
    UPDATE_PROGRAM,
    UPDATE_PROGRAM_SUCCESS,
    UPDATE_PROGRAM_FAIL,
    DELETE_PROGRAM,
    DELETE_PROGRAM_SUCCESS,
    DELETE_PROGRAM_FAIL,
    RESET_PROGRAM,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const Programs = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_PROGRAMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PROGRAM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(program => program._id.toString() !== action.payload.data.toString())
            }
        case ADD_PROGRAM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PROGRAM_SUCCESS:
            return {
                ...state,
                data: state.data.map(program =>
                    program._id.toString() === action.payload.data._id.toString() ?
                        { program, ...action.payload.data } :
                        program
                )
            }

        //FAILS
        case GET_PROGRAMS_FAIL:
        case DELETE_PROGRAM_FAIL:
        case ADD_PROGRAM_FAIL:
        case UPDATE_PROGRAM_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_PROGRAM: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Programs;