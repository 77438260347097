import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SECTOR_FORMS, DELETE_SECTOR_FORM, ADD_SECTOR_FORM, UPDATE_SECTOR_FORM,GET_SECTOR_FORMS_MY_SECTOR_FORMS} from "./actionTypes";
import {
    getSectorFormsSuccess, getSectorFormsFail,
    deleteSectorFormSuccess, deleteSectorFormFail,
    updateSectorFormSuccess, updateSectorFormFail,
    addSectorFormSuccess, addSectorFormFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSectorForms, //API CONNECTION
    delSectorForm,
    addSectorForm,
    updateSectorForm,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchSectorForms() {
    try {
        const response = yield call(getSectorForms)
        yield put(getSectorFormsSuccess(response));
    } catch (error) {
        yield put(getSectorFormsFail(error));
    }
}

function* onDeleteSectorForms({payload:id}){
    try{
        const response = yield call(delSectorForm, id)
        yield put(deleteSectorFormSuccess(response));
    } catch (error) {
        yield put(deleteSectorFormFail(error));
    }
}

function* onAddSectorForm({payload:sectorForm}){
    try{
        const response = yield call(addSectorForm, sectorForm)
        yield put(addSectorFormSuccess(response));
    } catch (error) {
        yield put(addSectorFormFail(error));
    }
}

function* onUpdateSectorForm({payload:sectorForm}){
    try{
        const response = yield call(updateSectorForm, sectorForm)
        yield put(updateSectorFormSuccess(response));
    } catch (error) {
        yield put(updateSectorFormFail(error));
    }
}

export function* sectorFormsSaga() {
    yield takeEvery(GET_SECTOR_FORMS, fetchSectorForms);
    yield takeEvery(DELETE_SECTOR_FORM, onDeleteSectorForms);
    yield takeEvery(ADD_SECTOR_FORM, onAddSectorForm);
    yield takeEvery(UPDATE_SECTOR_FORM, onUpdateSectorForm);
   
    
}

export default sectorFormsSaga;
