import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Input,
    FormGroup, Label, TabContent,
    TabPane,
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    addDutyForm as onAddDutyForm,
    getDutyForms as onGetDutyForms,
    getDevDutyForms as onGetDutyForm,
    deleteDutyForm as onDeleteDutyForm,
    updateDutyForm as onUpdateDutyForm
} from "store/actions"
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SectorForm from "./../SectorForm/index"
import ForeignLanguage from "pages/ForeignLanguage/index"
import Program from "pages/program/index"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from "classnames"



const DutyForm = props => {
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [modal, setModal] = useState(false);
    const [checkModal, setCheckModal] = useState(false);
    const dispatch = useDispatch()
    const [selectedWorkType, setSelectedWorkType] = useState()
    const [selectedId, setSelectedId] = useState();
    const [selectedPayment, setSelectedPayment] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [selectedMainCategoryId, setSelectedMainCategoryId] = useState()
    const [isEdit, setIsEdit] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1")
    const { SearchBar } = Search;
    useEffect(() => {
        dispatch(onGetDutyForms());
        dispatch(onGetDutyForm());
    }, [dispatch]);

    const { error, DutyForms, DevDutyForm } = useSelector(state => ({
        error: state.DutyForm?.error,
        DutyForms: state.DutyForm?.data,
        DevDutyForm: state.DevDutyForm?.data

    }))

    const columns = [
        {
            dataField: 'name',
            text: props.t("Name"),
            filterValue: (cell, row) => cell?.name,
            formatter: (cell, row) => <span>{cell?.name}</span>,

        },



        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedPayment(row);
                                    console.log("22row", row)
                                    setSelectedMainCategoryId(row.name?._id)
                                    setIsEdit(true);
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab)
        }
    }
    const pageOptions = {
        sizePerPage: 10,
        totalSize: DutyForms?.size, // replace later with size(customers),
        custom: true,
    }
    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }

    const handleValidProductSubmit = (e, values) => {
        if (isEdit) {
            const updateDutyForm = {
                _id: selectedPayment._id,
                name: values["name"],


            };

            dispatch(onUpdateDutyForm(updateDutyForm));
        } else {
            const newDutyForm = {

                name: values["name"],


            }

            dispatch(onAddDutyForm(newDutyForm))
        }
        toggle()

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Form içi Görev")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}


                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}




                    <Breadcrumb title={props.t("Ön Eleme Modülleri")} breadcrumbItem={props.t("Ön Eleme Modülleri")} addBtn={false} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedPayment(
                            {
                                companyId: "",
                                name: "",
                                description: "",
                                amount: "",
                                // PaymentPlanObjects: "",

                            }
                        );


                        toggle();

                    }} />


                    <Card>
                        <CardBody>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1")
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="bx bx-buildings"></i>
                                        </span>
                                        <span className="d-none d-sm-block">{props.t("Form İçi Görev")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2")
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="bx bx-clipboard"></i>
                                        </span>
                                        <span className="d-none d-sm-block">{props.t("Form İçi Sektör")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3")
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="bx bx-clipboard"></i>
                                        </span>
                                        <span className="d-none d-sm-block">{props.t("Yabancı Diller")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "4",
                                        })}
                                        onClick={() => {
                                            toggleCustom("4")
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="bx bx-clipboard"></i>
                                        </span>
                                        <span className="d-none d-sm-block">{props.t("Program")}</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            <TabContent
                                activeTab={customActiveTab}
                                className="p-3 text-muted"
                            >
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <Breadcrumb title={props.t("Form içi Görev")} breadcrumbItem={props.t("Form içi Görev Ekle")} addBtn={true} addBtnCallBack={() => {
                                                setIsEdit(false);
                                                setSelectedPayment(
                                                    {
                                                        companyId: "",
                                                        name: "",
                                                        description: "",
                                                        amount: "",
                                                        // PaymentPlanObjects: "",

                                                    }
                                                );


                                                toggle();

                                            }} />

                                            <Card>
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        keyField='id'
                                                        columns={columns}
                                                        data={DutyForms}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField='_id'
                                                                columns={columns}
                                                                data={DutyForms}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>

                                                                        <Row className="mb-2">
                                                                            <Col md="4">
                                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"_id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />

                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            </ToolkitProvider>
                                                        )
                                                        }</PaginationProvider>

                                                    <Modal isOpen={modal} toggle={toggle}>
                                                        <ModalHeader toggle={toggle} tag="h4">
                                                            {!!isEdit ? props.t("Form İçi Görev") : props.t("Form İçi Görev")}
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <AvForm onValidSubmit={handleValidProductSubmit} >
                                                                <Row form>
                                                                    <Col xs={12}>
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <div className="mb-3">
                                                                                    <AvField type="select" className="form-select" name="name" label="Form İçi Görev Seç" id="name" value={selectedMainCategoryId}>
                                                                                        <option>Form İçi Görev Seç</option>

                                                                                        {DevDutyForm?.map((item, index) => (
                                                                                            <option key={index} value={item._id} >{item.name}</option>
                                                                                        ))}
                                                                                    </AvField>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>




                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="text-end">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success save-user"
                                                                            >
                                                                                {props.t("Save")}
                                                                            </button>
                                                                            &nbsp;&nbsp;
                                                                            <Button onClick={() => { toggle() }}>
                                                                                {props.t("Cancel")}
                                                                            </Button>

                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </ModalBody>
                                                    </Modal>


                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <SectorForm /> 
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <ForeignLanguage /> 
                                        </Col>

                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            <Program /> 
                                        </Col>

                                    </Row>
                                </TabPane>

                            </TabContent>
                        </CardBody>
                    </Card>
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteDutyForm(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {/* {error && error ? (
                <SweetAlert
                    title={props.t("Error")}
                    warning
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        dispatch(onResetPayment())
                    }}
                >
                    {JSON.stringify(error)}
                </SweetAlert>
            ) : null} */}

                </Container>
            </div>
        </React.Fragment>
    )
}


export default withRouter(withTranslation()(DutyForm))
