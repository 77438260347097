import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  Input,
  FormGroup,
  Badge
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"

import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"



import { isEmpty, map, update, valuesIn } from "lodash"
import moment from "moment"

import { AvForm, AvField } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  getPartnerships as onGetPartnership,
  updatePartnership as onUpdatePartnership

} from "store/actions"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Dropzone from "react-dropzone"

import "./datatables.scss"
import classnames from "classnames"
import imgEmpty from "./../../assets/images/empty.png"
import config from "config"

const Companies = props => {
  const dispatch = useDispatch()

  const [selectedId, setSelectedId] = useState()
  const [selectedCompany, setSelectedCompany] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_qr, setconfirm_alert_qr] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [qrTemplateModal, setQrTemplateModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirm_alert_approve, setConfirm_alert_approve] = useState(false);
  const [confirm_alert_reject, setConfirm_alert_reject] = useState(false);
  const [formStep, setFormStep] = useState(0)

  const [value, setValue] = useState()
  const [selectedFiles, setselectedFiles] = useState([])

  const { error, Companies, Partnership } = useSelector(state => ({
    error: state.Companies?.error,
    Companies: state.Companies?.data,
    Partnership: state.Partnership?.data
  }))

  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetPartnership());
  }, [dispatch]);

  const toggle = () => {
    setModal(!modal)
  }
  const qrTemplateToggle = () => {
    setQrTemplateModal(!qrTemplateModal)
  }
  const statusSpan = _status => {
    if (_status == "success") {
      return (
        <span className="text-success">
          <i className="fa fa-check me-1"></i>
          {props.t("Confirmed")}
        </span>
      )
    } else if (_status == "wait") {
      return (
        <span className="text-warning">
          <i className="fa fa-hourglass me-1"></i>
          {props.t("Waiting")}
        </span>
      )
    } else {
      return (
        <span className="text-danger">
          <i className="fa fa-times me-1"></i>
          {props.t("Rejected")}
        </span>
      )
    }
  }
  const handleValidDate = date => {
    if (date) {
        const date1 = moment(new Date(date)).format("DD.MM.YYYY");
        return date1;
    } else {
        return "";
    }
}
  const columns = [
    { 
      dataField: "companyPhoto",
      text: props.t("Photo"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          {cell ? (
            <img
              src={config.baseImg + cell}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)

                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          ) : (
            <img
              src={imgEmpty}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)
                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "CompanyId",
      text: props.t("Company Name"),
      sort: false,
      formatter: (cell, row) => (
        <span>{cell?.name}</span>
      ) 
    },
    {
      dataField: "BrandName",
      text: props.t("Branch Name"),
      sort: false,
    },
    {
      dataField: "UserId",
      text: props.t("Bu Qr'da İş Var Firma Kullanıcısı"),
      sort: false,
      formatter: (cell, row) => (
        <span>{cell?.name}</span>
      )
    },
   
    {
      dataField: 'createdAt',
      text: props.t("Created At"),
      sort: true,
      formatter: (cell, row) => (
          <span>{handleValidDate(cell)}</span>
      )

  },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          {cell == "wait" ? (
            <Badge className="font-size-13 badge-soft bg-warning">{props.t("Waiting")}</Badge> 
          ) : cell =="reject"?(
            <Badge className="font-size-13 badge-soft bg-danger">{props.t("Rejected")}</Badge>
          ):
          <Badge className="font-size-13 badge-soft bg-success">{props.t("Approved")}</Badge>
          }
        </div>

      )
    },



    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded me-3 "
            onClick={() => {
              window.open(process.env.REACT_APP_DOMAIN+"/v2/"+row?.Link)
            }}
          >
            {props.t("Check The Link")}
          </Button>
        
          <Button 
            color="success"
            className="btn-rounded me-3 "
            onClick={() => {
              setConfirm_alert_approve(true)
              setSelectedId(cell);
              
            }}
          >
            {props.t("Approve")}
          </Button>
          <Button
            color="danger"
            className="btn-rounded me-3 "
            onClick={() => {
              setConfirm_alert_reject(true)
              setSelectedId(cell);
            }}
          >
            {props.t("Reject")}
          </Button>
        </div>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Companies ? Companies.length : 0, // replace later with size(Companies),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Companies ? Companies.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidCompanySubmit = (e, values) => {
    const updateCompany = {
      _id: selectedCompany._id,
      name: values.name,
      description: values["description"],
      verDaire: values["verDaire"],
      verNo: values["verNo"],
      userName: values["userName"],
      email: values["email"],
      phone: values["phone"],
      address: values["address"],
      photo: values["photo"],
    }

    // update Company
    dispatch(onUpdateCompany(updateCompany, selectedFiles))
    console.log(updateCompany, selectedFiles)

    toggle()
  }
  const handleValidCompanyQrSubmit = (e, values) => {
    const updateCompanyQr = {
      _id: selectedCompany._id,

      QRphoto: values["photo"],
    }

    // update Company
    dispatch(onUpdateCompanyQr(updateCompanyQr, selectedFiles))
    console.log(updateCompanyQr, selectedFiles)

    qrTemplateToggle()
    //setSelectedCompany(null)
  }

  //IMAGE CONTROL
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Application Approval")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Application Approval")}
            breadcrumbItem={props.t("Application Approval")}
          />

          {/* TABLE */}
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Partnership}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Partnership}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          {/* ADD or EDIT */}
          <Modal isOpen={modal} toggle={toggle} size={"lg"}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? props.t("My Company Info") : props.t("Add Company")}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={handleValidCompanySubmit}
                autoComplete="off"
              >
                <Row form>
                  <Col xs={12}>
                    <div className="wizard clearfix mb-4">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: formStep === 0 })}
                          >
                            <NavLink
                              className={classnames({
                                current: formStep === 0,
                              })}
                              onClick={() => {
                                setFormStep(0)
                              }}
                            >
                              <span className="number">1</span>{" "}
                              {props.t("Company Info")}
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: formStep === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                active: formStep === 1,
                              })}
                              onClick={() => {
                                setFormStep(1)
                              }}
                            >
                              <span className="number ms-2">2</span>{" "}
                              {props.t("Contact User Info")}
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                    </div>
                    <FormGroup row>
                      <div
                        id="step-0"
                        style={{ display: formStep == 0 ? "block" : "none" }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <div>
                            {selectedCompany?.photo ? (
                              <img
                                src={config.baseImg + selectedCompany?.photo}
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={imgEmpty}
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              width: "75%",
                              border: "1px solid #ced4da",
                              borderRadius: "10px",
                              height: "150px",
                            }}
                          >
                            <Dropzone
                              multiple={false}
                              accept="image/jpeg, image/png"
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone-min">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div
                                      className="dz-message needsclick"
                                      style={{ padding: "0px" }}
                                    >
                                      <div>
                                        <i className="display-5 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t("Drop Image")}</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews"
                              id="file-previews"
                              style={{
                                position: "absolute",
                                zIndex: 99,
                                left: 0,
                                top: -5,
                              }}
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <div className="align-items-center">
                                        <div className="col-12">
                                          <img
                                            data-dz-thumbnail=""
                                            style={{
                                              width: "150px",
                                              height: "120px",
                                              objectFit: "contain",
                                            }}
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="name"
                            label={props.t("Company Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: true },
                            }}
                            value={selectedCompany?.name || ""}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="verDaire"
                            label={props.t("Tax Office Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.verDaire || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="verNo"
                            label={props.t("Tax No")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage:
                                  "Geçerli Bir Vergi Numarası Girin",
                              },
                            }}
                            value={selectedCompany?.verNo || ""}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup row>
                      <div
                        id="step-1"
                        style={{ display: formStep == 1 ? "block" : "none" }}
                      >
                        <div className="mb-3">
                          <small>
                            {props.t("Company Generate Contact User Info")}
                          </small>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="userName"
                            label={props.t("User Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.userName || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.email || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            label={props.t("Phone")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            mask="(999) 999-9999"
                            maskChar="-"
                            tag={[Input, InputMask]}
                            placeholder="(999) 999-9999"
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.phone || ""}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        className="me-2"
                        onClick={() => {
                          toggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>

                      {formStep > 0 ? (
                        <Button
                          color="primary"
                          className="btn btn-primary me-2 "
                          onClick={() => {
                            setFormStep(formStep - 1)
                          }}
                        >
                          {props.t("Prev")}
                        </Button>
                      ) : null}

                      {formStep < 1 ? (
                        <Button
                          color="primary"
                          className="btn btn-primary me-2 "
                          onClick={() => {
                            setFormStep(formStep + 1)
                          }}
                        >
                          {props.t("Next")}
                        </Button>
                      ) : null}
                      {formStep == 1 ? (
                        <Button
                          type="submit"
                          className="btn-success me-2"
                          onClick={() => { }}
                        >
                          {props.t("Save")}
                        </Button>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>



          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompany(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {confirm_alert_qr ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert_qr(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompanyQRPhoto(selectedId))
                setSelectedId(null)
                setSelectedCompany(null)
                qrTemplateToggle()
              }}
              onCancel={() => setconfirm_alert_qr(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetCompany())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
           {confirm_alert_approve ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Approve")}
                    onConfirm={() => {
                        setConfirm_alert_approve(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Approved"));
                        setdynamic_description("");
                        const updateBrand = {
                          _id: selectedId,
                          status: "approved",
                        
                          
                      };
                      
                        dispatch(onUpdatePartnership(updateBrand)); 
                       
                        setSelectedId(null);
                    }}
                    onCancel={() => setConfirm_alert_approve(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}
             {confirm_alert_reject ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Reject")}
                    onConfirm={() => {
                      setConfirm_alert_reject(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Rejected"));
                        setdynamic_description("");
                        const updateBrand = {
                          _id: selectedId,
                          status: "reject",
                        
                          
                      };
                      
                        dispatch(onUpdatePartnership(updateBrand)); 
                       
                        setSelectedId(null);
                    }}
                    onCancel={() => setConfirm_alert_reject(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Companies))
