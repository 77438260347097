import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
//Modals


import ApplicationPlanModal from "./Modals/applicationPlanModal"
import { authorize, Role } from "./../../components/helpers/authorize"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  getOrientations as onGetOrientation,

  getCompanyBranches as onGetCompanyBranches,  
  addPlan as onAddMyPlans,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  deleteOrientation as onDeletePlans_MyPlans,  
  updatePlan as onUpdatePlans_MyPlans,

  addQRCode as onAddQRCode,

} from "store/actions"

import { defaultsDeep, isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { select } from "redux-saga/effects"
import types from "react-cropper"


const Plans = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)

  const [modalQr, setModalQr] = useState(false)
 
  const [infoQr, setInfoQr] = useState()
  const [selectedId, setSelectedId] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [copyPlan, setCopyPlan] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [copy_alert, setCopy_alert] = useState(false)

  const [isGroupButton, setIsGroupButton] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [selectedWorkType, setSelectedWorkType] = useState()

  const [selectedPosition, setSelectedPosition] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [moduleInfo, setModuleInfo] = useState([]) 
  const [qrListData, setQrListData] = useState()
 
  const [planObjects, setPlanObjects] = useState([
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },
  ])

  const { error,Orientations  } = useSelector(
    state => ({
      error: state.Plans?.error,
      Orientations:state.Orientation?.data
      
    
    })
  )
  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(getUserByMail(user.email))
   
    
    if (authorize([Role.Admin,Role.YetkiliIk])) {
      dispatch(onGetOrientation())
      dispatch(onGetCompanyBranches(user.companyId))
    } 
  }, [dispatch])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
 
 

  //Dynamic Inputs START
  const handleValidSubmit = (e, v) => {
   
    dispatch(
      onAddQRCode({ 
        BranchId: v.BranchId,
        CompanyId: user?.companyId,
        qrCode: v.qrCode, 
        title: v.title,
        description: v.description,
        tag: v.tag,
      })
    )
    toggleAddQr()
  }
  const branchReset = () => {
    setSelectedBranchId("")
  }
  const InputAddReset = () => {
    let tempInput = [
      {
        title: "",
        isType: "test",
        isValue: "",
        isPlan: [""],
        isOptions:["",""],
        targetValue : ""
       
      }, 
    ]
    setPlanObjects(tempInput)
  }
 

  //Dynamic Inputs END
  const columns = [
    {
      dataField: "name",
      text: props.t("Form Name"),
    },
    {
      dataField: "description",
      text: props.t("Form Description"),
      formatter: (cell, row) => (
        <span style={{ whiteSpace: "normal" }}>{cell}</span>
      ),
    },
    

    {
      dataField: "CreatorUserId",
      text: props.t("Creator User Name"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },
   
   
    {
      dataField: "planInfo",
      text: props.t("Plan Info"),
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            //href={"/appForm/" + row._id}
            onClick={() => {
              window.open("/orientation/" + row._id +"/test/")
              //window.open(config.baseApi+"appForm/"+ row._id);
            }}
          >
            {props.t("View Form")}
          </Button>
        </div>
      ),
    },

    {
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end">
             
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)
                  console.log("row",row)
                  setSelectedWorkType(row.workType)
                  setSelectedPosition(row.position)
                 
                 
                  setSelectedBranchId(row.BranchId?._id)

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                    setPlanDescription(row.planDesc)
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Plans ? Plans.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Plans ? Plans.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidPlanSubmit = (e, values) => {
    if (isEdit) {
      const updatePlan = {
        _id: selectedPlan._id,
        name: values.name,
        description: values["description"],
        CompanyId: user.companyId,
        CreatorUserId: user.uid,
        BranchId: BranchId.value,
        workType: values["workType"],
        returnURL: values["returnURL"],
        PlanObjects:
          document.getElementById("PlanObjects").value ||
          JSON.stringify(planObjects),
        planDesc: document.getElementById("planDesc")?.value || planDescription,
        //PlanObjects:JSON.stringify(planObjects)
      }
      // update Plan

      dispatch(onUpdatePlans_MyPlans(updatePlan))
    } else {
      const newPlan = {
        name: values["name"],
        description: values["description"],
        CompanyId: user.companyId,
        workType: values["workType"],
        returnURL: values["returnURL"],
        CreatorUserId: user.uid,
        PlanObjects: document.getElementById("PlanObjects").value,
        BranchId: BranchId.value,
        planDesc: document.getElementById("planDesc").value,
      }
      // save new Plan

      dispatch(onAddMyPlans(newPlan))
    }
    toggle()
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }

  return (
    <React.Fragment>
      <div>
       
        
          {/* Render Breadcrumb */}
          <Breadcrumb
           
            breadcrumbItem={props.t("ORYANTASYON FORMU EKLE")}
            addBtn={true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedPlan({
                name: "",
                description: "",

                PlanObjects: [],
              })
              branchReset()
              InputAddReset()

              toggle()
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Orientations}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Orientations}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={props.t(
                                  "You Don't Have a Plan Yet"
                                )}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          
    
     
          <ApplicationPlanModal
            modal={modal}
            isEdit={isEdit}
            selectedPlan={selectedPlan}
            selectedBranchId={selectedBranchId}
            selectedWorkType={selectedWorkType}
            selectedPosition = {selectedPosition}
          
            planObjects={planObjects}
            toggle={() => {
              toggle()
            }}
          />

         

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeletePlans_MyPlans(selectedId))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {copy_alert ? (
            <SweetAlert
              //title={props.t("Kopyalamak istediğinize emin misiniz?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Copy")}
              onConfirm={() => {
                setCopy_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Copied"))
                setdynamic_description("")

                dispatch(onAddMyPlans(copyPlan))

                setSelectedId(null)
              }}
              onCancel={() => setCopy_alert(false)}
            >
              {props.t("Copy Plan?")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
