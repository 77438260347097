import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_AVM_INFOS, DELETE_AVM_INFO, ADD_AVM_INFO,GET_AVM_INFOS_FOR_QR,
    UPDATE_AVM_INFO_ACTIVE, UPDATE_AVM_INFO,GET_AVM_INFOS_MY_AVM_INFOS} from "./actionTypes";
import { 
    getAvm_InfosSuccess, getAvm_InfosFail,
    deleteAvm_InfoSuccess, deleteAvm_InfoFail,
    updateAvm_InfoSuccess, updateAvm_InfoFail,
    addAvm_InfoSuccess, addAvm_InfoFail,
    updateAvm_InfoActiveSuccess,updateAvm_InfoActiveFail,
    getAvm_InfosForQrSuccess,getAvm_InfosForQrFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getAvm_Infos, //API CONNECTION
    delAvm_Info,
    addAvm_Info,
    updateAvm_Info, 
    updateAvm_InfoActive, 
    getAvm_InfosForQr
    
}
    from "../../helpers/fakebackend_helper"; 

    function* fetchAvm_InfosForQr({ payload: id }) {
        try {
            const response = yield call(getAvm_InfosForQr,id)
            yield put(getAvm_InfosForQrSuccess(response));
        } catch (error) {
            yield put(getAvm_InfosForQrFail(error));
        }
    }
function* fetchAvm_Infos() {
    try {
        const response = yield call(getAvm_Infos)
        yield put(getAvm_InfosSuccess(response));
    } catch (error) {
        yield put(getAvm_InfosFail(error));
    }
}

function* onDeleteAvm_Infos({payload:id}){
    try{
        const response = yield call(delAvm_Info, id)
        yield put(deleteAvm_InfoSuccess(response));
    } catch (error) {
        yield put(deleteAvm_InfoFail(error));
    }
}

function* onAddAvm_Info({payload:avm_Info, files: files}){
    try{
        const response = yield call(addAvm_Info, avm_Info, files)
        yield put(addAvm_InfoSuccess(response));
    } catch (error) {
        yield put(addAvm_InfoFail(error));
    }
}

function* onUpdateAvm_Info({payload:avm_Info, file: file}){
    try{
        const response = yield call(updateAvm_Info, avm_Info,file)
        yield put(updateAvm_InfoSuccess(response));
    } catch (error) {
        yield put(updateAvm_InfoFail(error));
    }
}
function* onUpdateAvm_InfoActive({payload:avm_Info}){
    try{
        const response = yield call(updateAvm_InfoActive, avm_Info)
        yield put(updateAvm_InfoActiveSuccess(response));
    } catch (error) {
        yield put(updateAvm_InfoActiveFail(error));
    }
}

export function* avm_InfosSaga() {
    yield takeEvery(GET_AVM_INFOS, fetchAvm_Infos);
    yield takeEvery(DELETE_AVM_INFO, onDeleteAvm_Infos);
    yield takeEvery(ADD_AVM_INFO, onAddAvm_Info);
    yield takeEvery(UPDATE_AVM_INFO, onUpdateAvm_Info);
    yield takeEvery(UPDATE_AVM_INFO_ACTIVE, onUpdateAvm_InfoActive);
    yield takeEvery(GET_AVM_INFOS_FOR_QR, fetchAvm_InfosForQr);
   
    
}

export default avm_InfosSaga;
