import {
    GET_ORIENTATIONS,
    GET_ORIENTATIONS_SUCCESS,
    GET_ORIENTATIONS_FAIL,
    GET_ORIENTATION_DETAIL,
    GET_ORIENTATION_DETAIL_SUCCESS,
    GET_ORIENTATION_DETAIL_FAIL,
    ADD_ORIENTATION,
    ADD_ORIENTATION_SUCCESS,
    ADD_ORIENTATION_FAIL, 
    UPDATE_ORIENTATION,
    UPDATE_ORIENTATION_SUCCESS,
    UPDATE_ORIENTATION_FAIL,
    DELETE_ORIENTATION,
    DELETE_ORIENTATION_SUCCESS,
    DELETE_ORIENTATION_FAIL,
    RESET_ORIENTATION,
   
} from "./actionTypes";

export const getOrientationDetail = (id) => ({
    type: GET_ORIENTATION_DETAIL, 
    payload:id
}); 
export const getOrientationDetailSuccess = (data) => ({ 
    type: GET_ORIENTATION_DETAIL_SUCCESS,
    payload: data
});
export const getOrientationDetailFail = (error) => ({
    type: GET_ORIENTATION_DETAIL_FAIL, 
    payload: error,
});
export const resetOrientation = () => ({
    type: RESET_ORIENTATION,
});

export const getOrientations = () => ({
    type: GET_ORIENTATIONS,
});

export const getOrientationsSuccess = (orientation) => ({
    type: GET_ORIENTATIONS_SUCCESS,
    payload: orientation,
});


export const getOrientationsFail = (error) => ({
    type: GET_ORIENTATIONS_FAIL,
    payload: error,
});

export const deleteOrientation = (id) => ({
    type:  DELETE_ORIENTATION,
    payload: id
});

export const deleteOrientationSuccess = (data) => ({
    type: DELETE_ORIENTATION_SUCCESS,
    payload: data
});

export const deleteOrientationFail = (error) => ({
    type: DELETE_ORIENTATION_FAIL,
    payload: error,
});


export const addOrientation = (orientation) => ({
    type: ADD_ORIENTATION,
    payload: orientation
});

export const addOrientationSuccess = (data) => ({
    type: ADD_ORIENTATION_SUCCESS,
    payload: data
});

export const addOrientationFail = (error) => ({
    type: ADD_ORIENTATION_FAIL,
    payload: error,
});


export const updateOrientation = (orientation) => ({
    type: UPDATE_ORIENTATION,
    payload: orientation
});

export const updateOrientationSuccess = (data) => ({
    type: UPDATE_ORIENTATION_SUCCESS,
    payload: data
});

export const updateOrientationFail = (error) => ({
    type: UPDATE_ORIENTATION_FAIL,
    payload: error,
});