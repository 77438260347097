import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ConfirmReducer from "./auth/confirm/reducer"
import userForgetPassword from "./auth/forgetpwd/reducer"
import changePassword from "./auth/changePassword/reducer"
import Profile from "./auth/profile/reducer"


//Dashboard 
import Dashboard from "./dashboard/reducer";

//Companies
import Companies from "./companies/reducer";

//plan
import Plans from "./plan/reducer";

//appForm
import AppForm from "./appForm/reducer"

//appFormPlan
import RequestFormPlan from "./requestFormPlan/reducer"

//Branchs
import Branchs from "./branchs/reducer";

//QRCode
import QRCode from "./qrcode/reducer"
import QRAnonymus from "./qr-anonymus/reducer"
import Analytics from "./analytics/reducer"
import Locations from "./locations/reducer"

//advertisement
import Advertisement from "./advertisement/reducer"

//partnership
import Partnership from "./partnership/reducer"

//sector
import Sector from "./sector/reducer"

//avmInfo
import AvmInfo from "./avmInfo/reducer"

//status
import Status from "./status/reducer"

//survey
import Survey from "./survey/reducer"

//surveyForm
import SurveyForm from "./surveyForm/reducer"

//surveySent
import SurveySent from "./surveySent/reducer"

//orientation
import Orientation from "./orientation/reducer"

//orientationForm
import OrientationForm from "./orientationForm/reducer"

//orientationSent
import OrientationSent from "./orientationSent/reducer"

//duty
import Duty from "./duty/reducer"

//planOutside
import PlanOutside from "./planOutside/reducer"

//appFormOutside
import AppFormOutside from "./appFormOutside/reducer"

//orientationPeriod
import OrientationPeriod from "./orientationPeriod/reducer"

//devDuty
import DevDuty from "./devDuty/reducer"

//label
import Label from "./label/reducer"

//softSkill
import SoftSkill from "./softSkill/reducer"

//foreign Language
import ForeignLanguage from "./foreignLanguage/reducer"

//devForeignLanguage
import DevForeignLanguage from "./devForeignLanguage/reducer"

//devProgram
import DevProgram from "./devProgram/reducer"

//program
import Program from "./program/reducer"

//backUrl
import BackUrl from "./backUrl/reducer"

//devSectorForm
import DevSectorForm from "./devSectorForm/reducer"

//sector Form
import SectorForm from "./sectorForm/reducer"

//devDutyForm
import DevDutyForm from "./devDutyForm/reducer"

//DutyForm
import DutyForm from "./dutyForm/reducer"

//DevWorkType
import DevWorkType from "./devWorkType/reducer"

//workType
import WorkType from "./workType/reducer"

//users
import Users from "./users/reducer"

//dutiesForm
import DutiesForm from "./dutiesForm/reducer"  

//DevRequestReason
import DevRequestReason from "./devRequestReason/reducer"

//RequestReason
import RequestReason from "./requestReason/reducer"

//Reports
import Reports from "./reports/reducer"

//Login Log
import LoginLogs from "./loginLog/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  userForgetPassword,
  changePassword,
  ConfirmReducer,
  Profile,
  Dashboard,
  Plans,
  Companies,
  AppForm,
  RequestFormPlan,
  Branchs,
  QRCode,
  QRAnonymus,
  Analytics,
  Locations,
  Advertisement,
  Partnership,
  Sector,
  AvmInfo,
  Status,
  Survey,
  SurveyForm,
  SurveySent,
  Orientation,
  OrientationForm,
  OrientationSent,
  Duty,
  PlanOutside,
  AppFormOutside,
  OrientationPeriod,
  DevDuty,
  Label,
  SoftSkill,
  ForeignLanguage,
  DevForeignLanguage,
  DevProgram,
  Program,
  BackUrl,
  DevSectorForm,
  SectorForm,
  DevDutyForm,
  DutyForm,
  DevWorkType,
  WorkType,
  Users,
  DutiesForm,
  DevRequestReason,
  RequestReason,
  Reports,
  LoginLogs
})

export default rootReducer
