/** Get AVM_INFO */
export const GET_AVM_INFOS = "GET_AVM_INFOS";
export const GET_AVM_INFOS_SUCCESS = "GET_AVM_INFOS_SUCCESS";
export const GET_AVM_INFOS_FAIL = "GET_AVM_INFOS_FAIL";

export const GET_AVM_INFO_DETAIL = "GET_AVM_INFO_DETAIL";
export const GET_AVM_INFO_DETAIL_SUCCESS = "GET_AVM_INFO_DETAIL_SUCCESS";
export const GET_AVM_INFO_DETAIL_FAIL = "GET_AVM_INFO_DETAIL_FAIL";


export const GET_AVM_INFOS_FOR_QR = "GET_AVM_INFOS_FOR_QR";
export const GET_AVM_INFOS_FOR_QR_SUCCESS = "GET_AVM_INFOS_FOR_QR_SUCCESS";
export const GET_AVM_INFOS_FOR_QR_FAIL = "GET_AVM_INFOS_FOR_QR_FAIL";


export const ADD_AVM_INFO = "ADD_AVM_INFO";
export const ADD_AVM_INFO_SUCCESS = "ADD_AVM_INFO_SUCCESS";
export const ADD_AVM_INFO_FAIL = "ADD_AVM_INFO_FAIL";

export const UPDATE_AVM_INFO = "UPDATE_AVM_INFO";
export const UPDATE_AVM_INFO_SUCCESS = "UPDATE_AVM_INFO_SUCCESS";
export const UPDATE_AVM_INFO_FAIL = "UPDATE_AVM_INFO_FAIL";

export const DELETE_AVM_INFO = "DELETE_AVM_INFO";
export const DELETE_AVM_INFO_SUCCESS = "DELETE_AVM_INFO_SUCCESS";
export const DELETE_AVM_INFO_FAIL = "DELETE_AVM_INFO_FAIL"; 

export const UPDATE_AVM_INFO_ACTIVE = "UPDATE_AVM_INFO_ACTIVE";
export const UPDATE_AVM_INFO_ACTIVE_SUCCESS = "UPDATE_AVM_INFO_ACTIVE_SUCCESS";
export const UPDATE_AVM_INFO_ACTIVE_FAIL = "UPDATE_AVM_INFO_ACTIVE_FAIL"; 



export const RESET_AVM_INFO= "RESET_AVM_INFO";