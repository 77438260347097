import {
    GET_REQUEST_REASONS,
    GET_REQUEST_REASONS_SUCCESS,
    GET_REQUEST_REASONS_FAIL,
    GET_REQUEST_REASON_DETAIL,
    GET_REQUEST_REASON_DETAIL_SUCCESS,
    GET_REQUEST_REASON_DETAIL_FAIL,
    ADD_REQUEST_REASON,
    ADD_REQUEST_REASON_SUCCESS,
    ADD_REQUEST_REASON_FAIL,
    UPDATE_REQUEST_REASON,
    UPDATE_REQUEST_REASON_SUCCESS,
    UPDATE_REQUEST_REASON_FAIL,
    DELETE_REQUEST_REASON,
    DELETE_REQUEST_REASON_SUCCESS,
    DELETE_REQUEST_REASON_FAIL,
    RESET_REQUEST_REASON,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const RequestReasons = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_REQUEST_REASONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: state.data.filter(requestReason => requestReason._id.toString() !== action.payload.data.toString())
            }
        case ADD_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: state.data.map(requestReason =>
                    requestReason._id.toString() === action.payload.data._id.toString() ?
                        { requestReason, ...action.payload.data } :
                        requestReason
                )
            }

        //FAILS
        case GET_REQUEST_REASONS_FAIL:
        case DELETE_REQUEST_REASON_FAIL:
        case ADD_REQUEST_REASON_FAIL:
        case UPDATE_REQUEST_REASON_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_REQUEST_REASON: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default RequestReasons;