import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
    Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
    FormGroup, Alert
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
    addProcessListPayment as OnAddProcessListPayment,

} from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const preSelectionSwitch = (props, cb) => {
    const [infoArea, setInfoArea] = useState(false)





    return (

        <Col
            className="form-check form-switch form-switch-lg mb-3" style={{ float: "right", display: 'flex', flexDirection: 'row' }}
        >
            <label
                className="form-check-label"
                htmlFor="cashSale"
                style={{ marginRight: '54px' }}
            >
                {props.t("Pre Selection")}
            </label>
            <input
                type="checkbox"
                name="cashSale"
                id="cashSale"
                className="form-check-input"
                checked={props.switch}
                onChange={(e) => {
                    props.setSwitch(!props.switch);

                }}
            />

        </Col>
    )
}


preSelectionSwitch.propTypes = {

   
    isValue: PropTypes.func,
    t: PropTypes.any,
    setSwitch: PropTypes.any,
    switch: PropTypes.any

}


export default withRouter(
    (withTranslation()(preSelectionSwitch))
)
