import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_BACK_URLS, DELETE_BACK_URL, ADD_BACK_URL, UPDATE_BACK_URL,GET_BACK_URLS_MY_BACK_URLS} from "./actionTypes";
import {
    getBackUrlsSuccess, getBackUrlsFail,
    deleteBackUrlSuccess, deleteBackUrlFail,
    updateBackUrlSuccess, updateBackUrlFail,
    addBackUrlSuccess, addBackUrlFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getBackUrls, //API CONNECTION
    delBackUrl,
    addBackUrl,
    updateBackUrl,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchBackUrls() {
    try {
        const response = yield call(getBackUrls)
        yield put(getBackUrlsSuccess(response));
    } catch (error) {
        yield put(getBackUrlsFail(error));
    }
}

function* onDeleteBackUrls({payload:id}){
    try{
        const response = yield call(delBackUrl, id)
        yield put(deleteBackUrlSuccess(response));
    } catch (error) {
        yield put(deleteBackUrlFail(error));
    }
}

function* onAddBackUrl({payload:backUrl}){
    try{
        const response = yield call(addBackUrl, backUrl)
        yield put(addBackUrlSuccess(response));
    } catch (error) {
        yield put(addBackUrlFail(error));
    }
}

function* onUpdateBackUrl({payload:backUrl}){
    try{
        const response = yield call(updateBackUrl, backUrl)
        yield put(updateBackUrlSuccess(response));
    } catch (error) {
        yield put(updateBackUrlFail(error));
    }
}

export function* backUrlsSaga() {
    yield takeEvery(GET_BACK_URLS, fetchBackUrls);
    yield takeEvery(DELETE_BACK_URL, onDeleteBackUrls);
    yield takeEvery(ADD_BACK_URL, onAddBackUrl);
    yield takeEvery(UPDATE_BACK_URL, onUpdateBackUrl);
   
    
}

export default backUrlsSaga;
