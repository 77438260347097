import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PROGRAMS, DELETE_PROGRAM, ADD_PROGRAM, UPDATE_PROGRAM,GET_PROGRAMS_MY_PROGRAMS} from "./actionTypes";
import {
    getProgramsSuccess, getProgramsFail,
    deleteProgramSuccess, deleteProgramFail,
    updateProgramSuccess, updateProgramFail,
    addProgramSuccess, addProgramFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPrograms, //API CONNECTION
    delProgram,
    addProgram,
    updateProgram,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchPrograms() {
    try {
        const response = yield call(getPrograms)
        yield put(getProgramsSuccess(response));
    } catch (error) {
        yield put(getProgramsFail(error));
    }
}

function* onDeletePrograms({payload:id}){
    try{
        const response = yield call(delProgram, id)
        yield put(deleteProgramSuccess(response));
    } catch (error) {
        yield put(deleteProgramFail(error));
    }
}

function* onAddProgram({payload:program}){
    try{
        const response = yield call(addProgram, program)
        yield put(addProgramSuccess(response));
    } catch (error) {
        yield put(addProgramFail(error));
    }
}

function* onUpdateProgram({payload:program}){
    try{
        const response = yield call(updateProgram, program)
        yield put(updateProgramSuccess(response));
    } catch (error) {
        yield put(updateProgramFail(error));
    }
}

export function* programsSaga() {
    yield takeEvery(GET_PROGRAMS, fetchPrograms);
    yield takeEvery(DELETE_PROGRAM, onDeletePrograms);
    yield takeEvery(ADD_PROGRAM, onAddProgram);
    yield takeEvery(UPDATE_PROGRAM, onUpdateProgram);
   
    
}

export default programsSaga;
