import {
    GET_WORK_TYPES,
    GET_WORK_TYPES_SUCCESS,
    GET_WORK_TYPES_FAIL,
    GET_WORK_TYPE_DETAIL,
    GET_WORK_TYPE_DETAIL_SUCCESS,
    GET_WORK_TYPE_DETAIL_FAIL,
    ADD_WORK_TYPE,
    ADD_WORK_TYPE_SUCCESS,
    ADD_WORK_TYPE_FAIL,
    UPDATE_WORK_TYPE,
    UPDATE_WORK_TYPE_SUCCESS,
    UPDATE_WORK_TYPE_FAIL,
    DELETE_WORK_TYPE,
    DELETE_WORK_TYPE_SUCCESS,
    DELETE_WORK_TYPE_FAIL,
    RESET_WORK_TYPE,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const WorkTypes = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_WORK_TYPES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(workType => workType._id.toString() !== action.payload.data.toString())
            }
        case ADD_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: state.data.map(workType =>
                    workType._id.toString() === action.payload.data._id.toString() ?
                        { workType, ...action.payload.data } :
                        workType
                )
            }

        //FAILS
        case GET_WORK_TYPES_FAIL:
        case DELETE_WORK_TYPE_FAIL:
        case ADD_WORK_TYPE_FAIL:
        case UPDATE_WORK_TYPE_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_WORK_TYPE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default WorkTypes;