/** Get REQUEST_REASON */
export const GET_REQUEST_REASONS = "GET_REQUEST_REASONS";
export const GET_REQUEST_REASONS_SUCCESS = "GET_REQUEST_REASONS_SUCCESS";
export const GET_REQUEST_REASONS_FAIL = "GET_REQUEST_REASONS_FAIL";

export const GET_REQUEST_REASON_DETAIL = "GET_REQUEST_REASON_DETAIL";
export const GET_REQUEST_REASON_DETAIL_SUCCESS = "GET_REQUEST_REASON_DETAIL_SUCCESS";
export const GET_REQUEST_REASON_DETAIL_FAIL = "GET_REQUEST_REASON_DETAIL_FAIL";


export const ADD_REQUEST_REASON = "ADD_REQUEST_REASON";
export const ADD_REQUEST_REASON_SUCCESS = "ADD_REQUEST_REASON_SUCCESS";
export const ADD_REQUEST_REASON_FAIL = "ADD_REQUEST_REASON_FAIL";

export const UPDATE_REQUEST_REASON = "UPDATE_REQUEST_REASON";
export const UPDATE_REQUEST_REASON_SUCCESS = "UPDATE_REQUEST_REASON_SUCCESS";
export const UPDATE_REQUEST_REASON_FAIL = "UPDATE_REQUEST_REASON_FAIL";

export const DELETE_REQUEST_REASON = "DELETE_REQUEST_REASON";
export const DELETE_REQUEST_REASON_SUCCESS = "DELETE_REQUEST_REASON_SUCCESS";
export const DELETE_REQUEST_REASON_FAIL = "DELETE_REQUEST_REASON_FAIL"; 



export const RESET_REQUEST_REASON= "RESET_REQUEST_REASON";