import {
    GET_ORIENTATION_FORMS,
    GET_ORIENTATION_FORMS_SUCCESS,
    GET_ORIENTATION_FORMS_FAIL,
    GET_ORIENTATION_FORM_DETAIL,
    GET_ORIENTATION_FORM_DETAIL_SUCCESS,
    GET_ORIENTATION_FORM_DETAIL_FAIL,
    ADD_ORIENTATION_FORM,
    ADD_ORIENTATION_FORM_SUCCESS,
    ADD_ORIENTATION_FORM_FAIL,
    UPDATE_ORIENTATION_FORM,
    UPDATE_ORIENTATION_FORM_SUCCESS,
    UPDATE_ORIENTATION_FORM_FAIL,
    DELETE_ORIENTATION_FORM,
    DELETE_ORIENTATION_FORM_SUCCESS,
    DELETE_ORIENTATION_FORM_FAIL,
    RESET_ORIENTATION_FORM,
    
    
} from "./actionTypes";


export const resetOrientationForm = () => ({
    type: RESET_ORIENTATION_FORM,
});

export const getOrientationForms = (appFormId) => ({
    type: GET_ORIENTATION_FORMS,
    payload: appFormId,
});

export const getOrientationFormsSuccess = (orientationForm) => ({
    type: GET_ORIENTATION_FORMS_SUCCESS,
    payload: orientationForm,
});


export const getOrientationFormsFail = (error) => ({
    type: GET_ORIENTATION_FORMS_FAIL,
    payload: error,
});

export const deleteOrientationForm = (id) => ({
    type:  DELETE_ORIENTATION_FORM,
    payload: id
});

export const deleteOrientationFormSuccess = (data) => ({
    type: DELETE_ORIENTATION_FORM_SUCCESS,
    payload: data
});

export const deleteOrientationFormFail = (error) => ({
    type: DELETE_ORIENTATION_FORM_FAIL,
    payload: error,
});


export const addOrientationForm = (orientationForm) => ({
    type: ADD_ORIENTATION_FORM,
    payload: orientationForm
});

export const addOrientationFormSuccess = (data) => ({
    type: ADD_ORIENTATION_FORM_SUCCESS,
    payload: data
});

export const addOrientationFormFail = (error) => ({
    type: ADD_ORIENTATION_FORM_FAIL,
    payload: error,
});


export const updateOrientationForm = (orientationForm) => ({
    type: UPDATE_ORIENTATION_FORM,
    payload: orientationForm
});

export const updateOrientationFormSuccess = (data) => ({
    type: UPDATE_ORIENTATION_FORM_SUCCESS,
    payload: data
});

export const updateOrientationFormFail = (error) => ({
    type: UPDATE_ORIENTATION_FORM_FAIL,
    payload: error,
});