/** Get BACK_URL */
export const GET_BACK_URLS = "GET_BACK_URLS";
export const GET_BACK_URLS_SUCCESS = "GET_BACK_URLS_SUCCESS";
export const GET_BACK_URLS_FAIL = "GET_BACK_URLS_FAIL";

export const GET_BACK_URL_DETAIL = "GET_BACK_URL_DETAIL";
export const GET_BACK_URL_DETAIL_SUCCESS = "GET_BACK_URL_DETAIL_SUCCESS";
export const GET_BACK_URL_DETAIL_FAIL = "GET_BACK_URL_DETAIL_FAIL";


export const ADD_BACK_URL = "ADD_BACK_URL";
export const ADD_BACK_URL_SUCCESS = "ADD_BACK_URL_SUCCESS";
export const ADD_BACK_URL_FAIL = "ADD_BACK_URL_FAIL";

export const UPDATE_BACK_URL = "UPDATE_BACK_URL";
export const UPDATE_BACK_URL_SUCCESS = "UPDATE_BACK_URL_SUCCESS";
export const UPDATE_BACK_URL_FAIL = "UPDATE_BACK_URL_FAIL";

export const DELETE_BACK_URL = "DELETE_BACK_URL";
export const DELETE_BACK_URL_SUCCESS = "DELETE_BACK_URL_SUCCESS";
export const DELETE_BACK_URL_FAIL = "DELETE_BACK_URL_FAIL"; 



export const RESET_BACK_URL= "RESET_BACK_URL";