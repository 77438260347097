import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  Input,
  FormGroup,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"

import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"

import {
  getQRAnonymus as onGetQR,
  getCompanyById as onGetCompanyById,
} from "store/actions"

import _, { includes, isEmpty, map, update, valuesIn } from "lodash"
import moment from "moment"

import "./datatables.scss"
import classnames from "classnames"
import imgEmpty from "./../../../assets/images/appforms/buqrda_is_var_d2.png"
import footerIcon from "./../../../assets/images/appforms/buqrdais.png"
import allIcon from "./../../../assets/images/favicon.png"

import { post } from "./../../../helpers/api_helper"

const QrV2 = props => {
  const dispatch = useDispatch()
  const ImgBase = ("https://api.buqrdaisvar.com/") + "static/uploads/";//process.env.REACT_APP_BASE_API
  //const ImgBase = (" http://localhost:4013/") + "static/uploads/";//process.env.REACT_APP_BASE_API


  const [selectedForm, setSelectedForm] = useState("")
  const [selectedCompany, setSelectedCompany] = useState()
  const [showForms, setShowForms] = useState(false)
  const [formKey, setFormKey] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [formStep, setFormStep] = useState(0)
  const [value, setValue] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [tabTitleList, setTabTitleList] = useState([])
  const [CompanyInfo, setCompanyInfo] = useState()
  const [Branches, setBranches] = useState([])
  const [mainJobs, setMainJobs] = useState([])
  const [qr, setQR] = useState()
  const [selectedTab, setSelectedTab] = useState("*")
  const [selectedBranch, setSelectedBranch] = useState([])

  const { error, QRAnonymus, Company } = useSelector(state => ({
    error: state.QRAnonymus?.error,
    QRAnonymus: state.QRAnonymus?.data,
    Company: state.Companies?.company
  }))
  const {
    match: { params },
  } = props
  const { location } = props
  const DomainBase = process.env.REACT_APP_DOMAIN

  let user = JSON.parse(localStorage.getItem("authUser"))
  const colorFactor = 7;

  useEffect(() => {
    dispatch(onGetQR(params.id))
    console.log(location, props)
  }, [])
  useEffect(() => {
    if (!isEmpty(CompanyInfo)) {

      dispatch(onGetCompanyById(CompanyInfo.companyId))
    }

  }, CompanyInfo)
  console.log("QrAnon", QRAnonymus)

  useEffect(() => {
    setFormStep(0)
    if (isEmpty(tabTitleList) && !isEmpty(QRAnonymus)) {
      console.log("QRAnonymus", QRAnonymus)
      setCompanyInfo(QRAnonymus.branchMain)
      setBranches(QRAnonymus.branchList)
      setQR(QRAnonymus.qr)
      setMainJobs(QRAnonymus.mainJobs)
      let tList = []
      tList.push({ title: "*", selected: true })
      for (let b of QRAnonymus.globalJobTypes) {
        tList.push({ title: b, selected: false })
      }
      // for (let b of Branches) {
      //   for (let pl of b.AppPlans) {
      //     if (tList.filter(x => x.title == pl.workType).length == 0) {
      //       tList.push({
      //         title: pl.workType,
      //         selected: false,
      //         branches: [b.Branch._id],
      //       })
      //     } else {
      //       tList
      //         .filter(x => x.title == pl.workType)[0]
      //         ?.branches.push(b.Branch._id)
      //     }
      //   }
      // }
      console.log("Liste :", tList)
      setTabTitleList(tList)
    }
  }, [QRAnonymus])

  useEffect(() => {
    if (!isEmpty(QRAnonymus) && !isEmpty(CompanyInfo)) {
      analytic()
    }
  }, [CompanyInfo, Branches])

  const analytic = async () => {
    let tagArr = []
    if (!isEmpty(location.search)) {
      let tagArrSplit = location.search.replace("?", "").split("&")
      for (let tIndex in tagArrSplit) {
        let tagKeyVal = tagArrSplit[tIndex].split("=")
        if (tagKeyVal.length == 2) {
          let istag = {}
          istag[tagKeyVal[0]] = tagKeyVal[1]
          tagArr.push(istag)
        }
      }
      console.log(tagArr)
      console.log(CompanyInfo)

      const analyticModel = {
        base: {
          metakey: location.pathname.replace("/v2/", ""),
          title: CompanyInfo.name,
          description: CompanyInfo.description,
          link: location.pathname.replace("/v2/", ""),
          CompanyId: qr.CompanyId,
          BranchId: qr.BranchId
        },
        model: {
          title: CompanyInfo.name,
          description: CompanyInfo.description,
          link: location.pathname.replace("/v2/", ""),
          CompanyId: qr.CompanyId,
          tags: tagArr,
          BranchId: qr.BranchId
        },
      }

      await post("api/analytic/add", analyticModel)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    try {
      console.log(props.location)
      let searchParam = !location.search.includes("share=friend")
        ? !location.search.includes("?")
          ? "?share=friend"
          : location.search + "&share=friend"
        : location.search
      const shareLink = DomainBase + location.pathname + searchParam

      console.log("Congrats! Your browser supports Web Share API", shareLink)
      await navigator
        .share({
          title: CompanyInfo?.name,
          text: "Bu QR'da iş var",
          url: shareLink,
        })
        .then(() => {
          console.log("Sharing successfull")
        })
        .catch(() => {
          console.log("Sharing failed")
        })
    } catch (err) {
      console.log("Sorry! Your browser does not support Web Share API", err)
    }
  }

  console.log("selected BRanch",selectedBranch)
  const DataBranch = (branchArr, _class = "", _display = "block", tempIndex = 0) => {
    branchArr = selectedTab == "*" ? branchArr : branchArr.filter(x => x.jobTypes.includes(selectedTab));
    return (
      <div>
        {isEmpty(branchArr)
          ? null
          : branchArr?.map((branch, key) => (
            <Col
              md="12"
              className={"mt-2 ps-2 pe-2 " + _class}
              key={"_col_" + key}
              id={branch._id}
              style={{ display: _display }}
            >
              <div>
                <Card
                  style={{
                    border:isEmpty(selectedBranch)?
                    null
                    :(selectedBranch.findIndex(x => x._id === branch?._id) ==-1)?null:"1px solid black" 
                    ,
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                    height: "111px",
                    position: "relative",
                    cursor: "pointer",
                    backgroundColor: `rgb(${(255 - (tempIndex * colorFactor).toString())} ${(255 - (tempIndex * colorFactor).toString())} ${(255 - (tempIndex * colorFactor).toString())})`
                  }}
                  onClick={() => {
                    
                     let values = [...selectedBranch]
                     console.log("index",values.findIndex(x => x._id === branch?._id))
                     if(values.findIndex(x => x._id === branch?._id) ==-1){
                      values.push(branch)
                      setSelectedBranch(values)
                     }
                    else{
                      values.splice(values.findIndex(x => x._id === branch?._id),1)
                      setSelectedBranch(values)
                    }
                    for (let el of document.querySelectorAll(
                      ".a_" + branch._id
                    )) {
                      //  console.log("el.style, el.style.cssText")
                      // el.style.transition = ".5s all linear";
                      // el.style.animation = "smoothDisplayNone 0.5s linear forwards";
                      el.style.display = el.style.cssText.includes("display: block;")? 
                        "none"
                        :
                         "block"
                    }
                  }}
                >
                  <CardBody className="justify-content-center align-items-center">
                    <Row xl="12" lg="12" sm="12" md="12" xs="12" >
                      <Col xl="12" lg="12" sm="12" md="12" xs="12" >

                        {(!isEmpty(branch?.photo) || !isEmpty(CompanyInfo?.photo) || !isEmpty(Company?.photo)) ? ( 
                          <Row style={{position:"relative"}}>
                            <Col className="text-end col-6">
                              <img
                                src={ImgBase + (branch?.photo || CompanyInfo?.photo || Company?.photo)}
                                className={"branch-icon-v2"}
                              />
                            </Col>
                            <Col className="col-6" >
                              <h4 className="name-position">{branch?.name}</h4>
                              <p className="text-center" 
                              style={{position:"absolute",bottom:"-20px",right:"0px",border:"1px solid gray",width:"80px",borderRadius:"5px",backgroundColor:"transparent",
                              display:isEmpty(selectedBranch)?
                              "block"
                              :(selectedBranch.findIndex(x => x._id === branch?._id) ==-1)?"block":"none" }} 
                              >Seçiniz</p>
                            </Col>

                          </Row>
                        ) : <h4 className="name-position">{branch?.name}</h4>}
                      </Col>
                      {/* <Col xl="2" lg="2" sm="3" md="3" xs="4">
                          {!isEmpty(branch?.photo) ? (
                            <img
                              src={ImgBase + branch?.photo}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                                position: "relative",
                                bottom: "14px",
                                right: "13px",
                              }}
                            />
                          ) : (
                            <img
                              src={imgEmpty}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                                position: "relative",
                                bottom: "14px",
                                right: "13px",
                              }}
                            />
                          )}
                        </Col>
                        <Col xl="10" lg="10" sm="9" md="9" xs="8">
                          <h5>{branch?.name}</h5>
                        </Col> */}
                    </Row>
                  </CardBody>
                </Card>
                {DataJob(branch?.AppPlans, "isbranch a_" + branch._id, "none")}
                {DataBranch(branch?.children, "isbranch a_" + branch._id, "none", tempIndex + 1)}
              </div>
            </Col>
          ))}
      </div>
    )
  }

  const DataJob = (AppPlans, _class = "", _display = "block") => {
    AppPlans = selectedTab == "*" ? AppPlans : AppPlans.filter(x => x.workType == selectedTab);
    return isEmpty(AppPlans) ? null : (
      <div className={"mb-4 " + _class} style={{ display: _display }}>

        {AppPlans.filter(x=>x.isActive==true)?.map((plan, key) => (

          <Col md="12" className="mt-2 ps-2" key={"_form_" + key}>

            <Card
              className="formCard"
              onClick={() => {
                window.open(`${DomainBase}/ik/${plan._id}`)
                //console.log(plan)
              }}
            >
              <CardBody style={{padding:"0.45rem 0.15rem"}}>
                <Row xl="12" lg="12" sm="12" md="12" xs="12" >
                  <h4
                    style={{
                      fontSize: "13px",
                      position: "relative",
                      //bottom: "19px",
                      display:"flex",
                      flexDirection: "row",
                      justifyContent:"space-between",
                      marginBottom:"-7px"
                    }}
                  >
                    <div>{plan?.DutyId?.description || plan?.name}<br /><small>{plan.workType}</small></div>
                    <div style={{minWidth:'65px', paddingTop:'6px'}}>
                      <i className="mdi mdi-cursor-default-click-outline font-size-20" style={{ float: 'right' }}></i>
                      <small style={{ float: 'right',position:"relative",top:"3px",right:"3px"}}>Başvur</small>
                    </div>
                  </h4>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </div>
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{qr?.title || CompanyInfo?.name || "Bu QR'da İş Var"}</title>
        <meta name="description" content={qr?.description || CompanyInfo?.description || "Bu QR'da İş Var"} />
        <meta property="og:title" content={qr?.title || CompanyInfo?.name || "Bu QR'da İş Var"} />
        {CompanyInfo?.photo ? <meta property="og:image" content={process.env.REACT_APP_BASE_API + "static/uploads/" + CompanyInfo?.photo} /> : null}
      </MetaTags>
      <Container style={{ position: "relative", top: "15px", bottom: "15px" }}>
        {isEmpty(CompanyInfo) ? isEmpty(error) ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (<div className="alert alert-danger text-center">{error}</div>) : (
          <div>
            <div>
              <Row>
                <Col xs="12" xl="12" lg="12" md="12" sm="12">
                  <div
                    style={{
                      position: "relative",
                      left: 0,
                      right: 0,
                      zIndex: 99,
                      width: "100%",
                    }}
                  >
                    <div className="card" style={{ marginBottom: "10px" }}>
                      <div
                        className="card-body"
                        style={{ textAlign: "center", padding: "1rem" }}
                      >
                        {!isEmpty(CompanyInfo?.photo) ? (

                          <div>
                            <Row>
                              <Col className="col-6">
                                <img
                                  src={ImgBase + CompanyInfo?.photo}
                                  className="company-appPlan-icon2"
                                />
                              </Col>
                              <Col className="col-6" >
                                <h1 className="name-position" style={{ float: "left" }}>{CompanyInfo?.name}</h1>
                              </Col>
                            </Row>

                          </div>
                        ) : !isEmpty(Company?.photo) ? (
                          <div>
                            <Row>
                              <Col className="col-6">
                                <img
                                  src={ImgBase + Company?.photo}
                                  className="company-appPlan-icon2"
                                />
                              </Col>
                              <Col className="col-6" >
                                <h1 className="name-position" style={{ float: "left" }}>{CompanyInfo?.name}</h1>
                              </Col>
                            </Row>

                          </div>

                        ) : <div>
                          <h1>{CompanyInfo?.name}</h1>
                          <div>{CompanyInfo?.description}</div>
                        </div>}
                      </div>
                    </div>
                    <div id="categoryButtonContainer">
                      {!isEmpty(tabTitleList)
                        ? tabTitleList.map((tab, index) => (
                          <div key={index}  className={ "categoryButton" +
                              (tab.selected ? " selected" : "") 
                            }
                            onClick={() => {
                              let v = [...tabTitleList]
                              if (
                                v.filter(x => x.selected == true).length > 0
                              ) {
                                v.filter(
                                  x => x.selected == true
                                )[0].selected = false
                              }
                              v[index].selected = true
                              //console.log(v[index])
                              setTabTitleList(v)
                              setSelectedTab(v[index].title)

                              for (let el of document.querySelectorAll(".isbranch")) {
                                el.style.display = "none"
                              }

                            }}
                          >
                            {tab.title == "*" ? (
                              <img src={allIcon} className="all-icon" />
                            ) : (
                              <span>{tab.title}</span>
                            )}
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                  {mainJobs ? DataJob(mainJobs) : null}
                  {DataBranch(Branches)}
                </Col>
              </Row>
            </div>

            <div className="my-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="my-footer-container col-md-12">
                    <img className="footer-icon" src={footerIcon} />
                    <a
                      className="btn btn-lg btn-primary"
                      onClick={async () => {
                        await handleShareButton()
                      }}
                    >
                      {props.t("Share Frends")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(QrV2))
