import {
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  RESET_CITIES,
  GET_DISTRICTS_FAIL,
  GET_NEIGHTBORHOODS_FAIL,
  GET_DISTRICTS_SUCCESS,
  GET_NEIGHTBORHOODS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  cities: [],
  districts: [],
  neighborhoods: [],
  error: null,
}

const Locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload.data,
        districts: [],
        neighborhoods: [],
      }
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload.data,
        neighborhoods:[],
      }
    case GET_NEIGHTBORHOODS_SUCCESS:
      return {
        ...state,
        neighborhoods: action.payload.data,
      }
    //FAILS
    case GET_CITIES_FAIL:
    case GET_DISTRICTS_FAIL:
    case GET_NEIGHTBORHOODS_FAIL:
      return {
        ...state,
        error: action.payload,
        cities: [],
        districts: [],
        neighborhoods: [],
      }
    case RESET_CITIES:
      return {
        ...state,
        error: null,
        cities: [],
        districts: [],
        neighborhoods: [],
      }
    default:
      return state
  }
}

export default Locations
