import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Input,
  FormGroup,
  Button, Alert
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
  getQRCodes as onGetQRCodes,
  addQRCode as onAddQRCode,
  resetQRCode as onResetQrCode,
  deleteQRCode as onDeleteQRCode,
  deleteTagQRCode as onDeleteTagQRCode,
  addTagQRCode as onAddTagQRCode,
  getCompaniesAvm as onGetCompaniesAvm,
  addPartnership as onAddPartnerShip,
  getCompanies as onGetCompanies,
  getBranchs as onGetBranchs,
  resetPartnership as onResetPartnership
} from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from "qrcode.react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import QrModal from "./qrModal"
import SweetAlert from "react-bootstrap-sweetalert"

const qrListModal = (props, cb) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [selectedModel, setSelectedModel] = useState()
  const [qr, setQR] = useState()
  const [formModal, setFormModal] = useState(false)
  const [partnerShipModal, setPartnershipModal] = useState(false)
  const [formTagModal, setFormTagModal] = useState(false)
  const [modalPreview, setModalPreview] = useState(false)

  const [selectedRow, setSelectedRow] = useState()

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [deleteInput, setDeleteInput] = useState("")

  let user = JSON.parse(localStorage.getItem("authUser"))
  const { error, QRCode, analytic, Avm, Company, partnershipError, CompanyInfo } = useSelector(state => ({
    error: state.QRCode?.error,
    QRCode: state.QRCode?.data,
    analytic: state.QRCode?.analytic,
    Avm: state.Companies?.Avm,
    Company: state.Companies?.data[0]?.photo,
    partnershipError: state.Partnership.error,
    CompanyInfo: state.Companies?.data[0]
  }))
  useEffect(() => {
    dispatch(onGetCompaniesAvm());
    dispatch(onGetCompanies());

  }, [dispatch]);
  useEffect(() => {
    dispatch(onResetPartnership());

  }, [props.modal]);

  useEffect(() => {
    setLoading(true)
    setQR()
    if (!isEmpty(props.modalData?.BranchId)) {
      setSelectedModel({
        qrCode: random(8),
        tag: "",
        title: "",
        description: "",
        BranchId: props.modalData.BranchId,
        CompanyId: user.companyId,
      })
    }
  }, [props.modalData])
  useEffect(() => {
    if (!isEmpty(selectedModel?.BranchId)) {
      dispatch(onGetQRCodes(selectedModel.BranchId))

    }
  }, [selectedModel])

  useEffect(() => {
    //console.log("QR", QRCode, new Date())
    setQR(QRCode)
    setLoading(false)
  }, [QRCode, error])
  const togglePartnership = () => {
    setPartnershipModal(!partnerShipModal)
  }
  const toggleFormModal = () => {
    setFormModal(!formModal)
  }
  const toggleFormTagModal = () => {
    setFormTagModal(!formTagModal)
  }

  const toggleQRPreview = () => {
    setModalPreview(!modalPreview)
  }

  function random(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const copyToClipboard = txt => {
    var textField = document.createElement("textarea")
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }
  const handleValidPartnershipSubmit = (e, values) => {

    const addPartnership = {

      CompanyId: user.companyId,
      AvmId: values["avm"],
      BrandName: qr?.BranchId?.name,
      Link: qr?.qrCode,
      BranchId: props.modalData?.BranchId,
      status: values["waiting"],
      companyPhoto: Company,
      UserId: user.uid,
      sector: CompanyInfo?.sector?._id

    };

    dispatch(onAddPartnerShip(addPartnership));
    togglePartnership()
    dispatch(onGetBranchs())
  }

  const handleValidSubmit = (e, v) => {
    setLoading(true)
    dispatch(
      onAddQRCode({
        BranchId: v.BranchId,
        CompanyId: v.CompanyId,
        qrCode: v.qrCode,
        title: v.title,
        description: v.description,
        tag: v.tag,
      })
    )
    toggleFormModal()
  }
  const handleValidSubmitTag = (e, v) => {
    setLoading(true)
    dispatch(
      onAddTagQRCode({
        BranchId: v.BranchId,
        CompanyId: v.CompanyId,
        id: v.id,
        tag: v.tag,
      })
    )
    toggleFormTagModal()
    setSelectedModel()
  }

  return (
    <>
      <ToastContainer autoClose={3000} />

      <Modal isOpen={props.modal} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle} tag="h4">
          <div>

            {props.t("New QR Tag")}

            {!isEmpty(qr) ? (
              <button
                className="btn btn-sm btn-success ms-2"
                onClick={() => {
                  setSelectedModel({
                    id: qr?._id,
                    tag: "",
                    BranchId: props.modalData.BranchId,
                    CompanyId: user.companyId,
                  })
                  toggleFormTagModal()
                }}
              >
                <i className="mdi mdi-plus"></i>
              </button>
            ) : null}
          </div>

        </ModalHeader>
        <ModalBody>
          {qr ? (
            <div className="alert alert-info d-flex flex-row justify-content-between">
              <div>
                <h4>{qr?.title}</h4>
                <h5>{qr?.description}</h5>
              </div>
              <div style={{ minWidth: "110px" }}>
                {props.t("Total Visit")}: {analytic?.totalVisit || 0}
              </div>
            </div>
          ) : null}
          <div style={{ textAlign: "center" }}>
            <div className="table-responsive">
              <table className="table table-bordered font-size-14">
                <thead>
                  <tr>
                    <td>{props.t("Tag")}</td>
                    <td>{props.t("Visit")}</td>
                    <td>{props.t("Action")}</td>
                  </tr>
                </thead>
                <tbody>
                  {qr?.tags?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start">
                        <a
                          href={`${process.env.REACT_APP_DOMAIN}/v2/${qr?.qrCode
                            }${isEmpty(item.tag) ? "" : "?tag=" + item.tag}`}
                          target="_blank"
                        >
                          <code>
                            {item.tag == "friend"
                              ? props.t("Share Frends")
                              : item.tag}
                          </code>
                        </a>
                      </td>
                      <td>{item.visit}</td>
                      <td className="text-end">
                        {props.comeFrom == "branch" ? (
                          <>
                            <a
                              className="me-3 btn btn-link"
                              href={`${process.env.REACT_APP_DOMAIN}/analytics/branch/qr/${qr?.BranchId?._id}/${qr?._id}/${item._id}`}
                            >
                              <i className="mdi mdi-trending-up font-size-18"></i>
                              <p>Analitik</p>

                            </a>
                            <button
                              className="me-3 btn btn-link"
                              type="button"
                              onClick={() => {
                                copyToClipboard(
                                  `${process.env.REACT_APP_DOMAIN}/v2/${qr?.qrCode
                                  }${isEmpty(item.tag) ? "" : "?tag=" + item.tag}`
                                )
                              }}
                            >
                              <i className="mdi mdi-content-copy font-size-18"></i>
                              <p>Kopyala</p>
                            </button>
                          </>

                        ) : null}



                        <button
                          className="me-3 btn btn-link"
                          type="button"
                          onClick={() => {
                            togglePartnership()



                          }}
                        >
                          <i className="bx bx-buildings font-size-18"></i>
                          <p>İş Birliği</p>
                        </button>

                        <button
                          className="me-3 btn btn-link"
                          type="button"
                          onClick={() => {
                            console.log(item)
                            setSelectedRow(item)
                            toggleQRPreview()
                          }}
                        >
                          <i className="mdi mdi-qrcode font-size-18"></i>
                          <p>QR kod</p>
                        </button>
                        <button
                          className="me-3 btn btn-link"
                          type="button"
                          onClick={() => {
                            setDeleteInput("")
                            setSelectedRow({
                              BranchId: qr.BranchId?._id,
                              CompanyId: qr.CompanyId?._id,
                              id: qr._id,
                              tag: item.tag,
                            })
                            setconfirm_alert(true)
                          }}
                        >
                          <i className="mdi mdi-trash-can text-danger font-size-18"></i>
                          <p style={{ color: "red" }}>Sil</p>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {!loading ? (
              isEmpty(qr) ? (
                <div>
                  <div className="alert alert-info text-center">
                    {props.t("No data yet.")}{" "}
                    <a
                      className="btn btn-link"
                      type="button"
                      onClick={() => {
                        setLoading(true)
                        dispatch(
                          onAddQRCode({
                            BranchId: selectedModel?.BranchId,
                            CompanyId: selectedModel?.CompanyId,
                            qrCode: selectedModel?.tag,
                            title: props.modalData?.BranchName || props.branchName,
                            description: "",
                            tag: "genel",
                          })
                        )


                      }}
                    >
                      {props.t("Otomatik Ekle")}
                    </a>
                    <a
                      className="btn btn-link"
                      type="button"
                      onClick={() => {
                        toggleFormModal()
                      }}
                    >
                      {props.t("Manuel Ekle")}
                    </a>
                  </div>

                </div>


              ) : null
            ) : (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {partnershipError && partnershipError ? (
            <Alert color="danger">{partnershipError}</Alert>
          ) : null}
        </ModalBody>
      </Modal>

      <Modal isOpen={formModal} toggle={toggleFormModal}>
        <ModalHeader toggle={toggleFormModal} tag="h4">
          {props.t("Add Qr Code")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit}>
            <Row form>
              <Col xs={12}>
                <AvField
                  type="hidden"
                  name="BranchId"
                  value={selectedModel?.BranchId || ""}
                />
                <AvField
                  type="hidden"
                  name="CompanyId"
                  value={selectedModel?.CompanyId || ""}
                />
                <div className="mb-3">
                  <AvField
                    name="qrCode"
                    label={props.t("qrCode")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: true },
                    }}
                    value={selectedModel?.qrCode || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="title"
                    label={props.t("Title")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: true },
                    }}
                    value={selectedModel?.title || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="description"
                    label={props.t("Description")}
                    type="textarea"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: false },
                    }}
                    value={selectedModel?.description || ""}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      toggleFormModal()
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal isOpen={partnerShipModal} toggle={togglePartnership}>
        <ModalHeader toggle={togglePartnership} tag="h4">
          {props.t("Partnership")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidPartnershipSubmit}>
            <Row form>
              <Col xs={12}>
                <AvField
                  type="hidden"
                  name="BranchId"
                  value={selectedModel?.BranchId || ""}
                />
                <AvField
                  type="hidden"
                  name="CompanyId"
                  value={selectedModel?.CompanyId || ""}
                />
                <div className="mb-3">
                  <AvField
                    className="form-select"
                    type="select"
                    name="avm"
                    value={""}
                    label={props.t("İş Birliği Yapılacak Avm")}
                  >
                    <option>{props.t("Avm Seçiniz")}</option>
                    {Avm?.map((item, index) => (
                      <option key={index} value={item._id} >{item.name}</option>
                    ))}
                  </AvField>
                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      toggleFormModal()
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal isOpen={formTagModal} toggle={toggleFormTagModal}>
        <ModalHeader toggle={toggleFormTagModal} tag="h4">
          {props.t("Add Tag")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmitTag}>
            <Row form>
              <Col xs={12}>
                <AvField
                  type="hidden"
                  name="BranchId"
                  value={selectedModel?.BranchId || ""}
                />
                <AvField
                  type="hidden"
                  name="CompanyId"
                  value={selectedModel?.CompanyId || ""}
                />
                <AvField
                  type="hidden"
                  name="id"
                  value={selectedModel?.id || ""}
                />
                <div className="mb-3">
                  <AvField
                    name="tag"
                    label={props.t("Tag")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: true },
                    }}
                    value={selectedModel?.tag || ""}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      toggleFormTagModal()
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <QrModal
        infoQr={`${process.env.REACT_APP_DOMAIN}/v2/${qr?.qrCode}${isEmpty(selectedRow?.tag)
          ? ""
          : !selectedRow?.tag.includes("?tag=" + selectedRow?.tag)
            ? selectedRow?.tag == "friend"
              ? "?share=friend"
              : "?tag=" + selectedRow?.tag
            : ""
          }`}
        toggle={toggleQRPreview}
        modal={modalPreview}
        QRphoto={qr?.CompanyId?.QRphoto}
        title={`${qr?.title}-${selectedRow?.tag}`}
      />

      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are You Sure")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Delete")}
          onConfirm={e => {
            if (deleteInput == qr.qrCode + "-" + selectedRow.tag) {
              setconfirm_alert(false)
              setsuccess_dlg(true)
              setdynamic_title(props.t("Deleted"))
              setdynamic_description("")
              //dispatch(onDeleteQRCode(selectedRow._id))
              dispatch(onDeleteTagQRCode(selectedRow))
              setSelectedRow(null)
              setDeleteInput("")
              toast.success("QR Kod Silindi")
            } else {
              toast.error("QR Kod Uyumsuz")
            }
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          {props.t("DeleteWarning")}
          <div className="mb-1">
            Silmek için <code>{qr.qrCode + "-" + selectedRow.tag}</code> Qr Kod
            Referansını Yazın
          </div>
          <input
            id="deleteInput"
            name="deleteInput"
            type={"text"}
            className={"form-control"}
            placeholder={props.t("QR Code Write For Delete")}
            value={deleteInput}
            onChange={e => {
              setDeleteInput(e.target.value)
            }}
          />
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnText={props.t("Ok")}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {error && error ? (
        <SweetAlert
          title={props.t("Error")}
          warning
          confirmBtnText={props.t("Ok")}
          onConfirm={() => {
            dispatch(onResetQrCode())
          }}
        >
          {JSON.stringify(error)}
        </SweetAlert>
      ) : null}
    </>
  )
}

qrListModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  toggle: PropTypes.func,
  modalData: PropTypes.any,
  branchName: PropTypes.any,
  comeFrom: PropTypes.any
}

export default withRouter(withTranslation()(qrListModal))
