import {
    GET_AVM_INFOS,
    GET_AVM_INFOS_SUCCESS,
    GET_AVM_INFOS_FAIL,
    GET_AVM_INFOS_FOR_QR,
    GET_AVM_INFOS_FOR_QR_SUCCESS,
    GET_AVM_INFOS_FOR_QR_FAIL,
    GET_AVM_INFO_DETAIL,
    GET_AVM_INFO_DETAIL_SUCCESS,
    GET_AVM_INFO_DETAIL_FAIL,
    ADD_AVM_INFO,
    ADD_AVM_INFO_SUCCESS,
    ADD_AVM_INFO_FAIL,
    UPDATE_AVM_INFO,
    UPDATE_AVM_INFO_SUCCESS,
    UPDATE_AVM_INFO_FAIL,
    UPDATE_AVM_INFO_ACTIVE,
    UPDATE_AVM_INFO_ACTIVE_SUCCESS,
    UPDATE_AVM_INFO_ACTIVE_FAIL,
    DELETE_AVM_INFO,
    DELETE_AVM_INFO_SUCCESS,
    DELETE_AVM_INFO_FAIL,
    RESET_AVM_INFO, 
   
} from "./actionTypes"; 


export const resetAvm_Info = () => ({
    type: RESET_AVM_INFO,
});
export const getAvm_InfosForQr = (id) => ({
    type: GET_AVM_INFOS_FOR_QR,
    payload:id
});

export const getAvm_InfosForQrSuccess = (avm_Info) => ({
    type: GET_AVM_INFOS_FOR_QR_SUCCESS,
    payload: avm_Info, 
});


export const getAvm_InfosForQrFail = (error) => ({
    type: GET_AVM_INFOS_FOR_QR_FAIL,
    payload: error,
});
export const getAvm_Infos = () => ({
    type: GET_AVM_INFOS,
   
});

export const getAvm_InfosSuccess = (avm_Info) => ({
    type: GET_AVM_INFOS_SUCCESS,
    payload: avm_Info,
});


export const getAvm_InfosFail = (error) => ({
    type: GET_AVM_INFOS_FAIL,
    payload: error,
});

export const deleteAvm_Info = (id) => ({
    type:  DELETE_AVM_INFO,
    payload: id
});

export const deleteAvm_InfoSuccess = (data) => ({
    type: DELETE_AVM_INFO_SUCCESS,
    payload: data 
});

export const deleteAvm_InfoFail = (error) => ({
    type: DELETE_AVM_INFO_FAIL,
    payload: error,
});


export const addAvm_Info = (avm_Info,files) => ({
    type: ADD_AVM_INFO,
    payload: avm_Info,
    files: files
});

export const addAvm_InfoSuccess = (data) => ({
    type: ADD_AVM_INFO_SUCCESS,
    payload: data
});

export const addAvm_InfoFail = (error) => ({
    type: ADD_AVM_INFO_FAIL,
    payload: error,
});


export const updateAvm_Info = (avm_Info, file) => ({
    type: UPDATE_AVM_INFO,
    payload: avm_Info,
    file: file 
});

export const updateAvm_InfoSuccess = (data) => ({
    type: UPDATE_AVM_INFO_SUCCESS,
    payload: data
});

export const updateAvm_InfoFail = (error) => ({
    type: UPDATE_AVM_INFO_FAIL,
    payload: error,
});
export const updateAvm_InfoActive = (avm_Info) => ({
    type: UPDATE_AVM_INFO_ACTIVE,
    payload: avm_Info,
    
});

export const updateAvm_InfoActiveSuccess = (data) => ({
    type: UPDATE_AVM_INFO_ACTIVE_SUCCESS,
    payload: data
});

export const updateAvm_InfoActiveFail = (error) => ({
    type: UPDATE_AVM_INFO_ACTIVE_FAIL,
    payload: error,
});