import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ORIENTATIONS, DELETE_ORIENTATION, ADD_ORIENTATION, UPDATE_ORIENTATION,GET_ORIENTATION_DETAIL} from "./actionTypes";
import {
    getOrientationsSuccess, getOrientationsFail,
    deleteOrientationSuccess, deleteOrientationFail,
    updateOrientationSuccess, updateOrientationFail,
    addOrientationSuccess, addOrientationFail,
    getOrientationDetailSuccess,getOrientationDetailFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getOrientations, //API CONNECTION
    delOrientation,
    addOrientation,
    updateOrientation,
    getOrientationById
    
}
    from "../../helpers/fakebackend_helper"; 

    function* fetchPlansDetail({ payload: id}) {
        try {
            const response = yield call(getOrientationById,id)
            yield put(getOrientationDetailSuccess(response));
        } catch (error) {
            yield put(getOrientationDetailFail(error));
        }
    }
function* fetchOrientations() {
    try {
        const response = yield call(getOrientations)
        yield put(getOrientationsSuccess(response));
    } catch (error) {
        yield put(getOrientationsFail(error));
    }
}

function* onDeleteOrientations({payload:id}){
    try{
        const response = yield call(delOrientation, id)
        yield put(deleteOrientationSuccess(response));
    } catch (error) {
        yield put(deleteOrientationFail(error));
    }
}

function* onAddOrientation({payload:orientation}){
    try{
        const response = yield call(addOrientation, orientation)
        yield put(addOrientationSuccess(response));
    } catch (error) {
        yield put(addOrientationFail(error));
    }
}

function* onUpdateOrientation({payload:orientation}){
    try{
        const response = yield call(updateOrientation, orientation)
        yield put(updateOrientationSuccess(response));
    } catch (error) {
        yield put(updateOrientationFail(error));
    }
}

export function* orientationsSaga() {
    yield takeEvery(GET_ORIENTATIONS, fetchOrientations);
    yield takeEvery(DELETE_ORIENTATION, onDeleteOrientations);
    yield takeEvery(ADD_ORIENTATION, onAddOrientation);
    yield takeEvery(UPDATE_ORIENTATION, onUpdateOrientation);
    yield takeEvery(GET_ORIENTATION_DETAIL, fetchPlansDetail);
    
    
}

export default orientationsSaga;
