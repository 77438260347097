import {
    GET_ORIENTATION_PERIODS,
    GET_ORIENTATION_PERIODS_SUCCESS,
    GET_ORIENTATION_PERIODS_FAIL,
    GET_ORIENTATION_PERIOD_DETAIL,
    GET_ORIENTATION_PERIOD_DETAIL_SUCCESS,
    GET_ORIENTATION_PERIOD_DETAIL_FAIL,
    ADD_ORIENTATION_PERIOD,
    ADD_ORIENTATION_PERIOD_SUCCESS,
    ADD_ORIENTATION_PERIOD_FAIL,
    UPDATE_ORIENTATION_PERIOD,
    UPDATE_ORIENTATION_PERIOD_SUCCESS,
    UPDATE_ORIENTATION_PERIOD_FAIL,
    DELETE_ORIENTATION_PERIOD,
    DELETE_ORIENTATION_PERIOD_SUCCESS,
    DELETE_ORIENTATION_PERIOD_FAIL,
    RESET_ORIENTATION_PERIOD,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const OrientationPeriods = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_ORIENTATION_PERIODS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ORIENTATION_PERIOD_SUCCESS:
            return {
                ...state,
                data: state.data.filter(orientationPeriod => orientationPeriod._id.toString() !== action.payload.data.toString())
            }
        case ADD_ORIENTATION_PERIOD_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_ORIENTATION_PERIOD_SUCCESS:
            return {
                ...state,
                data: state.data.map(orientationPeriod =>
                    orientationPeriod._id.toString() === action.payload.data._id.toString() ?
                        { orientationPeriod, ...action.payload.data } :
                        orientationPeriod
                )
            }

        //FAILS
        case GET_ORIENTATION_PERIODS_FAIL:
        case DELETE_ORIENTATION_PERIOD_FAIL:
        case ADD_ORIENTATION_PERIOD_FAIL:
        case UPDATE_ORIENTATION_PERIOD_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_ORIENTATION_PERIOD: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default OrientationPeriods;