import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_SECTOR_FORMS, DELETE_DEV_SECTOR_FORM, ADD_DEV_SECTOR_FORM, UPDATE_DEV_SECTOR_FORM,GET_DEV_SECTOR_FORMS_MY_DEV_SECTOR_FORMS} from "./actionTypes";
import {
    getDevSectorFormsSuccess, getDevSectorFormsFail,
    deleteDevSectorFormSuccess, deleteDevSectorFormFail,
    updateDevSectorFormSuccess, updateDevSectorFormFail,
    addDevSectorFormSuccess, addDevSectorFormFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevSectorForms, //API CONNECTION
    delDevSectorForm,
    addDevSectorForm,
    updateDevSectorForm,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevSectorForms() {
    try {
        const response = yield call(getDevSectorForms)
        yield put(getDevSectorFormsSuccess(response));
    } catch (error) {
        yield put(getDevSectorFormsFail(error));
    }
}

function* onDeleteDevSectorForms({payload:id}){
    try{
        const response = yield call(delDevSectorForm, id)
        yield put(deleteDevSectorFormSuccess(response));
    } catch (error) {
        yield put(deleteDevSectorFormFail(error));
    }
}

function* onAddDevSectorForm({payload:devSectorForm}){
    try{
        const response = yield call(addDevSectorForm, devSectorForm)
        yield put(addDevSectorFormSuccess(response));
    } catch (error) {
        yield put(addDevSectorFormFail(error));
    }
}

function* onUpdateDevSectorForm({payload:devSectorForm}){
    try{
        const response = yield call(updateDevSectorForm, devSectorForm)
        yield put(updateDevSectorFormSuccess(response));
    } catch (error) {
        yield put(updateDevSectorFormFail(error));
    }
}

export function* devSectorFormsSaga() {
    yield takeEvery(GET_DEV_SECTOR_FORMS, fetchDevSectorForms);
    yield takeEvery(DELETE_DEV_SECTOR_FORM, onDeleteDevSectorForms);
    yield takeEvery(ADD_DEV_SECTOR_FORM, onAddDevSectorForm);
    yield takeEvery(UPDATE_DEV_SECTOR_FORM, onUpdateDevSectorForm);
   
    
}

export default devSectorFormsSaga;
