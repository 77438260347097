import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QRCODES, DELETE_QRCODE, ADD_QRCODE, ADD_TAG_QRCODE, DELETE_TAG_QRCODE } from "./actionTypes"
import {
  getQRCodesSuccess,
  getQRCodesFail,
  deleteQRCodeSuccess,
  deleteQRCodeFail,
  addQRCodeSuccess,
  addQRCodeFail,
  addTagQRCodeSuccess,
  addTagQRCodeFail,
  deleteTagQRCodeSuccess,
  deleteTagQRCodeFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getQRCodes, //API CONNECTION
  delQRCode,
  addQRCode,
  addTagQRCode,
  deleteTagQRCode
} from "../../helpers/fakebackend_helper"

function* fetchQRCodes({ payload: branchId }) {
  try {
    const response = yield call(getQRCodes, branchId)
    yield put(getQRCodesSuccess(response))
  } catch (error) {
    yield put(getQRCodesFail(error))
  }
}


function* onAddQRCode({ payload: model }) {
  try {
    const response = yield call(addQRCode, model)
    yield put(addQRCodeSuccess(response))
  } catch (error) {
    yield put(addQRCodeFail(error))
  }
}

function* onAddTagQRCode({ payload: model }) {
  try {
    const response = yield call(addTagQRCode, model)
    yield put(addTagQRCodeSuccess(response))
  } catch (error) {
    yield put(addTagQRCodeFail(error))
  }
}

function* onDeleteTagQRCode({ payload: model }) {
  try {
    const response = yield call(deleteTagQRCode, model)
    yield put(deleteTagQRCodeSuccess(response))
  } catch (error) {
    yield put(deleteTagQRCodeFail(error))
  }
}


function* onDeleteQRCodes({ payload: id }) {
  try {
    const response = yield call(delQRCode, id)
    yield put(deleteQRCodeSuccess(response))
  } catch (error) {
    yield put(deleteQRCodeFail(error))
  }
}

export function* QRCodeSaga() {
  yield takeEvery(GET_QRCODES, fetchQRCodes);
  yield takeEvery(ADD_QRCODE, onAddQRCode);
  yield takeEvery(ADD_TAG_QRCODE, onAddTagQRCode);
  yield takeEvery(DELETE_QRCODE, onDeleteQRCodes); 
  yield takeEvery(DELETE_TAG_QRCODE, onDeleteTagQRCode);  
}

export default QRCodeSaga
