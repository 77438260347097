import React, { useEffect, useState } from "react"

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const handleWindowSizeChange = () => {
    if (window && window?.innerWidth && width) {
      setWidth(window?.innerWidth)
    }
  }

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleWindowSizeChange)
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange)
      }
    }
  }, [width])
  console.log(width)
  return width <= 768
}

export default useCheckMobileScreen
