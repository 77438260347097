import { 
  GET_QR_ANONYMUS_SUCCESS,
  GET_QR_ANONYMUS_FAIL,   
  RESET_QR_ANONYMUS, 
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: null, 
}

const QRAnonymus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QR_ANONYMUS_SUCCESS: 
      return {
        ...state,
        data: action.payload.data, 
      } 
    //FAILS
    case GET_QR_ANONYMUS_FAIL:   
      return {
        ...state,
        error: action.payload || "Hata Oluştu", 
      } 
    case RESET_QR_ANONYMUS:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default QRAnonymus
