import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SURVEY_SENTS, DELETE_SURVEY_SENT, ADD_SURVEY_SENT,
    SEND_SURVEY_MAIL, UPDATE_SURVEY_SENT,GET_SURVEY_SENTS_MY_SURVEY_SENTS} from "./actionTypes";
import {
    getSurveySentsSuccess, getSurveySentsFail,
    deleteSurveySentSuccess, deleteSurveySentFail,
    updateSurveySentSuccess, updateSurveySentFail,
    addSurveySentSuccess, addSurveySentFail,
    sendSurveyMailSuccess,sendSurveyMailFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSurveySents, //API CONNECTION
    delSurveySent,
    addSurveySent,
    updateSurveySent,sendSurveyMail
    
}
    from "../../helpers/fakebackend_helper"; 

    function* postSurveyMail({payload:email}){
        try{
           
            const response = yield call(sendSurveyMail, email);
            yield put(sendSurveyMailSuccess(response));
        } catch (error) {
            yield put(sendSurveyMailFail(error)); 
        }
      } 
function* fetchSurveySents({payload:appSentId}) {
    try {
        const response = yield call(getSurveySents,appSentId)
        yield put(getSurveySentsSuccess(response));
    } catch (error) {
        yield put(getSurveySentsFail(error));
    }
}

function* onDeleteSurveySents({payload:id}){
    try{
        const response = yield call(delSurveySent, id)
        yield put(deleteSurveySentSuccess(response));
    } catch (error) {
        yield put(deleteSurveySentFail(error));
    }
}

function* onAddSurveySent({payload:surveySent}){
    try{
        const response = yield call(addSurveySent, surveySent)
        yield put(addSurveySentSuccess(response));
    } catch (error) {
        yield put(addSurveySentFail(error));
    }
}

function* onUpdateSurveySent({payload:surveySent}){
    try{
        const response = yield call(updateSurveySent, surveySent)
        yield put(updateSurveySentSuccess(response));
    } catch (error) {
        yield put(updateSurveySentFail(error));
    }
}

export function* surveySentsSaga() {
    yield takeEvery(GET_SURVEY_SENTS, fetchSurveySents);
    yield takeEvery(DELETE_SURVEY_SENT, onDeleteSurveySents);
    yield takeEvery(ADD_SURVEY_SENT, onAddSurveySent);
    yield takeEvery(UPDATE_SURVEY_SENT, onUpdateSurveySent);
    yield takeEvery(SEND_SURVEY_MAIL, postSurveyMail);
    
    
}

export default surveySentsSaga;
