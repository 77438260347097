import React, { useEffect } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import logodark from "../../assets/images/index/buqrda_is_var_ib.png"
import { withRouter, Link } from "react-router-dom"
import "./datatables.scss"
const CarouselPageIndex = () => {
  useEffect(() => {
    console.log("H", window.innerHeight)
  }, [])

  return (
    <React.Fragment>
      <Col xl={12}>
        <div className="auth-full-index-bg pt-lg-0 p-0">
          <div className="w-100">
            <div className="d-flex h-100 flex-column">
              <div className="py-0 px-4" style={{marginTop:`${window.innerHeight/5}px`}}>
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <a href="https://buqrdaisvar.com/avm/577a6fa33b3106d1">
                        <img
                          id="preview-date2"
                          src={logodark}
                          alt=""
                          height="290"
                          style={{
                            position: "relative",
                            bottom: 0,
                            top: 0,
                          }}
                        />
                      </a>
                      <h4
                        className="mb-1"
                        style={{
                          position: "relative",
                          bottom: 0,
                          top: 0,
                          fontFamily: "citrus-gothic",
                          color: "#1f509b",
                          fontSize: "28px",
                          marginBottom: "50px",
                        }}
                      >
                        TELEFONUNUZUN KAMERASI İLE QR KODU OKUTARAK HEMEN İŞ
                        ORTAKLARIMIZIN İLANLARINA BAŞVURU YAPABİLİRSİNİZ VEYA İŞ
                        ARAYAN TANIDIKLARINIZA İŞ ORTAKLARIMIZI ÖNEREBİLİRSİNİZ
                      </h4>
                      <a
                        href="https://buqrdaisvar.com/avm/577a6fa33b3106d1"
                        style={{
                          position: "relative",
                          fontFamily: "citrus-gothic",
                          color: "#1f509b",
                          fontSize: "20px",
                          bottom: 0,
                          top: 0,
                        }}
                      >
                        WEB SİTEMİZ ÜZERİNDEN DEVAM ETMEK İÇİN{" "}
                        <span style={{ color: "#fb8633" }}>Tıklayın</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPageIndex
