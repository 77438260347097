import {
    GET_PLAN_OUTSIDES,
    GET_PLAN_OUTSIDES_SUCCESS,
    GET_PLAN_OUTSIDES_FAIL,
    GET_PLAN_OUTSIDE_DETAIL,
    GET_PLAN_OUTSIDE_DETAIL_SUCCESS,
    GET_PLAN_OUTSIDE_DETAIL_FAIL,
    ADD_PLAN_OUTSIDE,
    ADD_PLAN_OUTSIDE_SUCCESS,
    ADD_PLAN_OUTSIDE_FAIL,
    UPDATE_PLAN_OUTSIDE,
    UPDATE_PLAN_OUTSIDE_SUCCESS,
    UPDATE_PLAN_OUTSIDE_FAIL,
    DELETE_PLAN_OUTSIDE,
    DELETE_PLAN_OUTSIDE_SUCCESS,
    DELETE_PLAN_OUTSIDE_FAIL,
    RESET_PLAN_OUTSIDE,
    GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    GET_USER_BY_MAIL_SUCCESS,
    GET_USER_BY_MAIL_FAIL,
    GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS,
    GET_PLAN_OUTSIDES_BY_BRANCH_FAIL,
    GET_BRANCH_PLAN_OUTSIDE_SUCCESS, 
    GET_BRANCH_PLAN_OUTSIDE_FAIL,
    GET_PLAN_OUTSIDES_DOWN_SUCCESS,
    GET_PLAN_OUTSIDES_DOWN_FAIL,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    myPlanOutsides: [],
    user :[], 
    branch:[],
    planOutsidesDown:[],
    success:null,
    mail:[],
    sendMailSuccess:null
};

const PlanOutsides = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEND_MAIL_SUCCESS:
            return {
               
                ...state,
                mail:[action.payload.data], 
                sendMailSuccess:true  
            }  
        case GET_PLAN_OUTSIDES_DOWN_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_PLAN_OUTSIDE_DETAIL_SUCCESS:
            return {
                ...state,
                myPlanOutsides: action.payload.data
            }
        case GET_USER_BY_MAIL_SUCCESS:
                return {
                    ...state, 
                    user: action.payload.data
                }
        case GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS:
            return {
                ...state,
                myPlanOutsides: action.payload.data
            }
            case GET_BRANCH_PLAN_OUTSIDE_SUCCESS:
                return {
                    ...state,
                    branch: action.payload.data
                }
        case GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS:
            return {
                ...state,
                myPlanOutsides: action.payload.data
            }
        case ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS:
            return {
                ...state, 
             
                myPlanOutsides: [action.payload.data, ...state.myPlanOutsides],
            }
        case DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myPlanOutsides: state.myPlanOutsides.filter(
                    planOutside => planOutside._id.toString() !== action.payload.data.toString()
                  ),
                }
        case UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS:
                return {
                    ...state,
                    myPlanOutsides: state.myPlanOutsides.map(planOutside =>
                        planOutside._id.toString() === action.payload.data._id.toString() ?
                            { planOutside, ...action.payload.data } :
                            planOutside
                    )
                    }
        case GET_PLAN_OUTSIDES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PLAN_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(planOutside => planOutside._id.toString() !== action.payload.data.toString())
            }
        case ADD_PLAN_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true
            }
        case UPDATE_PLAN_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: state.data.map(planOutside =>
                    planOutside._id.toString() === action.payload.data._id.toString() ?
                        { planOutside, ...action.payload.data } :
                        planOutside
                )
            }

        //FAILS
        case GET_PLAN_OUTSIDES_FAIL:
        case DELETE_PLAN_OUTSIDE_FAIL:
        case ADD_PLAN_OUTSIDE_FAIL:
        case UPDATE_PLAN_OUTSIDE_FAIL:
        case UPDATE_PLAN_OUTSIDE_FAIL:
        case GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL:
        case ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL:
        case DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL:
        case UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL:
        case GET_PLAN_OUTSIDE_DETAIL_FAIL:
        case GET_USER_BY_MAIL_FAIL:
        case GET_PLAN_OUTSIDES_BY_BRANCH_FAIL: 
        case  GET_BRANCH_PLAN_OUTSIDE_FAIL:
        case GET_PLAN_OUTSIDES_DOWN_FAIL:
        case SEND_MAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PLAN_OUTSIDE: return {
            ...state,
            error: null,
            success: null,
            sendMailSuccess:null
        }
        default:
            return state;
    }
}


export default PlanOutsides;