import { 
  GET_QRCODES_SUCCESS,
  GET_QRCODES_FAIL,  
  ADD_QRCODE_SUCCESS,
  ADD_QRCODE_FAIL,  
  DELETE_QRCODE_SUCCESS,
  DELETE_QRCODE_FAIL,
  RESET_QRCODE, 
  ADD_TAG_QRCODE_FAIL, 
  DELETE_TAG_QRCODE_FAIL,
  ADD_TAG_QRCODE_SUCCESS,
  DELETE_TAG_QRCODE_SUCCESS, 
} from "./actionTypes"

const INIT_STATE = {
  data: {},
  analytic:{},
  error: null, 
}

const QRCode = (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case GET_QRCODES_SUCCESS:
      case ADD_TAG_QRCODE_SUCCESS: 
      case DELETE_TAG_QRCODE_SUCCESS:   
      return {
        ...state,
        data: action.payload.data, 
        analytic:action.payload.analytic
      } 
    case DELETE_QRCODE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          qrCode => qrCode._id.toString() !== action.payload.data.toString()
        ),
      }
    case ADD_QRCODE_SUCCESS:
      return {
        ...state,
        data: action.payload.data//[action.payload.data, ...state.data],
      }    
    
    //FAILS
    case GET_QRCODES_FAIL: 
    case DELETE_QRCODE_FAIL:
    case ADD_QRCODE_FAIL: 
    case ADD_TAG_QRCODE_FAIL: 
    case DELETE_TAG_QRCODE_FAIL: 
      return {
        ...state,
        error: action.payload,  
      } 
    case RESET_QRCODE:
      return {
        ...state,
        error: null, 
      }
    default:
      return state
  }
}

export default QRCode
