import {
    GET_WORK_TYPES,
    GET_WORK_TYPES_SUCCESS,
    GET_WORK_TYPES_FAIL,
    GET_WORK_TYPE_DETAIL,
    GET_WORK_TYPE_DETAIL_SUCCESS,
    GET_WORK_TYPE_DETAIL_FAIL,
    ADD_WORK_TYPE,
    ADD_WORK_TYPE_SUCCESS,
    ADD_WORK_TYPE_FAIL,
    UPDATE_WORK_TYPE,
    UPDATE_WORK_TYPE_SUCCESS,
    UPDATE_WORK_TYPE_FAIL,
    DELETE_WORK_TYPE,
    DELETE_WORK_TYPE_SUCCESS,
    DELETE_WORK_TYPE_FAIL,
    RESET_WORK_TYPE,
   
} from "./actionTypes";


export const resetWorkType = () => ({
    type: RESET_WORK_TYPE,
});

export const getWorkTypes = () => ({
    type: GET_WORK_TYPES,
});

export const getWorkTypesSuccess = (workType) => ({
    type: GET_WORK_TYPES_SUCCESS,
    payload: workType,
});


export const getWorkTypesFail = (error) => ({
    type: GET_WORK_TYPES_FAIL,
    payload: error,
});

export const deleteWorkType = (id) => ({
    type:  DELETE_WORK_TYPE,
    payload: id
});

export const deleteWorkTypeSuccess = (data) => ({
    type: DELETE_WORK_TYPE_SUCCESS,
    payload: data
});

export const deleteWorkTypeFail = (error) => ({
    type: DELETE_WORK_TYPE_FAIL,
    payload: error,
});


export const addWorkType = (workType) => ({
    type: ADD_WORK_TYPE,
    payload: workType
});

export const addWorkTypeSuccess = (data) => ({
    type: ADD_WORK_TYPE_SUCCESS,
    payload: data
});

export const addWorkTypeFail = (error) => ({
    type: ADD_WORK_TYPE_FAIL,
    payload: error,
});


export const updateWorkType = (workType) => ({
    type: UPDATE_WORK_TYPE,
    payload: workType
});

export const updateWorkTypeSuccess = (data) => ({
    type: UPDATE_WORK_TYPE_SUCCESS,
    payload: data
});

export const updateWorkTypeFail = (error) => ({
    type: UPDATE_WORK_TYPE_FAIL,
    payload: error,
});