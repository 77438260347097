import {
    GET_ORIENTATIONS,
    GET_ORIENTATIONS_SUCCESS,
    GET_ORIENTATIONS_FAIL,
    GET_ORIENTATION_DETAIL,
    GET_ORIENTATION_DETAIL_SUCCESS,
    GET_ORIENTATION_DETAIL_FAIL,
    ADD_ORIENTATION,
    ADD_ORIENTATION_SUCCESS,
    ADD_ORIENTATION_FAIL,
    UPDATE_ORIENTATION,
    UPDATE_ORIENTATION_SUCCESS,
    UPDATE_ORIENTATION_FAIL,
    DELETE_ORIENTATION,
    DELETE_ORIENTATION_SUCCESS,
    DELETE_ORIENTATION_FAIL,
    RESET_ORIENTATION,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    myOrientation:[],
    success:null,
};

const Orientations = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ORIENTATION_DETAIL_SUCCESS:
            return {
                ...state,
                myOrientation: action.payload.data
            }
        case GET_ORIENTATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ORIENTATION_SUCCESS:
            return {
                ...state,
                data: state.data.filter(orientation => orientation._id.toString() !== action.payload.data.toString())
            }
        case ADD_ORIENTATION_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true 
            }
        case UPDATE_ORIENTATION_SUCCESS:
            return {
                ...state,
                data: state.data.map(orientation =>
                    orientation._id.toString() === action.payload.data._id.toString() ?
                        { orientation, ...action.payload.data } :
                        orientation
                )
            }

        //FAILS
        case GET_ORIENTATIONS_FAIL:
        case DELETE_ORIENTATION_FAIL:
        case ADD_ORIENTATION_FAIL:
        case UPDATE_ORIENTATION_FAIL:
        case GET_ORIENTATION_DETAIL_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_ORIENTATION: return {
            ...state,
            error: null,
            success:null
        }
        default:
            return state;
    }
}


export default Orientations;