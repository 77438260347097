/** Get PROGRAM */
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL";

export const GET_PROGRAM_DETAIL = "GET_PROGRAM_DETAIL";
export const GET_PROGRAM_DETAIL_SUCCESS = "GET_PROGRAM_DETAIL_SUCCESS";
export const GET_PROGRAM_DETAIL_FAIL = "GET_PROGRAM_DETAIL_FAIL";


export const ADD_PROGRAM = "ADD_PROGRAM";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const ADD_PROGRAM_FAIL = "ADD_PROGRAM_FAIL";

export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_FAIL = "UPDATE_PROGRAM_FAIL";

export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_FAIL = "DELETE_PROGRAM_FAIL"; 



export const RESET_PROGRAM= "RESET_PROGRAM";