import {
  GET_QRCODES,
  GET_QRCODES_SUCCESS,
  GET_QRCODES_FAIL,
  ADD_QRCODE,
  ADD_QRCODE_SUCCESS,
  ADD_QRCODE_FAIL,
  ADD_TAG_QRCODE,
  ADD_TAG_QRCODE_SUCCESS,
  ADD_TAG_QRCODE_FAIL,
  DELETE_QRCODE_SUCCESS,
  DELETE_QRCODE_FAIL,
  RESET_QRCODE,
  DELETE_QRCODE,
  DELETE_TAG_QRCODE,
  DELETE_TAG_QRCODE_SUCCESS,
  DELETE_TAG_QRCODE_FAIL,
} from "./actionTypes"

export const resetQRCode = () => ({
  type: RESET_QRCODE,
})

export const getQRCodes = branchId => ({
  type: GET_QRCODES,
  payload: branchId,
})

export const getQRCodesSuccess = qrCodes => ({
  type: GET_QRCODES_SUCCESS,
  payload: qrCodes,
})

export const getQRCodesFail = error => ({
  type: GET_QRCODES_FAIL,
  payload: error,
})

export const deleteQRCode = id => ({
  type: DELETE_QRCODE,
  payload: id,
})

export const deleteQRCodeSuccess = data => ({
  type: DELETE_QRCODE_SUCCESS,
  payload: data,
})

export const deleteQRCodeFail = error => ({
  type: DELETE_QRCODE_FAIL,
  payload: error,
})

export const addQRCode = qrCode => ({
  type: ADD_QRCODE,
  payload: qrCode,
})

export const addQRCodeSuccess = data => ({
  type: ADD_QRCODE_SUCCESS,
  payload: data,
})

export const addQRCodeFail = error => ({
  type: ADD_QRCODE_FAIL,
  payload: error,
})

export const addTagQRCode = model => ({
  type: ADD_TAG_QRCODE,
  payload: model,
})

export const addTagQRCodeSuccess = data => ({
  type: ADD_TAG_QRCODE_SUCCESS,
  payload: data,
})

export const addTagQRCodeFail = error => ({
  type: ADD_TAG_QRCODE_FAIL,
  payload: error,
})

export const deleteTagQRCode = model => ({
  type: DELETE_TAG_QRCODE,
  payload: model,
})

export const deleteTagQRCodeSuccess = data => ({
  type: DELETE_TAG_QRCODE_SUCCESS,
  payload: data,
})

export const deleteTagQRCodeFail = error => ({
  type: DELETE_TAG_QRCODE_FAIL,
  payload: error,
})
