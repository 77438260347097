/** Get STATUS */
export const GET_STATUSS = "GET_STATUSS";
export const GET_STATUSS_SUCCESS = "GET_STATUSS_SUCCESS";
export const GET_STATUSS_FAIL = "GET_STATUSS_FAIL";

export const GET_STATUS_DETAIL = "GET_STATUS_DETAIL";
export const GET_STATUS_DETAIL_SUCCESS = "GET_STATUS_DETAIL_SUCCESS";
export const GET_STATUS_DETAIL_FAIL = "GET_STATUS_DETAIL_FAIL";


export const ADD_STATUS = "ADD_STATUS";
export const ADD_STATUS_SUCCESS = "ADD_STATUS_SUCCESS";
export const ADD_STATUS_FAIL = "ADD_STATUS_FAIL";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL";

export const DELETE_STATUS = "DELETE_STATUS";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAIL = "DELETE_STATUS_FAIL"; 



export const RESET_STATUS= "RESET_STATUS";