import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Form,
  Input,
} from "reactstrap"
import InputMask from "react-input-mask"
import imgEmpty from "./../../assets/images/empty.png"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/bu_qrda_is_var_1.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import CarouselPage from "./CarouselPage"
import Dropzone from "react-dropzone"
//i18n 
import { withTranslation } from "react-i18next"

// action
import { registerUser, apiError, registerSuccessReset } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  const dispatch = useDispatch()
  const [rememberCheck, setRememberCheck] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const { user, registrationError, loading, registerSuccess,UserCompanyId} = useSelector(state => ({
    user: state.Account.user,
    UserCompanyId:state.Account.user?.data.companyId,
    registerSuccess: state.Account.registerSuccess,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    const registerModel = {
      companyName: values.companyName,
      verDaire: values.verDaire,
      verNo: values.verNo,
      phone: values.phone,
      name: values.name,
      password: values.password,
      email: values.email,
      passwordConfirm: values.confirmPassword,
      photo: values["photo"],
    }
    dispatch(registerUser(registerModel,selectedFiles))

  }

  useEffect(() => {
    dispatch(apiError(""))
  }, [])
  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Kayıt Ol |Bu QR'da İş Var</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content  p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    {/* <div >
                      <Link to="dashboard" className="d-block auth-logo">
                        <img 
                         src={logodark}
                          alt=""
                          height="140"
                          className="auth-logo-dark me-4"
                          style={{position:"relative", left:"-13px"}}
                        
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="225"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div> */}
                    <div className="my-auto">
                      {!registerSuccess ? (

                        <div >
                          <h5 className="text-primary">Ücresiz Kayıt</h5>

                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(v)
                            }}
                            autoComplete="off"
                          >
                            <Row form>

                              {user && user ? ( 
                                <Alert color="success">
                                  Kayıt Olma İşlemi Başarılı

                                </Alert>

                              ) : null}

                              {registrationError && registrationError ? (
                                <Alert color="danger">{registrationError}</Alert>
                              ) : null}

                              <div className="mb-2">
                                <AvField
                                  name="companyName"
                                  label={props.t("Şirket Ünvanı")}
                                  className="form-control"
                                  placeholder={props.t("Şirket Ünvanı")}
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="verDaire"
                                  label={props.t("Vergi Dairesi")}
                                  className="form-control"
                                  placeholder={props.t("Vergi Dairesi")}
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="verNo"
                                  label={props.t("Vergi No")}
                                  className="form-control"
                                  placeholder={props.t("Vergi No")}
                                  type="text"
                                  required
                                />
                              </div>
                              <p style={{ fontSize: "13px", fontWeight: "500" }} className="mt-3 mb-2">Firmanızın Logosunu Ekleyin</p>
                              <div
                                className="mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <div>

                                  <img
                                    src={imgEmpty}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      objectFit: "contain",
                                    }}
                                  />

                                </div>

                                <div
                                  style={{
                                    width: "75%",
                                    border: "1px solid #ced4da",
                                    borderRadius: "10px",
                                    height: "150px",
                                  }}
                                >
                                  <Dropzone
                                    multiple={false}
                                    accept="image/jpeg, image/png"
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone-min">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div
                                            className="dz-message needsclick"
                                            style={{ padding: "0px" }}
                                          >
                                            <div style={{position:"relative",bottom:"15px"}}>
                                              <i className="display-5 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4  style={{fontSize:"14px",position:"relative",bottom:"15px"}}>{props.t("Drop Image")}</h4>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews"
                                    id="file-previews"
                                    style={{
                                      position: "absolute",
                                      zIndex: 99,
                                      left: 0,
                                      top: -5,
                                    }}
                                  >
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <div className="align-items-center">
                                              <div className="col-12">
                                                <img
                                                  data-dz-thumbnail=""
                                                  style={{
                                                    width: "125px",
                                                    height: "125px",
                                                    objectFit: "contain",
                                                  }}
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="name"
                                  label={props.t("Yetkili İsim ve Soyismi")}
                                  type="text"
                                  required
                                  placeholder={props.t("FullName")}
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="phone"
                                  label={props.t("Yetkili Telefon  No")}
                                  type="text"
                                  errorMessage={props.t("InvalidInput")}
                                  mask="(999) 999-9999"
                                  maskChar="-"
                                  tag={[Input, InputMask]}
                                  placeholder="(999) 999-9999"
                                  validate={{
                                    required: { value: false },
                                  }}
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="email"
                                  label={props.t("Yetkili e-Mail Adresi")}
                                  className="form-control"
                                  placeholder={props.t("Email")}
                                  type="email"
                                  required
                                />
                              </div>
                             
                              {/* <div className="mb-3">
                                   <AvField
                                       name="qrCode"
                                       label={props.t("Write Your Sticker Code If You Have")}
                                       type="text"
                                       errorMessage={props.t("InvalidInput")}
                                       validate={{
                                           required: { value: false },
                                       }}
                                      
                                   />
                               </div> */}
                              <div className="mb-2">
                                <AvField
                                  name="password"
                                  label={props.t("Şifre")}
                                  type="password"
                                  required
                                  placeholder={props.t("Şifre En Az 4 Karakterli Olmalıdır.")}
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="confirmPassword"
                                  label={props.t("Şifre Onay")}
                                  type="password"
                                  required
                                  placeholder={props.t("Şifre Onay")}
                                />
                              </div>

                              <div>
                                <p className="mb-0 mt-1" >
                                  Kayıt olarak{" "}
                                  <a href="#" className="text-primary">
                                    Gizlilik Sözleşmesi
                                  </a>{" "}
                                  kabul etmiş olursunuz.
                                </p>
                              </div>
                              <Row className="mt-3">
                                <Col md="5">
                                  <div className="mt-1">
                                    <button
                                      className="btn btn-primary btn-block "
                                      type="submit"
                                    >
                                      Kayıt Ol
                                    </button>
                                  </div>
                                </Col>
                                <Col md="7">
                                  <div className="mt-1">
                                    <p>
                                      Daha önce kayıt oldunuz mu?{""}
                                      <Link
                                        to="login"
                                        className="font-weight-medium text-primary"
                                      >
                                        {""}
                                        Giriş Yap
                                      </Link>{" "}
                                    </p>

                                  </div>
                                </Col>
                              </Row>
                            </Row>
                          </AvForm>


                        </div>



                       ) :
                      <div className="alert alert-success h3 text-center">
                        {props.t("Kayıt Olma İşlemi Başarılı")}
                        <br />
                        <i className="fa fa-check"></i>
                        <hr />
                        <Link to="/Login" className="font-size-13">{props.t("Continue")}</Link>
                      </div>
                     
                      //window.open(`${process.env.REACT_APP_DOMAIN}/register2/` + UserCompanyId, "_self")
                    }


                    </div>


                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withTranslation()(Register))
