import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ORIENTATION_FORMS, DELETE_ORIENTATION_FORM, ADD_ORIENTATION_FORM,
     UPDATE_ORIENTATION_FORM,GET_ORIENTATION_FORMS_MY_ORIENTATION_FORMS} from "./actionTypes";
import {
    getOrientationFormsSuccess, getOrientationFormsFail,
    deleteOrientationFormSuccess, deleteOrientationFormFail,
    updateOrientationFormSuccess, updateOrientationFormFail,
    addOrientationFormSuccess, addOrientationFormFail,

   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getOrientationForms, //API CONNECTION
    delOrientationForm,
    addOrientationForm,
    updateOrientationForm
    
}
    from "../../helpers/fakebackend_helper"; 

 
function* fetchOrientationForms({payload:appFormId}) {
    try {
        const response = yield call(getOrientationForms,appFormId)
        yield put(getOrientationFormsSuccess(response));
    } catch (error) {
        yield put(getOrientationFormsFail(error));
    }
}
 
function* onDeleteOrientationForms({payload:id}){
    try{
        const response = yield call(delOrientationForm, id)
        yield put(deleteOrientationFormSuccess(response));
    } catch (error) {
        yield put(deleteOrientationFormFail(error));
    }
}

function* onAddOrientationForm({payload:orientationForm}){
    try{
        const response = yield call(addOrientationForm, orientationForm)
        yield put(addOrientationFormSuccess(response));
    } catch (error) {
        yield put(addOrientationFormFail(error));
    }
}

function* onUpdateOrientationForm({payload:orientationForm}){
    try{
        const response = yield call(updateOrientationForm, orientationForm)
        yield put(updateOrientationFormSuccess(response));
    } catch (error) {
        yield put(updateOrientationFormFail(error));
    }
}

export function* orientationFormsSaga() {
    yield takeEvery(GET_ORIENTATION_FORMS, fetchOrientationForms);
    yield takeEvery(DELETE_ORIENTATION_FORM, onDeleteOrientationForms);
    yield takeEvery(ADD_ORIENTATION_FORM, onAddOrientationForm);
    yield takeEvery(UPDATE_ORIENTATION_FORM, onUpdateOrientationForm);

    
    
}

export default orientationFormsSaga;
