import {
    GET_SOFT_SKILLS,
    GET_SOFT_SKILLS_SUCCESS,
    GET_SOFT_SKILLS_FAIL,
    GET_SOFT_SKILL_DETAIL,
    GET_SOFT_SKILL_DETAIL_SUCCESS,
    GET_SOFT_SKILL_DETAIL_FAIL,
    ADD_SOFT_SKILL,
    ADD_SOFT_SKILL_SUCCESS,
    ADD_SOFT_SKILL_FAIL,
    UPDATE_SOFT_SKILL,
    UPDATE_SOFT_SKILL_SUCCESS,
    UPDATE_SOFT_SKILL_FAIL,
    DELETE_SOFT_SKILL,
    DELETE_SOFT_SKILL_SUCCESS,
    DELETE_SOFT_SKILL_FAIL,
    RESET_SOFT_SKILL,
   
} from "./actionTypes";


export const resetSoftSkill = () => ({
    type: RESET_SOFT_SKILL,
});

export const getSoftSkills = () => ({
    type: GET_SOFT_SKILLS,
});

export const getSoftSkillsSuccess = (softSkill) => ({
    type: GET_SOFT_SKILLS_SUCCESS,
    payload: softSkill,
});


export const getSoftSkillsFail = (error) => ({
    type: GET_SOFT_SKILLS_FAIL,
    payload: error,
});

export const deleteSoftSkill = (id) => ({
    type:  DELETE_SOFT_SKILL,
    payload: id
});

export const deleteSoftSkillSuccess = (data) => ({
    type: DELETE_SOFT_SKILL_SUCCESS,
    payload: data
});

export const deleteSoftSkillFail = (error) => ({
    type: DELETE_SOFT_SKILL_FAIL,
    payload: error,
});


export const addSoftSkill = (softSkill) => ({
    type: ADD_SOFT_SKILL,
    payload: softSkill
});

export const addSoftSkillSuccess = (data) => ({
    type: ADD_SOFT_SKILL_SUCCESS,
    payload: data
});

export const addSoftSkillFail = (error) => ({
    type: ADD_SOFT_SKILL_FAIL,
    payload: error,
});


export const updateSoftSkill = (softSkill) => ({
    type: UPDATE_SOFT_SKILL,
    payload: softSkill
});

export const updateSoftSkillSuccess = (data) => ({
    type: UPDATE_SOFT_SKILL_SUCCESS,
    payload: data
});

export const updateSoftSkillFail = (error) => ({
    type: UPDATE_SOFT_SKILL_FAIL,
    payload: error,
});