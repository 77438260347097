import {
    GET_ADVERTISEMENTS,
    GET_ADVERTISEMENTS_SUCCESS,
    GET_ADVERTISEMENTS_FAIL,
    GET_ADVERTISEMENT_DETAIL,
    GET_ADVERTISEMENT_DETAIL_SUCCESS,
    GET_ADVERTISEMENT_DETAIL_FAIL,
    ADD_ADVERTISEMENT,
    ADD_ADVERTISEMENT_SUCCESS,
    ADD_ADVERTISEMENT_FAIL,
    UPDATE_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT_SUCCESS,
    UPDATE_ADVERTISEMENT_FAIL,
    DELETE_ADVERTISEMENT,
    DELETE_ADVERTISEMENT_SUCCESS,
    DELETE_ADVERTISEMENT_FAIL,
    RESET_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS,
    UPDATE_ADVERTISEMENT_ACTIVE_FAIL,
    GET_ADVERTISEMENTS_FOR_QR_SUCCESS,
    GET_ADVERTISEMENTS_FOR_QR_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    isActive:[]
   
};

const Advertisements = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ADVERTISEMENTS_FOR_QR_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_ADVERTISEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(advertisement => advertisement._id.toString() !== action.payload.data.toString())
            }
        case ADD_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(advertisement =>
                    advertisement._id.toString() === action.payload.data._id.toString() ?
                        { advertisement, ...action.payload.data } :
                        advertisement
                )
            }
            case UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS:
                return {
                    ...state,
                    isActive: state.data.map(advertisement =>
                        advertisement._id.toString() === action.payload.data._id.toString() ?
                            { advertisement, ...action.payload.data } :
                            advertisement
                    )
                }

        //FAILS
        case GET_ADVERTISEMENTS_FAIL:
        case DELETE_ADVERTISEMENT_FAIL:
        case ADD_ADVERTISEMENT_FAIL:
        case UPDATE_ADVERTISEMENT_FAIL:
        case UPDATE_ADVERTISEMENT_ACTIVE_FAIL:
        case GET_ADVERTISEMENTS_FOR_QR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ADVERTISEMENT: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Advertisements;