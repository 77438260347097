/** Get LABEL */
export const GET_LABELS = "GET_LABELS";
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS";
export const GET_LABELS_FAIL = "GET_LABELS_FAIL";

export const GET_LABEL_DETAIL = "GET_LABEL_DETAIL";
export const GET_LABEL_DETAIL_SUCCESS = "GET_LABEL_DETAIL_SUCCESS";
export const GET_LABEL_DETAIL_FAIL = "GET_LABEL_DETAIL_FAIL";


export const ADD_LABEL = "ADD_LABEL";
export const ADD_LABEL_SUCCESS = "ADD_LABEL_SUCCESS";
export const ADD_LABEL_FAIL = "ADD_LABEL_FAIL";

export const UPDATE_LABEL = "UPDATE_LABEL";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_FAIL = "UPDATE_LABEL_FAIL";

export const DELETE_LABEL = "DELETE_LABEL";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAIL = "DELETE_LABEL_FAIL"; 



export const RESET_LABEL= "RESET_LABEL";