export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"; 

//neighborhood
export const GET_NEIGHTBORHOODS = "GET_NEIGHTBORHOODS";
export const GET_NEIGHTBORHOODS_SUCCESS = "GET_NEIGHTBORHOODS_SUCCESS";
export const GET_NEIGHTBORHOODS_FAIL = "GET_NEIGHTBORHOODS_FAIL"; 

export const RESET_CITIES = "RESET_CITIES"; 