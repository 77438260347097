import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_DUTY_FORMS, DELETE_DEV_DUTY_FORM, ADD_DEV_DUTY_FORM, UPDATE_DEV_DUTY_FORM,GET_DEV_DUTY_FORMS_MY_DEV_DUTY_FORMS} from "./actionTypes";
import {
    getDevDutyFormsSuccess, getDevDutyFormsFail,
    deleteDevDutyFormSuccess, deleteDevDutyFormFail,
    updateDevDutyFormSuccess, updateDevDutyFormFail,
    addDevDutyFormSuccess, addDevDutyFormFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevDutyForms, //API CONNECTION
    delDevDutyForm,
    addDevDutyForm,
    updateDevDutyForm,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevDutyForms() {
    try {
        const response = yield call(getDevDutyForms)
        yield put(getDevDutyFormsSuccess(response));
    } catch (error) {
        yield put(getDevDutyFormsFail(error));
    }
}

function* onDeleteDevDutyForms({payload:id}){
    try{
        const response = yield call(delDevDutyForm, id)
        yield put(deleteDevDutyFormSuccess(response));
    } catch (error) {
        yield put(deleteDevDutyFormFail(error));
    }
}

function* onAddDevDutyForm({payload:devDutyForm}){
    try{
        const response = yield call(addDevDutyForm, devDutyForm)
        yield put(addDevDutyFormSuccess(response));
    } catch (error) {
        yield put(addDevDutyFormFail(error));
    }
}

function* onUpdateDevDutyForm({payload:devDutyForm}){
    try{
        const response = yield call(updateDevDutyForm, devDutyForm)
        yield put(updateDevDutyFormSuccess(response));
    } catch (error) {
        yield put(updateDevDutyFormFail(error));
    }
}

export function* devDutyFormsSaga() {
    yield takeEvery(GET_DEV_DUTY_FORMS, fetchDevDutyForms);
    yield takeEvery(DELETE_DEV_DUTY_FORM, onDeleteDevDutyForms);
    yield takeEvery(ADD_DEV_DUTY_FORM, onAddDevDutyForm);
    yield takeEvery(UPDATE_DEV_DUTY_FORM, onUpdateDevDutyForm);
   
    
}

export default devDutyFormsSaga;
