import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
    Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
    DropdownItem
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    getDevSectorForms as onGetSectorForm,
    getDevDutys as onGetDuty,
    addDevSectorForm as onAddSectorForm,
    updateDevSectorForm as onUpdateSectorForm,
    resetDevSectorForm as onResetSectorForm,
    deleteDevSectorForm as onDeleteSectorForm
} from "store/actions" 
import config from "config"
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Dropzone from "react-dropzone"
import imgEmpty from "./../../assets/images/empty.png"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
}];

const pageOptions = {
    sizePerPage: 10,
    totalSize: 0, // replace later with size(customers),
    custom: true,
}


const DevSectorForm = props => {
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [modal, setModal] = useState(false);
    const [checkModal, setCheckModal] = useState(false);
    const [selectedFiles, setselectedFiles] = useState([])
    const dispatch = useDispatch()
    const [selectedWorkType, setSelectedWorkType] = useState()
    const [selectedId, setSelectedId] = useState();
    const [selectedPayment, setSelectedPayment] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const { SearchBar } = Search;
    useEffect(() => {
        dispatch(onGetSectorForm());
        dispatch(onGetDuty());
        
    }, [dispatch]);

    const { error, DevSectorForm } = useSelector(state => ({
        error: state.Brands?.error,
        DevSectorForm: state.DevSectorForm?.data


    }))
  
    const columns = [
       
        {
            dataField: 'name',
            text: props.t("Name"), 
            sort: true

        },
       

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedPayment(row);
                                    console.log("22row", row)

                                    setIsEdit(true);
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];


    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }

    const handleValidProductSubmit = (e, values) => {
        if (isEdit) {
            const updateBrand = {
                _id: selectedPayment._id,
                name: values["name"],
              
            };

            dispatch(onUpdateSectorForm(updateBrand));
        } else {
            const newBrand = {
                CompanyId: user.companyId,
                name: values["name"],
                CreatorUserId:user.uid
            }

            dispatch(onAddSectorForm(newBrand))
        }
        toggle()

    }
 

 
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Form içi Sektör")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Form içi Sektör")} breadcrumbItem={props.t("Form içi Sektör")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedPayment(
                            {
                                companyId: "",
                                name: "",
                                link: " ",
                                description: "",
                                amount: "",
                                // PaymentPlanObjects: "",

                            }
                        );


                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={DevSectorForm}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={DevSectorForm}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} size="lg" toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Form İçi Sektör Düzenle") : props.t("Form İçi Sektör Ekle")}
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidProductSubmit} >
                                        <Row form>
                                            <Col xs={12}>
                                                <Row>
                                                  
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="name"
                                                                label={props.t("Name")}
                                                                type="text"
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                value={selectedPayment?.name || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                  


                                                </Row>




                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        {props.t("Save")}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={checkModal} size="lg" toggle={checkToggle}>
                                <ModalHeader toggle={checkToggle} tag="h4">
                                    {!!isEdit ? props.t("Ürün") : props.t("Ürün")}
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm   >
                                        <Row form>
                                            <Col xs={12}>

                                                <Row>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="brand"
                                                                label={props.t("Brand")}
                                                                type="text"
                                                                disabled
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                value={selectedPayment?.brand || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="model"
                                                                label={props.t("model")}
                                                                type="text"
                                                                disabled
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: false },
                                                                }}
                                                                value={selectedPayment?.model || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>








                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    {/* <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button> */}
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteSectorForm(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetSectorForm())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}


export default withRouter(withTranslation()(DevSectorForm))
