/** Get PLAN */
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

export const GET_PLAN_DETAIL = "GET_PLAN_DETAIL";
export const GET_PLAN_DETAIL_SUCCESS = "GET_PLAN_DETAIL_SUCCESS";
export const GET_PLAN_DETAIL_FAIL = "GET_PLAN_DETAIL_FAIL";


export const ADD_PLAN = "ADD_PLAN";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL";

export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL"; 

export const GET_PLANS_MY_PLANS = "GET_PLANS_MY_PLANS";
export const GET_PLANS_MY_PLANS_SUCCESS = "GET_PLANS_MY_PLANS_SUCCESS";
export const GET_PLANS_MY_PLANS_FAIL = "GET_PLANS_MY_PLANS_FAIL";

export const ADD_PLANS_MY_PLANS = "ADD_PLANS_MY_PLANS";
export const ADD_PLANS_MY_PLANS_SUCCESS = "ADD_PLANS_MY_PLANS_SUCCESS";
export const ADD_PLANS_MY_PLANS_FAIL = "ADD_PLANS_MY_PLANS_FAIL";

export const DELETE_PLANS_MY_PLANS = "DELETE_PLANS_MY_PLANS";
export const DELETE_PLANS_MY_PLANS_SUCCESS = "DELETE_PLANS_MY_PLANS_SUCCESS";
export const DELETE_PLANS_MY_PLANS_FAIL = "DELETE_PLANS_MY_PLANS_FAIL";

export const UPDATE_PLANS_MY_PLANS = "UPDATE_PLANS_MY_PLANS";
export const UPDATE_PLANS_MY_PLANS_SUCCESS = "UPDATE_PLANS_MY_PLANS_SUCCESS";
export const UPDATE_PLANS_MY_PLANS_FAIL = "UPDATE_PLANS_MY_PLANS_FAIL";

export const GET_PLANS_BY_BRANCH = "GET_PLANS_BY_BRANCH";
export const GET_PLANS_BY_BRANCH_SUCCESS = "GET_PLANS_BY_BRANCH_SUCCESS";
export const GET_PLANS_BY_BRANCH_FAIL = "GET_PLANS_BY_BRANCH_FAIL";

export const GET_BRANCH_PLAN = "GET_BRANCH_PLAN";
export const GET_BRANCH_PLAN_SUCCESS = "GET_BRANCH_PLAN_SUCCESS";
export const GET_BRANCH_PLAN_FAIL = "GET_BRANCH_PLAN_FAIL";

export const GET_USER_BY_MAIL = "GET_USER_BY_MAIL";
export const GET_USER_BY_MAIL_SUCCESS = "GET_USER_BY_MAIL_SUCCESS";
export const GET_USER_BY_MAIL_FAIL = "GET_USER_BY_MAIL_FAIL";

export const GET_PLANS_DOWN = "GET_PLANS_DOWN";
export const GET_PLANS_DOWN_SUCCESS = "GET_PLANS_DOWN_SUCCESS";
export const GET_PLANS_DOWN_FAIL = "GET_PLANS_DOWN_FAIL";

export const UPDATE_PLAN_ACTIVE = "UPDATE_PLAN_ACTIVE";
export const UPDATE_PLAN_ACTIVE_SUCCESS = "UPDATE_PLAN_ACTIVE_SUCCESS";
export const UPDATE_PLAN_ACTIVE_FAIL = "UPDATE_PLAN_ACTIVE_FAIL";

export const RESET_PLAN= "RESET_PLAN";