import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DUTIES_FORMS, DELETE_DUTIES_FORM, ADD_DUTIES_FORM, UPDATE_DUTIES_FORM,GET_DUTIES_FORM_DETAIL} from "./actionTypes";
import {
    getDutiesFormsSuccess, getDutiesFormsFail,
    deleteDutiesFormSuccess, deleteDutiesFormFail,
    updateDutiesFormSuccess, updateDutiesFormFail,
    addDutiesFormSuccess, addDutiesFormFail,
    getDutiesFormByIdSuccess,getDutiesFormByIdFail
 } from "./actions";
 
//Include Both Helper File with needed methods
import {
    getDutiesForms, //API CONNECTION
    delDutiesForm,
    addDutiesForm,
    updateDutiesForm,
    getDutiesFormById
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDutiesForms() {
    try {
        const response = yield call(getDutiesForms)
        yield put(getDutiesFormsSuccess(response));  
    } catch (error) {
        yield put(getDutiesFormsFail(error));
    }
}
function* fetchDutiesFormsById({payload:id}) {
    try {
        const response = yield call(getDutiesFormById,id)
        yield put(getDutiesFormByIdSuccess(response));  
    } catch (error) {
        yield put(getDutiesFormByIdFail(error)); 
    }
}

function* onDeleteDutiesForms({payload:id}){
    try{
        const response = yield call(delDutiesForm, id)
        yield put(deleteDutiesFormSuccess(response));
    } catch (error) {
        yield put(deleteDutiesFormFail(error));
    }  
}

function* onAddDutiesForm({payload:dutiesForm}){
    try{
        const response = yield call(addDutiesForm, dutiesForm)
        yield put(addDutiesFormSuccess(response));
    } catch (error) {
        yield put(addDutiesFormFail(error));
    }
}

function* onUpdateDutiesForm({payload:dutiesForm}){
    try{
        const response = yield call(updateDutiesForm, dutiesForm)
        yield put(updateDutiesFormSuccess(response));
    } catch (error) {
        yield put(updateDutiesFormFail(error));
    }
}

export function* dutiesFormsSaga() {
    yield takeEvery(GET_DUTIES_FORMS, fetchDutiesForms);
    yield takeEvery(DELETE_DUTIES_FORM, onDeleteDutiesForms);
    yield takeEvery(ADD_DUTIES_FORM, onAddDutiesForm);
    yield takeEvery(UPDATE_DUTIES_FORM, onUpdateDutiesForm);
    yield takeEvery(GET_DUTIES_FORM_DETAIL, fetchDutiesFormsById);
   
    
}

export default dutiesFormsSaga;
