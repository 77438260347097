/** Get ADVERTISEMENT */
export const GET_ADVERTISEMENTS = "GET_ADVERTISEMENTS";
export const GET_ADVERTISEMENTS_SUCCESS = "GET_ADVERTISEMENTS_SUCCESS";
export const GET_ADVERTISEMENTS_FAIL = "GET_ADVERTISEMENTS_FAIL";

export const GET_ADVERTISEMENT_DETAIL = "GET_ADVERTISEMENT_DETAIL";
export const GET_ADVERTISEMENT_DETAIL_SUCCESS = "GET_ADVERTISEMENT_DETAIL_SUCCESS";
export const GET_ADVERTISEMENT_DETAIL_FAIL = "GET_ADVERTISEMENT_DETAIL_FAIL";


export const GET_ADVERTISEMENTS_FOR_QR = "GET_ADVERTISEMENTS_FOR_QR";
export const GET_ADVERTISEMENTS_FOR_QR_SUCCESS = "GET_ADVERTISEMENTS_FOR_QR_SUCCESS";
export const GET_ADVERTISEMENTS_FOR_QR_FAIL = "GET_ADVERTISEMENTS_FOR_QR_FAIL";


export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT";
export const ADD_ADVERTISEMENT_SUCCESS = "ADD_ADVERTISEMENT_SUCCESS";
export const ADD_ADVERTISEMENT_FAIL = "ADD_ADVERTISEMENT_FAIL";

export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT_SUCCESS = "UPDATE_ADVERTISEMENT_SUCCESS";
export const UPDATE_ADVERTISEMENT_FAIL = "UPDATE_ADVERTISEMENT_FAIL";

export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT_SUCCESS = "DELETE_ADVERTISEMENT_SUCCESS";
export const DELETE_ADVERTISEMENT_FAIL = "DELETE_ADVERTISEMENT_FAIL"; 

export const UPDATE_ADVERTISEMENT_ACTIVE = "UPDATE_ADVERTISEMENT_ACTIVE";
export const UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS = "UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS";
export const UPDATE_ADVERTISEMENT_ACTIVE_FAIL = "UPDATE_ADVERTISEMENT_ACTIVE_FAIL"; 



export const RESET_ADVERTISEMENT= "RESET_ADVERTISEMENT";