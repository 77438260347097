import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button

} from "reactstrap"

import InputMask from "react-input-mask"
import imgEmpty from "./../../assets/images/empty.png"
// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/bqr1.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import CarouselPage from "./CarouselPage"
import Dropzone from "react-dropzone"
//i18n 
import { withTranslation } from "react-i18next"

// action
import {
  registerUser, apiError, registerSuccessReset, register2User,
  getStatuss as onGetStatus
} from "../../store/actions"

import "./datatables.scss"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useParams } from "react-router-dom"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  const dispatch = useDispatch()
  const [country, setCountry] = useState(false)
  const [region, setRegion] = useState(false)
  const [city, setCity] = useState(false)
  const [district, setDistrict] = useState(false)
  const [neighbourhood, setNeighbourhood] = useState(false)
  const [semt, setSemt] = useState(false)
  const [street, setStreet] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [statusModal, setStatusModal] = useState(false)
  const [grades, setGrades] = useState([
    { grade: 1, id: "Firmalar", isActive: true },
    { grade: 2, id: "Ülkeler", isActive: false },
    { grade: 3, id: "Bölgeler", isActive: false },
    { grade: 4, id: "İller", isActive: false },
    { grade: 5, id: "İlçeler", isActive: false },
    { grade: 6, id: "Mahalleler", isActive: false },
    { grade: 7, id: "Semtler", isActive: false },
    { grade: 8, id: "Caddeler", isActive: false },
  ])

  const [Regions,setRegions] = useState([
  {name: "Marmara",isActive:false,
  cities:[
    {cityName:"Edirne",isCityActive:false, district:[{districtName:"Edirne (il merkezi)"},{districtName:"Enez"},{districtName:"Havsa"},{districtName:"İpsala"},{districtName:"Keşan"},{districtName:"Lalapaşa"},{districtName:"Meriç"},{districtName:"Süloğlu"},{districtName:"Uzunköprü"}]},
    {cityName:"Kırklareli",isCityActive:false,district:[{districtName:"Babaeski"},{districtName:"Demirköy"},{districtName:"Kofçaz"},{districtName:"Lüleburgaz"},{districtName:"Pehlivanköy"},{districtName:"Pınarhisar"},{districtName:"Vize"}]}
    ,{cityName:"Tekirdağ",isCityActive:false,district:[{districtName:"Süleymanpaşa"},{districtName:"Çerkezköy"},{districtName:"Çorlu"},{districtName:"Ergene"},{districtName:"Hayrabolu"},{districtName:"Kapaklı"},{districtName:"Malkara"},{districtName:"Marmaraereğlisi"},{districtName:"Muratlı"},{districtName:"Saray"},{districtName:"Şarköy"}]},
    {cityName:"İstanbul Avrupa",isCityActive:false, district:[{districtName:"Arnavutköy"},{districtName:"Avcılar"},{districtName:"Bağcılar"},{districtName:"Bahçelievler"},{districtName:"Bakırköy"},{districtName:"Başakşehir"},{districtName:"Bayrampaşa"},{districtName:"Beşiktaş"},{districtName:"Beylikdüzü"},{districtName:"Beyoğlu"},{districtName:"Büyükçekmece"},{districtName:"Çatalca"},{districtName:"Esenler"},
    {districtName:"Esenyurt"},{districtName:"Eyüpsultan"},{districtName:"Fatih"},{districtName:"Gaziosmanpaşa"},{districtName:"Güngören"},{districtName:"Kâğıthane"},{districtName:"Küçükçekmece"},{districtName:"Sarıyer"},{districtName:"Silivri"},{districtName:"Sultangazi"},{districtName:"Şişli"},{districtName:"Zeytinburnu"}]}
    ,{cityName:"İstanbul Anadolu",isCityActive:false,district:[{districtName:"Adalar"},{districtName:"Ataşehir"},{districtName:"Beykoz"},{districtName:"Çekmeköy"},{districtName:"Kadıköy"},{districtName:"Kartal"},{districtName:"Maltepe"},{districtName:"Pendik"},{districtName:"Sancaktepe"},{districtName:"Sultanbeyli"},{districtName:"Şile"},{districtName:"Tuzla"},{districtName:"Ümraniye"},{districtName:"Üsküdar"}]}
    ,{cityName:"Kocaeli",isCityActive:false,district:[{districtName:"Başiskele"},{districtName:"Çayırova"},{districtName:"Darıca"},{districtName:"Derince"},{districtName:"Dilovası"},{districtName:"Gebze"},{districtName:"Gölcük"},{districtName:"İzmit"},{districtName:"Kandıra"},{districtName:"Karamürsel"},{districtName:"Kartepe"},{districtName:"Körfez"}]},
    {cityName:"Yalova",isCityActive:false,district:[{districtName:"Yalova (il merkezi)"},{districtName:"Altınova"},{districtName:"Armutlu"},{districtName:"Çınarcık"},{districtName:"Çiftlikköy"},{districtName:"Termal"}]},
    {cityName:"Sakarya",isCityActive:false,district:[{districtName:"Adapazarı"},{districtName:"Arifiye"},{districtName:"Akyazı"},{districtName:"Erenler"},{districtName:"Serdivan"},{districtName:"Ferizli"},{districtName:"Geyve"},{districtName:"Hendek"},{districtName:"Karapürçek"},{districtName:"Karasu"},{districtName:"Kaynarca"},{districtName:"Kocaali"},{districtName:"Pamukova"},{districtName:"Sapanca"},{districtName:"Söğütlü"},{districtName:"Taraklı"}]},
    {cityName:"Bilecik",isCityActive:false,district:[{districtName:"Bilecik (il merkezi)"},{districtName:"Bozüyük"},{districtName:"Gölpazarı"},{districtName:"İnhisar"},{districtName:"Osmaneli"},{districtName:"Pazaryeri"},{districtName:"Söğüt"},{districtName:"Yenipazar"}]}
    ,{cityName:"Bursa",isCityActive:false,district:[{districtName:"Gürsu"},{districtName:"Kestel"},{districtName:"Nilüfer"},{districtName:"Osmangazi"},{districtName:"Yıldırım"},{districtName:"Büyükorhan"},{districtName:"Gemlik"},{districtName:"Harmancık"},{districtName:"İnegöl"},{districtName:"İznik"},{districtName:"Karacabey"},{districtName:"Keles"},{districtName:"Mudanya"},{districtName:"Mustafakemalpaşa"},{districtName:"Orhaneli"},{districtName:"Orhangazi"},{districtName:"Yenişehir"}]},
    {cityName:"Balıkesir",isCityActive:false,district:[{districtName:"Altıeylül"},{districtName:"Karesi"},{districtName:"Ayvalık"},{districtName:"Balya"},{districtName:"Bandırma"},{districtName:"Bigadiç"},{districtName:"Burhaniye"},{districtName:"Dursunbey"},{districtName:"Edremit"},{districtName:"Erdek"},{districtName:"Gömeç"},{districtName:"Gönen"},{districtName:"Havran"},{districtName:"İvrindi"},{districtName:"Kepsut"},{districtName:"Manyas"},{districtName:"Marmara"},{districtName:"Savaştepe"},{districtName:"Sındırgı"},{districtName:"Susurluk"}]}
    ,{cityName:"Çanakkale",isCityActive:false,district:[{districtName:"Çanakkale (il merkezi)"},{districtName:"Ayvacık"},{districtName:"Bayramiç"},{districtName:"Biga"},{districtName:"Bozcaada"},{districtName:"Çan"},{districtName:"Eceabat"},{districtName:"Ezine"},{districtName:"Gelibolu"},{districtName:"Gökçeada"},{districtName:"Lapseki"},{districtName:"Yenice"}]}]}, 
  {name: "İç Anadolu",isActive:false,
  cities:[
    {cityName:"Aksaray",isCityActive:false,district:[{districtName:"Aksaray (İl merkezi)"},{districtName:"Ağaçören"},{districtName:"Eskil"},{districtName:"Gülağaç"},{districtName:"Güzelyurt"},{districtName:"Ortaköy"},{districtName:"Sarıyahşi"},{districtName:"Sultanhanı"}]},
    {cityName:"Ankara",isCityActive:false,district:[{districtName:"Akyurt"},{districtName:"Altındağ"},{districtName:"Ayaş"},{districtName:"Balâ"},{districtName:"Beypazarı"},{districtName:"Çamlıdere"},{districtName:"Çankaya"},{districtName:"Çubuk"},{districtName:"Elmadağ"},{districtName:"Etimesgut"},{districtName:"Evren"},{districtName:"Gölbaşı"},{districtName:"Güdül"},{districtName:"Haymana"},{districtName:"Kahramankazan"},{districtName:"Kalecik"},{districtName:"Keçiören"},{districtName:"Kızılcahamam"},{districtName:"Mamak"},{districtName:"Nallıhan"},{districtName:"Polatlı"},{districtName:"Pursaklar"},{districtName:"Sincan"},{districtName:"Şereflikoçhisar"},{districtName:"Yenimahalle"}]},
    {cityName:"Çankırı",isCityActive:false,district:[{districtName:"Çankırı (il merkezi)"},{districtName:"Atkaracalar"},{districtName:"Bayramören"},{districtName:"Çerkeş"},{districtName:"Eldivan"},{districtName:"Ilgaz"},{districtName:"Kızılırmak"},{districtName:"Korgun"},{districtName:"Kurşunlu"},{districtName:"Orta"},{districtName:"Şabanözü"},{districtName:"Yapraklı"}]},
    {cityName:"Eskişehir",isCityActive:false,district:[{districtName:"Odunpazarı"},{districtName:"Tepebaşı"},{districtName:"Alpu"},{districtName:"Beylikova"},{districtName:"Çifteler"},{districtName:"Günyüzü"},{districtName:"Han"},{districtName:"İnönü"},{districtName:"Mahmudiye"},{districtName:"Mihalgazi"},{districtName:"Mihalıççık"},{districtName:"Sarıcakaya"},{districtName:"Seyitgazi"},{districtName:"Sivrihisar"}]},
    {cityName:"Karaman",isCityActive:false,district:[{districtName:"Karaman (il merkezi)"},{districtName:"Ayrancı"},{districtName:"Başyayla"},{districtName:"Ermenek"},{districtName:"Kazımkarabekir"},{districtName:"Sarıveliler"}]},
    {cityName:"Kırıkkale",isCityActive:false,district:[{districtName:"Kırıkkale (il merkezi)"},{districtName:"Bahşılı"},{districtName:"Balışeyh"},{districtName:"Çelebi"},{districtName:"Delice"},{districtName:"Karakeçili"},{districtName:"Keskin"},{districtName:"Sulakyurt"},{districtName:"Yahşihan"}]},
    {cityName:"Kırşehir",isCityActive:false,district:[{districtName:"Kırşehir (İl merkezi)"},{districtName:"Akçakent"},{districtName:"Akpınar"},{districtName:"Boztepe"},{districtName:"Çiçekdağı"},{districtName:"Kaman"},{districtName:"Mucur"}]},
    {cityName:"Konya",isCityActive:false,district:[{districtName:"Karatay"},{districtName:"Meram"},{districtName:"Selçuklu"},{districtName:"Ahırlı"},{districtName:"Akören"},{districtName:"Akşehir"},{districtName:"Altınekin"},{districtName:"Beyşehir"},{districtName:"Bozkır"},{districtName:"Cihanbeyli"},{districtName:"Çeltik"},{districtName:"Çumra"},{districtName:"Derbent"},{districtName:"Derebucak"},{districtName:"Doğanhisar"},{districtName:"Emirgazi"},{districtName:"Ereğli"},{districtName:"Güneysınır"},{districtName:"Hadim"},{districtName:"Halkapınar"},{districtName:"Hüyük"},{districtName:"Ilgın"},{districtName:"Kadınhanı"},{districtName:"Karapınar"},{districtName:"Kulu"},{districtName:"Sarayönü"},{districtName:"Seydişehir"},{districtName:"Taşkent"},{districtName:"Tuzlukçu"},{districtName:"Yalıhüyük"},{districtName:"Yunak"}]},
    {cityName:"Nevşehir",isCityActive:false,district:[{districtName:"Nevşehir (merkez ilçe)"},{districtName:"Acıgöl"},{districtName:"Avanos"},{districtName:"Derinkuyu"},{districtName:"Gülşehir"},{districtName:"Hacıbektaş"},{districtName:"Kozaklı"},{districtName:"Ürgüp"}]},
    {cityName:"Niğde",isCityActive:false,district:[{districtName:"Niğde (İl merkezi)"},{districtName:"Altunhisar"},{districtName:"Bor"},{districtName:"Çamardı"},{districtName:"Çiftlik"},{districtName:"Ulukışla"}]},
    {cityName:"Sivas",isCityActive:false,district:[{districtName:"Sivas (İl merkezi)"},{districtName:"Akıncılar"},{districtName:"Altınyayla"},{districtName:"Divriği"},{districtName:"Doğanşar"},{districtName:"Gemerek"},{districtName:"Gölova"},{districtName:"Gürün"},{districtName:"Hafik"},{districtName:"İmranlı"},{districtName:"Kangal"},{districtName:"Koyulhisar"},{districtName:"Suşehri"},{districtName:"Şarkışla"},{districtName:"Ulaş"},{districtName:"Yıldızeli"},{districtName:"Zara"}]},
    {cityName:"Yozgat",isCityActive:false,district:[{districtName:"Yozgat (il merkezi)"},{districtName:"Akdağmadeni"},{districtName:"Aydıncık"},{districtName:"Boğazlıyan"},{districtName:"Çandır"},{districtName:"Çayıralan"},{districtName:"Çekerek"},{districtName:"Kadışehri"},{districtName:"Saraykent"},{districtName:"Sarıkaya"},{districtName:"Sorgun"},{districtName:"Şefaatli"},{districtName:"Yenifakılı"},{districtName:"Yerköy"}]},
    {cityName:"Kayseri",isCityActive:false,district:[{districtName:""},{districtName:"Hacılar"},{districtName:"Kocasinan"},{districtName:"Melikgazi"},{districtName:"Talas"},{districtName:"Akkışla"},{districtName:"Bünyan"},{districtName:"Develi"},{districtName:"Felahiye"},{districtName:"İncesu"},{districtName:"Özvatan"},{districtName:"Pınarbaşı"},{districtName:""},{districtName:"Sarıoğlan"},{districtName:"Sarız"},{districtName:"Tomarza"},{districtName:"Yahyalı"},{districtName:"Yeşilhisar"}]}]}, 
  { name: "Ege",isActive:false,
  cities:[
    {cityName:"İzmir",isCityActive:false,district:[{districtName:"Balçova"},{districtName:"Bayraklı"},{districtName:"Bornova"},{districtName:"Buca"},{districtName:"Çiğli"},{districtName:"Gaziemir"},{districtName:"Güzelbahçe"},{districtName:"Karabağlar"},{districtName:"Karşıyaka"},{districtName:"Konak"},{districtName:"Narlıdere"},{districtName:"Aliağa"},{districtName:"Bayındır"},{districtName:"Bergama"},{districtName:"Beydağ"},{districtName:"Çeşme"},{districtName:"Dikili"},{districtName:"Foça"},{districtName:"Karaburun"},{districtName:"Kemalpaşa"},{districtName:"Kınık"},{districtName:"Kiraz"},{districtName:"Menderes"},{districtName:"Menemen"},{districtName:"Ödemiş"},{districtName:"Seferihisar"},{districtName:"Selçuk"},{districtName:"Tire"},{districtName:"Torbalı"},{districtName:"Urla"}]},
    {cityName:"Manisa",isCityActive:false,district:[{districtName:"Şehzadeler"},{districtName:"Yunusemre"},{districtName:"Ahmetli"},{districtName:"Akhisar"},{districtName:"Alaşehir"},{districtName:"Demirci"},{districtName:"Gölmarmara"},{districtName:"Gördes"},{districtName:"Kırkağaç"},{districtName:"Köprübaşı"},{districtName:"Kula"},{districtName:"Salihli"},{districtName:"Sarıgöl"},{districtName:"Saruhanlı"},{districtName:"Selendi"},{districtName:"Soma"},{districtName:"Turgutlu"}]},
    {cityName:"Aydın",isCityActive:false,district:[{districtName:"Efeler (Merkez ilçe)"},{districtName:"Bozdoğan"},{districtName:"Buharkent"},{districtName:"Çine"},{districtName:"Didim"},{districtName:"Germencik"},{districtName:"İncirliova"},{districtName:"İncirliova"},{districtName:"Karpuzlu"},{districtName:"Koçarlı"},{districtName:"Köşk"},{districtName:"Kuşadası"},{districtName:"Kuyucak"},{districtName:"Nazilli"},{districtName:"Söke"},{districtName:"Sultanhisar"},{districtName:"Yenipazar"}]},
    {cityName:"Denizli",isCityActive:false,district:[{districtName:"Merkezefendi"},{districtName:"Pamukkale"},{districtName:"Acıpayam"},{districtName:"Babadağ"},{districtName:"Baklan"},{districtName:"Bekilli"},{districtName:"Beyağaç"},{districtName:"Bozkurt"},{districtName:"Buldan"},{districtName:"Çal"},{districtName:"Çameli"},{districtName:"Çardak"},{districtName:"Çivril"},{districtName:"Güney"},{districtName:"Honaz"},{districtName:"Kale"},{districtName:"Sarayköy"},{districtName:"Serinhisar"},{districtName:"Tavas"}]},
    {cityName:"Kütahya",isCityActive:false,district:[{districtName:"Kütahya (İl merkezi)"},{districtName:"Altıntaş"},{districtName:"Aslanapa"},{districtName:"Çavdarhisar"},{districtName:"Domaniç"},{districtName:"Dumlupınar"},{districtName:"Emet"},{districtName:"Gediz"},{districtName:"Hisarcık"},{districtName:"Pazarlar"},{districtName:"Simav"},{districtName:"Şaphane"},{districtName:"Tavşanlı"}]},
    {cityName:"Afyonkarahisar",isCityActive:false,district:[{districtName:"Afyonkarahisar (İl merkezi)"},{districtName:"Bayat"},{districtName:"Başmakçı"},{districtName:"Bolvadin"},{districtName:"Çay"},{districtName:"Çobanlar"},{districtName:"Dazkırı"},{districtName:"Dinar"},{districtName:"Emirdağ"},{districtName:"Evciler"},{districtName:"Hocalar"},{districtName:"İhsaniye"},{districtName:"İscehisar"},{districtName:"Kızılören"},{districtName:"Sandıklı"},{districtName:"Sinanpaşa"},{districtName:"Sultandağı"},{districtName:"Şuhut"}]},
    {cityName:"Uşak",isCityActive:false,district:[{districtName:"Uşak (il merkezi)"},{districtName:"Banaz"},{districtName:"Eşme"},{districtName:"Karahallı"},{districtName:"Sivaslı"},{districtName:"Ulubey"}]},
    {cityName:"Muğla",isCityActive:false,district:[{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""},{districtName:""}]}] }, 
  { name: "Akdeniz",isActive:false,
  cities:[
    {cityName:"Adana",isCityActive:false,district:[{districtName:"Çukurova"},{districtName:"Sarıçam"},{districtName:"Seyhan"},{districtName:"Yüreğir"},{districtName:"Aladağ"},{districtName:"Ceyhan"},{districtName:"Feke"},{districtName:"İmamoğlu"},{districtName:"Karaisalı"},{districtName:"Karataş"},{districtName:"Kozan"},{districtName:"Pozantı"},{districtName:"Saimbeyli"},{districtName:"Tufanbeyli"},{districtName:"Yumurtalık"}]},
    {cityName:"Osmaniye",isCityActive:false,district:[{districtName:"Osmaniye (il merkezi)"},{districtName:"Bahçe"},{districtName:"Düziçi"},{districtName:"Hasanbeyli"},{districtName:"Kadirli"},{districtName:"Sumbas"},{districtName:"Toprakkale"}]},
    {cityName:"Antalya",isCityActive:false,district:[{districtName:"Aksu"},{districtName:"Döşemealtı"},{districtName:"Kepez"},{districtName:"Konyaaltı"},{districtName:"Muratpaşa"},{districtName:"Akseki"},{districtName:"Alanya"},{districtName:"Demre"},{districtName:"Elmalı"},{districtName:"Finike"},{districtName:"Gazipaşa"},{districtName:"Gündoğmuş"},{districtName:"İbradı"},{districtName:"Kaş"},{districtName:"Kemer"},{districtName:"Korkuteli"},{districtName:"Kumluca"},{districtName:"Manavgat"},{districtName:"Serik"}]},
    {cityName:"Burdur",isCityActive:false,district:[{districtName:"Burdur (il merkezi)"},{districtName:"Ağlasun"},{districtName:"Altınyayla"},{districtName:"Bucak"},{districtName:"Çavdır"},{districtName:"Çeltikçi"},{districtName:"Gölhisar"},{districtName:"Karamanlı"},{districtName:"Kemer"},{districtName:"Tefenni"},{districtName:"Yeşilova"}]},
    {cityName:"Hatay",isCityActive:false,district:[{districtName:"Antakya"},{districtName:"Defne"},{districtName:"Altınözü"},{districtName:"Arsuz"},{districtName:"Belen"},{districtName:"Dörtyol"},{districtName:"Erzin"},{districtName:"Hassa"},{districtName:"İskenderun"},{districtName:"Kırıkhan"},{districtName:"Kumlu"},{districtName:"Payas"},{districtName:"Reyhanlı"},{districtName:"Samandağ"},{districtName:"Yayladağı"}]},
    {cityName:"Isparta",isCityActive:false,district:[{districtName:"Isparta (İl merkezi)"},{districtName:"Aksu"},{districtName:"Atabey"},{districtName:"Eğirdir"},{districtName:"Gelendost"},{districtName:"Gönen"},{districtName:"Keçiborlu"},{districtName:"Senirkent"},{districtName:"Sütçüler"},{districtName:"Şarkikaraağaç"},{districtName:"Uluborlu"},{districtName:"Yalvaç"},{districtName:"Yenişarbademli"}]},
    {cityName:"Mersin",isCityActive:false,district:[{districtName:"Akdeniz"},{districtName:"Mezitli"},{districtName:"Toroslar"},{districtName:"Yenişehir"},{districtName:"Anamur"},{districtName:"Aydıncık"},{districtName:"Bozyazı"},{districtName:"Çamlıyayla"},{districtName:"Erdemli"},{districtName:"Gülnar"},{districtName:"Mut"},{districtName:"Silifke"},{districtName:"Tarsus"}]},
    {cityName:"Kahramanmaraş",isCityActive:false,district:[{districtName:"Dulkadiroğlu"},{districtName:"Onikişubat"},{districtName:"Afşin"},{districtName:"Andırın"},{districtName:"Çağlayancerit"},{districtName:"Ekinözü"},{districtName:"Elbistan"},{districtName:"Göksun"},{districtName:"Nurhak"},{districtName:"Pazarcık"},{districtName:"Türkoğlu"}]}] }, 
  { name: "Güneydoğu Anadolu",isActive:false 
  ,cities:[
    {cityName:"Adıyaman",isCityActive:false,district:[{districtName:"Adıyaman (İl merkezi)"},{districtName:"Besni"},{districtName:"Çelikhan"},{districtName:"Gerger"},{districtName:"Gölbaşı"},{districtName:"Kâhta"},{districtName:"Samsat"},{districtName:"Sincik"},{districtName:"Tut"}]},
    {cityName:"Batman",isCityActive:false,district:[{districtName:"Batman (il merkezi)"},{districtName:"Beşiri"},{districtName:"Gercüş"},{districtName:"Hasankeyf"},{districtName:"Kozluk"},{districtName:"Sason"}]},
    {cityName:"Diyarbakır",isCityActive:false,district:[{districtName:"Bağlar"},{districtName:"Kayapınar"},{districtName:"Sur"},{districtName:"Yenişehir"},{districtName:"Bismil"},{districtName:"Çermik"},{districtName:"Çınar"},{districtName:"Çüngüş"},{districtName:"Dicle"},{districtName:"Eğil"},{districtName:"Ergani"},{districtName:"Hani"},{districtName:"Hazro"},{districtName:"Kocaköy"},{districtName:"Kulp"},{districtName:"Lice"},{districtName:"Silvan"}]},
    {cityName:"Gaziantep",isCityActive:false,district:[{districtName:"Şahinbey"},{districtName:"Şehitkamil"},{districtName:"Araban"},{districtName:"İslahiye"},{districtName:"Karkamış"},{districtName:"Nizip"},{districtName:"Nurdağı"},{districtName:"Oğuzeli"},{districtName:"Yavuzeli"}]},
    {cityName:"Kilis",isCityActive:false,district:[{districtName:"Kilis (İl merkezi)"},{districtName:"Elbeyli"},{districtName:"Musabeyli"},{districtName:"Polateli"}]},
    {cityName:"Mardin",isCityActive:false,district:[{districtName:"Artuklu"},{districtName:"Dargeçit"},{districtName:"Derik"},{districtName:"Kızıltepe"},{districtName:"Mazıdağı"},{districtName:"Midyat"},{districtName:"Nusaybin"},{districtName:"Ömerli"},{districtName:"Savur"},{districtName:"Yeşilli"}]},
    {cityName:"Siirt",isCityActive:false,district:[{districtName:"Siirt (İl merkezi)"},{districtName:"Baykan"},{districtName:"Eruh"},{districtName:"Kurtalan"},{districtName:"Pervari"},{districtName:"Şirvan"},{districtName:"Tillo"}]},
    {cityName:"Şanlıurfa",isCityActive:false,district:[{districtName:"Eyyübiye"},{districtName:"Haliliye"},{districtName:"Karaköprü"},{districtName:"Akçakale"},{districtName:"Birecik"},{districtName:"Bozova"},{districtName:"Ceylanpınar"},{districtName:"Halfeti"},{districtName:"Harran"},{districtName:"Hilvan"},{districtName:"Siverek"},{districtName:"Suruç"},{districtName:"Viranşehir"}]}]}, 
  { name: "Karadeniz",isActive:false,
  cities:[
    {cityName:"Rize",isCityActive:false,district:[{districtName:"Rize (il merkezi)"},{districtName:"Ardeşen"},{districtName:"Çamlıhemşin"},{districtName:"Çayeli"},{districtName:"Derepazarı"},{districtName:"Fındıklı"},{districtName:"Güneysu"},{districtName:"Hemşin"},{districtName:"İkizdere"},{districtName:"İyidere"},{districtName:"Kalkandere"},{districtName:"Pazar"}]},
    {cityName:"Trabzon",isCityActive:false,district:[{districtName:"Ortahisar"},{districtName:"Akçaabat"},{districtName:"Araklı"},{districtName:"Arsin"},{districtName:"Beşikdüzü"},{districtName:"Çarşıbaşı"},{districtName:"Çaykara"},{districtName:"Dernekpazarı"},{districtName:"Düzköy"},{districtName:"Hayrat"},{districtName:"Köprübaşı"},{districtName:"Maçka"},{districtName:"Of"},{districtName:"Sürmene"},{districtName:"Şalpazarı"},{districtName:"Tonya"},{districtName:"Vakfıkebir"},{districtName:"Yomra"}]},
    {cityName:"Artvin",isCityActive:false,district:[{districtName:"Artvin (il merkezi)"},{districtName:"Ardanuç"},{districtName:"Arhavi"},{districtName:"Borçka"},{districtName:"Hopa"},{districtName:"Kemalpaşa"},{districtName:"Murgul"},{districtName:""},{districtName:"Şavşat"},{districtName:"Yusufeli"}]},
    {cityName:"Sinop",isCityActive:false,district:[{districtName:"Sinop (il merkezi)"},{districtName:"Ayancık"},{districtName:"Boyabat"},{districtName:"Dikmen"},{districtName:"Durağan"},{districtName:"Erfelek"},{districtName:"Gerze"},{districtName:""},{districtName:"Saraydüzü"},{districtName:"Türkeli"}]},
    {cityName:"Tokat",isCityActive:false,district:[{districtName:"Tokat (il merkezi)"},{districtName:"Almus"},{districtName:"Artova"},{districtName:"Başçiftlik"},{districtName:"Erbaa"},{districtName:"Niksar"},{districtName:"Pazar"},{districtName:"Reşadiye"},{districtName:"Sulusaray"},{districtName:"Turhal"},{districtName:"Yeşilyurt"},{districtName:"Zile"}]},
    {cityName:"Çorum",isCityActive:false,district:[{districtName:"Çorum (il merkezi)"},{districtName:"Alaca"},{districtName:"Bayat"},{districtName:"Boğazkale"},{districtName:"Dodurga"},{districtName:"İskilip"},{districtName:"Kargı"},{districtName:"Laçin"},{districtName:"Mecitözü"},{districtName:"Oğuzlar"},{districtName:"Ortaköy"},{districtName:"Osmancık"},{districtName:"Sungurlu"},{districtName:"Uğurludağ"}]},
    {cityName:"Amasya",isCityActive:false,district:[{districtName:"Amasya (il merkezi)"},{districtName:"Göynücek"},{districtName:"Gümüşhacıköy"},{districtName:"Hamamözü"},{districtName:"Merzifon"},{districtName:"Suluova"},{districtName:"Taşova"}]},
    {cityName:"Samsun",isCityActive:false,district:[{districtName:"Atakum"},{districtName:"Canik"},{districtName:"İlkadım"},{districtName:"Tekkeköy"},{districtName:"19 Mayıs"},{districtName:"Alaçam"},{districtName:"Asarcık"},{districtName:"Ayvacık"},{districtName:"Bafra"},{districtName:"Çarşamba"},{districtName:"Havza"},{districtName:"Kavak"},{districtName:"Ladik"},{districtName:"Salıpazarı"},{districtName:"Terme"},{districtName:"Vezirköprü"},{districtName:"Yakakent"}]},
    {cityName:"Zonguldak",isCityActive:false,district:[{districtName:"Zonguldak (il merkezi)"},{districtName:"Alaplı"},{districtName:"Çaycuma"},{districtName:"Devrek"},{districtName:"Gökçebey"},{districtName:"Karadeniz Ereğli"},{districtName:"Kilimli"},{districtName:"Kozlu"}]},
    {cityName:"Bolu",isCityActive:false,district:[{districtName:"Bolu (il merkezi)"},{districtName:"Dörtdivan"},{districtName:"Gerede"},{districtName:"Göynük"},{districtName:"Kıbrıscık"},{districtName:"Mengen"},{districtName:"Mudurnu"},{districtName:"Seben"},{districtName:"Yeniçağa"}]},
    {cityName:"Düzce",isCityActive:false,district:[{districtName:"Düzce (il merkezi)"},{districtName:"Akçakoca"},{districtName:"Cumayeri"},{districtName:"Çilimli"},{districtName:"Gölyaka"},{districtName:"Gümüşova"},{districtName:"Kaynaşlı"},{districtName:"Yığılca"}]},
    {cityName:"Karabük",isCityActive:false,district:[{districtName:"Karabük (İl merkezi)"},{districtName:"Eflani"},{districtName:"Eskipazar"},{districtName:"Ovacık"},{districtName:"Safranbolu"},{districtName:"Yenice"}]},
    {cityName:"Bartın",isCityActive:false,district:[{districtName:"Bartın (il merkezi)"},{districtName:"Amasra"},{districtName:"Kurucaşile"},{districtName:"Ulus"}]},
    {cityName:"Kastamonu",isCityActive:false,district:[{districtName:"Merkez"},{districtName:"Abana"},{districtName:"Ağlı"},{districtName:"Araç"},{districtName:"Azdavay"},{districtName:"Bozkurt"},{districtName:"Cide"},{districtName:"Çatalzeytin"},{districtName:"Daday"},{districtName:"Devrekâni"},{districtName:"Doğanyurt"},{districtName:"Hanönü"},{districtName:"İhsangazi"},{districtName:"İnebolu"},{districtName:"Küre"},{districtName:"Pınarbaşı"},{districtName:"Seydiler"},{districtName:"Şenpazar"},{districtName:"Taşköprü"},{districtName:"Tosya"}]},
    {cityName:"Bayburt",isCityActive:false,district:[{districtName:"Bayburt (il merkezi)"},{districtName:"Aydıntepe"},{districtName:"Demirözü"}]},
    {cityName:"Giresun",isCityActive:false,district:[{districtName:"Piraziz"},{districtName:"Giresun (il merkezi)"},{districtName:"Keşap"},{districtName:"Espiye"},{districtName:"Tirebolu"},{districtName:"Görele"},{districtName:"Eynesil"}]},
    {cityName:"Gümüşhane",isCityActive:false,district:[{districtName:"Gümüşhane (il merkezi)"},{districtName:"Kelkit"},{districtName:"Köse"},{districtName:"Kürtün"},{districtName:"Şiran"},{districtName:"Torul"}]},
    {cityName:"Ordu",isCityActive:false,district:[{districtName:"Altınordu"},{districtName:"Akkuş"},{districtName:"Aybastı"},{districtName:"Çamaş"},{districtName:"Çatalpınar"},{districtName:"Çaybaşı"},{districtName:"Fatsa"},{districtName:"Gölköy"},{districtName:"Gülyalı"},{districtName:"Gürgentepe"},{districtName:"İkizce"},{districtName:"Kabadüz"},{districtName:"Kabataş"},{districtName:"Korgan"},{districtName:"Kumru"},{districtName:"Mesudiye"},{districtName:"Perşembe"},{districtName:"Ulubey"},{districtName:"Ünye"}]}] }, 
  { name: "Doğu Anadolu",isActive:false,
  cities:[
    {cityName:"Ağrı",isCityActive:false,district:[{districtName:"Ağrı (il merkezi)"},{districtName:"Diyadin"},{districtName:"Doğubayazıt"},{districtName:"Eleşkirt"},{districtName:"Hamur"},{districtName:"Patnos"},{districtName:"Taşlıçay"},{districtName:"Tutak"}]},
    {cityName:"Ardahan",isCityActive:false,district:[{districtName:"Ardahan (il merkezi"},{districtName:"Çıldır"},{districtName:"Damal"},{districtName:"Göle"},{districtName:"Hanak"},{districtName:"Posof"}]},
    {cityName:"Bingöl",isCityActive:false,district:[{districtName:"Bingöl (il merkezi)"},{districtName:"Adaklı"},{districtName:"Genç"},{districtName:"Karlıova"},{districtName:"Kiğı"},{districtName:"Solhan"},{districtName:"Yayladere"},{districtName:"Yedisu"}]},
    {cityName:"Bitlis",isCityActive:false,district:[{districtName:"Bitlis (il merkezi)"},{districtName:"Adilcevaz"},{districtName:"Ahlat"},{districtName:"Güroymak"},{districtName:"Hizan"},{districtName:"Mutki"},{districtName:"Tatvan"}]},
    {cityName:"Elazığ",isCityActive:false,district:[{districtName:"Elazığ (il merkezi)"},{districtName:"Ağın"},{districtName:"Alacakaya"},{districtName:"Arıcak"},{districtName:"Baskil"},{districtName:"Karakoçan"},{districtName:"Keban"},{districtName:""},{districtName:"Kovancılar"},{districtName:"Maden"},{districtName:"Palu"},{districtName:"Sivrice"}]},
    {cityName:"Erzincan",isCityActive:false,district:[{districtName:"Erzincan (il merkezi)"},{districtName:"Çayırlı"},{districtName:"İliç"},{districtName:"Kemah"},{districtName:"Kemaliye"},{districtName:"Otlukbeli"},{districtName:"Refahiye"},{districtName:"Tercan"},{districtName:"Üzümlü"}]},
    {cityName:"Erzurum",isCityActive:false,district:[{districtName:"Aziziye"},{districtName:"Palandöken"},{districtName:"Yakutiye"},{districtName:"Aşkale"},{districtName:"Çat"},{districtName:"Hınıs"},{districtName:"Horasan"},{districtName:"İspir"},{districtName:"Karaçoban"},{districtName:"Karayazı"},{districtName:"Köprüköy"},{districtName:"Narman"},{districtName:"Oltu"},{districtName:"Olur"},{districtName:"Pasinler"},{districtName:"Pazaryolu"},{districtName:"Şenkaya"},{districtName:"Tekman"},{districtName:"Tortum"},{districtName:"Uzundere"}]},
    {cityName:"Hakkari",isCityActive:false,district:[{districtName:"Hakkâri (il merkezi)"},{districtName:"Çukurca"},{districtName:"Şemdinli"},{districtName:"Yüksekova"},{districtName:"Derecik"}]},
    {cityName:"Iğdır",isCityActive:false,district:[{districtName:"Iğdır (il merkezi)"},{districtName:"Aralık"},{districtName:"Karakoyunlu"},{districtName:"Tuzluca"}]},{
      cityName:"Kars",isCityActive:false,district:[{districtName:"Kars (il merkezi)"},{districtName:"Akyaka"},{districtName:"Arpaçay"},{districtName:"Digor"},{districtName:"Kağızman"},{districtName:"Sarıkamış"},{districtName:"Selim"},{districtName:"Susuz"}]},
      {cityName:"Malatya",isCityActive:false,district:[{districtName:"Battalgazi"},{districtName:"Yeşilyurt"},{districtName:"Akçadağ"},{districtName:"Arapgir"},{districtName:"Arguvan"},{districtName:"Darende"},{districtName:"Doğanşehir"},{districtName:"Doğanyol"},{districtName:"Hekimhan"},{districtName:"Kale"},{districtName:"Kuluncak"},{districtName:"Pütürge"},{districtName:"Yazıhan"}]},
      {cityName:"Muş",isCityActive:false,district:[{districtName:"Muş (İl merkezi)"},{districtName:"Bulanık"},{districtName:"Hasköy"},{districtName:"Korkut"},{districtName:"Malazgirt"},{districtName:"Varto"}]},
      {cityName:"Tunceli",isCityActive:false,district:[{districtName:"Tunceli (il merkezi)"},{districtName:"Çemişgezek"},{districtName:"Mazgirt"},{districtName:"Nazımiye"},{districtName:"Ovacık"},{districtName:"Pertek"},{districtName:"Pülümür"}]},
      {cityName:"Van",isCityActive:false,district:[{districtName:"Edremit"},{districtName:"İpekyolu"},{districtName:"Tuşba"},{districtName:"Bahçesaray"},{districtName:"Başkale"},{districtName:"Çaldıran"},{districtName:"Çatak"},{districtName:"Erciş"},{districtName:"Gevaş"},{districtName:"Gürpınar"},{districtName:"Muradiye"},{districtName:"Özalp"},{districtName:"Saray"}]},
      {cityName:"Şırnak",isCityActive:false,district:[{districtName:"Şırnak (il merkezi)"},{districtName:"Beytüşşebap"},{districtName:"Cizre"},{districtName:"Güçlükonak"},{districtName:"İdil"},{districtName:"Silopi"},{districtName:"Uludere"}]}]}])

     
  
  
  const { user, registrationError, loading, registerSuccess, Status, UserCompanyId } = useSelector(state => ({
    user: state.Account.user,
    UserCompanyId: state.Account.user?.data.companyId,
    registerSuccess: state.Account.register2Success,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
    Status: state.Status?.data
  }))
  const {
    match: { params },
  } = props
  // handleValidSubmit

  const handleValidSubmit = values => {
    // let tempArr = []
    // grades.map((item, index) => {
    //   if (item.isActive) {
    //     item.grade = tempArr.length;
    //     tempArr.push(item);
    //   }
    // })
    // const registerModel = {
    //   _id: params.id,
    //   brand: values.brand,
    //   photo: values["photo"],
    //   grades: JSON.stringify(tempArr),
    //   about: values.about
    // }
    // dispatch(register2User(registerModel, selectedFiles)) 
     let tempArr = []
    grades.map((item, index) => {
      if (item.isActive) {
        item.grade = tempArr.length;
        tempArr.push(item);
      }
    })
    const registerModel = {
      _id: params.id,
      branches:JSON.stringify(Regions.filter(x=>x.isActive==true)) ,
      photo: values["photo"],
      grades: JSON.stringify(tempArr),
      about: values.about,
      district:district
    }
    dispatch(register2User(registerModel, selectedFiles)) 
    window.open(`${process.env.REACT_APP_DOMAIN}/register3/` + params.id, "_self")
  }
  console.log("district",district)
  useEffect(() => {
    dispatch(onGetStatus())
    dispatch(apiError(""))
  }, [])
  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }
  const statusToggle = () => {
    setStatusModal(!statusModal)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const handleAdd = (grade) => {
    setGrades([...grades, { grade: grade + 1, id: 0 }])
  }
  const handleChangeInput = (i, e) => {

    const values = [...grades]
    values[i].id = e.target.value
    values[0].id = "Firmalar"
    values[0]._id = Status?.filter(x => x.name == "Firmalar")[0]?._id
    setGrades(values)
  }
  const handleChangeGrades = (i, e) => {

    const values = [...grades]
    values[i].isActive = e
    setGrades(values)
  }
  const handleSubtract = (i) => {
    const values = [...grades]
    values.splice(i, 1)
    setGrades([...values])
  }
  console.log("User Compan",params)
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Kayıt Ol |Bu QR'da İş Var</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
          <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4" style={{height:"100%"}}>
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="py-5 px-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-1">
                        
                     
                         <img  id="preview-date"
                         src={logodark}
                         alt=""   
                         height="490"
                         style={{position:"relative", right:"-80px" ,bottom:"210px"}}
                       />
                      </h4>
                      <div >
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>

            <Col xl={3}>
              <div className="p-4" style={{minHeight:"100vh"}}> 
                <div>
                  <div>

                    <div>
                      {!registerSuccess ? (

                        <div >
                          <h5 className="text-primary">Ücresiz Kayıt</h5>

                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(v)
                            }}
                            autoComplete="off"
                          >
                            <Row form>

                              {user && user ? (
                                <Alert color="success">
                                  Kayıt Olma İşlemi Başarılı 

                                </Alert>

                              ) : null}

                              {registrationError && registrationError ? (
                                <Alert color="danger">{registrationError}</Alert>
                              ) : null}
                             
                              
                              
                              <div className="mb-2">
                                <AvField
                                  name="brand"
                                  label={props.t("Markanız")}
                                  className="form-control"
                                  placeholder={props.t("Markanız")}
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-2">
                                <AvField
                                  name="about"
                                  label={props.t("Hakkında")}
                                  type="textarea"
                                  required
                                  placeholder={props.t("Hakkında")}
                                />
                              </div>

                              <p style={{ fontSize: "13px", fontWeight: "500" }} className="mt-3 mb-2">Markanızın Logosunu Ekleyin</p>
                              <div
                                className="mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <div>

                                  <img
                                    src={imgEmpty}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      objectFit: "contain",
                                    }}
                                  />

                                </div>

                                <div
                                  style={{
                                    width: "75%",
                                    border: "1px solid #ced4da",
                                    borderRadius: "10px",
                                    height: "150px",
                                  }}
                                >
                                  <Dropzone
                                    multiple={false}
                                    accept="image/jpeg, image/png"
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone-min">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div
                                            className="dz-message needsclick"
                                            style={{ padding: "0px" }}
                                          >
                                            <div>
                                              <i className="display-5 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4 style={{fontSize:"14px"}}>{props.t("Drop Image")}</h4>
                                          </div>
                                        </div>
                                      </div> 
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews"
                                    id="file-previews"
                                    style={{
                                      position: "absolute",
                                      zIndex: 99,
                                      left: 0,
                                      top: -5,
                                    }}
                                  >
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <div className="align-items-center">
                                              <div className="col-12">
                                                <img
                                                  data-dz-thumbnail=""
                                                  style={{
                                                    width: "125px",
                                                    height: "125px",
                                                    objectFit: "contain",
                                                  }}
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                              <p style={{ fontSize: "13px", fontWeight: "500" }} className="mt-3 mb-2">Markanızın İçin Organizasyon Yapınızı Oluşturun</p>

                           

                              <AvGroup check>
                                <AvInput
                                  type="checkbox"
                                  label="regionCheck"
                                  value="false"
                                  name="regionCheck"
                                  onChange={e => { 
                                    setRegion(!region) 
                                    
                                  }}
                                />
                                <Label check for="regionCheck">
                                  {" "}
                                  <p>Bölge</p><small style={{ position: "relative", bottom: "15px" }}>1'den fazla bölgede 10'dan fazla şubeniz varsa seçin.</small>
                                </Label>
                              </AvGroup>
                              {region == true ? (
                                <Row style={{paddingLeft:"10px"}} className="mb-4">
                               { Regions?.map((item, i) => (
                                  
                                    <span className="regions mt-1 mb-2 me-2" key={i+"reg"} style={{backgroundColor:item.isActive==true?"rgb(251 134 51)":"white",color:item.isActive==true?"white":"black",width:"auto"}} onClick={()=>{
                                     let values=[...Regions];
                                    
                                     values[i].isActive=!values[i].isActive
                                     setRegions(values)
                                    }}>
                                    <span>{item.name}</span>
                                    </span>
                                 ))}
                                   </Row>

                              ) :  null}
                             

                              <AvGroup check>
                                <AvInput
                                  type="checkbox"
                                  label="cityCheck"
                                  value="false"
                                  name="cityCheck"
                                  onChange={e => { setCity(!city) }}
                                />
                                <Label check for="cityCheck">
                                  {" "}
                                  <p>İl</p><small style={{ position: "relative", bottom: "15px" }}>1'den fazla şehirde 10'dan fazla şubeniz varsa seçin</small>
                                </Label>
                              </AvGroup>
                              {city == true && region==true ? (
                                <Row style={{paddingLeft:"10px"}} className="mb-4">
                               { Regions?.map((item, i) => (
                                  item.isActive?(
                                    <>
                                    <b className="pt-2" style={{fontWeight:"600"}}>{item.name}</b>
                                     {item.cities.map((ixt,it)=>(
                                     <span className="regions mt-1 mb-2 me-2" key={i+"city"+it} style={{backgroundColor:ixt.isCityActive==true?"rgb(251 134 51)":"white",color:ixt.isCityActive==true?"white":"black",width:"auto"}} onClick={()=>{
                                         let values=[...Regions]; 
                                         values[i].cities[it].isCityActive=!values[i].cities[it].isCityActive
                                         setRegions(values)
                                       }}> 
                                       <span>{ixt.cityName}</span>
                                       </span>
                                    ))}
                                    </>
                                   
                                   
                                  ):null
                                   
                                 ))}
                                   </Row>

                              ) : city == true && region==false ? 
                              <Row>
                              { Regions?.map((item, i) => (
                                
                                <>
                                  <b className="pt-2" style={{fontWeight:"600"}}>{item.name}</b>
                                   {item.cities.map((ixt,it)=>(
                                   
                                   <span className="regions mt-1 mb-2 me-2 text-center" key={i+"city"+it} style={{backgroundColor:ixt.isCityActive==true?"rgb(251 134 51)":"white",color:ixt.isCityActive==true?"white":"black",width:"auto"}} onClick={()=>{
                                       let values=[...Regions];
                                       values[i].cities[it].isCityActive=!values[i].cities[it].isCityActive
                                       setRegions(values)
                                     }}>
                                     <span>{ixt.cityName}</span>
                                     </span>
                                  ))}
                                </>
                                 
                                  ))} </Row>
                              :null}
                              <AvGroup check>
                                <AvInput
                                  type="checkbox"
                                  label="districtCheck"
                                  value="false"
                                  name="districtCheck"
                                  onChange={e => { setDistrict(!district) }
                                  }
                                />
                                <Label check for="districtCheck">
                                  {" "}
                                  <p>İlçe</p><small style={{ position: "relative", bottom: "15px" }}>1 şehirde 5'den fazla şubeniz varsa seçiniz</small>
                                </Label>
                              </AvGroup>






                              <Row className="mt-3">
                                <Col md="5">
                                  <div className="mt-1">
                                    <button
                                      className="btn btn-primary btn-block "
                                      type="submit"
                                    >
                                      Kaydet
                                    </button>
                                  </div>
                                </Col>
                                <Col md="7">

                                </Col>
                              </Row>
                            </Row>
                          </AvForm>


                        </div>



                      ) :
                        // <div className="alert alert-success h3 text-center">
                        //   {props.t("Kayıt Olma İşlemi Başarılı")}
                        //   <br />
                        //   <i className="fa fa-check"></i>
                        //   <hr />
                        //   <Link to="/Login" className="font-size-13">{props.t("Continue")}</Link>
                        // </div>
                        //window.open("http://test.buqrdaisvar.com/register3/" + UserCompanyId, "_self")


                        window.open(`${process.env.REACT_APP_DOMAIN}/register3/` + UserCompanyId, "_self")
                        
                      }


                    </div>
                    <Modal isOpen={statusModal} toggle={statusToggle} size={"lg"}>
                      <ModalHeader toggle={statusToggle} tag="h4">
                        {props.t("Organizasyon Yapısı Ekle")}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm

                        >
                          <Row form>
                            <Col xs={12}>
                              <Row form>
                                <Col xs={12} className="pb-4">

                                  {grades?.map((item, i) => (
                                    <div key={i}>
                                      <Row className="mt-2">
                                        <Col md="4" className="text-end">
                                          <Label
                                            md="8"
                                            className="col-form-label"
                                          >
                                            {i == 0 ? props.t("1.Birim") : item.grade + " " + props.t("Birim")}

                                          </Label>
                                        </Col>
                                        <Col md="4">
                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="sector"
                                            disabled={i == 0 ? true : false}
                                            value={i == 0 ? (
                                              // Status?.filter(x => x.name == "Firmalar")[0]?._id
                                              "Firmalar"
                                            ) : item.id}

                                            onChange={e => handleChangeInput(i, e)}
                                          >
                                            <option>{props.t("Birim Seç")}</option>
                                            {Status?.map((item, index) => (
                                              <option key={index} value={item.id} >{item.name}</option>
                                            ))}
                                          </AvField>

                                        </Col>
                                        <Col md="4">
                                          {i == grades.length - 1 ?
                                            <div>
                                              <Button onClick={() => handleAdd(item.grade)} className="btn btn-success me-2">
                                                <i className="fas fa-plus"></i>
                                              </Button>
                                              {grades.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                                                <i className="fas fa-minus"></i>
                                              </Button> : null}
                                            </div> : null
                                          }
                                        </Col>


                                      </Row>
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <Button
                                  type="button"
                                  className="btn-success me-2"
                                  onClick={() => { statusToggle() }}
                                >
                                  {props.t("Save")}
                                </Button>
                                <Button
                                  className="me-2"
                                  onClick={() => {
                                    statusToggle()
                                  }}
                                >
                                  {props.t("Cancel")}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>


                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withTranslation()(Register))
