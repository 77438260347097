import {
    GET_SECTORS,
    GET_SECTORS_SUCCESS,
    GET_SECTORS_FAIL,
    GET_SECTOR_DETAIL,
    GET_SECTOR_DETAIL_SUCCESS,
    GET_SECTOR_DETAIL_FAIL,
    ADD_SECTOR,
    ADD_SECTOR_SUCCESS,
    ADD_SECTOR_FAIL,
    UPDATE_SECTOR,
    UPDATE_SECTOR_SUCCESS,
    UPDATE_SECTOR_FAIL,
    DELETE_SECTOR,
    DELETE_SECTOR_SUCCESS,
    DELETE_SECTOR_FAIL,
    RESET_SECTOR,
   
} from "./actionTypes";


export const resetSector = () => ({
    type: RESET_SECTOR,
});

export const getSectors = () => ({
    type: GET_SECTORS,
});

export const getSectorsSuccess = (sector) => ({
    type: GET_SECTORS_SUCCESS,
    payload: sector,
});


export const getSectorsFail = (error) => ({
    type: GET_SECTORS_FAIL,
    payload: error,
});

export const deleteSector = (id) => ({
    type:  DELETE_SECTOR,
    payload: id
});

export const deleteSectorSuccess = (data) => ({
    type: DELETE_SECTOR_SUCCESS,
    payload: data
});

export const deleteSectorFail = (error) => ({
    type: DELETE_SECTOR_FAIL,
    payload: error,
});


export const addSector = (sector) => ({
    type: ADD_SECTOR,
    payload: sector
});

export const addSectorSuccess = (data) => ({
    type: ADD_SECTOR_SUCCESS,
    payload: data
});

export const addSectorFail = (error) => ({
    type: ADD_SECTOR_FAIL,
    payload: error,
});


export const updateSector = (sector) => ({
    type: UPDATE_SECTOR,
    payload: sector
});

export const updateSectorSuccess = (data) => ({
    type: UPDATE_SECTOR_SUCCESS,
    payload: data
});

export const updateSectorFail = (error) => ({
    type: UPDATE_SECTOR_FAIL,
    payload: error,
});