/** Get DUTY_FORM */
export const GET_DUTY_FORMS = "GET_DUTY_FORMS";
export const GET_DUTY_FORMS_SUCCESS = "GET_DUTY_FORMS_SUCCESS";
export const GET_DUTY_FORMS_FAIL = "GET_DUTY_FORMS_FAIL";

export const GET_DUTY_FORM_DETAIL = "GET_DUTY_FORM_DETAIL";
export const GET_DUTY_FORM_DETAIL_SUCCESS = "GET_DUTY_FORM_DETAIL_SUCCESS";
export const GET_DUTY_FORM_DETAIL_FAIL = "GET_DUTY_FORM_DETAIL_FAIL";


export const ADD_DUTY_FORM = "ADD_DUTY_FORM";
export const ADD_DUTY_FORM_SUCCESS = "ADD_DUTY_FORM_SUCCESS";
export const ADD_DUTY_FORM_FAIL = "ADD_DUTY_FORM_FAIL";

export const UPDATE_DUTY_FORM = "UPDATE_DUTY_FORM";
export const UPDATE_DUTY_FORM_SUCCESS = "UPDATE_DUTY_FORM_SUCCESS";
export const UPDATE_DUTY_FORM_FAIL = "UPDATE_DUTY_FORM_FAIL";

export const DELETE_DUTY_FORM = "DELETE_DUTY_FORM";
export const DELETE_DUTY_FORM_SUCCESS = "DELETE_DUTY_FORM_SUCCESS";
export const DELETE_DUTY_FORM_FAIL = "DELETE_DUTY_FORM_FAIL"; 



export const RESET_DUTY_FORM= "RESET_DUTY_FORM";