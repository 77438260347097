//REGISTER
export const POST_REGISTER = "/api/users"
export const POST_FAKE_REGISTER = "/api/users"
export const POST_REGISTER2 = "/api/users/register2/"
export const POST_REGISTER3 = "/api/users/register3/"
//LOGIN
export const POST_LOGIN = "/auth/token"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
 

//COMPANIES
export const GET_COMPANIES = "/api/companies"


export const GET_COMPANY_DETAIL = "/api/companies"
export const ADD_NEW_COMPANY = "/api/companies"
export const UPDATE_COMPANY = "/api/companies"
export const DELETE_COMPANY = "/api/companies"

//PLANS
export const GET_PLANS = "/api/plans" 
export const GET_PLAN_OUTSIDE = "/api/plansOutside"
export const GET_PLAN_DETAIL = "/api/plans"
export const ADD_NEW_PLAN = "/api/plans"
export const UPDATE_PLAN = "/api/plans"
export const GET_PLANS_MY_PLANS = "/api/plans/getMyPlans"

//ADVERTISEMENT
export const GET_ADVERTISEMENT = "/api/advertisement" 

//AVM_INFO
export const GET_AVM_INFO = "/api/avmInfo"

//PARTNERSHIP
export const GET_PARTNERSHIP= "/api/partnership"

//SECTOR
export const GET_SECTOR ="/api/sector"

//SECTOR FORM
export const GET_SECTOR_FORM ="/api/sectorForm"

//DUTY FORM
export const GET_DUTY_FORM ="/api/dutyForm"

//SOFT_SKILL
export const GET_SOFT_SKILL="api/softSkills"

//Status
export const GET_STATUS = "/api/status"

//Survey
export const GET_SURVEY = "/api/survey"
 
//SurveyForm 
export const GET_SURVEY_FORM = "/api/surveyForm"

//OrientationForm
export const GET_ORIENTATION_FORM = "/api/orientationForm"

//SurveySent
export const GET_SURVEY_SENT = "/api/surveySent"

//Orientation
export const ORIENTATION = "/api/orientation"

//OrientationSent
export const GET_ORIENTATION_SENT = "/api/orientationSent"

//duty
export const GET_DUTY = "/api/duty"

//formDuties
export const DUTIES_FORM = "/api/dutiesForm"
 
//foreignLanguage
export const GET_FOREIGN_LANGUAGE = "/api/foreignLanguage"

//devForeignLanguage
export const DEV_FOREIGN_LANGUAGE = "api/devForeignLanguage" 

//devProgram
export const DEV_PROGRAM = "api/devProgram"

//devRequestReason
export const DEV_REQUEST_REASON = "api/devRequestReason"

//requestReason
export const REQUEST_REASON = "api/requestReason"

//devSectorForm
export const DEV_SECTOR_FORM = "api/devSectorForm"

//devWorkType
export const DEV_WORK_TYPE = "api/devWorkType"

//devDutyForm
export const DEV_DUTY_FORM = "api/devDutyForm"

//program
export const GET_PROGRAM = "api/program"

//workType
export const GET_WORK_TYPE = "api/workType"

//label
export const GET_LABEL = "/api/label"

//backUrl 
export const GET_BACK_URL = "/api/backUrl"

//devDuty
export const GET_DEV_DUTY = "/api/devDuty"
 
//orientation Period
export const GET_ORIENTATION_PERIOD = "/api/orientationPeriod" 

//APPFORM 
export const GET_APP_FORMS = "/api/ik"
export const GET_APP_FORM_DETAIL = "/api/ik"
export const ADD_NEW_APP_FORM = "/api/ik"
export const UPDATE_APP_FORM = "/api/ik"
export const GET_APP_MY_FORMS = "/api/ik/getMyForms"
export const GET_APP_MY_FORMS_STATUS_FALSE = "/api/ik/getMyFalseStatusForms"
export const GET_APP_MY_FORMS_STATUS_TRUE = "/api/ik/getMyTrueStatusForms"
export const STATUS_UPDATE_APP_FORMS = "/api/ik/statusUpdate"
export const REJECT_APP_FORMS = "/api/ik/reject"
export const FIRED_APP_FORMS = "/api/ik/fired"
export const ACCEPT_APP_FORMS = "/api/ik/formAccept"
export const DESC_UPDATE_APP_FORMS = "/api/ik/descUpdate"

//APP_FORM_OUTSIDE
export const GET_APP_FORM_OUTSIDE = "/api/appFormOutside" 
//REQUESTFORMPLAN

export const GET_REQUEST_FORM_PLAN = "/api/requestFormPlan"
 

//BRANCHS
export const GET_BRANCHS = "/api/branchs" 
export const GET_BRANCH_DETAIL = "/api/branchs"
export const ADD_NEW_BRANCH = "/api/branchs"
export const UPDATE_BRANCH = "/api/branchs"
export const DELETE_BRANCH = "/api/branchs"
export const GET_BRANCH_CHILDS = "/api/branchs/childs/"

//QRCODES
 
export const API_BASE_QRCODE = "/api/qrcode" 
export const GET_QR_ANONYMUS = "/api/qr"
 
//ANALYTICS
export const API_BASE_ANALYTICS = "/api/analytic"

//LOCATIONS - CITIES - DISTRICT - NEIGHTBORHOODS
export const API_BASE_LOCATIONS = "/api/locations"

//REPORTS
export const GET_REPORT = "/api/reports"

//LOGIN_LOG
export const LOGIN_LOG = "/api/loginLog" 

//usercompany
export const UPDATE_USERCOMPANY = "/api/users/addCompany"

export const GET_ROLES = "/api/roles"
export const GET_USER = "/api/users"
export const GET_USER_DETAIL = "/api/users"
export const ADD_NEW_USERS = "/api/users"
export const UPDATE_USERS = "/api/users"
export const DELETE_USERS = "/api/users"
export const GET_COMPANY_BY_EMAIL = "/api/users"
export const FORGOT_SEND_MAIL = "/auth/forgotPassword"
export const SEND_MAIL = "/auth/mailtest"
export const EMAIL_CONFIRM = "/auth/confirm"






//CONTACTS
export const GET_USERS = "/api/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/del/user"