import {
    GET_ORIENTATON_SENTS,
    GET_ORIENTATON_SENTS_SUCCESS,
    GET_ORIENTATON_SENTS_FAIL,
    GET_ORIENTATON_SENT_DETAIL,
    GET_ORIENTATON_SENT_DETAIL_SUCCESS,
    GET_ORIENTATON_SENT_DETAIL_FAIL,
    ADD_ORIENTATON_SENT,
    ADD_ORIENTATON_SENT_SUCCESS,
    ADD_ORIENTATON_SENT_FAIL, 
    UPDATE_ORIENTATON_SENT,
    UPDATE_ORIENTATON_SENT_SUCCESS,
    UPDATE_ORIENTATON_SENT_FAIL,
    DELETE_ORIENTATON_SENT,
    DELETE_ORIENTATON_SENT_SUCCESS,
    DELETE_ORIENTATON_SENT_FAIL,
    RESET_ORIENTATON_SENT,
    SEND_ORIENTATON_MAIL_SUCCESS,
    SEND_ORIENTATON_MAIL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    success:null,
    mail:[]
};

const OrientationSents = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SEND_ORIENTATON_MAIL_SUCCESS:
            return {
               
                ...state,
                mail:[action.payload.data], 
                success:true  
            }  
        case GET_ORIENTATON_SENTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ORIENTATON_SENT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(orientationSent => orientationSent._id.toString() !== action.payload.data.toString())
            }
        case ADD_ORIENTATON_SENT_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
               
            }
        case UPDATE_ORIENTATON_SENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(orientationSent =>
                    orientationSent._id.toString() === action.payload.data._id.toString() ?
                        { orientationSent, ...action.payload.data } :
                        orientationSent
                )
            }

        //FAILS
        case GET_ORIENTATON_SENTS_FAIL:
        case DELETE_ORIENTATON_SENT_FAIL:
        case ADD_ORIENTATON_SENT_FAIL:
        case UPDATE_ORIENTATON_SENT_FAIL:
        case SEND_ORIENTATON_MAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ORIENTATON_SENT: return {
            ...state,
            error: null,
            success:null
        }
        default:
            return state;
    }
}


export default OrientationSents;