import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Input,
  FormGroup,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { addProcessListPayment as OnAddProcessListPayment } from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from "qrcode.react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as htmlToImage from "html-to-image"

const qrModal = (props, cb) => {
  const defaultQRSize = 256
  const defaultDownloadQRSize = 1024
  const defaultQRPosition = {
    l: 232, //left
    t: 570, //top
    s: 221, //size
    p: 1040, //pagesize
  } 
  const [qrPosition, setQRPosition] = useState(defaultQRPosition)
  const [qrDownloadSize, setQRDownloadSize] = useState(256)

  useEffect(() => {
    if (qrDownloadSize == defaultDownloadQRSize) {
      downloadQR()
    }
  }, [qrDownloadSize])
 

  function random(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  //const [modal, setModal] = useState(false)
  const downloadQR = () => {
    const canvas = document.getElementById("myQrCode")
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
      if (pngUrl) {
        let downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = (props.title || random(8)) + ".png"
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        toast.success(props.t("Downloaded"))
        setQRDownloadSize(defaultQRSize)
      }
    }
  }

  const copyToClipboard = txt => {
    var textField = document.createElement("textarea")
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a")
    elem.href = blob
    elem.download = fileName
    elem.style = "display:none;"
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === "function") {
      elem.click()
    } else {
      elem.target = "_blank"
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      )
    }
    URL.revokeObjectURL(elem.href)
    elem.remove()
  }
  const onCapture = id => {
    toast.success(props.t("Downloading"))
    htmlToImage
      .toPng(document.getElementById(id))
      .then(function (dataUrl) {
        saveAs(dataUrl, (props.title || random(8)) + ".png") 
      });
  }

  return (
    <Modal isOpen={props.modal} toggle={props.toggle} size="xl">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Qr Code")}
      </ModalHeader>
      <ModalBody>
        <div style={{ textAlign: "center" }}>
          <ToastContainer autoClose={3000} />
          <div className="mb-3">
            <h5>
              {props.infoQr}{" "}
              <a
                className="me-1"
                style={{ fontSize: 24 }}
                onClick={() => {
                  copyToClipboard(props.infoQr)
                }}
              >
                <i className="bx bx-copy text-success"></i>
              </a>{" "}
            </h5>
          </div>
          <div id="qr-preview" style={{ position: "relative" }}>
            <QRCode
              id="myQrCode"
              level={"M"}
              size={!isEmpty(props.QRphoto) ? qrPosition.s : 512}
              fgColor={"#2A3042"}
              value={props.infoQr}
              
              style={
                !isEmpty(props.QRphoto)
                  ? {
                      position: "absolute",
                      zIndex: 9,
                      left: qrPosition.l,
                      top: qrPosition.t, 
                    }
                  : {}
              }
            />
            {!isEmpty(props.QRphoto) ? (
              <img
                src={`${process.env.REACT_APP_BASE_API}static/uploads/${props.QRphoto}`}
                style={{
                  width: qrPosition.p,
                  height: qrPosition.p,
                  objectFit: "contain",
                  marginBottom:15
                }}
              />
            ) : null}
          </div>
        </div>
        {!isEmpty(props.QRphoto) ? (
          <a
            onClick={() => {  
              onCapture("qr-preview")
            }}
            className="btn btn-success float-end ms-3"
          >
            {props.t("Download")}
          </a>
        ) : null}

        <a
          onClick={() => {
            setQRDownloadSize(defaultDownloadQRSize)
          }}
          className="btn btn-success float-end"
        >
          {props.t("DownloadOnlyQR")}
        </a>
      </ModalBody>
    </Modal>
  )
}

qrModal.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any, 
  toggle: PropTypes.func,
  infoQr: PropTypes.string,
  QRphoto: PropTypes.string,
  title: PropTypes.string,
}

export default withRouter(withTranslation()(qrModal))