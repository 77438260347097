import {
    GET_ADVERTISEMENTS,
    GET_ADVERTISEMENTS_SUCCESS,
    GET_ADVERTISEMENTS_FAIL,
    GET_ADVERTISEMENTS_FOR_QR,
    GET_ADVERTISEMENTS_FOR_QR_SUCCESS,
    GET_ADVERTISEMENTS_FOR_QR_FAIL,
    GET_ADVERTISEMENT_DETAIL,
    GET_ADVERTISEMENT_DETAIL_SUCCESS,
    GET_ADVERTISEMENT_DETAIL_FAIL,
    ADD_ADVERTISEMENT,
    ADD_ADVERTISEMENT_SUCCESS,
    ADD_ADVERTISEMENT_FAIL,
    UPDATE_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT_SUCCESS,
    UPDATE_ADVERTISEMENT_FAIL,
    UPDATE_ADVERTISEMENT_ACTIVE,
    UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS,
    UPDATE_ADVERTISEMENT_ACTIVE_FAIL,
    DELETE_ADVERTISEMENT,
    DELETE_ADVERTISEMENT_SUCCESS,
    DELETE_ADVERTISEMENT_FAIL,
    RESET_ADVERTISEMENT, 
   
} from "./actionTypes"; 


export const resetAdvertisement = () => ({
    type: RESET_ADVERTISEMENT,
});
export const getAdvertisementsForQr = (id) => ({
    type: GET_ADVERTISEMENTS_FOR_QR,
    payload:id
});

export const getAdvertisementsForQrSuccess = (advertisement) => ({
    type: GET_ADVERTISEMENTS_FOR_QR_SUCCESS,
    payload: advertisement, 
});


export const getAdvertisementsForQrFail = (error) => ({
    type: GET_ADVERTISEMENTS_FOR_QR_FAIL,
    payload: error,
});
export const getAdvertisements = () => ({
    type: GET_ADVERTISEMENTS,
   
});

export const getAdvertisementsSuccess = (advertisement) => ({
    type: GET_ADVERTISEMENTS_SUCCESS,
    payload: advertisement,
});


export const getAdvertisementsFail = (error) => ({
    type: GET_ADVERTISEMENTS_FAIL,
    payload: error,
});

export const deleteAdvertisement = (id) => ({
    type:  DELETE_ADVERTISEMENT,
    payload: id
});

export const deleteAdvertisementSuccess = (data) => ({
    type: DELETE_ADVERTISEMENT_SUCCESS,
    payload: data 
});

export const deleteAdvertisementFail = (error) => ({
    type: DELETE_ADVERTISEMENT_FAIL,
    payload: error,
});


export const addAdvertisement = (advertisement,files) => ({
    type: ADD_ADVERTISEMENT,
    payload: advertisement,
    files: files
});

export const addAdvertisementSuccess = (data) => ({
    type: ADD_ADVERTISEMENT_SUCCESS,
    payload: data
});

export const addAdvertisementFail = (error) => ({
    type: ADD_ADVERTISEMENT_FAIL,
    payload: error,
});


export const updateAdvertisement = (advertisement, file) => ({
    type: UPDATE_ADVERTISEMENT,
    payload: advertisement,
    file: file 
});

export const updateAdvertisementSuccess = (data) => ({
    type: UPDATE_ADVERTISEMENT_SUCCESS,
    payload: data
});

export const updateAdvertisementFail = (error) => ({
    type: UPDATE_ADVERTISEMENT_FAIL,
    payload: error,
});
export const updateAdvertisementActive = (advertisement) => ({
    type: UPDATE_ADVERTISEMENT_ACTIVE,
    payload: advertisement,
    
});

export const updateAdvertisementActiveSuccess = (data) => ({
    type: UPDATE_ADVERTISEMENT_ACTIVE_SUCCESS,
    payload: data
});

export const updateAdvertisementActiveFail = (error) => ({
    type: UPDATE_ADVERTISEMENT_ACTIVE_FAIL,
    payload: error, 
});