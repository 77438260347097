import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_LABELS, DELETE_LABEL, ADD_LABEL, UPDATE_LABEL,GET_LABELS_MY_LABELS} from "./actionTypes";
import {
    getLabelsSuccess, getLabelsFail,
    deleteLabelSuccess, deleteLabelFail,
    updateLabelSuccess, updateLabelFail,
    addLabelSuccess, addLabelFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getLabels, //API CONNECTION
    delLabel,
    addLabel,
    updateLabel,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchLabels() {
    try {
        const response = yield call(getLabels)
        yield put(getLabelsSuccess(response));
    } catch (error) {
        yield put(getLabelsFail(error));
    }
}

function* onDeleteLabels({payload:id}){
    try{
        const response = yield call(delLabel, id)
        yield put(deleteLabelSuccess(response));
    } catch (error) {
        yield put(deleteLabelFail(error));
    }
}

function* onAddLabel({payload:label}){
    try{
        const response = yield call(addLabel, label)
        yield put(addLabelSuccess(response));
    } catch (error) {
        yield put(addLabelFail(error));
    }
}

function* onUpdateLabel({payload:label}){
    try{
        const response = yield call(updateLabel, label)
        yield put(updateLabelSuccess(response));
    } catch (error) {
        yield put(updateLabelFail(error));
    }
}

export function* labelsSaga() {
    yield takeEvery(GET_LABELS, fetchLabels);
    yield takeEvery(DELETE_LABEL, onDeleteLabels);
    yield takeEvery(ADD_LABEL, onAddLabel);
    yield takeEvery(UPDATE_LABEL, onUpdateLabel);
   
    
}

export default labelsSaga;
