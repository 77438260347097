import {
    GET_FOREIGN_LANGUAGES,
    GET_FOREIGN_LANGUAGES_SUCCESS,
    GET_FOREIGN_LANGUAGES_FAIL,
    GET_FOREIGN_LANGUAGE_DETAIL,
    GET_FOREIGN_LANGUAGE_DETAIL_SUCCESS,
    GET_FOREIGN_LANGUAGE_DETAIL_FAIL,
    ADD_FOREIGN_LANGUAGE,
    ADD_FOREIGN_LANGUAGE_SUCCESS,
    ADD_FOREIGN_LANGUAGE_FAIL,
    UPDATE_FOREIGN_LANGUAGE,
    UPDATE_FOREIGN_LANGUAGE_SUCCESS,
    UPDATE_FOREIGN_LANGUAGE_FAIL,
    DELETE_FOREIGN_LANGUAGE,
    DELETE_FOREIGN_LANGUAGE_SUCCESS,
    DELETE_FOREIGN_LANGUAGE_FAIL,
    RESET_FOREIGN_LANGUAGE,
   
} from "./actionTypes";


export const resetForeignLanguage = () => ({
    type: RESET_FOREIGN_LANGUAGE,
});

export const getForeignLanguages = () => ({
    type: GET_FOREIGN_LANGUAGES,
});

export const getForeignLanguagesSuccess = (foreignLanguage) => ({
    type: GET_FOREIGN_LANGUAGES_SUCCESS,
    payload: foreignLanguage,
});


export const getForeignLanguagesFail = (error) => ({
    type: GET_FOREIGN_LANGUAGES_FAIL,
    payload: error,
});

export const deleteForeignLanguage = (id) => ({
    type:  DELETE_FOREIGN_LANGUAGE,
    payload: id
});

export const deleteForeignLanguageSuccess = (data) => ({
    type: DELETE_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const deleteForeignLanguageFail = (error) => ({
    type: DELETE_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});


export const addForeignLanguage = (foreignLanguage) => ({
    type: ADD_FOREIGN_LANGUAGE,
    payload: foreignLanguage
});

export const addForeignLanguageSuccess = (data) => ({
    type: ADD_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const addForeignLanguageFail = (error) => ({
    type: ADD_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});


export const updateForeignLanguage = (foreignLanguage) => ({
    type: UPDATE_FOREIGN_LANGUAGE,
    payload: foreignLanguage
});

export const updateForeignLanguageSuccess = (data) => ({
    type: UPDATE_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const updateForeignLanguageFail = (error) => ({
    type: UPDATE_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});