import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_STATUSS, DELETE_STATUS, ADD_STATUS, UPDATE_STATUS,GET_STATUSS_MY_STATUSS} from "./actionTypes";
import {
    getStatussSuccess, getStatussFail,
    deleteStatusSuccess, deleteStatusFail,
    updateStatusSuccess, updateStatusFail,
    addStatusSuccess, addStatusFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getStatuss, //API CONNECTION
    delStatus,
    addStatus,
    updateStatuss,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchStatuss() {
    try {
        const response = yield call(getStatuss)
        yield put(getStatussSuccess(response));
    } catch (error) {
        yield put(getStatussFail(error));
    }
}

function* onDeleteStatuss({payload:id}){
    try{
        const response = yield call(delStatus, id)
        yield put(deleteStatusSuccess(response));
    } catch (error) {
        yield put(deleteStatusFail(error));
    }
}

function* onAddStatus({payload:status}){
    try{
        const response = yield call(addStatus, status)
        yield put(addStatusSuccess(response));
    } catch (error) {
        yield put(addStatusFail(error));
    }
}

function* onUpdateStatus({payload:status}){
    try{
        const response = yield call(updateStatuss, status)
        yield put(updateStatusSuccess(response));
    } catch (error) {
        yield put(updateStatusFail(error));
    }
}

export function* statussSaga() {
    yield takeEvery(GET_STATUSS, fetchStatuss);
    yield takeEvery(DELETE_STATUS, onDeleteStatuss);
    yield takeEvery(ADD_STATUS, onAddStatus);
    yield takeEvery(UPDATE_STATUS, onUpdateStatus);
   
    
}

export default statussSaga;
