import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_PROGRAMS, DELETE_DEV_PROGRAM, ADD_DEV_PROGRAM, UPDATE_DEV_PROGRAM,GET_DEV_PROGRAMS_MY_DEV_PROGRAMS} from "./actionTypes";
import {
    getDevProgramsSuccess, getDevProgramsFail,
    deleteDevProgramSuccess, deleteDevProgramFail,
    updateDevProgramSuccess, updateDevProgramFail,
    addDevProgramSuccess, addDevProgramFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevPrograms, //API CONNECTION
    delDevProgram,
    addDevProgram,
    updateDevProgram,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevPrograms() {
    try {
        const response = yield call(getDevPrograms)
        yield put(getDevProgramsSuccess(response));
    } catch (error) {
        yield put(getDevProgramsFail(error));
    }
}

function* onDeleteDevPrograms({payload:id}){
    try{
        const response = yield call(delDevProgram, id)
        yield put(deleteDevProgramSuccess(response));
    } catch (error) {
        yield put(deleteDevProgramFail(error));
    }
}

function* onAddDevProgram({payload:devProgram}){
    try{
        const response = yield call(addDevProgram, devProgram)
        yield put(addDevProgramSuccess(response));
    } catch (error) {
        yield put(addDevProgramFail(error));
    }
}

function* onUpdateDevProgram({payload:devProgram}){
    try{
        const response = yield call(updateDevProgram, devProgram)
        yield put(updateDevProgramSuccess(response));
    } catch (error) {
        yield put(updateDevProgramFail(error));
    }
}

export function* devProgramsSaga() {
    yield takeEvery(GET_DEV_PROGRAMS, fetchDevPrograms);
    yield takeEvery(DELETE_DEV_PROGRAM, onDeleteDevPrograms);
    yield takeEvery(ADD_DEV_PROGRAM, onAddDevProgram);
    yield takeEvery(UPDATE_DEV_PROGRAM, onUpdateDevProgram);
   
    
}

export default devProgramsSaga;
