/** Get PLAN_OUTSIDE */
export const GET_PLAN_OUTSIDES = "GET_PLAN_OUTSIDES";
export const GET_PLAN_OUTSIDES_SUCCESS = "GET_PLAN_OUTSIDES_SUCCESS";
export const GET_PLAN_OUTSIDES_FAIL = "GET_PLAN_OUTSIDES_FAIL";

export const GET_PLAN_OUTSIDE_DETAIL = "GET_PLAN_OUTSIDE_DETAIL";
export const GET_PLAN_OUTSIDE_DETAIL_SUCCESS = "GET_PLAN_OUTSIDE_DETAIL_SUCCESS";
export const GET_PLAN_OUTSIDE_DETAIL_FAIL = "GET_PLAN_OUTSIDE_DETAIL_FAIL";


export const ADD_PLAN_OUTSIDE = "ADD_PLAN_OUTSIDE";
export const ADD_PLAN_OUTSIDE_SUCCESS = "ADD_PLAN_OUTSIDE_SUCCESS";
export const ADD_PLAN_OUTSIDE_FAIL = "ADD_PLAN_OUTSIDE_FAIL";

export const UPDATE_PLAN_OUTSIDE = "UPDATE_PLAN_OUTSIDE";
export const UPDATE_PLAN_OUTSIDE_SUCCESS = "UPDATE_PLAN_OUTSIDE_SUCCESS";
export const UPDATE_PLAN_OUTSIDE_FAIL = "UPDATE_PLAN_OUTSIDE_FAIL";

export const DELETE_PLAN_OUTSIDE = "DELETE_PLAN_OUTSIDE";
export const DELETE_PLAN_OUTSIDE_SUCCESS = "DELETE_PLAN_OUTSIDE_SUCCESS";
export const DELETE_PLAN_OUTSIDE_FAIL = "DELETE_PLAN_OUTSIDE_FAIL"; 

export const GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES = "GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES";
export const GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS = "GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS";
export const GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL = "GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL";

export const ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES = "ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES";
export const ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS = "ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS";
export const ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL = "ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL";

export const DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES = "DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES";
export const DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS = "DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS";
export const DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL = "DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL";

export const UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES = "UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES";
export const UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS = "UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS";
export const UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL = "UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL";

export const GET_PLAN_OUTSIDES_BY_BRANCH = "GET_PLAN_OUTSIDES_BY_BRANCH";
export const GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS = "GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS";
export const GET_PLAN_OUTSIDES_BY_BRANCH_FAIL = "GET_PLAN_OUTSIDES_BY_BRANCH_FAIL";

export const GET_BRANCH_PLAN_OUTSIDE = "GET_BRANCH_PLAN_OUTSIDE";
export const GET_BRANCH_PLAN_OUTSIDE_SUCCESS = "GET_BRANCH_PLAN_OUTSIDE_SUCCESS";
export const GET_BRANCH_PLAN_OUTSIDE_FAIL = "GET_BRANCH_PLAN_OUTSIDE_FAIL";

export const GET_USER_BY_MAIL = "GET_USER_BY_MAIL";
export const GET_USER_BY_MAIL_SUCCESS = "GET_USER_BY_MAIL_SUCCESS";
export const GET_USER_BY_MAIL_FAIL = "GET_USER_BY_MAIL_FAIL";

export const GET_PLAN_OUTSIDES_DOWN = "GET_PLAN_OUTSIDES_DOWN";
export const GET_PLAN_OUTSIDES_DOWN_SUCCESS = "GET_PLAN_OUTSIDES_DOWN_SUCCESS";
export const GET_PLAN_OUTSIDES_DOWN_FAIL = "GET_PLAN_OUTSIDES_DOWN_FAIL";

export const SEND_MAIL = "SEND_MAIL";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";

export const RESET_PLAN_OUTSIDE= "RESET_PLAN_OUTSIDE";