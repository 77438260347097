import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import QrModal from "./Modals/qrModal"
import FormButton from "./Modals/buttons"

import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Alert,
  CardBody,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabContent,
  Nav,
  Form,
  FormGroup,
  TabPane,
  CardTitle,
  CardSubtitle,
  NavItem,
  NavLink,
  Badge,
  Tooltip,
} from "reactstrap"

import CashCard from "./Cards/CashCard"
import PreSelectionSwitch from "./Modals/preSelectionSwitch"
import SelectBranchModal from "./Modals/selectBranch"
import SelectSurveyModal from "./Modals/selectSurvey"
import SelectOrientationModal from "./Modals/selectOrientation"
import AcceptedModal from "./Modals/acceptedModal"
import Rating from "react-rating"
import RatingTooltip from "react-rating-tooltip"
import { ToastContainer, toast } from "react-toastify"

import {
  deleteAppForm as onDeleteAppForm,
  deleteMyFormStatusTrue as onDeleteMyFormStatusTrue,
  deleteMyFormStatusFalse as onDeleteMyFormStatusFalse,
  getMyFormsStatusFalse as onGetMyFormsStatusFalse,
  getMyFormsStatusTrue as onGetMyFormStatusTrue,
  getAppForms as onGetAppForms,
  updateMyFormStatusFalse as onUpdateFormStatusFalse,
  updateMyFormStatusTrue as onUpdateFormStatusTrue,
  statusUpdateAppForm as onStatusUpdateForm,
  descUpdateAppForm as onDescUpdateForm,
  rejectAppForm as onRejectAppForm,
  getCompanyById as onGetCompanyById,
  resetAppForm as onResetAppForm,
  getFormsDown as onGetFormsDown,
  getPartnerships as onGetPartnership,
  getCompanyBranches as onGetCompanyBranches,

  getSurveys as onGetSurveys,
  getSurveyForms as onGetSurveyForm,
  getSurveySents as onGetSurveySent,
  getOrientations as onGetOrientations,
  getOrientationForms as onGetOrientationForms,
  getOrientationSents as onGetOrientationSent,
  firedAppForm as onFiredAppForm,
  getSoftSkills as onGetSoftSkills,
  getBranchChilds as onGetBranchChilds,
  addVisit as onAddVisit,
  getAnalyticByLink as onGetAnalyticByLink
} from "store/actions"
import classnames from "classnames"
import defaultProfilePic from "../../assets/images/users/default-user-pic.png"
import Table from "./Tables/Tables"
import { authorize, Role } from "./../../components/helpers/authorize"
import { useSelector, useDispatch } from "react-redux"
import config from "config"
import QrCodeModal from "./../Dashboard/Modals/QrCodeModal"
import logodark from "./../../assets/images/bqr2.png"
import "./datatables.scss"
//i18n
import { withTranslation } from "react-i18next"

import { } from "store/actions"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"
import { entriesIn, isEmpty, map } from "lodash"
import moment from "moment"
import { locale } from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import user1 from "../../assets/images/User-Avatar-Profile-PNG-Free-File-Download.png"

const Dashboard = props => {
  const dispatch = useDispatch()
  const [editInfo, setEditInfo] = useState([])
  const [selectedPlanObject, setSelectedPlanObject] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [desc, setDesc] = useState()
  const [selectedForm, setSelectedForm] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modalQr, setModalQr] = useState(false)
  const [checkFormModal, setCheckFormModal] = useState(false)
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState("Bütün Birimler")
  const [descriptionModal, setDescriptionModal] = useState(false)
  const [SurveyModal, setSurveyModal] = useState(false)
  const [OrientationModal, setOrientationModal] = useState(false)
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const tooltipContentMore = ["1", "2", "3", "4", "5"]
  const [rate, setRate] = useState()
  const [rate2, setRate2] = useState()
  const [rate3, setRate3] = useState()
  const [rate4, setRate4] = useState()
  const [rate5, setRate5] = useState()
  const [matchSwitch, setMatchSwitch] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false);
  const starStyle = {}
  const [isEdit, setIsEdit] = useState(false)
  const [formsValue, setFormsValue] = useState("")
  const [tempList, setTempList] = useState([])
  const [partnershipList, setPartnershipList] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [rejectPlan, setRejectPlan] = useState([])
  const [isRejected, setIsRejected] = useState(false)
  const [typeSelectFail, setTypeSelectFail] = useState(false)
  const [tempBranchList, setTempBranchList] = useState([])
  const [GradeArray, setGradeArray] = useState([])
  const [gender, setGender] = useState("")
  const [falseInfoArea, setFalseInfoArea] = useState(false) 
 
  const {
    error,
    AppForm, MyForms, MyFormsStatusFalse, MyFormStatusTrue, CompanyQr, CompanyInfo, CompanyQrPhoto,
    descSuccess, Partnership, Branches, BranchesChild, Grades, CompanyName, FileMove, SoftSkills,
    getAnalyticByLink
  } = useSelector(state => ({
    error: state.Plans?.error,
    MyForms: state.AppForm?.myForms,
    AppForm: state.AppForm?.data,
    CompanyQr: state.Companies?.company?.qrCode,
    descSuccess: state.AppForm?.descSuccess,
    Partnership: state.Partnership?.data,
    CompanyInfo: state.Companies?.company?.qrCode,
    CompanyQrPhoto: state.Companies?.company?.QRphoto,
    Branches: state.Branchs?.Branches,
    BranchesChild: state.Branchs?.data,
    Grades: state.Companies?.company?.grades,
    CompanyName: state.Companies?.company?.name,
    FileMove: state.AppForm?.fileMove,
    SoftSkills: state.SoftSkill?.data,
    getAnalyticByLink: state.Analytics?.getAnalyticByLink
  }))
  let user = JSON.parse(localStorage.getItem("authUser"))
  
  useEffect(() => {
    if (!isEmpty(Grades)) { 
      let g = []
      {
        Grades?.map((item, index) => (
          g.push([])
        ))
      }
      setGradeArray(g)
    }

  }, [Grades])
  console.log("GradeArray",GradeArray)
  useEffect(() => {
    if (!isEmpty(Branches)) {
      let y = GradeArray
      let x = Branches.filter(x => x.name.charAt(0) != "-")
      y[0] = x
      setTempBranchList(y)
    }
  }, [Branches])
  useEffect(() => {
    if (!isEmpty(BranchesChild)) {
      let g = GradeArray

      for (let i = 0; i < g.length; i++) {

        if (isEmpty(g[i])) {
          g[i] = BranchesChild
          break;
        }
      }
      setGradeArray(g)
    }

  }, [BranchesChild])



  const load = () => {
    dispatch(onGetCompanyById(user.companyId))
     dispatch(onGetSurveys())
     dispatch(onGetOrientations())
    dispatch(onGetSoftSkills())


    if (authorize([Role.Admin])) {
      dispatch(onGetAppForms(user.companyId))
      dispatch(onGetCompanyBranches(user.companyId))
    } else if (authorize([Role.Avm])) {
      null
    } else {
      dispatch(onGetFormsDown(user.branchId))
      dispatch(onGetCompanyBranches(user.companyId, user.branchId))
    }

    if (!isEmpty(AppForm)) {
      setTempList(AppForm.filter(x => x.formStatus == "Yeni Başvurular"))
    }




    // dispatch(onGetMyFormsStatusFalse(user.companyId))
    // dispatch(onGetMyFormStatusTrue(user.companyId))
  }
  useEffect(() => {
    load()
    if (authorize([Role.Avm])) {
      dispatch(onGetPartnership()) 
    }
    const visitDetailArr =[{name:"Başvuru Havuzu",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 

  }, [dispatch])
  useEffect(() => {
    dispatch(onResetAppForm())
    setTypeSelectFail(false)
  }, [checkFormModal])
  useEffect(() => {
    if (!isEmpty(Partnership)) {

      setPartnershipList(
        Partnership.filter(
          x => x.status == "approved"
        )
      )

    }
  }, [Partnership])


  useEffect(()=>{
    if (!isEmpty(CompanyInfo) && authorize([Role.Avm])) { 
      console.log("user.companyId, CompanyInfo", user.companyId, CompanyInfo)
      dispatch(onGetAnalyticByLink(user.companyId, CompanyInfo))
    } 
  },[CompanyInfo])

  const [rateObject, setRateObject] = useState([])

  useEffect(() => {
    if (!isEmpty(SoftSkills)) {
      let Values = []
      for (let i = 0; i < SoftSkills.length; i++) {
        let Ar = {
          ratePoint: "",
          softSkill: "",
        }
        Values.push(Ar)
      }
      setRateObject(Values)
    }
  }, [SoftSkills])

  useEffect(() => {

    if (!isEmpty(AppForm)) {
      if (customIconActiveTab == "1" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "Yeni Başvurular" && x.isMatch == true)
        )
      }
      else if (customIconActiveTab == "1" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "Yeni Başvurular"))
      }


      if (customIconActiveTab == "2" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "IK Havuzu" && x.isMatch == true))
      } else if (customIconActiveTab == "2" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "IK Havuzu"))
      }
      else if (customIconActiveTab == "3" && matchSwitch == true) {
        setTempList(
          AppForm.filter(x => x.formStatus == "Elenenler" && x.isMatch == true)
        )
      } else if (customIconActiveTab == "3" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "Elenenler"))
      }
      else if (customIconActiveTab == "4" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "Görüşülecekler" && x.isMatch == true))
      } else if (customIconActiveTab == "4" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "Görüşülecekler"))
      }


      else if (customIconActiveTab == "5" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "Görüşülenler" && x.isMatch == true)
        )
      } else if (customIconActiveTab == "5" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "Görüşülenler"))
      }
      else if (customIconActiveTab == "6" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "İş Teklif Edilecekler" && x.isMatch == true
        )
        )
      } else if (customIconActiveTab == "6" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "İş Teklif Edilecekler"))
      } else if (customIconActiveTab == "7" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "Teklifi Reddedenler" && x.isMatch == true))
      }
      else if (customIconActiveTab == "7" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "Teklifi Reddedenler"))
      }
      else if (customIconActiveTab == "8" && matchSwitch == true) {
        setTempList(AppForm.filter(x => x.formStatus == "İşe Alınanlar" && x.isMatch == true)
        )
      } else if (customIconActiveTab == "8" && matchSwitch == false) {
        setTempList(AppForm.filter(x => x.formStatus == "İşe Alınanlar"))
      }
    }
    else { setTempList([""]) }
  }, [AppForm, customIconActiveTab, matchSwitch, selectedBranchId])

  const toggleQr = () => {
    setModalQr(!modalQr)
  }
  const toggleForm = () => {
    setCheckFormModal(!checkFormModal)
  }
  const toggleDescription = () => {
    setDescriptionModal(!descriptionModal)
  }
  const toggleSurvey = () => {
    setSurveyModal(!SurveyModal)
  }
  const toggleOrientation = () => {
    setOrientationModal(!OrientationModal)
  }
  const toggleAccepted = () => {
    setAcceptedModal(!acceptedModal)
  }

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      if (tab == "1") {
        setTempList(AppForm)
      } else if (tab == "2") {
        setTempList(AppForm.filter(x => x.status == false))
      } else if (tab == "3") {
        setTempList(AppForm.filter(x => x.status == true))
      }

      setcustomIconActiveTab(tab)
    }
  }
  const { SearchBar } = Search

  const handleValidFormSubmit = (e, values) => {
    const updatePlan = {
      _id: selectedForm._id,

      status: true,
    }

    dispatch(onStatusUpdateForm(updatePlan))

    toggleForm()
  }

  const handleValidFormSubmitDesc = (e, values) => {
    let commentArr = [
      {
        comment: values.ik_notes,
        rate: rateObject,
        UserId: user.uid,
        UserName: user.userName,
        position: user?.position,
      },
    ]
    //let ArrForComment = evaluations.concat(commentArr)
    //setEvaluations(ArrForComment)
    const updatePlan = {
      _id: selectedForm._id,
      ik_notes: JSON.stringify(commentArr),
    }

    dispatch(onDescUpdateForm(updatePlan))

    toggleDescription()

  }

  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD-MM-YYYY")
      return date1
    } else {
      return ""
    }
  }

  const handleChangeRate = (i, rate) => {
    const values = [...rateObject]
    values[i].softSkill = SoftSkills[i]?.name
    values[i].ratePoint = rate
    setRateObject(values)
  }
  const columns = [
    {
      dataField: "firstName",
      text: props.t("First Name"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },
    {
      dataField: "lastName",
      text: props.t("Last Name"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },
    {
      dataField: "email",
      dataField: "email",
      text: props.t("Email"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },
    {
      dataField: "DutyId",
      text: props.t("Başvurulan Pozisyon"),
      sort: true, 
      formatter: (cell, row) =>
        cell?.description,
    },
    {
      dataField: "createdAt",
      text: props.t("Created Date"),
      sort: true,
      formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
    },
    {
      dataField: "ParentBranchId",
      text: props.t("Üst Birim İsmi"),
      sort: true,
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{!isEmpty(cell?.name) ? cell?.name : null}</span>,

    },
    {
      dataField: "BranchId",
      text: props.t("Branch Name"),
      sort: true,
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{!isEmpty(cell?.name) ? cell?.name : row?.BranchLabel?.name}</span>,

    },
    
    

    {
      dataField: "planInfo",
      text: props.t("Applications Forms"),
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            onClick={() => {
              setSelectedForm(row)
              setSelectedPlanObject(row.PlanObjects)
              setEditInfo(row)
              //toggleForm()
              setCheckFormModal(true)
              setEvaluations(row.ik_notes)

              dispatch(onGetSurveyForm(row._id))
              dispatch(onGetSurveySent(row._id))

              dispatch(onGetOrientationForms(row._id))
              dispatch(onGetOrientationSent(row._id))


            }}
          >
            {props.t("Check The Form")}
          </Button>
        </div>
      ),
    },

    {
      dataField: "formStatus",
      text: props.t("Form Status"),
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.formStatus == "İş Teklif Edilecekler" ||
            row.formStatus == "İncelenenler" ||
            row.formStatus == "Görüşülecekler" ||
            row.formStatus == "Yeni Başvurular" ||
            row.formStatus == "IK Havuzu" ? (
            <Badge className="font-size-12 badge-soft bg-warning">{cell}</Badge>
          ) : row.formStatus == "Görüşülenler" ||
            row.formStatus == "Teklifler" ||
            row.formStatus == "İşe Alınanlar" ? (
            <Badge className="font-size-12 badge-soft bg-success">{cell}</Badge>
          ) : (
            <Badge className="font-size-12 badge-soft bg-danger">{cell}</Badge>
          )}
        </div>
      ),
    },
    {
      dataField: "isMatch",
      text: props.t("Pre Selection"),
      sort: true,
      formatter: (cell, row) => (
        <div
          onMouseEnter={() => {
            cell == true ? null : setFalseInfoArea(true)
          }}
          onMouseLeave={() => (cell == true ? null : setFalseInfoArea(false))}
          style={{ position: "relative", left: "10px" }}
        >
          {cell == true ? (
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle  text-white font-size-16"
                style={{ backgroundColor: "#34c38f" }}
              >
                <i className="bx bx-comment-check"></i>{" "}
              </span>
            </div>
          ) : (
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle text-white font-size-16"
                style={{ backgroundColor: "#f46a6a" }}
              >
                i
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#"
                onClick={() => {
                  //console.log(row)
                  setSelectedId(cell)
                  setConfirmDelete(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]
  
  const avmColumn = [
    {
      dataField: "BrandName",
      text: props.t("Company Name"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },
    {
      dataField: "tıklanmaSayısı",
      text: props.t("Tıklanma Sayısı"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      formatter: (cell, row) =>
        row.status ? <span>{cell}</span> : <b>{cell}</b>,
    },

  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tempList ? tempList.length : 0, // replace later with size(customers),
    custom: true,
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY HH:mm")
      return date1
    } else {
      return ""
    }
  }
  const copyToClipboard = txt => {
    var textField = document.createElement("textarea")
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
        <title>{props.t("Application Forms")}</title>
     
       </MetaTags>
        <Container fluid>

          <ToastContainer
            style={{ position: "absolute", top: "39px", left: "1229px" }}
            autoClose={3000}
          />

      {authorize([Role.Dev,Role.Avm,Role.Admin,Role.YetkiliUser, Role.Ik,Role.YetkiliIk])?(
         <h4 className="mb-4">{props.t("Application Pool Folders")}</h4>
        ):null}
          
          {authorize([Role.Dev, Role.Admin, Role.YetkiliUser,Role.Ik,Role.YetkiliIk]) ? (
            <div className="mt-2">
              <Row>
                {customIconActiveTab==1?(
                  <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("1")
                  }}
                >
                  <CashCard backgroundColor={"rgb(237, 237, 237)"} Shadow={"orange 3px 4px 26px -4px"}
                  
                    title={props.t("New Applications")}
                    border={"2px solid orange"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Yeni Başvurular" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Yeni Başvurular")
                          .length
                    }
                  />
                </Col>
                ): <Col
                sm="6" md="4" lg="4" xl="3"
                onClick={() => {
                  toggleIconCustom("1")
                }}
              >
                <CashCard
                  title={props.t("New Applications")} backgroundColor={"white"}
                  border={"1px solid orange"}
                  price={
                    matchSwitch
                      ? AppForm.filter(
                        x =>
                          x.formStatus == "Yeni Başvurular" &&
                          x.isMatch == true
                      ).length
                      : AppForm.filter(x => x.formStatus == "Yeni Başvurular")
                        .length
                  }
                />
              </Col>}
              {customIconActiveTab==2?(
                <Col sm="6" md="4" lg="4" xl="3"
                onClick={() => {
                  toggleIconCustom("2")
                }}
              >
                <CashCard backgroundColor={"rgb(237, 237, 237)"} Shadow={"blue 3px 4px 26px -4px"}
                  title={props.t("Updatable Repository")}
                  border={"1px solid blue"}
                  price={
                    matchSwitch
                      ? AppForm.filter(
                        x => x.formStatus == "IK Havuzu" && x.isMatch == true
                      ).length
                      : AppForm.filter(x => x.formStatus == "IK Havuzu").length
                  }
                />
              </Col>
              ):<Col sm="6" md="4" lg="4" xl="3"
              onClick={() => {
                toggleIconCustom("2")
              }}
            >
              <CashCard
                title={props.t("Updatable Repository")} backgroundColor={"white"}
                border={"1px solid blue"}
                price={
                  matchSwitch
                    ? AppForm.filter(
                      x => x.formStatus == "IK Havuzu" && x.isMatch == true
                    ).length
                    : AppForm.filter(x => x.formStatus == "IK Havuzu").length
                }
              />
            </Col>}
            {customIconActiveTab==3?( <Col sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("3")
                  }}
                >
                  <CashCard
                    title={props.t("Negatives")} backgroundColor={"rgb(237, 237, 237)"} Shadow={"red 3px 4px 26px -4px"}
                    border={"1px solid red"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x => x.formStatus == "Elenenler" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Elenenler").length
                    }
                  />
                </Col>):( <Col sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("3")
                  }}
                >
                  <CashCard
                    title={props.t("Negatives")} backgroundColor={"white"}
                    border={"1px solid red"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x => x.formStatus == "Elenenler" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Elenenler").length
                    }
                  />
                </Col>)}
                {customIconActiveTab==4?(<Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("4")
                  }}
                >
                  <CashCard
                    title={props.t("To Be Discuss")} backgroundColor={"rgb(237, 237, 237)"} Shadow={"orange 3px 4px 26px -4px"}
                    border={"1px solid orange"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Görüşülecekler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Görüşülecekler")
                          .length
                    }
                  />
                </Col>):(<Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("4")
                  }}
                >
                  <CashCard
                    title={props.t("To Be Discuss")} backgroundColor={"white"}
                    border={"1px solid orange"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Görüşülecekler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Görüşülecekler")
                          .length
                    }
                  />
                </Col>)}
                {customIconActiveTab==5?(<Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("5")
                  }}
                >
                  <CashCard
                    title={props.t("Interviewees")} backgroundColor={"rgb(237, 237, 237)"} Shadow={"green 3px 4px 26px -4px"}
                    border={"1px solid green"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Görüşülenler" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Görüşülenler")
                          .length
                    }
                  />
                </Col>):(<Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("5")
                  }}
                >
                  <CashCard
                    title={props.t("Interviewees")} backgroundColor={"white"}
                    border={"1px solid green"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Görüşülenler" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "Görüşülenler")
                          .length
                    }
                  />
                </Col>)}
                {customIconActiveTab==6?( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("6")
                  }}
                >
                  <CashCard backgroundColor={"rgb(237, 237, 237)"} Shadow={"orange 3px 4px 26px -4px"}
                    title={props.t("Offered")}
                    border={"1px solid orange"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "İş Teklif Edilecekler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(
                          x => x.formStatus == "İş Teklif Edilecekler"
                        ).length
                    }
                  />
                </Col>):( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("6")
                  }}
                >
                  <CashCard
                    title={props.t("Offered")} backgroundColor={"white"}
                    border={"1px solid orange"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "İş Teklif Edilecekler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(
                          x => x.formStatus == "İş Teklif Edilecekler"
                        ).length
                    }
                  />
                </Col>)}
                {customIconActiveTab==7?( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("7")
                  }}
                >
                  <CashCard
                    title={props.t("Who Rejected")} backgroundColor={"rgb(237, 237, 237)"} Shadow={"red 3px 4px 26px -4px"}
                    border={"1px solid red"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Teklifi Reddedenler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(
                          x => x.formStatus == "Teklifi Reddedenler"
                        ).length
                    }
                  />
                </Col>):( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("7")
                  }}
                >
                  <CashCard
                    title={props.t("Who Rejected")} backgroundColor={"white"}
                    border={"1px solid red"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "Teklifi Reddedenler" &&
                            x.isMatch == true
                        ).length
                        : AppForm.filter(
                          x => x.formStatus == "Teklifi Reddedenler"
                        ).length
                    }
                  />
                </Col>)}
                {customIconActiveTab==8?( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("8")
                  }}
                >
                  <CashCard backgroundColor={"rgb(237, 237, 237)"} Shadow={"green 3px 4px 26px -4px"}
                    title={props.t("Hired")}
                    border={"1px solid green"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "İşe Alınanlar" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "İşe Alınanlar")
                          .length
                    }
                  />
                </Col>):( <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("8")
                  }}
                >
                  <CashCard backgroundColor={"white"}
                    title={props.t("Hired")}
                    border={"1px solid green"}
                    price={
                      matchSwitch
                        ? AppForm.filter(
                          x =>
                            x.formStatus == "İşe Alınanlar" && x.isMatch == true
                        ).length
                        : AppForm.filter(x => x.formStatus == "İşe Alınanlar")
                          .length
                    }
                  />
                </Col>)}
               
              </Row>
            </div>

          ) :authorize([Role.Avm])? (

            <div className="mt-2">
              <Row>
                <Col md="8" sm="9" xs="12" className="profile-chart-pos" >
                  <div className="mb-4"><Link style={{ fontSize: "14px", fontWeight: "700" }} target={"_blank"} to={{ pathname: process.env.REACT_APP_DOMAIN + "/avm/" + CompanyInfo }}>
                    {process.env.REACT_APP_DOMAIN + "/avm/" + CompanyInfo}
                  </Link><a className="me-1" style={{ fontSize: 24, fontWeight: "700" }} onClick={() => { copyToClipboard(process.env.REACT_APP_DOMAIN + "/avm/" + CompanyInfo) }}>
                    </a>
                    <b style={{ fontWeight: "200", fontSize: "14px" }}></b>
                    <a onClick={() => { toggleQr() }}>
                      <i style={{ fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-grid-alt "></i></a>
                    <a onClick={() => { copyToClipboard(process.env.REACT_APP_DOMAIN + "/avm/" + CompanyInfo) }}>
                      <i style={{ fontSize: "30px" }} className="bx bx-copy"></i></a>
                  </div>

                </Col>
              </Row>
              <Row>
                <Col
                  sm="6" md="4" lg="4" xl="3"
                  onClick={() => {
                    toggleIconCustom("1")
                  }}
                >
                  <CashCard
                    title={props.t("Okutulan QR Sayısı")}
                    border={"2px solid" + getAnalyticByLink>0?"orange":""}
                    price={ getAnalyticByLink }
                  />
                </Col>
              </Row>
            </div>
          ):null}

          {authorize([Role.Dev, Role.Admin , Role.YetkiliUser ,Role.Ik,Role.YetkiliIk]) ? (
            <Card>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "1",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-file"></i>
                        {props.t("New Applications")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-file"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "2",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-file"></i>
                        {props.t("Updatable Repository")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-file"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "3",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("3")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-intersect"></i>
                        {props.t("Negatives")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-intersect"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "4",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("4")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-comment-minus"></i>{" "}
                        {props.t("To Be Discuss")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-comment-minus"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "5",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("5")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-comment-minus"></i>{" "}
                        {props.t("Interviewees")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-comment-minus"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "6",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("6")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-comment-minus"></i>{" "}
                        {props.t("Offered")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-comment-minus"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "7",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("7")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-comment-minus"></i>{" "}
                        {props.t("Who Rejected")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-comment-minus"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="t-navlink">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        "t-navlink-item " +
                        classnames({
                          active: customIconActiveTab === "8",
                        })
                      }
                      onClick={() => {
                        toggleIconCustom("8")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="bx bx-comment-minus"></i> {props.t("Hired")}
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="bx bx-comment-minus"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={customIconActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search

                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>

                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                         
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col> 

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>

                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                         
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                         
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="7">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="8">
                    <Row>
                      <Col sm="12" className="mt-2">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="_id"
                          columns={columns}
                          data={tempList}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              columns={columns}
                              data={tempList}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <PreSelectionSwitch
                                    setSwitch={setMatchSwitch}
                                    switch={matchSwitch}
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "145px",
                                    }}
                                  >
                                    {falseInfoArea ? (
                                      <Alert
                                        color="danger"
                                        style={{
                                          position: "absolute",
                                          zIndex: "10",
                                        }}
                                      >
                                        <i className="bx bx-info-circle" />{" "}
                                        {props.t(
                                          "Does not Meet the Pre-selection Criteria"
                                        )}
                                      </Alert>
                                    ) : null}
                                  </div>
                                  <Row className="mb-2">

                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />

                                       
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {!isEmpty(Grades) ? (<Col md="2">
                                      <AvForm>
                                        <Col className="col-12 mb-3">

                                          <AvField
                                            className="form-select"
                                            type="select"
                                            name="BranchId"
                                            value={selectedBranchId}
                                            onChange={e => {
                                              let g = GradeArray
                                              for (let i = 1; i < g.length; i++) {
                                                g[i] = []
                                              }
                                              setGradeArray(g)
                                              if (e.target.value.includes("Bütün")) {
                                                if (authorize([Role.Admin])) {
                                                  dispatch(onGetAppForms(user.companyId))

                                                } else {
                                                  dispatch(onGetFormsDown(Branches[0]?._id))
                                                }


                                              } else {
                                                dispatch(onGetFormsDown(e.target.value))
                                                dispatch(onGetBranchChilds(e.target.value))
                                              }
                                            }}
                                          >
                                            <option>Bütün {Grades ? Grades[0]?.id : null}</option>
                                            {tempBranchList[0]?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                      </AvForm>
                                    </Col>) : null}


                                    {Grades?.map((f, i) => {
                                      if (i != Grades?.length - 1) {
                                        return (
                                          <Col md="2">
                                            <AvForm>
                                              <Col className="col-12 mb-3">

                                                <AvField
                                                  className="form-select"
                                                  type="select"
                                                  name="BranchId"
                                                  value={selectedBranchId}
                                                  onChange={e => {
                                                    let g = GradeArray
                                                    for (i; i < Grades.length; i++) {
                                                      g[i + 2] = []
                                                    }
                                                    setGradeArray(g)

                                                    if (e.target.value.includes("Bütün")) {
                                                      if (authorize([Role.Admin])) {
                                                        dispatch(onGetAppForms(user.companyId))

                                                      } else {
                                                        dispatch(onGetFormsDown(Branches[0]?._id))
                                                      }
                                                    } else {
                                                      dispatch(onGetFormsDown(e.target.value))
                                                      dispatch(onGetBranchChilds(e.target.value))
                                                    }
                                                  }}
                                                >
                                                  <option>Bütün {Grades ? Grades[i + 1]?.id : null}</option>
                                                  {GradeArray[i + 1]?.map((item, index) => (

                                                    <option key={index} value={item._id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </Col>

                                            </AvForm>
                                          </Col>
                                        )
                                      }
                                    })}

                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"_id"}
                                          responsive
                                          bordered={false}
                                          noDataIndication={props.t(
                                            "You Don't Have a Form Yet"
                                          )}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>) 
            :authorize([Role.Avm]) ? (
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12" className="mt-2">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="_id"
                      columns={avmColumn}
                      data={partnershipList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={avmColumn}
                          data={partnershipList}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <div
                                style={{
                                  float: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  marginRight: "145px",
                                }}
                              >
                                {falseInfoArea ? (
                                  <Alert
                                    color="danger"
                                    style={{
                                      position: "absolute",
                                      zIndex: "10",
                                    }}
                                  >
                                    <i className="bx bx-info-circle" />{" "}
                                    {props.t(
                                      "Does not Meet the Pre-selection Criteria"
                                    )}
                                  </Alert>
                                ) : null}
                              </div>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />


                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"_id"}
                                      responsive
                                      bordered={false}
                                      noDataIndication={props.t(
                                        "Henüz Anlaşmalı Bir Firmanız Yok"
                                      )}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Col>
                </Row>



              </CardBody>
            </Card>):(
              <div className="text-center">
                 <img
              src={logodark}
              alt=""
              height="400"
              className="auth-logo-dark"
              style={{position:"relative", right:"21px"}}
            />
              <h4>BU QR'DA İŞ VAR PROGRAMINA HOŞGELDİNİZ</h4>
              </div>
             
            )} 
         
           
             
          <Modal isOpen={checkFormModal} style={{ maxWidth: "1490px" }} toggle={toggleForm}>
            <ModalHeader toggle={toggleForm} tag="h4">
              {props.t("Job Application Form")}
            </ModalHeader>
            <ModalBody className="modal-scroll">
              <AvForm onValidSubmit={handleValidFormSubmit}>
                <Row form>
                  <Col xs={12}>
                    <Row>
                      <Col xs="12" sm="12" md="2" lg="2">
                        <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                          {selectedForm?.photo ? (
                            <img
                              src={config.baseImg + selectedForm?.photo}
                              //style={{ width: "175px", height: "175px", borderRadius: "50%" }}
                              style={{ maxWidth: "175px", maxHeight: "175px",objectFit:"cover" }}
                            />
                          ) : (
                            <img
                              src={defaultProfilePic}
                              //style={{ width: "175px", height: "175px", borderRadius: "50%" }}
                              style={{ maxWidth: "175px", maxHeight: "175px",objectFit:"cover" }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <p style={{ fontSize: "36px", fontFamily: "boxicons" }}>{selectedForm?.firstName}{" "}{selectedForm?.lastName} </p>
                      </Col>

                      <Col xs="12" sm="12" md="4" lg="4">
                        <h4>İletişim Bilgileri</h4>
                        <hr />
                        <p> <i className="bx bx-envelope" style={{ fontSize: "24px", position: "relative", top: "5px" }} /> Email:{" "}{selectedForm?.email}</p>
                        <p> <i className="bx bx-phone-call" style={{ fontSize: "24px", position: "relative", top: "5px" }} />Tel:{" "}{selectedForm?.phone}</p>
                        <p> <i className="bx bx-map" style={{ fontSize: "24px", position: "relative", top: "5px" }} />Adres:{" "}{selectedForm?.city?.name}{"/"}{selectedForm?.district?.name}{"/"}{selectedForm?.neighborhood?.name}</p>


                      </Col>
                    </Row>
                    <hr style={{ color: "blue", border: "2px solid" }} />
                    <Row style={{ marginRight: "50px", marginLeft: "50px" }}>
                      <h2>Hakkında</h2>
                      <hr />
                      <Row>
                        <Col xs="12" sm="12" md="4" lg="4">
                          <p> <i className="bx bx-id-card" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}TC Kimlik No:{" "}{selectedForm?.identityNumber}</p>
                          <p> <i className="bx bx-cake" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Doğum Günü: {" "}{handleValidDateAvField(selectedForm?.birthday)}</p>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                          {map(selectedPlanObject, (item, index) => (
                            item.title == "Cinsiyet" ?
                              <p> <i className="bx bx-male" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Cinsiyet: {" "}{item?.isValue}</p> :
                              item.title == "Aile ve Medeni Durum" ? <p> <i className="  bx bxs-heart" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Medeni Durumu:{" "}{item?.isValue}</p> :
                                null

                          ))}
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                          {map(selectedPlanObject, (item, index) => (

                            item.title == "Eğitim Durumu" ? <p> <i className="  bx bxs-book" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Eğitim Durumu: {" "}{item?.isValue}</p> :
                              item.title == "Askerlik Durumu" ? item?.isValue ? (
                                <p> <i className="   bx bx-run" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Askerlik Durumu: {" "}{item?.isValue}</p>
                              ) : null : null

                          ))}
                        </Col>

                      </Row>

                    </Row>
                    {map(selectedPlanObject, (item, index) => (
                      item.title == "Program Bilgisi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }}>
                          <h2>Program Bilgisi</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <Row>
                                <Col xs="3" sm="3" md="1" lg="1">
                                  <b className="mb-2">{programName[0]?.isValue}</b>
                                </Col>
                                <Col xs="9" sm="9" md="11" lg="11" style={{ position: "relative", bottom: "7px" }}>
                                  <Rating
                                    ActiveComponent={
                                      <i
                                        className="mdi mdi-star text-primary"
                                        style={starStyle}
                                      />
                                    }
                                    InActiveComponent={
                                      <i
                                        className="mdi mdi-star-outline text-muted"
                                        style={starStyle}
                                      />
                                    }
                                    readonly={true}
                                    initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[1]?.isValue == "Az" ? 1 : programName[1]?.isValue == "Orta" ? 2 : programName[1]?.isValue == "İyi" ? 3 : programName[1]?.isValue == "Çok İyi" ? 4 : 0}
                                  />
                                </Col>
                              </Row>



                            </div>


                          ))}

                        </Row>
                      ) : item.title == "Yabancı Dil" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">

                          <h2>Yabancı Dil</h2>
                          <hr />
                            <Row>
                            {map(item?.isValue, (programName, programIndex) => (
                            <Col className="col-4 mt-3">
                               <b className="mt-4">{programName[0]?.isValue}</b><br /><br />

                              <div>
                                <Row>
                                  <Col className="col-2 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[1]?.title}</b>
                                  </Col>
                                  <Col className="col-10">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[1]?.isValue == "Az" ? 1 : programName[1]?.isValue == "Orta" ? 2 : programName[1]?.isValue == "İyi" ? 3 : programName[1]?.isValue == ":Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                              <div>
                                <Row>
                                  <Col className="col-2 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[2]?.title}</b>
                                  </Col>
                                  <Col className="col-10">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[2]?.isValue == "Yok" ? 0 : programName[2]?.isValue == "Az" ? 1 : programName[2]?.isValue == "Orta" ? 2 : programName[2]?.isValue == "İyi" ? 3 : programName[2]?.isValue == "Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                              <div>
                                <Row>
                                  <Col className="col-2 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[3]?.title}</b>
                                  </Col>
                                  <Col className="col-10">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[3]?.isValue == "Az" ? 1 : programName[3]?.isValue == "Orta" ? 2 : programName[3]?.isValue == "İyi" ? 3 : programName[3]?.isValue == "Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                             
                            </Col>
                           
                          ))}
                            </Row>
                          
                           
                        </Row>
                      ) : item.title == "Ehliyet" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>Ehliyet</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <p><span style={{ fontWeight: "600" }}>Ehliyet Türü:</span>{" "}{programName[0]?.isValue}</p>
                              <p><span style={{ fontWeight: "600" }}>Ehliyet Süresi:</span>{" "}{programName[1]?.isValue}</p>
                            </div>
                          ))}
                        </Row>
                      ) : item.title == "İş Deneyimi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>İş Deneyimi</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <p><span style={{ fontWeight: "600" }}>Şirket İsmi:</span>{" "}{programName[0]?.isValue}</p>
                              <Row>
                                <Col className="col-4">
                                {programName[1]?.isValue?( <p><i className="bx bx-caret-right"></i>{programName[1]?.title} - {programName[1]?.isValue}</p>):null}
                                {programName[2]?.isValue?( <p><i className="bx bx-caret-right"></i>{programName[2]?.title} - {programName[2]?.isValue}</p>):null}
                             
                                </Col>
                                <Col className="col-4">
                                {programName[3]?.isValue?(<p ><i className="bx bx-caret-right"></i>{programName[3]?.title} - {programName[3]?.isValue}</p>):null}
                                {programName[4]?.isValue?(<p ><i className="bx bx-caret-right"></i>{programName[4]?.title} - {programName[4]?.isValue}</p>):null}
                              
                                </Col>
                                <Col className="col-4">
                                {programName[5]?.isValue?( <p><i className="bx bx-caret-right"></i> {programName[5]?.title}  {programName[5]?.isValue}</p>):null}
                                {programName[6]?.isValue?(<p><i className="bx bx-caret-right"></i> {programName[6]?.title}  {programName[6]?.isValue}</p>):null}
                              
                                </Col>
                              </Row>
                              
                             
                             

                            </div>
                          ))}
                        </Row>
                      ) : item.title == "Maaş Beklentisi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>Maaş Beklentisi</h2>
                          <hr />
                          <p>{item.isValue}</p>
                        </Row>
                      ) : null

                    ))}


                   
                  </Col>
                </Row>

                {selectedForm?.formStatus == "İşe Alınanlar" ? (
                  <Row>
                    <hr />
                    <Col md={3}>
                      <div className="mb-3">
                        <AvField
                          name="hiredDate"
                          label={props.t("İşe Alınma Tarihi")}
                          type="text"
                          disabled
                          value={handleValidDateAvField(selectedForm?.hiredDate)}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <AvField
                          name="duty"
                          label={props.t("Görevi")}
                          type="text"
                          disabled
                          value={selectedForm?.DutyId?.name}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <AvField
                          name="managerMail"
                          label={props.t("Manager Mail")}
                          type="text"
                          disabled
                          value={selectedForm?.managerMail}
                        />
                      </div>
                    </Col>


                  </Row>

                ) : (
                  <Row>
                    <FormButton
                      name={"Yeni Başvurular"}
                      checked={
                        selectedForm?.formStatus == "Yeni Başvurular"
                          ? true
                          : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"IK Havuzu"}
                      checked={
                        selectedForm?.formStatus == "IK Havuzu" ? true : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"Elenenler"}
                      checked={
                        selectedForm?.formStatus == "Elenenler" ? true : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"Görüşülecekler"}
                      checked={
                        selectedForm?.formStatus == "Görüşülecekler"
                          ? true
                          : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"Görüşülenler"}
                      checked={
                        selectedForm?.formStatus == "Görüşülenler" ? true : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"İş Teklif Edilecekler"}
                      checked={
                        selectedForm?.formStatus == "İş Teklif Edilecekler"
                          ? true
                          : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />

                    <FormButton
                      name={"Teklifi Reddedenler"}
                      checked={
                        selectedForm?.formStatus == "Teklifi Reddedenler"
                          ? true
                          : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                    <FormButton
                      name={"İşe Alınanlar"}
                      checked={
                        selectedForm?.formStatus == "İşe Alınanlar" ? true : false
                      }
                      isValue={(e, _body) => {
                        if (e == true) {
                          setFormsValue(_body.isValue)
                        }
                      }}
                    />
                  </Row>
                )}
                {selectedForm?.isFired == true ? (
                  <div>
                    <hr />
                    <Col md={3}>
                      <div className="mb-3">
                        <AvField
                          name="firedDate"
                          label={props.t("İşten Çıkarılma Tarihi")}
                          type="text"
                          disabled
                          value={handleValidDateAvField(selectedForm?.firedDate)}
                        />
                      </div>
                    </Col>
                  </div>
                ) : null}

                {descSuccess == true ? (
                  <Alert color="success">
                    {props.t("Your Evaluation Has Been Successfully Saved")}
                  </Alert>
                ) : null}
                {typeSelectFail == true ? (
                  <Alert color="danger">
                    {props.t("You Have To Select a Form Type")}
                  </Alert>
                ) : null}
                <Row className="mt-3">
                  <Col md="6" sm="12" xs="12" className="mb-2">
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary position-relative"
                        onClick={() => {
                          toggleDescription()
                        }}
                      >
                        {props.t("Comments2")}{" "}
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {selectedForm?.ik_notes.length}{" "}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </button>
                    </div>
                  </Col>
                  <Col md="6" sm="12" xs="12">
                    <div className="text-end mb-2">
                      {selectedForm?.formStatus == "İşe Alınanlar" && !selectedForm?.isFired ? (

                        <button style={{ backgroundColor: "rgb(244, 106, 106)", color: "white" }}
                          type="button"
                          className="btn  save-user me-2 mb-2"
                          onClick={() => {
                            setSelectedId(selectedForm?._id);
                            setconfirm_alert(true);


                          }}
                        >
                          {props.t("İşten Çıkar")}
                        </button>
                      ) : null}
                      {selectedForm?.formStatus == "İşe Alınanlar" ? (

                        <button style={{ backgroundColor: "rgb(244 173 106)", color: "white" }}
                          type="button"
                          className="btn  save-user me-2 mb-2"
                          onClick={() => {
                            toggleOrientation()


                          }}
                        >
                          {props.t("Orientation")}
                        </button>
                      ) : null}
                      {authorize(Role.Admin,Role.YetkiliIk)?(
                           <button style={{ backgroundColor: "#f46a6a", color: "white" }}
                           type="button"
                           className="btn  save-user me-2 mb-2"
                           onClick={() => {
                             toggleSurvey()
   
   
                           }}
                         >
                           {props.t("Organizational Commitment")}
                         </button>
                      ):null}
                   
                      <button
                        type="button"
                        className="btn btn-success save-user me-2 mb-2"
                        onClick={() => {
                          if (formsValue == "İşe Alınanlar") {
                            toggleAccepted()
                          } else {
                            const rejectPlan = {
                              _id: selectedForm._id,
                              status: true,
                              formStatus: formsValue,
                            }
                            if (!isEmpty(formsValue)) {
                              dispatch(onRejectAppForm(rejectPlan))
                              toggleForm()
                            } else {
                              setTypeSelectFail(true)
                            }
                          }

                        }}
                      >
                        {props.t("Ok")}
                      </button>

                      <Button
                        className="mb-2"
                        onClick={() => {
                          toggleForm()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal isOpen={descriptionModal} size="lg" toggle={toggleDescription}>
            <ModalHeader toggle={toggleDescription} tag="h4">
              {props.t("Comments2")}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidFormSubmitDesc}>
                <Row form>
                  <Col xl={12}>
                    <Alert color="danger" role="alert">
                      {props.t(
                        "You can give points out of 5 according to applicant soft skills (fine skills) and give your opinions about the applicant.You can write in the add comment section."
                      )}
                    </Alert>

                    {!isEmpty(evaluations) ? (
                      evaluations?.map((item, i) => (
                        <div key={"comments" + i}>
                          <Row className="mt-2">
                            <Col xs="1" sm="1" md="1" lg="1" xl="1">
                              <img
                                style={{ height: "60px!important" }}
                                className="rounded-circle header-profile-comment"
                                src={user1}
                                alt="Header Avatar"
                              />
                            </Col>
                            <Col xs="11" sm="11" md="11" lg="11" xl="11">
                              <Col md="12">
                                <b>{item?.UserName}</b>
                                <small> {item?.position}</small>
                              </Col>
                              <Col md="12">
                                <Row>
                                  <Col md="10">
                                    <td>{item.comment}</td>
                                    {item.rate?.map((rateObj, i) => (
                                        <p key={"commeasdnts" + i} style={{fontSize:"10px",marginBottom:"0px"}}>
                                          {rateObj?.softSkill}
                                          <i className="bx bxs-star" style={{color:"#ffc107"}}></i>
                                          <b style={{fontSize: "13px",fontWeight: "500",}}>{rateObj?.ratePoint}</b>
                                        </p>
                                      ))}
                                  </Col>
                                  <Col md="2">
                                    {" "}
                                    <p>
                                      {handleValidDate(item.createdAt)}
                                    </p>{" "}
                                  </Col>
                                </Row>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      ))
                    ) : (
                      <p>{props.t("No reviews yet")}</p>
                    )}
                    <hr />{" "}
                  </Col>
                  <Col xs={12}>
                    <div className="mb-3">
                      <AvField
                        name="ik_notes"
                        label={props.t("Add Comment")}
                        type="textarea"
                        value={""}
                      />
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <td
                            colSpan="5"
                            className="text-center"
                            style={{ fontWeight: "bold" }}
                          >
                            5 Puan Üzerinden değerlendirme kriterleri
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">5</td>
                          <td className="text-center">4</td>
                          <td className="text-center">3</td>
                          <td className="text-center">2</td>
                          <td className="text-center">1</td>
                        </tr>
                        <tr>
                          <td className="text-center">Çok İyi</td>
                          <td className="text-center">İyi</td>
                          <td className="text-center">Ortalama</td>
                          <td className="text-center">Ortalama Altı</td>
                          <td className="text-center">Zayıf</td>
                        </tr>
                      </tbody>
                    </table>
                    <Col>
                      <div>
                        <Row>
                          <Col
                            xl="3"
                            lg="3"
                            md="3"
                            sm="3"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h4>{props.t("Soft Skill")}</h4>
                            <hr />
                          </Col>
                        </Row>
                        {SoftSkills?.map((item, index) => (
                            <Row>

                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{item.name}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate => {
                                    handleChangeRate(index, rate)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>

                          ))}
                        {/* {!isEmpty(SoftSkills) ? (
                          SoftSkills?.map((item, index) => (
                            <Row>

                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{item.name}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate => {
                                    handleChangeRate(index, rate)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>

                          ))
                        ) : (
                          <div>
                            <Row>
                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{props.t("Diction")}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate => {
                                    setRate(rate)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{props.t("Positive Attitude")}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate2 => {
                                    setRate2(rate2)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{props.t("Appearance")}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate3 => {
                                    setRate3(rate3)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{props.t("Teamwork")}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate4 => {
                                    setRate4(rate4)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row> 
                            <Row>
                              <Col
                                xl="5"
                                lg="5"
                                md="5"
                                sm="5"
                                className="mt-2"
                                style={{ position: "relative", top: "2px" }}
                              >
                                <h5>{props.t("Motivation to Want the Job")}</h5>
                              </Col>
                              <Col
                                xl="1"
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                style={{ position: "relative" }}
                              >
                                <RatingTooltip
                                  max={5}
                                  onChange={rate5 => {
                                    setRate5(rate5)
                                  }}
                                  clearRating={false}
                                  tooltipContent={tooltipContentMore}
                                  ActiveComponent={
                                    <i
                                      className="mdi mdi-star text-warning"
                                      style={starStyle}
                                    />
                                  }
                                  InActiveComponent={
                                    <i
                                      className="mdi mdi-star-outline text-muted"
                                      style={starStyle}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </div>

                        )} */}

                        {/* <Row>
                          <Col
                            xl="5"
                            lg="5"
                            md="5"
                            sm="5"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h5>{props.t("Diction")}</h5>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            style={{ position: "relative" }}
                          >
                            <RatingTooltip
                              max={5}
                              onChange={rate => {
                                setRate(rate)
                              }}
                              clearRating={false}
                              tooltipContent={tooltipContentMore}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={starStyle}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={starStyle}
                                />
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl="5"
                            lg="5"
                            md="5"
                            sm="5"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h5>{props.t("Positive Attitude")}</h5>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            style={{ position: "relative" }}
                          >
                            <RatingTooltip
                              max={5}
                              onChange={rate2 => {
                                setRate2(rate2)
                              }}
                              clearRating={false}
                              tooltipContent={tooltipContentMore}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={starStyle}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={starStyle}
                                />
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl="5"
                            lg="5"
                            md="5"
                            sm="5"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h5>{props.t("Appearance")}</h5>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            style={{ position: "relative" }}
                          >
                            <RatingTooltip
                              max={5}
                              onChange={rate3 => {
                                setRate3(rate3)
                              }}
                              clearRating={false}
                              tooltipContent={tooltipContentMore}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={starStyle}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={starStyle}
                                />
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl="5"
                            lg="5"
                            md="5"
                            sm="5"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h5>{props.t("Teamwork")}</h5>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            style={{ position: "relative" }}
                          >
                            <RatingTooltip
                              max={5}
                              onChange={rate4 => {
                                setRate4(rate4)
                              }}
                              clearRating={false}
                              tooltipContent={tooltipContentMore}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={starStyle}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={starStyle}
                                />
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl="5"
                            lg="5"
                            md="5"
                            sm="5"
                            className="mt-2"
                            style={{ position: "relative", top: "2px" }}
                          >
                            <h5>{props.t("Motivation to Want the Job")}</h5>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            style={{ position: "relative" }}
                          >
                            <RatingTooltip
                              max={5}
                              onChange={rate5 => {
                                setRate5(rate5)
                              }}
                              clearRating={false}
                              tooltipContent={tooltipContentMore}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={starStyle}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={starStyle}
                                />
                              }
                            />
                          </Col>
                        </Row> */}
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success">
                        {props.t("Save")}
                      </button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          toggleDescription()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <QrCodeModal modal={modalQr} infoQr={process.env.REACT_APP_DOMAIN + "/avm/" + CompanyInfo} toggle={() => { toggleQr() }} QRphoto={CompanyQrPhoto} />
          < AcceptedModal modal={acceptedModal} toggle={() => { toggleAccepted() }} selectedForm={selectedForm}
            accept={e => {
              if (e == true) {
                toggleForm()
              }
            }} />
          <SelectSurveyModal modal={SurveyModal} mail={selectedForm?.email} userName={selectedForm?.firstName} companyName={CompanyName} formId={selectedForm?._id} toggle={() => { toggleSurvey() }} />
          <SelectOrientationModal modal={OrientationModal} mail={selectedForm?.email} userName={selectedForm?.firstName} companyName={CompanyName} formId={selectedForm?._id} toggle={() => { toggleOrientation() }} />
          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          {isRejected ? (
            <SweetAlert
              title={props.t("Are you sure you want to reject the Form?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Reject")}
              onConfirm={() => {
                setIsRejected(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Rejected"))
                setdynamic_description("")
                dispatch(onRejectAppForm(rejectPlan))
                toggleForm()
              }}
              onCancel={() => setIsRejected(false)}
            >
              {props.t(
                "You won't be able to revert this,A rejection mail will be sent to the person you rejected."
              )}
            </SweetAlert>
          ) : null} 

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("İşten Çıkar")}
              onConfirm={() => {
                setconfirm_alert(false);
                setsuccess_dlg(true);
                setdynamic_title(props.t("İşten Çıkarıldı"));
                setdynamic_description("");
                dispatch(onFiredAppForm(selectedId));
                setSelectedId(null);
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {FileMove ? (
            <SweetAlert
              success
              title={"Form İstenen Dosyaya Taşındı"}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetAppForm())
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirmDelete ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setConfirmDelete(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteAppForm(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setConfirmDelete(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
