/** Get SOFT_SKILL */
export const GET_SOFT_SKILLS = "GET_SOFT_SKILLS";
export const GET_SOFT_SKILLS_SUCCESS = "GET_SOFT_SKILLS_SUCCESS";
export const GET_SOFT_SKILLS_FAIL = "GET_SOFT_SKILLS_FAIL";

export const GET_SOFT_SKILL_DETAIL = "GET_SOFT_SKILL_DETAIL";
export const GET_SOFT_SKILL_DETAIL_SUCCESS = "GET_SOFT_SKILL_DETAIL_SUCCESS";
export const GET_SOFT_SKILL_DETAIL_FAIL = "GET_SOFT_SKILL_DETAIL_FAIL";


export const ADD_SOFT_SKILL = "ADD_SOFT_SKILL";
export const ADD_SOFT_SKILL_SUCCESS = "ADD_SOFT_SKILL_SUCCESS";
export const ADD_SOFT_SKILL_FAIL = "ADD_SOFT_SKILL_FAIL";

export const UPDATE_SOFT_SKILL = "UPDATE_SOFT_SKILL";
export const UPDATE_SOFT_SKILL_SUCCESS = "UPDATE_SOFT_SKILL_SUCCESS";
export const UPDATE_SOFT_SKILL_FAIL = "UPDATE_SOFT_SKILL_FAIL";

export const DELETE_SOFT_SKILL = "DELETE_SOFT_SKILL";
export const DELETE_SOFT_SKILL_SUCCESS = "DELETE_SOFT_SKILL_SUCCESS";
export const DELETE_SOFT_SKILL_FAIL = "DELETE_SOFT_SKILL_FAIL"; 



export const RESET_SOFT_SKILL= "RESET_SOFT_SKILL";