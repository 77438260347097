import {
    GET_COMPANY_FORM_BY_DATE_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_FAIL,
    GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL,
    GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS,
    GET_COMPANY_FORM_BY_DATE_MONTH_FAIL,
    GET_COMPANY_QR_BY_DATE_SUCCESS,
    GET_COMPANY_QR_BY_DATE_FAIL,
    GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL,
    GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS,
    GET_COMPANY_QR_BY_DATE_MONTH_FAIL,
    GET_COMPANY_QR_BY_DATE_ALL_SUCCESS,
    GET_COMPANY_QR_BY_DATE_ALL_FAIL,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,
    GET_BRANCH_FORM_BY_DATE_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_FAIL,
    GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL,
    GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS,
    GET_BRANCH_FORM_BY_DATE_MONTH_FAIL,
    GET_BRANCH_QR_BY_DATE_SUCCESS,
    GET_BRANCH_QR_BY_DATE_FAIL,
    GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL,
    GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS,
    GET_BRANCH_QR_BY_DATE_MONTH_FAIL,
    GET_BRANCH_QR_BY_DATE_ALL_SUCCESS,
    GET_BRANCH_QR_BY_DATE_ALL_FAIL,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS,
    GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL,

    GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_FAIL,
    GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL,
    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL,
    GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS, 
    GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL,

    GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_FAIL,
    GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL,
    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS,
    GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL,
    GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS, 
    GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL,
    RESET_BRAND
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    companyFormToday :[],
    companyFormYesterday :[],
    companyFormMonth :[],
    companyQrToday :[],
    companyQrYesterday :[],
    companyQrMonth :[],
    companyQrAll :[],
    companyFormIsMatchToday :[],
    companyFormIsMatchYesterday :[],
    companyFormIsMatchMonth :[],
    branchFormToday :[],
    branchFormYesterday :[],
    branchFormMonth :[],
    branchQrToday :[],
    branchQrYesterday :[],
    branchQrMonth :[],
    branchQrAll :[],
    branchFormIsMatchToday :[],
    branchFormIsMatchYesterday :[],
    branchFormIsMatchMonth :[],
    
    companyShareQrToday :[],
    companyShareQrYesterday :[],
    companyShareQrMonth :[],
    companyShareQrAll :[],

    branchShareQrToday :[],
    branchShareQrYesterday :[],
    branchShareQrMonth :[],
    branchShareQrAll :[],
};
 
const Brands = (state = INIT_STATE, action) => {
    switch (action.type) {
         //---------BRANCH SHARE QR-----------
         case GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS:
            return { 
                ...state,
                branchShareQrAll: action.payload.data
            }
        case GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS:
            return { 
                ...state,
                branchShareQrToday: action.payload.data
            }
        case GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                branchShareQrYesterday: action.payload.data
            } 
        case GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS:
            return {  
                ...state,
                branchShareQrMonth: action.payload.data
            }   
         //---------COMPANY SHARE QR-----------
         case GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS:
            return { 
                ...state,
                companyShareQrAll: action.payload.data
            }
        case GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS:
            return { 
                ...state,
                companyShareQrToday: action.payload.data
            }
        case GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                companyShareQrYesterday: action.payload.data
            } 
        case GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS:
            return {  
                ...state,
                companyShareQrMonth: action.payload.data
            }   
         //-----------FORMS IS MATCH-----------------
         case GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS:
            return { 
                ...state,
                companyFormIsMatchToday: action.payload.data
            }
        case GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                companyFormIsMatchYesterday: action.payload.data
            } 
        case GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS:
            return { 
                ...state,
                companyFormIsMatchMonth: action.payload.data
            }    
        //---------QR-----------
        case GET_COMPANY_QR_BY_DATE_ALL_SUCCESS:
            return { 
                ...state,
                companyQrAll: action.payload.data
            }
        case GET_COMPANY_QR_BY_DATE_SUCCESS:
            return { 
                ...state,
                companyQrToday: action.payload.data
            }
        case GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                companyQrYesterday: action.payload.data
            } 
        case GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS:
            return {  
                ...state,
                companyQrMonth: action.payload.data
            }   
        //-----------FORMS-----------------
        case GET_COMPANY_FORM_BY_DATE_SUCCESS:
            return { 
                ...state,
                companyFormToday: action.payload.data
            }
        case GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                companyFormYesterday: action.payload.data
            } 
        case GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS:
            return { 
                ...state,
                companyFormMonth: action.payload.data
            }    
             //-----------FORMS IS MATCH-----------------
         case GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS:
            return { 
                ...state,
                branchFormIsMatchToday: action.payload.data
            }
        case GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                branchFormIsMatchYesterday: action.payload.data
            } 
        case GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS:
            return { 
                ...state,
                branchFormIsMatchMonth: action.payload.data
            }    
        //---------QR-----------
        case GET_BRANCH_QR_BY_DATE_ALL_SUCCESS:
            return { 
                ...state,
                branchQrAll: action.payload.data
            }
        case GET_BRANCH_QR_BY_DATE_SUCCESS:
            return { 
                ...state,
                branchQrToday: action.payload.data
            }
        case GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                branchQrYesterday: action.payload.data
            } 
        case GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS:
            return {  
                ...state,
                branchQrMonth: action.payload.data
            }   
        //-----------FORMS-----------------
        case GET_BRANCH_FORM_BY_DATE_SUCCESS:
            return { 
                ...state,
                branchFormToday: action.payload.data
            }
        case GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS:
            return { 
                ...state,
                branchFormYesterday: action.payload.data
            } 
        case GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS:
            return { 
                ...state,
                branchFormMonth: action.payload.data
            }    
        //FAILS
        case GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL:
        case GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL:
        case GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL: 
        case GET_COMPANY_QR_BY_DATE_FAIL:
        case GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL:
        case GET_COMPANY_QR_BY_DATE_MONTH_FAIL: 
        case GET_COMPANY_FORM_BY_DATE_FAIL:
        case GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL:
        case GET_COMPANY_FORM_BY_DATE_MONTH_FAIL: 
        case GET_COMPANY_QR_BY_DATE_ALL_FAIL:
        case GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL:
        case GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL:
        case GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL: 
        case GET_BRANCH_QR_BY_DATE_FAIL:
        case GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL:
        case GET_BRANCH_QR_BY_DATE_MONTH_FAIL: 

        case GET_BRANCH_FORM_BY_DATE_FAIL:
        case GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL:
        case GET_BRANCH_FORM_BY_DATE_MONTH_FAIL: 
        case GET_BRANCH_QR_BY_DATE_ALL_FAIL:

        case GET_COMPANY_SHARE_QR_BY_DATE_FAIL:
        case GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL:
        case GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL: 
        case GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL:

        case GET_BRANCH_SHARE_QR_BY_DATE_FAIL:
        case GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL:
        case GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL: 
        case GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL:

            return {
                ...state,
                error: action.payload
            }

        case RESET_BRAND: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Brands;