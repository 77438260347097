import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
    Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
    FormGroup, Alert,Button, Nav,
    NavItem,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,
    NavLink,Card, CardBody,
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from "classnames"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  
  sendOrientationMail as onSendMail,
  resetOrientationSent as onResetSurveySent,
  getOrientationSents as onGetSurveySent, 
  addSurveyForm as onAddSurveyForm 
} from "store/actions" 
import { FALSE } from "sass"

const selectOrientation = (props, cb) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch()
    const [selectedBranchId, setSelectedBranchId] = useState("")
    const [activeTab, setactiveTab] = useState("1")
    const [selectedSurvey, setSelectedSurvey] = useState()

    const [selectedPlanObject, setSelectedPlanObject] = useState([])
    const [checkSurveyModal, setCheckSurveyModal] = useState(false)
    let user = JSON.parse(localStorage.getItem("authUser"))
    const {  Orientation,SurveyForm,SurveySent,MailSuccess } = useSelector(state => ({ 
    
      Orientation: state.Orientation?.data,
        SurveyForm : state.OrientationForm?.data,
        SurveySent: state.OrientationSent?.data,
        MailSuccess : state.OrientationSent?.success
      })) 
      useEffect(() => { 
        
       if(props.formId){
        dispatch(onGetSurveySent(props.formId));
       }
        
         
        
    }, [dispatch,activeTab]);
      const toggleForm = () => {
        setCheckSurveyModal(!checkSurveyModal)
      }
     
      const columns = [
        {
            dataField: 'name',
            text:  props.t("Name"),
            sort: true
            
        }, {
          dataField: "createdAt",
          text: props.t("Gönderme Tarihi"),
          sort: true,
          formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
        },
        {
          dataField: "sendDate",
          text: props.t("Doldurma Tarihi"),
          sort: true,
          formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
        },
       
        {
          dataField: "planInfo",
          text: props.t("Applications Forms"),
          formatter: (cell, row) => 
        
              <div>
                {row.PlanObjects[0]?.isType=="onayliMetin"?(
                    <i className="bx bx-comment-check"></i>
                ):(
 !isEmpty(row.PlanObjects)?
  <Button style={{backgroundColor:"#f46a6a",color:"white",borderColor:"white"}}
  
  className="btn-rounded "
  onClick={() => {
      setSelectedSurvey(row)
      setSelectedPlanObject(row.PlanObjects)
  //   setEditInfo(row)
     toggleForm()
  //   setCheckFormModal(true)

   

   
  }}
>
  {props.t("Formu Gör")}
</Button>
  :null
                )}
               
              
            </div>
            
           
          ,
        },
        
        
      
       ]; 
       const handleValidDate = date => {
        if (date) {
          const date1 = moment(new Date(date)).format("DD.MM.YYYY")
          return date1
        } else {
          return ""
        }
      } 

        const defaultSorted = [{ 
          dataField: 'createdAt',
          order: 'desc'
        }];
        
        const pageOptions = {
          sizePerPage: 10,
          totalSize:SurveyForm ? SurveyForm.length : 0, // replace later with size(customers),
          custom: true,
        }

      
  
    
    return (
      <React.Fragment>
          <Modal isOpen={props.modal} size="xl"  toggle={props.toggle} >
        <ModalHeader toggle={props.toggle}  tag="h4">
          {props.t("Orientation Forms")}
        </ModalHeader>
        <ModalBody> 
          <Row>
            
            <Col className="col-3">
            <div className="mail-list">
            <Nav tabs className="nav-tabs-custom" vertical role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    setactiveTab("1")
                  }}
                >
                  <i className="mdi mdi-email-outline me-2"></i> Form Gönder{" "}

                </NavLink>
              </NavItem>

             

              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    setactiveTab("3")
                  }}
                >
                  <i className="mdi mdi-diamond-stone me-2"></i>Form Sonuçları
                </NavLink>
              </NavItem>
            </Nav>
          </div>
            </Col>
            <Col className="col-9">
            <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AvForm>
                <Col className="col-12 mb-3">

                  <AvField 
                    className="form-select"
                    type="select"
                    name="BranchId"
                    value={selectedBranchId}
                    onChange={e => {
                      setSelectedSurvey(e.target.value) 
                     
                    }}
                  >
                    <option>{props.t("Form Seç")}</option>
                    {Orientation?.map((item, index) => (
                      <option key={index} value={[item._id,item.name]} >
                        {item.name}
                      </option>
                    ))}
                  </AvField>
                </Col>
                <div className="text-end">
                  <Button
                    className="btn btn-success save-user me-2"
                    onClick={() => {
                     

                      const updatePlan = {
                        email:props.mail,
                        formId:props.formId,
                        userName:props.userName,
                        companyName:props.companyName,
                        orientationId:selectedSurvey?.split(',')[0],
                        name:selectedSurvey?.split(',')[1],
                        companyId:user?.companyId
                        
                      }
                    
                      dispatch(onSendMail(updatePlan)) 
                  
                      
                      
                    }}
                  >
                    {props.t("Send")}
                  </Button>
                </div>
              </AvForm>
            </TabPane>
           
            <TabPane tabId="3"> 
        
            <Card>
                <CardBody>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={SurveyForm}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField='_id'
                                columns={columns}
                                data={SurveyForm}
                               
                            >
                                {toolkitProps => (
                                    <React.Fragment>
                                       <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={"_id"}
                                                        responsive
                                                        bordered={false}
                                                        noDataIndication={props.t(
                                                          "Henüz Gelen Bir Anket Yok"
                                                        )}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                                }
                            </ToolkitProvider>
                        )
                        }</PaginationProvider>

                   

                
                </CardBody>
            </Card>
            </TabPane>
            </TabContent>
            </Col>
          
         
          </Row>
         

        </ModalBody>
       
      </Modal>
         <Modal isOpen={checkSurveyModal} size="lg" toggle={toggleForm}>
            <ModalHeader toggle={toggleForm} tag="h4">
              {props.t("Orientation")}
            </ModalHeader>
            <ModalBody className="modal-scroll">
              <AvForm >
                <Row form>
                  <Col xs={12}>
                  
                   
                   
                   
                    {map(selectedPlanObject, (item, index) => (
                      <div className="mb-3" key={"h_" + index}>
                        {item.isType === "fourchoice" ? (
                           <div className="mt-3">
                           <h4 className="mt-5 mb-2">{item.title}</h4>
                            {map(item?.isOptions, (itemOpt, indexOpt) => (
                           <div className="mb-2" key={"h_" + index + "dd" + indexOpt}>
                             <Col className="col-10 mt-1">
   
                               <div className="form-check mb-3">
                                 <input
                                   className="form-check-input"
                                   type="radio"
                                   disabled
                                   name={index + "exampleRadios"}
                                   id={index + itemOpt + indexOpt}
                                   value={index + itemOpt + indexOpt}
                                    checked={itemOpt == item?.isValue?true:false}
                                  
                                 />
                                 <label
                                   className="form-check-label"
                                   htmlFor={index + itemOpt + indexOpt}
                                 >
                                   {itemOpt}
                                 </label>
                               </div>
   
                             </Col>
   
                           </div>
                         ))}
                         </div>
                          // <AvField
                          //   key={"h1_" + index}
                          //   id={"planObjects" + index + "__isValue"}
                          //   name={"planObjects[" + index + "].isValue"}
                          //   type="text"
                          //   disabled
                          //   label={item.title}
                          //   value={item.isValue}
                          // />
                        ) :item.isType === "fourSelect" ? (
                          <div className="mt-3">
                          <h4 className="mt-5 mb-2">{item.title}</h4>
                           {map(item?.isOptions, (itemOpt, indexOpt) => (
                          <div className="mb-2" key={"h_" + index + "dd" + indexOpt}>
                            <Col className="col-10 mt-1">
  
                            <div className="form-check mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"h_" + index + "dd" + indexOpt}
                             
                              disabled
                              checked= {item?.isPlan.includes(itemOpt)?true:false}
                              
                            /> 
                            <Label
                              className="form-check-label"
                              htmlFor={"h_" + index + "dd" + indexOpt}
                            >
                              {itemOpt}
                            </Label>
                          </div>
  
                            </Col>
  
                          </div>
                        ))}
                        </div>
                          // <AvField 
                          //   key={"h1_" + index}
                          //   id={"planObjects" + index + "__isValue"}
                          //   name={"planObjects[" + index + "].isValue"}
                          //   type="text"
                          //   disabled
                          //   label={item.title}
                          //   value={item.isPlan}
                          // />
                        )
                        :item.isType === "onayliMetin" ? (
                          <div>
                            <p><i className="bx bx-comment-check"></i>{item?.isOptions} </p>
                          
                          </div>
                          
                        ):item.isType === "sadeceMetin" ? (
                          <div>
                          <p><i className="bx bx-comment-check"></i>{item?.isOptions} </p>
                        
                        </div>
                        ) 
                        : 
                         item.isType === "longText" ? (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="textarea"
                            disabled
                            label={item.title}
                            value={item.isValue}
                          />
                        ) : item.isType === "title" ? (
                          <div>
                            <br />
                            <h4>{item.title}</h4>
                            <hr />
                          </div>
                        ) : item.isType === "group" ||
                          item.isType === "singleGroup" ? (
                          <Row>
                            <Col md={5}>
                              <div>
                                <h4>{props.t(item.title)}</h4>
                              </div>
                              <hr />
                            </Col>

                            {typeof item.isValue == "object" &&
                              item.isValue.map((itemArray, indexArray) => (
                                <Row key={"g_" + index + "_" + indexArray}>
                                  {typeof itemArray == "object" &&
                                    itemArray.map((groupItem, indexGroup) => (
                                      <Col
                                        md={
                                          itemArray.length == 1
                                            ? 12
                                            : itemArray.length == 2
                                              ? 6
                                              : 4
                                        }
                                        className="mb-2"
                                        key={
                                          "d_" +
                                          index +
                                          "_" +
                                          indexArray +
                                          "_" +
                                          indexGroup
                                        }
                                      >
                                        {groupItem.isType === "boolean" ? (
                                          <AvField
                                            key={
                                              "g_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup
                                            }
                                            id={
                                              "planObjectsGroup_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup +
                                              "__isValue"
                                            }
                                            name={
                                              "planObjectsGroup_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup +
                                              ".isValue"
                                            }
                                            type="checkbox"
                                            label={groupItem.title}
                                            disabled
                                            value={
                                              groupItem.isValue == ""
                                                ? false
                                                : groupItem.isValue
                                            }
                                          />
                                        ) : (
                                          <AvField
                                            key={
                                              "g_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup
                                            }
                                            id={
                                              "planObjectsGroup_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup +
                                              "__isValue"
                                            }
                                            name={
                                              "planObjectsGroup_" +
                                              index +
                                              "_" +
                                              indexArray +
                                              "_" +
                                              indexGroup +
                                              ".isValue"
                                            }
                                            type="text"
                                            label={groupItem.title}
                                            placeholder={groupItem.title}
                                            value={groupItem.isValue}
                                            disabled
                                          />
                                        )}
                                      </Col>
                                    ))}
                                </Row>
                              ))}
                          </Row>
                        ) : item.isType === "date" ? (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="date"
                            disabled
                            label={item.title}
                            value={handleValidDateAvField(item.isValue)}
                          />
                        ) : item.isType === "number" ? (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="number"
                            disabled
                            label={item.title}
                            value={item.isValue}
                          />
                        ) : item.isType === "boolean" ? (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="checkbox"
                            disabled
                            label={item.title}
                            value={item.isValue == "" ? false : item.isValue}
                          />
                        ) : item.isType === "dropdown" ? (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="text"
                            disabled
                            label={item.title}
                            value={item.isValue}
                          />
                        ) : (
                          <AvField
                            key={"h1_" + index}
                            id={"planObjects" + index + "__isValue"}
                            name={"planObjects[" + index + "].isValue"}
                            type="text"
                            label={item.title}
                            disabled
                            value={item.isValue}
                          />
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>

               
               
               
                <Row className="mt-3">
                  
                  <Col>
                    <div className="text-end">
                  
                     

                      <Button
                        onClick={() => {
                          toggleForm()
                        }}
                      >
                        {props.t("Ok")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            
            </ModalBody>
          </Modal>
          {MailSuccess == true? (
                        <SweetAlert
                            success
                            title={"Mail Gönderildi"}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                               dispatch(onResetSurveySent())
                            }}
                        >
                            Mail Gönderildi
                        </SweetAlert>
                    ) : null}
        </React.Fragment>
      
    )
}


selectOrientation.propTypes = {

   
    isValue: PropTypes.func,
    t: PropTypes.any,
    setSwitch: PropTypes.any,
    switch: PropTypes.any,
    mail:PropTypes.any,
    userName:PropTypes.any,
    companyName:PropTypes.any,
    formId:PropTypes.any
}


export default withRouter(
    (withTranslation()(selectOrientation))
)


