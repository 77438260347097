import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_REQUEST_FORM_PLANS, DELETE_REQUEST_FORM_PLAN, ADD_REQUEST_FORM_PLAN, UPDATE_REQUEST_FORM_PLAN,UPDATE_ACTION,UPDATE_STATUS,GET_REQ_FORMS_DOWN} from "./actionTypes";
import {
    getRequestFormPlansSuccess, getRequestFormPlansFail,
    deleteRequestFormPlanSuccess, deleteRequestFormPlanFail,
    updateRequestFormPlanSuccess, updateRequestFormPlanFail,
    addRequestFormPlanSuccess, addRequestFormPlanFail,
    updateActionSuccess,updateActionFail,
    updateStatusSuccess,updateStatusFail,
    getReqFormsDownSuccess,getReqFormsDownFail


 } from "./actions";

//Include Both Helper File with needed methods
import {
    getRequestFormPlans, //API CONNECTION
    delRequestFormPlan,
    addRequestFormPlan,
    updateRequestFormPlan,
    updateActions,
    updateStatus,
    getReqFormsDown
   
     
} 
    from "../../helpers/fakebackend_helper"; 
function* fetchRequestFormsDown({ payload: branchId}) { 
    try { 
        const response = yield call(getReqFormsDown,branchId)
        yield put(getReqFormsDownSuccess(response));
    } catch (error) {
        yield put(getReqFormsDownFail(error));
    }
}
function* fetchRequestFormPlans() {
    try {
        const response = yield call(getRequestFormPlans)
        yield put(getRequestFormPlansSuccess(response));
    } catch (error) {
        yield put(getRequestFormPlansFail(error));
    }
}

function* onDeleteRequestFormPlans({payload:id}){
    try{
        const response = yield call(delRequestFormPlan, id)
        yield put(deleteRequestFormPlanSuccess(response));
    } catch (error) {
        yield put(deleteRequestFormPlanFail(error));
    }
}

function* onAddRequestFormPlan({payload:requestFormPlan}){
  
    try{
        const response = yield call(addRequestFormPlan, requestFormPlan)
        yield put(addRequestFormPlanSuccess(response));
    } catch (error) {
        yield put(addRequestFormPlanFail(error));
    }
}

function* onUpdateRequestFormPlan({payload:requestFormPlan }){ 
    try{
        const response = yield call(updateRequestFormPlan, requestFormPlan)
        yield put(updateRequestFormPlanSuccess(response));
    } catch (error) {
        yield put(updateRequestFormPlanFail(error));
    }
}
function* onUpdateActions({payload:requestFormPlan }){ 
    try{
        const response = yield call(updateActions, requestFormPlan)
        yield put(updateActionSuccess(response));
    } catch (error) {
        yield put(updateActionFail(error));
    }
}
function* onUpdateStatus({payload:requestFormPlan }){ 
    try{
        const response = yield call(updateStatus, requestFormPlan)
        yield put(updateStatusSuccess(response));
    } catch (error) {
        yield put(updateStatusFail(error));
    }
}


export function* requestFormPlansSaga() {
    yield takeEvery(GET_REQUEST_FORM_PLANS, fetchRequestFormPlans);
    yield takeEvery(DELETE_REQUEST_FORM_PLAN, onDeleteRequestFormPlans);
    yield takeEvery(ADD_REQUEST_FORM_PLAN, onAddRequestFormPlan);
    yield takeEvery(UPDATE_REQUEST_FORM_PLAN, onUpdateRequestFormPlan);
    yield takeEvery(UPDATE_ACTION, onUpdateActions);
    yield takeEvery(UPDATE_STATUS, onUpdateStatus);
    yield takeEvery(GET_REQ_FORMS_DOWN, fetchRequestFormsDown);
   
}

export default requestFormPlansSaga;
