import React from "react"

import PropTypes from 'prop-types'
import { Container, Row, Col ,Card,CardBody} from "reactstrap" 
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
const ReportCard = props => {
  return ( 
    <React.Fragment>
       <Card style={{ borderRadius: "12px", border: props.border, wordBreak: "break-all" }}>
                <CardBody style={{ position: "relative", paddingTop:'10px', paddingBottom:'10px' }}>
                <div className="d-flex align-items-center">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className={props.icon} style={{color:props.iconColor}} /> 
                      </span></div>
                    <Col> 
                    <h5 className="font-size-14 mb-0">
                    {props.title}</h5>
                    </Col></div>
                
                    <div >
                       

                        <hr style={{ color: props.hrColor, height: "2px" }} />

                        <Row style={{ position: "relative", bottom: "5px" }}>
                            <Col xl="4" lg="4" md="3" sm="3" xs="3">
                                <p className="preview-card mb-2">{props.t("Bugün")}</p>
                            </Col>
                            <Col xl="2" lg="2" md="2" sm="2" xs="2" className="text-center">
                                <p className="preview-card mb-2">
                                    {props.todayAmount}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3" xs="3" className="text-end p-0">
                                <p className="preview-card mb-2" >
                                    {props.todayCiro}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="4" xs="4" className="text-end p-0" >
                                <p className="preview-card mb-2" >{props.todayProfit}</p>
                            </Col>

                        </Row>
                        <Row style={{ position: "relative", bottom: "5px" }}>
                            <Col xl="4" lg="4" md="3" sm="3" xs="3">
                                <p className="preview-card mb-2">{props.t("Dün")}</p>
                            </Col>
                            <Col xl="2" lg="2" md="2" sm="2" xs="2" className="text-center">
                                <p className="preview-card mb-2">
                                    {props.yesterdayAmount}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3" xs="3" className="text-end p-0">
                                <p className="preview-card mb-2" >
                                    {props.yesterdayCiro}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="4" xs="4" className="text-end p-0" >
                                <p className="preview-card mb-2" > {props.yesterdayProfit}</p>
                            </Col>

                        </Row>
                        <Row style={{ position: "relative", bottom: "5px" }}>
                            <Col xl="4" lg="4" md="3" sm="3" xs="3">
                                <p className="preview-card mb-2">{props.t("Bu Ay")}</p>
                            </Col>
                            <Col xl="2" lg="2" md="2" sm="2" xs="2" className="text-center">
                                <p className="preview-card mb-2">
                                    {props.thisMonthAmount}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3" xs="3" className="text-end p-0">
                                <p className="preview-card mb-2" >
                                    {props.thisMonthCiro}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="4" xs="4" className="text-end p-0" >
                                <p className="preview-card mb-2" > {props.thisMonthProfit}</p>
                            </Col>

                        </Row>
                        <Row style={{ position: "relative", bottom: "5px" }}>
                            <Col xl="4" lg="4" md="3" sm="3" xs="3">
                                <p className="preview-card mb-2">{props.t("Tüm Zamanlar")}</p>
                            </Col>
                            <Col xl="2" lg="2" md="2" sm="2" xs="2" className="text-center">
                                <p className="preview-card mb-2">
                                    {props.lastMonthAmount}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3" xs="3" className="text-end p-0">
                                <p className="preview-card mb-2" >
                                    {props.lastMonthCiro}</p>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="4" xs="4" className="text-end p-0" >
                                <p className="preview-card mb-2" > {props.lastMonthProfit}</p>
                            </Col>

                        </Row>

 

                    </div>


                </CardBody>
            </Card>
    </React.Fragment>
  )
} 


ReportCard.propTypes = {
  props: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.number,
  border:PropTypes.any,
  icon:PropTypes.any,
  iconColor:PropTypes.any,
  todayAmount: PropTypes.any,
  yesterdayAmount: PropTypes.any,
  thisMonthAmount: PropTypes.any,
  lastMonthAmount: PropTypes.any,
  todayCiro: PropTypes.any,
  yesterdayCiro: PropTypes.any,
  thisMonthCiro: PropTypes.any,
  lastMonthCiro: PropTypes.any,
  todayProfit: PropTypes.any,
  yesterdayProfit: PropTypes.any,
  thisMonthProfit: PropTypes.any,
  lastMonthProfit: PropTypes.any,
 
}

export default withRouter(
  (withTranslation()(ReportCard))
)


