import {
    GET_ORIENTATON_SENTS,
    GET_ORIENTATON_SENTS_SUCCESS,
    GET_ORIENTATON_SENTS_FAIL,
    GET_ORIENTATON_SENT_DETAIL,
    GET_ORIENTATON_SENT_DETAIL_SUCCESS,
    GET_ORIENTATON_SENT_DETAIL_FAIL,
    ADD_ORIENTATON_SENT,
    ADD_ORIENTATON_SENT_SUCCESS,
    ADD_ORIENTATON_SENT_FAIL,
    UPDATE_ORIENTATON_SENT,
    UPDATE_ORIENTATON_SENT_SUCCESS,
    UPDATE_ORIENTATON_SENT_FAIL,
    DELETE_ORIENTATON_SENT,
    DELETE_ORIENTATON_SENT_SUCCESS,
    DELETE_ORIENTATON_SENT_FAIL,
    RESET_ORIENTATON_SENT,
    SEND_ORIENTATON_MAIL,
    SEND_ORIENTATON_MAIL_SUCCESS,
    SEND_ORIENTATON_MAIL_FAIL,
    
} from "./actionTypes";

export const sendOrientationMail = (email) => {
    return {
      type: SEND_ORIENTATON_MAIL,
      payload: email, 
    }
  }
  
  export const sendOrientationMailSuccess = (data) => {
    return {
      type: SEND_ORIENTATON_MAIL_SUCCESS,
      payload: data,
    }
  } 
  
  export const sendOrientationMailFail = (message) => {
    return {
      type: SEND_ORIENTATON_MAIL_FAIL,
      payload: message,
    }}
export const resetOrientationSent = () => ({
    type: RESET_ORIENTATON_SENT,
});

export const getOrientationSents = (appSentId) => ({
    type: GET_ORIENTATON_SENTS,
    payload: appSentId,
});

export const getOrientationSentsSuccess = (orientationSent) => ({
    type: GET_ORIENTATON_SENTS_SUCCESS,
    payload: orientationSent,
});
 

export const getOrientationSentsFail = (error) => ({
    type: GET_ORIENTATON_SENTS_FAIL,
    payload: error,
});

export const deleteOrientationSent = (id) => ({
    type:  DELETE_ORIENTATON_SENT,
    payload: id
});

export const deleteOrientationSentSuccess = (data) => ({
    type: DELETE_ORIENTATON_SENT_SUCCESS,
    payload: data
});

export const deleteOrientationSentFail = (error) => ({
    type: DELETE_ORIENTATON_SENT_FAIL,
    payload: error,
});


export const addOrientationSent = (orientationSent) => ({
    type: ADD_ORIENTATON_SENT,
    payload: orientationSent
});

export const addOrientationSentSuccess = (data) => ({
    type: ADD_ORIENTATON_SENT_SUCCESS,
    payload: data
});

export const addOrientationSentFail = (error) => ({
    type: ADD_ORIENTATON_SENT_FAIL,
    payload: error,
});


export const updateOrientationSent = (orientationSent) => ({
    type: UPDATE_ORIENTATON_SENT,
    payload: orientationSent
});

export const updateOrientationSentSuccess = (data) => ({
    type: UPDATE_ORIENTATON_SENT_SUCCESS,
    payload: data
});

export const updateOrientationSentFail = (error) => ({
    type: UPDATE_ORIENTATON_SENT_FAIL,
    payload: error,
});