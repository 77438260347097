import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Col, Container, Form,Alert, FormGroup, Label, Row, Input } from "reactstrap"


import logodark from "../../assets/images/bqr2.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import CarouselPage from "./CarouselPage"
 
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"  

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  const dispatch = useDispatch()

  const { error,Company,user,registrationError} = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    error: state.Login.error,
    Company : state.Users?.User?.companyId,
    
  }))
  
  // handleValidSubmit
  const handleValidSubmit = async(event, values) => {
  
    
    dispatch(loginUser(values, props.history))
   

  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
    <div>
    <MetaTags>
       <title>Login 2 | Bu QR'da İş Var</title>
     </MetaTags>
       <Container fluid className="p-0">
         <Row className="g-0">
           <CarouselPage />

           <Col xl={3}>
             <div className="auth-full-page-content p-4">
               <div className="w-100">
                 <div >
                   <div >
                     <Link to="/" className="d-block auth-logo">
                       <img
                         src={logodark}
                         alt=""
                         height="160"
                         className="auth-logo-dark"
                         style={{position:"relative", right:"21px"}}
                       />
                       <img
                         src={logolight}
                         alt=""
                         height="225"
                         className="auth-logo-light"
                       />
                     </Link>
                   </div>
                   <div className="my-auto">
                     <div>
                       <h5 className="text-primary">Merhabalar</h5>
                       <p className="text-muted">
                       Kullanmaya başlamak için oturum açın.
                       </p>
                     </div>

                     <div className="mt-4">

                       
                    <AvForm
                   className="form-horizontal"
                   onValidSubmit={(e, v) => {
                     handleValidSubmit(e, v)
                   }}
                 >
                   

                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                     <div className="mb-3">
                       <AvField
                         name="email"
                         label="Email"
                         className="form-control"
                         placeholder="Email Adresinizi Girin"
                         type="email"
                         required
                       />
                     </div> 
                     <div className="mb-3">
                     <div className="float-end">
                             <Link
                               to="forgot-password"
                               className="text-muted"
                             >
                              Şifremi Unuttum
                             </Link>
                           </div>
                       <AvField
                         name="password"
                         label="Şifre"
                         className="form-control"
                         placeholder="Şifrenizi Girin"
                         type="password"
                         required
                       />
                     </div>

                     <div className="form-check">
                           <Input
                             type="checkbox"
                             className="form-check-input"
                             id="auth-remember-check"
                           />
                           <label
                             className="form-check-label"
                             htmlFor="auth-remember-check"
                           >
                             Şifremi Hatırla
                           </label>
                         </div>

                         <div className="mt-3 d-grid">
                           <button
                             className="btn btn-primary btn-block "
                             type="submit"
                           >
                            Devam
                           </button>
                         </div>
                     
                     </AvForm>

                       
                       <div className="mt-5 text-center">
                         <p>
                         Hesabınız yok mu?{" "}
                           <Link
                             to="register"
                             className="fw-medium text-primary"
                           >
                             {" "}
                             Kayıt Ol{" "}
                           </Link>{" "}
                         </p>
                       </div>
                     </div>
                   </div>

                  
                 </div>
               </div>
             </div>
           </Col>
         </Row>
       </Container>
     </div>
 </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
