import {
    GET_PARTNERSHIPS,
    GET_PARTNERSHIPS_SUCCESS,
    GET_PARTNERSHIPS_FAIL,
    GET_PARTNERSHIPS_FOR_QR,
    GET_PARTNERSHIPS_FOR_QR_SUCCESS,
    GET_PARTNERSHIPS_FOR_QR_FAIL,
    GET_PARTNERSHIP_DETAIL,
    GET_PARTNERSHIP_DETAIL_SUCCESS,
    GET_PARTNERSHIP_DETAIL_FAIL,
    ADD_PARTNERSHIP,
    ADD_PARTNERSHIP_SUCCESS,
    ADD_PARTNERSHIP_FAIL,
    UPDATE_PARTNERSHIP,
    UPDATE_PARTNERSHIP_SUCCESS,
    UPDATE_PARTNERSHIP_FAIL,
    DELETE_PARTNERSHIP,
    DELETE_PARTNERSHIP_SUCCESS,
    DELETE_PARTNERSHIP_FAIL,
    RESET_PARTNERSHIP,
   
} from "./actionTypes"; 


export const resetPartnership = () => ({
    type: RESET_PARTNERSHIP,
});
export const getPartnershipsForQr = (id) => ({
    type: GET_PARTNERSHIPS_FOR_QR,
    payload:id
});

export const getPartnershipsForQrSuccess = (partnership) => ({
    type: GET_PARTNERSHIPS_FOR_QR_SUCCESS,
    payload: partnership,
});


export const getPartnershipsForQrFail = (error) => ({
    type: GET_PARTNERSHIPS_FOR_QR_FAIL,
    payload: error, 
});
export const getPartnerships = () => ({
    type: GET_PARTNERSHIPS,
    
});

export const getPartnershipsSuccess = (partnership) => ({
    type: GET_PARTNERSHIPS_SUCCESS,
    payload: partnership,
});


export const getPartnershipsFail = (error) => ({
    type: GET_PARTNERSHIPS_FAIL,
    payload: error,
});

export const deletePartnership = (id) => ({
    type:  DELETE_PARTNERSHIP,
    payload: id
});

export const deletePartnershipSuccess = (data) => ({
    type: DELETE_PARTNERSHIP_SUCCESS,
    payload: data
});

export const deletePartnershipFail = (error) => ({
    type: DELETE_PARTNERSHIP_FAIL,
    payload: error,
});


export const addPartnership = (partnership,files) => ({
    type: ADD_PARTNERSHIP,
    payload: partnership,
    files: files
});

export const addPartnershipSuccess = (data) => ({
    type: ADD_PARTNERSHIP_SUCCESS,
    payload: data
});

export const addPartnershipFail = (error) => ({
    type: ADD_PARTNERSHIP_FAIL,
    payload: error,
});


export const updatePartnership = (partnership, file) => ({
    type: UPDATE_PARTNERSHIP,
    payload: partnership,
    file: file 
});

export const updatePartnershipSuccess = (data) => ({
    type: UPDATE_PARTNERSHIP_SUCCESS,
    payload: data
});

export const updatePartnershipFail = (error) => ({
    type: UPDATE_PARTNERSHIP_FAIL,
    payload: error,
});