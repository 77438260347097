import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER2_USER,
  REGISTER2_USER_SUCCESSFUL,
  REGISTER2_USER_FAILED,
  REGISTER3_USER,
  REGISTER3_USER_SUCCESSFUL,
  REGISTER3_USER_FAILED,
  REGISTER_SUCCESS_RESET,
} from "./actionTypes"

export const registerUser = (user,file) => {
  return {
    type: REGISTER_USER,
    payload: {user},
    file: file
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  } 
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}
export const register2User = (user,file) => {
  return {
    type: REGISTER2_USER,
    payload: {user},
    file: file
  }
}

export const register2UserSuccessful = user => {
  return {
    type: REGISTER2_USER_SUCCESSFUL,
    payload: user,
  }
}

export const register2UserFailed = user => {
  return { 
    type: REGISTER2_USER_FAILED,
    payload: user,
  }
}
export const register3User = (user,file) => {
  return {
    type: REGISTER3_USER,
    payload: {user},
    file: file
  } 
}

export const register3UserSuccessful = user => {
  return {
    type: REGISTER3_USER_SUCCESSFUL,
    payload: user,
  }
}

export const register3UserFailed = user => {
  return { 
    type: REGISTER3_USER_FAILED,
    payload: user,
  }
} 

export const registerSuccessReset = () => {
  return {
    type: REGISTER_SUCCESS_RESET,
    payload: {}, 
  }
}

