import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_DUTYS, DELETE_DEV_DUTY, ADD_DEV_DUTY, UPDATE_DEV_DUTY,GET_DEV_DUTYS_MY_DEV_DUTYS} from "./actionTypes";
import {
    getDevDutysSuccess, getDevDutysFail,
    deleteDevDutySuccess, deleteDevDutyFail,
    updateDevDutySuccess, updateDevDutyFail,
    addDevDutySuccess, addDevDutyFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevDutys, //API CONNECTION
    delDevDuty,
    addDevDuty,
    updateDevDuty,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevDutys() {
    try {
        const response = yield call(getDevDutys)
        yield put(getDevDutysSuccess(response));
    } catch (error) {
        yield put(getDevDutysFail(error));
    }
}

function* onDeleteDevDutys({payload:id}){
    try{
        const response = yield call(delDevDuty, id)
        yield put(deleteDevDutySuccess(response));
    } catch (error) {
        yield put(deleteDevDutyFail(error));
    }
}

function* onAddDevDuty({payload:devDuty}){
    try{
        const response = yield call(addDevDuty, devDuty)
        yield put(addDevDutySuccess(response));
    } catch (error) {
        yield put(addDevDutyFail(error));
    }
}

function* onUpdateDevDuty({payload:devDuty}){
    try{
        const response = yield call(updateDevDuty, devDuty)
        yield put(updateDevDutySuccess(response));
    } catch (error) {
        yield put(updateDevDutyFail(error));
    }
}

export function* devDutysSaga() {
    yield takeEvery(GET_DEV_DUTYS, fetchDevDutys);
    yield takeEvery(DELETE_DEV_DUTY, onDeleteDevDutys);
    yield takeEvery(ADD_DEV_DUTY, onAddDevDuty);
    yield takeEvery(UPDATE_DEV_DUTY, onUpdateDevDuty);
   
    
}

export default devDutysSaga;
