import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER,REGISTER2_USER,REGISTER3_USER} from "./actionTypes"
import { 
  registerUserSuccessful, registerUserFailed,
  register2UserSuccessful,register2UserFailed,
  register3UserSuccessful,register3UserFailed
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
  postJwtRegister2,
  postJwtRegister3
} from "../../../helpers/fakebackend_helper"
 

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user }, file: file }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") { 
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") { 
      const response = yield call(postJwtRegister, user,file)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user,file)
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}
function* register2User({payload: { user }, file: file}){
  try{
      const response = yield call(postJwtRegister2, user, file)
      yield put(register2UserSuccessful(response));
  } catch (error) {
      yield put(register2UserFailed(error));
  }
}
function* register3User({payload: { user }, file: file}){
  try{
      const response = yield call(postJwtRegister3, user, file)
      yield put(register3UserSuccessful(response));
  } catch (error) {
      yield put(register3UserFailed(error));
  }
}
export function* watchUserRegister() { 
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(REGISTER2_USER, register2User)
  yield takeEvery(REGISTER3_USER, register3User)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
