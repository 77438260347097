import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types'

const barchart = (props) => {
  // const series = [
  //   {
  //     name: "Net Profit",
  //     data: [46, 57, 59, 54, 62, 58, 64, 60, 66], 
  //   },
  //   {
  //     name: "Revenue",
  //     data: [74, 83, 102, 97, 86, 106, 93, 114, 94], 
  //   },
  //   {
  //     name: "Free Cash Flow",
  //     data: [37, 42, 38, 26, 47, 50, 54, 55, 43], 
  //   },
  // ]
  const options = { 
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: props.categories || [] 
    },
  }

  return (
    <ReactApexChart options={options} series={props.series || []} type="bar" />
  )
}

barchart.propTypes = { 
  series: PropTypes.array,
  categories: PropTypes.array, 
}

export default barchart
