import {
    GET_PROGRAMS,
    GET_PROGRAMS_SUCCESS,
    GET_PROGRAMS_FAIL,
    GET_PROGRAM_DETAIL,
    GET_PROGRAM_DETAIL_SUCCESS,
    GET_PROGRAM_DETAIL_FAIL,
    ADD_PROGRAM,
    ADD_PROGRAM_SUCCESS,
    ADD_PROGRAM_FAIL,
    UPDATE_PROGRAM,
    UPDATE_PROGRAM_SUCCESS,
    UPDATE_PROGRAM_FAIL,
    DELETE_PROGRAM,
    DELETE_PROGRAM_SUCCESS,
    DELETE_PROGRAM_FAIL,
    RESET_PROGRAM,
   
} from "./actionTypes";


export const resetProgram = () => ({
    type: RESET_PROGRAM,
});

export const getPrograms = () => ({
    type: GET_PROGRAMS,
});

export const getProgramsSuccess = (program) => ({
    type: GET_PROGRAMS_SUCCESS,
    payload: program,
});


export const getProgramsFail = (error) => ({
    type: GET_PROGRAMS_FAIL,
    payload: error,
});

export const deleteProgram = (id) => ({
    type:  DELETE_PROGRAM,
    payload: id
});

export const deleteProgramSuccess = (data) => ({
    type: DELETE_PROGRAM_SUCCESS,
    payload: data
});

export const deleteProgramFail = (error) => ({
    type: DELETE_PROGRAM_FAIL,
    payload: error,
});


export const addProgram = (program) => ({
    type: ADD_PROGRAM,
    payload: program
});

export const addProgramSuccess = (data) => ({
    type: ADD_PROGRAM_SUCCESS,
    payload: data
});

export const addProgramFail = (error) => ({
    type: ADD_PROGRAM_FAIL,
    payload: error,
});


export const updateProgram = (program) => ({
    type: UPDATE_PROGRAM,
    payload: program
});

export const updateProgramSuccess = (data) => ({
    type: UPDATE_PROGRAM_SUCCESS,
    payload: data
});

export const updateProgramFail = (error) => ({
    type: UPDATE_PROGRAM_FAIL,
    payload: error,
});