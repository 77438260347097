import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  CardTitle,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"
import { getAnalytics as onGetAnalytics, resetAnalytics as onResetAnalytics,
  addVisit as onAddVisit
} from "store/actions"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { isEmpty } from "lodash"

// Charts
import Gauge from "./AllCharts/echart/gaugechart"
import Line from "./AllCharts/echart/linechart"
import LineBar from "./AllCharts/echart/linebarchart"
import Doughnut from "./AllCharts/echart/doughnutchart"
import Pie from "./AllCharts/echart/piechart"
import Scatter from "./AllCharts/echart/scatterchart"
import Bubble from "./AllCharts/echart/bubblechart"
import Candlestick from "./AllCharts/echart/candlestickchart"

import LineApexChart from "./AllCharts/apex/chartapex"
import DashedLine from "./AllCharts/apex/dashedLine"
import SplineArea from "./AllCharts/apex/SplineArea"
import Apaexlinecolumn from "./AllCharts/apex/apaexlinecolumn"
import ColumnWithDataLabels from "./AllCharts/apex/ColumnWithDataLabels"
import BarChart from "./AllCharts/apex/barchart"
import LineColumnArea from "./AllCharts/apex/LineColumnArea"
import RadialChart from "./AllCharts/apex/RadialChart"
import PieChart from "./AllCharts/apex/PieChart"
import DonutChart from "./AllCharts/apex/dountchart"

import TurkeyMap from "turkey-map-react"

const Analytics = props => {
  const dispatch = useDispatch()

  const { error, Analytics } = useSelector(state => ({
    error: state.Analytics?.error,
    Analytics: state.Analytics?.data,
  }))
  let user = JSON.parse(localStorage.getItem("authUser"))

  const [loading, setLoading] = useState(true)

  const {
    match: { params },
  } = props

  useEffect(() => {
    setLoading(true)
    if (isEmpty(params?.id)) {
      dispatch(onGetAnalytics("v1/" + (params?.id || "")))
    }
    const visitDetailArr =[{name:"Analitik Veriler/Grafikler",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
  }, [dispatch])

  const renderCity = (cityComponent, cityData) => (
    <>
      {cityComponent}
      <text
        x={parseFloat(cityData.path.split(" ")[1].split(",")[0]) - 10}
        y={parseFloat(cityData.path.split(" ")[1].split(",")[0]) - 10}
      >
        {cityData.name}
      </text>
    </>
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Analytics")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Analytics")}
            breadcrumbItem={props.t("Analytics")}
          />
          <Row style={{position:'relative'}}>
            {isEmpty(Analytics) ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            <a
            style={{width:'150px', height:'25px', position:'absolute', top:-50, right:0, zIndex:'99'}}
            className="btn btn-sm btn-primary"
              onClick={() => {
                setLoading(true)
                dispatch(onResetAnalytics())
                if (isEmpty(params?.id)) {
                  dispatch(
                    onGetAnalytics(`v1${params?.id ? '/'+params?.id:''}?reload=1`)
                  )
                }
              }}
            >
              <i className="bx bx-sync"></i> {props.t("Refresh")}
            </a>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Location Visitors Info")}
                  </CardTitle>
                  <BarChart
                    series={Analytics?.treeChart_3?.series}
                    categories={Analytics?.treeChart_3?.categories}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Visitors Info By Qr")}
                  </CardTitle>
                  <BarChart
                    series={Analytics?.treeChart_4?.series}
                    categories={Analytics?.treeChart_4?.categories}
                  />
                </CardBody>
              </Card>
            </Col>
            {console.log(">>>>>", Analytics)}

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Visitors Info By Tag")}
                  </CardTitle>
                  <BarChart
                    series={Analytics?.treeChart_5?.series}
                    categories={Analytics?.treeChart_5?.categories}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("App Analytic By Application Form")}
                  </CardTitle>
                  <BarChart
                    series={Analytics?.treeChart_1?.series}
                    categories={Analytics?.treeChart_1?.categories}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("App Analytic By Units")}
                  </CardTitle>
                  <BarChart
                    series={Analytics?.treeChart_2?.series}
                    categories={Analytics?.treeChart_2?.categories}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <hr />
          <TurkeyMap hoverable={true} showTooltip={true} />
          <hr />
          <h2>EChart</h2>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Line Chart</CardTitle>
                  <div id="line-chart" className="e-chart">
                    <Line />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Mix Line-Bar</CardTitle>
                  <div id="mix-line-bar" className="e-chart">
                    <LineBar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Doughnut Chart</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <Doughnut />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Pie Chart</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Scatter Chart</CardTitle>
                  <div id="scatter-chart" className="e-chart">
                    <Scatter />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Bubble Chart</CardTitle>
                  <div id="bubble-chart" className="e-chart">
                    <Bubble />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Candlestick Chart</CardTitle>
                  <div id="candlestick-chart" className="e-chart">
                    <Candlestick />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Gauge Chart</CardTitle>
                  <div id="gauge-chart" className="e-chart">
                    <Gauge />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr />
          <h2>APEX</h2>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Line with Data Labels</CardTitle>
                  <LineApexChart />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Dashed Line</CardTitle>
                  <DashedLine />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> Spline Area </CardTitle>
                  <SplineArea />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> Column Chart </CardTitle>
                  <Apaexlinecolumn />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Column with Data Labels{" "}
                  </CardTitle>
                  <ColumnWithDataLabels />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Bar Chart</CardTitle>
                  <BarChart
                    series={[
                      {
                        name: "Net Profit",
                        data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
                      },
                      {
                        name: "Revenue",
                        data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
                      },
                      {
                        name: "Free Cash Flow",
                        data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
                      },
                    ]}
                    categories={[
                      "Deneme",
                      "Canada",
                      "United Kingdom",
                      "Netherlands",
                      "Italy",
                      "France",
                      "Japan",
                      "United States",
                      "China",
                      "Germany",
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Line, Column & Area Chart{" "}
                  </CardTitle>
                  <LineColumnArea />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Radial Chart</CardTitle>
                  <RadialChart />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Pie Chart </CardTitle>
                  <PieChart />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Donut Chart</CardTitle>
                  <DonutChart />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Analytics))
