import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SOFT_SKILLS, DELETE_SOFT_SKILL, ADD_SOFT_SKILL, UPDATE_SOFT_SKILL,GET_SOFT_SKILLS_MY_SOFT_SKILLS} from "./actionTypes";
import {
    getSoftSkillsSuccess, getSoftSkillsFail,
    deleteSoftSkillSuccess, deleteSoftSkillFail,
    updateSoftSkillSuccess, updateSoftSkillFail,
    addSoftSkillSuccess, addSoftSkillFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSoftSkills, //API CONNECTION
    delSoftSkill,
    addSoftSkill,
    updateSoftSkill,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchSoftSkills() {
    try {
        const response = yield call(getSoftSkills)
        yield put(getSoftSkillsSuccess(response));
    } catch (error) {
        yield put(getSoftSkillsFail(error));
    }
}

function* onDeleteSoftSkills({payload:id}){
    try{
        const response = yield call(delSoftSkill, id)
        yield put(deleteSoftSkillSuccess(response));
    } catch (error) {
        yield put(deleteSoftSkillFail(error));
    }
}

function* onAddSoftSkill({payload:softSkill}){
    try{
        const response = yield call(addSoftSkill, softSkill)
        yield put(addSoftSkillSuccess(response));
    } catch (error) {
        yield put(addSoftSkillFail(error));
    }
}

function* onUpdateSoftSkill({payload:softSkill}){
    try{
        const response = yield call(updateSoftSkill, softSkill)
        yield put(updateSoftSkillSuccess(response));
    } catch (error) {
        yield put(updateSoftSkillFail(error));
    }
}

export function* softSkillsSaga() {
    yield takeEvery(GET_SOFT_SKILLS, fetchSoftSkills);
    yield takeEvery(DELETE_SOFT_SKILL, onDeleteSoftSkills);
    yield takeEvery(ADD_SOFT_SKILL, onAddSoftSkill);
    yield takeEvery(UPDATE_SOFT_SKILL, onUpdateSoftSkill);
   
    
}

export default softSkillsSaga;
