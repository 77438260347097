import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QR_ANONYMUS } from "./actionTypes"
import { getQRAnonymusSuccess, getQRAnonymusFail } from "./actions"

//Include Both Helper File with needed methods
import {
  getQRAnonymus, //API CONNECTION
} from "../../helpers/fakebackend_helper"

function* fetchQRAnonymus({ payload: qrcode }) {
  try {
    const response = yield call(getQRAnonymus, qrcode)
    yield put(getQRAnonymusSuccess(response))
  } catch (error) {
    yield put(getQRAnonymusFail(error))
  }
}

export function* QRAnonymusSaga() {
  yield takeEvery(GET_QR_ANONYMUS, fetchQRAnonymus)
}

export default QRAnonymusSaga
