import {
    GET_SOFT_SKILLS,
    GET_SOFT_SKILLS_SUCCESS,
    GET_SOFT_SKILLS_FAIL,
    GET_SOFT_SKILL_DETAIL,
    GET_SOFT_SKILL_DETAIL_SUCCESS,
    GET_SOFT_SKILL_DETAIL_FAIL,
    ADD_SOFT_SKILL,
    ADD_SOFT_SKILL_SUCCESS,
    ADD_SOFT_SKILL_FAIL,
    UPDATE_SOFT_SKILL,
    UPDATE_SOFT_SKILL_SUCCESS,
    UPDATE_SOFT_SKILL_FAIL,
    DELETE_SOFT_SKILL,
    DELETE_SOFT_SKILL_SUCCESS,
    DELETE_SOFT_SKILL_FAIL,
    RESET_SOFT_SKILL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const SoftSkills = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_SOFT_SKILLS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SOFT_SKILL_SUCCESS:
            return {
                ...state,
                data: state.data.filter(softSkill => softSkill._id.toString() !== action.payload.data.toString())
            }
        case ADD_SOFT_SKILL_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_SOFT_SKILL_SUCCESS:
            return {
                ...state,
                data: state.data.map(softSkill =>
                    softSkill._id.toString() === action.payload.data._id.toString() ?
                        { softSkill, ...action.payload.data } :
                        softSkill
                )
            }

        //FAILS
        case GET_SOFT_SKILLS_FAIL:
        case DELETE_SOFT_SKILL_FAIL:
        case ADD_SOFT_SKILL_FAIL:
        case UPDATE_SOFT_SKILL_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_SOFT_SKILL: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default SoftSkills;