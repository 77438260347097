import {
    GET_DEV_SECTOR_FORMS,
    GET_DEV_SECTOR_FORMS_SUCCESS,
    GET_DEV_SECTOR_FORMS_FAIL,
    GET_DEV_SECTOR_FORM_DETAIL,
    GET_DEV_SECTOR_FORM_DETAIL_SUCCESS,
    GET_DEV_SECTOR_FORM_DETAIL_FAIL,
    ADD_DEV_SECTOR_FORM,
    ADD_DEV_SECTOR_FORM_SUCCESS,
    ADD_DEV_SECTOR_FORM_FAIL,
    UPDATE_DEV_SECTOR_FORM,
    UPDATE_DEV_SECTOR_FORM_SUCCESS,
    UPDATE_DEV_SECTOR_FORM_FAIL,
    DELETE_DEV_SECTOR_FORM,
    DELETE_DEV_SECTOR_FORM_SUCCESS,
    DELETE_DEV_SECTOR_FORM_FAIL,
    RESET_DEV_SECTOR_FORM,
   
} from "./actionTypes";


export const resetDevSectorForm = () => ({
    type: RESET_DEV_SECTOR_FORM,
});

export const getDevSectorForms = () => ({
    type: GET_DEV_SECTOR_FORMS,
});

export const getDevSectorFormsSuccess = (devSectorForm) => ({
    type: GET_DEV_SECTOR_FORMS_SUCCESS,
    payload: devSectorForm,
});


export const getDevSectorFormsFail = (error) => ({
    type: GET_DEV_SECTOR_FORMS_FAIL,
    payload: error,
});

export const deleteDevSectorForm = (id) => ({
    type:  DELETE_DEV_SECTOR_FORM,
    payload: id
});

export const deleteDevSectorFormSuccess = (data) => ({
    type: DELETE_DEV_SECTOR_FORM_SUCCESS,
    payload: data
});

export const deleteDevSectorFormFail = (error) => ({
    type: DELETE_DEV_SECTOR_FORM_FAIL,
    payload: error,
});


export const addDevSectorForm = (devSectorForm) => ({
    type: ADD_DEV_SECTOR_FORM,
    payload: devSectorForm
});

export const addDevSectorFormSuccess = (data) => ({
    type: ADD_DEV_SECTOR_FORM_SUCCESS,
    payload: data
});

export const addDevSectorFormFail = (error) => ({
    type: ADD_DEV_SECTOR_FORM_FAIL,
    payload: error,
});


export const updateDevSectorForm = (devSectorForm) => ({
    type: UPDATE_DEV_SECTOR_FORM,
    payload: devSectorForm
});

export const updateDevSectorFormSuccess = (data) => ({
    type: UPDATE_DEV_SECTOR_FORM_SUCCESS,
    payload: data
});

export const updateDevSectorFormFail = (error) => ({
    type: UPDATE_DEV_SECTOR_FORM_FAIL,
    payload: error,
});