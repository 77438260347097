import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga" 
import changePasswordSaga from "./auth/changePassword/saga" 
import ConfirmSaga from "./auth/confirm/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga" 
import dashboardSaga from "./dashboard/saga";

import companiesSaga from "./companies/saga";
import plansSaga from "./plan/saga";
import branchsSaga from "./branchs/saga";
import AppFormSaga from "./appForm/saga"
import RequestFormPlanSaga from "./requestFormPlan/saga"
import QRCodeSaga from "./qrcode/saga"
import QRAnonymusSaga from "./qr-anonymus/saga"
import AnalyticsSaga from "./analytics/saga" 
import LocationsSaga from "./locations/saga"
import AdvertisementSaga from "./advertisement/saga"
import PartnershipSaga from "./partnership/saga"
import SectorSaga from "./sector/saga"
import AvmInfoSaga from "./avmInfo/saga"
import StatusSaga from "./status/saga"
import SurveySaga from "./survey/saga"
import SurveyFormSaga from "./surveyForm/saga"
import SurveySentSaga from "./surveySent/saga"
import OrientationSaga from "./orientation/saga"
import OrientationFormsSaga from "./orientationForm/saga"
import OrientationSentSaga from "./orientationSent/saga"
import DutySaga from "./duty/saga"
import PlanOutsideSaga from "./planOutside/saga"
import AppFormOutside from "./appFormOutside/saga"
import OrientationPeriod from "./orientationPeriod/saga"
import DevDuty from "./devDuty/saga"
import Label from "./label/saga"
import SoftSkillSaga from "./softSkill/saga"
import ForeignLanguage from "./foreignLanguage/saga"
import DevForeignLanguage from "./devForeignLanguage/saga"
import DevProgram from "./devProgram/saga"
import Program from "./program/saga"
import BackUrl from "./backUrl/saga"
import DevSectorForm from "./devSectorForm/saga"
import SectorForm from "./sectorForm/saga"
import DevDutyForm from "./devDutyForm/saga"
import DutyForm from "./dutyForm/saga"
import DevWorkType from "./devWorkType/saga"
import WorkType from "./workType/saga"
import Users from "./users/saga"
import DutiesForm from "./dutiesForm/saga"
import DevRequestReason from "./devRequestReason/saga"
import RequestReason from "./requestReason/saga"
import Reports from "./reports/saga"
import LoginLogs from "./loginLog/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(changePasswordSaga),
    fork(ConfirmSaga),
    fork(ProfileSaga),
    fork(LayoutSaga), 
    fork(dashboardSaga), 
    fork(companiesSaga), 
    fork(plansSaga), 
    fork(branchsSaga), 
    fork(AppFormSaga), 
    fork(RequestFormPlanSaga),
    fork(QRCodeSaga),
    fork(QRAnonymusSaga),
    fork(AnalyticsSaga),
    fork(LocationsSaga),
    fork(AdvertisementSaga),
    fork(PartnershipSaga),
    fork(SectorSaga),
    fork(AvmInfoSaga),
    fork(StatusSaga),
    fork(SurveySaga),
    fork(SurveyFormSaga),
    fork(SurveySentSaga),
    fork(OrientationSaga),
    fork(OrientationFormsSaga),
    fork(OrientationSentSaga),
    fork(DutySaga),
    fork(PlanOutsideSaga),
    fork(AppFormOutside),
    fork(OrientationPeriod),
    fork(DevDuty),
    fork(Label),
    fork(SoftSkillSaga),
    fork(ForeignLanguage),
    fork(DevForeignLanguage),
    fork(DevProgram),
    fork(Program),
    fork(BackUrl),
    fork(DevSectorForm),
    fork(SectorForm),
    fork(DevDutyForm),
    fork(DutyForm),
    fork(DevWorkType),
    fork(WorkType),
    fork(Users),
    fork(DutiesForm),
    fork(DevRequestReason),
    fork(RequestReason),
    fork(Reports),
    fork(LoginLogs)
    
  ])
}
