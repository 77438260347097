import {
    GET_APP_FORM_OUTSIDES,
    GET_APP_FORM_OUTSIDES_SUCCESS,
    GET_APP_FORM_OUTSIDES_FAIL,
    GET_APP_FORM_OUTSIDE_DETAIL,
    GET_APP_FORM_OUTSIDE_DETAIL_SUCCESS,
    GET_APP_FORM_OUTSIDE_DETAIL_FAIL,
    ADD_APP_FORM_OUTSIDE,
    ADD_APP_FORM_OUTSIDE_SUCCESS,
    ADD_APP_FORM_OUTSIDE_FAIL,
    UPDATE_APP_FORM_OUTSIDE,
    UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    UPDATE_APP_FORM_OUTSIDE_FAIL,
    STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL,
    DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    DESC_UPDATE_APP_FORM_OUTSIDE_FAIL,
    DELETE_APP_FORM_OUTSIDE,
    DELETE_APP_FORM_OUTSIDE_SUCCESS,
    DELETE_APP_FORM_OUTSIDE_FAIL,
    RESET_APP_FORM_OUTSIDE,
    GET_MY_FORMS_SUCCESS,
    GET_MY_FORMS_FAIL,
    UPDATE_MY_FORMS_SUCCESS,
    UPDATE_MY_FORMS_FAIL,
    DELETE_MY_FORMS_SUCCESS, 
    DELETE_MY_FORMS_FAIL,
    DELETE_MY_FORM_STATUS_TRUE_SUCCESS,
    DELETE_MY_FORM_STATUS_TRUE_FAIL,
    DELETE_MY_FORM_STATUS_FALSE_SUCCESS,
    DELETE_MY_FORM_STATUS_FALSE_FAIL,
    GET_MY_FORMS_STATUS_FALSE_SUCCESS,
    GET_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_TRUE_FAIL,
    GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    GET_MY_FORMS_STATUS_TRUE_FAIL,
    REJECT_APP_FORM_OUTSIDE_SUCCESS,
    REJECT_APP_FORM_OUTSIDE_FAIL,
    ACCEPT_APP_FORM_OUTSIDE_SUCCESS,
    ACCEPT_APP_FORM_OUTSIDE_FAIL,
    GET_FORMS_DOWN_SUCCESS,
    GET_FORMS_DOWN_FAIL 
} from "./actionTypes";

const INIT_STATE = { 
    data: [],
    error: null, 
    success:null,
    descSuccess:false,
    myForms :[],
    myFormsStatusFalse :[],
    myFormsStatusTrue :[] ,
    formsDown:[],
    fileMove:false

};

const AppFormOutsides = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FORMS_DOWN_SUCCESS:
            return { 
                ...state,
                data: action.payload.data
            }
        case GET_MY_FORMS_SUCCESS:
            return { 
                ...state,
                myForms: action.payload.data
            }
        case GET_MY_FORMS_STATUS_FALSE_SUCCESS:
                return { 
                    ...state,
                    myFormsStatusFalse: action.payload.data
                }
        case GET_MY_FORMS_STATUS_TRUE_SUCCESS:
            return { 
                ...state,
                myFormsStatusTrue: action.payload.data
            }
        case UPDATE_MY_FORMS_SUCCESS:
            return {
                 ...state,
                 myForms: state.myForms.map(form =>
                     form._id.toString() === action.payload.data._id.toString() ?
                         { form, ...action.payload.data } :
                         form
                    )
                }
         case UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS:
             return {
                  ...state,
                  myFormsStatusFalse: state.myFormsStatusFalse.map(form =>
                      form._id.toString() === action.payload.data._id.toString() ?
                          { form, ...action.payload.data } :
                          form
                     )
                 }
         case UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS:
                    return {
                         ...state,
                         myFormsStatusTrue: state.myFormsStatusTrue.map(form =>
                             form._id.toString() === action.payload.data._id.toString() ?
                                 { form, ...action.payload.data } :
                                 form
                            )
                        }
        case DELETE_MY_FORMS_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myForms: state.myForms.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
            case DELETE_MY_FORM_STATUS_TRUE_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myFormsStatusTrue: state.myFormsStatusTrue.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
            case DELETE_MY_FORM_STATUS_FALSE_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myFormsStatusFalse: state.myFormsStatusFalse.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
        case GET_APP_FORM_OUTSIDES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_APP_FORM_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(appFormOutsides => appFormOutsides._id.toString() !== action.payload.data.toString())
            }
        case ADD_APP_FORM_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true
            }
        case UPDATE_APP_FORM_OUTSIDE_SUCCESS:
            return {
                ...state,
                data: state.data.map(appFormOutsides =>
                    appFormOutsides._id.toString() === action.payload.data._id.toString() ?
                        { appFormOutsides, ...action.payload.data } :
                        appFormOutsides
                ),
                success:true 
            } 
        case STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS:
                return {
                    ...state,
                    data: state.data.map(appFormOutsides =>
                        appFormOutsides._id.toString() === action.payload.data._id.toString() ?
                            { appFormOutsides, ...action.payload.data } :
                            appFormOutsides
                    )
                } 
         case REJECT_APP_FORM_OUTSIDE_SUCCESS:  
             return {
                 ...state,
                 fileMove:true,
                 data: state.data.map(appFormOutsides =>
                     appFormOutsides._id.toString() === action.payload.data._id.toString() ?
                         { appFormOutsides, ...action.payload.data } :
                         appFormOutsides
                 )
             }
             case ACCEPT_APP_FORM_OUTSIDE_SUCCESS:  
             return {
                 ...state,
                 fileMove:true,
                 data: state.data.map(appFormOutsides =>
                     appFormOutsides._id.toString() === action.payload.data._id.toString() ?
                         { appFormOutsides, ...action.payload.data } :
                         appFormOutsides
                 )
             }
        case DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS:
                return {
                  
                    ...state,
                    descSuccess:true,
                    data: state.data.map(appFormOutsides =>
                        appFormOutsides._id.toString() === action.payload.data._id.toString() ?
                            { appFormOutsides, ...action.payload.data } :
                            appFormOutsides
                    )
                }  
        //FAILS
        case GET_APP_FORM_OUTSIDES_FAIL:
        case DELETE_APP_FORM_OUTSIDE_FAIL:
        case ADD_APP_FORM_OUTSIDE_FAIL:
        case UPDATE_APP_FORM_OUTSIDE_FAIL:
        case STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL: 
        case DESC_UPDATE_APP_FORM_OUTSIDE_FAIL: 
        case GET_MY_FORMS_FAIL:
        case UPDATE_MY_FORMS_FAIL:
        case DELETE_MY_FORMS_FAIL:
        case DELETE_MY_FORM_STATUS_TRUE_FAIL:
        case DELETE_MY_FORM_STATUS_FALSE_FAIL:
        case GET_MY_FORMS_STATUS_FALSE_FAIL:
        case UPDATE_MY_FORMS_STATUS_FALSE_FAIL:
        case GET_MY_FORMS_STATUS_TRUE_FAIL:
        case UPDATE_MY_FORMS_STATUS_TRUE_FAIL:
        case REJECT_APP_FORM_OUTSIDE_FAIL:
        case GET_FORMS_DOWN_FAIL:
        case ACCEPT_APP_FORM_OUTSIDE_FAIL:
            return {
                ...state, 
                error: action.payload
            }

        case RESET_APP_FORM_OUTSIDE: return {
            ...state,
            error: null,
            success:null,
            descSuccess:false,
            fileMove:false
        }
        default:
            return state;
    }
}


export default AppFormOutsides;