import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
import { isEmpty } from "lodash"

const Breadcrumb = props => {
  const linkGet = () => { 
    if (!isEmpty(props.linkList)) { 
      return (
        <div className="d-flex flex-row">
          {props.linkList?.map((item, index) => (
            <div key={index} className="d-flex flex-row">
              <a href={item?.link || "#"}>{item?.title || "--"}</a>
              {index < props.linkList.length - 1 ? (
                <div className="ms-1 me-1">/</div>
              ) : null}
            </div>
          ))}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="mb-0 font-size-18 d-flex flex-row align-items-center">
            <h4 className="pb-0 mb-0 me-2">{!isEmpty(props.linkList) && props.linkType == "header"
              ? linkGet()
              : props.breadcrumbItem}</h4>
            {props.addBtn ? (
              <Button className="m-2" style={{backgroundColor:"#34c38f",borderColor:"#34c38f"}} onClick={() => props.addBtnCallBack()}>
                <i className="fa fa-plus"></i>
              </Button>
            ) : null}
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem></BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  linkList: PropTypes.array,
  linkType: PropTypes.string,
}

export default Breadcrumb
