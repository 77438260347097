import {
    GET_LABELS,
    GET_LABELS_SUCCESS,
    GET_LABELS_FAIL,
    GET_LABEL_DETAIL,
    GET_LABEL_DETAIL_SUCCESS,
    GET_LABEL_DETAIL_FAIL,
    ADD_LABEL,
    ADD_LABEL_SUCCESS,
    ADD_LABEL_FAIL,
    UPDATE_LABEL,
    UPDATE_LABEL_SUCCESS,
    UPDATE_LABEL_FAIL,
    DELETE_LABEL,
    DELETE_LABEL_SUCCESS,
    DELETE_LABEL_FAIL,
    RESET_LABEL,
   
} from "./actionTypes";


export const resetLabel = () => ({
    type: RESET_LABEL,
});

export const getLabels = () => ({
    type: GET_LABELS,
});

export const getLabelsSuccess = (label) => ({
    type: GET_LABELS_SUCCESS,
    payload: label,
});


export const getLabelsFail = (error) => ({
    type: GET_LABELS_FAIL,
    payload: error,
});

export const deleteLabel = (id) => ({
    type:  DELETE_LABEL,
    payload: id
});

export const deleteLabelSuccess = (data) => ({
    type: DELETE_LABEL_SUCCESS,
    payload: data
});

export const deleteLabelFail = (error) => ({
    type: DELETE_LABEL_FAIL,
    payload: error,
});


export const addLabel = (label) => ({
    type: ADD_LABEL,
    payload: label
});

export const addLabelSuccess = (data) => ({
    type: ADD_LABEL_SUCCESS,
    payload: data
});

export const addLabelFail = (error) => ({
    type: ADD_LABEL_FAIL,
    payload: error,
});


export const updateLabel = (label) => ({
    type: UPDATE_LABEL,
    payload: label
});

export const updateLabelSuccess = (data) => ({
    type: UPDATE_LABEL_SUCCESS,
    payload: data
});

export const updateLabelFail = (error) => ({
    type: UPDATE_LABEL_FAIL,
    payload: error,
});