import {
    GET_REQUEST_FORM_PLANS,
    GET_REQUEST_FORM_PLANS_SUCCESS,
    GET_REQUEST_FORM_PLANS_FAIL,
    GET_REQUEST_FORM_PLAN_DETAIL,
    GET_REQUEST_FORM_PLAN_DETAIL_SUCCESS,
    GET_REQUEST_FORM_PLAN_DETAIL_FAIL,
    ADD_REQUEST_FORM_PLAN,
    ADD_REQUEST_FORM_PLAN_SUCCESS,
    ADD_REQUEST_FORM_PLAN_FAIL,
    UPDATE_REQUEST_FORM_PLAN,
    UPDATE_REQUEST_FORM_PLAN_SUCCESS,
    UPDATE_REQUEST_FORM_PLAN_FAIL,
    DELETE_REQUEST_FORM_PLAN,
    DELETE_REQUEST_FORM_PLAN_SUCCESS,
    DELETE_REQUEST_FORM_PLAN_FAIL,
    RESET_REQUEST_FORM_PLAN,
    UPDATE_ACTION_SUCCESS,
    UPDATE_ACTION_FAIL, 
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    GET_REQ_FORMS_DOWN_SUCCESS, 
    GET_REQ_FORMS_DOWN_FAIL

} from "./actionTypes"; 
 
const INIT_STATE = {
    data: [],
    error: null,
    RequestFormPlanes :[],
};

const RequestFormPlans = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REQ_FORMS_DOWN_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_REQUEST_FORM_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_REQUEST_FORM_PLAN_SUCCESS:
            return {
                ...state,
                data: state.data.filter(requestFormPlan => requestFormPlan._id.toString() !== action.payload.data.toString())
            }
        case ADD_REQUEST_FORM_PLAN_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
      
         case UPDATE_REQUEST_FORM_PLAN_SUCCESS:
             return {
                 ...state,
                 data: state.data.map(x =>
                     x._id.toString() === action.payload.data._id.toString() ?
                         { x, ...action.payload.data } :
                         x
                 )
             }
             case UPDATE_ACTION_SUCCESS:
                 return {
                     ...state,
                     data: state.data.map(x =>
                         x._id.toString() === action.payload.data._id.toString() ?
                             { x, ...action.payload.data } :
                             x
                     )
                 }
        case UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                data: state.data.map(status =>
                    status._id.toString() === action.payload.data._id.toString() ?
                        { status, ...action.payload.data } :
                        status
                )
            }


        //FAILS
        case GET_REQUEST_FORM_PLANS_FAIL:
        case DELETE_REQUEST_FORM_PLAN_FAIL:
        case ADD_REQUEST_FORM_PLAN_FAIL:
        case UPDATE_ACTION_FAIL:
        case UPDATE_STATUS_FAIL:
        case GET_REQ_FORMS_DOWN_FAIL:
      
            return {
                ...state,
                error: action.payload
            }

        case RESET_REQUEST_FORM_PLAN: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default RequestFormPlans;