/** Get ORIENTATION */
export const GET_ORIENTATIONS = "GET_ORIENTATIONS";
export const GET_ORIENTATIONS_SUCCESS = "GET_ORIENTATIONS_SUCCESS";
export const GET_ORIENTATIONS_FAIL = "GET_ORIENTATIONS_FAIL";

export const GET_ORIENTATION_DETAIL = "GET_ORIENTATION_DETAIL";
export const GET_ORIENTATION_DETAIL_SUCCESS = "GET_ORIENTATION_DETAIL_SUCCESS";
export const GET_ORIENTATION_DETAIL_FAIL = "GET_ORIENTATION_DETAIL_FAIL";


export const ADD_ORIENTATION = "ADD_ORIENTATION";
export const ADD_ORIENTATION_SUCCESS = "ADD_ORIENTATION_SUCCESS";
export const ADD_ORIENTATION_FAIL = "ADD_ORIENTATION_FAIL";

export const UPDATE_ORIENTATION = "UPDATE_ORIENTATION";
export const UPDATE_ORIENTATION_SUCCESS = "UPDATE_ORIENTATION_SUCCESS";
export const UPDATE_ORIENTATION_FAIL = "UPDATE_ORIENTATION_FAIL";

export const DELETE_ORIENTATION = "DELETE_ORIENTATION";
export const DELETE_ORIENTATION_SUCCESS = "DELETE_ORIENTATION_SUCCESS";
export const DELETE_ORIENTATION_FAIL = "DELETE_ORIENTATION_FAIL"; 



export const RESET_ORIENTATION= "RESET_ORIENTATION";