import {
    GET_DEV_REQUEST_REASONS,
    GET_DEV_REQUEST_REASONS_SUCCESS,
    GET_DEV_REQUEST_REASONS_FAIL,
    GET_DEV_REQUEST_REASON_DETAIL,
    GET_DEV_REQUEST_REASON_DETAIL_SUCCESS,
    GET_DEV_REQUEST_REASON_DETAIL_FAIL,
    ADD_DEV_REQUEST_REASON,
    ADD_DEV_REQUEST_REASON_SUCCESS,
    ADD_DEV_REQUEST_REASON_FAIL,
    UPDATE_DEV_REQUEST_REASON,
    UPDATE_DEV_REQUEST_REASON_SUCCESS,
    UPDATE_DEV_REQUEST_REASON_FAIL,
    DELETE_DEV_REQUEST_REASON,
    DELETE_DEV_REQUEST_REASON_SUCCESS,
    DELETE_DEV_REQUEST_REASON_FAIL,
    RESET_DEV_REQUEST_REASON,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevRequestReasons = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_REQUEST_REASONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devRequestReason => devRequestReason._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_REQUEST_REASON_SUCCESS:
            return {
                ...state,
                data: state.data.map(devRequestReason =>
                    devRequestReason._id.toString() === action.payload.data._id.toString() ?
                        { devRequestReason, ...action.payload.data } :
                        devRequestReason
                )
            }

        //FAILS
        case GET_DEV_REQUEST_REASONS_FAIL:
        case DELETE_DEV_REQUEST_REASON_FAIL:
        case ADD_DEV_REQUEST_REASON_FAIL:
        case UPDATE_DEV_REQUEST_REASON_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_REQUEST_REASON: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevRequestReasons;