import {
    GET_PLAN_OUTSIDES,
    GET_PLAN_OUTSIDES_SUCCESS,
    GET_PLAN_OUTSIDES_FAIL,
    GET_PLAN_OUTSIDE_DETAIL,
    GET_PLAN_OUTSIDE_DETAIL_SUCCESS,
    GET_PLAN_OUTSIDE_DETAIL_FAIL,
    ADD_PLAN_OUTSIDE,
    ADD_PLAN_OUTSIDE_SUCCESS,
    ADD_PLAN_OUTSIDE_FAIL,
    UPDATE_PLAN_OUTSIDE,
    UPDATE_PLAN_OUTSIDE_SUCCESS,
    UPDATE_PLAN_OUTSIDE_FAIL,
    DELETE_PLAN_OUTSIDE,
    DELETE_PLAN_OUTSIDE_SUCCESS,
    DELETE_PLAN_OUTSIDE_FAIL, 
    RESET_PLAN_OUTSIDE,
    GET_BRANCH_PLAN_OUTSIDE,
    GET_BRANCH_PLAN_OUTSIDE_SUCCESS,
    GET_BRANCH_PLAN_OUTSIDE_FAIL,
    GET_PLAN_OUTSIDES_BY_BRANCH,
    GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS, 
    GET_PLAN_OUTSIDES_BY_BRANCH_FAIL,
    GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL, 
    ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL, 
    DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    GET_USER_BY_MAIL,
    GET_USER_BY_MAIL_SUCCESS,
    GET_USER_BY_MAIL_FAIL,
    GET_PLAN_OUTSIDES_DOWN,
    GET_PLAN_OUTSIDES_DOWN_SUCCESS, 
    GET_PLAN_OUTSIDES_DOWN_FAIL,
    SEND_MAIL,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAIL,
} from "./actionTypes";  
export const sendMail = (email) => {
    return {
      type: SEND_MAIL, 
      payload: email, 
    }
  }
  
  export const sendMailSuccess = (data) => {
    return {
      type: SEND_MAIL_SUCCESS,
      payload: data,
    }
  } 
  
  export const sendMailFail = (message) => {
    return {
      type: SEND_MAIL_FAIL,
      payload: message,
    }}
export const getPlanOutsidesDown = (branchId) => ({
    type: GET_PLAN_OUTSIDES_DOWN, 
    payload:branchId
}); 
export const getPlanOutsidesDownSuccess = (data) => ({ 
    type: GET_PLAN_OUTSIDES_DOWN_SUCCESS,
    payload: data 
});
export const getPlanOutsidesDownFail = (error) => ({
    type: GET_PLAN_OUTSIDES_DOWN_FAIL, 
    payload: error,
});
export const getPlanOutsidesDetail = (id) => ({
    type: GET_PLAN_OUTSIDE_DETAIL, 
    payload:id
}); 
export const getPlanOutsidesDetailSuccess = (data) => ({
    type: GET_PLAN_OUTSIDE_DETAIL_SUCCESS,
    payload: data
});
export const getPlanOutsidesDetailFail = (error) => ({
    type: GET_PLAN_OUTSIDE_DETAIL_FAIL, 
    payload: error,
});
export const getUserByMail = (id) => ({
    type: GET_USER_BY_MAIL, 
    payload:id
}); 
export const getUserByMailSuccess = (data) => ({
    type: GET_USER_BY_MAIL_SUCCESS,
    payload: data
});
export const getUserByMailFail = (error) => ({
    type: GET_USER_BY_MAIL_FAIL, 
    payload: error,
});
//MyPlanOutsides
export const getBranch_byPlanOutside = (id) => ({ 
    type: GET_BRANCH_PLAN_OUTSIDE, 
    payload:id
}); 
export const getBranch_byPlanOutsideSuccess = (data) => ({
    type: GET_BRANCH_PLAN_OUTSIDE_SUCCESS,
    payload: data
});
export const getBranch_byPlanOutsideFail = (error) => ({
    type: GET_BRANCH_PLAN_OUTSIDE_FAIL, 
    payload: error,
});
export const getPlanOutsides_ByBranch = (id) => ({
    type: GET_PLAN_OUTSIDES_BY_BRANCH, 
    payload:id
}); 
export const getPlanOutsides_ByBranchSuccess = (data) => ({
    type: GET_PLAN_OUTSIDES_BY_BRANCH_SUCCESS,
    payload: data
});
export const getPlanOutsides_ByBranchFail = (error) => ({
    type: GET_PLAN_OUTSIDES_BY_BRANCH_FAIL, 
    payload: error,
});
export const getPlanOutsides_MyPlanOutsides = (id) => ({
    type: GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES, 
    payload:id
}); 
export const getPlanOutsides_MyPlanOutsidesSuccess = (data) => ({
    type: GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    payload: data
});
export const getPlanOutsides_MyPlanOutsidesFail = (error) => ({
    type: GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL, 
    payload: error,
});
export const addPlanOutsides_MyPlanOutsides = (planOutside) => ({ 
    type: ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    payload:planOutside
});
export const addPlanOutsides_MyPlanOutsidesSuccess = (data) => ({
    type: ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS, 
    payload: data
});
export const addPlanOutsides_MyPlanOutsidesFail = (error) => ({
    type: ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    payload: error,
});
export const deletePlanOutsides_MyPlanOutsides = (id) => ({ 
    type: DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    payload:id
});
export const deletePlanOutsides_MyPlanOutsidesSuccess = (data) => ({ 
    type: DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS, 
    payload: data
});
export const deletePlanOutsides_MyPlanOutsidesFail = (error) => ({
    type: DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    payload: error,
});
export const updatePlanOutsides_MyPlanOutsides = (planOutside) => ({
    type: UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,
    payload:planOutside
});
export const updatePlanOutsides_MyPlanOutsidesSuccess = (data) => ({
    type: UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_SUCCESS,
    payload: data
});
export const updatePlanOutsides_MyPlanOutsidesFail = (error) => ({
    type: UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES_FAIL,
    payload: error,
});

//
export const resetPlanOutside = () => ({
    type: RESET_PLAN_OUTSIDE,
});

export const getPlanOutsides = () => ({
    type: GET_PLAN_OUTSIDES,
});

export const getPlanOutsidesSuccess = (planOutside) => ({
    type: GET_PLAN_OUTSIDES_SUCCESS,
    payload: planOutside,
});


export const getPlanOutsidesFail = (error) => ({
    type: GET_PLAN_OUTSIDES_FAIL,
    payload: error,
});

export const deletePlanOutside = (id) => ({
    type:  DELETE_PLAN_OUTSIDE,
    payload: id
});

export const deletePlanOutsideSuccess = (data) => ({
    type: DELETE_PLAN_OUTSIDE_SUCCESS,
    payload: data
});

export const deletePlanOutsideFail = (error) => ({
    type: DELETE_PLAN_OUTSIDE_FAIL,
    payload: error,
});


export const addPlanOutside = (planOutside) => ({
    type: ADD_PLAN_OUTSIDE,
    payload: planOutside 
});

export const addPlanOutsideSuccess = (data) => ({
    type: ADD_PLAN_OUTSIDE_SUCCESS,
    payload: data
});

export const addPlanOutsideFail = (error) => ({
    type: ADD_PLAN_OUTSIDE_FAIL,
    payload: error,
});


export const updatePlanOutside = (planOutside) => ({
    type: UPDATE_PLAN_OUTSIDE,
    payload: planOutside
});

export const updatePlanOutsideSuccess = (data) => ({
    type: UPDATE_PLAN_OUTSIDE_SUCCESS,
    payload: data
});

export const updatePlanOutsideFail = (error) => ({
    type: UPDATE_PLAN_OUTSIDE_FAIL,
    payload: error,
});