import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  CardTitle,
  Collapse,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import classnames from "classnames"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"
import { getAnalytics as onGetAnalytics ,
  addVisit as onAddVisit
} from "store/actions"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { isEmpty } from "lodash"

const AnalyticBranches = props => {
  const dispatch = useDispatch()

  const { error, AnalyticBranch } = useSelector(state => ({
    error: state.Analytics?.error,
    AnalyticBranch: state.Analytics?.data,
  }))
  let user = JSON.parse(localStorage.getItem("authUser"))

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [listTemp, setListTemp] = useState([])
  const [filterInput, setFilterInput] = useState("")

  const {
    match: { params },
  } = props

  useEffect(() => {
    setLoading(true)
    if (!isEmpty(user)) {
      dispatch(onGetAnalytics("v1/branches"))
    }
    const visitDetailArr =[{name:"Analitik Veriler/QR Kod",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(AnalyticBranch)) {
      setList(AnalyticBranch.data)
      setListTemp(AnalyticBranch.data)
    }
  }, [AnalyticBranch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Analytics")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <div style={{position:'relative'}}>
            <Breadcrumb
              title={props.t("Analytics")}
              breadcrumbItem={props.t("Analytics")}
            />
            <input
              type={"text"}
              className={"form-control"}
              placeholder={props.t("Search")}
              value={filterInput}
              onChange={e => {
                setFilterInput(e.target.value)
                let temp = [...listTemp];
                if(e.target.value == ""){
                  setList(temp);
                }else{
                  temp = temp.filter(x=>x.name.toLowerCase().includes(filterInput))
                  setList(temp);
                }
              }}
              style={{
                width:200,
                position:'absolute',
                top:0,
                right:0
              }}
            />
          </div>
          <Row>
            <div className="accordion" id="accordion">
              {!isEmpty(list) ? (
                list.map((item, index) => (
                  <Col lg={12} key={index} className="mb-1">
                    <div className="accordion-item border border-primary">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium bg-white",
                            { collapsed: !item.isOpen }
                          )}
                          type="button"
                          onClick={() => {
                            let templist = [...list]
                            templist[index].isOpen = !templist[index].isOpen
                            setList(templist)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item.name}{" "}
                          {item.QRCode?.tags?.length > 0 ? (
                            <span className="ms-2 badge rounded-2 bg-success">
                              {item.QRCode?.tags?.length}
                            </span>
                          ) : null}
                        </button>
                      </h2>

                      <Collapse
                        isOpen={item.isOpen}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <Row>
                            {!isEmpty(item.QRCode) ? (
                              <Col md={12}>
                                <Card className="border border-primary">
                                  <CardBody>
                                    <CardTitle>{item.QRCode?.title}</CardTitle>
                                    {item.QRCode?.description}
                                  </CardBody>
                                </Card>
                              </Col>
                            ) : null}
                            {!isEmpty(item.QRCode) ? (
                              item.QRCode?.tags?.map((tag, i2) => (
                                <Col md={3} key={i2}>
                                  <a
                                    href={`/analytics/branch/qr/${item._id}/${item.QRCode._id}/${tag._id}/`}
                                  >
                                    <Card className="border border-success">
                                      <CardBody>
                                        <CardTitle className="mt-2">
                                          <i className="mdi mdi-qrcode me-2"></i>
                                          {tag.tag}
                                          <small className="float-end">
                                            {props.t("Total Visit")}:{" "}
                                            {tag.visit}
                                          </small>
                                        </CardTitle>
                                      </CardBody>
                                    </Card>
                                  </a>
                                </Col>
                              ))
                            ) : (
                              <Card className="border border-danger">
                                <CardBody>
                                  <CardTitle className="mt-1 p-0">
                                    <i className="mdi mdi-alert-outline font-size-24 me-2 text-warning"></i>
                                    {props.t("No data yet.")}
                                  </CardTitle>
                                </CardBody>
                              </Card>
                            )}
                          </Row>
                        </div>
                      </Collapse>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AnalyticBranches))
