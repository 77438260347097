import {
    GET_DEV_PROGRAMS,
    GET_DEV_PROGRAMS_SUCCESS,
    GET_DEV_PROGRAMS_FAIL,
    GET_DEV_PROGRAM_DETAIL,
    GET_DEV_PROGRAM_DETAIL_SUCCESS,
    GET_DEV_PROGRAM_DETAIL_FAIL,
    ADD_DEV_PROGRAM,
    ADD_DEV_PROGRAM_SUCCESS,
    ADD_DEV_PROGRAM_FAIL,
    UPDATE_DEV_PROGRAM,
    UPDATE_DEV_PROGRAM_SUCCESS,
    UPDATE_DEV_PROGRAM_FAIL,
    DELETE_DEV_PROGRAM,
    DELETE_DEV_PROGRAM_SUCCESS,
    DELETE_DEV_PROGRAM_FAIL,
    RESET_DEV_PROGRAM,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevPrograms = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_PROGRAMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_PROGRAM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devProgram => devProgram._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_PROGRAM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_PROGRAM_SUCCESS:
            return {
                ...state,
                data: state.data.map(devProgram =>
                    devProgram._id.toString() === action.payload.data._id.toString() ?
                        { devProgram, ...action.payload.data } :
                        devProgram
                )
            }

        //FAILS
        case GET_DEV_PROGRAMS_FAIL:
        case DELETE_DEV_PROGRAM_FAIL:
        case ADD_DEV_PROGRAM_FAIL:
        case UPDATE_DEV_PROGRAM_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_PROGRAM: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevPrograms;