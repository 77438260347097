/** Get SURVEY_SENT */
export const GET_SURVEY_SENTS = "GET_SURVEY_SENTS";
export const GET_SURVEY_SENTS_SUCCESS = "GET_SURVEY_SENTS_SUCCESS";
export const GET_SURVEY_SENTS_FAIL = "GET_SURVEY_SENTS_FAIL";

export const GET_SURVEY_SENT_DETAIL = "GET_SURVEY_SENT_DETAIL";
export const GET_SURVEY_SENT_DETAIL_SUCCESS = "GET_SURVEY_SENT_DETAIL_SUCCESS";
export const GET_SURVEY_SENT_DETAIL_FAIL = "GET_SURVEY_SENT_DETAIL_FAIL";


export const ADD_SURVEY_SENT = "ADD_SURVEY_SENT";
export const ADD_SURVEY_SENT_SUCCESS = "ADD_SURVEY_SENT_SUCCESS";
export const ADD_SURVEY_SENT_FAIL = "ADD_SURVEY_SENT_FAIL";

export const UPDATE_SURVEY_SENT = "UPDATE_SURVEY_SENT";
export const UPDATE_SURVEY_SENT_SUCCESS = "UPDATE_SURVEY_SENT_SUCCESS";
export const UPDATE_SURVEY_SENT_FAIL = "UPDATE_SURVEY_SENT_FAIL";

export const DELETE_SURVEY_SENT = "DELETE_SURVEY_SENT";
export const DELETE_SURVEY_SENT_SUCCESS = "DELETE_SURVEY_SENT_SUCCESS";
export const DELETE_SURVEY_SENT_FAIL = "DELETE_SURVEY_SENT_FAIL"; 

export const SEND_SURVEY_MAIL = "SEND_SURVEY_MAIL";
export const SEND_SURVEY_MAIL_SUCCESS = "SEND_SURVEY_MAIL_SUCCESS";
export const SEND_SURVEY_MAIL_FAIL = "SEND_SURVEY_MAIL_FAIL"; 

export const RESET_SURVEY_SENT= "RESET_SURVEY_SENT"; 