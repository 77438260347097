import {
    GET_DEV_WORK_TYPES,
    GET_DEV_WORK_TYPES_SUCCESS,
    GET_DEV_WORK_TYPES_FAIL,
    GET_DEV_WORK_TYPE_DETAIL,
    GET_DEV_WORK_TYPE_DETAIL_SUCCESS,
    GET_DEV_WORK_TYPE_DETAIL_FAIL,
    ADD_DEV_WORK_TYPE,
    ADD_DEV_WORK_TYPE_SUCCESS,
    ADD_DEV_WORK_TYPE_FAIL,
    UPDATE_DEV_WORK_TYPE,
    UPDATE_DEV_WORK_TYPE_SUCCESS,
    UPDATE_DEV_WORK_TYPE_FAIL,
    DELETE_DEV_WORK_TYPE,
    DELETE_DEV_WORK_TYPE_SUCCESS,
    DELETE_DEV_WORK_TYPE_FAIL,
    RESET_DEV_WORK_TYPE,
   
} from "./actionTypes";


export const resetDevWorkType = () => ({
    type: RESET_DEV_WORK_TYPE,
});

export const getDevWorkTypes = () => ({
    type: GET_DEV_WORK_TYPES,
});

export const getDevWorkTypesSuccess = (devWorkType) => ({
    type: GET_DEV_WORK_TYPES_SUCCESS,
    payload: devWorkType,
});


export const getDevWorkTypesFail = (error) => ({
    type: GET_DEV_WORK_TYPES_FAIL,
    payload: error,
});

export const deleteDevWorkType = (id) => ({
    type:  DELETE_DEV_WORK_TYPE,
    payload: id
});

export const deleteDevWorkTypeSuccess = (data) => ({
    type: DELETE_DEV_WORK_TYPE_SUCCESS,
    payload: data
});

export const deleteDevWorkTypeFail = (error) => ({
    type: DELETE_DEV_WORK_TYPE_FAIL,
    payload: error,
});


export const addDevWorkType = (devWorkType) => ({
    type: ADD_DEV_WORK_TYPE,
    payload: devWorkType
});

export const addDevWorkTypeSuccess = (data) => ({
    type: ADD_DEV_WORK_TYPE_SUCCESS,
    payload: data
});

export const addDevWorkTypeFail = (error) => ({
    type: ADD_DEV_WORK_TYPE_FAIL,
    payload: error,
});


export const updateDevWorkType = (devWorkType) => ({
    type: UPDATE_DEV_WORK_TYPE,
    payload: devWorkType
});

export const updateDevWorkTypeSuccess = (data) => ({
    type: UPDATE_DEV_WORK_TYPE_SUCCESS,
    payload: data
});

export const updateDevWorkTypeFail = (error) => ({
    type: UPDATE_DEV_WORK_TYPE_FAIL,
    payload: error,
});