/** Get ORIENTATON_SENT */
export const GET_ORIENTATON_SENTS = "GET_ORIENTATON_SENTS";
export const GET_ORIENTATON_SENTS_SUCCESS = "GET_ORIENTATON_SENTS_SUCCESS";
export const GET_ORIENTATON_SENTS_FAIL = "GET_ORIENTATON_SENTS_FAIL";

export const GET_ORIENTATON_SENT_DETAIL = "GET_ORIENTATON_SENT_DETAIL";
export const GET_ORIENTATON_SENT_DETAIL_SUCCESS = "GET_ORIENTATON_SENT_DETAIL_SUCCESS";
export const GET_ORIENTATON_SENT_DETAIL_FAIL = "GET_ORIENTATON_SENT_DETAIL_FAIL";


export const ADD_ORIENTATON_SENT = "ADD_ORIENTATON_SENT";
export const ADD_ORIENTATON_SENT_SUCCESS = "ADD_ORIENTATON_SENT_SUCCESS";
export const ADD_ORIENTATON_SENT_FAIL = "ADD_ORIENTATON_SENT_FAIL";

export const UPDATE_ORIENTATON_SENT = "UPDATE_ORIENTATON_SENT";
export const UPDATE_ORIENTATON_SENT_SUCCESS = "UPDATE_ORIENTATON_SENT_SUCCESS";
export const UPDATE_ORIENTATON_SENT_FAIL = "UPDATE_ORIENTATON_SENT_FAIL";

export const DELETE_ORIENTATON_SENT = "DELETE_ORIENTATON_SENT";
export const DELETE_ORIENTATON_SENT_SUCCESS = "DELETE_ORIENTATON_SENT_SUCCESS";
export const DELETE_ORIENTATON_SENT_FAIL = "DELETE_ORIENTATON_SENT_FAIL"; 

export const SEND_ORIENTATON_MAIL = "SEND_ORIENTATON_MAIL";
export const SEND_ORIENTATON_MAIL_SUCCESS = "SEND_ORIENTATON_MAIL_SUCCESS";
export const SEND_ORIENTATON_MAIL_FAIL = "SEND_ORIENTATON_MAIL_FAIL"; 

export const RESET_ORIENTATON_SENT= "RESET_ORIENTATON_SENT"; 