import {
    GET_PLANS,
    GET_PLANS_SUCCESS,
    GET_PLANS_FAIL,
    GET_PLAN_DETAIL,
    GET_PLAN_DETAIL_SUCCESS,
    GET_PLAN_DETAIL_FAIL,
    ADD_PLAN,
    ADD_PLAN_SUCCESS,
    ADD_PLAN_FAIL,
    UPDATE_PLAN,
    UPDATE_PLAN_SUCCESS,
    UPDATE_PLAN_FAIL,
    DELETE_PLAN,
    DELETE_PLAN_SUCCESS,
    DELETE_PLAN_FAIL, 
    RESET_PLAN,
    GET_BRANCH_PLAN,
    GET_BRANCH_PLAN_SUCCESS,
    GET_BRANCH_PLAN_FAIL,
    GET_PLANS_BY_BRANCH,
    GET_PLANS_BY_BRANCH_SUCCESS, 
    GET_PLANS_BY_BRANCH_FAIL,
    GET_PLANS_MY_PLANS,
    GET_PLANS_MY_PLANS_SUCCESS,
    GET_PLANS_MY_PLANS_FAIL, 
    ADD_PLANS_MY_PLANS,
    ADD_PLANS_MY_PLANS_SUCCESS,
    ADD_PLANS_MY_PLANS_FAIL, 
    DELETE_PLANS_MY_PLANS,
    DELETE_PLANS_MY_PLANS_SUCCESS,
    DELETE_PLANS_MY_PLANS_FAIL,
    UPDATE_PLANS_MY_PLANS,
    UPDATE_PLANS_MY_PLANS_SUCCESS,
    UPDATE_PLANS_MY_PLANS_FAIL,
    GET_USER_BY_MAIL,
    GET_USER_BY_MAIL_SUCCESS,
    GET_USER_BY_MAIL_FAIL,
    GET_PLANS_DOWN,
    GET_PLANS_DOWN_SUCCESS, 
    GET_PLANS_DOWN_FAIL, 
    UPDATE_PLAN_ACTIVE, 
    UPDATE_PLAN_ACTIVE_SUCCESS, 
    UPDATE_PLAN_ACTIVE_FAIL,
} from "./actionTypes";   
export const updatePlanActive = (plan) => ({
    type: UPDATE_PLAN_ACTIVE,
    payload: plan, 
    
});

export const updatePlanActiveSuccess = (data) => ({
    type: UPDATE_PLAN_ACTIVE_SUCCESS,
    payload: data
});

export const updatePlanActiveFail = (error) => ({
    type: UPDATE_PLAN_ACTIVE_FAIL,
    payload: error, 
});
export const getPlansDown = (branchId) => ({
    type: GET_PLANS_DOWN, 
    payload:branchId
}); 
export const getPlansDownSuccess = (data) => ({ 
    type: GET_PLANS_DOWN_SUCCESS,
    payload: data 
});
export const getPlansDownFail = (error) => ({
    type: GET_PLANS_DOWN_FAIL, 
    payload: error,
});
export const getPlansDetail = (id) => ({
    type: GET_PLAN_DETAIL, 
    payload:id
}); 
export const getPlansDetailSuccess = (data) => ({
    type: GET_PLAN_DETAIL_SUCCESS,
    payload: data
});
export const getPlansDetailFail = (error) => ({
    type: GET_PLAN_DETAIL_FAIL, 
    payload: error,
});
export const getUserByMail = (id) => ({
    type: GET_USER_BY_MAIL, 
    payload:id
}); 
export const getUserByMailSuccess = (data) => ({
    type: GET_USER_BY_MAIL_SUCCESS,
    payload: data
});
export const getUserByMailFail = (error) => ({
    type: GET_USER_BY_MAIL_FAIL, 
    payload: error,
});
//MyPlans
export const getBranch_byPlan = (id) => ({ 
    type: GET_BRANCH_PLAN, 
    payload:id
}); 
export const getBranch_byPlanSuccess = (data) => ({
    type: GET_BRANCH_PLAN_SUCCESS,
    payload: data
});
export const getBranch_byPlanFail = (error) => ({
    type: GET_BRANCH_PLAN_FAIL, 
    payload: error,
});
export const getPlans_ByBranch = (id) => ({
    type: GET_PLANS_BY_BRANCH, 
    payload:id
}); 
export const getPlans_ByBranchSuccess = (data) => ({
    type: GET_PLANS_BY_BRANCH_SUCCESS,
    payload: data
});
export const getPlans_ByBranchFail = (error) => ({
    type: GET_PLANS_BY_BRANCH_FAIL, 
    payload: error,
});
export const getPlans_MyPlans = (id) => ({
    type: GET_PLANS_MY_PLANS, 
    payload:id
}); 
export const getPlans_MyPlansSuccess = (data) => ({
    type: GET_PLANS_MY_PLANS_SUCCESS,
    payload: data
});
export const getPlans_MyPlansFail = (error) => ({
    type: GET_PLANS_MY_PLANS_FAIL, 
    payload: error,
});
export const addPlans_MyPlans = (plan) => ({ 
    type: ADD_PLANS_MY_PLANS,
    payload:plan
});
export const addPlans_MyPlansSuccess = (data) => ({
    type: ADD_PLANS_MY_PLANS_SUCCESS, 
    payload: data
});
export const addPlans_MyPlansFail = (error) => ({
    type: ADD_PLANS_MY_PLANS_FAIL,
    payload: error,
});
export const deletePlans_MyPlans = (id) => ({ 
    type: DELETE_PLANS_MY_PLANS,
    payload:id
});
export const deletePlans_MyPlansSuccess = (data) => ({ 
    type: DELETE_PLANS_MY_PLANS_SUCCESS, 
    payload: data
});
export const deletePlans_MyPlansFail = (error) => ({
    type: DELETE_PLANS_MY_PLANS_FAIL,
    payload: error,
});
export const updatePlans_MyPlans = (plan) => ({
    type: UPDATE_PLANS_MY_PLANS,
    payload:plan
});
export const updatePlans_MyPlansSuccess = (data) => ({
    type: UPDATE_PLANS_MY_PLANS_SUCCESS,
    payload: data
});
export const updatePlans_MyPlansFail = (error) => ({
    type: UPDATE_PLANS_MY_PLANS_FAIL,
    payload: error,
});

//
export const resetPlan = () => ({
    type: RESET_PLAN,
});

export const getPlans = () => ({
    type: GET_PLANS,
});

export const getPlansSuccess = (plan) => ({
    type: GET_PLANS_SUCCESS,
    payload: plan,
});


export const getPlansFail = (error) => ({
    type: GET_PLANS_FAIL,
    payload: error,
});

export const deletePlan = (id) => ({
    type:  DELETE_PLAN,
    payload: id
});

export const deletePlanSuccess = (data) => ({
    type: DELETE_PLAN_SUCCESS,
    payload: data
});

export const deletePlanFail = (error) => ({
    type: DELETE_PLAN_FAIL,
    payload: error,
});


export const addPlan = (plan) => ({
    type: ADD_PLAN,
    payload: plan
});

export const addPlanSuccess = (data) => ({
    type: ADD_PLAN_SUCCESS,
    payload: data
});

export const addPlanFail = (error) => ({
    type: ADD_PLAN_FAIL,
    payload: error,
});


export const updatePlan = (plan) => ({
    type: UPDATE_PLAN,
    payload: plan
});

export const updatePlanSuccess = (data) => ({
    type: UPDATE_PLAN_SUCCESS,
    payload: data
});

export const updatePlanFail = (error) => ({
    type: UPDATE_PLAN_FAIL,
    payload: error,
});