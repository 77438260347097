import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SECTORS, DELETE_SECTOR, ADD_SECTOR, UPDATE_SECTOR,GET_SECTORS_MY_SECTORS} from "./actionTypes";
import {
    getSectorsSuccess, getSectorsFail,
    deleteSectorSuccess, deleteSectorFail,
    updateSectorSuccess, updateSectorFail,
    addSectorSuccess, addSectorFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSectors, //API CONNECTION
    delSector,
    addSector,
    updateSector,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchSectors() {
    try {
        const response = yield call(getSectors)
        yield put(getSectorsSuccess(response));
    } catch (error) {
        yield put(getSectorsFail(error));
    }
}

function* onDeleteSectors({payload:id}){
    try{
        const response = yield call(delSector, id)
        yield put(deleteSectorSuccess(response));
    } catch (error) {
        yield put(deleteSectorFail(error));
    }
}

function* onAddSector({payload:sector}){
    try{
        const response = yield call(addSector, sector)
        yield put(addSectorSuccess(response));
    } catch (error) {
        yield put(addSectorFail(error));
    }
}

function* onUpdateSector({payload:sector}){
    try{
        const response = yield call(updateSector, sector)
        yield put(updateSectorSuccess(response));
    } catch (error) {
        yield put(updateSectorFail(error));
    }
}

export function* sectorsSaga() {
    yield takeEvery(GET_SECTORS, fetchSectors);
    yield takeEvery(DELETE_SECTOR, onDeleteSectors);
    yield takeEvery(ADD_SECTOR, onAddSector);
    yield takeEvery(UPDATE_SECTOR, onUpdateSector);
   
    
}

export default sectorsSaga;
