import {
    GET_PARTNERSHIPS,
    GET_PARTNERSHIPS_SUCCESS,
    GET_PARTNERSHIPS_FAIL,
    GET_PARTNERSHIP_DETAIL,
    GET_PARTNERSHIP_DETAIL_SUCCESS,
    GET_PARTNERSHIP_DETAIL_FAIL,
    ADD_PARTNERSHIP,
    ADD_PARTNERSHIP_SUCCESS,
    ADD_PARTNERSHIP_FAIL,
    UPDATE_PARTNERSHIP,
    UPDATE_PARTNERSHIP_SUCCESS,
    UPDATE_PARTNERSHIP_FAIL,
    DELETE_PARTNERSHIP,
    DELETE_PARTNERSHIP_SUCCESS,
    DELETE_PARTNERSHIP_FAIL,
    RESET_PARTNERSHIP,
    GET_PARTNERSHIPS_FOR_QR_SUCCESS,
    GET_PARTNERSHIPS_FOR_QR_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const Partnerships = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_PARTNERSHIPS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
            case GET_PARTNERSHIPS_FOR_QR_SUCCESS:
                return {
                    ...state,
                    data: action.payload.data
                }
        case DELETE_PARTNERSHIP_SUCCESS:
            return {
                ...state,
                data: state.data.filter(partnership => partnership._id.toString() !== action.payload.data.toString())
            }
        case ADD_PARTNERSHIP_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PARTNERSHIP_SUCCESS:
            return {
                ...state,
                data: state.data.map(partnership =>
                    partnership._id.toString() === action.payload.data._id.toString() ?
                        { partnership, ...action.payload.data } :
                        partnership
                )
            }

        //FAILS
        case GET_PARTNERSHIPS_FAIL:
        case DELETE_PARTNERSHIP_FAIL:
        case ADD_PARTNERSHIP_FAIL:
        case UPDATE_PARTNERSHIP_FAIL:
        case GET_PARTNERSHIPS_FOR_QR_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_PARTNERSHIP: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Partnerships;