import {
    GET_REQUEST_REASONS,
    GET_REQUEST_REASONS_SUCCESS,
    GET_REQUEST_REASONS_FAIL,
    GET_REQUEST_REASON_DETAIL,
    GET_REQUEST_REASON_DETAIL_SUCCESS,
    GET_REQUEST_REASON_DETAIL_FAIL,
    ADD_REQUEST_REASON,
    ADD_REQUEST_REASON_SUCCESS,
    ADD_REQUEST_REASON_FAIL,
    UPDATE_REQUEST_REASON,
    UPDATE_REQUEST_REASON_SUCCESS,
    UPDATE_REQUEST_REASON_FAIL,
    DELETE_REQUEST_REASON,
    DELETE_REQUEST_REASON_SUCCESS,
    DELETE_REQUEST_REASON_FAIL,
    RESET_REQUEST_REASON,
   
} from "./actionTypes";


export const resetRequestReason = () => ({
    type: RESET_REQUEST_REASON,
});

export const getRequestReasons = () => ({
    type: GET_REQUEST_REASONS,
});

export const getRequestReasonsSuccess = (requestReason) => ({
    type: GET_REQUEST_REASONS_SUCCESS,
    payload: requestReason,
});


export const getRequestReasonsFail = (error) => ({
    type: GET_REQUEST_REASONS_FAIL,
    payload: error,
});

export const deleteRequestReason = (id) => ({
    type:  DELETE_REQUEST_REASON,
    payload: id
});

export const deleteRequestReasonSuccess = (data) => ({
    type: DELETE_REQUEST_REASON_SUCCESS,
    payload: data
});

export const deleteRequestReasonFail = (error) => ({
    type: DELETE_REQUEST_REASON_FAIL,
    payload: error,
});


export const addRequestReason = (requestReason) => ({
    type: ADD_REQUEST_REASON,
    payload: requestReason
});

export const addRequestReasonSuccess = (data) => ({
    type: ADD_REQUEST_REASON_SUCCESS,
    payload: data
});

export const addRequestReasonFail = (error) => ({
    type: ADD_REQUEST_REASON_FAIL,
    payload: error,
});


export const updateRequestReason = (requestReason) => ({
    type: UPDATE_REQUEST_REASON,
    payload: requestReason
});

export const updateRequestReasonSuccess = (data) => ({
    type: UPDATE_REQUEST_REASON_SUCCESS,
    payload: data
});

export const updateRequestReasonFail = (error) => ({
    type: UPDATE_REQUEST_REASON_FAIL,
    payload: error,
});