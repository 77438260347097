export const PROGRAM_BILGISI_SEC = "Program Seç,Excel,Word,Outlook,Perakende Yazılımı Perakende Yazılımı / Nebim,Perakende Yazılımı / Epos,Diğer";
export const SEVIYE_SEC = "Seviye Seç,Yok,Az,Orta,İyi,Çok İyi";
export const ASKERLIK_DURUMU_SEC = "Askerlik Durumu Seç,Yapıldı,Yapılmadı,Tecilli,Muaf";
export const EGITIM_DURUMU_SEC = "Eğitim Durumu Seç,İlk Okul,Orta Okul,Lise Öğrencisi,Lise Mezunu,Ön Lisans Öğrencisi,Ön Lisans Mezunu,Üniversite Öğrencisi,Üniversite Mezunu,Master / Doktora Öğrencisi,Master / Doktora Mezunu";
export const MEDENI_DURUM_SEC = "Medeni Durum Seç,Bekar,Nişanlı,Evli,Boşanmış,Dul";
export const CINSIYET_SEC = "Cinsiyet Seç,Kadın,Erkek,Belirtilmemiş";
export const DIL_SEC = "Dil Seç,İngilizce,Arapça,Rusça,Almanca,Fransızca,Çince,Japonca,Diğer";
export const EHLIYET_BILGISI_SEC = "Ehliyet Bilgisi Seç,A1,A2,B1,B2";
export const EHLIYET_VAR_YOK = "Ehliyet Durumu,Var,Yok"
export const SURE_SEC = "Süre Seç,1 den az,1-2 arası,2-3 arası,3-5 arası,5-10 arası,10 dan fazla";
export const GOREV_SEC = "Görev Seç,Satış Danışmanı,Karşılama Hostesi,Kasa Elemanı,Depo Elemanı,Ütücü,Servis Elemanı,Barista,Komi / Garson,Mutfak Elemanı,Usta,Müdür Yardımcısı,Diğer"; 
export const SEKTOR_SEC = "Sektör Seç,Hazır Giyim,Ev Tekstili,Kişisel Bakım,Elektronik Ürünler,Telekomünikasyon,Süpermarket,Mobilya,Kitap / Kırtasiye,Otel,Restorant / Kafe,Diğer";
export const SURE_SEC_YIL = "Süre Seç,1 yıldan az,1-2 yıl arası,2-3 yıl arası,3-5 yıl arası,5-10 yıl arası,10 yıldan fazla";
export const AYRILMA_NEDENI_SEC = "Ayrılma Sebebi Seç,Çalışma Şartları,Çalışma Saatleri,Kanuni Hakların Verilmemesi,Ücret Alamama,Sigorta Yapılmadı,Ulaşım Zorluğu,Çalışma Arkadaşları ile Anlaşamama,İş Yoğunluğu,Mobbing,Aile Rızası,Diğer";
export const MAAS_BEKLENTISI = "Maaş Beklentisi Seç,Asgari Ücret,6000-7000,7000-8000,8000-9000,9000-10000,10000 üstü";
