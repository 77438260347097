import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CITIES, GET_DISTRICTS, GET_NEIGHTBORHOODS } from "./actionTypes"
import { getCitiesSuccess, getCitiesFail, getDistrictsSuccess, getDistrictsFail, getNeighborhoodsSuccess, getNeighborhoodsFail } from "./actions"

//Include Both Helper File with needed methods
import {
  getCities, //API CONNECTION
  getDistricts,
  getNeighborhoods
} from "../../helpers/fakebackend_helper"

function* fetchCities() {
  try {
    const response = yield call(getCities)
    yield put(getCitiesSuccess(response))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}
function* fetchDistricts({ payload: cityId }) {
  try {
    const response = yield call(getDistricts, cityId)
    yield put(getDistrictsSuccess(response))
  } catch (error) {
    yield put(getDistrictsFail(error))
  }
}
function* fetchNeighborhoods({ payload: {cityId, districtId} }) {
  try {
    const response = yield call(getNeighborhoods, cityId, districtId)
    yield put(getNeighborhoodsSuccess(response))
  } catch (error) {
    yield put(getNeighborhoodsFail(error))
  }
}

export function* LocationsSaga() {
  yield takeEvery(GET_CITIES, fetchCities); 
  yield takeEvery(GET_DISTRICTS, fetchDistricts);
  yield takeEvery(GET_NEIGHTBORHOODS, fetchNeighborhoods);
}

export default LocationsSaga
