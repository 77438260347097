import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMPANIES,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY_USERS,
  ADD_COMPANY_USER,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY_USER,
  GET_ROLES,
  GET_USER_ROLES,
  ADD_USER_ROLES,
  USER_MAIL,
  GET_COMPANY_BY_ID,
  GET_COMPANY_BY_QR,
  GET_COMPANY_INFO_BY_QR,
  GET_USER_LIST_DOWN,
  UPDATE_COMPANY_QR,
  DELETE_COMPANY_QRPHOTO,
  GET_COMPANIES_AVM,
  UPDATE_COMPANY_CLASS


} from "./actionTypes"
import {
  getCompaniesSuccess,
  getCompaniesFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  addCompanySuccess,
  addCompanyFail,

  getCompanyUsersSuccess,
  getCompanyUsersFail,
  addCompanyUserSuccess,
  addCompanyUserFail,
  deleteCompanyUserSuccess,
  deleteCompanyUserFail,
  updateCompanyUserSuccess,
  updateCompanyUserFail,
  getRolesSuccess,
  getRolesFail,
  getUserRolesSuccess,
  getUserRolesFail,
  addUserRolesSuccess,
  addUserRolesFail,
  userMailSuccess,
  userMailFail,
  getCompanyByIdSuccess,getCompanyByIdFail,
  getCompanyByQrSuccess,getCompanyByQrFail,
  getCompanyInfoByQrSuccess,getCompanyInfoByQrFail,
  updateCompanyQrSuccess,updateCompanyQrFail,

  getUserListDownSuccess,getUserListDownFail,
  deleteCompanyQRPhotoSuccess, deleteCompanyQRPhotoFail,
  getCompaniesAvmSuccess,getCompaniesAvmFail,
  updateCompanyClassSuccess,updateCompanyClassFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanies, //API CONNECTION
  delCompany,
  addCompany,
  updateCompany,
  getCompanyUsers,
  addCompanyUser,
  deleteCompanyUser,
  updateCompanyUser,
  getRoles,
  getUserRoles,
  addUserRoles,
  userMail,
  getCompanyById,
  getCompanyByQr,
  getCompanyInfoByQr,getUserListDown,
  updateCompanyQrPhoto,
  delCompanyQRPhoto,
  getCompaniesAvm,updateCompanyClass
 
} from "../../helpers/fakebackend_helper"
function* onUpdateCompanyClass({ payload: form }) {
  try {
    console.log("sagaya geldi")
    const response = yield call(updateCompanyClass, form)
    yield put(updateCompanyClassSuccess(response))
  } catch (error) {
    yield put(updateCompanyClassFail(error))
   }
}
function* fetchUserListDown( { payload:branchId }) {
  try {
    const response = yield call(getUserListDown,branchId)
    // localStorage.setItem("companies", JSON.stringify(response))
    yield put(getUserListDownSuccess(response))
  } catch (error) {
    yield put(getUserListDownFail(error))
  }
}
function* fetchCompaniesAvm() {
  try {
    const response = yield call(getCompaniesAvm)
    // localStorage.setItem("companies", JSON.stringify(response))
    yield put(getCompaniesAvmSuccess(response))
  } catch (error) {
    yield put(getCompaniesAvmFail(error))
  }
}
function* fetchCompanies() {
  try {
    const response = yield call(getCompanies)
    // localStorage.setItem("companies", JSON.stringify(response))
    yield put(getCompaniesSuccess(response))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}
function* onGetCompanyById({ payload: id }) {
  try {
    const response = yield call(getCompanyById, id)
    yield put(getCompanyByIdSuccess(response))
  } catch (error) {
    yield put(getCompanyByIdFail(error))
  }
}
function* onGetCompanyInfoByQr({ payload: id }) {
  try {
    const response = yield call(getCompanyInfoByQr, id)
    yield put(getCompanyInfoByQrSuccess(response))
  } catch (error) {
    yield put(getCompanyInfoByQrFail(error))
  }
}
function* onGetCompanyByQr({ payload: id }) {
  try {
    const response = yield call(getCompanyByQr, id)
    yield put(getCompanyByQrSuccess(response))
  } catch (error) {
    yield put(getCompanyByQrFail(error))
  }
}
function* onDeleteCompanies({ payload: id }) {
  try {
    const response = yield call(delCompany, id)
    yield put(deleteCompanySuccess(response))
  } catch (error) {
    yield put(deleteCompanyFail(error))
  }
}

function* onAddCompany({ payload: company }) {
  try {
    const response = yield call(addCompany, company)
    yield put(addCompanySuccess(response))
  } catch (error) {
    yield put(addCompanyFail(error))
  }
}
function* onUpdateCompanyQrPhoto({ payload: companyId, file: file }) {
  try {
    const response = yield call(updateCompanyQrPhoto, companyId, file)
    yield put(updateCompanyQrSuccess(response))
  } catch (error) {
    yield put(updateCompanyQrFail(error))
  }
}

function* onDeleteCompanyQrPhoto({ payload: companyId }) {
  try {
    const response = yield call(delCompanyQRPhoto, companyId)
    yield put(deleteCompanyQRPhotoSuccess(response))
  } catch (error) {
    yield put(deleteCompanyQRPhotoFail(error))
  }
}

function* onUpdateCompany({ payload: companyId, file: file }) {
  try {
    const response = yield call(updateCompany, companyId, file)
    yield put(updateCompanySuccess(response))
  } catch (error) {
    yield put(updateCompanyFail(error))
  }
}

function* onGetCompanyUsers({ payload: id }) {
  try {
    const response = yield call(getCompanyUsers, id)
    yield put(getCompanyUsersSuccess(response))
  } catch (error) {
    yield put(getCompanyUsersFail(error))
  }
}


function* onGetRoles() {
  try {
    const response = yield call(getRoles)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* onGetUserRoles({ payload: id }) {
  try {
    const response = yield call(getUserRoles, id)
    yield put(getUserRolesSuccess(response))
  } catch (error) {
    yield put(getUserRolesFail(error))
  }
}

function* onAddCompanyUser({ payload: user }) {
  try {
    const response = yield call(addCompanyUser, user)
    yield put(addCompanyUserSuccess(response))
  } catch (error) {
    yield put(addCompanyUserFail(error))
  }
}

function* onDeleteCompanyUser({ payload: id }) {
  try {
    const response = yield call(deleteCompanyUser, id)
    yield put(deleteCompanyUserSuccess(response))
  } catch (error) {
    yield put(deleteCompanyUserFail(error))
  }
}

function* onUpdateCompanyUser({ payload: user }) {
  try {
    const response = yield call(updateCompanyUser, user)
    yield put(updateCompanyUserSuccess(response))
  } catch (error) {
    yield put(updateCompanyUserFail(error))
  }
}
function* onUserMail({ payload: email }) {
  try {
    const response = yield call(userMail, email)
    yield put(userMailSuccess(response))
  } catch (error) {
    yield put(userMailFail(error))
  }
}

function* onAddUserRoles({ payload: userRoles }) {
    try {
      const response = yield call(addUserRoles, userRoles)
      yield put(addUserRolesSuccess(response))
    } catch (error) {
      yield put(addUserRolesFail(error))
    }
  }

export function* companiesSaga() {
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(DELETE_COMPANY, onDeleteCompanies)
  yield takeEvery(ADD_COMPANY, onAddCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(GET_COMPANY_USERS, onGetCompanyUsers)
  yield takeEvery(USER_MAIL, onUserMail)
  yield takeEvery(ADD_COMPANY_USER, onAddCompanyUser)
  yield takeEvery(DELETE_COMPANY_USER, onDeleteCompanyUser)
  yield takeEvery(UPDATE_COMPANY_USER, onUpdateCompanyUser)
  yield takeEvery(GET_ROLES, onGetRoles)
  yield takeEvery(GET_USER_ROLES, onGetUserRoles) 
  yield takeEvery(ADD_USER_ROLES, onAddUserRoles)
  yield takeEvery(GET_COMPANY_BY_ID, onGetCompanyById) 
  yield takeEvery(GET_COMPANY_BY_QR, onGetCompanyByQr) 
  yield takeEvery(GET_COMPANY_INFO_BY_QR, onGetCompanyInfoByQr) 
  yield takeEvery(GET_USER_LIST_DOWN, fetchUserListDown) 
  yield takeEvery(UPDATE_COMPANY_QR, onUpdateCompanyQrPhoto) 
  yield takeEvery(DELETE_COMPANY_QRPHOTO, onDeleteCompanyQrPhoto)
  yield takeEvery(GET_COMPANIES_AVM, fetchCompaniesAvm) 
  yield takeEvery(UPDATE_COMPANY_CLASS, onUpdateCompanyClass) 

  
}

export default companiesSaga
