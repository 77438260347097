import {
    GET_DUTYS,
    GET_DUTYS_SUCCESS,
    GET_DUTYS_FAIL,
    GET_DUTY_DETAIL,
    GET_DUTY_DETAIL_SUCCESS,
    GET_DUTY_DETAIL_FAIL,
    ADD_DUTY,
    ADD_DUTY_SUCCESS,
    ADD_DUTY_FAIL,
    UPDATE_DUTY,
    UPDATE_DUTY_SUCCESS,
    UPDATE_DUTY_FAIL,
    DELETE_DUTY,
    DELETE_DUTY_SUCCESS,
    DELETE_DUTY_FAIL,
    RESET_DUTY,
   
} from "./actionTypes";


export const resetDuty = () => ({
    type: RESET_DUTY,
});

export const getDutys = () => ({
    type: GET_DUTYS,
});

export const getDutysSuccess = (duty) => ({
    type: GET_DUTYS_SUCCESS,
    payload: duty,
});


export const getDutysFail = (error) => ({
    type: GET_DUTYS_FAIL,
    payload: error,
});

export const deleteDuty = (id) => ({
    type:  DELETE_DUTY,
    payload: id
});

export const deleteDutySuccess = (data) => ({
    type: DELETE_DUTY_SUCCESS,
    payload: data
});

export const deleteDutyFail = (error) => ({
    type: DELETE_DUTY_FAIL,
    payload: error,
});


export const addDuty = (duty) => ({
    type: ADD_DUTY,
    payload: duty
});

export const addDutySuccess = (data) => ({
    type: ADD_DUTY_SUCCESS,
    payload: data
});

export const addDutyFail = (error) => ({
    type: ADD_DUTY_FAIL,
    payload: error,
});


export const updateDuty = (duty) => ({
    type: UPDATE_DUTY,
    payload: duty
});

export const updateDutySuccess = (data) => ({
    type: UPDATE_DUTY_SUCCESS,
    payload: data
});

export const updateDutyFail = (error) => ({
    type: UPDATE_DUTY_FAIL,
    payload: error,
});