/** Get APP_FORM_OUTSIDE */
export const GET_APP_FORM_OUTSIDES = "GET_APP_FORM_OUTSIDES";
export const GET_APP_FORM_OUTSIDES_SUCCESS = "GET_APP_FORM_OUTSIDES_SUCCESS";
export const GET_APP_FORM_OUTSIDES_FAIL = "GET_APP_FORM_OUTSIDES_FAIL";

export const GET_APP_FORM_OUTSIDE_DETAIL = "GET_APP_FORM_OUTSIDE_DETAIL";
export const GET_APP_FORM_OUTSIDE_DETAIL_SUCCESS = "GET_APP_FORM_OUTSIDE_DETAIL_SUCCESS";
export const GET_APP_FORM_OUTSIDE_DETAIL_FAIL = "GET_APP_FORM_OUTSIDE_DETAIL_FAIL";


export const ADD_APP_FORM_OUTSIDE = "ADD_APP_FORM_OUTSIDE";
export const ADD_APP_FORM_OUTSIDE_SUCCESS = "ADD_APP_FORM_OUTSIDE_SUCCESS";
export const ADD_APP_FORM_OUTSIDE_FAIL = "ADD_APP_FORM_OUTSIDE_FAIL";

export const UPDATE_APP_FORM_OUTSIDE = "UPDATE_APP_FORM_OUTSIDE";
export const UPDATE_APP_FORM_OUTSIDE_SUCCESS = "UPDATE_APP_FORM_OUTSIDE_SUCCESS";
export const UPDATE_APP_FORM_OUTSIDE_FAIL = "UPDATE_APP_FORM_OUTSIDE_FAIL";

export const STATUS_UPDATE_APP_FORM_OUTSIDE = "STATUS_UPDATE_APP_FORM_OUTSIDE";
export const STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS = "STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS";
export const STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL = "STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL";

export const DESC_UPDATE_APP_FORM_OUTSIDE = "DESC_UPDATE_APP_FORM_OUTSIDE";
export const DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS = "DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS";
export const DESC_UPDATE_APP_FORM_OUTSIDE_FAIL = "DESC_UPDATE_APP_FORM_OUTSIDE_FAIL";

 
export const GET_MY_FORMS = "GET_MY_FORMS";
export const GET_MY_FORMS_SUCCESS = "GET_MY_FORMS_SUCCESS";
export const GET_MY_FORMS_FAIL = "GET_MY_FORMS_FAIL";

export const GET_MY_FORMS_STATUS_FALSE = "GET_MY_FORMS_STATUS_FALSE"; 
export const GET_MY_FORMS_STATUS_FALSE_SUCCESS = "GET_MY_FORMS_STATUS_FALSE_SUCCESS";
export const GET_MY_FORMS_STATUS_FALSE_FAIL = "GET_MY_FORMS_STATUS_FALSE_FAIL";

export const UPDATE_MY_FORMS = "UPDATE_MY_FORMS";
export const UPDATE_MY_FORMS_SUCCESS = "UPDATE_MY_FORMS_SUCCESS";
export const UPDATE_MY_FORMS_FAIL = "UPDATE_MY_FORMS_FAIL";

export const UPDATE_MY_FORMS_STATUS_FALSE = "UPDATE_MY_FORMS_STATUS_FALSE";
export const UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS = "UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS";
export const UPDATE_MY_FORMS_STATUS_FALSE_FAIL = "UPDATE_MY_FORMS_STATUS_FALSE_FAIL"; 

export const UPDATE_MY_FORMS_STATUS_TRUE = "UPDATE_MY_FORMS_STATUS_TRUE";
export const UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS = "UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS";
export const UPDATE_MY_FORMS_STATUS_TRUE_FAIL = "UPDATE_MY_FORMS_STATUS_TRUE_FAIL"; 

export const GET_MY_FORMS_STATUS_TRUE = "GET_MY_FORMS_STATUS_TRUE";
export const GET_MY_FORMS_STATUS_TRUE_SUCCESS = "GET_MY_FORMS_STATUS_TRUE_SUCCESS";
export const GET_MY_FORMS_STATUS_TRUE_FAIL = "GET_MY_FORMS_STATUS_TRUE_FAIL"; 

export const DELETE_APP_FORM_OUTSIDE = "DELETE_APP_FORM_OUTSIDE";
export const DELETE_APP_FORM_OUTSIDE_SUCCESS = "DELETE_APP_FORM_OUTSIDE_SUCCESS";
export const DELETE_APP_FORM_OUTSIDE_FAIL = "DELETE_APP_FORM_OUTSIDE_FAIL";

export const DELETE_MY_FORMS = "DELETE_MY_FORMS";
export const DELETE_MY_FORMS_SUCCESS = "DELETE_MY_FORMS_SUCCESS"; 
export const DELETE_MY_FORMS_FAIL = "DELETE_MY_FORMS_FAIL";

export const REJECT_APP_FORM_OUTSIDE = "REJECT_APP_FORM_OUTSIDE";
export const REJECT_APP_FORM_OUTSIDE_SUCCESS = "REJECT_APP_FORM_OUTSIDE_SUCCESS";
export const REJECT_APP_FORM_OUTSIDE_FAIL = "REJECT_APP_FORM_OUTSIDE_FAIL";

export const DELETE_MY_FORM_STATUS_TRUE = "DELETE_MY_FORM_STATUS_TRUE";
export const DELETE_MY_FORM_STATUS_TRUE_SUCCESS = "DELETE_MY_FORM_STATUS_TRUE_SUCCESS";
export const DELETE_MY_FORM_STATUS_TRUE_FAIL = "DELETE_MY_FORM_STATUS_TRUE_FAIL";

export const DELETE_MY_FORM_STATUS_FALSE = "DELETE_MY_FORM_STATUS_FALSE";
export const DELETE_MY_FORM_STATUS_FALSE_SUCCESS = "DELETE_MY_FORM_STATUS_FALSE_SUCCESS";
export const DELETE_MY_FORM_STATUS_FALSE_FAIL = "DELETE_MY_FORM_STATUS_FALSE_FAIL";

export const GET_FORMS_DOWN = "GET_FORMS_DOWN";
export const GET_FORMS_DOWN_SUCCESS = "GET_FORMS_DOWN_SUCCESS";
export const GET_FORMS_DOWN_FAIL = "GET_FORMS_DOWN_FAIL";

export const ACCEPT_APP_FORM_OUTSIDE = "ACCEPT_APP_FORM_OUTSIDE";
export const ACCEPT_APP_FORM_OUTSIDE_SUCCESS = "ACCEPT_APP_FORM_OUTSIDE_SUCCESS";
export const ACCEPT_APP_FORM_OUTSIDE_FAIL = "ACCEPT_APP_FORM_OUTSIDE_FAIL";


export const RESET_APP_FORM_OUTSIDE = "RESET_APP_FORM_OUTSIDE";