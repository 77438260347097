import {
    GET_REQUEST_FORM_PLANS,
    GET_REQUEST_FORM_PLANS_SUCCESS,
    GET_REQUEST_FORM_PLANS_FAIL,
    GET_REQUEST_FORM_PLAN_DETAIL,
    GET_REQUEST_FORM_PLAN_DETAIL_SUCCESS,
    GET_REQUEST_FORM_PLAN_DETAIL_FAIL,
    ADD_REQUEST_FORM_PLAN,
    ADD_REQUEST_FORM_PLAN_SUCCESS,
    ADD_REQUEST_FORM_PLAN_FAIL,
    UPDATE_REQUEST_FORM_PLAN,
    UPDATE_REQUEST_FORM_PLAN_SUCCESS,
    UPDATE_REQUEST_FORM_PLAN_FAIL,
    DELETE_REQUEST_FORM_PLAN,
    DELETE_REQUEST_FORM_PLAN_SUCCESS,
    DELETE_REQUEST_FORM_PLAN_FAIL,
    RESET_REQUEST_FORM_PLAN,
    UPDATE_ACTION,
    UPDATE_ACTION_SUCCESS, 
    UPDATE_ACTION_FAIL,
    UPDATE_STATUS, 
    UPDATE_STATUS_SUCCESS, 
    UPDATE_STATUS_FAIL,
    GET_REQ_FORMS_DOWN,
    GET_REQ_FORMS_DOWN_SUCCESS, 
    GET_REQ_FORMS_DOWN_FAIL
     
} from "./actionTypes";
 
export const resetRequestFormPlan = () => ({ 
    type: RESET_REQUEST_FORM_PLAN,
}); 
export const getReqFormsDown = (branchId) => ({
    type: GET_REQ_FORMS_DOWN, 
    payload:branchId
}); 
export const getReqFormsDownSuccess = (data) => ({ 
    type: GET_REQ_FORMS_DOWN_SUCCESS,
    payload: data 
});
export const getReqFormsDownFail = (error) => ({
    type: GET_REQ_FORMS_DOWN_FAIL, 
    payload: error,
});

export const getRequestFormPlans = () => ({
    type: GET_REQUEST_FORM_PLANS,
});

export const getRequestFormPlansSuccess = (requestFormPlans) => ({
    type: GET_REQUEST_FORM_PLANS_SUCCESS,
    payload: requestFormPlans,
});


export const getRequestFormPlansFail = (error) => ({
    type: GET_REQUEST_FORM_PLANS_FAIL,
    payload: error,
});

export const deleteRequestFormPlan = (id) => ({
    type:  DELETE_REQUEST_FORM_PLAN,
    payload: id
});

export const deleteRequestFormPlanSuccess = (data) => ({
    type: DELETE_REQUEST_FORM_PLAN_SUCCESS,
    payload: data
});

export const deleteRequestFormPlanFail = (error) => ({
    type: DELETE_REQUEST_FORM_PLAN_FAIL,
    payload: error,
});


export const addRequestFormPlan = (requestFormPlan) => ({ 
    type: ADD_REQUEST_FORM_PLAN,
    payload: requestFormPlan,
     
});

export const addRequestFormPlanSuccess = (data) => ({
    type: ADD_REQUEST_FORM_PLAN_SUCCESS,
    payload: data
});

export const addRequestFormPlanFail = (error) => ({
    type: ADD_REQUEST_FORM_PLAN_FAIL,
    payload: error, 
});


export const updateRequestFormPlan = (requestFormPlan) => ({
    type: UPDATE_REQUEST_FORM_PLAN,
    payload: requestFormPlan,
   
});

export const updateRequestFormPlanSuccess = (data) => ({
    type: UPDATE_REQUEST_FORM_PLAN_SUCCESS,
    payload: data
});

export const updateRequestFormPlanFail = (error) => ({
    type: UPDATE_REQUEST_FORM_PLAN_FAIL,
    payload: error,
});

export const updateAction = (requestFormPlan) => ({
    type: UPDATE_ACTION,
    payload: requestFormPlan,
   
});

export const updateActionSuccess = (data) => ({
    type: UPDATE_ACTION_SUCCESS,
    payload: data
}); 

export const updateActionFail = (error) => ({
    type: UPDATE_ACTION_FAIL,
    payload: error,
});
export const updateStatus = (requestFormPlan) => ({
    type: UPDATE_STATUS,
    payload: requestFormPlan,
   
});

export const updateStatusSuccess = (data) => ({
    type: UPDATE_STATUS_SUCCESS,
    payload: data
});

export const updateStatusFail = (error) => ({
    type: UPDATE_STATUS_FAIL,
    payload: error,
});


