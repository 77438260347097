import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
  DropdownItem
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
import { defaultsDeep, isEmpty, map } from "lodash"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    addDutiesForm as onAddDutiesForm, 
    getDutiesForms as onGetDutiesForms, 
    deleteDutiesForm as onDeleteDutiesForm, 
    updateDutiesForm as onUpdateDutiesForm, 
    getForeignLanguages as onGetForeignLanguages,
    getPrograms as onGetPrograms,
    getSectorForms as onGetSectorForms,
    getDutyForms as onGetDutyForms,
    getDutys as onGetDuty,
} from "store/actions"  
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import ApplicationPlanModal from "./Modals/applicationPlanModal"
import {
    ASKERLIK_DURUMU_SEC,
    AYRILMA_NEDENI_SEC,
    CINSIYET_SEC,
    DIL_SEC,
    EGITIM_DURUMU_SEC,
    EHLIYET_BILGISI_SEC,
    GOREV_SEC,
    MEDENI_DURUM_SEC,
    PROGRAM_BILGISI_SEC,
    SEKTOR_SEC,
    SEVIYE_SEC,
    SURE_SEC_YIL,
    MAAS_BEKLENTISI
  } from "./helper/options"


const DutiesForm = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const dispatch = useDispatch()
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [selectedId, setSelectedId] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDuty, setSelectedDuty] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [planObjects, setPlanObjects] = useState([
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    }, 
  ])
  const { SearchBar } = Search;
  useEffect(() => { 
    dispatch(onGetDutiesForms());  
    dispatch(onGetForeignLanguages())
    dispatch(onGetPrograms())
    dispatch(onGetSectorForms())
    dispatch(onGetDutyForms())
    dispatch(onGetDuty())
    }, [dispatch]);
   
  const { error, DutiesForms,ForeignLanguages,Programs,SectorForms,DutyForms } = useSelector(state => ({
    error: state.DutiesForms?.error,
    DutiesForms: state.DutiesForm?.data,
    Programs: state.Program?.data,
    SectorForms: state.SectorForm?.data,
    DutyForms: state.DutyForm?.data,
    ForeignLanguages: state.ForeignLanguage?.data,
   
})) 

let tempForeignLanguages = "Dil Seç"
let tempProgram = "Program Seç"
let tempSectorForms = "Sektör Seç"
let tempDutyForms = "Görev Seç"
const InputAddReset = () => {
    let tempInput = [
      {
        title: "Cinsiyet",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: CINSIYET_SEC,
        key: "cinsiyet",
        targetValue: "",
      },
      {
        title: "Aile ve Medeni Durum",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MEDENI_DURUM_SEC,
        key: "medeniDurum",
        targetValue: "",
      },
      {
        title: "Eğitim Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: EGITIM_DURUMU_SEC,
        key: "egitimDurumu",
        targetValue: "",
      },
      {
        title: "Askerlik Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: ASKERLIK_DURUMU_SEC,
        key: "askerlikDurumu",
        targetValue: "",
      },
      {
        title: "Program Bilgisi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Program Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: tempProgram,
          },
          {
            title: "Bilgi Derecesi",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],

        key: "programBilgisi",
        targetValue: "",
      },
      {
        title: "Yabancı Dil",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Dil",
            isType: "dropdown",
            isValue: "",
            isOptions: DIL_SEC,
          },
          {
            title: "Konuşma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Yazma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Okuma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],
        key: "yabanciDil",
        targetValue: "",
      },
      {
        title: "Ehliyet",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Ehliyet Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: EHLIYET_BILGISI_SEC,
          },
          {
            title: "Süre Seç",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
        ],
        key: "ehliyet",
        targetValue: "",
      },
      {
        title: "İş Deneyimi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Firma Unvanı",
            isType: "text",
            isValue: "",
            isOptions: "",
          },
          {
            title: "Görev",
            isType: "dropdown",
            isValue: "",
            isOptions: tempDutyForms,
          },
          {
            title: "Sektör",
            isType: "dropdown",
            isValue: "",
            isOptions: tempSectorForms,
          },
          {
            title: "Süre",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
          {
            title: "Ayrılma Nedeni",
            isType: "dropdown",
            isValue: "",
            isOptions: AYRILMA_NEDENI_SEC,
          },
          {
            title: "Giriş Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
          {
            title: "Çıkış Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
        ],
        key: "isDeneyimi",
        targetValue: "",
      },
      {
        title: "Maaş Beklentisi",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MAAS_BEKLENTISI,
        key: "maasBeklentisi",
        targetValue: "",
      },
    ]
   
    setPlanObjects(tempInput)
  }
  if (!isEmpty(DutyForms)) {
    DutyForms.map((item, index) => {
      tempDutyForms = tempDutyForms + "," + item.name.name
    })
  } else {
    tempDutyForms = "Görev Seç,Satış Danışmanı,Karşılama Hostesi,Kasa Elemanı,Depo Elemanı,Ütücü,Servis Elemanı,Barista,Komi / Garson,Mutfak Elemanı,Usta,Müdür Yardımcısı,Diğer"
  }
  if (!isEmpty(ForeignLanguages)) {
    ForeignLanguages.map((item, index) => {
      tempForeignLanguages = tempForeignLanguages + "," + item.name.name
    })
  } else {
    tempForeignLanguages = "Dil Seç,İngilizce,Almanca,Çince"
  }
  if (!isEmpty(SectorForms)) {
    SectorForms.map((item, index) => {
      tempSectorForms = tempSectorForms + "," + item.name.name
    })
  } else {
    tempSectorForms = "Sektör Seç,Hazır Giyim,Ev Tekstili,Kişisel Bakım,Elektronik Ürünler,Telekomünikasyon,Süpermarket,Mobilya,Kitap / Kırtasiye,Otel,Restorant / Kafe,Diğer"
  }
  if (!isEmpty(Programs)) {
    Programs.map((item, index) => {
      tempProgram = tempProgram + "," + item.name.name
    })
  } else {
    tempProgram = "Program Seç,Excel,Word,Outlook,Perakende Yazılımı Perakende Yazılımı / Nebim,Perakende Yazılımı / Epos,Diğer"
  }
  const columns = [
    {
      dataField: "DutyId",
      text: props.t("Position Name"),
      formatter: (cell, row) => <span>{cell?.description}</span>,
    },
    {
      dataField: "description",
      text: props.t("Form Tanımı"),
      formatter: (cell, row) => (
        <span style={{ whiteSpace: "normal" }}>{cell}</span>
      ),
    },
   

    { 
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end"> 
          
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)

                
                  setSelectedDuty(row?.DutyId?._id)

                

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                  
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]
   
    const defaultSorted = [{ 
        dataField: 'createdAt',
        order: 'desc'
      }];
      
      const pageOptions = {
        sizePerPage: 10,
        totalSize:  DutiesForms?.size, // replace later with size(customers),
        custom: true,
      }
    const toggle = () => {
        setModal(!modal)
    }
  
 
  const handleValidProductSubmit = (e, values) => {
    if (isEdit) {
        const updateDutiesForm = {
            _id: selectedPayment._id,
            name: values["name"],
         
            
        };
        
        dispatch(onUpdateDutiesForm(updateDutiesForm)); 
    } else {
        const newDutiesForm = {
            
            name: values["name"],
           
           
        }
       
        dispatch(onAddDutiesForm(newDutiesForm))
    }
    toggle()
    
}

  return ( 
    <React.Fragment>
    <div className="page-content">
        <MetaTags>
            <title>{props.t("Görev Formları")}</title>
        </MetaTags>
        <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title={props.t("Görev Formları")} breadcrumbItem={props.t("Görev Formu Ekle")} addBtn={true} addBtnCallBack={() => {
                setIsEdit(false);
                setSelectedPlan({
                    name: "",
                    description: "",
    
                    PlanObjects: [],
                  })
                setSelectedPayment(
                    {
                        companyId: "", 
                        name: "",
                        description: "" ,
                        amount: "",
                       // PaymentPlanObjects: "",
                       
                    }
                );
                InputAddReset()
                     
                toggle();

            }} />

            {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

            <Card>
                <CardBody>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={DutiesForms}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField='_id'
                                columns={columns}
                                data={DutiesForms}
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>

                                        <Row className="mb-2">
                                            <Col md="4">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitProps.searchProps}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div> 
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={"_id"}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                                }
                            </ToolkitProvider>
                        )
                        }</PaginationProvider>

                    

                   
                </CardBody>
            </Card>

            <ApplicationPlanModal
            modal={modal} 
            isEdit={isEdit}
            selectedPlan={selectedPlan}
            selectedDutyId={selectedDuty}
            planObjects={planObjects}
            foreignLang={tempForeignLanguages}
            dutyForm={tempDutyForms}
            sectorForm={tempSectorForms} 
            program={tempProgram}
            comeFrom={"plan"}
            toggle={() => {
              toggle()
            }}
            save={(e, _body) => {
             
              if (e == true) {
                if (_body?.branchLength > 1) {
                  null
                } else {
                  console.log("buraya g")
                  setQrListData({
                    BranchId: _body.BranchId
                  }) 
                  toggleQrBranch()
                }
               }
             
            }}
          />
            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteDutiesForm(selectedId));
                        setSelectedId(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}


            {/* {error && error ? (
                <SweetAlert
                    title={props.t("Error")}
                    warning
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        dispatch(onResetPayment())
                    }}
                >
                    {JSON.stringify(error)}
                </SweetAlert>
            ) : null} */}

        </Container>
    </div>
</React.Fragment>
  )
}


export default withRouter(withTranslation()(DutiesForm))
