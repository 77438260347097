import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
    Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
    DropdownItem
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getMyForms as onGetMyForms,
    getMyFormsLength as onGetMyFormsLength,
    getCompanyFormsByDate as onGetCompanyFormsByDate,
    getCompanyFormsByDateYesterday as onGetCompanyFormsByDateYesterday,
    getCompanyFormsByDateMonth as onGetCompanyFormsByDateMonth,
    getCompanyQrByDate as onGetCompanyQrByDate,
    getCompanyQrByDateYesterday as onGetCompanyQrByDateYesterday,
    getCompanyQrByDateMonth as onGetCompanyQrByDateMonth,
    getCompanyQrByDateAll as onGetCompanyQrByDateAll,
    getCompanyFormsIsMatchByDate as onGetCompanyFormsIsMatchByDate,
    getCompanyFormsIsMatchByDateYesterday as onGetCompanyFormsIsMatchByDateYesterday,
    getCompanyFormsIsMatchByDateMonth as onGetCompanyFormsIsMatchByDateMonth,
    getCompanyBranches as onGetCompanyBranches,

    getBranchFormsByDate as onGetBranchFormsByDate,
    getBranchFormsByDateYesterday as onGetBranchFormsByDateYesterday,
    getBranchFormsByDateMonth as onGetBranchFormsByDateMonth,
    getBranchQrByDate as onGetBranchQrByDate,
    getBranchQrByDateYesterday as onGetBranchQrByDateYesterday,
    getBranchQrByDateMonth as onGetBranchQrByDateMonth,
    getBranchQrByDateAll as onGetBranchQrByDateAll,
    getBranchFormsIsMatchByDate as onGetBranchFormsIsMatchByDate,
    getBranchFormsIsMatchByDateYesterday as onGetBranchFormsIsMatchByDateYesterday,
    getBranchFormsIsMatchByDateMonth as onGetBranchFormsIsMatchByDateMonth,

    getBranchShareQrByDateAll as onGetBranchShareQrByDateAll,
    getBranchShareQrByDate as onGetBranchShareQrByDate,
    getBranchShareQrByDateYesterday as onGetBranchShareQrByDateYesterday,
    getBranchShareQrByDateMonth as onGetBranchShareQrByDateMonth,

    getCompanyShareQrByDateAll as onGetCompanyShareQrByDateAll,
    getCompanyShareQrByDate as onGetCompanyShareQrByDate,
    getCompanyShareQrByDateYesterday as onGetCompanyShareQrByDateYesterday,
    getCompanyShareQrByDateMonth as onGetCompanyShareQrByDateMonth,
 
    getBranchBranches as onGetBranchBranches,
    addVisit as onAddVisit
} from "store/actions"
import config from "config"
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from "moment"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import ReportCard from "./Modals/ReportCard"
import Multiselect from "multiselect-react-dropdown"
import { isEmpty } from "lodash"


const Duty = props => {
    let user = JSON.parse(localStorage.getItem("authUser"))

    const dispatch = useDispatch()
    const [allBranches, setAllBranches] = useState()
    const [date, setDate] = useState()
    const defaultValue = moment(new Date()).format("YYYY-MM-DD");// new Date(datet).toISOString().split('T')[0] // yyyy-mm-dd
    useEffect(() => {
        dispatch(onGetCompanyBranches(user.companyId))
        setDate(moment(new Date()).format("YYYY-MM-DD"))
        dispatch(onGetMyForms(user?.companyId));
        dispatch(onGetMyFormsLength(user?.companyId));
        const getYear = (date) => {
            return moment(date).format("YYYY");
        }
        const getMonth = (date) => {
            return moment(date).format("MM");
        }
        let dateNow = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let _startDate = moment([getYear(dateNow), getMonth(dateNow) - 1]);

        const CompanyFormsToday = {
            CompanyId: user?.companyId,
            startDate: moment(new Date()).format("DD-MM-YYYY"),
            endDate: moment(new Date()).format("DD-MM-YYYY")
        }
        dispatch(onGetCompanyFormsByDate(CompanyFormsToday))
        dispatch(onGetCompanyQrByDate(CompanyFormsToday))
        dispatch(onGetCompanyFormsIsMatchByDate(CompanyFormsToday))
        dispatch(onGetCompanyShareQrByDate(CompanyFormsToday))
        const CompanyFormsYesterday = {
            CompanyId: user?.companyId,
            startDate: moment(yesterday).format("DD-MM-YYYY"),
            endDate: moment(yesterday).format("DD-MM-YYYY")
        }
        dispatch(onGetCompanyFormsByDateYesterday(CompanyFormsYesterday))
        dispatch(onGetCompanyQrByDateYesterday(CompanyFormsYesterday))
        dispatch(onGetCompanyFormsIsMatchByDateYesterday(CompanyFormsYesterday))
        dispatch(onGetCompanyShareQrByDateYesterday(CompanyFormsYesterday))
        const CompanyFormsMonth = {
            CompanyId: user?.companyId,
            startDate: moment(_startDate).format("DD-MM-YYYY"),
            endDate: moment(_startDate).endOf('month').format("DD-MM-YYYY")
        }
        dispatch(onGetCompanyFormsByDateMonth(CompanyFormsMonth))
        dispatch(onGetCompanyQrByDateMonth(CompanyFormsMonth))
        dispatch(onGetCompanyFormsIsMatchByDateMonth(CompanyFormsMonth))
        dispatch(onGetCompanyShareQrByDateMonth(CompanyFormsMonth))
        const CompanyQrAll = {
            CompanyId: user?.companyId,
            startDate: "28-02-2021",
            endDate: moment(new Date()).format("DD-MM-YYYY")
        }
        dispatch(onGetCompanyQrByDateAll(CompanyQrAll))
        dispatch(onGetCompanyShareQrByDateAll(CompanyQrAll))
        
        //QR
        

        const visitDetailArr =[{name:"Analitik Veriler/Rapor",visitDate:Date.now()}]
        const updatePlan = {
          _id:user.uid,
          visitDetail: JSON.stringify(visitDetailArr)
        }
        dispatch(onAddVisit(updatePlan)) 
        
    }, [dispatch]);
    useEffect(() => {
       if(!isEmpty(allBranches)){
        console.log(allBranches[0]._id)
        const getYear = (date) => {
            return moment(date).format("YYYY");
        }
        const getMonth = (date) => {
            return moment(date).format("MM");
        }
        let dateNow = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let _startDate = moment([getYear(dateNow), getMonth(dateNow) - 1]);

        const BranchFormsToday = {
            BranchId: allBranches[0]._id,
            startDate: moment(new Date()).format("DD-MM-YYYY"),
            endDate: moment(new Date()).format("DD-MM-YYYY")
        }
        dispatch(onGetBranchFormsByDate(BranchFormsToday))
        dispatch(onGetBranchQrByDate(BranchFormsToday))
        dispatch(onGetBranchFormsIsMatchByDate(BranchFormsToday))
        dispatch(onGetBranchShareQrByDate(BranchFormsToday))
        const BranchFormsYesterday = {
            BranchId: allBranches[0]._id,
            startDate: moment(yesterday).format("DD-MM-YYYY"),
            endDate: moment(yesterday).format("DD-MM-YYYY")
        }
        dispatch(onGetBranchFormsByDateYesterday(BranchFormsYesterday))
        dispatch(onGetBranchQrByDateYesterday(BranchFormsYesterday))
        dispatch(onGetBranchFormsIsMatchByDateYesterday(BranchFormsYesterday))
        dispatch(onGetBranchShareQrByDateYesterday(BranchFormsYesterday))
        const BranchFormsMonth = {
            BranchId: allBranches[0]._id,
            startDate: moment(_startDate).format("DD-MM-YYYY"),
            endDate: moment(_startDate).endOf('month').format("DD-MM-YYYY")
        }
        dispatch(onGetBranchFormsByDateMonth(BranchFormsMonth))
        dispatch(onGetBranchQrByDateMonth(BranchFormsMonth))
        dispatch(onGetBranchFormsIsMatchByDateMonth(BranchFormsMonth))
        dispatch(onGetBranchShareQrByDateMonth(BranchFormsMonth))
        const BranchQrAll = {
            BranchId: allBranches[0]._id,
            startDate: "28-02-2021",
            endDate: moment(new Date()).format("DD-MM-YYYY")
        }
        dispatch(onGetBranchQrByDateAll(BranchQrAll))
        dispatch(onGetBranchShareQrByDateAll(BranchQrAll))
       }
       
    }, [allBranches]);
    const { error, MyForms, MyFormsAnalyticsLength, Reports,Branches } = useSelector(state => ({
        error: state.AppForm?.error,
        MyForms: state.AppForm?.myForms,
        MyFormsAnalyticsLength: state?.Analytics?.myFormsLength,
        Reports: state.Reports,
        Branches: state.Branchs?.Branches,
    }))
   console.log("MyForms",allBranches)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Rapor")}</title>
                </MetaTags>
                <Container fluid> 
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Rapor")} breadcrumbItem={props.t("Şirket Raporu")} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
                    <AvForm >
                  

                </AvForm>
                    <Row>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard title={"Toplam Okutulan Qr Kod"} hrColor={"#ee752d"} border={"2px solid #ee752d"}  icon={"mdi mdi-qrcode"} iconColor={"#ee752d"}
                                todayAmount={Reports?.companyQrToday}
                                yesterdayAmount={Reports?.companyQrYesterday}
                                thisMonthAmount={Reports?.companyQrMonth}
                                lastMonthAmount={Reports?.companyQrAll}

                            />

                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard
                                title={props.t("Arkadaş Önerisi")} border={"2px solid  #000b80"}icon={"bx bx-user-voice"} hrColor={" #000b80"} iconColor={" #000b80"}
                                todayAmount={Reports?.companyShareQrToday}
                                yesterdayAmount={Reports?.companyShareQrYesterday}
                                thisMonthAmount={Reports?.companyShareQrMonth}
                                lastMonthAmount={Reports?.companyShareQrAll}

                            />
                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12"> 
                            <ReportCard
                                title={props.t("Toplam Başvuru Sayısı")} border={"2px solid orange"} price={MyForms?.length} icon={"bx bx-file-blank"} hrColor={"orange"} iconColor={"orange"} 
                                todayAmount={Reports?.companyFormToday}
                                yesterdayAmount={Reports?.companyFormYesterday
                                }
                                thisMonthAmount={Reports?.companyFormMonth}
                                lastMonthAmount={MyForms?.length}
                            />
                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard
                                title={props.t("Ön Elemeyi Geçen Başvuru Sayısı")} border={"2px solid green"}icon={"bx bx-check"} hrColor={"green"} iconColor={"green"}
                                todayAmount={Reports?.companyFormIsMatchToday}
                                yesterdayAmount={Reports?.companyFormIsMatchYesterday}
                                thisMonthAmount={Reports?.companyFormIsMatchMonth}
                                lastMonthAmount={MyForms.filter(x => x.isMatch == true).length}

                            />
                        </Col>
                       

                    </Row>
                        <h4>Birim Raporu</h4>
                        <div className="mb-3 mt-2 ">
                  <Multiselect
                  
                    displayValue="name"
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function noRefCheck() { }}
                    onSearch={function noRefCheck() { }}
                    onSelect={(event) =>{setAllBranches(event)}}
                    options={Branches}
                    placeholder="Rapor Çıkılacak Birim"
                    emptyRecordMsg="Seçenek Yok"
                    selectedValues ={""}
                    disable={false}
                    singleSelect={true}
                  />
                </div>
                {!isEmpty(allBranches)?(
                    <Row>
                        <p className="mb-3">{allBranches[0].name} Biriminin Raporu</p>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard title={"Toplam Okutulan Qr Kod"} hrColor={"#ee752d"} border={"2px solid #ee752d"}  icon={"mdi mdi-qrcode"} iconColor={"#ee752d"}
                                todayAmount={Reports?.branchQrToday}
                                yesterdayAmount={Reports?.branchQrYesterday}
                                thisMonthAmount={Reports?.branchQrMonth}
                                lastMonthAmount={Reports?.branchQrAll}

                            />

                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard title={"Arkadaş Önerisi"} hrColor={"#000b80"} border={"2px solid  #000b80"}  icon={"bx bx-user-voice"} iconColor={" #000b80"}
                                todayAmount={Reports?.branchShareQrToday}
                                yesterdayAmount={Reports?.branchShareQrYesterday}
                                thisMonthAmount={Reports?.branchShareQrMonth}
                                lastMonthAmount={Reports?.branchShareQrAll}

                            />

                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12"> 
                            <ReportCard
                                title={props.t("Toplam Başvuru Sayısı")} border={"2px solid orange"} price={MyForms?.length} icon={"bx bx-file-blank"} hrColor={"orange"} iconColor={"orange"}
                                todayAmount={Reports?.branchFormToday
                                }
                                yesterdayAmount={Reports?.branchFormYesterday
                                }
                                thisMonthAmount={Reports?.branchFormMonth}
                                lastMonthAmount={MyForms.filter(x=> x?.BranchId?._id == allBranches[0]._id)?.length}
                            />
                        </Col>
                        <Col lg="3" md="6" sm="12" xs="12">
                            <ReportCard
                                title={props.t("Ön Elemeyi Geçen Başvuru Sayısı")} border={"2px solid green"}icon={"bx bx-check"} hrColor={"green"} iconColor={"green"}
                                todayAmount={Reports?.branchFormIsMatchToday}
                                yesterdayAmount={Reports?.companyFormIsMatchYesterday}
                                thisMonthAmount={Reports?.branchFormIsMatchMonth
                                }
                                lastMonthAmount={MyForms.filter(x => x.isMatch == true && x?.BranchId?._id == allBranches[0]._id ).length}

                            />
                        </Col>

                    </Row>
                ):<div style={{height:"300px"}}></div>}
                        

                </Container>
            </div>
        </React.Fragment>
    )
}


export default withRouter(withTranslation()(Duty))
