import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabContent,
  Nav,
  Form,
  FormGroup,
  TabPane,
  CardTitle,
  CardSubtitle,
  NavItem,
  NavLink,
} from "reactstrap"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

import {
  addOrientation as onAddOrientation,
  updateOrientation as onUpdateOrientation

} from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import { isEmpty, map } from "lodash"

import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { productComments } from "common/data"
import { Search } from "react-bootstrap-table2-toolkit"


const applicationPlanModal = (props, cb) => {
  const dispatch = useDispatch()
  const [activeTab, setactiveTab] = useState("1")
  const [chosenModule, setChosenModule] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [isGroupButton, setIsGroupButton] = useState([])
  const [modalInputs, setModalInputs] = useState(false)

  let user = JSON.parse(localStorage.getItem("authUser"))
  const { error, Plans, Branches, MyPlans, User } = useSelector(state => ({
    error: state.Plans?.error,
    MyPlans: state.Plans?.myPlans,
    Plans: state.Plans?.data,
    Branches: state.Branchs?.Branches,
    User: state.Plans?.user,

  }))



  const [planObjects, setPlanObjects] = useState([

    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },

  ])
  useEffect(() => {
    setPlanObjects(props.planObjects)
  }, [props.modal])
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD") || ""

      return date1
    } else {
      return ""
    }
  }

  const handleValidPlanSubmit = (e, values) => {
    if (props.isEdit) {
      const updatePlan = {
        _id: props.selectedPlan._id,
        name: values.name,
        description: values["description"],
        CompanyId: user.companyId,
        CreatorUserId: user.uid,
        PlanObjects:
          document.getElementById("PlanObjects").value ||
          JSON.stringify(planObjects),
        planDesc: document.getElementById("planDesc")?.value || planDescription,
        //PlanObjects:JSON.stringify(planObjects)
      }
      // update Plan

      dispatch(onUpdateOrientation(updatePlan))
      //console.log("updatePlan",props.selectedPlan)
    } else {
      const newPlan = {
        name: values["name"],
        description: values["description"],
        CompanyId: user.companyId,
        CreatorUserId: user.uid,
        PlanObjects: document.getElementById("PlanObjects").value,
        planDesc: document.getElementById("planDesc").value,
      }
      // save new Plan

      dispatch(onAddOrientation(newPlan))


    }
    props.toggle()
  }

  const handleChangeInput = (i, e, inputName) => {
    const values = [...planObjects]

    values[i][inputName] = e.target.value
    setPlanObjects(values)
  }
  const handleChangeInputForOpt = (i, e, optIndex, inputName) => {
    const values = [...planObjects]

    values[i].isOptions[optIndex] = e.target.value
    setPlanObjects(values)
  }
  const handleChangeInputForPlan = (i, e, optIndex, inputName) => {
    const values = [...planObjects]

    values[i].isPlan[optIndex] = e.target.value
    setPlanObjects(values)
  }
  const [inputVariables, setInputVariables] = useState([
    {
      name: "sadeceMetin",
      text: props.t("Sadece Metin")
    },
    {
      name: "onayliMetin",
      text: props.t("Onay Kutulu Metin")
    },
    {
      name: "fourchoice",
      text: props.t("Test")
    },
    {
      name: "fourSelect",
      text: props.t("Çoktan Seçmeli Test")
    },




  ])
  const handleAdd = index => {
    let temp = planObjects
    let newProjectObjects = []
    temp.splice(index + 1, 0, {
      title: "",
      isType: "test",
      isValue: "",
      isPlan: ["", "", "", ""],
      isOptions: ["", ""],
      targetValue: ""
    })

    for (let i = 0; i < temp.length; i++) {
      newProjectObjects.push(temp[i])
    }
    setPlanObjects(newProjectObjects)
  }
  const handleAddFourChoiceOptions = index => {
    let temp = [...planObjects]

    temp[index]?.isOptions.push("")
    setPlanObjects(temp)
    console.log(">>", planObjects)
    // let newProjectObjects = []
    // temp.splice(index + 1, 0, {
    //   title: "",
    //   isType: "test",
    //   isValue: "",
    //   isPlan: ["", "", "", ""],
    //   isOptions: ["", ""],
    //   targetValue: ""
    // }) 

    // for (let i = 0; i < temp.length; i++) {
    //   newProjectObjects.push(temp[i])
    // }
    // setPlanObjects(newProjectObjects)
  }

  const handleSubtractFourChoiceOptions = i => {
    const values = [...planObjects]
    values[i].isOptions.splice(i, 1)

    setPlanObjects([...values])
  }
  const handleSubtractForGroup = (i, i2) => {
    const values = [...planObjects]
    values[i]["isPlan"].splice(i2, 1)
    setPlanObjects([...values])
  }

  const handleSubtract = i => {
    const values = [...planObjects]
    values.splice(i, 1)
    setPlanObjects([...values])
  }
  const handlethreeSub = (i, i2) => {
    const values = [...planObjects]
    values[i].isOptions = ["", "", ""]
    console.log(values)
    setPlanObjects([...values])
  }
  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
  console.log("plan Object", planObjects)
  return (
    <React.Fragment>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {!!props.isEdit
            ? props.t("Oryantasyon Formu")
            : props.t("Oryantasyon Formu")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidPlanSubmit}>
            <Row form>
              <Col xs={12}>
                <input
                  id="PlanObjects"
                  name="hidden"
                  type="hidden"
                //value={JSON.stringify(planObjects) }
                />
                <input id="planDesc" name="hidden" type="hidden" />
                <div className="mb-3">
                  <AvField
                    name="name"
                    label={props.t("Orientation Form Name")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: true },
                    }}
                    value={props.selectedPlan?.name || ""}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="description"
                    label={props.t("Orientation Form Desc")}
                    type="textarea"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: false },
                    }}
                    value={
                      props.version == "v2"
                        ? ""
                        : props.selectedPlan?.description || ""
                    }
                  />
                </div>  </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end mb-3">
                  <Button
                    color="primary"
                    className="btn-rounded "
                    onClick={() => {
                      toggleInputs()
                    }}
                  >
                    {props.t("Prepare Survey")}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() =>
                      // { toggle() }
                      console.log("branchID", props.selectedBranchId)
                    }
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalInputs} toggle={toggleInputs} size="xl">
        <ModalHeader toggle={toggleInputs} tag="h4">
          {props.t("Add Form Plan")}
        </ModalHeader>
        <ModalBody>
          <small className="mb-3">
            {props.t("Please Enter Form Plan")}
          </small>
          <br /> <br />
          <AvForm>
            <Col md="4" className="mb-1">
              {props.t("Description")}
            </Col>
            <Col md="9" className="mb-3">
              <AvField
                type="textarea"
                name="planDesc"
                placeholder={props.t("Description")}
                value={props.selectedPlan?.planDesc || ""}
                onChange={e => {
                  setPlanDescription(e.target.value)
                }}
                validate={{
                  required: { value: false },
                }}
              />
            </Col>
            <Col md="4" className="mb-1">
              {props.t("Sorular")}
            </Col>
            <Row form> 
              {planObjects?.map((item, i) => (
                <div key={i}>
                  <input
                    id={"planObjects_" + (i) + "__isValue"}
                    name={"planObjects[" + (i) + "].isValue"}
                    type="hidden"
                  />

                  <Row className="mt-2">
                    <Col md="7">
                      <AvField type="select"
                        id={"planObjects_" + (i) + "__isType"}
                        name={"planObjects[" + (i) + "].isType"}
                        value={item.isType}
                        className="form-select"
                        onChange={e => handleChangeInput(i, e, "isType")}
                      >
                        <option>{props.t("Choose data type")}</option>
                        {inputVariables?.map((item, index) => (
                          <option key={index} value={item.name} >{item.text}</option>
                        ))}
                      </AvField>
                    </Col>
                    <Col md="2">

                      <div>
                        <Button onClick={() => handleAdd(i)} className="btn btn-success me-2">
                          <i className="fas fa-plus"></i>
                        </Button>
                        {planObjects.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                          <i className="fas fa-minus"></i>
                        </Button> : null}
                      </div>
                    </Col>
                    {item.isType === "sadeceMetin" || item.isType === "onayliMetin" ?
                      <Col md="12" className="mt-3 mb-3">
                        <AvField
                          type="textarea"
                          id={"planObjects_" + (i) + "__isOptions"}
                          name={"planObjects[" + (i) + "].isOptions"}
                          placeholder={props.t("Options(Sep. w/ comma)")}
                          value={" "}
                          onChange={e => handleChangeInput(i, e, "isOptions")}
                          validate={{
                            required: { value: false },
                          }}
                        />
                      </Col> : null}
                    {item.isType === "fourchoice" ?
                      <Row className="mt-2">

                        <Col md="10">
                          <AvField
                            type="textarea"
                            id={"planObjects_" + i + "__title"}
                            name={"planObjects[" + i + "].title"}
                            placeholder={props.t("Soru " + (i + 1))}
                            value={item.title}
                            onChange={e => handleChangeInput(i, e, "title")}
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </Col>


                        {planObjects[i]?.isOptions?.map((optItem, optIndex) => (
                          <div key={optIndex + "isOpt"}>
                            <Row className="mt-3">
                              <Col md="8" >
                                <AvField
                                  type="text"
                                  id={"planObjects_" + i + "__isType"}
                                  placeholder={props.t("Şık" + (optIndex + 1))}
                                  name={"planObjects[" + i + "].isType"}
                                  onChange={e => handleChangeInputForOpt(i, e, optIndex, "isOptions")}
                                  value={item.isOptions[optIndex]}>


                                </AvField>
                              </Col>
                              <Col md="2">

                                <div>
                                  <Button onClick={() => handleAddFourChoiceOptions(i)} className="btn btn-warning me-2">
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                 
                                  {planObjects[i].isOptions.length> 1  &&planObjects[i].isOptions.length ==optIndex+1  ? <Button onClick={() => handleSubtractFourChoiceOptions(i)}>
                                    <i className="fas fa-minus"></i>
                                  </Button> : null}
                                </div>
                              </Col>


                            </Row>
                          </div>
                        ))}
                      </Row>
                      : null}
                    {item.isType === "fourSelect" ?
                      <Row className="mt-2">

                        <Col md="10">
                          <AvField
                            type="textarea"
                            id={"planObjects_" + i + "__title"}
                            name={"planObjects[" + i + "].title"}
                            placeholder={props.t("Soru " + (i + 1))}
                            value={item.title}
                            onChange={e => handleChangeInput(i, e, "title")}
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </Col>


                        {planObjects[i]?.isOptions?.map((optItem, optIndex) => (
                          <div key={optIndex + "isOpt"}>
                            <Row className="mt-3">
                              <Col md="8" >
                                <AvField
                                  type="text"
                                  id={"planObjects_" + i + "__isType"}
                                  placeholder={props.t("Şık" + (optIndex + 1))}
                                  name={"planObjects[" + i + "].isType"}
                                  onChange={e => handleChangeInputForOpt(i, e, optIndex, "isOptions")}
                                  value={item.isOptions[optIndex]}>


                                </AvField>
                              </Col>
                              <Col md="2">

                                <div>
                                  <Button onClick={() => handleAddFourChoiceOptions(i)} className="btn btn-warning me-2">
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                  {planObjects[i].isOptions.length > 1 &&planObjects[i].isOptions.length ==optIndex+1 ? <Button onClick={() => handleSubtractFourChoiceOptions(i)}>
                                    <i className="fas fa-minus"></i>
                                  </Button> : null}
                                </div>
                              </Col>

                            </Row>
                          </div>
                        ))}
                      </Row>
                      : null}
                    {item.isType === "threechoice" ?

                      <Row className="mt-5">

                        <Col md="10">
                          <AvField
                            type="textarea"
                            id={"planObjects_" + i + "__title"}
                            name={"planObjects[" + i + "].title"}
                            placeholder={props.t("Soru " + (i + 1))}
                            value={item.title}
                            onChange={e => handleChangeInput(i, e, "title")}
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </Col>

                        {planObjects[i]?.isOptions?.map((optItem, optIndex) => (
                          <div key={optIndex + "isOpt"}>
                            <Row className="mt-3">
                              <Col md="8" >
                                <AvField
                                  type="text"
                                  id={"planObjects_" + i + "__isType"}
                                  placeholder={props.t("Şık" + (optIndex + 1))}
                                  name={"planObjects[" + i + "].isType"}
                                  onChange={e => handleChangeInputForOpt(i, e, optIndex, "isOptions")}
                                  value={item.isOptions[optIndex]}>


                                </AvField>
                              </Col>
                              <Col md="2">

                                <div>
                                  <Button onClick={() => handleAddFourChoiceOptions(i)} className="btn btn-success me-2">
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                  {planObjects[i].isOptions.length > 1 ? <Button onClick={() => handleSubtractFourChoiceOptions(i)}>
                                    <i className="fas fa-minus"></i>
                                  </Button> : null}
                                </div>
                              </Col>


                            </Row>
                          </div>
                        ))}
                      </Row>
                      : null}

                  </Row>



                </div>
              ))}

            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    onClick={() => {
                      document.getElementById("PlanObjects").value =
                        JSON.stringify(planObjects)

                      if (planDescription == undefined) {
                        document.getElementById("planDesc").value = ""
                      } else {
                        document.getElementById("planDesc").value =
                          planDescription
                      }

                      toggleInputs()

                    }}
                    className="btn btn-success save-user"
                  >
                    {props.t("Save")}
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      toggleInputs()
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    </React.Fragment>
  )
}

applicationPlanModal.propTypes = {
  modal: PropTypes.bool,
  cancel: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,
  save: PropTypes.func,
  modalToggle: PropTypes.bool,
  isEdit: PropTypes.any,
  selectedPlan: PropTypes.any,
  selectedBranchId: PropTypes.any,
  selectedWorkType: PropTypes.any,

  selectedPosition: PropTypes.any,
  planObjects: PropTypes.any,
  payment: PropTypes.func,
  version: PropTypes.string,
}

export default withRouter(withTranslation()(applicationPlanModal))
