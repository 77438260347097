import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_REQUEST_REASONS, DELETE_DEV_REQUEST_REASON, ADD_DEV_REQUEST_REASON, UPDATE_DEV_REQUEST_REASON,GET_DEV_REQUEST_REASONS_MY_DEV_REQUEST_REASONS} from "./actionTypes";
import {
    getDevRequestReasonsSuccess, getDevRequestReasonsFail,
    deleteDevRequestReasonSuccess, deleteDevRequestReasonFail,
    updateDevRequestReasonSuccess, updateDevRequestReasonFail,
    addDevRequestReasonSuccess, addDevRequestReasonFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevRequestReasons, //API CONNECTION
    delDevRequestReason,
    addDevRequestReason,
    updateDevRequestReason,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevRequestReasons() {
    try {
        const response = yield call(getDevRequestReasons)
        yield put(getDevRequestReasonsSuccess(response));
    } catch (error) {
        yield put(getDevRequestReasonsFail(error));
    }
}

function* onDeleteDevRequestReasons({payload:id}){
    try{
        const response = yield call(delDevRequestReason, id)
        yield put(deleteDevRequestReasonSuccess(response));
    } catch (error) {
        yield put(deleteDevRequestReasonFail(error));
    }
}

function* onAddDevRequestReason({payload:devRequestReason}){
    try{
        const response = yield call(addDevRequestReason, devRequestReason)
        yield put(addDevRequestReasonSuccess(response));
    } catch (error) {
        yield put(addDevRequestReasonFail(error));
    }
}

function* onUpdateDevRequestReason({payload:devRequestReason}){
    try{
        const response = yield call(updateDevRequestReason, devRequestReason)
        yield put(updateDevRequestReasonSuccess(response));
    } catch (error) {
        yield put(updateDevRequestReasonFail(error));
    }
}

export function* devRequestReasonsSaga() {
    yield takeEvery(GET_DEV_REQUEST_REASONS, fetchDevRequestReasons);
    yield takeEvery(DELETE_DEV_REQUEST_REASON, onDeleteDevRequestReasons);
    yield takeEvery(ADD_DEV_REQUEST_REASON, onAddDevRequestReason);
    yield takeEvery(UPDATE_DEV_REQUEST_REASON, onUpdateDevRequestReason);
   
    
}

export default devRequestReasonsSaga;
