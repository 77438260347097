import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
  FormGroup, Alert, Button, Nav,
  NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  NavLink, Card, CardBody,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classnames from "classnames"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import {

  getDutys as onGetDuties,
  acceptAppForm as onAcceptAppForm,
} from "store/actions"

const acceptedModal = (props, cb) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch()
  const [selectedBranchId, setSelectedBranchId] = useState("")
  const [activeTab, setactiveTab] = useState("1")
  const [selectedSurvey, setSelectedSurvey] = useState()
  const [dutyAlert, setDutyAlert] = useState(false)
  const [selectedPlanObject, setSelectedPlanObject] = useState([])
  const [checkSurveyModal, setCheckSurveyModal] = useState(false)
  const [date, setDate] = useState()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const { Duty } = useSelector(state => ({

    Duty: state.Duty?.data,

  }))
  useEffect(() => {


    dispatch(onGetDuties());


  }, [dispatch]);

  const handleValidPlanSubmit = (e, values) => {

    const acceptPlan = {
      _id: props.selectedForm._id,
      status: true,
      formStatus: "İşe Alınanlar",
      DutyId: values["dutyId"],
      managerMail: values["managerMail"],
      date:!isEmpty(date)?date:defaultValue
    }
   
    if (!isEmpty(values["dutyId"])) {
      dispatch(onAcceptAppForm(acceptPlan))

      props.toggle()
      props.accept(true)
    } else {
      setDutyAlert(true)

    } 


  }


  const today = new Date();
  const datet = today.setDate(today.getDate());
  const defaultValue = new Date(datet).toISOString().split('T')[0] // yyyy-mm-dd
  return (
    <React.Fragment>
      <Modal isOpen={props.modal} size="lg" toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("İşe Alınanlar")}

        </ModalHeader>

        <ModalBody>

          <AvForm onValidSubmit={handleValidPlanSubmit}>

            <Row form>
              <small style={{ position: "relative", bottom: "15px" }}>Kişiyi İşe Alacaksınız. İleride Gönderilecek Oryantasyon Formları için kişinin görevini ve kişinin müdürünün mail adresini giriniz.</small>
              <Col xs={12}>

                <div className="mb-3">
                  <AvField
                    className="form-select"
                    label={props.t("Görev Seç")}
                    type="select"
                    name="dutyId"
                    value={selectedBranchId}

                  >
                    <option>{props.t("Bütün Görevler")}</option>
                    {Duty?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.description}
                      </option>
                    ))}
                  </AvField>
                </div>


                <div className="mb-3">
                  <AvField
                    name="managerMail"
                    label={props.t("Müdür Maili")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: true },
                    }}
                    value={props.selectedPlan?.returnURL || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="date"
                    label={props.t("İşe Alınma Tarihi")}
                    type="date"
                    errorMessage="invalid Date Of Birth"
                    onChange={e => setDate(e.target.value)}
                    defaultValue={defaultValue}
                    value={""}
                  />
                  {/* <input id="dateRequired" type="date" name="dateRequired" defaultValue={defaultValue} onChange={e =>setDate(e.target.value)} /> */}
                </div>

              </Col>
            </Row>
            {dutyAlert ? (
              <Alert color="danger" role="alert">
                {props.t(
                  "Lütfen Bir Görev Seçin!"
                )}
              </Alert>
            ) : null}



            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() =>
                      props.toggle()

                    }
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    </React.Fragment>

  )
}


acceptedModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  selectedForm: PropTypes.any,
  accept: PropTypes.func,
}


export default withRouter(
  (withTranslation()(acceptedModal))
)


