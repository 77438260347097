import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  RESET_CITIES,
  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  GET_NEIGHTBORHOODS,
  GET_NEIGHTBORHOODS_SUCCESS,
  GET_NEIGHTBORHOODS_FAIL,
} from "./actionTypes"

export const resetCities = () => ({
  type: RESET_CITIES,
})

export const getCities = () => ({
  type: GET_CITIES,
})

export const getCitiesSuccess = data => ({
  type: GET_CITIES_SUCCESS,
  payload: data,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})

//DISTRICTS
export const getDistricts = (cityId) => ({
  type: GET_DISTRICTS,
  payload:cityId
})
export const getDistrictsSuccess = data => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: data,
})

export const getDistrictsFail = error => ({
  type: GET_DISTRICTS_FAIL,
  payload: error,
})

//NEIGHTBORHOODS
export const getNeighborhoods = (cityId, districtId) => ({
  type: GET_NEIGHTBORHOODS,
  payload:{cityId, districtId}
})
export const getNeighborhoodsSuccess = data => ({
  type: GET_NEIGHTBORHOODS_SUCCESS,
  payload: data,
})

export const getNeighborhoodsFail = error => ({
  type: GET_NEIGHTBORHOODS_FAIL,
  payload: error,
})