import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_APP_FORMS, DELETE_APP_FORM, ADD_APP_FORM, UPDATE_APP_FORM, GET_MY_FORMS, UPDATE_MY_FORMS, DELETE_MY_FORMS, GET_MY_FORMS_STATUS_FALSE,
     UPDATE_MY_FORMS_STATUS_FALSE,GET_MY_FORMS_STATUS_TRUE,UPDATE_MY_FORMS_STATUS_TRUE,DELETE_MY_FORM_STATUS_TRUE,DELETE_MY_FORM_STATUS_FALSE,STATUS_UPDATE_APP_FORM,
     DESC_UPDATE_APP_FORM,REJECT_APP_FORM,GET_FORMS_DOWN,ACCEPT_APP_FORM,FIRED_APP_FORM} from "./actionTypes";
import {
    getAppFormsSuccess, getAppFormsFail,
    deleteAppFormSuccess, deleteAppFormFail,
    updateAppFormSuccess, updateAppFormFail,
    addAppFormSuccess, addAppFormFail,
    getMyFormsSuccess, getMyFormsFail,
    updateMyFormSuccess, updateMyFormFail,
    deleteMyFormSuccess, deleteMyFormFail,
    getMyFormsStatusFalseSuccess, getMyFormsStatusFalseFail,
    updateMyFormStatusFalseSuccess, updateMyFormStatusFalseFail,
    getMyFormsStatusTrueSuccess,getMyFormsStatusTrueFail,
    updateMyFormStatusTrueSuccess,updateMyFormStatusTrueFail,
    deleteMyFormStatusTrueSuccess,deleteMyFormStatusTrueFail,
    deleteMyFormStatusFalseSuccess,deleteMyFormStatusFalseFail,
    statusUpdateAppFormSuccess,statusUpdateAppFormFail,
    descUpdateAppFormSuccess,descUpdateAppFormFail,
    rejectAppFormSuccess,rejectAppFormFail,
    getFormsDownSuccess,getFormsDownFail,
    acceptAppFormSuccess,acceptAppFormFail,
    firedAppFormSuccess,firedAppFormFail
} from "./actions";

//Include Both Helper File with needed methods
import {
    getAppForms, //API CONNECTION
    delAppForm,
    addAppForm,
    updateAppForm,
    getMyForms,
    getMyFormsStatusFalse,
    getMyFormsStatusTrue,
    statusUpdateAppForm,
    descUpdateAppForm,
    RejectAppForm,
    getFormsDown,
    AcceptAppForm,
    FiredAppForm
}
    from "../../helpers/fakebackend_helper";
function* fetchFormsDown({payload:branchId}) {
    try {
        const response = yield call(getFormsDown,branchId)

        yield put(getFormsDownSuccess(response));
    } catch (error) {
        yield put(getFormsDownFail(error));
    }
}
function* fetchAppForms() {
    try {
        const response = yield call(getAppForms)

        yield put(getAppFormsSuccess(response));
    } catch (error) {
        yield put(getAppFormsFail(error));
    }
}
function* fetchMyForms({ payload: id }) {
    try {
        const response = yield call(getMyForms, id)
        yield put(getMyFormsSuccess(response));
    } catch (error) {
        yield put(getMyFormsFail(error));
    }
}
function* fetchMyFormsStatusFalse({ payload: id }) {
    try {
        const response = yield call(getMyFormsStatusFalse, id)
        yield put(getMyFormsStatusFalseSuccess(response));
    } catch (error) {
        yield put(getMyFormsStatusFalseFail(error));
    }
}
function* fetchMyFormsStatusTrue({ payload: id }) {
    try {
        const response = yield call(getMyFormsStatusTrue, id)
        yield put(getMyFormsStatusTrueSuccess(response));
    } catch (error) {
        yield put(getMyFormsStatusTrueFail(error));
    }
}
function* onUpdateMyForm({ payload: form }) {
    try {
        const response = yield call(updateAppForm, form)
        yield put(updateMyFormSuccess(response))
    } catch (error) {
        yield put(updateMyFormFail(error))
    }
}

function* onUpdateMyFormStatusFalse({ payload: form }) {
    try {
        const response = yield call(updateAppForm, form)
        yield put(updateMyFormStatusFalseSuccess(response))
    } catch (error) {
        yield put(updateMyFormStatusFalseFail(error))
    }
}
function* onUpdateMyFormStatusTrue({ payload: form }) {
    try {
        const response = yield call(updateAppForm, form)
        yield put(updateMyFormStatusTrueSuccess(response))
    } catch (error) {
        yield put(updateMyFormStatusTrueFail(error))
    }
}
function* onDeleteMyForm({ payload: id }) {
    try {
        const response = yield call(delAppForm, id)
        yield put(deleteMyFormSuccess(response))
    } catch (error) {
        yield put(deleteMyFormFail(error))
    }
}
function* onDeleteMyFormStatusTrue({ payload: id }) {
    try {
        const response = yield call(delAppForm, id)
        yield put(deleteMyFormStatusTrueSuccess(response))
    } catch (error) {
        yield put(deleteMyFormStatusTrueFail(error))
    }
}
function* onDeleteMyFormStatusFalse({ payload: id }) {
    try {
        const response = yield call(delAppForm, id)
        yield put(deleteMyFormStatusFalseSuccess(response))
    } catch (error) {
        yield put(deleteMyFormStatusFalseFail(error))
    }
}
function* onDeleteAppForms({ payload: id }) {
    try {
        const response = yield call(delAppForm, id)
        yield put(deleteAppFormSuccess(response));
    } catch (error) {
        yield put(deleteAppFormFail(error));
    }
}

function* onAddAppForm({ payload: appForm, files: files }) {
    try {
        const response = yield call(addAppForm, appForm, files)
        yield put(addAppFormSuccess(response));
    } catch (error) {
        yield put(addAppFormFail(error));
    }
}


function* onUpdateAppForm({ payload: appForm }) {
    try {
        const response = yield call(updateAppForm, appForm)
        yield put(updateAppFormSuccess(response));
    } catch (error) {
        yield put(updateAppFormFail(error));
    }
}
function* onStatusUpdateAppForm({ payload: appForm }) {
    try {
        const response = yield call(statusUpdateAppForm, appForm)
        yield put(statusUpdateAppFormSuccess(response));
    } catch (error) {
        yield put(statusUpdateAppFormFail(error));
    }
}
function* onRejectAppForm({ payload: appForm }) {
    try {
        const response = yield call(RejectAppForm, appForm)
        yield put(rejectAppFormSuccess(response));
    } catch (error) {
        yield put(rejectAppFormFail(error));
    }
}
function* onFiredAppForm({ payload: appForm }) {
    try {
        const response = yield call(FiredAppForm, appForm)
        yield put(firedAppFormSuccess(response));
    } catch (error) {
        yield put(firedAppFormFail(error));
    }
}
function* onAcceptAppForm({ payload: appForm }) {
    try {
        const response = yield call(AcceptAppForm, appForm)
        yield put(acceptAppFormSuccess(response));
    } catch (error) {
        yield put(acceptAppFormFail(error));
    }
}
function* onDescUpdateAppForm({ payload: appForm }) {
    try {
        const response = yield call(descUpdateAppForm, appForm)
        yield put(descUpdateAppFormSuccess(response));
    } catch (error) {
        yield put(descUpdateAppFormFail(error));
    }
}

export function* appFormsSaga() {
    yield takeEvery(GET_APP_FORMS, fetchAppForms);
    yield takeEvery(DELETE_APP_FORM, onDeleteAppForms);
    yield takeEvery(ADD_APP_FORM, onAddAppForm);
    yield takeEvery(UPDATE_APP_FORM, onUpdateAppForm);
    yield takeEvery(GET_MY_FORMS, fetchMyForms);
    yield takeEvery(UPDATE_MY_FORMS, onUpdateMyForm);
    yield takeEvery(DELETE_MY_FORMS, onDeleteMyForm);
    yield takeEvery(DELETE_MY_FORM_STATUS_TRUE, onDeleteMyFormStatusTrue);
    yield takeEvery(DELETE_MY_FORM_STATUS_FALSE, onDeleteMyFormStatusFalse);
    yield takeEvery(GET_MY_FORMS_STATUS_FALSE, fetchMyFormsStatusFalse);
    yield takeEvery(UPDATE_MY_FORMS_STATUS_FALSE, onUpdateMyFormStatusFalse);
    yield takeEvery(UPDATE_MY_FORMS_STATUS_TRUE, onUpdateMyFormStatusTrue);
    yield takeEvery(GET_MY_FORMS_STATUS_TRUE, fetchMyFormsStatusTrue);
    yield takeEvery(STATUS_UPDATE_APP_FORM, onStatusUpdateAppForm);
    yield takeEvery(DESC_UPDATE_APP_FORM, onDescUpdateAppForm);
    yield takeEvery(REJECT_APP_FORM, onRejectAppForm);
    yield takeEvery(GET_FORMS_DOWN, fetchFormsDown);
    yield takeEvery(ACCEPT_APP_FORM, onAcceptAppForm);
    yield takeEvery(FIRED_APP_FORM, onFiredAppForm);
    
    
}

export default appFormsSaga;
