import {
    GET_SURVEYS,
    GET_SURVEYS_SUCCESS,
    GET_SURVEYS_FAIL,
    GET_SURVEY_DETAIL,
    GET_SURVEY_DETAIL_SUCCESS,
    GET_SURVEY_DETAIL_FAIL,
    ADD_SURVEY,
    ADD_SURVEY_SUCCESS,
    ADD_SURVEY_FAIL, 
    UPDATE_SURVEY,
    UPDATE_SURVEY_SUCCESS,
    UPDATE_SURVEY_FAIL,
    DELETE_SURVEY,
    DELETE_SURVEY_SUCCESS,
    DELETE_SURVEY_FAIL,
    RESET_SURVEY,
   
} from "./actionTypes";

export const getSurveyDetail = (id) => ({
    type: GET_SURVEY_DETAIL, 
    payload:id
}); 
export const getSurveyDetailSuccess = (data) => ({ 
    type: GET_SURVEY_DETAIL_SUCCESS,
    payload: data
});
export const getSurveyDetailFail = (error) => ({
    type: GET_SURVEY_DETAIL_FAIL, 
    payload: error,
});
export const resetSurvey = () => ({
    type: RESET_SURVEY,
});

export const getSurveys = () => ({
    type: GET_SURVEYS,
});

export const getSurveysSuccess = (survey) => ({
    type: GET_SURVEYS_SUCCESS,
    payload: survey,
});


export const getSurveysFail = (error) => ({
    type: GET_SURVEYS_FAIL,
    payload: error,
});

export const deleteSurvey = (id) => ({
    type:  DELETE_SURVEY,
    payload: id
});

export const deleteSurveySuccess = (data) => ({
    type: DELETE_SURVEY_SUCCESS,
    payload: data
});

export const deleteSurveyFail = (error) => ({
    type: DELETE_SURVEY_FAIL,
    payload: error,
});


export const addSurvey = (survey) => ({
    type: ADD_SURVEY,
    payload: survey
});

export const addSurveySuccess = (data) => ({
    type: ADD_SURVEY_SUCCESS,
    payload: data
});

export const addSurveyFail = (error) => ({
    type: ADD_SURVEY_FAIL,
    payload: error,
});


export const updateSurvey = (survey) => ({
    type: UPDATE_SURVEY,
    payload: survey
});

export const updateSurveySuccess = (data) => ({
    type: UPDATE_SURVEY_SUCCESS,
    payload: data
});

export const updateSurveyFail = (error) => ({
    type: UPDATE_SURVEY_FAIL,
    payload: error,
});