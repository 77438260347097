import {
    GET_DEV_DUTY_FORMS,
    GET_DEV_DUTY_FORMS_SUCCESS,
    GET_DEV_DUTY_FORMS_FAIL,
    GET_DEV_DUTY_FORM_DETAIL,
    GET_DEV_DUTY_FORM_DETAIL_SUCCESS,
    GET_DEV_DUTY_FORM_DETAIL_FAIL,
    ADD_DEV_DUTY_FORM,
    ADD_DEV_DUTY_FORM_SUCCESS,
    ADD_DEV_DUTY_FORM_FAIL,
    UPDATE_DEV_DUTY_FORM,
    UPDATE_DEV_DUTY_FORM_SUCCESS,
    UPDATE_DEV_DUTY_FORM_FAIL,
    DELETE_DEV_DUTY_FORM,
    DELETE_DEV_DUTY_FORM_SUCCESS,
    DELETE_DEV_DUTY_FORM_FAIL,
    RESET_DEV_DUTY_FORM,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevDutyForms = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_DUTY_FORMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_DUTY_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devDutyForm => devDutyForm._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_DUTY_FORM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_DUTY_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.map(devDutyForm =>
                    devDutyForm._id.toString() === action.payload.data._id.toString() ?
                        { devDutyForm, ...action.payload.data } :
                        devDutyForm
                )
            }

        //FAILS
        case GET_DEV_DUTY_FORMS_FAIL:
        case DELETE_DEV_DUTY_FORM_FAIL:
        case ADD_DEV_DUTY_FORM_FAIL:
        case UPDATE_DEV_DUTY_FORM_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_DUTY_FORM: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevDutyForms;