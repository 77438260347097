import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_APP_FORM_OUTSIDES, DELETE_APP_FORM_OUTSIDE, ADD_APP_FORM_OUTSIDE, UPDATE_APP_FORM_OUTSIDE, GET_MY_FORMS, UPDATE_MY_FORMS, DELETE_MY_FORMS, GET_MY_FORMS_STATUS_FALSE,
     UPDATE_MY_FORMS_STATUS_FALSE,GET_MY_FORMS_STATUS_TRUE,UPDATE_MY_FORMS_STATUS_TRUE,DELETE_MY_FORM_STATUS_TRUE,DELETE_MY_FORM_STATUS_FALSE,STATUS_UPDATE_APP_FORM_OUTSIDE,
     DESC_UPDATE_APP_FORM_OUTSIDE,REJECT_APP_FORM_OUTSIDE,GET_FORMS_DOWN,ACCEPT_APP_FORM_OUTSIDE} from "./actionTypes";
import {
    getAppFormOutsidesSuccess, getAppFormOutsidesFail,
    deleteAppFormOutsideSuccess, deleteAppFormOutsideFail,
    updateAppFormOutsideSuccess, updateAppFormOutsideFail,
    addAppFormOutsideSuccess, addAppFormOutsideFail,
    getMyFormsSuccess, getMyFormsFail,
    updateMyFormSuccess, updateMyFormFail,
    deleteMyFormSuccess, deleteMyFormFail,
    getMyFormsStatusFalseSuccess, getMyFormsStatusFalseFail,
    updateMyFormStatusFalseSuccess, updateMyFormStatusFalseFail,
    getMyFormsStatusTrueSuccess,getMyFormsStatusTrueFail,
    updateMyFormStatusTrueSuccess,updateMyFormStatusTrueFail,
    deleteMyFormStatusTrueSuccess,deleteMyFormStatusTrueFail,
    deleteMyFormStatusFalseSuccess,deleteMyFormStatusFalseFail,
    statusUpdateAppFormOutsideSuccess,statusUpdateAppFormOutsideFail,
    descUpdateAppFormOutsideSuccess,descUpdateAppFormOutsideFail,
    rejectAppFormOutsideSuccess,rejectAppFormOutsideFail,
    getFormsDownSuccess,getFormsDownFail,
    acceptAppFormOutsideSuccess,acceptAppFormOutsideFail
} from "./actions";

//Include Both Helper File with needed methods
import {
    getAppFormOutsides, //API CONNECTION
    delAppFormOutside,
    addAppFormOutside,
    updateAppFormOutside,
    getMyForms,
    getMyFormsStatusFalse,
    getMyFormsStatusTrue,
    statusUpdateAppFormOutside,
    descUpdateAppFormOutside,
    RejectAppFormOutside,
    getFormsDown,
    AcceptAppFormOutside
}
    from "../../helpers/fakebackend_helper";
function* fetchFormsDown({payload:branchId}) {
    try {
        const response = yield call(getFormsDown,branchId)

        yield put(getFormsDownSuccess(response));
    } catch (error) {
        yield put(getFormsDownFail(error));
    }
}
function* fetchAppFormOutsides() {
    try {
        const response = yield call(getAppFormOutsides)

        yield put(getAppFormOutsidesSuccess(response));
    } catch (error) {
        yield put(getAppFormOutsidesFail(error));
    }
}
function* fetchMyForms({ payload: id }) {
    try {
        const response = yield call(getMyForms, id)
        yield put(getMyFormsSuccess(response));
    } catch (error) {
        yield put(getMyFormsFail(error));
    }
}
function* fetchMyFormsStatusFalse({ payload: id }) {
    try {
        const response = yield call(getMyFormsStatusFalse, id)
        yield put(getMyFormsStatusFalseSuccess(response));
    } catch (error) {
        yield put(getMyFormsStatusFalseFail(error));
    }
}
function* fetchMyFormsStatusTrue({ payload: id }) {
    try {
        const response = yield call(getMyFormsStatusTrue, id)
        yield put(getMyFormsStatusTrueSuccess(response));
    } catch (error) {
        yield put(getMyFormsStatusTrueFail(error));
    }
}
function* onUpdateMyForm({ payload: form }) {
    try {
        const response = yield call(updateAppFormOutside, form)
        yield put(updateMyFormSuccess(response))
    } catch (error) {
        yield put(updateMyFormFail(error))
    }
}

function* onUpdateMyFormStatusFalse({ payload: form }) {
    try {
        const response = yield call(updateAppFormOutside, form)
        yield put(updateMyFormStatusFalseSuccess(response))
    } catch (error) {
        yield put(updateMyFormStatusFalseFail(error))
    }
}
function* onUpdateMyFormStatusTrue({ payload: form }) {
    try {
        const response = yield call(updateAppFormOutside, form)
        yield put(updateMyFormStatusTrueSuccess(response))
    } catch (error) {
        yield put(updateMyFormStatusTrueFail(error))
    }
}
function* onDeleteMyForm({ payload: id }) {
    try {
        const response = yield call(delAppFormOutside, id)
        yield put(deleteMyFormSuccess(response))
    } catch (error) {
        yield put(deleteMyFormFail(error))
    }
}
function* onDeleteMyFormStatusTrue({ payload: id }) {
    try {
        const response = yield call(delAppFormOutside, id)
        yield put(deleteMyFormStatusTrueSuccess(response))
    } catch (error) {
        yield put(deleteMyFormStatusTrueFail(error))
    }
}
function* onDeleteMyFormStatusFalse({ payload: id }) {
    try {
        const response = yield call(delAppFormOutside, id)
        yield put(deleteMyFormStatusFalseSuccess(response))
    } catch (error) {
        yield put(deleteMyFormStatusFalseFail(error))
    }
}
function* onDeleteAppFormOutsides({ payload: id }) {
    try {
        const response = yield call(delAppFormOutside, id)
        yield put(deleteAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(deleteAppFormOutsideFail(error));
    }
}

function* onAddAppFormOutside({ payload: appFormOutside, files: files }) {
    try {
        const response = yield call(addAppFormOutside, appFormOutside, files)
        yield put(addAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(addAppFormOutsideFail(error));
    }
} 


function* onUpdateAppFormOutside({ payload: appFormOutside , files: files}) {
    try {
        const response = yield call(updateAppFormOutside, appFormOutside,files)
        yield put(updateAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(updateAppFormOutsideFail(error));
    }
}
function* onStatusUpdateAppFormOutside({ payload: appFormOutside }) {
    try {
        const response = yield call(statusUpdateAppFormOutside, appFormOutside)
        yield put(statusUpdateAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(statusUpdateAppFormOutsideFail(error));
    }
}
function* onRejectAppFormOutside({ payload: appFormOutside }) {
    try {
        const response = yield call(RejectAppFormOutside, appFormOutside)
        yield put(rejectAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(rejectAppFormOutsideFail(error));
    }
}
function* onAcceptAppFormOutside({ payload: appFormOutside }) {
    try {
        const response = yield call(AcceptAppFormOutside, appFormOutside)
        yield put(acceptAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(acceptAppFormOutsideFail(error));
    }
}
function* onDescUpdateAppFormOutside({ payload: appFormOutside }) {
    try {
        const response = yield call(descUpdateAppFormOutside, appFormOutside)
        yield put(descUpdateAppFormOutsideSuccess(response));
    } catch (error) {
        yield put(descUpdateAppFormOutsideFail(error));
    }
}

export function* appFormOutsidesSaga() {
    yield takeEvery(GET_APP_FORM_OUTSIDES, fetchAppFormOutsides);
    yield takeEvery(DELETE_APP_FORM_OUTSIDE, onDeleteAppFormOutsides);
    yield takeEvery(ADD_APP_FORM_OUTSIDE, onAddAppFormOutside);
    yield takeEvery(UPDATE_APP_FORM_OUTSIDE, onUpdateAppFormOutside);
    yield takeEvery(GET_MY_FORMS, fetchMyForms);
    yield takeEvery(UPDATE_MY_FORMS, onUpdateMyForm);
    yield takeEvery(DELETE_MY_FORMS, onDeleteMyForm);
    yield takeEvery(DELETE_MY_FORM_STATUS_TRUE, onDeleteMyFormStatusTrue);
    yield takeEvery(DELETE_MY_FORM_STATUS_FALSE, onDeleteMyFormStatusFalse);
    yield takeEvery(GET_MY_FORMS_STATUS_FALSE, fetchMyFormsStatusFalse);
    yield takeEvery(UPDATE_MY_FORMS_STATUS_FALSE, onUpdateMyFormStatusFalse);
    yield takeEvery(UPDATE_MY_FORMS_STATUS_TRUE, onUpdateMyFormStatusTrue);
    yield takeEvery(GET_MY_FORMS_STATUS_TRUE, fetchMyFormsStatusTrue);
    yield takeEvery(STATUS_UPDATE_APP_FORM_OUTSIDE, onStatusUpdateAppFormOutside);
    yield takeEvery(DESC_UPDATE_APP_FORM_OUTSIDE, onDescUpdateAppFormOutside);
    yield takeEvery(REJECT_APP_FORM_OUTSIDE, onRejectAppFormOutside);
    yield takeEvery(GET_FORMS_DOWN, fetchFormsDown);
    yield takeEvery(ACCEPT_APP_FORM_OUTSIDE, onAcceptAppFormOutside);
    
}

export default appFormOutsidesSaga;
