import {
    GET_BACK_URLS,
    GET_BACK_URLS_SUCCESS,
    GET_BACK_URLS_FAIL,
    GET_BACK_URL_DETAIL,
    GET_BACK_URL_DETAIL_SUCCESS,
    GET_BACK_URL_DETAIL_FAIL,
    ADD_BACK_URL,
    ADD_BACK_URL_SUCCESS,
    ADD_BACK_URL_FAIL,
    UPDATE_BACK_URL,
    UPDATE_BACK_URL_SUCCESS,
    UPDATE_BACK_URL_FAIL,
    DELETE_BACK_URL,
    DELETE_BACK_URL_SUCCESS,
    DELETE_BACK_URL_FAIL,
    RESET_BACK_URL,
   
} from "./actionTypes";


export const resetBackUrl = () => ({
    type: RESET_BACK_URL,
});

export const getBackUrls = () => ({
    type: GET_BACK_URLS,
});

export const getBackUrlsSuccess = (backUrl) => ({
    type: GET_BACK_URLS_SUCCESS,
    payload: backUrl,
});


export const getBackUrlsFail = (error) => ({
    type: GET_BACK_URLS_FAIL,
    payload: error,
});

export const deleteBackUrl = (id) => ({
    type:  DELETE_BACK_URL,
    payload: id
});

export const deleteBackUrlSuccess = (data) => ({
    type: DELETE_BACK_URL_SUCCESS,
    payload: data
});

export const deleteBackUrlFail = (error) => ({
    type: DELETE_BACK_URL_FAIL,
    payload: error,
});


export const addBackUrl = (backUrl) => ({
    type: ADD_BACK_URL,
    payload: backUrl
});

export const addBackUrlSuccess = (data) => ({
    type: ADD_BACK_URL_SUCCESS,
    payload: data
});

export const addBackUrlFail = (error) => ({
    type: ADD_BACK_URL_FAIL,
    payload: error,
});


export const updateBackUrl = (backUrl) => ({
    type: UPDATE_BACK_URL,
    payload: backUrl
});

export const updateBackUrlSuccess = (data) => ({
    type: UPDATE_BACK_URL_SUCCESS,
    payload: data
});

export const updateBackUrlFail = (error) => ({
    type: UPDATE_BACK_URL_FAIL,
    payload: error,
});