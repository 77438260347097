/** Get ORIENTATION_FORM */
export const GET_ORIENTATION_FORMS = "GET_ORIENTATION_FORMS";
export const GET_ORIENTATION_FORMS_SUCCESS = "GET_ORIENTATION_FORMS_SUCCESS";
export const GET_ORIENTATION_FORMS_FAIL = "GET_ORIENTATION_FORMS_FAIL";

export const GET_ORIENTATION_FORM_DETAIL = "GET_ORIENTATION_FORM_DETAIL";
export const GET_ORIENTATION_FORM_DETAIL_SUCCESS = "GET_ORIENTATION_FORM_DETAIL_SUCCESS";
export const GET_ORIENTATION_FORM_DETAIL_FAIL = "GET_ORIENTATION_FORM_DETAIL_FAIL";


export const ADD_ORIENTATION_FORM = "ADD_ORIENTATION_FORM";
export const ADD_ORIENTATION_FORM_SUCCESS = "ADD_ORIENTATION_FORM_SUCCESS";
export const ADD_ORIENTATION_FORM_FAIL = "ADD_ORIENTATION_FORM_FAIL";

export const UPDATE_ORIENTATION_FORM = "UPDATE_ORIENTATION_FORM";
export const UPDATE_ORIENTATION_FORM_SUCCESS = "UPDATE_ORIENTATION_FORM_SUCCESS";
export const UPDATE_ORIENTATION_FORM_FAIL = "UPDATE_ORIENTATION_FORM_FAIL";

export const DELETE_ORIENTATION_FORM = "DELETE_ORIENTATION_FORM";
export const DELETE_ORIENTATION_FORM_SUCCESS = "DELETE_ORIENTATION_FORM_SUCCESS";
export const DELETE_ORIENTATION_FORM_FAIL = "DELETE_ORIENTATION_FORM_FAIL"; 



export const RESET_ORIENTATION_FORM= "RESET_ORIENTATION_FORM"; 