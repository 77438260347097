import {
    GET_DEV_FOREIGN_LANGUAGES,
    GET_DEV_FOREIGN_LANGUAGES_SUCCESS,
    GET_DEV_FOREIGN_LANGUAGES_FAIL,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL_SUCCESS,
    GET_DEV_FOREIGN_LANGUAGE_DETAIL_FAIL,
    ADD_DEV_FOREIGN_LANGUAGE,
    ADD_DEV_FOREIGN_LANGUAGE_SUCCESS,
    ADD_DEV_FOREIGN_LANGUAGE_FAIL,
    UPDATE_DEV_FOREIGN_LANGUAGE,
    UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    UPDATE_DEV_FOREIGN_LANGUAGE_FAIL,
    DELETE_DEV_FOREIGN_LANGUAGE,
    DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    DELETE_DEV_FOREIGN_LANGUAGE_FAIL,
    RESET_DEV_FOREIGN_LANGUAGE,
   
} from "./actionTypes";


export const resetDevForeignLanguage = () => ({
    type: RESET_DEV_FOREIGN_LANGUAGE,
});

export const getDevForeignLanguages = () => ({
    type: GET_DEV_FOREIGN_LANGUAGES,
});

export const getDevForeignLanguagesSuccess = (devForeignLanguage) => ({
    type: GET_DEV_FOREIGN_LANGUAGES_SUCCESS,
    payload: devForeignLanguage,
});


export const getDevForeignLanguagesFail = (error) => ({
    type: GET_DEV_FOREIGN_LANGUAGES_FAIL,
    payload: error,
});

export const deleteDevForeignLanguage = (id) => ({
    type:  DELETE_DEV_FOREIGN_LANGUAGE,
    payload: id
});

export const deleteDevForeignLanguageSuccess = (data) => ({
    type: DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const deleteDevForeignLanguageFail = (error) => ({
    type: DELETE_DEV_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});


export const addDevForeignLanguage = (devForeignLanguage) => ({
    type: ADD_DEV_FOREIGN_LANGUAGE,
    payload: devForeignLanguage
});

export const addDevForeignLanguageSuccess = (data) => ({
    type: ADD_DEV_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const addDevForeignLanguageFail = (error) => ({
    type: ADD_DEV_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});


export const updateDevForeignLanguage = (devForeignLanguage) => ({
    type: UPDATE_DEV_FOREIGN_LANGUAGE,
    payload: devForeignLanguage
});

export const updateDevForeignLanguageSuccess = (data) => ({
    type: UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS,
    payload: data
});

export const updateDevForeignLanguageFail = (error) => ({
    type: UPDATE_DEV_FOREIGN_LANGUAGE_FAIL,
    payload: error,
});