import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  CardTitle,
  Collapse,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import classnames from "classnames"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import imgEmpty from "./../../assets/images/empty.png"
import { getAnalytics as onGetAnalytics } from "store/actions"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { isEmpty } from "lodash"
import LineColumnArea from "./AllCharts/apex/LineColumnArea"

const AnalyticBranchTag = props => {
  const dispatch = useDispatch()

  const { error, AnalyticTag } = useSelector(state => ({
    error: state.Analytics?.error,
    AnalyticTag: state.Analytics?.data,
  }))
  let user = JSON.parse(localStorage.getItem("authUser"))

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])

  const {
    match: { params },
  } = props

  useEffect(() => {
    setLoading(true)
    if (!isEmpty(user)) {
      dispatch(
        onGetAnalytics(
          "v1/branch/qr/" +
            params.branchid +
            "/" +
            params.qrid +
            "/" +
            params.tagid
        )
      )
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`${AnalyticTag?.qr?.title || ""} > ${
            AnalyticTag?.data?.tag?.tag || ""
          }`}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={`${AnalyticTag?.qr?.title || ""} > ${
              AnalyticTag?.data?.tag?.tag || ""
            }`}
            breadcrumbItem={`${AnalyticTag?.qr?.title || ""} > ${
              AnalyticTag?.data?.tag?.tag || ""
            }`}
          />
          <Row>
            {isEmpty(AnalyticTag) ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}

            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Visit")}</CardTitle>
                  <LineColumnArea
                    series={AnalyticTag?.data?.graphic?.series}
                    labels={AnalyticTag?.data?.graphic?.labels}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle>{props.t("DeviceTypes")}</CardTitle>
                  {AnalyticTag?.data?.devices.map((item, index) => (
                    <Card className="border border-success" key={index}>
                      <CardBody>
                        <CardTitle className="mt-2">
                          {item.name == "Other" ? props.t("Other") : item.name}
                          <small className="float-end">
                            {props.t("Total Visit")}: {item.visit}
                          </small>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Locations")}</CardTitle>
                  {AnalyticTag?.data?.locations.map((item, index) => (
                    <Card className="border border-success" key={index}>
                      <CardBody>
                        <CardTitle className="mt-2">
                          {item.name == "Other" ? props.t("Other") : item.name}
                          <small className="float-end">
                            {props.t("Total Visit")}: {item.visit}
                          </small>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AnalyticBranchTag))
