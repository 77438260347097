import axios from "axios"
import { del, get, post, put, postX } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
/*
// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
*/
// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)

export const postJwtRegister = (data,file) => postX(url.POST_REGISTER, data,file)
export const postJwtRegister2 = (data,file) => postX(url.POST_REGISTER2+data?._id, data,file)
export const postJwtRegister3 = (data,file) => postX(url.POST_REGISTER3+data?._id, data,file)
// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

export const forgotSendMail = email => post(url.FORGOT_SEND_MAIL, email)

export const postEmailConfirm = (id, hash) => post(url.EMAIL_CONFIRM, id, hash)

/* ####################################################################### */
/* COMPANIES*/

export const getCompanies = () => get(url.GET_COMPANIES); 
export const getCompaniesAvm = () => get(url.GET_COMPANIES+"/companies/avm");


export const delCompanyQRPhoto = (id) => del(url.GET_COMPANIES + "/qrphoto/" + id);
export const delCompany = (id) => del(url.GET_COMPANIES + "/" + id);
export const remail = (email) => post(url.GET_COMPANIES + "/remail/" + email);
export const addCompany = (company) => post(url.GET_COMPANIES, company);
export const updateCompany = (company, file) => postX(url.GET_COMPANIES + "/update/" + company?._id, company, file);
export const updateCompanyClass = (company) => post(url.GET_COMPANIES + "/updateClass/" + company?._id, company);
export const updateCompanyQrPhoto = (company, file) => postX(url.GET_COMPANIES + "/updateQrPhoto/" + company?._id, company, file);

export const getCompanyUsers = (id) => get(url.GET_COMPANIES + "/users/" + id);
export const getCompanyById = (id) => get(url.GET_COMPANIES + "/" + id);
export const getCompanyByQr = (id) => get(url.GET_COMPANIES + "/getCompanyByQr/" + id);
export const getCompanyInfoByQr = (id) => get(url.GET_COMPANIES + "/getCompanyInfoByQr/" + id);
export const addCompanyUser = (user) => post(url.GET_COMPANIES + "/user", user); 
export const updateCompanyUser = (user) => post(url.GET_COMPANIES + "/user-update", user);
export const userMail = (email) => post(url.GET_COMPANIES + "/remail/", email);
export const deleteCompanyUser = (id) => del(url.GET_COMPANIES + "/user/" + id); 

export const getUserListDown = (branchId) => get(url.GET_USER + "/list/down/" + branchId);



/* ####################################################################### */
/* PLAN*/

export const getPlans = () => get(url.GET_PLANS);
export const getPlanById = (id) => get(url.GET_PLANS + "/" + id);
export const getPlansDown = (branchId) => get(url.GET_PLANS + "/list/down/" + branchId);
export const delPlan = (id) => del(url.GET_PLANS + "/" + id);
export const addPlan = (planInput) => post(url.GET_PLANS, planInput);
export const updatePlan = (planInput) => put(url.GET_PLANS + "/" + planInput?._id, planInput);
export const getPlans_MyPlans = (id) => get(url.GET_PLANS_MY_PLANS + "/" + id);
export const getPlans_ByBranch = (id) => get(url.GET_PLANS + "/getPlanByBranch/" + id);
export const getBranch_ByPlan = (id) => get(url.GET_PLANS + "/getBranchByPlanId/" + id);
export const updatePlanActive = (planInput) => post(url.GET_PLANS + "/updateIsActive/" + planInput?._id, planInput); 
 
/* ####################################################################### */
/* PLANOUTSIDE*/
export const sendPlanMail = email => post(url.GET_PLAN_OUTSIDE+"/sendMail/", email)
export const getPlanOutsides = () => get(url.GET_PLAN_OUTSIDE);
export const getPlanOutsideById = (id) => get(url.GET_PLAN_OUTSIDE + "/" + id);
export const getPlanOutsidesDown = (branchId) => get(url.GET_PLAN_OUTSIDE + "/list/down/" + branchId);
export const delPlanOutside = (id) => del(url.GET_PLAN_OUTSIDE + "/" + id);
export const addPlanOutside = (planInput) => post(url.GET_PLAN_OUTSIDE, planInput);
export const updatePlanOutside = (planInput) => put(url.GET_PLAN_OUTSIDE + "/" + planInput?._id, planInput);
export const getPlanOutsides_MyPlanOutsides = (id) => get(url.GET_PLAN_OUTSIDE + "/" + id);
export const getPlanOutsides_ByBranch = (id) => get(url.GET_PLAN_OUTSIDE + "/getPlanByBranch/" + id);
export const getBranch_ByPlanOutside = (id) => get(url.GET_PLAN_OUTSIDE + "/getBranchByPlanId/" + id);

/* ####################################################################### */
/* APP_FORM*/

export const getAppForms = () => get(url.GET_APP_FORMS);
export const delAppForm = (id) => del(url.GET_APP_FORMS + "/" + id);
export const getFormsDown = (branchId) => get(url.GET_APP_FORMS + "/list/down/" + branchId);
export const addAppForm = (appForm, files) => postX(url.GET_APP_FORMS, appForm, files);
export const updateAppForm = (appForm) => put(url.GET_APP_FORMS + "/" + appForm?._id, appForm);
export const statusUpdateAppForm = (appForm) => put(url.STATUS_UPDATE_APP_FORMS + "/" + appForm?._id, appForm);
export const RejectAppForm = (appForm) => put(url.REJECT_APP_FORMS + "/" + appForm?._id, appForm);
export const FiredAppForm = (appForm) => put(url.FIRED_APP_FORMS + "/"+appForm);
export const AcceptAppForm = (appForm) => put(url.ACCEPT_APP_FORMS + "/" + appForm?._id, appForm);
export const descUpdateAppForm = (appForm) => put(url.DESC_UPDATE_APP_FORMS + "/" + appForm?._id, appForm);
export const getMyForms = (id) => get(url.GET_APP_MY_FORMS + "/" + id);
export const getMyFormsStatusFalse = (id) => get(url.GET_APP_MY_FORMS_STATUS_FALSE + "/" + id);
export const getMyFormsStatusTrue = (id) => get(url.GET_APP_MY_FORMS_STATUS_TRUE + "/" + id);
/* APP_FORM*/

export const getAppFormOutsides = () => get(url.GET_APP_FORM_OUTSIDE);
export const delAppFormOutside = (id) => del(url.GET_APP_FORM_OUTSIDE + "/" + id);
export const getFormOutsidesDown = (branchId) => get(url.GET_APP_FORM_OUTSIDE + "/list/down/" + branchId);
export const addAppFormOutside = (appForm, files) => postX(url.GET_APP_FORM_OUTSIDE, appForm, files);
export const updateAppFormOutside = (appForm,files) => postX(url.GET_APP_FORM_OUTSIDE + "/" + appForm?._id, appForm,files);
export const statusUpdateAppFormOutside = (appForm) => put(url.GET_APP_FORM_OUTSIDE + "/" + appForm?._id, appForm);
export const RejectAppFormOutside = (appForm) => put(url.GET_APP_FORM_OUTSIDE + "/" + appForm?._id, appForm);
export const AcceptAppFormOutside = (appForm) => put(url.GET_APP_FORM_OUTSIDE + "/" + appForm?._id, appForm);
export const descUpdateAppFormOutside = (appForm) => put(url.GET_APP_FORM_OUTSIDE + "/" + appForm?._id, appForm);
export const getMyFormOutsides = (id) => get(url.GET_APP_FORM_OUTSIDE + "/" + id);
export const getMyFormOutsidesStatusFalse = (id) => get(url.GET_APP_FORM_OUTSIDE + "/" + id);
export const getMyFormOutsidesStatusTrue = (id) => get(url.GET_APP_FORM_OUTSIDE + "/" + id);
/* ADVERTISEMENT*/
export const getAdvertisementsForQr = (id) => get(url.GET_ADVERTISEMENT+"/"+ id);
export const getAdvertisements = () => get(url.GET_ADVERTISEMENT);
export const delAdvertisement = (id) => del(url.GET_ADVERTISEMENT + "/" + id); 
export const addAdvertisement = (planInput, files) => postX(url.GET_ADVERTISEMENT, planInput,files);
export const updateAdvertisement = (planInput,file) => postX(url.GET_ADVERTISEMENT + "/" + planInput?._id, planInput,file); 
export const updateAdvertisementActive = (planInput) => post(url.GET_ADVERTISEMENT + "/updateIsActive/" + planInput?._id, planInput); 


/* ####################################################################### */
/* AvmInfo*/
export const getAvm_InfosForQr = (id) => get(url.GET_AVM_INFO+"/"+ id);
export const getAvm_Infos = () => get(url.GET_AVM_INFO);
export const delAvm_Info = (id) => del(url.GET_AVM_INFO + "/" + id); 
export const addAvm_Info = (planInput, files) => postX(url.GET_AVM_INFO, planInput,files);
export const updateAvm_Info = (planInput,file) => postX(url.GET_AVM_INFO + "/" + planInput?._id, planInput,file); 
export const updateAvm_InfoActive = (planInput) => post(url.GET_AVM_INFO + "/updateIsActive/" + planInput?._id, planInput); 
/* ####################################################################### */
/* PARTNERHIP*/
export const getPartnershipsForQr = (id) => get(url.GET_PARTNERSHIP+"/"+ id);
export const getPartnerships = () => get(url.GET_PARTNERSHIP);
export const delPartnership = (id) => del(url.GET_PARTNERSHIP + "/" + id); 
export const addPartnership = (planInput, files) => postX(url.GET_PARTNERSHIP, planInput,files);
export const updatePartnership = (planInput,file) => postX(url.GET_PARTNERSHIP + "/" + planInput?._id, planInput,file); 


/* ####################################################################### */
/* SECTOR*/

export const getSectors = () => get(url.GET_SECTOR);
export const delSector = (id) => del(url.GET_SECTOR + "/" + id); 
export const addSector = (planInput) => post(url.GET_SECTOR, planInput);
export const updateSector = (planInput) => put(url.GET_SECTOR + "/" + planInput?._id, planInput); 


/* ####################################################################### */
/* DUTY FORM*/

export const getDutyForms = () => get(url.GET_DUTY_FORM);
export const delDutyForm = (id) => del(url.GET_DUTY_FORM + "/" + id); 
export const addDutyForm = (planInput) => post(url.GET_DUTY_FORM, planInput);
export const updateDutyForm = (planInput) => put(url.GET_DUTY_FORM + "/" + planInput?._id, planInput); 

/* SECTOR FORM*/

export const getSectorForms = () => get(url.GET_SECTOR_FORM);
export const delSectorForm = (id) => del(url.GET_SECTOR_FORM + "/" + id); 
export const addSectorForm = (planInput) => post(url.GET_SECTOR_FORM, planInput);
export const updateSectorForm = (planInput) => put(url.GET_SECTOR_FORM + "/" + planInput?._id, planInput); 
/* SOFT_SKILL*/

export const getSoftSkills = () => get(url.GET_SOFT_SKILL);
export const delSoftSkill = (id) => del(url.GET_SOFT_SKILL + "/" + id); 
export const addSoftSkill = (planInput) => post(url.GET_SOFT_SKILL, planInput);
export const updateSoftSkill = (planInput) => put(url.GET_SOFT_SKILL + "/" + planInput?._id, planInput); 


/* ####################################################################### */
/* SECTOR*/

export const getStatuss = () => get(url.GET_STATUS);
export const delStatus = (id) => del(url.GET_STATUS + "/" + id); 
export const addStatus = (planInput) => post(url.GET_STATUS, planInput);
export const updateStatuss = (planInput) => put(url.GET_STATUS + "/" + planInput?._id, planInput); 


/* ####################################################################### */
/* SURVEY*/

export const getSurveys = () => get(url.GET_SURVEY);
export const delSurvey = (id) => del(url.GET_SURVEY + "/" + id); 
export const addSurvey = (planInput) => post(url.GET_SURVEY, planInput);
export const updateSurvey = (planInput) => put(url.GET_SURVEY + "/" + planInput?._id, planInput); 
export const getSurveyById = (id) => get(url.GET_SURVEY + "/" + id);

/* ####################################################################### */
/* SURVEY_FORM*/

export const getSurveyForms = (appFormId) => get(url.GET_SURVEY_FORM+"/"+appFormId);
export const delSurveyForm = (id) => del(url.GET_SURVEY_FORM + "/" + id); 
export const addSurveyForm = (planInput) => post(url.GET_SURVEY_FORM, planInput);
export const updateSurveyForm = (planInput) => put(url.GET_SURVEY_FORM, planInput); 
export const getSurveyFormById = (id) => get(url.GET_SURVEY_FORM + "/" + id); 

/* ####################################################################### */
/* SURVEY_SENT*/
export const sendSurveyMail = email => post(url.GET_SURVEY_SENT+"/mailtest/", email)
export const getSurveySents = (appFormId) => get(url.GET_SURVEY_SENT+"/"+appFormId);
export const delSurveySent = (id) => del(url.GET_SURVEY_SENT + "/" + id); 
export const addSurveySent = (planInput) => post(url.GET_SURVEY_SENT, planInput);
export const updateSurveySent = (planInput) => put(url.GET_SURVEY_SENT + "/" + planInput?._id, planInput); 
export const getSurveySentById = (id) => get(url.GET_SURVEY_SENT + "/" + id); 

/* ####################################################################### *
/* ORIENTATION*/

export const getOrientations = () => get(url.ORIENTATION);
export const delOrientation = (id) => del(url.ORIENTATION + "/" + id); 
export const addOrientation = (planInput) => post(url.ORIENTATION, planInput);
export const updateOrientation = (planInput) => put(url.ORIENTATION + "/" + planInput?._id, planInput); 
export const getOrientationById = (id) => get(url.ORIENTATION + "/" + id);

/* ####################################################################### */

/* ORIENTATION_FORM*/

export const getOrientationForms = (appFormId) => get(url.GET_ORIENTATION_FORM+"/"+appFormId);
export const delOrientationForm = (id) => del(url.GET_ORIENTATION_FORM + "/" + id); 
export const addOrientationForm = (planInput) => post(url.GET_ORIENTATION_FORM, planInput);
export const updateOrientationForm = (planInput) => put(url.GET_ORIENTATION_FORM, planInput); 
export const getOrientationFormById = (id) => get(url.GET_ORIENTATION_FORM + "/" + id); 

/* ####################################################################### */
/* ORIENTATION_SENT*/
export const sendOrientationMail = email => post(url.GET_ORIENTATION_SENT+"/mailtest/", email)
export const getOrientationSents = (appFormId) => get(url.GET_ORIENTATION_SENT+"/"+appFormId);
export const delOrientationSent = (id) => del(url.GET_ORIENTATION_SENT + "/" + id); 
export const addOrientationSent = (planInput) => post(url.GET_ORIENTATION_SENT, planInput);
export const updateOrientationSent = (planInput) => put(url.GET_ORIENTATION_SENT + "/" + planInput?._id, planInput); 
export const getOrientationSentById = (id) => get(url.GET_ORIENTATION_SENT + "/" + id); 

/* ####################################################################### *
/* FORMDUTIES*/

export const getDutiesForms = () => get(url.DUTIES_FORM);
export const delDutiesForm = (id) => del(url.DUTIES_FORM + "/" + id); 
export const addDutiesForm = (planInput) => post(url.DUTIES_FORM, planInput);
export const updateDutiesForm = (planInput) => put(url.DUTIES_FORM + "/" + planInput?._id, planInput); 
export const getDutiesFormById = (id) => get(url.DUTIES_FORM + "/" + id);
/* ####################################################################### */
/* DUTY*/

export const getDutys = () => get(url.GET_DUTY);
export const delDuty = (id) => del(url.GET_DUTY + "/" + id); 
export const addDuty = (planInput) => post(url.GET_DUTY, planInput);
export const updateDuty = (planInput) => put(url.GET_DUTY + "/" + planInput?._id, planInput); 
/* ####################################################################### */
/* DEV FOREIGN LANGUAGE*/
export const getDevForeignLanguages = () => get(url.DEV_FOREIGN_LANGUAGE);
export const delDevForeignLanguage = (id) => del(url.DEV_FOREIGN_LANGUAGE + "/" + id); 
export const addDevForeignLanguage = (planInput) => post(url.DEV_FOREIGN_LANGUAGE, planInput);
export const updateDevForeignLanguage = (planInput) => put(url.DEV_FOREIGN_LANGUAGE + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV DUTY FORM*/
export const getDevDutyForms = () => get(url.DEV_DUTY_FORM);
export const delDevDutyForm = (id) => del(url.DEV_DUTY_FORM + "/" + id); 
export const addDevDutyForm = (planInput) => post(url.DEV_DUTY_FORM, planInput);
export const updateDevDutyForm = (planInput) => put(url.DEV_DUTY_FORM + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV WORK TYPE*/
export const getDevWorkTypes = () => get(url.DEV_WORK_TYPE);
export const delDevWorkType = (id) => del(url.DEV_WORK_TYPE + "/" + id); 
export const addDevWorkType = (planInput) => post(url.DEV_WORK_TYPE, planInput);
export const updateDevWorkType = (planInput) => put(url.DEV_WORK_TYPE + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV SECTOR FORM*/
export const getDevSectorForms = () => get(url.DEV_SECTOR_FORM);
export const delDevSectorForm = (id) => del(url.DEV_SECTOR_FORM + "/" + id); 
export const addDevSectorForm = (planInput) => post(url.DEV_SECTOR_FORM, planInput);
export const updateDevSectorForm = (planInput) => put(url.DEV_SECTOR_FORM + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV PROGRAM*/
export const getDevPrograms = () => get(url.DEV_PROGRAM);
export const delDevProgram = (id) => del(url.DEV_PROGRAM + "/" + id); 
export const addDevProgram = (planInput) => post(url.DEV_PROGRAM, planInput);
export const updateDevProgram = (planInput) => put(url.DEV_PROGRAM + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV REQUEST REASON*/
export const getDevRequestReasons = () => get(url.DEV_REQUEST_REASON);
export const delDevRequestReason = (id) => del(url.DEV_REQUEST_REASON + "/" + id); 
export const addDevRequestReason = (planInput) => post(url.DEV_REQUEST_REASON, planInput);
export const updateDevRequestReason = (planInput) => put(url.DEV_REQUEST_REASON + "/" + planInput?._id, planInput); 
/* ####################################################################### */


/* REQUEST REASON*/
export const getRequestReasons = () => get(url.REQUEST_REASON);
export const delRequestReason = (id) => del(url.REQUEST_REASON + "/" + id); 
export const addRequestReason = (planInput) => post(url.REQUEST_REASON, planInput);
export const updateRequestReason = (planInput) => put(url.REQUEST_REASON + "/" + planInput?._id, planInput); 

/* WORK-TYPE*/
export const getWorkTypes = () => get(url.GET_WORK_TYPE);
export const delWorkType = (id) => del(url.GET_WORK_TYPE + "/" + id); 
export const addWorkType = (planInput) => post(url.GET_WORK_TYPE, planInput);
export const updateWorkType = (planInput) => put(url.GET_WORK_TYPE + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* PROGRAM*/
export const getPrograms = () => get(url.GET_PROGRAM);
export const delProgram = (id) => del(url.GET_PROGRAM + "/" + id); 
export const addProgram = (planInput) => post(url.GET_PROGRAM, planInput);
export const updateProgram = (planInput) => put(url.GET_PROGRAM + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* FOREIGN LANGUAGE*/
export const getForeignLanguages = () => get(url.GET_FOREIGN_LANGUAGE);
export const delForeignLanguage = (id) => del(url.GET_FOREIGN_LANGUAGE + "/" + id); 
export const addForeignLanguage = (planInput) => post(url.GET_FOREIGN_LANGUAGE, planInput);
export const updateForeignLanguage = (planInput) => put(url.GET_FOREIGN_LANGUAGE + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* BACKURL*/
export const getBackUrls = () => get(url.GET_BACK_URL);
export const delBackUrl = (id) => del(url.GET_BACK_URL + "/" + id); 
export const addBackUrl = (planInput) => post(url.GET_BACK_URL, planInput);
export const updateBackUrl = (planInput) => put(url.GET_BACK_URL + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* LABEL*/
export const getLabels = () => get(url.GET_LABEL);
export const delLabel = (id) => del(url.GET_LABEL + "/" + id); 
export const addLabel = (planInput) => post(url.GET_LABEL, planInput);
export const updateLabel = (planInput) => put(url.GET_LABEL + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* DEV_DUTIES*/
export const getDevDutys = () => get(url.GET_DEV_DUTY);
export const delDevDuty = (id) => del(url.GET_DEV_DUTY + "/" + id); 
export const addDevDuty = (planInput) => post(url.GET_DEV_DUTY, planInput);
export const updateDevDuty = (planInput) => put(url.GET_DEV_DUTY + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* ORIENTATION PERIOD*/
export const getOrientationPeriods = () => get(url.GET_ORIENTATION_PERIOD);
export const delOrientationPeriod = (id) => del(url.GET_ORIENTATION_PERIOD + "/" + id); 
export const addOrientationPeriod = (planInput) => post(url.GET_ORIENTATION_PERIOD, planInput);
export const updateOrientationPeriod = (planInput) => put(url.GET_ORIENTATION_PERIOD + "/" + planInput?._id, planInput); 
/* ####################################################################### */

/* APP_FORM_PLAN*/
export const getRequestFormPlans = () => get(url.GET_REQUEST_FORM_PLAN);
export const getReqFormsDown = (branchId) => get(url.GET_REQUEST_FORM_PLAN + "/reqForm/down/" + branchId);
export const delRequestFormPlan = (id) => del(url.GET_REQUEST_FORM_PLAN + "/" + id);
export const addRequestFormPlan = (requestFormPlan) => post(url.GET_REQUEST_FORM_PLAN, requestFormPlan);
export const updateRequestFormPlan = (requestFormPlan) => put(url.GET_REQUEST_FORM_PLAN + "/" + requestFormPlan?._id, requestFormPlan);
export const updateActions = (requestFormPlan) => put(url.GET_REQUEST_FORM_PLAN + "/actionUpdate/" + requestFormPlan?._id, requestFormPlan);
export const updateStatus = (requestFormPlan) => put(url.GET_REQUEST_FORM_PLAN + "/statusUpdate/" + requestFormPlan?._id, requestFormPlan); 
/* ####################################################################### */

/* BRANCHS*/
export const getBranchs = () => get(url.GET_BRANCHS)
export const getBranchChilds = id => get(url.GET_BRANCH_CHILDS + id)
export const getBranchById = id => get(url.GET_BRANCHS + "/" + id)
export const delBranch = id => del(url.GET_BRANCHS + "/" + id)
export const addBranch = (branch, file) => postX(url.GET_BRANCHS, branch, file)
export const updateBranch = (branch, file) => postX(url.GET_BRANCHS + "/" + branch?._id, branch, file)
export const getCompanyBranches = (id, branchId) => get(url.GET_BRANCHS + "/branchs/" + id + "/" + branchId)
export const delCompanyBranch = id => del(url.GET_BRANCHS + "/branch/" + id)
/* ####################################################################### */

export const getQRCodes = branchId => get(url.API_BASE_QRCODE + "/list?BranchId=" + branchId)
export const addQRCode = model => post(url.API_BASE_QRCODE, model)
export const addTagQRCode = model => post(url.API_BASE_QRCODE+"/add-tag", model)
export const deleteTagQRCode = model => post(url.API_BASE_QRCODE+"/del-tag", model)
export const delQRCode = id => del(url.API_BASE_QRCODE + "/" + id)
export const getQRAnonymus = qrcode =>  get(url.GET_QR_ANONYMUS + "/" + qrcode)

//ANALYTICS 
export const getMyFormsLength = (id) => get(url.API_BASE_ANALYTICS + "/getMyFormsLength/" + id)
export const getAnalyticByLink = (CompanyId, Link) => get(url.API_BASE_ANALYTICS + "/get-by-link/" + CompanyId + "/"+ Link) 
export const getAnalytics = parameters => get(url.API_BASE_ANALYTICS + "/" + parameters) 
export const getCities = () => get(url.API_BASE_LOCATIONS + "/cities" )
export const getDistricts = (cityId) => get(url.API_BASE_LOCATIONS + "/Districts/"+cityId )
export const getNeighborhoods = (cityId, districtId) => get(`${url.API_BASE_LOCATIONS}/neighborhoods/${cityId}/${districtId}`)

//USER 
export const updateUserCompany = user => 
  put(url.GET_USER + "/addCompany/" + user?._id, user)
export const getUser = () => get(url.GET_USER)
export const delUser = id => del(url.GET_USER + "/" + id)
export const addUser = user => post(url.GET_USER, user)
export const updateUsers = user => put(url.GET_USER + "/" + user?._id, user)
export const getCompanyByEmail = email =>
  get(url.GET_USER + "/getCompanyByEmail/" + email)
export const changePassword = obj => post(url.GET_USER + "/changePassword", obj)
export const sendMail = email => post(url.SEND_MAIL, email)

export const getRoles = () => get(url.GET_ROLES)
export const getUserRoles = id => get(url.GET_USER + "/roles/" + id)
export const addUserRoles = userRoles =>
  post(url.GET_ROLES + "/user", userRoles)

/* ####################################################################### */
//REPORTS
export const getCompanyFormsByDate = (CompanyId,startDate,endDate) => get(url.GET_REPORT+"/companyForm/"+CompanyId+"/"+startDate+"/"+endDate);
export const getCompanyQrByDate = (CompanyId,startDate,endDate) => get(url.GET_REPORT+"/companyQr/"+CompanyId+"/"+startDate+"/"+endDate);
export const getCompanyFormsIsMatchByDate = (CompanyId,startDate,endDate) => get(url.GET_REPORT+"/companyFormIsMatch/"+CompanyId+"/"+startDate+"/"+endDate);
export const getBranchFormsByDate = (BranchId,startDate,endDate) => get(url.GET_REPORT+"/branchForm/"+BranchId+"/"+startDate+"/"+endDate);
export const getBranchQrByDate = (BranchId,startDate,endDate) => get(url.GET_REPORT+"/branchQr/"+BranchId+"/"+startDate+"/"+endDate);
export const getBranchFormsIsMatchByDate = (BranchId,startDate,endDate) => get(url.GET_REPORT+"/branchFormIsMatch/"+BranchId+"/"+startDate+"/"+endDate);
export const getCompanyShareQrByDate = (CompanyId,startDate,endDate) => get(url.GET_REPORT+"/companyShareQr/"+CompanyId+"/"+startDate+"/"+endDate);
export const getBranchShareQrByDate = (BranchId,startDate,endDate) => get(url.GET_REPORT+"/branchShareQr/"+BranchId+"/"+startDate+"/"+endDate);  
/* ####################################################################### */
//LOGIN LOG
export const getLoginLog = () => get(url.LOGIN_LOG);
export const addVisit = (planInput) => put(url.LOGIN_LOG+"/addVisit/"+ planInput?._id, planInput);

export { 
  getLoggedInUser,
  isUserAuthenticated, 
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
}
