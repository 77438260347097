import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody, Nav, NavItem, NavLink, TabContent,
  TabPane,
  Label,
} from "reactstrap"
//redux
import Rating from "react-rating"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
//Modals
import QrModal from "./Modals/qrModal"
import PreparedModulesModal from "./Modals/preparedModulesModal"
import ApplicationPlanModal from "./../Plan/Modals/applicationPlanModal"
import { authorize, Role } from "../../components/helpers/authorize"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import moment from "moment"
const starStyle = {}
import {
  getPlanOutsides as onGetPlanOutsides,
  getPlans as onGetPlans,
  getPlans_MyPlans as onGetMyPlans,
  getCompanyBranches as onGetCompanyBranches,
  addPlan as onAddMyPlans,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  deletePlanOutside as onDeletePlans_MyPlans,
  deleteAppFormOutside as onDeleteAppFormOutside,
  updatePlan as onUpdatePlans_MyPlans,
  getBranchs as onGetBranches,
  getPlansDown as onGetPlansDown,
  addQRCode as onAddQRCode,
  sendMail as onSendMail,
  getCompanies as onGetCompanies,
  resetPlanOutside as onResetAppFormOutside,
  getLabels as onGetLabels,
  getForeignLanguages as onGetForeignLanguages,
  getPrograms as onGetPrograms,
  getDutys as onGetDuty,
  getSectorForms as onGetSectorForms,
  getDutyForms as onGetDutyForms,
  getDutiesForms as onGetDutiesForms,
  getBackUrls as onGetBackUrls,
  addAppFormOutside as onAddFormOutside,
  getAppFormOutsides as onGetAppFormOutside,
  addVisit as onAddVisit

} from "store/actions" 

import { defaultsDeep, isEmpty, map } from "lodash"
import QrListModal from "./Modals/qrListModal"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import defaultProfilePic from "../../assets/images/users/default-user-pic.png"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { select } from "redux-saga/effects"
import types from "react-cropper"
import {
  ASKERLIK_DURUMU_SEC,
  AYRILMA_NEDENI_SEC,
  CINSIYET_SEC,
  DIL_SEC,
  EGITIM_DURUMU_SEC,
  EHLIYET_BILGISI_SEC,
  GOREV_SEC,
  MEDENI_DURUM_SEC,
  PROGRAM_BILGISI_SEC,
  SEKTOR_SEC,
  SEVIYE_SEC,
  SURE_SEC_YIL,
  MAAS_BEKLENTISI

} from "./helper/options"
import QRRefresher from "pages/CompanyQr/v2/components/QRRefresher"
import LabelPage from "../Label/index"
const Plans = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)
  const [modalPreparedModules, setModalPreparedModules] = useState(false)
  const [modalQr, setModalQr] = useState(false)
  const [sendMail, setSendMail] = useState(false)
  const [AddQrModal, setAddQrModal] = useState(false)
  const [infoQr, setInfoQr] = useState()
  const [selectedId, setSelectedId] = useState()
  const [selectedId2, setSelectedId2] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [copyPlan, setCopyPlan] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg2, setsuccess_dlg2] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [copy_alert, setCopy_alert] = useState(false)
  const [selectedForm, setSelectedForm] = useState("")
  const [isGroupButton, setIsGroupButton] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_title2, setdynamic_title2] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [dynamic_description2, setdynamic_description2] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [selectedPosition, setSelectedPosition] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [moduleInfo, setModuleInfo] = useState([])
  const [qrBranch, setQrBranch] = useState(false)
  const [qrListData, setQrListData] = useState()
  const [qrListOpen, setQRListOpen] = useState(false)
  const [selectedDuty, setSelectedDuty] = useState()
  const [selectedPlanObject, setSelectedPlanObject] = useState([])
  const [checkFormModal, setCheckFormModal] = useState(false)
  const [planObjects, setPlanObjects] = useState([

    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },
  ])
  const [mailObject, setMailObject] = useState([

    {
      mail: "",
      label: ""
    },
  ])
  let tempForeignLanguages = "Dil Seç"
  let tempProgram = "Program Seç"
  let tempSectorForms = "Sektör Seç"
  let tempDutyForms = "Görev Seç"
  console.log("mailObj", mailObject)
  const { error, Plans, Branches, ForeignLanguages, DutyForms, SectorForms, Programs, MyPlans, User, PlansDown, Companies, sendMailSuccess, Labels, Duty, AppFormOutsides } = useSelector(
    state => ({
      error: state.Plans?.error,
      MyPlans: state.Plans?.myPlans,
      Plans: state.PlanOutside?.data,
      Branches: state.Branchs?.Branches,
      User: state.Plans?.user,
      PlansDown: state.Plans?.plansDown,
      Companies: state.Companies?.data,
      sendMailSuccess: state.PlanOutside?.sendMailSuccess,
      Labels: state.Label?.data,
      ForeignLanguages: state.ForeignLanguage?.data,
      Programs: state.Program?.data,
      Duty: state.Duty?.data,
      SectorForms: state.SectorForm?.data,
      DutyForms: state.DutyForm?.data,
      AppFormOutsides: state.AppFormOutside?.data
    })
  )
  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetCompanies())
    dispatch(onGetPlanOutsides())
    dispatch(onGetLabels());
    dispatch(onGetForeignLanguages())
    dispatch(onGetPrograms())
    dispatch(onGetDuty())
    dispatch(onGetSectorForms())
    dispatch(onGetDutyForms())
    dispatch(onGetCompanyBranches(user.companyId))
    dispatch(onGetDutiesForms());
    dispatch(onGetBackUrls())
    dispatch(onGetAppFormOutside())
    const visitDetailArr =[{name:"Platfrom Dışı Personel",visitDate:Date.now()}]
    const updatePlan = {
      _id:user.uid,
      visitDetail: JSON.stringify(visitDetailArr)
    }
    dispatch(onAddVisit(updatePlan)) 
    

  }, [dispatch])

  if (!isEmpty(ForeignLanguages)) {
    ForeignLanguages.map((item, index) => {
      tempForeignLanguages = tempForeignLanguages + "," + item.name.name
    })
  } else {
    tempForeignLanguages = "Dil Seç,İngilizce,Almanca,Çince"
  }
  if (!isEmpty(Programs)) {
    Programs.map((item, index) => {
      tempProgram = tempProgram + "," + item.name.name
    })
  } else {
    tempProgram = "Program Seç,Excel,Word,Outlook,Perakende Yazılımı Perakende Yazılımı / Nebim,Perakende Yazılımı / Epos,Diğer"
  }
  if (!isEmpty(DutyForms)) {
    DutyForms.map((item, index) => {
      tempDutyForms = tempDutyForms + "," + item.name.name
    })
  } else {
    tempDutyForms = "Görev Seç,Satış Danışmanı,Karşılama Hostesi,Kasa Elemanı,Depo Elemanı,Ütücü,Servis Elemanı,Barista,Komi / Garson,Mutfak Elemanı,Usta,Müdür Yardımcısı,Diğer"
  }
  if (!isEmpty(SectorForms)) {
    SectorForms.map((item, index) => {
      tempSectorForms = tempSectorForms + "," + item.name.name
    })
  } else {
    tempSectorForms = "Sektör Seç,Hazır Giyim,Ev Tekstili,Kişisel Bakım,Elektronik Ürünler,Telekomünikasyon,Süpermarket,Mobilya,Kitap / Kırtasiye,Otel,Restorant / Kafe,Diğer"
  }
  const toggle = () => {
    setModal(!modal)
  }
  const toggleForm = () => {
    setCheckFormModal(!checkFormModal)
  }
  const togglePreparedModules = () => {
    if (modalPreparedModules) {
      // InputAddReset();
    }
    setModalPreparedModules(!modalPreparedModules)
  }
  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
  const toggleAddQr = () => {
    setAddQrModal(!AddQrModal)
  }
  const toggleQr = () => {
    setModalQr(!modalQr)
  }
  const sendMailToggle = () => {
    setSendMail(!sendMail)
  }

  //Dynamic Inputs START
  const handleValidSubmit = (e, v) => {

    dispatch(
      onAddQRCode({
        BranchId: v.BranchId,
        CompanyId: user?.companyId,
        qrCode: v.qrCode,
        title: v.title,
        description: v.description,
        tag: v.tag,
      })
    )
    toggleAddQr()
  }
  const branchReset = () => {
    setSelectedBranchId("")
  }

  const InputAddReset = () => {
    let tempInput = [
      {
        title: "Cinsiyet",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: CINSIYET_SEC,
        key: "cinsiyet",
        targetValue: "",
      },
      {
        title: "Aile ve Medeni Durum",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MEDENI_DURUM_SEC,
        key: "medeniDurum",
        targetValue: "",
      },
      {
        title: "Eğitim Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: EGITIM_DURUMU_SEC,
        key: "egitimDurumu",
        targetValue: "",
      },
      {
        title: "Askerlik Durumu",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: tempForeignLanguages,
        key: "askerlikDurumu",
        targetValue: "",
      },
      {
        title: "Program Bilgisi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Program Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: PROGRAM_BILGISI_SEC,
          },
          {
            title: "Bilgi Derecesi",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],

        key: "programBilgisi",
        targetValue: "",
      },
      {
        title: "Yabancı Dil",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Dil",
            isType: "dropdown",
            isValue: "",
            isOptions: DIL_SEC,
          },
          {
            title: "Konuşma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Yazma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
          {
            title: "Okuma",
            isType: "dropdown",
            isValue: "",
            isOptions: SEVIYE_SEC,
          },
        ],
        key: "yabanciDil",
        targetValue: "",
      },
      {
        title: "Ehliyet",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Ehliyet Bilgisi",
            isType: "dropdown",
            isValue: "",
            isOptions: EHLIYET_BILGISI_SEC,
          },
          {
            title: "Süre Seç",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
        ],
        key: "ehliyet",
        targetValue: "",
      },
      {
        title: "İş Deneyimi",
        isType: "group",
        isValue: "",
        isPlan: [
          {
            title: "Firma Unvanı",
            isType: "text",
            isValue: "",

          },
          {
            title: "Görev",
            isType: "dropdown",
            isValue: "",
            isOptions: tempDutyForms,
          },
          {
            title: "Sektör",
            isType: "dropdown",
            isValue: "",
            isOptions: tempSectorForms,
          },
          {
            title: "Süre",
            isType: "dropdown",
            isValue: "",
            isOptions: SURE_SEC_YIL,
          },
          {
            title: "Ayrılma Nedeni",
            isType: "dropdown",
            isValue: "",
            isOptions: AYRILMA_NEDENI_SEC,
          },
          {
            title: "Giriş Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
          {
            title: "Çıkış Tarihi",
            isType: "date",
            isValue: "",
            isOptions: "",
          },
        ],
        key: "isDeneyimi",
        targetValue: "",
      },
      {
        title: "Maaş Beklentisi",
        isType: "dropdown",
        isValue: "",
        isPlan: null,
        isOptions: MAAS_BEKLENTISI,
        key: "maasBeklentisi",
        targetValue: "",
      },

    ]
    // let tempInput =[
    //   {
    //         title: "",
    //         isType: "",
    //         isValue: "",
    //         isPlan: "",
    //         isOptions:"" ,
    //         key: "",
    //         targetValue: "",
    //       },
    // ]
    setPlanObjects(tempInput)
  }
  const toggleQrBranch = () => {
    setQrBranch(!qrBranch)
  }
  const toggleQrList = () => {
    setQRListOpen(!qrListOpen)
  }
  //Dynamic Inputs END
  const columns = [
    {
      dataField: "DutyId",
      text: props.t("Position Name"),
      formatter: (cell, row) => <span>{cell?.description}</span>,
    },
    {
      dataField: "description",
      text: props.t("Form Desc"),
    },


    {
      dataField: "CreatorUserId",
      text: props.t("Creator User Name"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },


    {
      dataField: "planInfo",
      text: props.t("Plan Info"),
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            //href={"/appForm/" + row._id}
            onClick={() => {

              window.open("/planFromOutside/" + row._id + "/" + user.companyId + "/test/test")
              //window.open(config.baseApi+"appForm/"+ row._id);
            }}
          >
            {props.t("View Form")}
          </Button>
        </div>
      ),
    },
    {
      dataField: "dummy",
      text: props.t("Personel Davet Et"),
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            //href={"/appForm/" + row._id}
            onClick={() => {
              setSelectedForm(row._id)
              sendMailToggle()

            }}
          >
            {props.t("Davet Et")}
          </Button>
        </div>
      ),
    },
    {
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drodp-absolute">
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem
                onClick={() => {
                  setCopyPlan(row)
                  //console.log(row)
                  let copyPlans = {
                    name: row.name + "_kopya",
                    description: "",
                    CompanyId: row.CompanyId._id,
                    BranchId: row.BranchId._id,
                    CreatorUserId: row.CreatorUserId._id,
                    PlanObjects: JSON.stringify(row.PlanObjects),
                    planDesc: row.planDesc,
                  }

                  setCopyPlan(copyPlans)
                  setCopy_alert(true)
                  //dispatch(onAddMyPlans(copyPlan))
                }}
              >
                <i className="bx bx-copy font-size-16 text-warning me-1"></i>
                {props.t("Copy")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)

                  setSelectedWorkType(row.workType)
                  setSelectedPosition(row.position)

                  setSelectedDuty(row?.DutyId?._id)
                  setSelectedBranchId(row.BranchId?._id)

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                    setPlanDescription(row.planDesc)
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem> */}
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]
  const AppFormColumn = [

    {
      dataField: "AppPlanOutsideId",
      text: props.t("Plan İsmi"),
      formatter: (cell, row) => <span>{cell?.description}</span>,
    },
    {
      dataField: "LabelId",
      text: props.t("Firma İsmi"),
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },
    {
      dataField: "sendedMail",
      text: props.t("Gönderilen Mail"),
    },
    {
      dataField: "sendMail",
      text: props.t("Tekrar Mail Gönder"),
      formatter: (cell, row) => (

        <div>

          <Button
            color="primary"
            className="btn-rounded "
            onClick={() => {
             console.log("row",row)
              const newPlan = {
                sendedMail: row.sendedMail,
                formId: row.AppPlanOutsideId?._id,
                companyName: Companies[0]?.name,
                CompanyId: user.companyId ,
                LabelId:row.LabelId?._id,
                id:row._id
              }
              dispatch(onSendMail(newPlan))
            }}
          >
            {props.t("Tekrar Mail Gönder")}
          </Button>


        </div>
      ),
    },
    {
      dataField: "isMatch",
      text: props.t("Görüldü mü"),
      sort: true,
      formatter: (cell, row) => (
        <div
          style={{ position: "relative", left: "10px" }}
        >
          {row.firstName ? (
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle  text-white font-size-16"
                style={{ backgroundColor: "#34c38f" }}
              >
                <i className="bx bx-comment-check"></i>{" "}
              </span>
            </div>
          ) : (
            <div className="avatar-xs">
              <span
                className="avatar-title rounded-circle text-white font-size-16"
                style={{ backgroundColor: "#f46a6a" }}
              >
                i
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      dataField: "planInfo",
      text: props.t("Check The Form"),
      formatter: (cell, row) => (

        <div>
          {row.firstName ? (
            <Button
              color="primary"
              className="btn-rounded "
              onClick={() => {
                setSelectedForm(row)
                setSelectedPlanObject(row.PlanObjects)

                //toggleForm()
                setCheckFormModal(true)





              }}
            >
              {props.t("Check The Form")}
            </Button>
          ) : null}

        </div>
      ),
    },






    {
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drodp-absolute">
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem
                onClick={() => {
                  setCopyPlan(row)
                  //console.log(row)
                  let copyPlans = {
                    name: row.name + "_kopya",
                    description: "",
                    CompanyId: row.CompanyId._id,
                    BranchId: row.BranchId._id,
                    CreatorUserId: row.CreatorUserId._id,
                    PlanObjects: JSON.stringify(row.PlanObjects),
                    planDesc: row.planDesc,
                  }

                  setCopyPlan(copyPlans)
                  setCopy_alert(true)
                  //dispatch(onAddMyPlans(copyPlan))
                }}
              >
                <i className="bx bx-copy font-size-16 text-warning me-1"></i>
                {props.t("Copy")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)

                  setSelectedWorkType(row.workType)
                  setSelectedPosition(row.position)

                  setSelectedDuty(row?.DutyId?._id)
                  setSelectedBranchId(row.BranchId?._id)

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                    setPlanDescription(row.planDesc)
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem> */}
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId2(cell)
                  setconfirm_alert2(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },

  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Plans ? Plans.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Plans ? Plans.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidPlanSubmit = (e, values) => {

    // const newPlan = {
    //   sendedMail: JSON.stringify(mailObject),
    //   formId: selectedForm,
    //   companyName: Companies[0]?.name,
    //   companyId: user.companyId
    // }
    // // save new Plan

    // dispatch(onSendMail(newPlan))  
    for (let i = 0; i < mailObject.length; i++) {
      const newPlan = {
        sendedMail: mailObject[i].mail,
        CompanyId: user.companyId,
        AppPlanOutsideId: selectedForm,
        LabelId: mailObject[i].label,
        formId: selectedForm,
        companyName: Companies[0]?.name
      }
      // save new Plan 

      dispatch(onAddFormOutside(newPlan))
    }

    sendMailToggle()

  }

  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
  const handleAdd = index => {
    let temp = mailObject
    let newProjectObjects = []
    temp.splice(index + 1, 0, {

      mail: ""
    })

    for (let i = 0; i < temp.length; i++) {
      newProjectObjects.push(temp[i])
    }
    setMailObject(newProjectObjects)
  }
  const handleSubtract = i => {
    const values = [...mailObject]
    values.splice(i, 1)
    setMailObject([...values])
  }
  const handleChangeForm = (i, e) => {
    const values = [...mailObject]
    values[i].mail = e
    setMailObject(values)
  }
  const handleChangeFormLabel = (i, e) => {
    const values = [...mailObject]
    values[i].label = e
    setMailObject(values)
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Platform Dışı Personel Başvuru Formu")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {authorize(Role.Ik) ? (<Row>
            <Col sm="12">
              <Breadcrumb
                title={props.t("Platform Dışı Personel Başvuru Formu")}
                breadcrumbItem={props.t("Platform Dışı Personel Başvuru Formu Ekle")}
                addBtn={true}
                addBtnCallBack={() => {
                  setIsEdit(false)
                  setSelectedPlan({
                    name: "",
                    description: "",

                    PlanObjects: [],
                  })
                  branchReset()
                  InputAddReset()

                  toggle()
                }}
              />
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={Plans}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={Plans}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"_id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    noDataIndication={props.t(
                                      "You Don't Have a Plan Yet"
                                    )}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>

            </Col>
          </Row>) : (
           
             <Card>
               <CardBody style={{padding:"0.7rem 1.25rem"}}>
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer",backgroundColor: "white"}}
                    className={classnames({
                      active: customActiveTab === "1",
                    })} 
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="bx bx-buildings"></i>
                    </span>
                    <span className="d-none d-sm-block">{props.t("Platform Dışı Personel Başvuru Formu")}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer",backgroundColor: "white" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="bx bx-clipboard"></i>
                    </span>
                    <span className="d-none d-sm-block">{props.t("Platform Dışı Firma")}</span>
                  </NavLink>
                </NavItem>


              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Breadcrumb
                        title={props.t("Platform Dışı Personel Başvuru Formu")}
                        breadcrumbItem={props.t("Platform Dışı Personel Başvuru Formu Ekle")}
                        addBtn={true}
                        addBtnCallBack={() => {
                          setIsEdit(false)
                          setSelectedPlan({
                            name: "",
                            description: "",

                            PlanObjects: [],
                          })
                          branchReset()
                          InputAddReset()

                          toggle()
                        }}
                      />
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={columns}
                            data={Plans}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="_id"
                                columns={columns}
                                data={Plans}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>

                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar {...toolkitProps.searchProps} />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"_id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            noDataIndication={props.t(
                                              "You Don't Have a Plan Yet"
                                            )}
                                            defaultSorted={defaultSorted}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone {...paginationProps} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>

                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Breadcrumb
                        title={props.t("Gönderilen Formlar")}
                        breadcrumbItem={props.t("Gönderilen Formlar")}
                        addBtn={false}

                      />
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={AppFormColumn}
                            data={AppFormOutsides}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="_id"
                                columns={AppFormColumn}
                                data={AppFormOutsides}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>

                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar {...toolkitProps.searchProps} />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"_id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            noDataIndication={props.t(
                                              "You Don't Have a Plan Yet"
                                            )}
                                            defaultSorted={defaultSorted}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone {...paginationProps} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>

                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <LabelPage />
                    </Col>
                  </Row>
                </TabPane>


              </TabContent>
              </CardBody>
            </Card>
           
          )}

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}




          <Modal isOpen={sendMail} size="lg" toggle={sendMailToggle}>
            <ModalHeader toggle={sendMailToggle} tag="h4">
              Personel Davet
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidPlanSubmit}>

                {mailObject?.map((item, i) => (
                  <div key={i} className="mb-3">
                    <Row>
                      <Col md="5" lg="5" sm="4" xs="4">
                        <div >
                          <AvField
                            name="name"
                            label={props.t("Mail")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            value={item?.mail}
                            onChange={e =>
                              handleChangeForm(i, e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col md="5" lg="5" sm="4" xs="4">
                        <div >
                          <AvField
                            className="form-select"
                            type="select"
                            name="label"
                            value={props.selectedDutyId}
                            label={props.t("Firma")}
                            onChange={e =>
                              handleChangeFormLabel(i, e.target.value)
                            }
                          >
                            <option>{props.t("Firma Seçiniz")}</option>
                            {Labels?.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item?.name}
                              </option>
                            ))}
                          </AvField>
                        </div>
                      </Col>
                      <Col md="2" lg="2" sm="4" xs="4" className="items-margin" style={{ position: "relative", top: "26px" }}>
                        <div>
                          <Button
                            onClick={() => handleAdd(i)}
                            className="btn btn-success me-2"
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                          {mailObject.length > 1 ? (
                            <Button
                              onClick={() => handleSubtract(i)}
                              className="btn btn-danger"
                            >
                              <i className="bx bx-trash"></i>
                            </Button>
                          ) : null}
                        </div>
                      </Col>



                    </Row>
                  </div>
                ))}



                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        {props.t("Send Mail")}
                      </button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() =>
                          // { toggle() }
                          console.log("branchID", props.selectedBranchId)
                        }
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={checkFormModal} style={{ maxWidth: "1490px" }} toggle={toggleForm}>
            <ModalHeader toggle={toggleForm} tag="h4">
              {props.t("Job Application Form")}
            </ModalHeader>
            <ModalBody className="modal-scroll">
              <AvForm>
                <Row form>
                  <Col xs={12}>
                    <Row>
                      <Col xs="12" sm="12" md="2" lg="2">
                        <div className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                          {selectedForm?.photo ? (
                            <img
                              src={config.baseImg + selectedForm?.photo}
                              //style={{ width: "175px", height: "175px", borderRadius: "50%" }}
                              style={{ maxWidth: "175px", maxHeight: "175px", objectFit: "cover" }}
                            />
                          ) : (
                            <img
                              src={defaultProfilePic}
                              //style={{ width: "175px", height: "175px", borderRadius: "50%" }}
                              style={{ maxWidth: "175px", maxHeight: "175px", objectFit: "cover" }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <p style={{ fontSize: "36px", fontFamily: "boxicons" }}>{selectedForm?.firstName}{" "}{selectedForm?.lastName} </p>
                      </Col>

                      <Col xs="12" sm="12" md="4" lg="4">
                        <h4>İletişim Bilgileri</h4>
                        <hr />
                        <p> <i className="bx bx-envelope" style={{ fontSize: "24px", position: "relative", top: "5px" }} /> Email:{" "}{selectedForm?.email}</p>
                        <p> <i className="bx bx-phone-call" style={{ fontSize: "24px", position: "relative", top: "5px" }} />Tel:{" "}{selectedForm?.phone}</p>
                        <p> <i className="bx bx-map" style={{ fontSize: "24px", position: "relative", top: "5px" }} />Adres:{" "}{selectedForm?.city?.name}{"/"}{selectedForm?.district?.name}{"/"}{selectedForm?.neighborhood?.name}</p>


                      </Col>
                    </Row>
                    <hr style={{ color: "blue", border: "2px solid" }} />
                    <Row style={{ marginRight: "50px", marginLeft: "50px" }}>
                      <h2>Hakkında</h2>
                      <hr />
                      <Row>
                        <Col xs="12" sm="12" md="4" lg="4">
                          <p> <i className="bx bx-id-card" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}TC Kimlik No:{" "}{selectedForm?.identityNumber}</p>
                          <p> <i className="bx bx-cake" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Doğum Günü: {" "}{handleValidDateAvField(selectedForm?.birthday)}</p>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                          {map(selectedPlanObject, (item, index) => (
                            item.title == "Cinsiyet" ?
                              <p> <i className="bx bx-male" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Cinsiyet: {" "}{item?.isValue}</p> :
                              item.title == "Aile ve Medeni Durum" ? <p> <i className="  bx bxs-heart" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Medeni Durumu:{" "}{item?.isValue}</p> :
                                null

                          ))}
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4">
                          {map(selectedPlanObject, (item, index) => (

                            item.title == "Eğitim Durumu" ? <p> <i className="  bx bxs-book" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Eğitim Durumu: {" "}{item?.isValue}</p> :
                              item.title == "Askerlik Durumu" ? item?.isValue ? (
                                <p> <i className="   bx bx-run" style={{ fontSize: "24px", position: "relative", top: "5px" }} />{" "}Askerlik Durumu: {" "}{item?.isValue}</p>
                              ) : null : null

                          ))}
                        </Col>

                      </Row>

                    </Row>
                    {map(selectedPlanObject, (item, index) => (
                      item.title == "Program Bilgisi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }}>
                          <h2>Program Bilgisi</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <Row>
                                <Col xs="3" sm="3" md="1" lg="1">
                                  <b className="mb-2">{programName[0]?.isValue}</b>
                                </Col>
                                <Col xs="9" sm="9" md="11" lg="11" style={{ position: "relative", bottom: "7px" }}>
                                  <Rating
                                    ActiveComponent={
                                      <i
                                        className="mdi mdi-star text-primary"
                                        style={starStyle}
                                      />
                                    }
                                    InActiveComponent={
                                      <i
                                        className="mdi mdi-star-outline text-muted"
                                        style={starStyle}
                                      />
                                    }
                                    readonly={true}
                                    initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[1]?.isValue == "Az" ? 1 : programName[1]?.isValue == "Orta" ? 2 : programName[1]?.isValue == "İyi" ? 3 : programName[1]?.isValue == "Çok İyi" ? 4 : 0}
                                  />
                                </Col>
                              </Row>



                            </div>


                          ))}

                        </Row>
                      ) : item.title == "Yabancı Dil" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">

                          <h2>Yabancı Dil</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <b>{programName[0]?.isValue}</b><br /><br />

                              <div>
                                <Row>
                                  <Col className="col-1 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[1]?.title}</b>
                                  </Col>
                                  <Col className="col-11">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[1]?.isValue == "Az" ? 1 : programName[1]?.isValue == "Orta" ? 2 : programName[1]?.isValue == "İyi" ? 3 : programName[1]?.isValue == ":Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                              <div>
                                <Row>
                                  <Col className="col-1 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[2]?.title}</b>
                                  </Col>
                                  <Col className="col-11">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[2]?.isValue == "Yok" ? 0 : programName[2]?.isValue == "Az" ? 1 : programName[2]?.isValue == "Orta" ? 2 : programName[2]?.isValue == "İyi" ? 3 : programName[2]?.isValue == "Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                              <div>
                                <Row>
                                  <Col className="col-1 mt-1">
                                    <b style={{ fontWeight: "400" }}>{programName[3]?.title}</b>
                                  </Col>
                                  <Col className="col-11">
                                    <Rating style={{ position: "relative", botton: "10px", left: "6px" }}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={starStyle}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={starStyle}
                                        />
                                      }
                                      readonly={true}
                                      initialRating={programName[1]?.isValue == "Yok" ? 0 : programName[3]?.isValue == "Az" ? 1 : programName[3]?.isValue == "Orta" ? 2 : programName[3]?.isValue == "İyi" ? 3 : programName[3]?.isValue == "Çok İyi" ? 4 : 0}
                                    />
                                  </Col>
                                </Row>



                              </div>
                            </div>
                          ))}
                        </Row>
                      ) : item.title == "Ehliyet" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>Ehliyet</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <p><span style={{ fontWeight: "600" }}>Ehliyet Türü:</span>{" "}{programName[0]?.isValue}</p>
                              <p><span style={{ fontWeight: "600" }}>Ehliyet Süresi:</span>{" "}{programName[1]?.isValue}</p>
                            </div>
                          ))}
                        </Row>
                      ) : item.title == "İş Deneyimi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>İş Deneyimi</h2>
                          <hr />
                          {map(item?.isValue, (programName, programIndex) => (
                            <div>
                              <p><span style={{ fontWeight: "600" }}>Şirket İsmi:</span>{" "}{programName[0]?.isValue}</p>
                              <Row>
                                <Col className="col-4">
                                  {programName[1]?.isValue ? (<p><i className="bx bx-caret-right"></i>{programName[1]?.title} - {programName[1]?.isValue}</p>) : null}
                                  {programName[2]?.isValue ? (<p><i className="bx bx-caret-right"></i>{programName[2]?.title} - {programName[2]?.isValue}</p>) : null}

                                </Col>
                                <Col className="col-4">
                                  {programName[3]?.isValue ? (<p ><i className="bx bx-caret-right"></i>{programName[3]?.title} - {programName[3]?.isValue}</p>) : null}
                                  {programName[4]?.isValue ? (<p ><i className="bx bx-caret-right"></i>{programName[4]?.title} - {programName[4]?.isValue}</p>) : null}

                                </Col>
                                <Col className="col-4">
                                  {programName[5]?.isValue ? (<p><i className="bx bx-caret-right"></i> {programName[5]?.title}  {programName[5]?.isValue}</p>) : null}
                                  {programName[6]?.isValue ? (<p><i className="bx bx-caret-right"></i> {programName[6]?.title}  {programName[6]?.isValue}</p>) : null}

                                </Col>
                              </Row>




                            </div>
                          ))}
                        </Row>
                      ) : item.title == "Maaş Beklentisi" ? (
                        <Row style={{ marginRight: "50px", marginLeft: "50px" }} className="mt-4">
                          <h2>Maaş Beklentisi</h2>
                          <hr />
                          <p>{item.isValue}</p>
                        </Row>
                      ) : null

                    ))}



                  </Col>
                </Row>







              </AvForm>
            </ModalBody>
          </Modal>
          {sendMailSuccess ? (
            <SweetAlert
              success
              title={"Mail Gönderildi"}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetAppFormOutside())
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          <ApplicationPlanModal
            modal={modal}
            isEdit={isEdit}
            selectedPlan={selectedPlan}
            selectedBranchId={selectedBranchId}
            selectedWorkType={selectedWorkType}
            selectedPosition={selectedPosition}
            selectedDutyId={selectedDuty}
            planObjects={planObjects}
            foreignLang={tempForeignLanguages}
            program={tempProgram}
            dutyForm={tempDutyForms}
            sectorForm={tempSectorForms}
            toggle={() => {
              toggle()
            }}
            comeFrom={"outside"}
            save={(e, _body) => {
              null
            }}
          />

          <QrModal
            modal={modalQr}
            infoQr={infoQr}
            toggle={() => {
              toggleQr()
            }}
          />

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          {confirm_alert2 ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert2(false)
                setsuccess_dlg2(true)
                setdynamic_title2(props.t("Deleted"))
                setdynamic_description2("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeleteAppFormOutside(selectedId2))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeletePlans_MyPlans(selectedId))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {copy_alert ? (
            <SweetAlert
              //title={props.t("Kopyalamak istediğinize emin misiniz?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Copy")}
              onConfirm={() => {
                setCopy_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Copied"))
                setdynamic_description("")

                dispatch(onAddMyPlans(copyPlan))

                setSelectedId(null)
              }}
              onCancel={() => setCopy_alert(false)}
            >
              {props.t("Copy Plan?")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
