import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ANALYTICS,GET_ANALYTIC_BY_LINK,GET_MY_FORMS_LENGTH } from "./actionTypes"
import { getAnalyticsSuccess, getAnalyticsFail,
  getMyFormsLengthSuccess,getMyFormsLengthFail, getAnalyticByLinkSuccess, getAnalyticByLinkFail
} from "./actions" 

//Include Both Helper File with needed methods
import {
  getAnalyticByLink,
  getAnalytics,getMyFormsLength //API CONNECTION
} from "../../helpers/fakebackend_helper"

function* fetchAnalytics({ payload: qrcode }) {
  try {
    const response = yield call(getAnalytics, qrcode) 
    yield put(getAnalyticsSuccess(response))
  } catch (error) {
    yield put(getAnalyticsFail(error))
  }
}

function* fetchAnalyticByLink({ payload: {CompanyId, Link} }) {
  try {
    const response = yield call(getAnalyticByLink, CompanyId, Link) 
    yield put(getAnalyticByLinkSuccess(response))
  } catch (error) {
    yield put(getAnalyticByLinkFail(error))
  }
}

function* fetchMyFormsLength({ payload: id }) {
  try {
      const response = yield call(getMyFormsLength, id)
      yield put(getMyFormsLengthSuccess(response));
  } catch (error) {
      yield put(getMyFormsLengthFail(error));
  }
}

export function* AnalyticsSaga() {
  yield takeEvery(GET_ANALYTICS, fetchAnalytics)
  yield takeEvery(GET_ANALYTIC_BY_LINK, fetchAnalyticByLink)
  yield takeEvery(GET_MY_FORMS_LENGTH, fetchMyFormsLength)
}

export default AnalyticsSaga
