import {
    GET_DEV_WORK_TYPES,
    GET_DEV_WORK_TYPES_SUCCESS,
    GET_DEV_WORK_TYPES_FAIL,
    GET_DEV_WORK_TYPE_DETAIL,
    GET_DEV_WORK_TYPE_DETAIL_SUCCESS,
    GET_DEV_WORK_TYPE_DETAIL_FAIL,
    ADD_DEV_WORK_TYPE,
    ADD_DEV_WORK_TYPE_SUCCESS,
    ADD_DEV_WORK_TYPE_FAIL,
    UPDATE_DEV_WORK_TYPE,
    UPDATE_DEV_WORK_TYPE_SUCCESS,
    UPDATE_DEV_WORK_TYPE_FAIL,
    DELETE_DEV_WORK_TYPE,
    DELETE_DEV_WORK_TYPE_SUCCESS,
    DELETE_DEV_WORK_TYPE_FAIL,
    RESET_DEV_WORK_TYPE,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevWorkTypes = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_WORK_TYPES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devWorkType => devWorkType._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_WORK_TYPE_SUCCESS:
            return {
                ...state,
                data: state.data.map(devWorkType =>
                    devWorkType._id.toString() === action.payload.data._id.toString() ?
                        { devWorkType, ...action.payload.data } :
                        devWorkType
                )
            }

        //FAILS
        case GET_DEV_WORK_TYPES_FAIL:
        case DELETE_DEV_WORK_TYPE_FAIL:
        case ADD_DEV_WORK_TYPE_FAIL:
        case UPDATE_DEV_WORK_TYPE_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_WORK_TYPE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevWorkTypes;