import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {

    Container,
    Row,
    Col,
    Card,
    Input,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    TabContent,
    Nav,
    Form,
    FormGroup,
    TabPane,
    CardTitle,
    CardSubtitle,
    NavItem,
    NavLink,
} from "reactstrap"

import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import { isEmpty, map } from "lodash"

import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { productComments } from "common/data"
import { Search } from "react-bootstrap-table2-toolkit"
import { ASKERLIK_DURUMU_SEC, CINSIYET_SEC, EGITIM_DURUMU_SEC, EHLIYET_VAR_YOK, MEDENI_DURUM_SEC, SEVIYE_SEC } from "../helper/options"

const PreparedModulesModal = (props, cb) => {
   
    const [activeTab, setactiveTab] = useState("1")
    const [chosenModule, setChosenModule] = useState();
    const [moduleSample, setModuleSample] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleObjects, setModuleObjects] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleContact , setModuleContact] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleExperience , setModuleExperience] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleEducation , setModuleEducation] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleComputerSkills , setModuleComputerSkills] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleCourse , setModuleCourse] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleLanguage , setModuleLanguage] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleHealth , setModuleHealth] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleEmergency , setModuleEmergency] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleAssociations , setModuleAssociations] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    const [moduleReferences , setModuleReferences] = useState([
        {
            title: "",
            isType: "", //date, string, number, vs
            isValue: "",
            isPlan: null
        }
    ]);
    useEffect(() => {

        setModuleSample([
            { title: "", isType: "",  isValue: ""},
            ]),
        setModuleObjects([
            { title: "", isType: "",  isValue: ""},
            ]),
        setModuleContact([
                { title: "", isType: "",  isValue: ""},
                ]),
        setModuleExperience([
            { title: "", isType: "",  isValue: ""},
            ])
            setModuleEducation([
              { title: "", isType: "",  isValue: ""},
              ])  
              setModuleComputerSkills([
               { title: "", isType: "",  isValue: ""},
               ]) 
               setModuleCourse([
                 { title: "", isType: "",  isValue: ""},
                 ])   
                 setModuleLanguage([
               { title: "", isType: "",  isValue: ""},
               ]) 
               setModuleHealth([
                { title: "", isType: "",  isValue: ""},
                ])  
                setModuleEmergency([
                    { title: "", isType: "",  isValue: ""},
                    ])  
                    setModuleAssociations([
                        { title: "", isType: "",  isValue: ""},
                        ])  
                        setModuleReferences([
                            { title: "", isType: "",  isValue: ""},
                            ])            
            


    }, [props.modalToggle]);
   
    const handleValidDateAvField = date => {
        if (date) {
          const date1 = moment(new Date(date)).format("YYYY-MM-DD") || "";
          
          return date1;
        } else {
         
          return "";
        }
      }
      const handleValidPlanSubmit = (e, values) => {
            let _body = {
            moduleSample:moduleSample,
            moduleObjects: moduleObjects, 
            moduleContact : moduleContact,
            moduleExperience: moduleExperience,
            moduleEducation:moduleEducation,
            moduleComputerSkills:moduleComputerSkills,
            moduleCourse:moduleCourse,
            moduleLanguage:moduleLanguage,
            moduleHealth:moduleHealth,
            moduleEmergency:moduleEmergency,
            moduleAssociations:moduleAssociations,
            moduleReferences:moduleReferences 
           };
          
          //console.log("değer bu",values.customRadioInline2)

          props.save(true,_body)
        
    }

    return (

        <Modal isOpen={props.modal} toggle={props.toggle} size="xl">
            <ModalHeader toggle={props.toggle} tag="h4">
                {props.t("Prepared Modules")}
            </ModalHeader>
            <ModalBody>
                <small className="mb-3">
                    {props.t("Select Prepared Modules")}

                </small>
                <br /> <br />

                <AvForm onValidSubmit={handleValidPlanSubmit}>

                    <div className="checkout-tabs">
                        <Row>
                            <Col xl="2" sm="3">
                                <Nav className="flex-column" pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "1" })}
                                            onClick={() => {
                                                setactiveTab("1")
                                            }}
                                        >
                                            <i className="bx bx-file-blank d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Sample Application Form")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => {
                                                setactiveTab("2")
                                            }}
                                        >
                                            <i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Personal information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "3" })}
                                            onClick={() => {
                                                setactiveTab("3")
                                            }}
                                        >
                                            <i className="bx bxs-note d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Contact Information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "4" })}
                                            onClick={() => {
                                                setactiveTab("4")
                                            }}
                                        >
                                            <i className="bx bx-chevron-up-square d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Professional Experience")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "5" })}
                                            onClick={() => {
                                                setactiveTab("5")
                                            }}
                                        >
                                            <i className="bx bx-cctv d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Education Information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "6" })}
                                            onClick={() => {
                                                setactiveTab("6")
                                            }}
                                        >
                                            <i className="bx bx-message-rounded-add d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Computer Skills")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "7" })}
                                            onClick={() => {
                                                setactiveTab("7")
                                            }}
                                        >
                                            <i className="bx bx-collection d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Certificates and Courses")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "8" })}
                                            onClick={() => {
                                                setactiveTab("8")
                                            }}
                                        >
                                            <i className="bx bx-world d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Foreign Language Knowledge")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "9" })}
                                            onClick={() => {
                                                setactiveTab("9")
                                            }}
                                        >
                                            <i className="bx bx-first-aid d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Health Information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "10" })}
                                            onClick={() => {
                                                setactiveTab("10")
                                            }}
                                        >
                                            <i className="bx bx-minus-front d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Emergency Information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "11" })}
                                            onClick={() => {
                                                setactiveTab("11")
                                            }}
                                        >
                                            <i className="bx bx-braille d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("Social Information")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "12" })}
                                            onClick={() => {
                                                setactiveTab("12")
                                            }}
                                        >
                                            <i className="bx bx-paper-plane d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">{props.t("References")}</p>
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                            </Col>
                            <Col xl="10" sm="9"> 
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                                <div>
                                                    <Row>
                                                    <Col sm="12" className="mt-2">
                                                        <Row>
                                                            <div   style={{backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline1"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleSample([ 
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            {title: 'Gender', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select Gender,Male,Female'},
                                                                            {title: 'Date of Birth', isType: 'date', isValue: '', isPlan: null},
                                                                            {title: 'Place of Birth', isType: 'string', isValue: '', isPlan: null},
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            {title: 'Address', isType: 'longText', isValue: '', isPlan: null},
                                                                            {title: 'City', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'District', isType: 'string', isValue: '', isPlan: null},
                                                                            { title: props.t("Education Information"),isType: "title",isValue: ""},
                                                                            {title: 'Education Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select Education Status,Primary School,Secondary School,HighSchool,Associate Degree,University,Master Degree'},
                                                                            { title: props.t("Social Information"),isType: "title",isValue: ""},
                                                                            {title: 'Do You Have Driver Licence?', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select license status,Yes,No'},
                                                                            {title: 'Marital Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select Marital Status,Sinle,Married,Divorced'},
                                                                            {title: 'Military Service(For Men)', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select Military Status,Deferment,Discharge,Exemption'},
                                                                            {title: 'Foreign Language Knowledge', isType: 'group', isValue: '', isPlan:[{title: 'Language Name', isType: 'string', isValue: ''},{title: 'Level', isType: 'dropdown', isValue: '',isOptions:"Beginner,Elementary,Pre intermediate,Intermediate,Upper intermediate"}]},
                                                                            {title: 'Professional Experience', isType: 'group', isValue: '', isPlan:[{title: 'Name of Work Place', isType: 'string', isValue: ''},{title: 'Duty', isType: 'string', isValue: ''},{title: 'Time Worked', isType: 'string', isValue: ''}]},
                                                                            {title: 'Note', isType: 'longText', isValue: '', isPlan: null},
                                                                                
                                                                                       
                                                                             ])
                                                                           }else{
                                                                        setModuleSample([
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            {title: 'Cinsiyetiniz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: CINSIYET_SEC},
                                                                            {title: 'Doğum Tarihi', isType: 'date', isValue: '', isPlan: null},
                                                                            {title: 'Doğum Yeriniz', isType: 'string', isValue: '', isPlan: null},
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                             {title: 'Adres', isType: 'longText', isValue: '', isPlan: null},
                                                                            {title: 'İl', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'İlçe', isType: 'string', isValue: '', isPlan: null},
                                                                           
                                                                            { title: props.t("Education Information"),isType: "title",isValue: ""},
                                                                            {title: 'Öğrenim Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: EGITIM_DURUMU_SEC},
                                                                            { title: props.t("Social Information"),isType: "title",isValue: ""},
                                                                            {title: 'Ehliyetiniz Var Mı?', isType: 'dropdown', isValue: '', isPlan: null, isOptions: EHLIYET_VAR_YOK},
                                                                            {title: 'Medeni Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: MEDENI_DURUM_SEC},
                                                                            {title: 'Askerlik(Erkekler İçin)', isType: 'dropdown', isValue: '', isPlan: null, isOptions: ASKERLIK_DURUMU_SEC},
                                                                            {title: 'Yabancı Dil Bilginiz', isType: 'group', isValue: '', isPlan:[{title: 'Dil İsmi', isType: 'string', isValue: ''},{title: 'Seviye', isType: 'dropdown', isValue: '',isOptions: SEVIYE_SEC}]},
                                                                            {title: 'İş Tecrübeniz', isType: 'group', isValue: '', isPlan:[{title: 'İş Yeri Adı', isType: 'string', isValue: ''},{title: 'Görev', isType: 'string', isValue: ''},{title: 'Çalışılan Süre', isType: 'string', isValue: ''}]},
                                                                            {title: 'Not', isType: 'longText', isValue: '', isPlan: null},
                                                                            
                                                                            
                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline1"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="form"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="dateOfBirth"
                                                                                    label={props.t("Date Of Birth")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={""}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Place Of Birth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                        
                                                                      
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="District"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="City"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Driving Licence"
                                                                                    label={props.t("Driving Licence")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Driving Licence")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Education Status"
                                                                                    label={props.t("Education Status")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Education Status")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Marital Status"
                                                                                    label={props.t("Marital Status")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Marital Status")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Military Service(For Men)"
                                                                                    label={props.t("Military Service(For Men)")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Military Service(For Men)")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <Col md="6"className="mt-2"></Col>
                                                                        <p className="mt-3">{props.t("Foreign Language Knowledge")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Level"
                                                                                    label={props.t("Level")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Level")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <br/><br/><br/> <br/>
                                                                        <hr/>
                                                                        <Col md="6"></Col>
                                                                        <p className="mt-3">{props.t("Professional Experience")}</p>
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name of Work Place"
                                                                                    label={props.t("Name of Work Place")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name of Work Place")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Duty"
                                                                                    label={props.t("Duty")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Duty")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Time Worked"
                                                                                    label={props.t("Time Worked")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Time Worked")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <br/><br/><br/> <br/>
                                                                        <hr/>
                                                                        <Col md="6" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Note"
                                                                                    label={props.t("Note")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Note")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                      
                                                                       
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline24"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleSample([ 
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            {title: 'Gender', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Select Gender,Male,Female'},
                                                                            {title: 'Date of Birth', isType: 'date', isValue: '', isPlan: null},
                                                                            {title: 'Place of Birth', isType: 'string', isValue: '', isPlan: null},
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            {title: 'Phone Number', isType: 'phone', isValue: '', isPlan: null},
                                                                            {title: 'Address', isType: 'longText', isValue: '', isPlan: null},
                                                                            {title: 'City', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'District', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Please Tell About Yourself', isType: 'longText', isValue: '', isPlan: null},
                                                                                
                                                                                       
                                                                             ])
                                                                           }else{
                                                                        setModuleSample([
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            {title: 'Cinsiyet', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Cinsiyet Seç,Erkek,Kadın'},
                                                                            {title: 'Doğum Tarihi', isType: 'date', isValue: '', isPlan: null},
                                                                            {title: 'Doğum Yeri', isType: 'string', isValue: '', isPlan: null},
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            {title: 'Telefon Numarası', isType: 'phone', isValue: '', isPlan: null},
                                                                            {title: 'Adres', isType: 'longText', isValue: '', isPlan: null},
                                                                            {title: 'İl', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'ilçe', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Lütfen Kendinizden Bahsedin', isType: 'longText', isValue: '', isPlan: null},
                                                                            
                                                                            
                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline24"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="form"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="dateOfBirth"
                                                                                    label={props.t("Date Of Birth")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={" "}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Place Of Birth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="PhoneNumber"
                                                                                    label={props.t("Phone Number")}
                                                                                    type="text"
                                                                                   
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Phone Number")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Email"
                                                                                    label={props.t("Email")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Email")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        
                                                                      
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="District"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="City"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                          <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Please Tell About Yourself"
                                                                                    label={props.t("Please Tell About Yourself")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Please Tell About Yourself")}
                                                                                />

                                                                            </div>
                                                                        </Col> 
                                                                        
                                                                       
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                            
                                                           
                                                           
                                                        </Row>
                                                    </Col>
                                                     
                                                    </Row>

                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div>
                                                    <Row>
                                                    <Col sm="12" className="mt-2">
                                                        <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                    style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline2"
                                                                    name="customRadioInline2"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                       if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                        setModuleObjects([
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            { title: props.t("Gender"),isType: "dropdown",isValue: "",isOptions:"Male,Female"},
                                                                            { title: "Date Of Birth",isType: "date",isValue: ""},
                                                                            
                                                                                  
                                                                         ])
                                                                       }else{
                                                                        setModuleObjects([
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            { title: "cinsiyet",isType: "dropdown",isValue: "",isOptions:"kadın,erkek"},
                                                                            { title: "Doğum Tarihi",isType: "date",isValue: ""},
                                                                            
                                                                                  
                                                                         ])
                                                                       }
                                                                       
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline2"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                                     </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="dateOfBirth"
                                                                                    label={props.t("Date Of Birth")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={" "}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{  backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline3"
                                                                    name="customRadioInline2"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                { title: "Gender", isType: "dropdown",  isValue: "", isOptions:"Female,Male"},
                                                                                { title: "Date of Birth",isType: "date",  isValue: ""},
                                                                                { title: "Place of Birth", isType: "string", isValue: ""},
                                                                                    
                                                                             ])
                                                                        }
                                                                        else{
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                { title: "cinsiyet", isType: "dropdown",  isValue: "", isOptions:"kadın,erkek"},
                                                                                { title: "Doğum Tarihi",isType: "date",  isValue: ""},
                                                                                { title: "Doğum Yeriniz", isType: "string", isValue: ""},
                                                                                    
                                                                             ])
                                                                        }
                                                                      
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline3"
                                                              >

                                                                    <Row className="mt-4">
                                                                        <Col md="6">
                                                                            <div >
                                                                            <AvField 
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="4">
                                                                            <div>
                                                                                <AvField
                                                                                    name="dateOfBirth"
                                                                                    label={props.t("Date Of Birth")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={handleValidDateAvField("")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="4"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="placeOfBirth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{  backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="3"
                                                                    id="customRadioInline4"
                                                                    name="customRadioInline2"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                {title: 'Nationality', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Identity Number', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Gender', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Male,Female'},
                                                                                {title: 'Date of Birth', isType: 'date', isValue: '', isPlan: null},
                                                                                {title: 'Place of Birth', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Fathers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Mothers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Marital Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Married,Single'},
                                                                                {title: 'If you are married', isType: 'title', isValue: '', isPlan: null},
                                                                                {title: 'Your Partners', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Children Infos', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Date of Work', isType: 'date', isValue: ''}]},
                                                                                {title: 'Your Dependents', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Degree of proximity', isType: 'string', isValue: ''}]},
                                                                                {title: 'Your Height', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Your Weight', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Military Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Postponement,Discharge,Exempt'},
                                                                                    
                                                                             ])
                                                                        }else{
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                {title: 'Uyruğunuz', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Tc Kimlik Numarası', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Cinsiyetiniz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: CINSIYET_SEC},
                                                                                {title: 'Doğum Tarihi', isType: 'date', isValue: '', isPlan: null},
                                                                                {title: 'Doğum Yeriniz', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Babanızın', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                {title: 'Annenizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                {title: 'Medeni Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: MEDENI_DURUM_SEC},
                                                                                {title: 'Evli İseniz', isType: 'title', isValue: '', isPlan: null},
                                                                                {title: 'Eşinizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                {title: 'Çocuk Bilgileriniz', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Doğum Tarihi', isType: 'date', isValue: ''}]},
                                                                                {title: 'Bakmakla Yükümlü Olduğunuz Kişiler', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Yakınlık Derecesi', isType: 'string', isValue: ''}]},
                                                                                {title: 'Boyunuz', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Kilonuz', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Askerlik Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: ASKERLIK_DURUMU_SEC},
                                                                             ])
                                                                        }
                                                                       
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline4"
                                                                > 

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-1">
                                                                            <div >
                                                                            <AvField 
                                                                                    name="nationally"
                                                                                    label={props.t("Your Nationally")}
                                                                                    className ="form-select"
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Nationally")}
                                                                                    >
                                                                                        

                                                                                    </AvField>

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="IdentificationNumber"
                                                                                    label={props.t("IdentificationNumber")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("IdentificationNumber")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                                    
                                                                               
                                                                                
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Date Of Birth"
                                                                                    label={props.t("Date Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Date Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="placeOfBirth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                      
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherName"
                                                                                    label={props.t("Father Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherProfession"
                                                                                    label={props.t("Father's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherName"
                                                                                    label={props.t("Mother Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherProfession"
                                                                                    label={props.t("Mother's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="maritalStatus"
                                                                                    label={props.t("Marital Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Marital Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Marital Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2"></Col>
                                                                        <p className="mt-3">{props.t("If you are married")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnerName"
                                                                                    label={props.t("Partner Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnersProfession"
                                                                                    label={props.t("Partner's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Child Infos")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="childsName"
                                                                                    label={props.t("Child's Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Child's Birthday"
                                                                                    label={props.t("Child's Birthday")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Birthday")}
                                                                                />

                                                                            </div>
                                                                          
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Your Dependents")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree of proximity"
                                                                                    label={props.t("Degree of proximity")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of proximity")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Weight"
                                                                                    label={props.t("Your Weight")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Weight")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Height"
                                                                                    label={props.t("Your Height")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Height")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                        <div >
                                                                                <AvField
                                                                                    name="Military Status"
                                                                                    label={props.t("Military Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Military Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Military Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                                    </Col >
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{  backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="4"
                                                                    id="customRadioInline5"
                                                                    name="customRadioInline2"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                {title: 'Nationality', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Identity Number', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Gender', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Male,Female'},
                                                                                {title: 'Date of Birth', isType: 'date', isValue: '', isPlan: null},
                                                                                {title: 'Place of Birth', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Fathers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Mothers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Marital Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Married,Single'},
                                                                                {title: 'If you are married', isType: 'title', isValue: '', isPlan: null},
                                                                                {title: 'Your Partners', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Children Infos', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Date of Work', isType: 'date', isValue: ''}]},
                                                                                {title: 'Your Dependents', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Degree of proximity', isType: 'string', isValue: ''}]},
                                                                                {title: 'Your Height', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Your Weight', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Military Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Postponement,Discharge,Exempt'},
                                                                                {title: 'Social Security Number', isType: 'string', isValue: '', isPlan: null},
                                                                                    
                                                                             ])}else{
                                                                                setModuleObjects([
                                                                                    { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                    {title: 'Uyruğunuz', isType: 'number', isValue: '', isPlan: null},
                                                                                    {title: 'Tc Kimlik Numarası', isType: 'number', isValue: '', isPlan: null},
                                                                                    {title: 'Cinsiyetiniz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: CINSIYET_SEC},
                                                                                    {title: 'Doğum Tarihi', isType: 'date', isValue: '', isPlan: null},
                                                                                    {title: 'Doğum Yeriniz', isType: 'string', isValue: '', isPlan: null},
                                                                                    {title: 'Babanızın', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                    {title: 'Annenizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                    {title: 'Medeni Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: MEDENI_DURUM_SEC},
                                                                                    {title: 'Evli İseniz', isType: 'title', isValue: '', isPlan: null},
                                                                                    {title: 'Eşinizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                                    {title: 'Çocuk Bilgileriniz', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Doğum Tarihi', isType: 'date', isValue: ''}]},
                                                                                    {title: 'Bakmakla Yükümlü Olduğunuz Kişiler', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Yakınlık Derecesi', isType: 'string', isValue: ''}]},
                                                                                    {title: 'Boyunuz', isType: 'string', isValue: '', isPlan: null},
                                                                                    {title: 'Kilonuz', isType: 'string', isValue: '', isPlan: null},
                                                                                    {title: 'Askerlik Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: ASKERLIK_DURUMU_SEC},
                                                                                    {title: 'Sosyal Güvenlik Numaranız', isType: 'string', isValue: '', isPlan: null},
                                                                                 ])
                                                                             }
                                                                      
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline5"
                                                                > 

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-1">
                                                                            <div >
                                                                            <AvField 
                                                                                    name="nationally"
                                                                                    label={props.t("Your Nationally")}
                                                                                    className ="form-select"
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Nationally")}
                                                                                    >
                                                                                        

                                                                                    </AvField>

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="IdentificationNumber"
                                                                                    label={props.t("IdentificationNumber")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("IdentificationNumber")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                                    
                                                                               
                                                                                
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="placeOfBirth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                      
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherName"
                                                                                    label={props.t("Father Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherProfession"
                                                                                    label={props.t("Father's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherName"
                                                                                    label={props.t("Mother Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherProfession"
                                                                                    label={props.t("Mother's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="maritalStatus"
                                                                                    label={props.t("Marital Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Marital Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Marital Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2"></Col>
                                                                        <p className="mt-3">{props.t("If you are married")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnerName"
                                                                                    label={props.t("Partner Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnersProfession"
                                                                                    label={props.t("Partner's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Child Infos")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="childsName"
                                                                                    label={props.t("Child's Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Child's Birthday"
                                                                                    label={props.t("Child's Birthday")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Birthday")}
                                                                                />

                                                                            </div>
                                                                          
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Your Dependents")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree of proximity"
                                                                                    label={props.t("Degree of proximity")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of proximity")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Weight"
                                                                                    label={props.t("Your Weight")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Weight")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Height"
                                                                                    label={props.t("Your Height")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Height")}
                                                                                />

                                                                            </div>
                                                                        </Col >
                                                                        <Col md="6"className="mt-2">
                                                                        <div >
                                                                                <AvField
                                                                                    name="Military Status"
                                                                                    label={props.t("Military Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Military Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Military Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                                    </Col >
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Social Security Number"
                                                                                    label={props.t("Social Security Number")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Social Security Number")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{  backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="5"
                                                                    id="customRadioInline6"
                                                                    name="customRadioInline2"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleObjects([
                                                                                { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                                {title: 'Nationality', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Identity Number', isType: 'number', isValue: '', isPlan: null},
                                                                                {title: 'Gender', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Male,Female'},
                                                                                {title: 'Date of Birth', isType: 'date', isValue: '', isPlan: null},
                                                                                {title: 'Place of Birth', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Fathers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Mothers', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Marital Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Married,Single'},
                                                                                {title: 'If you are married', isType: 'title', isValue: '', isPlan: null},
                                                                                {title: 'Your Partners', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Job', isType: 'string', isValue: ''}]},
                                                                                {title: 'Children Infos', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Date of Work', isType: 'date', isValue: ''}]},
                                                                                {title: 'Your Dependents', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Degree of proximity', isType: 'string', isValue: ''}]},
                                                                                {title: 'Your Height', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Your Weight', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Military Status', isType: 'dropdown', isValue: '', isPlan: null, isOptions: 'Postponement,Discharge,Exempt'},
                                                                                {title: 'Social Security Number', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Your Religion', isType: 'string', isValue: '', isPlan: null},
                                                                                    
                                                                             ])}else{
                                                                        setModuleObjects([
                                                                            { title: props.t("Personal information"),isType: "title",isValue: ""},
                                                                            {title: 'Uyruğunuz', isType: 'number', isValue: '', isPlan: null},
                                                                            {title: 'Tc Kimlik Numarası', isType: 'number', isValue: '', isPlan: null},
                                                                            {title: 'Cinsiyetiniz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: CINSIYET_SEC},
                                                                            {title: 'Doğum Tarihi', isType: 'date', isValue: '', isPlan: null},
                                                                            {title: 'Doğum Yeriniz', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Babanızın', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                            {title: 'Annenizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                            {title: 'Medeni Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: MEDENI_DURUM_SEC},
                                                                            {title: 'Evli İseniz', isType: 'title', isValue: '', isPlan: null},
                                                                            {title: 'Eşinizin', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Mesleği', isType: 'string', isValue: ''}]},
                                                                            {title: 'Çocuk Bilgileriniz', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Doğum Tarihi', isType: 'date', isValue: ''}]},
                                                                            {title: 'Bakmakla Yükümlü Olduğunuz Kişiler', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Yakınlık Derecesi', isType: 'string', isValue: ''}]},
                                                                            {title: 'Boyunuz', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Kilonuz', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Askerlik Durumunuz', isType: 'dropdown', isValue: '', isPlan: null, isOptions: ASKERLIK_DURUMU_SEC},
                                                                            {title: 'Sosyal Güvenlik Numaranız', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Dininiz', isType: 'string', isValue: '', isPlan: null},
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label md="12"
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline6"
                                                                > 

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-1">
                                                                            <div >
                                                                            <AvField 
                                                                                    name="nationally"
                                                                                    label={props.t("Your Nationally")}
                                                                                    className ="form-select"
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Nationally")}
                                                                                    >
                                                                                        

                                                                                    </AvField>

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="IdentificationNumber"
                                                                                    label={props.t("IdentificationNumber")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("IdentificationNumber")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Gender"
                                                                                    label={props.t("Gender")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Gender")}
                                                                                    >
                                                                                         <option>{props.t("Select Gender")}</option>

                                                                                    </AvField>
                                                                                    
                                                                               
                                                                                
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="placeOfBirth"
                                                                                    label={props.t("Place Of Birth")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Place Of Birth")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                      
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherName"
                                                                                    label={props.t("Father Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="fatherProfession"
                                                                                    label={props.t("Father's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Father's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherName"
                                                                                    label={props.t("Mother Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="motherProfession"
                                                                                    label={props.t("Mother's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Mother's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                        <Col md="6"className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="maritalStatus"
                                                                                    label={props.t("Marital Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Marital Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Marital Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2"></Col>
                                                                        <p className="mt-3">{props.t("If you are married")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnerName"
                                                                                    label={props.t("Partner Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="partnersProfession"
                                                                                    label={props.t("Partner's profession")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Partner's profession")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Child Infos")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="childsName"
                                                                                    label={props.t("Child's Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Child's Birthday"
                                                                                    label={props.t("Child's Birthday")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Child's Birthday")}
                                                                                />

                                                                            </div>
                                                                          
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <p className="mt-2">{props.t("Your Dependents")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree of proximity"
                                                                                    label={props.t("Degree of proximity")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of proximity")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="12"> <hr/></Col>
                                                                        <Col md="6"className="mt-1">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Weight"
                                                                                    label={props.t("Your Weight")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Weight")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Height"
                                                                                    label={props.t("Your Height")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Height")}
                                                                                />

                                                                            </div>
                                                                        </Col >
                                                                        <Col md="6"className="mt-2">
                                                                        <div >
                                                                                <AvField
                                                                                    name="Military Status"
                                                                                    label={props.t("Military Status")}
                                                                                    className ="form-select"
                                                                                    type="select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Military Status")}
                                                                                    >
                                                                                         <option>{props.t("Select Military Status")}</option>

                                                                                    </AvField>
                                                                                    </div>
                                                                                    </Col >
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Social Security Number"
                                                                                    label={props.t("Social Security Number")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Social Security Number")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Your Religion"
                                                                                    label={props.t("Your Religion")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Your Religion")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           
                                                           
                                                        </Row>
                                                    </Col>
                                                     
                                                    </Row>

                                                </div>
                                            </TabPane>
                                            <TabPane
                                                tabId="3"  >

                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline7"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleContact([
                                                                                { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                                { title: "Address",  isType: "longText",isValue: ""},
                                                                                { title: "District",isType: "string", isValue: ""},
                                                                                { title: "Cty",isType: "string", isValue: ""},
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleContact([
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            { title: "Adresiniz",  isType: "longText",isValue: ""},
                                                                            { title: "İlçe",isType: "string", isValue: ""},
                                                                            { title: "İl",isType: "string", isValue: ""},
                                                                            
                                                                                  
                                                                         ]) }
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline7"
                                                                >

                                                                    <Row className="mt-3">
                                                                        <Col md="6" className="mt-3">
                                                                            <div >
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-3" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="district"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="city"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline8"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleContact([
                                                                                { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                                { title: "Address",  isType: "longText",isValue: ""},
                                                                                { title: "District",isType: "string", isValue: ""},
                                                                                { title: "Cty",isType: "string", isValue: ""},
                                                                                { title: "Home Phone",isType: "phone", isValue: ""}, 
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleContact([
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            { title: "Adresiniz",  isType: "longText",isValue: ""},
                                                                            { title: "Posta Kodu",isType: "number", isValue: ""},
                                                                            { title: "İlçe",isType: "string", isValue: ""},
                                                                            { title: "İl",isType: "string", isValue: ""},
                                                                             { title: "Ev Telefonu",isType: "phone", isValue: ""}, 
                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline8"
                                                                >

                                                                    <Row className="mt-4" >
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="zipCode"
                                                                                    label={props.t("Zip Code")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Zip Code")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="district"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="city"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline9"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleContact([
                                                                                { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                                { title: "Address",  isType: "longText",isValue: ""},
                                                                                { title: "Zip Code",isType: "number", isValue: ""},
                                                                                { title: "District",isType: "string", isValue: ""},
                                                                                { title: "Cty",isType: "string", isValue: ""},
                                                                                { title: "Home Phone",isType: "phone", isValue: ""}, 
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleContact([
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            { title: "Adresiniz",  isType: "longText",isValue: ""},
                                                                            { title: "Posta Kodu",isType: "number", isValue: ""},
                                                                            { title: "İlçe",isType: "string", isValue: ""},
                                                                            { title: "İl",isType: "string", isValue: ""},
                                                                            { title: "Ev Telefonu",isType: "phone", isValue: ""}, 
                                                                            
                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline9"
                                                                >

                                                                    <Row className="mt-4" >
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="zipCode"
                                                                                    label={props.t("Zip Code")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Zip Code")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="district"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="city"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Home Phone"
                                                                                    label={props.t("Home Phone")}
                                                                                    type="text"
                                                                                   
                                                                                    
                                                                                    
                                                                                    placeholder="(999) 999-9999"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Home Phone")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline10"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleContact([
                                                                                { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                                { title: "Address",  isType: "longText",isValue: ""},
                                                                                { title: "Zip Code",isType: "number", isValue: ""},
                                                                                { title: "District",isType: "string", isValue: ""},
                                                                                { title: "Cty",isType: "string", isValue: ""},
                                                                                { title: "Home Phone",isType: "phone", isValue: ""}, 
                                                                                {title: 'Social Media', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Instagram', isType: 'string', isValue: ''},{title: 'Facebook', isType: 'string', isValue: ''},{title: 'Twitter', isType: 'string', isValue: ''},{title: 'Youtube', isType: 'string', isValue: ''},{title: 'Snapchat', isType: 'string', isValue: ''}]},
                                                                             ])
                                                                           }else{
                                                                        setModuleContact([
                                                                            { title: props.t("Contact Information"),isType: "title",isValue: ""},
                                                                            { title: "Adresiniz",  isType: "longText",isValue: ""},
                                                                            { title: "Posta Kodu",isType: "number", isValue: ""},
                                                                            { title: "İlçe",isType: "string", isValue: ""},
                                                                            { title: "İl",isType: "string", isValue: ""},
                                                                            { title: "Ev Telefonu",isType: "phone", isValue: ""},
                                                                            {title: 'Sosyal Medya', isType: 'singleGroup', isValue: '', isPlan:[{title: 'Instagram', isType: 'string', isValue: ''},{title: 'Facebook', isType: 'string', isValue: ''},{title: 'Twitter', isType: 'string', isValue: ''},{title: 'Youtube', isType: 'string', isValue: ''},{title: 'Snapchat', isType: 'string', isValue: ''}]}, 
                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline10"
                                                                >

                                                                    <Row className="mt-4" >
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="zipCode"
                                                                                    label={props.t("Zip Code")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Zip Code")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="district"
                                                                                    label={props.t("District")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("District")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="city"
                                                                                    label={props.t("City")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("City")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Home Phone"
                                                                                    label={props.t("Home Phone")}
                                                                                    type="text"
                                                                                   
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Home Phone")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6"className="mt-2"></Col>
                                                                        <p className="mt-3">{props.t("Social Media")}</p>
                                                                        <Col md="6">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Instagram"
                                                                                    label={props.t("Instagram")}
                                                                                    type="text"
                                                                                      errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Instagram")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Facebook"
                                                                                    label={props.t("Facebook")}
                                                                                    type="text"
                                                                                      errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Facebook")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Twitter"
                                                                                    label={props.t("Twitter")}
                                                                                    type="text"
                                                                                      errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Twitter")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Youtube"
                                                                                    label={props.t("Youtube")}
                                                                                    type="text"
                                                                                      errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Youtube")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Snapchat"
                                                                                    label={props.t("Snapchat")}
                                                                                    type="text"
                                                                                      errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Snapchat")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                          
                                                           
                                                        </Row>
                                                    </Col>

                                                </div>

                                            </TabPane>
                                            <TabPane
                                                tabId="4"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline11"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleExperience([
                                                                                
                                                                                {title: 'Professional Experience', isType: 'group', isValue: '', isPlan:[{title: 'Name of Work Place', isType: 'string', isValue: ''},{title: 'Duty', isType: 'string', isValue: ''},{title: 'Time Worked', isType: 'string', isValue: ''}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleExperience([
                                                                          
                                                                            {title: 'Mesleki Tecrübe', isType: 'group', isValue: '', isPlan:[{title: 'Çalışılan İş Yerinin Adı', isType: 'string', isValue: ''},{title: 'Görev', isType: 'string', isValue: ''},{title: 'Çalışılan Süre', isType: 'string', isValue: ''}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline11"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name of Work Place"
                                                                                    label={props.t("Name of Work Place")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name of Work Place")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Duty"
                                                                                    label={props.t("Duty")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Duty")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Time Worked"
                                                                                    label={props.t("Time Worked")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Time Worked")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline12"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        setModuleExperience([
                                                                            {title: 'Mesleki Tecrübe', isType: 'group', isValue: '', isPlan:[{title: 'Çalışılan İş Yerinin Adı', isType: 'string', isValue: ''},{title: 'Çalışılan Bölüm', isType: 'string', isValue: ''},{title: 'Görev', isType: 'string', isValue: ''},
                                                                            {title: 'İşe Giriş Tarihi', isType: 'date', isValue: ''},  {title: 'İşten Çıkış Tarihi', isType: 'date', isValue: ''}, {title: 'Ayrılma Sebebiniz', isType: 'string', isValue: ''}, {title: 'Son Aldığınız Maaş', isType: 'number', isValue: ''}]}, 

                                                                                  
                                                                         ])
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline12"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name of Work Place"
                                                                                    label={props.t("Name of Work Place")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name of Work Place")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Department of Work"
                                                                                    label={props.t("Department of Work")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Department of Work")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Duty"
                                                                                    label={props.t("Duty")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Duty")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Start Date of Work"
                                                                                    label={props.t("Start Date of Work")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={" "}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="End Date of Work"
                                                                                    label={props.t("End Date of Work")}
                                                                                    type="date"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={" "}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Reason of Leaving"
                                                                                    label={props.t("Reason of Leaving")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Reason of Leaving")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Last Salary"
                                                                                    label={props.t("Last Salary")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Last Salary")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="5"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                    <div  style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline13"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        setModuleEducation([
                                                                            {title: 'Eğitim Bilgileri', isType: 'group', isValue: '', isPlan:[{title: 'Bitirilen Okul ve Bölüm', isType: 'string', isValue: ''},{title: 'Bitirme Süresi', isType: 'string', isValue: ''}]}, 

                                                                                  
                                                                         ])
                                                                      }}
                                                                />
                                                                <Label 
                                                                    className="form-check-label font-size-13" md="8"
                                                                    htmlFor="customRadioInline13"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Graduated School and Department"
                                                                                    label={props.t("Graduated School and Department")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Graduated School and Department")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Completion Time"
                                                                                    label={props.t("Completion Time")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Completion Time")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div  style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline14"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleEducation([
                                                                                {title: 'Education Informations', isType: 'group', isValue: '', isPlan:[{title: 'Graduated School and Department', isType: 'string', isValue: ''},{title: 'Starting Date', isType: 'date', isValue: ''},{title: 'Finish Date', isType: 'date', isValue: ''}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleEducation([
                                                                            {title: 'Eğitim Bilgileri', isType: 'group', isValue: '', isPlan:[{title: 'Bitirilen Okul ve Bölüm', isType: 'string', isValue: ''},{title: 'Başlama Tarihi', isType: 'date', isValue: ''},{title: 'Btirme Tarihi', isType: 'date', isValue: ''}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline14"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Graduated School and Department"
                                                                                    label={props.t("Graduated School and Department")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Graduated School and Department")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Starting Date"
                                                                                    label={props.t("Starting Date")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Starting Date")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Finish Date"
                                                                                    label={props.t("Finish Date")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Finish Date")}
                                                                                />

                                                                            </div>
                                                                        </Col> </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="6"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline15"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleComputerSkills([
                                                                                {title: 'Computer Skills', isType: 'group', isValue: '', isPlan:[{title: 'Known Programs', isType: 'string', isValue: ''},{title: 'Level', isType: 'dropdown', isValue: '',isOptions:"1,2,3,4,5"},{title: 'How Did You Learn?', isType: 'longText', isValue: ''}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleComputerSkills([
                                                                            {title: 'Bilgisayar Bilgisi', isType: 'group', isValue: '', isPlan:[{title: 'Bildiğiniz Programlar', isType: 'string', isValue: ''},{title: "Seviye",isType: "dropdown",isValue: "",isOptions:"1,2,3,4,5"},{title: 'Nasıl Öğrendiniz', isType: 'longText', isValue: ''}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline15"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Known Programs"
                                                                                    label={props.t("Known Programs")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Known Programs")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Level"
                                                                                    label={props.t("Level")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Level")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="How Did You Learn?"
                                                                                    label={props.t("How Did You Learn?")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("How Did You Learn?")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="7"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline16"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleCourse([
                                                                                {title: 'Certificates and Courses', isType: 'group', isValue: '', isPlan:[{title: 'Course or Certificate Name', isType: 'string', isValue: ''},{title: "Educational Institution",isType: "string",isValue: ""},{title: 'Time of Course or Certificate', isType: 'string', isValue: ''},{title: 'Degree of Completion', isType: 'string', isValue: ''},{title: "Document Type",isType: "dropdown",isValue: "",isOptions:"None,Certificate,Certificate of participation"}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleCourse([
                                                                            {title: 'Sertifika ve Kurslar', isType: 'group', isValue: '', isPlan:[{title: 'Kurs veya Sertifika İsmi', isType: 'string', isValue: ''},{title: "Eğitim Veren Kurum",isType: "string",isValue: ""},{title: 'Süresi', isType: 'string', isValue: ''},{title: 'Bitirme Derecesi', isType: 'string', isValue: ''},{title: "Belge Türü",isType: "dropdown",isValue: "",isOptions:"Yok,Sertifika,Katılım Belgesi"}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline16"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Course or Certificate Name"
                                                                                    label={props.t("Course or Certificate Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Course or Certificate Name")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Educational Institution"
                                                                                    label={props.t("Educational Institution")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Educational Institution")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Time of Course or Certificate"
                                                                                    label={props.t("Time of Course or Certificate")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Time of Course or Certificate")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree of Completion"
                                                                                    label={props.t("Degree of Completion")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of Completion")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2">
                                                                            <div>
                                                                                <AvField
                                                                                    name="Document Type"
                                                                                    label={props.t("Document Type")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Document Type")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="8"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline17"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleLanguage([
                                                                                {title: 'Foreign Language Knowledge', isType: 'group', isValue: '', isPlan:[{title: 'Language Name', isType: 'string', isValue: ''},{title: "Level",isType: "dropdown",isValue: "",isOptions:"Beginner,Elementary,Pre intermediate,Intermediate,Upper intermediate"}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleLanguage([
                                                                            {title: 'Yabancı Dil Bilgisi', isType: 'group', isValue: '', isPlan:[{title: 'Dil İsmi', isType: 'string', isValue: ''},{title: "Seviye",isType: "dropdown",isValue: "",isOptions:"Zayıf,Az,Orta,İyi,Çok iyi"}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline17"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Language Name"
                                                                                    label={props.t("Language Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Language Name")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Level"
                                                                                    label={props.t("Level")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Level")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline18"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleLanguage([
                                                                                {title: 'Foreign Language Knowledge', isType: 'group', isValue: '', isPlan:[{title: 'Language Name', isType: 'string', isValue: ''},{title: "Speaking Level",isType: "dropdown",isValue: "", isOptions:"Beginner,Elementary,pre intermediate,intermediate,upper intermediate"},{title: "Reading Level",isType: "dropdown",isValue: "", isOptions:"Beginner,Elementary,pre intermediate,intermediate,upper intermediate"},{title: "Writing Level",isType: "dropdown",isValue: "", isOptions:"Beginner,Elementary,pre intermediate,intermediate,upper intermediate"}]}, 
                                                                                
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleLanguage([
                                                                            {title: 'Yabancı Dil Bilgisi', isType: 'group', isValue: '', isPlan:[{title: 'Dil İsmi', isType: 'string', isValue: ''},{title: "Konuşma Seviyesi",isType: "dropdown",isValue: "",isOptions:"Zayıf,Az,Orta,İyi,Çok iyi"},{title: "Okuma Seviyesi",isType: "dropdown",isValue: "",isOptions:"Zayıf,Az,Orta,İyi,Çok iyi"},{title: "Yazma Seviyesi",isType: "dropdown",isValue: "",isOptions:"Zayıf,Az,Orta,İyi,Çok iyi"}]}, 

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline18"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Language Name"
                                                                                    label={props.t("Language Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Language Name")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Speaking Level"
                                                                                    label={props.t("Speaking Level")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Speaking Level")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Reading Level"
                                                                                    label={props.t("Reading Level")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Reading Level")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Writing Level"
                                                                                    label={props.t("Writing Level")}
                                                                                    type="form"
                                                                                    className="form-select"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Writing Level")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                             
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="9"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline19"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleHealth([
                                                                               
                                                                                {title: props.t("Health Information"), isType: 'title', isValue: ''},
                                                                                {title: 'Blood Group', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Body Disability', isType: 'string', isValue: '', isPlan: null},
                                                                                {title: 'Surgeries', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''}]},
                                                                                {title: 'Chronic Disease', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''}]},
                                                                                {title: 'Drugs You Use', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''}]},
                                                                                {title: 'Addiction', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''}]},
                                                                                      
                                                                             ])
                                                                           }else{
                                                                        setModuleHealth([
                                                                            {title: props.t("Health Information"), isType: 'title', isValue: ''},
                                                                            {title: 'Kan Grubu', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Bedeni Özür', isType: 'string', isValue: '', isPlan: null},
                                                                            {title: 'Geçirilen Ameliyatlar', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''}]},
                                                                            {title: 'Kronik Hastalık', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''}]},
                                                                            {title: 'Kullandığınız İlaçlar', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''}]},
                                                                            {title: 'Bağımlılık', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''}]},
                                                                            

                                                                                  
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline19"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Blood Group"
                                                                                    label={props.t("Blood Group")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Blood Group")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Body Disability"
                                                                                    label={props.t("Body Disability")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Body Disability")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Surgeries"
                                                                                    label={props.t("Surgeries")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Surgeries")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Chronic Disease"
                                                                                    label={props.t("Chronic Disease")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Chronic Disease")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Drugs You Use"
                                                                                    label={props.t("Drugs You Use")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Drugs You Use")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Addiction"
                                                                                    label={props.t("Addiction")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Addiction")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                          
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="10"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline20"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleEmergency([
                                                                                {title: 'Name and Surname of the Person to be notified', isType: 'group', isValue: '', isPlan:[{title: 'Name Lastname', isType: 'string', isValue: ''},{title: 'Degree of proximity', isType: 'string', isValue: ''},{title: 'Phone Number', isType: 'phone', isValue: ''},{title: 'Adress', isType: 'longText', isValue: ''}]},
                                                                             ])
                                                                           }else{
                                                                        setModuleEmergency([
                                                                            
                                                                            {title: 'Acil Durumlarda Haber Verilecek Kişi', isType: 'group', isValue: '', isPlan:[{title: 'Adı Soyadı', isType: 'string', isValue: ''},{title: 'Yakınlık Derecesi', isType: 'string', isValue: ''},{title: 'Telefon Numarası', isType: 'phone', isValue: ''},{title: 'Adresi', isType: 'longText', isValue: ''}]},
                                                                                     
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline20"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name and Surname of the Person to be notified"
                                                                                    label={props.t("Name and Surname of the Person to be notified")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name and Surname of the Person to be notified")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree of proximity"
                                                                                    label={props.t("Degree of proximity")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of proximity")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Phone Number"
                                                                                    label={props.t("Phone Number")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Phone Number")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Address"
                                                                                    label={props.t("Address")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Address")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             <TabPane
                                                tabId="11"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline21"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleAssociations([
                                                                                {title: 'Associations or institutions you are registered', isType: 'group', isValue: '', isPlan:[{title: 'Name', isType: 'string', isValue: ''},{title: 'Duty', isType: 'string', isValue: ''}]},
                                                                           
                                                                             ])
                                                                           }else{
                                                                        setModuleAssociations([
                                                                            
                                                                            {title: 'Kayıtlı olduğunuz dernek veya kurumlar', isType: 'group', isValue: '', isPlan:[{title: 'Adı', isType: 'string', isValue: ''},{title: 'Göreviniz', isType: 'string', isValue: ''}]},
                                                                            
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13" md="8"
                                                                    htmlFor="customRadioInline21"
                                                                >

                                                                    <Row className="mt-4">
                                                                        <Col md="6" className="mt-2">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Associations or institutions you are registered"
                                                                                    label={props.t("Associations or institutions you are registered")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Associations or institutions you are registered")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Duty"
                                                                                    label={props.t("Duty")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Duty")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>

                                             <TabPane
                                                tabId="12"  >
                                                <div >
                                                    <Col sm="12" className="mt-2">
                                                    <Row>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline22"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleReferences([
                                                                                {title: 'References', isType: 'group', isValue: '', isPlan:[{title: 'Name Lastname', isType: 'string', isValue: ''},{title: 'Name of Work Place', isType: 'string', isValue: ''},{title: 'Degree', isType: 'string', isValue: ''},{title: 'Phone Number', isType: 'phone', isValue: ''}]},
                                                                            
                                                                             ])
                                                                           }else{
                                                                        setModuleReferences([
                                                                            
                                                                            {title: 'Referanslar', isType: 'group', isValue: '', isPlan:[{title: 'Adı Soyadı', isType: 'string', isValue: ''},{title: 'İş Yeri', isType: 'string', isValue: ''},{title: 'Ünvanı', isType: 'string', isValue: ''},{title: 'Telefonu', isType: 'phone', isValue: ''}]},
                                                                                     
                                                                         ])}
                                                                      }}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13" md="8"
                                                                    htmlFor="customRadioInline22"
                                                                >

                                                                    <Row className="mt-4"> 
                                                                    <Col md="6"className="mt-2"></Col>
                                                                        <p >{props.t("References")}</p>
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Workplace"
                                                                                    label={props.t("Workplace")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Workplace")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree"
                                                                                    label={props.t("Degree")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Phone Number"
                                                                                    label={props.t("Phone Number")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Phone Number")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                            <div   style={{ backgroundColor: "#F9F9FC" }} className="form-check form-check-inline font-size-16 mt-3">
                                                                <Input
                                                                  style={{ position: "relative" , top:"9px",left:"12px" }}
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline23"
                                                                    name="customRadioInline3"
                                                                    className="form-check-input"
                                                                    onChange={event => {
                                                                        if( localStorage.getItem("I18N_LANGUAGE")=="en"){
                                                                            setModuleReferences([
                                                                                {title: 'References', isType: 'group', isValue: '', isPlan:[{title: 'Name Lastname', isType: 'string', isValue: ''},{title: 'Degree of proximity', isType: 'string', isValue: ''},{title: 'Name of Work Place', isType: 'string', isValue: ''},{title: 'Degree', isType: 'string', isValue: ''},{title: 'Phone Number', isType: 'phone', isValue: ''}]},
                                                                               
                                                                            
                                                                             ])
                                                                           }else{
                                                                        setModuleReferences([
                                                                            
                                                                            {title: 'Referanslar', isType: 'group', isValue: '', isPlan:[{title: 'Adı Soyadı', isType: 'string', isValue: ''},{title: 'Yakınlık Derecesi', isType: 'string', isValue: ''},{title: 'İş Yeri', isType: 'string', isValue: ''},{title: 'Ünvanı', isType: 'string', isValue: ''},{title: 'Telefonu', isType: 'phone', isValue: ''}]},
                                                                                     
                                                                         ])}
                                                                      }}
                                                                      
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13" md="8"
                                                                    htmlFor="customRadioInline23"
                                                                >

                                                                    <Row className="mt-4">
                                                                    <Col md="6"className="mt-2"></Col>
                                                                        <p >{props.t("References")}</p>
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Name"
                                                                                    label={props.t("Name")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Name")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div >
                                                                                <AvField
                                                                                    name="Degree of proximity"
                                                                                    label={props.t("Degree of proximity")}
                                                                                    type="text"
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree of proximity")}
                                                                                    
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Workplace"
                                                                                    label={props.t("Workplace")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Workplace")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Degree"
                                                                                    label={props.t("Degree")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Degree")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6" className="mt-2" >
                                                                            <div>
                                                                                <AvField
                                                                                    name="Phone Number"
                                                                                    label={props.t("Phone Number")}
                                                                                    type="text"
                                                                                    
                                                                                    errorMessage={props.t("InvalidInput")}
                                                                                    validate={{
                                                                                        required: { value: false },
                                                                                    }}
                                                                                    disabled
                                                                                    value={props.t("Phone Number")}
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                       
                                                                        
                                                                    </Row>
                                                                    <br /> <br />
                                                                </Label>
                                                            </div>
                                                           
                                                           
                                                           </Row>
                                                    </Col>
                                                      </div>
                                             </TabPane>
                                             

                                        </TabContent>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>

                    </div>

                                                                                    
                   
                    <Row>
                     <Col>
                         <div className="text-end">
                             <button
                                 type="submit"
                                 className="btn btn-success save-user"
                             >
                                 {props.t("Save")}
                             </button>
                             &nbsp;&nbsp;
                             <Button onClick={() =>

                                 props.cancel(true)

                                  }>
                                 {props.t("Cancel")}
                             </Button>

                         </div>
                     </Col>
                 </Row>

                </AvForm>
                
            </ModalBody>
           
        </Modal>
    )
}


PreparedModulesModal.propTypes = {

    modal: PropTypes.bool,
    cancel:PropTypes.func,
    t: PropTypes.any,
    toggle: PropTypes.func,
    save:PropTypes.func,
    modalToggle: PropTypes.bool,


}


export default withRouter(
    (withTranslation()(PreparedModulesModal))
)
