/** Get DEV_SECTOR_FORM */
export const GET_DEV_SECTOR_FORMS = "GET_DEV_SECTOR_FORMS";
export const GET_DEV_SECTOR_FORMS_SUCCESS = "GET_DEV_SECTOR_FORMS_SUCCESS";
export const GET_DEV_SECTOR_FORMS_FAIL = "GET_DEV_SECTOR_FORMS_FAIL";

export const GET_DEV_SECTOR_FORM_DETAIL = "GET_DEV_SECTOR_FORM_DETAIL";
export const GET_DEV_SECTOR_FORM_DETAIL_SUCCESS = "GET_DEV_SECTOR_FORM_DETAIL_SUCCESS";
export const GET_DEV_SECTOR_FORM_DETAIL_FAIL = "GET_DEV_SECTOR_FORM_DETAIL_FAIL";


export const ADD_DEV_SECTOR_FORM = "ADD_DEV_SECTOR_FORM";
export const ADD_DEV_SECTOR_FORM_SUCCESS = "ADD_DEV_SECTOR_FORM_SUCCESS";
export const ADD_DEV_SECTOR_FORM_FAIL = "ADD_DEV_SECTOR_FORM_FAIL";

export const UPDATE_DEV_SECTOR_FORM = "UPDATE_DEV_SECTOR_FORM";
export const UPDATE_DEV_SECTOR_FORM_SUCCESS = "UPDATE_DEV_SECTOR_FORM_SUCCESS";
export const UPDATE_DEV_SECTOR_FORM_FAIL = "UPDATE_DEV_SECTOR_FORM_FAIL";

export const DELETE_DEV_SECTOR_FORM = "DELETE_DEV_SECTOR_FORM";
export const DELETE_DEV_SECTOR_FORM_SUCCESS = "DELETE_DEV_SECTOR_FORM_SUCCESS";
export const DELETE_DEV_SECTOR_FORM_FAIL = "DELETE_DEV_SECTOR_FORM_FAIL"; 



export const RESET_DEV_SECTOR_FORM= "RESET_DEV_SECTOR_FORM";