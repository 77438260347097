import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SURVEY_FORMS, DELETE_SURVEY_FORM, ADD_SURVEY_FORM,
     UPDATE_SURVEY_FORM,GET_SURVEY_FORMS_MY_SURVEY_FORMS} from "./actionTypes";
import {
    getSurveyFormsSuccess, getSurveyFormsFail,
    deleteSurveyFormSuccess, deleteSurveyFormFail,
    updateSurveyFormSuccess, updateSurveyFormFail,
    addSurveyFormSuccess, addSurveyFormFail,

   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSurveyForms, //API CONNECTION
    delSurveyForm,
    addSurveyForm,
    updateSurveyForm
    
}
    from "../../helpers/fakebackend_helper"; 

 
function* fetchSurveyForms({payload:appFormId}) {
    try {
        const response = yield call(getSurveyForms,appFormId)
        yield put(getSurveyFormsSuccess(response));
    } catch (error) {
        yield put(getSurveyFormsFail(error));
    }
}

function* onDeleteSurveyForms({payload:id}){
    try{
        const response = yield call(delSurveyForm, id)
        yield put(deleteSurveyFormSuccess(response));
    } catch (error) {
        yield put(deleteSurveyFormFail(error));
    }
}

function* onAddSurveyForm({payload:surveyForm}){
    try{
        const response = yield call(addSurveyForm, surveyForm)
        yield put(addSurveyFormSuccess(response));
    } catch (error) {
        yield put(addSurveyFormFail(error));
    }
}

function* onUpdateSurveyForm({payload:surveyForm}){
    try{
        const response = yield call(updateSurveyForm, surveyForm)
        yield put(updateSurveyFormSuccess(response));
    } catch (error) {
        yield put(updateSurveyFormFail(error));
    }
}

export function* surveyFormsSaga() {
    yield takeEvery(GET_SURVEY_FORMS, fetchSurveyForms);
    yield takeEvery(DELETE_SURVEY_FORM, onDeleteSurveyForms);
    yield takeEvery(ADD_SURVEY_FORM, onAddSurveyForm);
    yield takeEvery(UPDATE_SURVEY_FORM, onUpdateSurveyForm);

    
    
}

export default surveyFormsSaga;
