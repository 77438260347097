import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_DEV_FOREIGN_LANGUAGES, DELETE_DEV_FOREIGN_LANGUAGE, ADD_DEV_FOREIGN_LANGUAGE, UPDATE_DEV_FOREIGN_LANGUAGE,GET_DEV_FOREIGN_LANGUAGES_MY_DEV_FOREIGN_LANGUAGES} from "./actionTypes";
import {
    getDevForeignLanguagesSuccess, getDevForeignLanguagesFail,
    deleteDevForeignLanguageSuccess, deleteDevForeignLanguageFail,
    updateDevForeignLanguageSuccess, updateDevForeignLanguageFail,
    addDevForeignLanguageSuccess, addDevForeignLanguageFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getDevForeignLanguages, //API CONNECTION
    delDevForeignLanguage,
    addDevForeignLanguage,
    updateDevForeignLanguage,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchDevForeignLanguages() {
    try {
        const response = yield call(getDevForeignLanguages)
        yield put(getDevForeignLanguagesSuccess(response));
    } catch (error) {
        yield put(getDevForeignLanguagesFail(error));
    }
}

function* onDeleteDevForeignLanguages({payload:id}){
    try{
        const response = yield call(delDevForeignLanguage, id)
        yield put(deleteDevForeignLanguageSuccess(response));
    } catch (error) {
        yield put(deleteDevForeignLanguageFail(error));
    }
}

function* onAddDevForeignLanguage({payload:devForeignLanguage}){
    try{
        const response = yield call(addDevForeignLanguage, devForeignLanguage)
        yield put(addDevForeignLanguageSuccess(response));
    } catch (error) {
        yield put(addDevForeignLanguageFail(error));
    }
}

function* onUpdateDevForeignLanguage({payload:devForeignLanguage}){
    try{
        const response = yield call(updateDevForeignLanguage, devForeignLanguage)
        yield put(updateDevForeignLanguageSuccess(response));
    } catch (error) {
        yield put(updateDevForeignLanguageFail(error));
    }
}

export function* devForeignLanguagesSaga() {
    yield takeEvery(GET_DEV_FOREIGN_LANGUAGES, fetchDevForeignLanguages);
    yield takeEvery(DELETE_DEV_FOREIGN_LANGUAGE, onDeleteDevForeignLanguages);
    yield takeEvery(ADD_DEV_FOREIGN_LANGUAGE, onAddDevForeignLanguage);
    yield takeEvery(UPDATE_DEV_FOREIGN_LANGUAGE, onUpdateDevForeignLanguage);
   
    
}

export default devForeignLanguagesSaga;
