import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  Input,
  FormGroup,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"

import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {

  getCompanyByQr as onGetCompanyByQr,
  getAdvertisementsForQr as onGetAdvertisement,
  getCompanyInfoByQr as onGetCompanyInfoByQr,
  getPartnershipsForQr as onGetPartnerships,
  getAvm_InfosForQr as onGetAvmInfos,
  getSectors as onGetSectors,

} from "store/actions"

import { isEmpty, map, update, valuesIn } from "lodash"
import moment from "moment"

import { AvForm, AvField } from "availity-reactstrap-validation"
const { SearchBar } = Search
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Dropzone from "react-dropzone"

import "./datatables.scss"
import classnames from "classnames"
import imgEmpty from "./../../assets/images/appforms/buqrda_is_var_d2.png"
import footerIcon from "./../../assets/images/appforms/buqrda_is_var_a.png"
import allIcon from "./../../assets/images/favicon.png"
import config from "config"
import Sector from "pages/Sector"
import CashCard from "./v2/components/CashCard"
import AvmLogo from "./../../assets/images/avmLogo2.png"

import { post } from "./../../helpers/api_helper"

const CompanyQr = props => {
  const dispatch = useDispatch()
  const ImgBase = (process.env.REACT_APP_BASE_API) + "static/uploads/"


  const [showForms, setShowForms] = useState(false)
  const [formKey, setFormKey] = useState("")
  const [modal, setModal] = useState(false)
  const [formStep, setFormStep] = useState(0)
  const [selectedFiles, setselectedFiles] = useState([])
  const [tabTitleList, setTabTitleList] = useState([])
  const [tempList, setTempList] = useState([])
  const [tempAdList, setTempAdList] = useState([])
  const [tempSectors, setTempSectors] = useState([])
  const colors = ["#0088FE", "#00C49F", "#FFBB28"];
  const delay = 5000;
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const [analyticCount, setAnalyticCount] = useState(0);

  const { error, Plans, CompanyInfo, Branches, Advertisement, Partnership, Sectors, AvmInfo } = useSelector(state => ({
    error: state.Companies?.error,
    Branches: state.Companies?.Branches,
    Plans: state.Plans.myPlans,
    CompanyInfo: state.Companies?.companyInfo,
    Advertisement: state.Advertisement.data,
    Partnership: state.Partnership?.data,
    Sectors: state.Sector?.data,
    AvmInfo: state.AvmInfo?.data[0]
  }))
  const {
    match: { params },
  } = props


  let user = JSON.parse(localStorage.getItem("authUser"))

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === Advertisement.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);
  useEffect(() => {

    dispatch(onGetSectors())
    dispatch(onGetCompanyByQr(params.id))
    dispatch(onGetCompanyInfoByQr(params.id))


  }, [])
  useEffect(() => {
    if (!isEmpty(CompanyInfo)) {
      dispatch(onGetAdvertisement(CompanyInfo?._id))
      dispatch(onGetPartnerships(CompanyInfo?._id))
      dispatch(onGetAvmInfos(CompanyInfo?._id))
      // console.log("Company",CompanyInfo?._id)
      if(analyticCount == 0){
        setAnalyticCount(1)
        analytic()
      }
    }
  }, [CompanyInfo])
  useEffect(() => {
    setFormStep(0)
    if (!isEmpty(Partnership)) {
      setTempList(
        Partnership.filter(
          x => x.status == "approved"
        )
      )
    }
  }, [Partnership])
  useEffect(() => {

    if (!isEmpty(Advertisement)) {
      setTempAdList(
        Advertisement.filter(
          x => x.isActive == true
        )
      )
    }
  }, [Advertisement])
  useEffect(() => {
    if (!isEmpty(Sectors) && !isEmpty(Partnership)) {
      let sList = []
      sList.push({ name: "*", selected: true, })
      for (let i = 0; i < Sectors.length; i++) {
        if(Partnership.filter(x=>x.sector == Sectors[i]._id.toString()).length>0){
          sList.push({
            name: Sectors[i].name,
            _id: Sectors[i]._id,
            selected: false,
          })
        } 
      }
      setTempSectors(sList)
      //console.log("sList", sList, Sectors, tempList)
    }
  }, [Sectors, Partnership])

  
  useEffect(() => {
    setFormStep(0)
    if (isEmpty(tabTitleList) && !isEmpty(Branches)) {
      let tList = []
      tList.push({ title: "*", selected: true, branches: [] })
      for (let b of Branches) {
        for (let pl of b.AppPlans) { 
          if (tList.filter(x => x.title == pl.workType).length == 0) {
            tList.push({
              title: pl.workType,
              selected: false,
              branches: [b.Branch._id],
            })
          } else {
            tList
              .filter(x => x.title == pl.workType)[0]
              ?.branches.push(b.Branch._id)
          }
        }
      }
      //console.log("Liste :", tList)
      setTabTitleList(tList)
    }
  }, [Branches])
 

  const analytic = async () => {
    
    console.log("analytic test", location)
    let tagArr = []
    let search = location.search;
    if(isEmpty(search)){
      search="?tag=genel"
    }
    if (!isEmpty(search)) {
      let tagArrSplit = search.replace("?", "").split("&")
      for (let tIndex in tagArrSplit) {
        let tagKeyVal = tagArrSplit[tIndex].split("=")
        if (tagKeyVal.length == 2) {
          let istag = {}
          istag[tagKeyVal[0]] = tagKeyVal[1]
          tagArr.push(istag)
        }
      }
      console.log("tagArr",tagArr)
      console.log("CompanyInfo",CompanyInfo)

      const analyticModel = {
        base: {
          metakey: location.pathname.replace("/avm/", ""),
          title: CompanyInfo.name,
          description: CompanyInfo.description,
          link: location.pathname.replace("/avm/", ""),
          CompanyId: CompanyInfo?._id,
          BranchId: null
        },
        model: {
          title: CompanyInfo.name,
          description: CompanyInfo.description,
          link: location.pathname.replace("/avm/", ""),
          CompanyId: CompanyInfo?._id,
          tags: tagArr,
          BranchId: null
        },
      }

      console.log("analyticModel",analyticModel)
      await post("api/analytic/add", analyticModel)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }
  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    try {
      console.log("Congrats! Your browser supports Web Share API")
      await navigator
        .share({
          title: CompanyInfo?.name,
          text: "Bu QR'da iş var",
          url: `https://buqrdaisvar.com/avm/${CompanyInfo.qrCode}`,
        })
        .then(() => {
          console.log("Sharing successfull")
        })
        .catch(() => {
          console.log("Sharing failed")
        })
    } catch (err) {
      console.log("Sorry! Your browser does not support Web Share API", err)
    }
  }

  return (
    selectedPage == 1 ? (
      <React.Fragment>
        <Container style={{ position: "relative", top: "15px", bottom: "15px" }}>
        

          {CompanyInfo?.photo?(
          <div className="card" style={{ marginBottom: "10px" }}>
            <div
              className="card-body"
              style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}
              onClick={() => {

                setSelectedPage(0)


              }}
            >
              <img style={{ height: "150px", width: "100%", objectFit: "contain" }}
                src={ImgBase + CompanyInfo?.photo}
              //className="company-appPlan-icon"
              />

            </div>
          </div>):null}

          {/* <div className="slideshow">
              <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)`, cursor: "pointer" }}
              >
                {tempAdList.map((backgroundColor, index) => (

                  <div onClick={() => {
                    window.open(
                      backgroundColor.link
                    )

                  }}

                    className="slide"
                    key={index}
                  >
                    <img style={{ height: "100%", width: "100%", objectFit: "contain" }}

                      src={ImgBase + backgroundColor.photo}


                    /></div>
                ))}
              </div>

              <div className="slideshowDots">
                {tempAdList.map((_, idx) => (
                  <div
                    key={idx}
                    className={`slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div> */}



          <div>
            <AvForm>
              <Row form>
                <Col xs="12" xl="12" lg="12" md="12" sm="12">
                  <div
                    style={{
                      position: "relative",
                      left: 0,
                      right: 0,
                      zIndex: 99,
                      width: "100%",
                    }}
                  >

                    <div id="categoryButtonContainer">
                      {!isEmpty(tempSectors)
                        ? tempSectors.map((tab, index) => (
                          <div
                            key={index}
                            className={
                              "categoryButton" +
                              (tab.selected ? " selected" : "")
                            }
                            onClick={() => {
                              let v = [...tempSectors]
                              if (v.filter(x => x.selected == true).length > 0) {
                                v.filter(
                                  x => x.selected == true
                                )[0].selected = false
                              }
                              v[index].selected = true 
                              setTempSectors(v)
                              if (tab.name == "*") {

                                setTempList(
                                  Partnership.filter(
                                    x => x.status == "approved"
                                  )
                                )
                              } else {

                                setTempList(
                                  Partnership.filter(
                                    x => x.status == "approved" && x.sector == tab._id
                                  )
                                )
                              }  
                            }}
                          >
                            {tab.title == "*" ? (
                              <img src={allIcon} className="all-icon" />
                            ) : (
                              <span>{tab.name}</span>
                            )}
                          </div>
                        ))
                        : null}
                    </div>
                     {/* <div id="categoryButtonContainer">
                    {!isEmpty(tabTitleList)
                      ? tabTitleList.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            "categoryButton" +
                            (tab.selected ? " selected" : "")
                          }
                          onClick={() => {
                            let v = [...tabTitleList]
                            if (
                              v.filter(x => x.selected == true).length > 0
                            ) {
                              v.filter(
                                x => x.selected == true
                              )[0].selected = false
                            }
                            v[index].selected = true
                            //console.log(v[index]) 
                            setTabTitleList(v)
                          }}
                        >
                          {tab.title == "*" ? (
                            <img src={allIcon} className="all-icon" />
                          ) : (
                            <span>{tab.title}</span>
                          )}
                        </div>
                      ))
                      : null}
                  </div>  */}

                  </div>
                  <Row>
                    {tempList?.map((link, key) =>


                      <Col xl="6" lg="6" sm="6" md="6" xs="6"

                        className="mt-2"
                        key={"_col_" + key}

                      >
                        <div>
                          <Card
                            style={{
                              borderRadius: "10px",
                              boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                              height: "70px",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => {

                              window.open(process.env.REACT_APP_DOMAIN + "/v2/" + link?.Link)
                            }}
                          >
                            <CardBody>
                              <Row xl="6" lg="6" sm="6" md="6" xs="6">
                                <Col xl="2" lg="2" sm="3" md="3" xs="4">
                                  {link.companyPhoto ? (
                                    <img
                                      src={config.baseImg + link.companyPhoto}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        objectFit: "contain",
                                        position: "relative",
                                        bottom: "14px",
                                        right: "13px",
                                        borderRadius: "5px"
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imgEmpty}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        objectFit: "contain",
                                        position: "relative",
                                        bottom: "14px",
                                        right: "13px",
                                      }}
                                    />
                                  )}
                                </Col>
                                <Col xl="10" lg="10" sm="9" md="9" xs="8">
                                  <h5 style={{ fontSize: "14px" }}>{link?.CompanyId?.name}</h5>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          {showForms && key == formKey ? (
                            <div className="mb-4">
                              {Branches[key].AppPlans?.map((plan, key) =>
                                tabTitleList.filter(x => x.selected == true)[0]
                                  .title == "*" ||
                                  tabTitleList.filter(x => x.selected == true)[0]
                                    .title == plan?.workType ? (
                                  <Col
                                    md="12"
                                    className="mt-2"
                                    key={"_form_" + key}
                                  >
                                    <Card
                                      className="formCard"
                                      onClick={() => {
                                        window.open(
                                          `https://buqrdaisvar.com/ik/${plan._id}`
                                        )
                                        //console.log(plan)
                                      }}
                                    >
                                      <CardBody>
                                        <Row
                                          xl="12"
                                          lg="12"
                                          sm="12"
                                          md="12"
                                          xs="12"
                                        >
                                          <h4
                                            style={{
                                              fontSize: "12px",
                                              position: "relative",
                                              bottom: "19px",
                                            }}
                                          >
                                            {plan.name}
                                          </h4>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                ) : null
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                    )}
                  </Row>

                  {/* {Branches?.map((link, key) =>
                  !isEmpty(tabTitleList) &&
                  !isEmpty(tabTitleList.filter(x => x.selected == true)) &&
                  (tabTitleList.filter(x => x.selected == true)[0].title ==
                    "*" ||
                    tabTitleList
                      .filter(x => x.selected == true)[0]
                      .branches.includes(link.Branch._id) == true) ? (
                    <Col
                      md="12"
                      className="mt-2"
                      key={"_col_" + key}
                      id={link.Branch._id}
                    >
                      <div>
                        <Card
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            height: "111px",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (key == formKey) {
                              setShowForms(!showForms)
                            } else {
                              setShowForms(true)
                            }

                            //dispatch(onGetPlans_ByBranch(link.Branch._id))
                            setFormKey(key)
                          }}
                        >
                          <CardBody>
                            <Row xl="12" lg="12" sm="12" md="12" xs="12">
                              <Col xl="2" lg="2" sm="3" md="3" xs="4">
                                {link.Branch?.photo ? (
                                  <img
                                    src={config.baseImg + link.Branch?.photo}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "contain",
                                      position: "relative",
                                      bottom: "14px",
                                      right: "13px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imgEmpty}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "contain",
                                      position: "relative",
                                      bottom: "14px",
                                      right: "13px",
                                    }}
                                  />
                                )}
                              </Col>
                              <Col xl="10" lg="10" sm="9" md="9" xs="8">
                                <h5>{link.Branch.name}</h5>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        {showForms && key == formKey ? (
                          <div className="mb-4">
                            {Branches[key].AppPlans?.map((plan, key) =>
                              tabTitleList.filter(x => x.selected == true)[0]
                                .title == "*" ||
                              tabTitleList.filter(x => x.selected == true)[0]
                                .title == plan?.workType ? (
                                <Col
                                  md="12"
                                  className="mt-2"
                                  key={"_form_" + key}
                                >
                                  <Card
                                    className="formCard"
                                    onClick={() => {
                                      window.open(
                                        `https://buqrdaisvar.com/ik/${plan._id}`
                                      )
                                      //console.log(plan)
                                    }}
                                  >
                                    <CardBody>
                                      <Row
                                        xl="12"
                                        lg="12"
                                        sm="12"
                                        md="12"
                                        xs="12"
                                      >
                                        <h4
                                          style={{
                                            fontSize: "12px",
                                            position: "relative",
                                            bottom: "19px",
                                          }}
                                        >
                                          {plan.name}
                                        </h4>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              ) : null
                            )}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null
                )} */}


                </Col>
              </Row>
            </AvForm>
          </div>

          <div className="my-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="my-footer-container col-md-12">
                  <img className="footer-icon" src={footerIcon} />
                  <a
                    className="btn btn-lg btn-primary"
                    onClick={async () => {
                      await handleShareButton()
                    }}
                  >
                    {props.t("Share Frends")}
                  </a>
                </div>
              </div>
              {/* <div className="row">
              <div className="col-md-6">2022 © İş Başvuru.</div>
              <div className="col-md-6">
                <div className="text-sm-end d-none d-sm-block">Design &amp; Develop by Uniqode</div>
              </div>
            </div> */}
            </div>
          </div>
        </Container>
      </React.Fragment>
    ) : <Container style={{ position: "relative", top: "15px", bottom: "15px" }}>
       {CompanyInfo?.photo?(
     <div className="card" style={{ marginBottom: "10px" }}>
     <div
       className="card-body"
       style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}
       onClick={() => {

         setSelectedPage(0)


       }}
     >
       <img style={{ height: "150px", width: "100%", objectFit: "contain" }}
         src={ImgBase + CompanyInfo?.photo}
       //className="company-appPlan-icon"
       />

     </div>
   </div>
       ):null}
      {Advertisement[0]?.photo  && Advertisement[0]?.isActive==true ? (
      <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div className="card-body"  style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}>
           <img style={{ height: "100%", width: "100%", objectFit: "contain" }}
              onClick={() => {
                window.open(Advertisement[0]?.link)
              }}
              src={ImgBase + Advertisement[0]?.photo}
              /> 
              </div>
      </div> ) 
      : null}
       {Advertisement[1]?.photo  && Advertisement[1]?.isActive==true ? (
      <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div className="card-body"  style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}>
           <img style={{ height: "100%", width: "100%", objectFit: "contain" }}
              onClick={() => {
                window.open(Advertisement[1]?.link)
              }}
              src={ImgBase + Advertisement[1]?.photo}
              /> 
              </div>
      </div> ) 
      : null}
      <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div
          className="card-body"
          style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}

        >
          <img style={{ height: "100%", width: "100%", objectFit: "contain" }} onClick={() => {
            setSelectedPage(1)
          }}
          src={ImgBase + AvmInfo?.photo}
          //className="company-appPlan-icon"
          />

        </div>
      </div>
      {Advertisement[2]?.photo  && Advertisement[2]?.isActive==true ? (
      <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div className="card-body"  style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}>
           <img style={{ height: "100%", width: "100%", objectFit: "contain" }}
              onClick={() => {
                window.open(Advertisement[2]?.link)
              }}
              src={ImgBase + Advertisement[2]?.photo}
              /> 
              </div>
      </div> ) 
      : null}
       {Advertisement[3]?.photo  && Advertisement[3]?.isActive==true ? (
      <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div className="card-body"  style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}>
           <img style={{ height: "100%", width: "100%", objectFit: "contain" }}
              onClick={() => {
                window.open(Advertisement[3]?.link)
              }}
              src={ImgBase + Advertisement[3]?.photo}
              /> 
              </div>
      </div> ) 
      : null}
      {/* <div className="card" style={{ marginBottom: "10px", marginRight: "20px", marginLeft: "20px", borderRadius: "20px" }}>
        <div
          className="card-body"
          style={{ textAlign: "center", padding: "1rem", cursor: "pointer" }}

        >
          {Advertisement[1]?.photo   && Advertisement[1]?.isActive==true  ? (
            <img style={{ height: "100%", width: "100%", objectFit: "contain" }}
              onClick={() => {
                window.open(Advertisement[1]?.link)
              }}
              src={ImgBase + Advertisement[1]?.photo}
            //className="company-appPlan-icon"
            />
          ) :
            <div style={{ height: "150px", width: "100%", objectFit: "contain" }}

            //className="company-appPlan-icon"
            />}


        </div>
      </div> */}
      {/* <Col sm="12" md="12" lg="12" xl="12"
                  onClick={() => {
                   window.open(AvmInfo?.website)
                  }}
                >
      <CashCard
        title={props.t("Web Sitemiz")}
        border={"2px solid blue"}

      />
      </Col>
      <Col sm="12" md="12" lg="12" xl="12"
                  onClick={() => {
                    setSelectedPage(1)
                  }}
                >
      <CashCard 
        title={props.t("Firmalarımıza Başvurun")}
        border={"2px solid orange"} 

      />
       </Col>
       <Col sm="12" md="12" lg="12" xl="12"
                  onClick={() => {
                    window.open(AvmInfo?.pdf)
                  }}
                >
      <CashCard
        title={props.t("Dergimiz")}
        border={"2px solid red"}

      />
       </Col> */}
    </Container>


  )
}

export default withRouter(withTranslation()(CompanyQr))
