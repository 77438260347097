export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"

export const REGISTER2_USER = "REGISTER2_USER"
export const REGISTER2_USER_SUCCESSFUL = "REGISTER2_USER_SUCCESSFUL"
export const REGISTER2_USER_FAILED = "REGISTER2_USER_FAILED"

export const REGISTER3_USER = "REGISTER3_USER"
export const REGISTER3_USER_SUCCESSFUL = "REGISTER3_USER_SUCCESSFUL"
export const REGISTER3_USER_FAILED = "REGISTER3_USER_FAILED"

export const REGISTER_SUCCESS_RESET = "register_success_reset"
  