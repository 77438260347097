/** Get DEV_FOREIGN_LANGUAGE */
export const GET_DEV_FOREIGN_LANGUAGES = "GET_DEV_FOREIGN_LANGUAGES";
export const GET_DEV_FOREIGN_LANGUAGES_SUCCESS = "GET_DEV_FOREIGN_LANGUAGES_SUCCESS";
export const GET_DEV_FOREIGN_LANGUAGES_FAIL = "GET_DEV_FOREIGN_LANGUAGES_FAIL";

export const GET_DEV_FOREIGN_LANGUAGE_DETAIL = "GET_DEV_FOREIGN_LANGUAGE_DETAIL";
export const GET_DEV_FOREIGN_LANGUAGE_DETAIL_SUCCESS = "GET_DEV_FOREIGN_LANGUAGE_DETAIL_SUCCESS";
export const GET_DEV_FOREIGN_LANGUAGE_DETAIL_FAIL = "GET_DEV_FOREIGN_LANGUAGE_DETAIL_FAIL";


export const ADD_DEV_FOREIGN_LANGUAGE = "ADD_DEV_FOREIGN_LANGUAGE";
export const ADD_DEV_FOREIGN_LANGUAGE_SUCCESS = "ADD_DEV_FOREIGN_LANGUAGE_SUCCESS";
export const ADD_DEV_FOREIGN_LANGUAGE_FAIL = "ADD_DEV_FOREIGN_LANGUAGE_FAIL";

export const UPDATE_DEV_FOREIGN_LANGUAGE = "UPDATE_DEV_FOREIGN_LANGUAGE";
export const UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS = "UPDATE_DEV_FOREIGN_LANGUAGE_SUCCESS";
export const UPDATE_DEV_FOREIGN_LANGUAGE_FAIL = "UPDATE_DEV_FOREIGN_LANGUAGE_FAIL";

export const DELETE_DEV_FOREIGN_LANGUAGE = "DELETE_DEV_FOREIGN_LANGUAGE";
export const DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS = "DELETE_DEV_FOREIGN_LANGUAGE_SUCCESS";
export const DELETE_DEV_FOREIGN_LANGUAGE_FAIL = "DELETE_DEV_FOREIGN_LANGUAGE_FAIL"; 



export const RESET_DEV_FOREIGN_LANGUAGE= "RESET_DEV_FOREIGN_LANGUAGE";