import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
    Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
    DropdownItem
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    getAdvertisements as onGetAdvertisement,

    addAdvertisement as onAddAdvertisement,
    updateAdvertisement as onUpdateAdvertisement,
    resetAdvertisement as onResetAdvertisement,
    updateAdvertisementActive as onUpdateAdvertisementActive,

    deleteAdvertisement as onDeleteAdvertisement
} from "store/actions"
import config from "config"
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Dropzone from "react-dropzone"
import imgEmpty from "./../../assets/images/empty.png"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
}];

const pageOptions = {
    sizePerPage: 10,
    totalSize: 0, // replace later with size(customers),
    custom: true,
}


const Advertisement = props => {
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [modal, setModal] = useState(false);
    const [checkModal, setCheckModal] = useState(false);
    const [selectedFiles, setselectedFiles] = useState([])
    const dispatch = useDispatch()
    const [selectedWorkType, setSelectedWorkType] = useState()
    const [selectedId, setSelectedId] = useState();
    const [selectedPayment, setSelectedPayment] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const { SearchBar } = Search;
    useEffect(() => {
        dispatch(onGetAdvertisement());
    }, [dispatch]);

    const { error, Advertisement } = useSelector(state => ({
        error: state.Brands?.error,
        Advertisement: state.Advertisement?.data


    }))
    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const columns = [
        {
            dataField: "photo",
            text: props.t("Photo"),
            sort: false,
            formatter: (cell, row) => (
                <div>
                    {cell ? (
                        <img
                            src={config.baseImg + cell}

                            style={{ width: "75px", height: "75px", objectFit: "contain" }}
                        />
                    ) : (
                        <img
                            src={imgEmpty}

                            style={{ width: "75px", height: "75px", objectFit: "contain" }}
                        />
                    )}
                </div>
            ),
        },
        {
            dataField: 'name',
            text: props.t("Name"), 
            sort: true

        },
        {
            dataField: 'link',
            text: props.t("Link"),
            sort: true,
            formatter: (cell, row) => (
              
                <span style={{ display:"inline-block",wordBreak:"break-word",tableLayout:"fixed",whiteSpace:"normal" }}>{cell}</span>
            )

        },
        {
            dataField: 'activeDate',
            text: props.t("Activation Date"),
            sort: true,
            formatter: (cell, row) => (
                row.isActive==true?
                <span>{handleValidDate(cell)}</span>:null
            )
      
        }, 
        {
            dataField: 'isActive',
            text: props.t("Status"),
            sort: true,
            formatter: (cell, row) => ( 
                <div>
                    <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="photo"
                            style={{ marginRight: '54px' }}
                          >
                           
                          </label>
                          <input
                            type="checkbox"
                            name="photo"
                            id="photo"
                            className="form-check-input"
                            checked={cell}
                            onChange={(e) => {
                                const updateBrand = {
                                    _id: row._id,
                                    };
                    
                                dispatch(onUpdateAdvertisementActive(updateBrand));
                                dispatch(onGetAdvertisement());
                            }}
                          />

                        </Col>
                </div>
            ),

        },



        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedPayment(row);
                                    console.log("22row", row)

                                    setIsEdit(true);
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];


    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }

    const handleValidProductSubmit = (e, values) => {
        if (isEdit) {
            const updateBrand = {
                _id: selectedPayment._id,
                name: values["name"],
                photo: values["photo"],
                link:values["link"]

            };

            dispatch(onUpdateAdvertisement(updateBrand, selectedFiles));
        } else {
            const newBrand = {
                CompanyId: user.companyId,
                name: values["name"],
                photo: values["photo"],
                link:values["link"]
            }

            dispatch(onAddAdvertisement(newBrand, selectedFiles))
        }
        toggle()

    }
    function handleAcceptedFiles(files) {
        files.map(file => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        })
        setselectedFiles(files);
        console.log(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Advertisement")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Advertisement")} breadcrumbItem={props.t("Advertisement")} addBtn={Advertisement.length <=3?true:false} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedPayment(
                            {
                                companyId: "",
                                name: "",
                                link: " ",
                                description: "",
                                amount: "",
                                // PaymentPlanObjects: "",

                            }
                        );


                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={Advertisement}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={Advertisement}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} size="lg" toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Edit Advertisement") : props.t("Add Advertisement")}
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidProductSubmit} >
                                        <Row form>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                                                            <div>
                                                                {selectedPayment?.photo ? <img src={config.baseImg + selectedPayment?.photo} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                                                                    : <img src={imgEmpty} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                                                                }

                                                            </div>
                                                            {/* {selectedEmbedded=="Phone"? handleAcceptedFiles(acceptedFiles):null} */}
                                                            <div style={{ width: "65%", border: "1px solid #ced4da", borderRadius: "10px", height: "150px" }} >
                                                                <Dropzone

                                                                    multiple={false}
                                                                    accept="image/jpeg, image/png"
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles)

                                                                    }}

                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="dropzone-min" style={{ wordBreak: "break-all" }} >
                                                                            <div
                                                                                className="dz-message needsclick"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="dz-message needsclick" style={{ padding: "0px" }}>
                                                                                    <div>
                                                                                        <i className="display-5 text-muted bx bxs-cloud-upload" />
                                                                                    </div>
                                                                                    <h4>{props.t("Drop Image")}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                <div
                                                                    className="dropzone-previews"
                                                                    id="file-previews"
                                                                    style={{ position: "absolute", zIndex: 99, left: 0, top: -5 }}
                                                                >
                                                                    {selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <div className="align-items-center">
                                                                                        <div className="col-12">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                style={{ width: "182px", height: "108px", objectFit: "contain" }}
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </Card>
                                                                        )
                                                                    })}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="name"
                                                                label={props.t("Name")}
                                                                type="text"
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                value={selectedPayment?.name || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="link"
                                                                label={props.t("Link")}
                                                                type="text"
                                                                errorMessage={props.t("InvalidInput")}
                                                                
                                                                value={selectedPayment?.link || ""}
                                                            />
                                                        </div>
                                                    </Col>


                                                </Row>




                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        {props.t("Save")}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={checkModal} size="lg" toggle={checkToggle}>
                                <ModalHeader toggle={checkToggle} tag="h4">
                                    {!!isEdit ? props.t("Ürün") : props.t("Ürün")}
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm   >
                                        <Row form>
                                            <Col xs={12}>

                                                <Row>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="brand"
                                                                label={props.t("Brand")}
                                                                type="text"
                                                                disabled
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                value={selectedPayment?.brand || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="model"
                                                                label={props.t("model")}
                                                                type="text"
                                                                disabled
                                                                errorMessage={props.t("InvalidInput")}
                                                                validate={{
                                                                    required: { value: false },
                                                                }}
                                                                value={selectedPayment?.model || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>








                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    {/* <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button> */}
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteAdvertisement(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetAdvertisement())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}


export default withRouter(withTranslation()(Advertisement))
