import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"

import {
  Col,
  Container,
  Form,
  Alert,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap"

import logodark from "../../assets/images/bqr2.png"
import logolight from "../../assets/images/bu_qrda_is_var_1.png"
import imgQRCode from "../../assets/images/index/buqrda_is_var_a.png"
import imgLogo from "../../assets/images/index/buqrda_is_var_ib.png"
import CarouselPage from "./CarouselPage"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"
import CarouselPageIndex from "./CarouselPageIndex"
import useCheckMobileScreen from "components/helpers/useCheckMobileScreen"

const Index = props => {
  const dispatch = useDispatch()

  const isMobile = useCheckMobileScreen()

  const { error, Company, user, registrationError } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    error: state.Login.error,
    Company: state.Users?.User?.companyId,
  }))

  useEffect(() => {
    if (isMobile == true) {
      window.location.replace("https://buqrdaisvar.com/avm/577a6fa33b3106d1")
    }
  }, [])

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Bu QR'da İş Var</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {useCheckMobileScreen()}
            <Col xl={12}>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  zIndex: 999,
                }}
              >
                <Link
                  to="/login"
                  style={{
                    fontFamily: "citrus-gothic",
                    color: "#1f509b",
                    fontSize: "22px",
                    marginRight: "22px",
                  }}
                >
                  İŞ ORTAĞI GİRİŞİ
                </Link>
                <Link
                  to="/register"
                  style={{
                    fontFamily: "citrus-gothic",
                    color: "#fb8633",
                    fontSize: "22px",
                    marginRight: "10px",
                  }}
                >
                  İŞ ORTAĞIMIZ OLUN
                </Link>
              </div>
            </Col>
            <CarouselPageIndex />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Index)

Index.propTypes = {
  history: PropTypes.object,
}
