import {
    GET_SURVEY_FORMS,
    GET_SURVEY_FORMS_SUCCESS,
    GET_SURVEY_FORMS_FAIL,
    GET_SURVEY_FORM_DETAIL,
    GET_SURVEY_FORM_DETAIL_SUCCESS,
    GET_SURVEY_FORM_DETAIL_FAIL,
    ADD_SURVEY_FORM,
    ADD_SURVEY_FORM_SUCCESS,
    ADD_SURVEY_FORM_FAIL, 
    UPDATE_SURVEY_FORM,
    UPDATE_SURVEY_FORM_SUCCESS,
    UPDATE_SURVEY_FORM_FAIL,
    DELETE_SURVEY_FORM,
    DELETE_SURVEY_FORM_SUCCESS,
    DELETE_SURVEY_FORM_FAIL,
    RESET_SURVEY_FORM,
 
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    success:null,
 
};

const SurveyForms = (state = INIT_STATE, action) => {
    switch (action.type) {
       
        case GET_SURVEY_FORMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SURVEY_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(surveyForm => surveyForm._id.toString() !== action.payload.data.toString())
            }
        case ADD_SURVEY_FORM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true 
            }
        case UPDATE_SURVEY_FORM_SUCCESS:
            return {
                ...state,
                success:true ,
                data: state.data.map(surveyForm =>
                    surveyForm._id.toString() === action.payload.data._id.toString() ?
                        { surveyForm, ...action.payload.data } :
                        surveyForm
                )
            }

        //FAILS
        case GET_SURVEY_FORMS_FAIL:
        case DELETE_SURVEY_FORM_FAIL:
        case ADD_SURVEY_FORM_FAIL:
        case UPDATE_SURVEY_FORM_FAIL:
      
            return {
                ...state,
                error: action.payload
            }

        case RESET_SURVEY_FORM: return {
            ...state,
            error: null,
            success:null
        }
        default:
            return state;
    }
}


export default SurveyForms;