import {
    GET_SECTORS,
    GET_SECTORS_SUCCESS,
    GET_SECTORS_FAIL,
    GET_SECTOR_DETAIL,
    GET_SECTOR_DETAIL_SUCCESS,
    GET_SECTOR_DETAIL_FAIL,
    ADD_SECTOR,
    ADD_SECTOR_SUCCESS,
    ADD_SECTOR_FAIL,
    UPDATE_SECTOR,
    UPDATE_SECTOR_SUCCESS,
    UPDATE_SECTOR_FAIL,
    DELETE_SECTOR,
    DELETE_SECTOR_SUCCESS,
    DELETE_SECTOR_FAIL,
    RESET_SECTOR,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const Sectors = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_SECTORS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SECTOR_SUCCESS:
            return {
                ...state,
                data: state.data.filter(sector => sector._id.toString() !== action.payload.data.toString())
            }
        case ADD_SECTOR_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_SECTOR_SUCCESS:
            return {
                ...state,
                data: state.data.map(sector =>
                    sector._id.toString() === action.payload.data._id.toString() ?
                        { sector, ...action.payload.data } :
                        sector
                )
            }

        //FAILS
        case GET_SECTORS_FAIL:
        case DELETE_SECTOR_FAIL:
        case ADD_SECTOR_FAIL:
        case UPDATE_SECTOR_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_SECTOR: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Sectors;