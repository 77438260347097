import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ADVERTISEMENTS, DELETE_ADVERTISEMENT, ADD_ADVERTISEMENT,GET_ADVERTISEMENTS_FOR_QR,
    UPDATE_ADVERTISEMENT_ACTIVE, UPDATE_ADVERTISEMENT,GET_ADVERTISEMENTS_MY_ADVERTISEMENTS} from "./actionTypes";
import { 
    getAdvertisementsSuccess, getAdvertisementsFail,
    deleteAdvertisementSuccess, deleteAdvertisementFail,
    updateAdvertisementSuccess, updateAdvertisementFail,
    addAdvertisementSuccess, addAdvertisementFail,
    updateAdvertisementActiveSuccess,updateAdvertisementActiveFail,
    getAdvertisementsForQrSuccess,getAdvertisementsForQrFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getAdvertisements, //API CONNECTION
    delAdvertisement,
    addAdvertisement,
    updateAdvertisement,
    updateAdvertisementActive,
    getAdvertisementsForQr
    
}
    from "../../helpers/fakebackend_helper"; 

    function* fetchAdvertisementsForQr({ payload: id }) {
        try {
            const response = yield call(getAdvertisementsForQr,id)
            yield put(getAdvertisementsForQrSuccess(response));
        } catch (error) {
            yield put(getAdvertisementsForQrFail(error));
        }
    }
function* fetchAdvertisements() {
    try {
        const response = yield call(getAdvertisements)
        yield put(getAdvertisementsSuccess(response));
    } catch (error) {
        yield put(getAdvertisementsFail(error));
    }
}

function* onDeleteAdvertisements({payload:id}){
    try{
        const response = yield call(delAdvertisement, id)
        yield put(deleteAdvertisementSuccess(response));
    } catch (error) {
        yield put(deleteAdvertisementFail(error));
    }
}

function* onAddAdvertisement({payload:advertisement, files: files}){
    try{
        const response = yield call(addAdvertisement, advertisement, files)
        yield put(addAdvertisementSuccess(response));
    } catch (error) {
        yield put(addAdvertisementFail(error));
    }
}

function* onUpdateAdvertisement({payload:advertisement, file: file}){
    try{
        const response = yield call(updateAdvertisement, advertisement,file)
        yield put(updateAdvertisementSuccess(response));
    } catch (error) {
        yield put(updateAdvertisementFail(error));
    }
}
function* onUpdateAdvertisementActive({payload:advertisement}){
    try{
        const response = yield call(updateAdvertisementActive, advertisement)
        yield put(updateAdvertisementActiveSuccess(response));
    } catch (error) {
        yield put(updateAdvertisementActiveFail(error));
    }
}

export function* advertisementsSaga() {
    yield takeEvery(GET_ADVERTISEMENTS, fetchAdvertisements);
    yield takeEvery(DELETE_ADVERTISEMENT, onDeleteAdvertisements);
    yield takeEvery(ADD_ADVERTISEMENT, onAddAdvertisement);
    yield takeEvery(UPDATE_ADVERTISEMENT, onUpdateAdvertisement);
    yield takeEvery(UPDATE_ADVERTISEMENT_ACTIVE, onUpdateAdvertisementActive);
    yield takeEvery(GET_ADVERTISEMENTS_FOR_QR, fetchAdvertisementsForQr);
   
    
}

export default advertisementsSaga;
