import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
//Modals


import ApplicationPlanModal from "./Modals/applicationPlanModal"
import { authorize, Role } from "./../../components/helpers/authorize"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  getOrientationPeriods as onGetOrientation,

  getCompanyBranches as onGetCompanyBranches,  
  addOrientationPeriod as onAddMyPlans,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  deleteOrientationPeriod as onDeletePlans_MyPlans,  
  updateOrientationPeriod as onUpdatePlans_MyPlans,

  addQRCode as onAddQRCode,
  getDutys as onGetDuties,

} from "store/actions"

import { defaultsDeep, isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"
import { select } from "redux-saga/effects"
import types from "react-cropper"


const Plans = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)

  const [modalQr, setModalQr] = useState(false)
 
  const [infoQr, setInfoQr] = useState()
  const [selectedId, setSelectedId] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [copyPlan, setCopyPlan] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [copy_alert, setCopy_alert] = useState(false)

  const [isGroupButton, setIsGroupButton] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [selectedWorkType, setSelectedWorkType] = useState()

  const [selectedPosition, setSelectedPosition] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [moduleInfo, setModuleInfo] = useState([]) 
  const [qrListData, setQrListData] = useState()
 
  const [planObjects, setPlanObjects] = useState([
    {
     
      FormId: "", //date, string, number, vs
      ManagerFormId: "",
      MailTime:"",
    
    },
  ])

  const { error,Orientations,Duty,OrientationPeriod } = useSelector(
    state => ({
      error: state.Plans?.error,
      Orientations:state.Orientation?.data,
      Duty: state.Duty?.data,
      OrientationPeriod:state.OrientationPeriod?.data

      
    
    })
  )
  let user = JSON.parse(localStorage.getItem("authUser"))
  console.log("asd",OrientationPeriod)

  useEffect(() => {
    dispatch(onGetDuties());
    dispatch(getUserByMail(user.email))
   
    
    if (authorize([Role.Admin])) {
      dispatch(onGetOrientation())
      dispatch(onGetCompanyBranches(user.companyId))
    } 
  }, [dispatch])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
 
 


  const branchReset = () => {
    setSelectedBranchId("")
  }
  const InputAddReset = () => {
    let tempInput = [
      {
        Form: "", //date, string, number, vs
      ManagerMail: "",
      MailTime:"",
       
      }, 
    ]
    setPlanObjects(tempInput)
  }
 

  //Dynamic Inputs END
  const columns = [
    {
      dataField: "DutyId",
      text: props.t("Duty Name"),
      formatter: (cell, row) => <span>{cell?.description}</span>,
    },
    
    {
      dataField: "CreatorUserId",
      text: props.t("Creator User Name"),
      filterValue: (cell, row) => cell?.name,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },
    {
      dataField: "createdAt",
      text: props.t("Oluşturulma Tarihi"),
      sort: true,
      formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
    },

    {
      dataField: "_id",
      text: props.t("Action"),

      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
            <DropdownMenu className="dropdown-menu-end">
             
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedPlan(row)
                  console.log("row",row)
                  setSelectedWorkType(row.workType)
                  setSelectedPosition(row.position)
                 
                 
                  setSelectedBranchId(row.BranchId?._id)

                  if (isEmpty(row.PlanObjects) || row.PlanObjects.length == 0) {
                    InputAddReset()
                  } else {
                    setPlanObjects(row.PlanObjects)
                    setPlanDescription(row.planDesc)
                  }
                  setIsEdit(true)
                  toggle()
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                {props.t("Edit")}
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  setSelectedId(cell)
                  setconfirm_alert(true)
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                {props.t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Plans ? Plans.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Plans ? Plans.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidPlanSubmit = (e, values) => {
    if (isEdit) {
      const updatePlan = {
        _id: selectedPlan._id,
       
        CreatorUserId:user.uid,
        CompanyId: user.companyId,
        DutyId: values["DutyId"],
        PlanObjects: JSON.stringify(planObjects),
      }
      // update Plan

      dispatch(onUpdatePlans_MyPlans(updatePlan))
    } else {
      const newPlan = {
        CreatorUserId:user.uid,
        CompanyId: user.companyId,
        DutyId: values["DutyId"],
        PlanObjects: JSON.stringify(planObjects),
       
      }
      // save new Plan

      dispatch(onAddMyPlans(newPlan))
    }
    toggle()
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
  const handleAdd = index => {
    let temp = planObjects
    let newProjectObjects = []
    temp.splice(index + 1, 0, {
     
      FormId: "", //date, string, number, vs
      ManagerFormId: "",
      MailTime:"",
    })

    for (let i = 0; i < temp.length; i++) {
      newProjectObjects.push(temp[i])
    }
    setPlanObjects(newProjectObjects)
  }
  const handleSubtract = i => {
    const values = [...planObjects]
    values.splice(i, 1)
    setPlanObjects([...values])
  }
  const handleChangeForm = (i, e) => {
     const values = [...planObjects]
     values[i].FormId = e
     setPlanObjects(values)
   }
   const handleChangeManagerMail = (i, e) => {
    const values = [...planObjects]
    values[i].ManagerFormId = e
    setPlanObjects(values)
  }
  const handleChangeMailTime = (i, e) => {
    const values = [...planObjects]
    values[i].MailTime = e
    setPlanObjects(values)
  }
  const Orientation=[""]
  console.log(">>",selectedPlan)
  return (
    <React.Fragment>
      <div>
       
        
          {/* Render Breadcrumb */}
          <Breadcrumb
           
            breadcrumbItem={props.t("Oryantaston Süreci Ekle")}
            addBtn={true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedPlan({
                name: "",
                description: "",

                PlanObjects: [],
              })
              branchReset()
              InputAddReset()

              toggle()
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={OrientationPeriod}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={OrientationPeriod}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={props.t(
                                  "You Don't Have a Plan Yet"
                                )}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>

          
    
     
              <Modal isOpen={modal} size="xl" toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                     Oryantasyon Süreci
                  </ModalHeader>
                  <ModalBody>
                      <AvForm onValidSubmit={handleValidPlanSubmit}>
                      <Row>
                              <Col md="3" sm="6" xs="12">  <div className="mb-3">
                                      <AvField
                                          className="form-select"
                                          label={props.t("Görev Seç")}
                                          type="select"
                                          name="DutyId"
                                          value={selectedPlan?.DutyId?._id || ""}

                                      >
                                          <option>{props.t("Görev Seç")}</option>
                                          {Duty?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                  {item?.name?.name}
                                              </option>
                                          ))}
                                      </AvField>
                                  </div></Col>
                                  </Row>
                      {planObjects?.map((item, i) => (
                         <div key={i} className="mt-4 mb-4">
                            <Row>
                              <Col md="3" sm="6" xs="12">
                                  <div className="mb-3">
                                      <AvField
                                          className="form-select"
                                          label={props.t("Kişiye Gönderilecek Form")}
                                          type="select"
                                          name="dutyId"
                                          value={planObjects[i].FormId}
                                          onChange={e =>
                                            handleChangeForm(  i,  e.target.value)
                                          }
                                      >
                                          <option>{props.t("Oryantasyon Formu Seç")}</option>
                                          {Orientations?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                  {item.name}
                                              </option>
                                          ))}
                                      </AvField>
                                  </div> </Col>
                              <Col md="3" sm="6" xs="12"> <div className="mb-3">
                                      <AvField
                                          className="form-select"
                                          label={props.t("Kişinin Müdürüne Gönderilecek Form")}
                                          type="select"
                                          name="dutyId"
                                          value={planObjects[i].ManagerFormId}
                                          onChange={e =>
                                            handleChangeManagerMail(  i,  e.target.value)
                                          }
                                         
                                      >
                                          <option>{props.t("Kişinin Müdürüne Form Gönderme")}</option>
                                          {Orientations?.map((item, index) => (
                                              <option key={index} value={item._id}>
                                                  {item.name}
                                              </option>
                                          ))}
                                      </AvField>
                                  </div> </Col>
                              <Col md="3" sm="6" xs="12">  <div className="mb-3">
                                      <AvField
                                          className="form-select"
                                          label={props.t("Gönderilme Süresi")}
                                          type="select"
                                          name="dutyId"
                                          value={planObjects[i].MailTime}
                                          onChange={e =>
                                            handleChangeMailTime(  i,  e.target.value)
                                          }
                                          
                                      >
                                         <option>{props.t("Süre Seç")}</option>
                                          <option>{props.t("1.Gün")}</option>
                                          <option>{props.t("2.Gün")}</option>
                                          <option>{props.t("3.Gün")}</option>
                                          <option>{props.t("4.Gün")}</option>
                                          <option>{props.t("5.Gün")}</option>
                                          <option>{props.t("6.Gün")}</option>
                                          <option>{props.t("7.Gün")}</option>
                                          <option>{props.t("8.Gün")}</option>
                                          <option>{props.t("9.Gün")}</option>
                                          <option>{props.t("10.Gün")}</option>
                                          <option>{props.t("20.Gün")}</option>
                                          <option>{props.t("30.Gün")}</option>
                                          <option>{props.t("45.Gün")}</option>
                                          <option>{props.t("2.Ay")}</option>
                                          <option>{props.t("3.Ay")}</option>
                                        <option>{props.t("6.Ay")}</option>
                                          <option>{props.t("1.Yıl")}</option>
                                          <option>{props.t("2.Yıl")}</option>
                                      </AvField>
                                  </div> </Col>
                               
                            <Col md="3" sm="6" xs="12" className="items-margin" style={{ position: "relative", top: "26px" }}>
                              <div>
                                <Button
                                  onClick={() => handleAdd(i)}
                                  className="btn btn-success me-2"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                                {planObjects.length > 1 ? (
                                  <Button 
                                    onClick={() => handleSubtract(i)}
                                    className="btn btn-danger"
                                  >
                                    <i className="bx bx-trash"></i>
                                  </Button>
                                ) : null}
                              </div>
                            </Col>   
                                 

                                  
                          </Row>
                         </div>
                      ))}
                         
                                
                         
                          <Row>
                              <Col>
                                  <div className="text-end">
                                      <button type="submit" className="btn btn-success save-user">
                                          {props.t("Save")}
                                      </button>
                                      &nbsp;&nbsp;
                                      <Button
                                          onClick={() =>
                                              // { toggle() }
                                              console.log("branchID", props.selectedBranchId)
                                          }
                                      >
                                          {props.t("Cancel")}
                                      </Button>
                                  </div>
                              </Col>
                          </Row>
                      </AvForm>
                  </ModalBody>
              </Modal>

         

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                //dispatch(onDeletePlan(selectedId));
                dispatch(onDeletePlans_MyPlans(selectedId))

                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}
          {copy_alert ? (
            <SweetAlert
              //title={props.t("Kopyalamak istediğinize emin misiniz?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Copy")}
              onConfirm={() => {
                setCopy_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Copied"))
                setdynamic_description("")

                dispatch(onAddMyPlans(copyPlan))

                setSelectedId(null)
              }}
              onCancel={() => setCopy_alert(false)}
            >
              {props.t("Copy Plan?")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetPlan())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Plans))
