import {
    GET_DUTIES_FORMS,
    GET_DUTIES_FORMS_SUCCESS,
    GET_DUTIES_FORMS_FAIL,
    GET_DUTIES_FORM_DETAIL,
    GET_DUTIES_FORM_DETAIL_SUCCESS,
    GET_DUTIES_FORM_DETAIL_FAIL,
    ADD_DUTIES_FORM,
    ADD_DUTIES_FORM_SUCCESS,
    ADD_DUTIES_FORM_FAIL,
    UPDATE_DUTIES_FORM,
    UPDATE_DUTIES_FORM_SUCCESS,
    UPDATE_DUTIES_FORM_FAIL,
    DELETE_DUTIES_FORM,
    DELETE_DUTIES_FORM_SUCCESS,
    DELETE_DUTIES_FORM_FAIL,
    RESET_DUTIES_FORM,

} from "./actionTypes";

const INIT_STATE = {
    data: [],  
    error: null,
    formDetail:{}
   
};

const DutiesForms = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DUTIES_FORMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_DUTIES_FORM_DETAIL_SUCCESS:
            return {
              ...state,
              formDetail: action.payload.data,
            }
        case DELETE_DUTIES_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(dutiesForm => dutiesForm._id.toString() !== action.payload.data.toString())
            }
        case ADD_DUTIES_FORM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DUTIES_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.map(dutiesForm =>
                    dutiesForm._id.toString() === action.payload.data._id.toString() ?
                        { dutiesForm, ...action.payload.data } :
                        dutiesForm
                )
            }

        //FAILS
        case GET_DUTIES_FORMS_FAIL:
        case DELETE_DUTIES_FORM_FAIL:
        case ADD_DUTIES_FORM_FAIL:
        case UPDATE_DUTIES_FORM_FAIL:
        case GET_DUTIES_FORM_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_DUTIES_FORM: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DutiesForms;