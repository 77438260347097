import React, { useState } from "react"

import PropTypes from "prop-types"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"

const QRRefresher = props => {
  const [qrRefresing, setQRRefresing] = useState(false)

  let user = JSON.parse(localStorage.getItem("authUser"))

  const refreshAPI = (_url) =>{
    axios({
        method: 'get',
        url: _url,
        headers: { 
          'Authorization': 'Bearer '+ user?.accessToken
        }
      })
      .then(function (response) {
          if (response.status >= 200 || response.status <= 299){
            toast.success(JSON.stringify(response.data.data));   
          }else{
            toast.error(JSON.stringify(response.data?.message || response?.message));   
          }  
          setQRRefresing(false);
      })
      .catch(function (error) { 
        toast.error(JSON.stringify(error?.message || error));    
        setQRRefresing(false);
      });
  }

  const refreshHandler = async () =>{
    setQRRefresing(true);
    console.log(user)
    if(isEmpty(user)){
        toast.error(props.t("userNotFound")); 
        setQRRefresing(false);
    }else if(isEmpty(user?.branchId)){
        if(isEmpty(user?.accessToken)){
            toast.error(props.t("userInfoFailed"));   
            setQRRefresing(false);
        }else{
            //Admin or Developer
            
            toast.warn(props.t("The task has been started and is in progress."), {autoClose:5000});
            refreshAPI(`${process.env.REACT_APP_BASE_API}api/qrcode/refresh-qr-all`)
        }
    }else if(!isEmpty(user.branchId)){  
        //User for Branch
        toast.warn(props.t("The task has been started and is in progress."), {autoClose:5000});
        refreshAPI(`${process.env.REACT_APP_BASE_API}api/qrcode/refresh-qr-for-branch/${user.branchId}`) 
    }else{
        toast.error(props.t("Unexpected Error Occurred"));   
        setQRRefresing(false);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer autoClose={3000} />
      <button className="btn btn-sm btn-warning d-flex justify-content-center"
        disabled={qrRefresing}
        onClick={()=>{
            refreshHandler()
        }}
      >
        {qrRefresing ? (
          <div className="spinner-border spinner-border-sm me-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : null}
        {props.t("qrCodeRefresh")}
      </button>
    </React.Fragment>
  )
}

QRRefresher.propTypes = {
  props: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(QRRefresher))
