import {
  GET_QR_ANONYMUS,
  GET_QR_ANONYMUS_SUCCESS,
  GET_QR_ANONYMUS_FAIL, 
  RESET_QR_ANONYMUS
} from "./actionTypes"

export const resetQRAnonymus = () => ({
  type: RESET_QR_ANONYMUS,
})

export const getQRAnonymus = qrcode => ({
  type: GET_QR_ANONYMUS,
  payload: qrcode,
})

export const getQRAnonymusSuccess = qrCodes => ({
  type: GET_QR_ANONYMUS_SUCCESS,
  payload: qrCodes,
})

export const getQRAnonymusFail = error => ({
  type: GET_QR_ANONYMUS_FAIL,
  payload: error,
})
 