/** Get DEV_REQUEST_REASON */
export const GET_DEV_REQUEST_REASONS = "GET_DEV_REQUEST_REASONS";
export const GET_DEV_REQUEST_REASONS_SUCCESS = "GET_DEV_REQUEST_REASONS_SUCCESS";
export const GET_DEV_REQUEST_REASONS_FAIL = "GET_DEV_REQUEST_REASONS_FAIL";

export const GET_DEV_REQUEST_REASON_DETAIL = "GET_DEV_REQUEST_REASON_DETAIL";
export const GET_DEV_REQUEST_REASON_DETAIL_SUCCESS = "GET_DEV_REQUEST_REASON_DETAIL_SUCCESS";
export const GET_DEV_REQUEST_REASON_DETAIL_FAIL = "GET_DEV_REQUEST_REASON_DETAIL_FAIL";


export const ADD_DEV_REQUEST_REASON = "ADD_DEV_REQUEST_REASON";
export const ADD_DEV_REQUEST_REASON_SUCCESS = "ADD_DEV_REQUEST_REASON_SUCCESS";
export const ADD_DEV_REQUEST_REASON_FAIL = "ADD_DEV_REQUEST_REASON_FAIL";

export const UPDATE_DEV_REQUEST_REASON = "UPDATE_DEV_REQUEST_REASON";
export const UPDATE_DEV_REQUEST_REASON_SUCCESS = "UPDATE_DEV_REQUEST_REASON_SUCCESS";
export const UPDATE_DEV_REQUEST_REASON_FAIL = "UPDATE_DEV_REQUEST_REASON_FAIL";

export const DELETE_DEV_REQUEST_REASON = "DELETE_DEV_REQUEST_REASON";
export const DELETE_DEV_REQUEST_REASON_SUCCESS = "DELETE_DEV_REQUEST_REASON_SUCCESS";
export const DELETE_DEV_REQUEST_REASON_FAIL = "DELETE_DEV_REQUEST_REASON_FAIL"; 



export const RESET_DEV_REQUEST_REASON= "RESET_DEV_REQUEST_REASON";