import {
    GET_DEV_PROGRAMS,
    GET_DEV_PROGRAMS_SUCCESS,
    GET_DEV_PROGRAMS_FAIL,
    GET_DEV_PROGRAM_DETAIL,
    GET_DEV_PROGRAM_DETAIL_SUCCESS,
    GET_DEV_PROGRAM_DETAIL_FAIL,
    ADD_DEV_PROGRAM,
    ADD_DEV_PROGRAM_SUCCESS,
    ADD_DEV_PROGRAM_FAIL,
    UPDATE_DEV_PROGRAM,
    UPDATE_DEV_PROGRAM_SUCCESS,
    UPDATE_DEV_PROGRAM_FAIL,
    DELETE_DEV_PROGRAM,
    DELETE_DEV_PROGRAM_SUCCESS,
    DELETE_DEV_PROGRAM_FAIL,
    RESET_DEV_PROGRAM,
   
} from "./actionTypes";


export const resetDevProgram = () => ({
    type: RESET_DEV_PROGRAM,
});

export const getDevPrograms = () => ({
    type: GET_DEV_PROGRAMS,
});

export const getDevProgramsSuccess = (devProgram) => ({
    type: GET_DEV_PROGRAMS_SUCCESS,
    payload: devProgram,
});


export const getDevProgramsFail = (error) => ({
    type: GET_DEV_PROGRAMS_FAIL,
    payload: error,
});

export const deleteDevProgram = (id) => ({
    type:  DELETE_DEV_PROGRAM,
    payload: id
});

export const deleteDevProgramSuccess = (data) => ({
    type: DELETE_DEV_PROGRAM_SUCCESS,
    payload: data
});

export const deleteDevProgramFail = (error) => ({
    type: DELETE_DEV_PROGRAM_FAIL,
    payload: error,
});


export const addDevProgram = (devProgram) => ({
    type: ADD_DEV_PROGRAM,
    payload: devProgram
});

export const addDevProgramSuccess = (data) => ({
    type: ADD_DEV_PROGRAM_SUCCESS,
    payload: data
});

export const addDevProgramFail = (error) => ({
    type: ADD_DEV_PROGRAM_FAIL,
    payload: error,
});


export const updateDevProgram = (devProgram) => ({
    type: UPDATE_DEV_PROGRAM,
    payload: devProgram
});

export const updateDevProgramSuccess = (data) => ({
    type: UPDATE_DEV_PROGRAM_SUCCESS,
    payload: data
});

export const updateDevProgramFail = (error) => ({
    type: UPDATE_DEV_PROGRAM_FAIL,
    payload: error,
});