import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import "./../rightbar.scss"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { authorize, Role } from "./../../helpers/authorize"
// users
import user1 from "../../../assets/images/User-Avatar-Profile-PNG-Free-File-Download.png"

const ProfileMenu = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [info_dropup111, setInfo_dropup111] = useState(false)
  const [username, setusername] = useState("Admin")
  const [info_dropup112, setInfo_dropup112] = useState(false)
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser")); 
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") { 
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) { 
        setusername(obj.userName)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
            <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
         
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
       
        
         <DropdownItem tag="a" href="/profile">
            {" "} 
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem> 
          {authorize([Role.Admin,Role.Avm,Role.YetkiliIk]) ? (
                    <DropdownItem tag="a" href="/companyInfo">
                    {" "} 
                    <i className="bx bx-chevron-up-square font-size-16 align-middle me-1"/>
                    {props.t("Company Info")}{" "}
                  </DropdownItem> 
                  ) : null}
          {authorize([Role.Admin,Role.YetkiliIk]) ? (
            <div className="mt-1" >
              <Dropdown style={{ width: "100%", height: "32px"}}
                isOpen={info_dropup111}
                direction="left"
                className="btn-group dropstart"
                toggle={() => setInfo_dropup111(!info_dropup111)}
              >
                <DropdownToggle style={{ backgroundColor: "white", border: "0px solid"   }}>
                <p  className="setttingsButton"> <i className="mdi mdi-chevron-left" />{props.t("Ayarlar")}</p>
                </DropdownToggle>
                <DropdownMenu data-popper-placement="left-start" className="dropdown-menu-items" >
                <DropdownItem > <Link to="/duty" className="dropdown-item">
                    {props.t("Görev Tanımlama")}
                  </Link>
                  </DropdownItem>
                <DropdownItem > <Link to="/dutiesForm" className="dropdown-item">
                    {props.t("Görev Formu Tanımlama")}
                  </Link>
                  </DropdownItem>
                 
                  <DropdownItem> <Link to="/softSkill" className="dropdown-item">
                  {props.t("Soft Skill Nitelik Tanımlama")}
                  </Link></DropdownItem>
                  
                 
                  
                  <DropdownItem> <Link to="/backUrl" className="dropdown-item">
                  {props.t("Geri Dönüş Sayfası Tanımlama")}
                  </Link></DropdownItem>
                  
                  <DropdownItem> <Link to="/dutyForm" className="dropdown-item">
                  {props.t("Modül İçeriği Tanımlama")}
                  </Link></DropdownItem>
                  <DropdownItem> <Link to="/requestReason" className="dropdown-item">
                  {props.t("Personel Talebi Tanımlama")}
                  </Link></DropdownItem>
                  
                  {/* <DropdownItem> <Link to="/workType" className="dropdown-item">
                  {props.t("Çalışma Yöntemi")}
                  </Link></DropdownItem>  */}
                  
                  

                </DropdownMenu>
              </Dropdown> 

            </div>
          ) : null}
         
          <div className="dropdown-divider"/>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
