import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ORIENTATON_SENTS, DELETE_ORIENTATON_SENT, ADD_ORIENTATON_SENT,
    SEND_ORIENTATON_MAIL, UPDATE_ORIENTATON_SENT,GET_ORIENTATON_SENTS_MY_ORIENTATON_SENTS} from "./actionTypes";
import {
    getOrientationSentsSuccess, getOrientationSentsFail,
    deleteOrientationSentSuccess, deleteOrientationSentFail,
    updateOrientationSentSuccess, updateOrientationSentFail,
    addOrientationSentSuccess, addOrientationSentFail,
    sendOrientationMailSuccess,sendOrientationMailFail
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getOrientationSents, //API CONNECTION
    delOrientationSent,
    addOrientationSent,
    updateOrientationSent,sendOrientationMail
    
}
    from "../../helpers/fakebackend_helper"; 

    function* postOrientationMail({payload:email}){
        try{
           
            const response = yield call(sendOrientationMail, email);
            yield put(sendOrientationMailSuccess(response));
        } catch (error) {
            yield put(sendOrientationMailFail(error)); 
        }
      } 
function* fetchOrientationSents({payload:appSentId}) {
    try {
        const response = yield call(getOrientationSents,appSentId)
        yield put(getOrientationSentsSuccess(response));
    } catch (error) {
        yield put(getOrientationSentsFail(error));
    }
}

function* onDeleteOrientationSents({payload:id}){
    try{
        const response = yield call(delOrientationSent, id)
        yield put(deleteOrientationSentSuccess(response));
    } catch (error) {
        yield put(deleteOrientationSentFail(error));
    }
}

function* onAddOrientationSent({payload:orientationSent}){
    try{
        const response = yield call(addOrientationSent, orientationSent)
        yield put(addOrientationSentSuccess(response));
    } catch (error) {
        yield put(addOrientationSentFail(error));
    }
}

function* onUpdateOrientationSent({payload:orientationSent}){
    try{
        const response = yield call(updateOrientationSent, orientationSent)
        yield put(updateOrientationSentSuccess(response));
    } catch (error) {
        yield put(updateOrientationSentFail(error));
    }
}

export function* orientationSentsSaga() {
    yield takeEvery(GET_ORIENTATON_SENTS, fetchOrientationSents);
    yield takeEvery(DELETE_ORIENTATON_SENT, onDeleteOrientationSents);
    yield takeEvery(ADD_ORIENTATON_SENT, onAddOrientationSent);
    yield takeEvery(UPDATE_ORIENTATON_SENT, onUpdateOrientationSent);
    yield takeEvery(SEND_ORIENTATON_MAIL, postOrientationMail);
    
    
}

export default orientationSentsSaga;
