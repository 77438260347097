/** Get SURVEY */
export const GET_SURVEYS = "GET_SURVEYS";
export const GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS";
export const GET_SURVEYS_FAIL = "GET_SURVEYS_FAIL";

export const GET_SURVEY_DETAIL = "GET_SURVEY_DETAIL";
export const GET_SURVEY_DETAIL_SUCCESS = "GET_SURVEY_DETAIL_SUCCESS";
export const GET_SURVEY_DETAIL_FAIL = "GET_SURVEY_DETAIL_FAIL";


export const ADD_SURVEY = "ADD_SURVEY";
export const ADD_SURVEY_SUCCESS = "ADD_SURVEY_SUCCESS";
export const ADD_SURVEY_FAIL = "ADD_SURVEY_FAIL";

export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAIL = "UPDATE_SURVEY_FAIL";

export const DELETE_SURVEY = "DELETE_SURVEY";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const DELETE_SURVEY_FAIL = "DELETE_SURVEY_FAIL"; 



export const RESET_SURVEY= "RESET_SURVEY";