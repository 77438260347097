/** Get SURVEY_FORM */
export const GET_SURVEY_FORMS = "GET_SURVEY_FORMS";
export const GET_SURVEY_FORMS_SUCCESS = "GET_SURVEY_FORMS_SUCCESS";
export const GET_SURVEY_FORMS_FAIL = "GET_SURVEY_FORMS_FAIL";

export const GET_SURVEY_FORM_DETAIL = "GET_SURVEY_FORM_DETAIL";
export const GET_SURVEY_FORM_DETAIL_SUCCESS = "GET_SURVEY_FORM_DETAIL_SUCCESS";
export const GET_SURVEY_FORM_DETAIL_FAIL = "GET_SURVEY_FORM_DETAIL_FAIL";


export const ADD_SURVEY_FORM = "ADD_SURVEY_FORM";
export const ADD_SURVEY_FORM_SUCCESS = "ADD_SURVEY_FORM_SUCCESS";
export const ADD_SURVEY_FORM_FAIL = "ADD_SURVEY_FORM_FAIL";

export const UPDATE_SURVEY_FORM = "UPDATE_SURVEY_FORM";
export const UPDATE_SURVEY_FORM_SUCCESS = "UPDATE_SURVEY_FORM_SUCCESS";
export const UPDATE_SURVEY_FORM_FAIL = "UPDATE_SURVEY_FORM_FAIL";

export const DELETE_SURVEY_FORM = "DELETE_SURVEY_FORM";
export const DELETE_SURVEY_FORM_SUCCESS = "DELETE_SURVEY_FORM_SUCCESS";
export const DELETE_SURVEY_FORM_FAIL = "DELETE_SURVEY_FORM_FAIL"; 



export const RESET_SURVEY_FORM= "RESET_SURVEY_FORM"; 