import {
    GET_APP_FORM_OUTSIDES,
    GET_APP_FORM_OUTSIDES_SUCCESS,
    GET_APP_FORM_OUTSIDES_FAIL,
    GET_APP_FORM_OUTSIDE_DETAIL,
    GET_APP_FORM_OUTSIDE_DETAIL_SUCCESS,
    GET_APP_FORM_OUTSIDE_DETAIL_FAIL,
    ADD_APP_FORM_OUTSIDE,
    ADD_APP_FORM_OUTSIDE_SUCCESS, 
    ADD_APP_FORM_OUTSIDE_FAIL,
    UPDATE_APP_FORM_OUTSIDE,
    UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    UPDATE_APP_FORM_OUTSIDE_FAIL,
    STATUS_UPDATE_APP_FORM_OUTSIDE,
    STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS, 
    STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL,
    REJECT_APP_FORM_OUTSIDE,
    REJECT_APP_FORM_OUTSIDE_SUCCESS,
    REJECT_APP_FORM_OUTSIDE_FAIL,
    ACCEPT_APP_FORM_OUTSIDE,
    ACCEPT_APP_FORM_OUTSIDE_SUCCESS,
    ACCEPT_APP_FORM_OUTSIDE_FAIL,
    DESC_UPDATE_APP_FORM_OUTSIDE, 
    DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    DESC_UPDATE_APP_FORM_OUTSIDE_FAIL,
    DELETE_APP_FORM_OUTSIDE,
    DELETE_APP_FORM_OUTSIDE_SUCCESS,
    DELETE_APP_FORM_OUTSIDE_FAIL,
    RESET_APP_FORM_OUTSIDE,
    GET_MY_FORMS,
    GET_MY_FORMS_SUCCESS,
    GET_MY_FORMS_FAIL,
    GET_MY_FORMS_STATUS_FALSE,
    GET_MY_FORMS_STATUS_FALSE_SUCCESS,
    GET_MY_FORMS_STATUS_FALSE_FAIL,
    GET_MY_FORMS_STATUS_TRUE,
    GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    GET_MY_FORMS_STATUS_TRUE_FAIL,
    UPDATE_MY_FORMS,
    UPDATE_MY_FORMS_SUCCESS, 
    UPDATE_MY_FORMS_FAIL,
    UPDATE_MY_FORMS_STATUS_FALSE,
    UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_TRUE,
    UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_TRUE_FAIL,
    DELETE_MY_FORMS,
    DELETE_MY_FORMS_SUCCESS, 
    DELETE_MY_FORMS_FAIL,
    DELETE_MY_FORM_STATUS_TRUE,
    DELETE_MY_FORM_STATUS_TRUE_SUCCESS,
    DELETE_MY_FORM_STATUS_TRUE_FAIL,
    DELETE_MY_FORM_STATUS_FALSE,
    DELETE_MY_FORM_STATUS_FALSE_SUCCESS,
    DELETE_MY_FORM_STATUS_FALSE_FAIL,
    GET_FORMS_DOWN,
    GET_FORMS_DOWN_SUCCESS, 
    GET_FORMS_DOWN_FAIL 
} from "./actionTypes"; 

export const resetAppFormOutside = () => ({
    type: RESET_APP_FORM_OUTSIDE,
});
export const getFormsDown = (branchId) => ({
    type: GET_FORMS_DOWN,  
    payload:branchId 
}); 
export const getFormsDownSuccess = (data) => ({
    type: GET_FORMS_DOWN_SUCCESS,
    payload: data
}); 
export const getFormsDownFail = (error) => ({
    type: GET_FORMS_DOWN_FAIL, 
    payload: error,
});
//MyForms
export const getMyForms = (id) => ({
    type: GET_MY_FORMS,  
    payload:id
}); 
export const getMyFormsSuccess = (data) => ({
    type: GET_MY_FORMS_SUCCESS,
    payload: data
}); 
export const getMyFormsFail = (error) => ({
    type: GET_MY_FORMS_FAIL, 
    payload: error,
});
export const getMyFormsStatusFalse = (id) => ({
    type: GET_MY_FORMS_STATUS_FALSE,  
    payload:id
}); 
export const getMyFormsStatusFalseSuccess = (data) => ({
    type: GET_MY_FORMS_STATUS_FALSE_SUCCESS, 
    payload: data
});
export const getMyFormsStatusFalseFail = (error) => ({
    type: GET_MY_FORMS_STATUS_FALSE_FAIL, 
    payload: error, 
});
export const getMyFormsStatusTrue = (id) => ({
    type: GET_MY_FORMS_STATUS_TRUE,  
    payload:id
}); 
export const getMyFormsStatusTrueSuccess = (data) => ({
    type: GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    payload: data
});
export const getMyFormsStatusTrueFail = (error) => ({
    type: GET_MY_FORMS_STATUS_TRUE_FAIL, 
    payload: error, 
});
export const updateMyForm = (form) => ({
    type: UPDATE_MY_FORMS,
    payload:form
});
export const updateMyFormSuccess = (data) => ({
    type: UPDATE_MY_FORMS_SUCCESS,
    payload: data
});
export const updateMyFormFail = (error) => ({
    type: UPDATE_MY_FORMS_FAIL,
    payload: error,
});
export const updateMyFormStatusFalse = (form) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE,
    payload:form
});
export const updateMyFormStatusFalseSuccess = (data) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    payload: data
});
export const updateMyFormStatusFalseFail = (error) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE_FAIL, 
    payload: error,
});
export const updateMyFormStatusTrue = (form) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE,
    payload:form
});
export const updateMyFormStatusTrueSuccess = (data) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    payload: data
});
export const updateMyFormStatusTrueFail = (error) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE_FAIL, 
    payload: error,
});
export const deleteMyForm = (id) => ({ 
    type: DELETE_MY_FORMS,
    payload:id
});
export const deleteMyFormSuccess = (data) => ({ 
    type: DELETE_MY_FORMS_SUCCESS, 
    payload: data
});
export const deleteMyFormFail = (error) => ({
    type: DELETE_MY_FORMS_FAIL,
    payload: error,
});
export const deleteMyFormStatusTrue = (id) => ({ 
    type: DELETE_MY_FORM_STATUS_TRUE,
    payload:id
});
export const deleteMyFormStatusTrueSuccess = (data) => ({ 
    type: DELETE_MY_FORM_STATUS_TRUE_SUCCESS, 
    payload: data
});
export const deleteMyFormStatusTrueFail = (error) => ({
    type: DELETE_MY_FORM_STATUS_TRUE_FAIL,
    payload: error,
});
export const deleteMyFormStatusFalse = (id) => ({ 
    type: DELETE_MY_FORM_STATUS_FALSE,
    payload:id
});
export const deleteMyFormStatusFalseSuccess = (data) => ({ 
    type: DELETE_MY_FORM_STATUS_FALSE_SUCCESS, 
    payload: data
});
export const deleteMyFormStatusFalseFail = (error) => ({
    type: DELETE_MY_FORM_STATUS_FALSE_FAIL,
    payload: error,
});
//
export const getAppFormOutsides = () => ({
    type: GET_APP_FORM_OUTSIDES,
});

export const getAppFormOutsidesSuccess = (appFormOutsides) => ({
    type: GET_APP_FORM_OUTSIDES_SUCCESS,
    payload: appFormOutsides, 
});


export const getAppFormOutsidesFail = (error) => ({
    type: GET_APP_FORM_OUTSIDES_FAIL,
    payload: error,
});

export const deleteAppFormOutside = (id) => ({
    type:  DELETE_APP_FORM_OUTSIDE,
    payload: id
});

export const deleteAppFormOutsideSuccess = (data) => ({
    type: DELETE_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const deleteAppFormOutsideFail = (error) => ({
    type: DELETE_APP_FORM_OUTSIDE_FAIL,
    payload: error,
});


export const addAppFormOutside = (appFormOutside,files) => ({
    type: ADD_APP_FORM_OUTSIDE,
    payload: appFormOutside,
    files: files
});

export const addAppFormOutsideSuccess = (data) => ({
    type: ADD_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const addAppFormOutsideFail = (error) => ({
    type: ADD_APP_FORM_OUTSIDE_FAIL,
    payload: error,
});


export const updateAppFormOutside = (appFormOutside,files) => ({
    type: UPDATE_APP_FORM_OUTSIDE,
    payload: appFormOutside,
    files: files  
});

export const updateAppFormOutsideSuccess = (data) => ({
    type: UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const updateAppFormOutsideFail = (error) => ({
    type: UPDATE_APP_FORM_OUTSIDE_FAIL,
    payload: error,
});
export const statusUpdateAppFormOutside = (appFormOutside) => ({
    type: STATUS_UPDATE_APP_FORM_OUTSIDE,
    payload: appFormOutside
});

export const statusUpdateAppFormOutsideSuccess = (data) => ({
    type: STATUS_UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const statusUpdateAppFormOutsideFail = (error) => ({
    type: STATUS_UPDATE_APP_FORM_OUTSIDE_FAIL,
    payload: error, 
});
export const rejectAppFormOutside = (appFormOutside) => ({
    type: REJECT_APP_FORM_OUTSIDE,
    payload: appFormOutside
});

export const rejectAppFormOutsideSuccess = (data) => ({
    type: REJECT_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const rejectAppFormOutsideFail = (error) => ({
    type: REJECT_APP_FORM_OUTSIDE_FAIL,
    payload: error, 
});
export const acceptAppFormOutside = (appFormOutside) => ({
    type: ACCEPT_APP_FORM_OUTSIDE,
    payload: appFormOutside
});

export const acceptAppFormOutsideSuccess = (data) => ({
    type: ACCEPT_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
}); 

export const acceptAppFormOutsideFail = (error) => ({
    type: ACCEPT_APP_FORM_OUTSIDE_FAIL,
    payload: error, 
});
export const descUpdateAppFormOutside = (appFormOutside) => ({
    type: DESC_UPDATE_APP_FORM_OUTSIDE,
    payload: appFormOutside
});

export const descUpdateAppFormOutsideSuccess = (data) => ({
    type:  DESC_UPDATE_APP_FORM_OUTSIDE_SUCCESS,
    payload: data
});

export const descUpdateAppFormOutsideFail = (error) => ({
    type:  DESC_UPDATE_APP_FORM_OUTSIDE_FAIL,
    payload: error, 
});