import {
    GET_SURVEY_SENTS,
    GET_SURVEY_SENTS_SUCCESS,
    GET_SURVEY_SENTS_FAIL,
    GET_SURVEY_SENT_DETAIL,
    GET_SURVEY_SENT_DETAIL_SUCCESS,
    GET_SURVEY_SENT_DETAIL_FAIL,
    ADD_SURVEY_SENT,
    ADD_SURVEY_SENT_SUCCESS,
    ADD_SURVEY_SENT_FAIL, 
    UPDATE_SURVEY_SENT,
    UPDATE_SURVEY_SENT_SUCCESS,
    UPDATE_SURVEY_SENT_FAIL,
    DELETE_SURVEY_SENT,
    DELETE_SURVEY_SENT_SUCCESS,
    DELETE_SURVEY_SENT_FAIL,
    RESET_SURVEY_SENT,
    SEND_SURVEY_MAIL_SUCCESS,
    SEND_SURVEY_MAIL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    success:null,
    mail:[]
};

const SurveySents = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SEND_SURVEY_MAIL_SUCCESS:
            return {
               
                ...state,
                mail:[action.payload.data], 
                success:true  
            }  
        case GET_SURVEY_SENTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SURVEY_SENT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(surveySent => surveySent._id.toString() !== action.payload.data.toString())
            }
        case ADD_SURVEY_SENT_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
               
            }
        case UPDATE_SURVEY_SENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(surveySent =>
                    surveySent._id.toString() === action.payload.data._id.toString() ?
                        { surveySent, ...action.payload.data } :
                        surveySent
                )
            }

        //FAILS
        case GET_SURVEY_SENTS_FAIL:
        case DELETE_SURVEY_SENT_FAIL:
        case ADD_SURVEY_SENT_FAIL:
        case UPDATE_SURVEY_SENT_FAIL:
        case SEND_SURVEY_MAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_SURVEY_SENT: return {
            ...state,
            error: null,
            success:null
        }
        default:
            return state;
    }
}


export default SurveySents;