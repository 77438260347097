import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PLAN_OUTSIDES, DELETE_PLAN_OUTSIDE, ADD_PLAN_OUTSIDE, UPDATE_PLAN_OUTSIDE,GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES
    ,UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES,GET_PLAN_OUTSIDE_DETAIL,
    SEND_MAIL,GET_USER_BY_MAIL,GET_PLAN_OUTSIDES_BY_BRANCH,GET_BRANCH_PLAN_OUTSIDE,GET_PLAN_OUTSIDES_DOWN} from "./actionTypes";
import {
    getPlanOutsidesSuccess, getPlanOutsidesFail,
    deletePlanOutsideSuccess, deletePlanOutsideFail,
    updatePlanOutsideSuccess, updatePlanOutsideFail,
    addPlanOutsideSuccess, addPlanOutsideFail,
    getPlanOutsides_MyPlanOutsidesSuccess,getPlanOutsides_MyPlanOutsidesFail,
    addPlanOutsides_MyPlanOutsidesSuccess,addPlanOutsides_MyPlanOutsidesFail,
    deletePlanOutsides_MyPlanOutsidesSuccess,deletePlanOutsides_MyPlanOutsidesFail,
    updatePlanOutsides_MyPlanOutsidesSuccess,updatePlanOutsides_MyPlanOutsidesFail,
    getPlanOutsidesDetailSuccess,getPlanOutsidesDetailFail,
    getUserByMailSuccess,getUserByMailFail,
    getPlanOutsides_ByBranchSuccess,getPlanOutsides_ByBranchFail,
    getBranch_byPlanOutsideSuccess,getBranch_byPlanOutsideFail,
    getPlanOutsidesDownSuccess,getPlanOutsidesDownFail,
    sendMailSuccess,sendMailFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPlanOutsides, //API CONNECTION
    delPlanOutside,
    addPlanOutside,
    updatePlanOutside,
    getPlanOutsides_MyPlanOutsides,
    getPlanOutsideById,
    getCompanyByEmail,
    getPlanOutsides_ByBranch,
    getBranch_ByPlanOutside,getPlanOutsidesDown,
    sendPlanMail
}
    from "../../helpers/fakebackend_helper"; 
    function* postMail({payload:email}){
        try{
           
            const response = yield call(sendPlanMail, email);
            yield put(sendMailSuccess(response));
        } catch (error) {
            yield put(sendMailFail(error)); 
        }
      } 
    function* fetchPlanOutsideDown({ payload: branchId}) {
        try {
            const response = yield call(getPlanOutsidesDown,branchId)
            yield put(getPlanOutsidesDownSuccess(response));
        } catch (error) {
            yield put(getPlanOutsidesDownFail(error));
        }
    }
function* fetchPlanOutsidesDetail({ payload: id}) {
        try {
            const response = yield call(getPlanOutsideById,id)
            yield put(getPlanOutsidesDetailSuccess(response));
        } catch (error) {
            yield put(getPlanOutsidesDetailFail(error));
        }
    }
    function* getUserByMail({ payload: id}) {
        try {
            const response = yield call(getCompanyByEmail,id)
            yield put(getUserByMailSuccess(response));
        } catch (error) {
            yield put(getUserByMailFail(error));
        }
    }
    function* fetchPlanOutsides_byBranch({ payload: id}) {
        try {
            const response = yield call(getPlanOutsides_ByBranch,id)
            yield put(getPlanOutsides_ByBranchSuccess(response));
        } catch (error) {
            yield put(getPlanOutsides_ByBranchFail(error));
        }
    }
    function* fetchBranch_byPlanOutside({ payload: id}) {
        try {
            const response = yield call(getBranch_ByPlanOutside,id)
            yield put(getBranch_byPlanOutsideSuccess(response));
        } catch (error) {
            yield put(getBranch_byPlanOutsideFail(error));
        }
    }
function* fetchPlanOutsides_myPlanOutsides({ payload: id}) {
        try {
            const response = yield call(getPlanOutsides_MyPlanOutsides,id)
            yield put(getPlanOutsides_MyPlanOutsidesSuccess(response));
        } catch (error) {
            yield put(getPlanOutsides_MyPlanOutsidesFail(error));
        }
    }
function* onAddPlanOutsides_myPlanOutsides({ payload: planOutside }) {
        try {
          const response = yield call(addPlanOutside, planOutside)
          yield put(addPlanOutsides_MyPlanOutsidesSuccess(response))
        } catch (error) {
          yield put(addPlanOutsides_MyPlanOutsidesFail(error))
        }
    }
function* onDeletePlanOutsides_myPlanOutsides({ payload: id }) {
        try {
          const response = yield call(delPlanOutside, id)
          yield put(deletePlanOutsides_MyPlanOutsidesSuccess(response))
        } catch (error) {
          yield put(deletePlanOutsides_MyPlanOutsidesFail(error))
        }
      }
function* onUpdatePlanOutsides_myPlanOutsides({ payload: planOutside }) {
        try {
          const response = yield call(updatePlanOutside, planOutside)
          yield put(updatePlanOutsides_MyPlanOutsidesSuccess(response))
        } catch (error) {
          yield put(updatePlanOutsides_MyPlanOutsidesFail(error))
        }
      }
function* fetchPlanOutsides() {
    try {
        const response = yield call(getPlanOutsides)
        yield put(getPlanOutsidesSuccess(response));
    } catch (error) {
        yield put(getPlanOutsidesFail(error));
    }
}

function* onDeletePlanOutsides({payload:id}){
    try{
        const response = yield call(delPlanOutside, id)
        yield put(deletePlanOutsideSuccess(response));
    } catch (error) {
        yield put(deletePlanOutsideFail(error));
    }
}

function* onAddPlanOutside({payload:planOutside}){
    try{
        const response = yield call(addPlanOutside, planOutside)
        yield put(addPlanOutsideSuccess(response));
    } catch (error) {
        yield put(addPlanOutsideFail(error));
    }
}

function* onUpdatePlanOutside({payload:planOutside}){
    try{
        const response = yield call(updatePlanOutside, planOutside)
        yield put(updatePlanOutsideSuccess(response));
    } catch (error) {
        yield put(updatePlanOutsideFail(error));
    }
}

export function* planOutsidesSaga() {
    yield takeEvery(GET_PLAN_OUTSIDES, fetchPlanOutsides);
    yield takeEvery(DELETE_PLAN_OUTSIDE, onDeletePlanOutsides);
    yield takeEvery(ADD_PLAN_OUTSIDE, onAddPlanOutside);
    yield takeEvery(UPDATE_PLAN_OUTSIDE, onUpdatePlanOutside);
    yield takeEvery(GET_PLAN_OUTSIDES_MY_PLAN_OUTSIDES, fetchPlanOutsides_myPlanOutsides);
    yield takeEvery(ADD_PLAN_OUTSIDES_MY_PLAN_OUTSIDES, onAddPlanOutsides_myPlanOutsides);
    yield takeEvery(DELETE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES, onDeletePlanOutsides_myPlanOutsides);
    yield takeEvery(UPDATE_PLAN_OUTSIDES_MY_PLAN_OUTSIDES, onUpdatePlanOutsides_myPlanOutsides);
    yield takeEvery(GET_PLAN_OUTSIDE_DETAIL, fetchPlanOutsidesDetail);
    yield takeEvery(GET_USER_BY_MAIL, getUserByMail);
    yield takeEvery(GET_PLAN_OUTSIDES_BY_BRANCH, fetchPlanOutsides_byBranch);
    yield takeEvery(GET_BRANCH_PLAN_OUTSIDE, fetchBranch_byPlanOutside);
    yield takeEvery(GET_PLAN_OUTSIDES_DOWN, fetchPlanOutsideDown);
    yield takeEvery(SEND_MAIL, postMail);
    
    

    
}

export default planOutsidesSaga;
