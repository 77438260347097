import {
    GET_LABELS,
    GET_LABELS_SUCCESS,
    GET_LABELS_FAIL,
    GET_LABEL_DETAIL,
    GET_LABEL_DETAIL_SUCCESS,
    GET_LABEL_DETAIL_FAIL,
    ADD_LABEL,
    ADD_LABEL_SUCCESS,
    ADD_LABEL_FAIL,
    UPDATE_LABEL,
    UPDATE_LABEL_SUCCESS,
    UPDATE_LABEL_FAIL,
    DELETE_LABEL,
    DELETE_LABEL_SUCCESS,
    DELETE_LABEL_FAIL,
    RESET_LABEL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const Labels = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_LABELS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_LABEL_SUCCESS:
            return {
                ...state,
                data: state.data.filter(label => label._id.toString() !== action.payload.data.toString())
            }
        case ADD_LABEL_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_LABEL_SUCCESS:
            return {
                ...state,
                data: state.data.map(label =>
                    label._id.toString() === action.payload.data._id.toString() ?
                        { label, ...action.payload.data } :
                        label
                )
            }

        //FAILS
        case GET_LABELS_FAIL:
        case DELETE_LABEL_FAIL:
        case ADD_LABEL_FAIL:
        case UPDATE_LABEL_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_LABEL: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Labels;