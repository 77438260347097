import {
    GET_APP_FORMS,
    GET_APP_FORMS_SUCCESS,
    GET_APP_FORMS_FAIL,
    GET_APP_FORM_DETAIL,
    GET_APP_FORM_DETAIL_SUCCESS,
    GET_APP_FORM_DETAIL_FAIL,
    ADD_APP_FORM,
    ADD_APP_FORM_SUCCESS,
    ADD_APP_FORM_FAIL,
    UPDATE_APP_FORM,
    UPDATE_APP_FORM_SUCCESS,
    UPDATE_APP_FORM_FAIL,
    STATUS_UPDATE_APP_FORM_SUCCESS,
    STATUS_UPDATE_APP_FORM_FAIL,
    DESC_UPDATE_APP_FORM_SUCCESS,
    DESC_UPDATE_APP_FORM_FAIL,
    DELETE_APP_FORM,
    DELETE_APP_FORM_SUCCESS,
    DELETE_APP_FORM_FAIL,
    RESET_APP_FORM,
    GET_MY_FORMS_SUCCESS,
    GET_MY_FORMS_FAIL,
    UPDATE_MY_FORMS_SUCCESS,
    UPDATE_MY_FORMS_FAIL,
    DELETE_MY_FORMS_SUCCESS, 
    DELETE_MY_FORMS_FAIL,
    DELETE_MY_FORM_STATUS_TRUE_SUCCESS,
    DELETE_MY_FORM_STATUS_TRUE_FAIL,
    DELETE_MY_FORM_STATUS_FALSE_SUCCESS,
    DELETE_MY_FORM_STATUS_FALSE_FAIL,
    GET_MY_FORMS_STATUS_FALSE_SUCCESS,
    GET_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_TRUE_FAIL,
    GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    GET_MY_FORMS_STATUS_TRUE_FAIL,
    REJECT_APP_FORM_SUCCESS,
    REJECT_APP_FORM_FAIL,
    ACCEPT_APP_FORM_SUCCESS,
    ACCEPT_APP_FORM_FAIL,
    GET_FORMS_DOWN_SUCCESS,
    GET_FORMS_DOWN_FAIL,
    FIRED_APP_FORM_SUCCESS,
    FIRED_APP_FORM_FAIL,
} from "./actionTypes";

const INIT_STATE = { 
    data: [],
    error: null, 
    success:null,
    descSuccess:false,
    myForms :[],
    myFormsStatusFalse :[],
    myFormsStatusTrue :[] ,
    formsDown:[],
    fileMove:false

};

const AppForms = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FORMS_DOWN_SUCCESS:
            return { 
                ...state,
                data: action.payload.data
            }
        case GET_MY_FORMS_SUCCESS:
            return { 
                ...state,
                myForms: action.payload.data
            }
        case GET_MY_FORMS_STATUS_FALSE_SUCCESS:
                return { 
                    ...state,
                    myFormsStatusFalse: action.payload.data
                }
        case GET_MY_FORMS_STATUS_TRUE_SUCCESS:
            return { 
                ...state,
                myFormsStatusTrue: action.payload.data
            }
        case UPDATE_MY_FORMS_SUCCESS:
            return {
                 ...state,
                 myForms: state.myForms.map(form =>
                     form._id.toString() === action.payload.data._id.toString() ?
                         { form, ...action.payload.data } :
                         form
                    )
                }
         case UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS:
             return {
                  ...state,
                  myFormsStatusFalse: state.myFormsStatusFalse.map(form =>
                      form._id.toString() === action.payload.data._id.toString() ?
                          { form, ...action.payload.data } :
                          form
                     )
                 }
         case UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS:
                    return {
                         ...state,
                         myFormsStatusTrue: state.myFormsStatusTrue.map(form =>
                             form._id.toString() === action.payload.data._id.toString() ?
                                 { form, ...action.payload.data } :
                                 form
                            )
                        }
        case DELETE_MY_FORMS_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myForms: state.myForms.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
            case DELETE_MY_FORM_STATUS_TRUE_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myFormsStatusTrue: state.myFormsStatusTrue.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
            case DELETE_MY_FORM_STATUS_FALSE_SUCCESS: 
           
            return { 
                
                  ...state,
                  
                  myFormsStatusFalse: state.myFormsStatusFalse.filter(
                    form => form._id.toString() !== action.payload.data.toString()
                  ),
            }
        case GET_APP_FORMS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_APP_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.filter(appForms => appForms._id.toString() !== action.payload.data.toString())
            }
        case ADD_APP_FORM_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data],
                success:true 
            }
        case UPDATE_APP_FORM_SUCCESS:
            return {
                ...state,
                data: state.data.map(appForms =>
                    appForms._id.toString() === action.payload.data._id.toString() ?
                        { appForms, ...action.payload.data } :
                        appForms
                )
            } 
        case STATUS_UPDATE_APP_FORM_SUCCESS:
                return {
                    ...state,
                    data: state.data.map(appForms =>
                        appForms._id.toString() === action.payload.data._id.toString() ?
                            { appForms, ...action.payload.data } :
                            appForms
                    )
                } 
         case REJECT_APP_FORM_SUCCESS:  
             return {
                 ...state,
                 fileMove:true,
                 data: state.data.map(appForms =>
                     appForms._id.toString() === action.payload.data._id.toString() ?
                         { appForms, ...action.payload.data } :
                         appForms
                 )
             }
             case FIRED_APP_FORM_SUCCESS:  
             return {
                 ...state,
                 
                 data: state.data.map(appForms =>
                     appForms._id.toString() === action.payload.data._id.toString() ?
                         { appForms, ...action.payload.data } :
                         appForms
                 )
             }
             case ACCEPT_APP_FORM_SUCCESS:  
             return {
                 ...state,
                 fileMove:true,
                 data: state.data.map(appForms =>
                     appForms._id.toString() === action.payload.data._id.toString() ?
                         { appForms, ...action.payload.data } :
                         appForms
                 )
             }
        case DESC_UPDATE_APP_FORM_SUCCESS: 
                return {
                  
                    ...state,
                    descSuccess:true,
                    data: state.data.map(appForms =>
                        appForms._id.toString() === action.payload.data._id.toString() ?
                            { appForms, ...action.payload.data } :
                            appForms
                    )
                }  
        //FAILS
        case GET_APP_FORMS_FAIL:
        case DELETE_APP_FORM_FAIL:
        case ADD_APP_FORM_FAIL:
        case UPDATE_APP_FORM_FAIL:
        case STATUS_UPDATE_APP_FORM_FAIL: 
        case DESC_UPDATE_APP_FORM_FAIL: 
        case GET_MY_FORMS_FAIL:
        case UPDATE_MY_FORMS_FAIL:
        case DELETE_MY_FORMS_FAIL:
        case DELETE_MY_FORM_STATUS_TRUE_FAIL:
        case DELETE_MY_FORM_STATUS_FALSE_FAIL:
        case GET_MY_FORMS_STATUS_FALSE_FAIL:
        case UPDATE_MY_FORMS_STATUS_FALSE_FAIL:
        case GET_MY_FORMS_STATUS_TRUE_FAIL:
        case UPDATE_MY_FORMS_STATUS_TRUE_FAIL:
        case REJECT_APP_FORM_FAIL:
        case GET_FORMS_DOWN_FAIL:
        case ACCEPT_APP_FORM_FAIL:
        case FIRED_APP_FORM_FAIL:
            return {
                ...state, 
                error: action.payload
            }

        case RESET_APP_FORM: return {
            ...state,
            error: null,
            success:null,
            descSuccess:false,
            fileMove:false
        }
        default:
            return state;
    }
}


export default AppForms;