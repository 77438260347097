import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
    Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
    FormGroup, Alert
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
    addProcessListPayment as OnAddProcessListPayment,

} from "store/actions"
import config from "config"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const selectBranch = (props, cb) => {
    const [selectedBranchId, setSelectedBranchId] = useState("")
    const {  Branches } = useSelector(state => ({
    
        Branches: state.Branchs?.Branches,
      }))





    return (

        <AvForm>
        <Col className="col-3 mb-3"> 
      
            <AvField
              className="form-select"
              type="select"
              name="BranchId"
              value={selectedBranchId}
                
            >
              <option>{props.t("Bütün Birimler")}</option>
              {Branches?.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </AvField>
          </Col>
       
      </AvForm>
    )
}


selectBranch.propTypes = {

   
    isValue: PropTypes.func,
    t: PropTypes.any,
    setSwitch: PropTypes.any,
    switch: PropTypes.any

}


export default withRouter(
    (withTranslation()(selectBranch))
)


