/** Get WORK_TYPE */
export const GET_WORK_TYPES = "GET_WORK_TYPES";
export const GET_WORK_TYPES_SUCCESS = "GET_WORK_TYPES_SUCCESS";
export const GET_WORK_TYPES_FAIL = "GET_WORK_TYPES_FAIL";

export const GET_WORK_TYPE_DETAIL = "GET_WORK_TYPE_DETAIL";
export const GET_WORK_TYPE_DETAIL_SUCCESS = "GET_WORK_TYPE_DETAIL_SUCCESS";
export const GET_WORK_TYPE_DETAIL_FAIL = "GET_WORK_TYPE_DETAIL_FAIL";


export const ADD_WORK_TYPE = "ADD_WORK_TYPE";
export const ADD_WORK_TYPE_SUCCESS = "ADD_WORK_TYPE_SUCCESS";
export const ADD_WORK_TYPE_FAIL = "ADD_WORK_TYPE_FAIL";

export const UPDATE_WORK_TYPE = "UPDATE_WORK_TYPE";
export const UPDATE_WORK_TYPE_SUCCESS = "UPDATE_WORK_TYPE_SUCCESS";
export const UPDATE_WORK_TYPE_FAIL = "UPDATE_WORK_TYPE_FAIL";

export const DELETE_WORK_TYPE = "DELETE_WORK_TYPE";
export const DELETE_WORK_TYPE_SUCCESS = "DELETE_WORK_TYPE_SUCCESS";
export const DELETE_WORK_TYPE_FAIL = "DELETE_WORK_TYPE_FAIL"; 



export const RESET_WORK_TYPE= "RESET_WORK_TYPE";