import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
  DropdownItem
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {
    getLoginLogs as onGetLoginLogs, 
   
} from "store/actions" 
import "./datatables.scss"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"



const LoginLog = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const dispatch = useDispatch()
  const [selectedDetail, setSelectedDetail] = useState()
  const [selectedId, setSelectedId] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;
  useEffect(() => { 
    dispatch(onGetLoginLogs());  
    }, [dispatch]);
   
  const { error, LoginLogs } = useSelector(state => ({
    error: state.LoginLogs?.error,
    LoginLogs: state.LoginLogs?.data, 
   
})) 
const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY HH:mm")
      return date1
    } else {
      return ""
    }
  }
  const columns = [
    {
        dataField: 'CompanyId',
        text:  props.t("Şirket İsmi"),
        sort: true,
        formatter: (cell, row) => <span>{cell?.name}</span>,
     },
    { 
        dataField: 'UserId',
        text:  props.t("Name"),
        sort: true,
        formatter: (cell, row) => <span>{cell?.name}</span>,
     },
    {
        dataField: "loginDate",
        text: props.t("Giriş Zamanı"),
        sort: true,
        formatter: (cell, row) => <span>{handleValidDate(cell)}</span>,
      },
      {
        dataField: "visitDetail",
        text: props.t("Detaylar"),
        formatter: (cell, row) => (
          <div>
            {cell?(
                <Button
                color="primary"
                className="btn-rounded "
                onClick={() => {
                    checkToggle()
                    setSelectedDetail(cell)
                }}
              >
                {props.t("Detayları Gör")}
              </Button>
            ):null}
            
          </div>
        ),
      },
   
];
   
    const defaultSorted = [{ 
        dataField: 'createdAt',
        order: 'desc'
      }];
      
      const pageOptions = {
        sizePerPage: 10,
        totalSize:  LoginLogs?.size, // replace later with size(customers),
        custom: true,
      }
    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }
 
  const handleValidProductSubmit = (e, values) => {
    if (isEdit) {
        const updateLoginLog = {
            _id: selectedPayment._id,
            name: values["name"],
         
            
        };
        
        dispatch(onUpdateLoginLog(updateLoginLog)); 
    } else {
        const newLoginLog = {
            
            name: values["name"],
           
           
        }
       
        dispatch(onAddLoginLog(newLoginLog))
    }
    toggle()
    
}
    console.log(">>>",selectedDetail)
  return (
    <React.Fragment>
    <div className="page-content">
        <MetaTags>
            <title>{props.t("Kullanıcı Girişleri")}</title>
        </MetaTags>
        <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title={props.t("Kullanıcı Girişleri")} breadcrumbItem={props.t("Kullanıcı Girişleri")} addBtn={false} />

            {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

            <Card>
                <CardBody>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={LoginLogs}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField='_id'
                                columns={columns}
                                data={LoginLogs}
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>

                                        <Row className="mb-2">
                                            <Col md="4">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitProps.searchProps}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div> 
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={"_id"}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                                }
                            </ToolkitProvider>
                        )
                        }</PaginationProvider>

                    <Modal isOpen={modal}  toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Marka Düzenle") : props.t("Marka Ekle")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm  onValidSubmit={handleValidProductSubmit} >
                                <Row form>
                                    <Col xs={12}>
                                        <Row>
                                        <Col md="12">
                                        <div className="mb-3">
                                            <AvField
                                                name="name"
                                                label={props.t("Name")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedPayment?.name || ""}
                                            />
                                        </div>
                                        </Col>
                                       
                                       
                                        </Row>
                                       
                                       
                                        

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <div className="text-end">
                                    <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button>
                                    &nbsp;&nbsp;
                                    <Button onClick={()=>{ toggle() }}>
                                            {props.t("Cancel")}
                                            </Button>       
                                    
                                </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={checkModal} size="lg" toggle={checkToggle}>
                        <ModalHeader toggle={checkToggle} tag="h4">
                            Kullanıcı Giriş Detayları
                        </ModalHeader>
                        <ModalBody>
                            <AvForm   >
                                <Row form>
                                {selectedDetail?.map((item, index) => (
                                    <>
                                            <p>Sayfa İsmi:{" "} {item.name} |  {handleValidDate(item.visitDate)} </p>
                                            
                                            <hr/>
                                    </>
                                            
                                            ))}
                                </Row>
                                <Row>
                                    <Col>
                                    <div className="text-end">
                                    {/* <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button> */}
                                    &nbsp;&nbsp;
                                    <Button onClick={()=>{ toggle() }}>
                                            {props.t("Cancel")}
                                            </Button>       
                                    
                                </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                </CardBody>
            </Card>

            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteLoginLog(selectedId));
                        setSelectedId(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}


            {/* {error && error ? (
                <SweetAlert
                    title={props.t("Error")}
                    warning
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        dispatch(onResetPayment())
                    }}
                >
                    {JSON.stringify(error)}
                </SweetAlert>
            ) : null} */}

        </Container>
    </div>
</React.Fragment>
  )
}


export default withRouter(withTranslation()(LoginLog))
