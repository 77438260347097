import {
    GET_DEV_REQUEST_REASONS,
    GET_DEV_REQUEST_REASONS_SUCCESS,
    GET_DEV_REQUEST_REASONS_FAIL,
    GET_DEV_REQUEST_REASON_DETAIL,
    GET_DEV_REQUEST_REASON_DETAIL_SUCCESS,
    GET_DEV_REQUEST_REASON_DETAIL_FAIL,
    ADD_DEV_REQUEST_REASON,
    ADD_DEV_REQUEST_REASON_SUCCESS,
    ADD_DEV_REQUEST_REASON_FAIL,
    UPDATE_DEV_REQUEST_REASON,
    UPDATE_DEV_REQUEST_REASON_SUCCESS,
    UPDATE_DEV_REQUEST_REASON_FAIL,
    DELETE_DEV_REQUEST_REASON,
    DELETE_DEV_REQUEST_REASON_SUCCESS,
    DELETE_DEV_REQUEST_REASON_FAIL,
    RESET_DEV_REQUEST_REASON,
   
} from "./actionTypes";


export const resetDevRequestReason = () => ({
    type: RESET_DEV_REQUEST_REASON,
});

export const getDevRequestReasons = () => ({
    type: GET_DEV_REQUEST_REASONS,
});

export const getDevRequestReasonsSuccess = (devRequestReason) => ({
    type: GET_DEV_REQUEST_REASONS_SUCCESS,
    payload: devRequestReason,
});


export const getDevRequestReasonsFail = (error) => ({
    type: GET_DEV_REQUEST_REASONS_FAIL,
    payload: error,
});

export const deleteDevRequestReason = (id) => ({
    type:  DELETE_DEV_REQUEST_REASON,
    payload: id
});

export const deleteDevRequestReasonSuccess = (data) => ({
    type: DELETE_DEV_REQUEST_REASON_SUCCESS,
    payload: data
});

export const deleteDevRequestReasonFail = (error) => ({
    type: DELETE_DEV_REQUEST_REASON_FAIL,
    payload: error,
});


export const addDevRequestReason = (devRequestReason) => ({
    type: ADD_DEV_REQUEST_REASON,
    payload: devRequestReason
});

export const addDevRequestReasonSuccess = (data) => ({
    type: ADD_DEV_REQUEST_REASON_SUCCESS,
    payload: data
});

export const addDevRequestReasonFail = (error) => ({
    type: ADD_DEV_REQUEST_REASON_FAIL,
    payload: error,
});


export const updateDevRequestReason = (devRequestReason) => ({
    type: UPDATE_DEV_REQUEST_REASON,
    payload: devRequestReason
});

export const updateDevRequestReasonSuccess = (data) => ({
    type: UPDATE_DEV_REQUEST_REASON_SUCCESS,
    payload: data
});

export const updateDevRequestReasonFail = (error) => ({
    type: UPDATE_DEV_REQUEST_REASON_FAIL,
    payload: error,
});