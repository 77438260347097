/** Get Branchs */
export const GET_REQUEST_FORM_PLANS = "GET_REQUEST_FORM_PLANS";
export const GET_REQUEST_FORM_PLANS_SUCCESS = "GET_REQUEST_FORM_PLANS_SUCCESS";
export const GET_REQUEST_FORM_PLANS_FAIL = "GET_REQUEST_FORM_PLANS_FAIL";

export const GET_REQUEST_FORM_PLAN_DETAIL = "GET_REQUEST_FORM_PLAN_DETAIL";
export const GET_REQUEST_FORM_PLAN_DETAIL_SUCCESS = "GET_REQUEST_FORM_PLAN_DETAIL_SUCCESS";
export const GET_REQUEST_FORM_PLAN_DETAIL_FAIL = "GET_REQUEST_FORM_PLAN_DETAIL_FAIL";


export const ADD_REQUEST_FORM_PLAN = "ADD_REQUEST_FORM_PLAN";
export const ADD_REQUEST_FORM_PLAN_SUCCESS = "ADD_REQUEST_FORM_PLAN_SUCCESS";
export const ADD_REQUEST_FORM_PLAN_FAIL = "ADD_REQUEST_FORM_PLAN_FAIL";

export const UPDATE_REQUEST_FORM_PLAN = "UPDATE_REQUEST_FORM_PLAN";
export const UPDATE_REQUEST_FORM_PLAN_SUCCESS = "UPDATE_REQUEST_FORM_PLAN_SUCCESS";
export const UPDATE_REQUEST_FORM_PLAN_FAIL = "UPDATE_REQUEST_FORM_PLAN_FAIL";

export const DELETE_REQUEST_FORM_PLAN = "DELETE_REQUEST_FORM_PLAN";
export const DELETE_REQUEST_FORM_PLAN_SUCCESS = "DELETE_REQUEST_FORM_PLAN_SUCCESS";
export const DELETE_REQUEST_FORM_PLAN_FAIL = "DELETE_REQUEST_FORM_PLAN_FAIL";

export const UPDATE_ACTION = "UPDATE_ACTION";
export const UPDATE_ACTION_SUCCESS = "UPDATE_ACTION_SUCCESS";
export const UPDATE_ACTION_FAIL = "UPDATE_ACTION_FAIL";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL";

export const GET_REQ_FORMS_DOWN = "GET_REQ_FORMS_DOWN";
export const GET_REQ_FORMS_DOWN_SUCCESS = "GET_REQ_FORMS_DOWN_SUCCESS";
export const GET_REQ_FORMS_DOWN_FAIL = "GET_REQ_FORMS_DOWN_FAIL";

export const RESET_REQUEST_FORM_PLAN = "RESET_REQUEST_FORM_PLAN";