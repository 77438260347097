/** Get Reports */
export const GET_COMPANY_FORM_BY_DATE = "GET_COMPANY_FORM_BY_DATE";
export const GET_COMPANY_FORM_BY_DATE_SUCCESS = "GET_COMPANY_FORM_BY_DATE_SUCCESS";
export const GET_COMPANY_FORM_BY_DATE_FAIL = "GET_COMPANY_FORM_BY_DATE_FAIL ";

export const GET_COMPANY_FORM_BY_DATE_YESTERDAY = "GET_COMPANY_FORM_BY_DATE_YESTERDAY";
export const GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS = "GET_COMPANY_FORM_BY_DATE_YESTERDAY_SUCCESS";
export const GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL = "GET_COMPANY_FORM_BY_DATE_YESTERDAY_FAIL ";

export const GET_COMPANY_FORM_BY_DATE_MONTH = "GET_COMPANY_FORM_BY_DATE_MONTH";
export const GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS = "GET_COMPANY_FORM_BY_DATE_MONTH_SUCCESS";
export const GET_COMPANY_FORM_BY_DATE_MONTH_FAIL = "GET_COMPANY_FORM_BY_DATE_MONTH_FAIL ";

export const GET_COMPANY_FORM_IS_MATCH_BY_DATE = "GET_COMPANY_FORM_IS_MATCH_BY_DATE";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_SUCCESS";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_FAIL ";

export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL ";

export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS";
export const GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL = "GET_COMPANY_FORM_IS_MATCH_BY_DATE_MONTH_FAIL "; 

export const GET_COMPANY_QR_BY_DATE = "GET_COMPANY_QR_BY_DATE";
export const GET_COMPANY_QR_BY_DATE_SUCCESS = "GET_COMPANY_QR_BY_DATE_SUCCESS";
export const GET_COMPANY_QR_BY_DATE_FAIL = "GET_COMPANY_QR_BY_DATE_FAIL ";

export const GET_COMPANY_QR_BY_DATE_YESTERDAY = "GET_COMPANY_QR_BY_DATE_YESTERDAY";
export const GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS = "GET_COMPANY_QR_BY_DATE_YESTERDAY_SUCCESS";
export const GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL = "GET_COMPANY_QR_BY_DATE_YESTERDAY_FAIL ";

export const GET_COMPANY_QR_BY_DATE_MONTH = "GET_COMPANY_QR_BY_DATE_MONTH";
export const GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS = "GET_COMPANY_QR_BY_DATE_MONTH_SUCCESS";
export const GET_COMPANY_QR_BY_DATE_MONTH_FAIL = "GET_COMPANY_QR_BY_DATE_MONTH_FAIL ";

export const GET_COMPANY_QR_BY_DATE_ALL = "GET_COMPANY_QR_BY_DATE_ALL";
export const GET_COMPANY_QR_BY_DATE_ALL_SUCCESS = "GET_COMPANY_QR_BY_DATE_ALL_SUCCESS";
export const GET_COMPANY_QR_BY_DATE_ALL_FAIL = "GET_COMPANY_QR_BY_DATE_ALL_FAIL ";

export const GET_BRANCH_FORM_BY_DATE = "GET_BRANCH_FORM_BY_DATE";
export const GET_BRANCH_FORM_BY_DATE_SUCCESS = "GET_BRANCH_FORM_BY_DATE_SUCCESS";
export const GET_BRANCH_FORM_BY_DATE_FAIL = "GET_BRANCH_FORM_BY_DATE_FAIL ";

export const GET_BRANCH_FORM_BY_DATE_YESTERDAY = "GET_BRANCH_FORM_BY_DATE_YESTERDAY";
export const GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS = "GET_BRANCH_FORM_BY_DATE_YESTERDAY_SUCCESS";
export const GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL = "GET_BRANCH_FORM_BY_DATE_YESTERDAY_FAIL ";

export const GET_BRANCH_FORM_BY_DATE_MONTH = "GET_BRANCH_FORM_BY_DATE_MONTH";
export const GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS = "GET_BRANCH_FORM_BY_DATE_MONTH_SUCCESS";
export const GET_BRANCH_FORM_BY_DATE_MONTH_FAIL = "GET_BRANCH_FORM_BY_DATE_MONTH_FAIL ";

export const GET_BRANCH_FORM_IS_MATCH_BY_DATE = "GET_BRANCH_FORM_IS_MATCH_BY_DATE";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_SUCCESS";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_FAIL ";

export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_SUCCESS";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_YESTERDAY_FAIL ";

export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_SUCCESS";
export const GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL = "GET_BRANCH_FORM_IS_MATCH_BY_DATE_MONTH_FAIL "; 

export const GET_BRANCH_QR_BY_DATE = "GET_BRANCH_QR_BY_DATE";
export const GET_BRANCH_QR_BY_DATE_SUCCESS = "GET_BRANCH_QR_BY_DATE_SUCCESS";
export const GET_BRANCH_QR_BY_DATE_FAIL = "GET_BRANCH_QR_BY_DATE_FAIL ";

export const GET_BRANCH_QR_BY_DATE_YESTERDAY = "GET_BRANCH_QR_BY_DATE_YESTERDAY";
export const GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS = "GET_BRANCH_QR_BY_DATE_YESTERDAY_SUCCESS";
export const GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL = "GET_BRANCH_QR_BY_DATE_YESTERDAY_FAIL ";

export const GET_BRANCH_QR_BY_DATE_MONTH = "GET_BRANCH_QR_BY_DATE_MONTH";
export const GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS = "GET_BRANCH_QR_BY_DATE_MONTH_SUCCESS";
export const GET_BRANCH_QR_BY_DATE_MONTH_FAIL = "GET_BRANCH_QR_BY_DATE_MONTH_FAIL ";

export const GET_BRANCH_QR_BY_DATE_ALL = "GET_BRANCH_QR_BY_DATE_ALL";
export const GET_BRANCH_QR_BY_DATE_ALL_SUCCESS = "GET_BRANCH_QR_BY_DATE_ALL_SUCCESS";
export const GET_BRANCH_QR_BY_DATE_ALL_FAIL = "GET_BRANCH_QR_BY_DATE_ALL_FAIL ";

export const GET_COMPANY_SHARE_QR_BY_DATE = "GET_COMPANY_SHARE_QR_BY_DATE";
export const GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS = "GET_COMPANY_SHARE_QR_BY_DATE_SUCCESS";
export const GET_COMPANY_SHARE_QR_BY_DATE_FAIL = "GET_COMPANY_SHARE_QR_BY_DATE_FAIL ";

export const GET_COMPANY_SHARE_QR_BY_DATE_ALL = "GET_COMPANY_SHARE_QR_BY_DATE_ALL";
export const GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS = "GET_COMPANY_SHARE_QR_BY_DATE_ALL_SUCCESS";
export const GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL = "GET_COMPANY_SHARE_QR_BY_DATE_ALL_FAIL ";

export const GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY = "GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY";
export const GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS = "GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS";
export const GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL = "GET_COMPANY_SHARE_QR_BY_DATE_YESTERDAY_FAIL ";

export const GET_COMPANY_SHARE_QR_BY_DATE_MONTH = "GET_COMPANY_SHARE_QR_BY_DATE_MONTH";
export const GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS = "GET_COMPANY_SHARE_QR_BY_DATE_MONTH_SUCCESS";
export const GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL = "GET_COMPANY_SHARE_QR_BY_DATE_MONTH_FAIL ";

export const GET_BRANCH_SHARE_QR_BY_DATE = "GET_BRANCH_SHARE_QR_BY_DATE";
export const GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS = "GET_BRANCH_SHARE_QR_BY_DATE_SUCCESS";
export const GET_BRANCH_SHARE_QR_BY_DATE_FAIL = "GET_BRANCH_SHARE_QR_BY_DATE_FAIL ";

export const GET_BRANCH_SHARE_QR_BY_DATE_ALL = "GET_BRANCH_SHARE_QR_BY_DATE_ALL";
export const GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS = "GET_BRANCH_SHARE_QR_BY_DATE_ALL_SUCCESS";
export const GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL = "GET_BRANCH_SHARE_QR_BY_DATE_ALL_FAIL ";

export const GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY = "GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY";
export const GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS = "GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_SUCCESS";
export const GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL = "GET_BRANCH_SHARE_QR_BY_DATE_YESTERDAY_FAIL ";

export const GET_BRANCH_SHARE_QR_BY_DATE_MONTH = "GET_BRANCH_SHARE_QR_BY_DATE_MONTH";
export const GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS = "GET_BRANCH_SHARE_QR_BY_DATE_MONTH_SUCCESS";
export const GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL = "GET_BRANCH_SHARE_QR_BY_DATE_MONTH_FAIL ";

export const RESET_BRAND= "RESET_BRAND"; 