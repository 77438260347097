import {
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL, 
  RESET_ANALYTICS,
  GET_MY_FORMS_LENGTH,
  GET_MY_FORMS_LENGTH_SUCCESS,
  GET_MY_FORMS_LENGTH_FAIL, 
  GET_ANALYTIC_BY_LINK_SUCCESS,
  GET_ANALYTIC_BY_LINK_FAIL,
  GET_ANALYTIC_BY_LINK
} from "./actionTypes"

export const resetAnalytics = () => ({
  type: RESET_ANALYTICS, 
})
export const getMyFormsLength = (id) => ({
  type: GET_MY_FORMS_LENGTH,
  payload: id,
})  

export const getMyFormsLengthSuccess = (data) => ({
  type: GET_MY_FORMS_LENGTH_SUCCESS,
  payload: data,
})

export const getMyFormsLengthFail = (error) => ({
  type: GET_MY_FORMS_LENGTH_FAIL,
  payload: error,
})
export const getAnalytics = qrcode => ({
  type: GET_ANALYTICS,
  payload: qrcode,
})

export const getAnalyticsSuccess = qrCodes => ({
  type: GET_ANALYTICS_SUCCESS,
  payload: qrCodes,
})

export const getAnalyticsFail = error => ({
  type: GET_ANALYTICS_FAIL,
  payload: error,
})
 

export const getAnalyticByLink = (CompanyId, Link) => ({
  type: GET_ANALYTIC_BY_LINK,
  payload: {CompanyId, Link},
})

export const getAnalyticByLinkSuccess = (data) => ({
  type: GET_ANALYTIC_BY_LINK_SUCCESS,
  payload: data,
})

export const getAnalyticByLinkFail = (error) => ({
  type: GET_ANALYTIC_BY_LINK_FAIL,
  payload: error
})