import {
    GET_DUTIES_FORMS,
    GET_DUTIES_FORMS_SUCCESS,
    GET_DUTIES_FORMS_FAIL,
    GET_DUTIES_FORM_DETAIL,
    GET_DUTIES_FORM_DETAIL_SUCCESS,
    GET_DUTIES_FORM_DETAIL_FAIL,
    ADD_DUTIES_FORM,
    ADD_DUTIES_FORM_SUCCESS,
    ADD_DUTIES_FORM_FAIL,
    UPDATE_DUTIES_FORM,
    UPDATE_DUTIES_FORM_SUCCESS,
    UPDATE_DUTIES_FORM_FAIL,
    DELETE_DUTIES_FORM,
    DELETE_DUTIES_FORM_SUCCESS,
    DELETE_DUTIES_FORM_FAIL,
    RESET_DUTIES_FORM,
   
} from "./actionTypes";  


export const resetDutiesForm = () => ({
    type: RESET_DUTIES_FORM, 
});
export const getDutiesFormById = (id) => ({
    type: GET_DUTIES_FORM_DETAIL, 
    payload:id
});
export const getDutiesFormByIdSuccess = (data) => ({ 
    type: GET_DUTIES_FORM_DETAIL_SUCCESS,
    payload: data
});
export const getDutiesFormByIdFail = (error) => ({
    type: GET_DUTIES_FORM_DETAIL_FAIL,
    payload: error,
}); 
export const getDutiesForms = () => ({
    type: GET_DUTIES_FORMS,
});

export const getDutiesFormsSuccess = (dutiesForm) => ({
    type: GET_DUTIES_FORMS_SUCCESS,
    payload: dutiesForm,
});


export const getDutiesFormsFail = (error) => ({
    type: GET_DUTIES_FORMS_FAIL,
    payload: error,
});

export const deleteDutiesForm = (id) => ({
    type:  DELETE_DUTIES_FORM,
    payload: id
});

export const deleteDutiesFormSuccess = (data) => ({
    type: DELETE_DUTIES_FORM_SUCCESS,
    payload: data
});

export const deleteDutiesFormFail = (error) => ({
    type: DELETE_DUTIES_FORM_FAIL,
    payload: error,
});


export const addDutiesForm = (dutiesForm) => ({
    type: ADD_DUTIES_FORM,
    payload: dutiesForm
});

export const addDutiesFormSuccess = (data) => ({
    type: ADD_DUTIES_FORM_SUCCESS,
    payload: data
});

export const addDutiesFormFail = (error) => ({
    type: ADD_DUTIES_FORM_FAIL,
    payload: error,
});


export const updateDutiesForm = (dutiesForm) => ({
    type: UPDATE_DUTIES_FORM,
    payload: dutiesForm
});

export const updateDutiesFormSuccess = (data) => ({
    type: UPDATE_DUTIES_FORM_SUCCESS,
    payload: data
});

export const updateDutiesFormFail = (error) => ({
    type: UPDATE_DUTIES_FORM_FAIL,
    payload: error,
});