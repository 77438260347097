import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  TabContent,
  Nav,
  Form,
  FormGroup,
  TabPane,
  CardTitle,
  CardSubtitle,
  NavItem,
  NavLink,
} from "reactstrap"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import PreparedModulesModal from "./preparedModulesModal"
import {
  getPlans as onGetPlans,
  getPlans_MyPlans as onGetMyPlans,
  getCompanyBranches as onGetCompanyBranches,
  addPlan as onAddMyPlans,
  getUserByMail as getUserByMail,
  resetPlan as onResetPlan,
  deletePlan as onDeletePlans_MyPlans,
  updatePlan as onUpdatePlans_MyPlans,
  addPlanOutside as onAddPlanOutside,
  getDutiesFormById as onGetDutiesFormById,
  getBranchChilds as onGetBranchChilds,
} from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import { isEmpty, map, values } from "lodash"

import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { productComments } from "common/data"
import { Search } from "react-bootstrap-table2-toolkit"
import {
  ASKERLIK_DURUMU_SEC,
  AYRILMA_NEDENI_SEC,
  CINSIYET_SEC,
  DIL_SEC,
  EGITIM_DURUMU_SEC,
  EHLIYET_BILGISI_SEC,
  GOREV_SEC,
  MEDENI_DURUM_SEC,
  PROGRAM_BILGISI_SEC,
  SEKTOR_SEC,
  SEVIYE_SEC,
  SURE_SEC_YIL,
  MAAS_BEKLENTISI
} from "../helper/options"
import Multiselect from "multiselect-react-dropdown"

const applicationPlanModal = (props, cb) => {
  const dispatch = useDispatch()
  const [activeTab, setactiveTab] = useState("1")
  const [chosenModule, setChosenModule] = useState()
  const [planDescription, setPlanDescription] = useState()
  const [isGroupButton, setIsGroupButton] = useState([])
  const [modalInputs, setModalInputs] = useState(false)

  const [modalPreparedModules, setModalPreparedModules] = useState(false)
  const [allBranches, setAllBranches] = useState()



  const [roleList, setRoleList] = useState([])
  let user = JSON.parse(localStorage.getItem("authUser"))
  const { error, Grades, Branches, BranchesChild, User, Duty, BackUrls, DutiesForms, formDetail,DutyForms} = useSelector(state => ({
    error: state.Plans?.error,
    MyPlans: state.Plans?.myPlans,
    Plans: state.Plans?.data,
    Branches: state.Branchs?.Branches,
    User: state.Plans?.user,
    Duty: state.Duty?.data,
    BackUrls: state.BackUrl?.data,
    DutiesForms: state.DutiesForm?.data,
    formDetail: state.DutiesForm?.formDetail,
    Grades: state.Companies?.data[0]?.grades,
    BranchesChild: state.Branchs?.data,
    DutyForms:state.DutyForm?.data
  }))

  useEffect(() => {
    setDeletedItem([])
  }, [props.modal])


  const [branchesObject, setBranchesObject] = useState([
    { branchId: "", key: "a" }
  ])

  const [planObjects, setPlanObjects] = useState([
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },
    {
      title: "",
      isType: "", //date, string, number, vs
      isValue: "",
      isPlan: null,
      key: "",
    },
  ])
  const [deletedItems, setDeletedItem] = useState([])
  useEffect(() => {
    if (props.version == "v2") {
      // const values = [...branchesObject]
      // values[0].branchId = props.selectedBranchId
      // setBranchesObject(values)

      setAllBranches([props.selectedBranchId])

    }
    setPlanObjects(props.planObjects)
  }, [props.modal])


  const handleValidPlanSubmit = (e, values) => {
    if (props.comeFrom == "plan" || props.version == "v2") {
      if (props.isEdit) {
        const updatePlan = {
          _id: props.selectedPlan._id,
          name: values.name,
          description: values["description"],
          CompanyId: user.companyId,
          returnURL: values["returnURL"],
          CreatorUserId: user.uid,
          BranchId: BranchId.value,
          workType: values["workType"],
          //DutyId: values["DutyId"],
          DutyId: formDetail?.DutyId,
          DutiesFormId: values["DutiesFormId"],
          position: values["position"],
          // PlanObjects:
          //   document.getElementById("PlanObjects").value ||
          //   JSON.stringify(planObjects),
          PlanObjects: JSON.stringify(formDetail?.PlanObjects),
          planDesc: document.getElementById("planDesc")?.value || planDescription,
          //PlanObjects:JSON.stringify(planObjects)
        }
        // update Plan
        props.toggle()

        dispatch(onUpdatePlans_MyPlans(updatePlan))

      } else {
   
        for (let i = 0; i < allBranches.length; i++) {
          const newPlan = {
            name: values["name"],
            description: values["description"],
            returnURL: values["returnURL"],
            CompanyId: user.companyId,
            workType: values["workType"],
            CreatorUserId: user.uid,
            // PlanObjects: document.getElementById("PlanObjects").value,
            PlanObjects: JSON.stringify(formDetail?.PlanObjects),
            //BranchId: BranchId.value,
            BranchId: allBranches[i]._id,
            //DutyId: values["DutyId"],
            DutyId: formDetail?.DutyId,
            position: values["position"],
            planDesc: document.getElementById("planDesc").value,
            DutiesFormId: values["DutiesFormId"],
          }
          // save new Plan 

          dispatch(onAddMyPlans(newPlan))
          let _body = {
            branchLength: allBranches?.length,
            BranchId: allBranches[0]._id,
          };

          if (props.version == "v2") {
            props.payment(true)
          } else {
            props.save(true, _body)
           props.toggle()

          }

        }

      }
    } else {
      const newPlan = {

        CompanyId: user.companyId,
        CreatorUserId: user.uid,
        PlanObjects: JSON.stringify(formDetail?.PlanObjects),
       // PlanObjects: document.getElementById("PlanObjects").value,
        name: values["name"],
        description: values["description"],
        returnURL: values["returnURL"],
        DutyId: formDetail?.DutyId,
        DutiesFormId: values["DutiesFormId"],
      }
      // save new Plan
      props.toggle()

      dispatch(onAddPlanOutside(newPlan)) 
      console.log("new",newPlan)
    }

    
    setBranchesObject([{ branchId: "" }])
  }

  const handleChangeInput = (i, e, inputName) => {
    const values = [...planObjects]

    values[i][inputName] = e.target.value
    setPlanObjects(values)
  }
  const handleAddGroupFirst = index => {
    let temp = [...planObjects]
    if (temp[index].isPlan == null || temp[index].isPlan == "") {
      temp[index].isPlan = []
    }
    temp[index].isPlan.splice(index + 1, 0, {
      title: "",
      isType: "",
      isValue: "",
    })

    setPlanObjects([...temp])
  }
  const handleChangeInputForGroup = (i, i2, e, inputName) => {

    const values = [...planObjects]
    values[i]["isPlan"][i2]["targetValue"] = e.target.value
    setPlanObjects(values)
  }

  const handleChangeInputForGroupKey = (i, i2, e, inputName) => {

    const values = [...planObjects]

    values[i]["isPlan"][i2]["targetValue"] = e.target.value
    setPlanObjects(values)
  }
  const handleChangeBranch = (i, e) => {
    const values = [...branchesObject]
    values[i].branchId = e
    setBranchesObject(values)
  }
  const handleSubtractBranch = i => {

    const values = [...branchesObject]
    values.splice(i, 1)
    setBranchesObject([...values])
  }
  const handleAddBranch = index => {
    let temp = branchesObject
    let newProjectObjects = []
    temp.splice(index + 1, 0, {
      branchId: ""
    })

    for (let i = 0; i < temp.length; i++) {
      newProjectObjects.push(temp[i])
    }
    setBranchesObject(newProjectObjects)
  }

  const handleChangeCriteria = (
    i,
    e,
    inputName,
    keyName,
    title,
    isType,
    options
  ) => {
    const values = [...planObjects]
    values[i]["isOptions"] = options
    values[i]["isType"] = isType
    values[i]["key"] = keyName
    values[i][inputName] = e.target.value
    setPlanObjects(values)
  }
  const handleSubtractForGroup = (i, i2) => {
    const values = [...planObjects]
    values[i]["isPlan"].splice(i2, 1)
    setPlanObjects([...values])
  }

  const handleSubtract = (i, items) => {
    const values = [...planObjects]
    const deleted = [...deletedItems]
    deleted.push(items)
    setDeletedItem(deleted)
    values.splice(i, 1)
    setPlanObjects([...values])
  }

  const handleChangeInputForTarget = (i, e, inputName) => {
    const values = [...planObjects]

    if (values[i][inputName].includes(e.target.value)) {
      let toRemove = e.target.value
      let index = values[i][inputName].indexOf(toRemove)
      if (index > -1) {
        values[i][inputName].splice(index, 1)
      }

    } else {
      values[i][inputName] = [...values[i][inputName], e.target.value]
    }

    setPlanObjects(values)
  }
  const handleAddGroup_AddGroup = (index, title, isType, isOpt) => {
    let temp = [...planObjects]
    if (temp[index].isPlan == null || temp[index].isPlan == "") {
      temp[index].isPlan = []
    }
    temp[index].isPlan.push({
      title: title,
      isType: isType,
      isValue: "",
      targetValue: "",
      isOptions: isOpt,
    })

    setPlanObjects([...temp])
  }
  function handleOnDragSocialMediasEnd_up(index) {
    const items = Array.from(planObjects);
    const [reorderedItem] = items.splice(index, 1);
    items.splice(index - 1, 0, reorderedItem);

    setPlanObjects(items)

  }
  function handleOnDragSocialMediasEnd_down(index) {
    const items = Array.from(planObjects);
    const [reorderedItem] = items.splice(index, 1);
    items.splice(index + 1, 0, reorderedItem);

    setPlanObjects(items)
  }
  const handleChangeInputNameForGroup = (
    i,
    i2,
    e,
    title,
    isType,
    isOptions,
    inputName
  ) => {
    //console.log(i, i2, e.target.value, inputName)
    const values = [...planObjects]
    values[i]["isType"] = isType
    values[i]["isPlan"][i2]["isType"] = "dropdown"
    values[i]["isPlan"][i2]["isOptions"] = isOptions
    values[i]["key"] = title

    values[i]["isPlan"][i2]["targetValue"] = e.target.value
    values[i]["isPlan"][i2]["isValue"] = e.target.value
    setPlanObjects(values)
  }

  const toggleInputs = () => {
    if (modalInputs) {
      // InputAddReset();
    }
    setModalInputs(!modalInputs)
  }
  const togglePreparedModules = () => {
    if (modalPreparedModules) {
      // InputAddReset();
    }
    setModalPreparedModules(!modalPreparedModules)
  }

  return (
    <React.Fragment>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {!!props.isEdit
            ? props.t("PLATFORM DIŞI PERSONEL BAŞVURU FORMU ")
            : props.t("PLATFORM DIŞI PERSONEL BAŞVURU FORMU ")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidPlanSubmit}>
            <Row form>
              <Col xs={12}>
                <input
                  id="PlanObjects"
                  name="hidden"
                  type="hidden"
                //value={JSON.stringify(planObjects) }
                />
                <input id="planDesc" name="hidden" type="hidden" />

                <div className="mb-3">
                  <AvField
                    className="form-select"
                    type="select"
                    name="DutiesFormId"
                    disabled={props.version == "v2" ? true : false}
                    value={props.selectedDutiesFormId}
                    label={props.t("İlan Çıkılacak Görev Formunu Seçiniz")}
                    onChange={e => {
                      dispatch(onGetDutiesFormById(e.target.value))
                    }}
                  >
                    <option>{props.t("İlan Çıkılacak Görev Formunu Seçiniz")}</option>
                    
                    {DutiesForms?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item?.description}
                      </option>
                    ))}
                  </AvField> 
                </div>


                <div className="mb-3">
                  <AvField
                    name="description"
                    label={props.t("Position Desc")}
                    type="textarea"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: false },
                    }}
                    value={
                      props.version == "v2"
                        ? ""
                        : props.selectedPlan?.description || "" 
                    }
                  />
                </div>
                {props.comeFrom=="outside"?null:(
                  <>
                   <b style={{ fontWeight: "500" }}>{props.t("Select Req Unit")}</b>
                <div className="mb-3 mt-2 ">
                  <Multiselect
                  
                    displayValue="name"
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function noRefCheck() { }}
                    onSearch={function noRefCheck() { }}
                    onSelect={(event) =>{setAllBranches(event)}}
                    options={Branches}
                    placeholder="İlan Çıkılacak Birim"
                    emptyRecordMsg="Seçenek Yok"
                    selectedValues ={props.isEdit || props.version=="v2"?[props.selectedBranchId]:""}
                    disable={props.version=="v2"?true:false}
                    singleSelect={false}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-select"
                    type="select"
                    name="workType"
                    disabled={props.version == "v2" ? true : false}
                    value={props.selectedWorkType}
                    label={props.t("Staff Job Type")}
                  >
                    <option>{props.t("Staff Job Type")}</option>
                    <option>{props.t("Full Time")}</option>
                    <option>{props.t("Part Time")}</option>
                    <option>{props.t("Intern")}</option>
                    <option>{props.t("Dönemsel")}</option>
                    <option>{props.t("Geçici")}</option>
                  </AvField>
                </div>
                  </>
                )}
               
                   

                {/* <b style={{ fontWeight: "500" }}>{props.t("Select Req Unit")}</b>
                {branchesObject?.map((item, i) => (
                  <div key={i} className="mt-2 mb-2">
                    <Row>
                      <Col md="9" sm="8" xs="8">
                        <div className="mb-3">
                          <AvField
                            className="form-select"
                            type="select"
                            name="BranchId"
                            disabled={props.version == "v2" ? true : false}
                            value={props.version == "v2" ? props.selectedBranchId : branchesObject[i]?.branchId}

                            onChange={e =>
                              handleChangeBranch(i, e.target.value)
                            }
                          >
                            <option>{props.t("Select Req Unit")}</option>
                            {Branches?.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </AvField>
                        </div> </Col>
                      {props.version == "v2" ? (
                        null

                      ) : (
                        <Col md="3" sm="4" xs="4" className="items-margin">
                          <div>
                            {i + 1 == branchesObject?.length ? (
                              <Button
                                onClick={() => handleAddBranch(i)}
                                className="btn btn-success me-2"
                              >
                                <i className="fas fa-plus"></i>
                              </Button>
                            ) : null
                            }

                            {branchesObject.length > 1 ? (
                              <Button style={{ position: "relative" }}
                                onClick={() => handleSubtractBranch(i)}
                                className="btn btn-danger"
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            ) : null}
                          </div>
                        </Col>
                      )}




                    </Row>
                  </div>
                ))} */}
               
                <div className="mb-3">
                  <AvField
                    className="form-select"
                    type="select"
                    name="returnURL"
                    value={props.selectedPlan?.returnURL}
                    label={props.t("returnURL")}
                  >
                    <option>{props.t("Başvuru Sonrası Yönlendirme URL'i Seçiniz")}</option>
                    {BackUrls?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item?.description}
                      </option>
                    ))}
                  </AvField>
                </div>



              </Col>
            </Row>


            {/* <Row>
              <Col>
                <div className="text-end">
                  {document.getElementById("PlanObjects")?.value ? (
                    <button type="submit" className="btn btn-success save-user">
                      {props.t("Save")}
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary save-user"
                      onClick={() => {
                        toggleInputs()
                      }}>
                      {props.t("Continue")}
                    </button>
                  )}

                  &nbsp;&nbsp;
                  <Button
                    onClick={() => { props.toggle() }

                    }
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <div className="text-end">

                  <button type="submit" className="btn btn-success save-user">
                    {props.t("Save")}
                  </button>


                  &nbsp;&nbsp; 
                  <Button
                    onClick={() => { props.toggle() }

                    }
                  >
                    {props.t("Cancel")} 
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalInputs} toggle={toggleInputs} size="lg">
        <ModalHeader toggle={toggleInputs} tag="h4">
          {props.t("Add Job Application Plan")}
        </ModalHeader>
        <ModalBody >
          <small className="mb-3">
            {props.t("Please Enter Job Application Plan")}
          </small>
          <br /> <br />
          <AvForm>
            <Col md="4" className="mb-1">
              {props.t("Description")}
            </Col>
            <Col md="12" className="mb-3">
              <AvField
                type="textarea"
                name="planDesc"
                placeholder={props.t("Description")}
                value={props.selectedPlan?.planDesc || ""}
                onChange={e => {
                  setPlanDescription(e.target.value)
                }}
                validate={{
                  required: { value: false },
                }}
              />
            </Col>
            <Col md="4" className="mb-1">
              {props.t("Default Infos")}
            </Col>
            <Row>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Photo")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("First Name")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Last Name")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Email")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Phone Number")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Birthday")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mb-2">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Identitiy Number")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
              <Col md="4" className="mb-4">
                <AvField
                  type="text"
                  id={"deneme"}
                  name={"deneme"}
                  placeholder={props.t("Address")}
                  value={""}
                  disabled
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>
            </Row>
            <p>Aşağıdaki sorulardan personel başvuru formunda sormak istemediklerinizi lütfen siliniz</p>
            <p>Ön eleme kriteri eklemek için lütfen modüllerden kriterlerini seçiniz</p>
            {!isEmpty(planObjects) ? (
              <Row style={{ position: "relative", top: "18px", left: "20px" }}>
                <Col className="col-4"><b style={{ fontWeight: "600" }}>Başlık</b></Col>
                <Col className="col-4"><b style={{ fontWeight: "600" }}>Ön Eleme Kriteri</b></Col>
              </Row>
            ) : null}

            <Row form>
              <Col className="col-12">
                {planObjects?.map((item, i) => (
                  <div key={i} style={{
                    border: "1px solid white", borderRadius: "5px",
                    backgroundColor: item.key == "cinsiyet" ? isEmpty(item.targetValue) || item.targetValue == "Cinsiyet Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                      item.key == "medeniDurum" ? isEmpty(item.targetValue) || item.targetValue == "Medeni Durum Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                        item.key == "maasBeklentisi" ? isEmpty(item.targetValue) || item.targetValue == "Maaş Beklentisi Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                          item.key == "egitimDurumu" ? isEmpty(item.targetValue) || item.targetValue == "Eğitim Durumu Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                            item.key == "askerlikDurumu" ? isEmpty(item.targetValue) || item.targetValue == "Askerlik Durumu Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                              item.key == "ehliyet" ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Ehliyet Bilgisi Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" :
                                item.key == "isDeneyimi" ? !isEmpty(item.isPlan) ? (isEmpty(item?.isPlan[1]?.targetValue) || item?.isPlan[1]?.targetValue == "Görev Seç") && (isEmpty(item?.isPlan[2]?.targetValue) || item?.isPlan[2]?.targetValue == "Sektör Seç") ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                  item.key == "yabanciDil" ? !isEmpty(item.isPlan) ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Dil Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                    item.key == "programBilgisi" ? !isEmpty(item.isPlan) ? isEmpty(item?.isPlan[0]?.targetValue) || item?.isPlan[0]?.targetValue == "Program Seç" ? "#f3f3f3" : "rgb(255, 231, 190)" : null :
                                      null

                  }} className="mt-4 mb-4">

                    <input
                      id={"planObjects_hidden" + i + "__isValue"}
                      name={"planObjects_hidden[" + i + "].isValue"}
                      type="hidden"
                    />

                    <Row className="dropdown-plan-item-pos mt-2 mb-2" >
                      <Col md="4" style={{ display: "flex", alignItems: "center" }} >
                        {props.t(item.title)}

                      </Col>

                      {item.key === "yabanciDil" ? (
                        <Col md="4">
                          {isEmpty(planObjects[i].isPlan) ? (
                            <Button
                              onClick={() => {
                                handleAddGroupFirst(
                                  i,
                                  "Dil",
                                  "dropdown",
                                  props.foreignLang
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Okuma",
                                  "dropdown",
                                  SEVIYE_SEC
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Yazma",
                                  "dropdown",
                                  SEVIYE_SEC
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Konuşma",
                                  "dropdown",
                                  SEVIYE_SEC
                                )
                                setIsGroupButton(
                                  isGroupButton ? { ...isGroupButton } : null,
                                  planObjects[i].isPlan
                                )
                              }}
                              className="btn btn-warning"
                            >
                              <i className="fas fa-plus"></i>
                            </Button>
                          ) : null}
                        </Col>
                      ) : null}
                      {item.key === "isDeneyimi" ? (
                        <Col md="4">
                          {isEmpty(planObjects[i].isPlan) ? (
                            <Button
                              onClick={() => {
                                //handleAddGroupFirst
                                handleAddGroupFirst(
                                  i,
                                  "Firma Unvanı",
                                  "text"
                                )
                                handleAddGroupFirst(
                                  i,
                                  "Görev",
                                  "dropdown",
                                  props.dutyForm
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Sektör",
                                  "dropdown",
                                  props.sectorForm
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Süre",
                                  "dropdown",
                                  SURE_SEC_YIL
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Ayrılma Nedeni",
                                  "dropdown",
                                  AYRILMA_NEDENI_SEC
                                )
                                handleAddGroupFirst(
                                  i,
                                  "Giriş Tarihi",
                                  "date"
                                )
                                handleAddGroupFirst(
                                  i,
                                  "Çıkış Tarihi",
                                  "date"
                                )


                                setIsGroupButton(
                                  isGroupButton ? { ...isGroupButton } : null,
                                  planObjects[i].isPlan
                                )
                              }}
                              className="btn btn-warning"
                            >
                              <i className="fas fa-plus"></i>
                            </Button>
                          ) : null}
                        </Col>
                      ) : null}

                      {item.key === "programBilgisi" ? (
                        <Col md="4">
                          {isEmpty(planObjects[i].isPlan) ? (
                            <Button
                              onClick={() => {
                                handleAddGroupFirst(
                                  i,
                                  "Program Bilgisi",
                                  "dropdown",
                                  props.program
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Bilgi Derecesi",
                                  "dropdown",
                                  SEVIYE_SEC
                                )

                                setIsGroupButton(
                                  isGroupButton ? { ...isGroupButton } : null,
                                  planObjects[i].isPlan
                                )

                              }}
                              className="btn btn-warning"
                            >
                              <i className="fas fa-plus"></i>
                            </Button>
                          ) : null}
                        </Col>
                      ) : null}
                      {item.key === "ehliyet" ? (

                        <Col md="4">

                          {isEmpty(planObjects[i].isPlan) ? (
                            <Button
                              onClick={() => {
                                handleAddGroupFirst(
                                  i,
                                  "Ehliyet Bilgisi",
                                  "dropdown",
                                  EHLIYET_BILGISI_SEC
                                )
                                handleAddGroup_AddGroup(
                                  i,
                                  "Süre",
                                  "dropdown",
                                  SURE_SEC_YIL
                                )
                                setIsGroupButton(
                                  isGroupButton ? { ...isGroupButton } : null,
                                  planObjects[i].isPlan
                                )
                              }}
                              className="btn btn-warning"
                            >
                              <i className="fas fa-plus"></i>
                            </Button>
                          ) : null}
                        </Col>
                      ) : null}
                      {item.key === "cinsiyet" ? (
                        <Col md="4" >
                          <Row>
                            <Col md="12">
                              <AvField
                                type="select"
                                id={"planObjects_cinsiyet" + i + "__isType"}
                                name={
                                  "planObjects_cinsiyet[" + i + "].isType"
                                }
                                value={item.targetValue}
                                className="form-select"
                                onChange={e =>
                                  handleChangeCriteria(
                                    i,
                                    e,
                                    "targetValue",
                                    "cinsiyet",
                                    "Cinsiyet",
                                    "dropdown",
                                    CINSIYET_SEC
                                  )
                                }
                              >
                                {CINSIYET_SEC?.split(",").map((itm, idx) => (
                                  <option key={idx}>{props.t(itm)}</option>
                                ))}
                              </AvField>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {item.key === "medeniDurum" ? (
                        <Col md="4">
                          <Row>
                            <Col md="12">
                              <AvField
                                type="select"
                                id={"planObjects_medeni" + i + "__isType"}
                                name={"planObjects_medeni[" + i + "].isType"}
                                value={item.targetValue}
                                className="form-select"
                                onChange={e =>
                                  handleChangeCriteria(
                                    i,
                                    e,
                                    "targetValue",
                                    "medeniDurum",
                                    "Medeni Durum",
                                    "dropdown",
                                    MEDENI_DURUM_SEC
                                  )
                                }
                              >
                                {MEDENI_DURUM_SEC?.split(",").map(
                                  (itm, idx) => (
                                    <option key={idx}>{props.t(itm)}</option>
                                  )
                                )}
                              </AvField>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {item.key === "egitimDurumu" ? (
                        <Col md="4">
                          <Row>
                            <Col md="12">

                              <AvField
                                type="select"
                                id={"planObjects_egitim" + i + "__isType"}
                                name={"planObjects_egitim[" + i + "].isType"}
                                value={item.targetValue}
                                className="form-select"
                                onChange={e =>
                                  handleChangeCriteria(
                                    i,
                                    e,
                                    "targetValue",
                                    "egitimDurumu",
                                    "Eğitim Durumu",
                                    "dropdown",
                                    EGITIM_DURUMU_SEC
                                  )
                                }
                              >
                                {EGITIM_DURUMU_SEC?.split(",").map(
                                  (itm, idx) => (
                                    <option key={idx}>{props.t(itm)}</option>
                                  )
                                )}
                              </AvField>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {item.key === "askerlikDurumu" ? (
                        <Col md="4">
                          <Row>
                            <Col md="12">
                              <AvField
                                type="select"
                                id={"planObjects_askerlik" + i + "__isType"}
                                name={
                                  "planObjects_askerlik[" + i + "].isType"
                                }
                                value={item.targetValue}
                                className="form-select"
                                onChange={e =>
                                  handleChangeCriteria(
                                    i,
                                    e,
                                    "targetValue",
                                    "askerlikDurumu",
                                    "Askerlik Durumu",
                                    "dropdown",
                                    ASKERLIK_DURUMU_SEC
                                  )
                                }
                              >
                                {ASKERLIK_DURUMU_SEC?.split(",").map(
                                  (itm, idx) => (
                                    <option key={idx}>{props.t(itm)}</option>
                                  )
                                )}
                              </AvField>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {item.key === "maasBeklentisi" ? (
                        <Col md="4">
                          <Row>
                            <Col md="12">
                              <AvField
                                type="select"
                                id={"planObjects_askerlik" + i + "__isType"}
                                name={
                                  "planObjects_askerlik[" + i + "].isType"
                                }
                                value={item.targetValue}
                                className="form-select"
                                onChange={e =>
                                  handleChangeCriteria(
                                    i,
                                    e,
                                    "targetValue",
                                    "maasBeklentisi",
                                    "Maaş Beklentisi",
                                    "dropdown",
                                    MAAS_BEKLENTISI
                                  )
                                }
                              >
                                {MAAS_BEKLENTISI?.split(",").map(
                                  (itm, idx) => (
                                    <option key={idx}>{props.t(itm)}</option>
                                  )
                                )}
                              </AvField>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      <Col md="3" sm="6" xs="6" style={{ position: "relative", textAlign: "end" }}>
                        <div>
                          {planObjects.length > 1 && i != 0 ? (
                            <Button
                              onClick={() => handleOnDragSocialMediasEnd_up(i)}
                              className="btn btn-success me-2"
                            >
                              <i className="bx bx-up-arrow"></i>
                            </Button>
                          ) : null}

                          {planObjects.length != (i + 1) ? (
                            <Button onClick={() => handleOnDragSocialMediasEnd_down(i)}
                              className="btn btn-info me-2">
                              <i className="bx bx-down-arrow"></i>
                            </Button>
                          ) : null}

                        </div>
                      </Col>
                      <Col md="1" sm="6" xs="6" className="items-margin" style={{ position: "relative", top: "2px", right: "24px" }}>
                        <div>
                          {isEmpty(planObjects) ? (
                            null


                          ) : (
                            <Button style={{ position: "relative", top: "2px" }}
                              onClick={() => handleSubtract(i, item)}
                              className="btn btn-danger"
                            >
                              <i className="bx bx-trash"></i>
                            </Button>)}





                        </div>
                      </Col>
                    </Row>
                    {item.key === "programBilgisi" ? (
                      <div>
                        {typeof item.isPlan == "object"
                          ? item.isPlan?.map((groupItem, groupIndex) => (
                            <div key={groupIndex}>
                              <Row
                                className="group-item-pos"
                              >
                                <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                  {props.t(groupItem.title)}
                                </Col>
                                <Col md="4" style={{ position: "relative", right: "5px" }}>
                                  {groupItem?.title == "Bilgi Derecesi" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program_sure" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "__isType"
                                      }
                                      name={
                                        "planObjects_program_sure[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].isType"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>

                                        handleChangeInputNameForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "programBilgisi",
                                          "group",
                                          SEVIYE_SEC,
                                          "Bilgi Derecesi"
                                        )
                                      }
                                    >
                                      {SEVIYE_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "programBilgisi",
                                          "group",
                                          props.program
                                        )
                                      }
                                    >
                                      {" "}
                                      {props.program?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  )}
                                </Col>

                                <Col md="1">
                                  {groupIndex % 2 == 0 ? (
                                    <Button
                                      onClick={() => {
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Program Bilgisi",
                                          "dropdown",
                                          props.program
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Bilgi Derecesi",
                                          "dropdown",
                                          SEVIYE_SEC
                                        )

                                      }}
                                      className="btn btn-warning"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                                <Col md="1" style={{ position: "relative", right: "19px" }}>
                                  {groupIndex % 2 == 0 && item.isPlan.length != 2 ? (
                                    <Button
                                      className="text-danger"
                                      onClick={() => {
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        {
                                          isEmpty(planObjects[i].isPlan)
                                            ? null
                                            : setIsGroupButton(
                                              planObjects[i].isPlan
                                            )
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          ))
                          : null}
                      </div>
                    ) : null}
                    {item.key === "ehliyet" ? (
                      <div>
                        {typeof item.isPlan == "object"
                          ? item.isPlan?.map((groupItem, groupIndex) => (
                            <div key={groupIndex}>
                              <Row className="group-item-pos"

                              >
                                <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                  {props.t(groupItem.title)}
                                </Col>
                                <Col md="4">
                                  {groupItem?.title == "Ehliyet Bilgisi" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program_sure" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "__isType"
                                      }
                                      name={
                                        "planObjects_program_sure[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].isType"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputNameForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "ehliyet",
                                          "group",
                                          EHLIYET_BILGISI_SEC
                                        )
                                      }
                                    >
                                      {EHLIYET_BILGISI_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "ehliyet",
                                          "group",
                                          SURE_SEC_YIL,
                                          "Süre"
                                        )
                                      }
                                    >
                                      {SURE_SEC_YIL?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  )}
                                </Col>

                                <Col md="1">
                                  {groupIndex % 2 == 0 ? (
                                    <Button
                                      onClick={() => {
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Ehliyet Bilgisi",
                                          "dropdown",
                                          EHLIYET_BILGISI_SEC
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Süre",
                                          "dropdown",
                                          SURE_SEC_YIL
                                        )
                                      }}
                                      className="btn btn-warning"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                                <Col md="1" style={{ position: "relative", right: "19px" }}>

                                  {groupIndex % 2 == 0 && item.isPlan.length != 2 ? (
                                    <Button
                                      className="text-danger"
                                      onClick={() => {
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        {
                                          isEmpty(planObjects[i].isPlan)
                                            ? null
                                            : setIsGroupButton(
                                              planObjects[i].isPlan
                                            )
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          ))
                          : null}
                      </div>
                    ) : null}
                    {item.key === "isDeneyimi" ? (
                      <div>
                        {typeof item.isPlan == "object"
                          ? item.isPlan?.map((groupItem, groupIndex) => (

                            <div key={groupIndex}>

                              <Row
                                className="group-item-pos"
                              >
                                <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                  {groupItem.title == "Ayrılma Nedeni" || groupItem.title == "Giriş Tarihi" || groupItem.title == "Çıkış Tarihi" ? null : props.t(groupItem.title)}

                                </Col>
                                <Col md="4">
                                  {groupItem?.title == "Görev" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "isDeneyimi",
                                          "group",
                                          props.dutyForm,
                                          "Görev"
                                        )
                                      }
                                    >
                                      {props.dutyForm?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : groupItem?.title == "Sektör" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program_sure" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "__isType"
                                      }
                                      name={
                                        "planObjects_program_sure[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].isType"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputNameForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "isDeneyimi",
                                          "group",
                                          props.sectorForm
                                        )
                                      }
                                    >
                                      {props.sectorForm?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : groupItem?.title == "Süre" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "isDeneyimi",
                                          "group",
                                          SURE_SEC_YIL,
                                          "Süre"
                                        )
                                      }
                                    >
                                      {SURE_SEC_YIL?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : (
                                    <AvField
                                      type="hidden"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={" "}
                                      className="form-select"
                                      disabled

                                    >
                                      {AYRILMA_NEDENI_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  )}
                                </Col>

                                <Col md="1">
                                  {groupIndex % 7 == 0 ? (
                                    <Button
                                      onClick={() => {

                                        handleAddGroup_AddGroup(
                                          i,
                                          "Firma Unvanı",
                                          "text"

                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Görev",
                                          "dropdown",
                                          props.dutyForm
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Sektör",
                                          "dropdown",
                                          props.sectorForm
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Süre",
                                          "dropdown",
                                          SURE_SEC_YIL
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Ayrılma Nedeni",
                                          "dropdown",
                                          AYRILMA_NEDENI_SEC
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Giriş Tarihi",
                                          "date"
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Çıkış Tarihi",
                                          "date"
                                        )
                                      }}
                                      className="btn btn-warning"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                                <Col md="1" style={{ position: "relative", right: "19px" }}>
                                  {groupIndex % 7 == 0 && item.isPlan.length != 7 ? (
                                    <Button
                                      className="text-danger"
                                      onClick={() => {
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )

                                        {
                                          isEmpty(planObjects[i].isPlan)
                                            ? null
                                            : setIsGroupButton(
                                              planObjects[i].isPlan
                                            )
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          ))
                          : null}
                      </div>
                    ) : null}
                    {item.key === "yabanciDil" ? (
                      <div>
                        {typeof item.isPlan == "object"
                          ? item.isPlan?.map((groupItem, groupIndex) => (
                            <div key={groupIndex}>
                              <Row
                                className="group-item-pos"
                              >
                                <Col md="4" style={{ display: "flex", alignItems: "center" }}>
                                  {props.t(groupItem.title)}
                                </Col>
                                <Col md="4">

                                  {groupItem?.title == "Dil" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program_sure" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "__isType"
                                      }
                                      name={
                                        "planObjects_program_sure[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].isType"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputNameForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "yabanciDil",
                                          "group",
                                          props.foreignLang
                                        )
                                      }
                                    >
                                      {props.foreignLang?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : groupItem?.title == "Okuma" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "yabanciDil",
                                          "group",
                                          SEVIYE_SEC,
                                          "Okuma Seviyesi"
                                        )
                                      }
                                    >
                                      {SEVIYE_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : groupItem?.title == "Yazma" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "yabanciDil",
                                          "group",
                                          SEVIYE_SEC,
                                          "Yazma Seviyesi"
                                        )
                                      }
                                    >
                                      {SEVIYE_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : groupItem?.title == "Konuşma" ? (
                                    <AvField
                                      type="select"
                                      id={
                                        "planObjects_program" +
                                        i +
                                        "_" +
                                        groupIndex +
                                        "title"
                                      }
                                      name={
                                        "planObjects_program[" +
                                        i +
                                        "][" +
                                        groupIndex +
                                        "].title"
                                      }
                                      value={groupItem.targetValue}
                                      className="form-select"
                                      onChange={e =>
                                        handleChangeInputForGroup(
                                          i,
                                          groupIndex,
                                          e,
                                          "yabanciDil",
                                          "group",
                                          SEVIYE_SEC,
                                          "Konuşma Seviyesi"
                                        )
                                      }
                                    >
                                      {SEVIYE_SEC?.split(",").map(
                                        (itm, idx) => (
                                          <option key={idx}>
                                            {props.t(itm)}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  ) : null}
                                </Col>

                                <Col md="1">
                                  {groupIndex % 4 == 0 ? (
                                    <Button
                                      onClick={() => {
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Dil",
                                          "dropdown",
                                          props.foreignLang
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Okuma",
                                          "dropdown",
                                          SEVIYE_SEC
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Yazma",
                                          "dropdown",
                                          SEVIYE_SEC
                                        )
                                        handleAddGroup_AddGroup(
                                          i,
                                          "Konuşma",
                                          "dropdown",
                                          SEVIYE_SEC
                                        )
                                      }}
                                      className="btn btn-warning"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                                <Col md="1" style={{ position: "relative", right: "19px" }}>
                                  {groupIndex % 4 == 0 && item.isPlan.length != 4 ? (
                                    <Button
                                      className="text-danger"
                                      onClick={() => {
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        handleSubtractForGroup(
                                          i,
                                          groupIndex
                                        )
                                        {
                                          isEmpty(planObjects[i].isPlan)
                                            ? null
                                            : setIsGroupButton(
                                              planObjects[i].isPlan
                                            )
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          ))
                          : null}
                      </div>
                    ) : null}
                  </div>
                ))}
              </Col>


            </Row>

            <Row>
              <Col>

                <div className="text-end">

                  <Button
                    onClick={() => {
                      document.getElementById("PlanObjects").value =
                        JSON.stringify(planObjects)

                      if (planDescription == undefined) {
                        document.getElementById("planDesc").value = ""
                      } else {
                        document.getElementById("planDesc").value =
                          planDescription
                      }

                      toggleInputs()
                    }}
                    className="btn btn-success save-user"
                  >
                    {props.t("Save")}
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      toggleInputs()
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                  &nbsp;&nbsp;
                  <Button className="btn btn-warning save-user"
                    disabled={isEmpty(deletedItems) ? true : false}
                    onClick={() => {
                      const array1 = [...planObjects];
                      const array2 = [...deletedItems];
                      const array3 = array1.concat(array2);
                      setPlanObjects(array3)
                      setDeletedItem([])
                    }}
                  >
                    {props.t("Modül Ekle")}
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <PreparedModulesModal
        modal={modalPreparedModules}
        save={(e, _body) => {
          if (e == true) {
            if (
              _body.moduleObjects[0]?.title != "" ||
              _body.moduleContact[0]?.title != "" ||
              _body.moduleExperience[0]?.title != "" ||
              _body.moduleEducation[0]?.title != "" ||
              _body.moduleComputerSkills[0]?.title != "" ||
              _body.moduleCourse[0]?.title != "" ||
              _body.moduleLanguage[0]?.title != "" ||
              _body.moduleHealth[0]?.title != "" ||
              _body.moduleEmergency[0]?.title != "" ||
              _body.moduleAssociations[0]?.title != "" ||
              _body.moduleSample[0]?.title != "" ||
              _body.moduleReferences[0]?.title != ""
            ) {
              planObjects.shift()
            }
            if (_body.moduleSample[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleSample)
            }
            if (_body.moduleObjects[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleObjects)
            }
            if (_body.moduleContact[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleContact)
            }
            if (_body.moduleExperience[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleExperience)
            }
            if (_body.moduleEducation[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleEducation)
            }
            if (_body.moduleComputerSkills[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleComputerSkills)
            }
            if (_body.moduleCourse[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleCourse)
            }
            if (_body.moduleLanguage[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleLanguage)
            }
            if (_body.moduleHealth[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleHealth)
            }
            if (_body.moduleEmergency[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleEmergency)
            }
            if (_body.moduleAssociations[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleAssociations)
            }
            if (_body.moduleReferences[0]?.title != "") {
              planObjects.push.apply(planObjects, _body.moduleReferences)
            }

            togglePreparedModules()
            toggleInputs()
          }
        }}
        toggle={() => {
          togglePreparedModules()
        }}
        modalToggle={props.modal}
        cancel={e => {
          if (e == true) {
            togglePreparedModules()
          }
        }}
      />
    </React.Fragment>
  )
}

applicationPlanModal.propTypes = {
  modal: PropTypes.bool,
  cancel: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,
  save: PropTypes.func,
  modalToggle: PropTypes.bool,
  isEdit: PropTypes.any,
  selectedPlan: PropTypes.any,
  selectedBranchId: PropTypes.any,
  selectedWorkType: PropTypes.any,
  selectedDutyId: PropTypes.any,
  selectedDutiesFormId: PropTypes.any,
  selectedPosition: PropTypes.any,
  planObjects: PropTypes.any,
  payment: PropTypes.func,
  version: PropTypes.string,
  foreignLang: PropTypes.any,
  sectorForm: PropTypes.any,
  dutyForm: PropTypes.any,
  program: PropTypes.any,
  comeFrom: PropTypes.any,
}

export default withRouter(withTranslation()(applicationPlanModal))
