import React from "react"

import PropTypes from 'prop-types'
import { Container, Row, Col ,Card,CardBody} from "reactstrap" 

 
const CashCard = props => {
  return ( 
    <React.Fragment>
       <Card style={{borderRadius:"15px",border:props.border,cursor:"pointer",backgroundColor:props.backgroundColor,boxShadow:props.Shadow}}  >
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-archive-in" />
                      </span>
                     
                    </div>
                    <Col>
                    <h5 className="font-size-14 mb-0">
                    {props.title}
                   
                    </h5>
                    </Col>
                    <Col>
                    <b style={{float:"right"}} className="text-end">{props.price}</b></Col>
                   
                  </div>
                
                </CardBody>
              </Card>
    </React.Fragment>
  )
} 


CashCard.propTypes = {
  props: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.number,
  border:PropTypes.any,
  backgroundColor:PropTypes.any,
  boxShadow:PropTypes.any
 
}


export default CashCard;
