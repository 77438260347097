import {
    GET_AVM_INFOS,
    GET_AVM_INFOS_SUCCESS,
    GET_AVM_INFOS_FAIL,
    GET_AVM_INFO_DETAIL,
    GET_AVM_INFO_DETAIL_SUCCESS,
    GET_AVM_INFO_DETAIL_FAIL,
    ADD_AVM_INFO,
    ADD_AVM_INFO_SUCCESS,
    ADD_AVM_INFO_FAIL,
    UPDATE_AVM_INFO,
    UPDATE_AVM_INFO_SUCCESS,
    UPDATE_AVM_INFO_FAIL,
    DELETE_AVM_INFO,
    DELETE_AVM_INFO_SUCCESS,
    DELETE_AVM_INFO_FAIL,
    RESET_AVM_INFO,
    UPDATE_AVM_INFO_ACTIVE_SUCCESS,
    UPDATE_AVM_INFO_ACTIVE_FAIL,
    GET_AVM_INFOS_FOR_QR_SUCCESS,
    GET_AVM_INFOS_FOR_QR_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    isActive:[]
   
};

const Avm_Infos = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AVM_INFOS_FOR_QR_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_AVM_INFOS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_AVM_INFO_SUCCESS:
            return {
                ...state,
                data: state.data.filter(avm_Info => avm_Info._id.toString() !== action.payload.data.toString())
            }
        case ADD_AVM_INFO_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_AVM_INFO_SUCCESS:
            return {
                ...state,
                data: state.data.map(avm_Info =>
                    avm_Info._id.toString() === action.payload.data._id.toString() ?
                        { avm_Info, ...action.payload.data } :
                        avm_Info
                )
            }
            case UPDATE_AVM_INFO_ACTIVE_SUCCESS:
                return {
                    ...state,
                    isActive: state.data.map(avm_Info =>
                        avm_Info._id.toString() === action.payload.data._id.toString() ?
                            { avm_Info, ...action.payload.data } :
                            avm_Info
                    )
                }

        //FAILS
        case GET_AVM_INFOS_FAIL:
        case DELETE_AVM_INFO_FAIL:
        case ADD_AVM_INFO_FAIL:
        case UPDATE_AVM_INFO_FAIL:
        case UPDATE_AVM_INFO_ACTIVE_FAIL:
        case GET_AVM_INFOS_FOR_QR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_AVM_INFO: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Avm_Infos;