/** Get ORIENTATION_PERIOD */
export const GET_ORIENTATION_PERIODS = "GET_ORIENTATION_PERIODS";
export const GET_ORIENTATION_PERIODS_SUCCESS = "GET_ORIENTATION_PERIODS_SUCCESS";
export const GET_ORIENTATION_PERIODS_FAIL = "GET_ORIENTATION_PERIODS_FAIL";

export const GET_ORIENTATION_PERIOD_DETAIL = "GET_ORIENTATION_PERIOD_DETAIL";
export const GET_ORIENTATION_PERIOD_DETAIL_SUCCESS = "GET_ORIENTATION_PERIOD_DETAIL_SUCCESS";
export const GET_ORIENTATION_PERIOD_DETAIL_FAIL = "GET_ORIENTATION_PERIOD_DETAIL_FAIL";


export const ADD_ORIENTATION_PERIOD = "ADD_ORIENTATION_PERIOD";
export const ADD_ORIENTATION_PERIOD_SUCCESS = "ADD_ORIENTATION_PERIOD_SUCCESS";
export const ADD_ORIENTATION_PERIOD_FAIL = "ADD_ORIENTATION_PERIOD_FAIL";

export const UPDATE_ORIENTATION_PERIOD = "UPDATE_ORIENTATION_PERIOD";
export const UPDATE_ORIENTATION_PERIOD_SUCCESS = "UPDATE_ORIENTATION_PERIOD_SUCCESS";
export const UPDATE_ORIENTATION_PERIOD_FAIL = "UPDATE_ORIENTATION_PERIOD_FAIL";

export const DELETE_ORIENTATION_PERIOD = "DELETE_ORIENTATION_PERIOD";
export const DELETE_ORIENTATION_PERIOD_SUCCESS = "DELETE_ORIENTATION_PERIOD_SUCCESS";
export const DELETE_ORIENTATION_PERIOD_FAIL = "DELETE_ORIENTATION_PERIOD_FAIL"; 



export const RESET_ORIENTATION_PERIOD= "RESET_ORIENTATION_PERIOD";