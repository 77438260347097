import {
    GET_SECTOR_FORMS,
    GET_SECTOR_FORMS_SUCCESS,
    GET_SECTOR_FORMS_FAIL,
    GET_SECTOR_FORM_DETAIL,
    GET_SECTOR_FORM_DETAIL_SUCCESS,
    GET_SECTOR_FORM_DETAIL_FAIL,
    ADD_SECTOR_FORM,
    ADD_SECTOR_FORM_SUCCESS,
    ADD_SECTOR_FORM_FAIL,
    UPDATE_SECTOR_FORM,
    UPDATE_SECTOR_FORM_SUCCESS,
    UPDATE_SECTOR_FORM_FAIL,
    DELETE_SECTOR_FORM,
    DELETE_SECTOR_FORM_SUCCESS,
    DELETE_SECTOR_FORM_FAIL,
    RESET_SECTOR_FORM,
   
} from "./actionTypes";


export const resetSectorForm = () => ({
    type: RESET_SECTOR_FORM,
});

export const getSectorForms = () => ({
    type: GET_SECTOR_FORMS,
});

export const getSectorFormsSuccess = (sectorForm) => ({
    type: GET_SECTOR_FORMS_SUCCESS,
    payload: sectorForm,
});


export const getSectorFormsFail = (error) => ({
    type: GET_SECTOR_FORMS_FAIL,
    payload: error,
});

export const deleteSectorForm = (id) => ({
    type:  DELETE_SECTOR_FORM,
    payload: id
});

export const deleteSectorFormSuccess = (data) => ({
    type: DELETE_SECTOR_FORM_SUCCESS,
    payload: data
});

export const deleteSectorFormFail = (error) => ({
    type: DELETE_SECTOR_FORM_FAIL,
    payload: error,
});


export const addSectorForm = (sectorForm) => ({
    type: ADD_SECTOR_FORM,
    payload: sectorForm
});

export const addSectorFormSuccess = (data) => ({
    type: ADD_SECTOR_FORM_SUCCESS,
    payload: data
});

export const addSectorFormFail = (error) => ({
    type: ADD_SECTOR_FORM_FAIL,
    payload: error,
});


export const updateSectorForm = (sectorForm) => ({
    type: UPDATE_SECTOR_FORM,
    payload: sectorForm
});

export const updateSectorFormSuccess = (data) => ({
    type: UPDATE_SECTOR_FORM_SUCCESS,
    payload: data
});

export const updateSectorFormFail = (error) => ({
    type: UPDATE_SECTOR_FORM_FAIL,
    payload: error,
});