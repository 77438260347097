import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PLANS, DELETE_PLAN, ADD_PLAN, UPDATE_PLAN,GET_PLANS_MY_PLANS,ADD_PLANS_MY_PLANS,DELETE_PLANS_MY_PLANS
    ,UPDATE_PLANS_MY_PLANS,GET_PLAN_DETAIL,UPDATE_PLAN_ACTIVE,GET_USER_BY_MAIL,GET_PLANS_BY_BRANCH,GET_BRANCH_PLAN,GET_PLANS_DOWN} from "./actionTypes";
import {
    getPlansSuccess, getPlansFail,
    deletePlanSuccess, deletePlanFail,
    updatePlanSuccess, updatePlanFail,
    addPlanSuccess, addPlanFail,
    getPlans_MyPlansSuccess,getPlans_MyPlansFail,
    addPlans_MyPlansSuccess,addPlans_MyPlansFail,
    deletePlans_MyPlansSuccess,deletePlans_MyPlansFail,
    updatePlans_MyPlansSuccess,updatePlans_MyPlansFail,
    getPlansDetailSuccess,getPlansDetailFail,
    getUserByMailSuccess,getUserByMailFail,
    getPlans_ByBranchSuccess,getPlans_ByBranchFail,
    getBranch_byPlanSuccess,getBranch_byPlanFail,
    getPlansDownSuccess,getPlansDownFail,
    updatePlanActiveSuccess,updatePlanActiveFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPlans, //API CONNECTION
    delPlan,
    addPlan,
    updatePlan,
    getPlans_MyPlans,
    getPlanById,
    getCompanyByEmail,
    getPlans_ByBranch,
    getBranch_ByPlan,getPlansDown,updatePlanActive
}
    from "../../helpers/fakebackend_helper";
    function* onUpdatePlanActive({payload:plan}){
        try{
            const response = yield call(updatePlanActive, plan)
            yield put(updatePlanActiveSuccess(response));
        } catch (error) {
            yield put(updatePlanActiveFail(error));
        }
    } 
    function* fetchPlanDown({ payload: branchId}) {
        try {
            const response = yield call(getPlansDown,branchId)
            yield put(getPlansDownSuccess(response));
        } catch (error) {
            yield put(getPlansDownFail(error));
        }
    }
function* fetchPlansDetail({ payload: id}) {
        try {
            const response = yield call(getPlanById,id)
            yield put(getPlansDetailSuccess(response));
        } catch (error) {
            yield put(getPlansDetailFail(error));
        }
    }
    function* getUserByMail({ payload: id}) {
        try {
            const response = yield call(getCompanyByEmail,id)
            yield put(getUserByMailSuccess(response));
        } catch (error) {
            yield put(getUserByMailFail(error));
        }
    }
    function* fetchPlans_byBranch({ payload: id}) {
        try {
            const response = yield call(getPlans_ByBranch,id)
            yield put(getPlans_ByBranchSuccess(response));
        } catch (error) {
            yield put(getPlans_ByBranchFail(error));
        }
    }
    function* fetchBranch_byPlan({ payload: id}) {
        try {
            const response = yield call(getBranch_ByPlan,id)
            yield put(getBranch_byPlanSuccess(response));
        } catch (error) {
            yield put(getBranch_byPlanFail(error));
        }
    }
function* fetchPlans_myPlans({ payload: id}) {
        try {
            const response = yield call(getPlans_MyPlans,id)
            yield put(getPlans_MyPlansSuccess(response));
        } catch (error) {
            yield put(getPlans_MyPlansFail(error));
        }
    }
function* onAddPlans_myPlans({ payload: plan }) {
        try {
          const response = yield call(addPlan, plan)
          yield put(addPlans_MyPlansSuccess(response))
        } catch (error) {
          yield put(addPlans_MyPlansFail(error))
        }
    }
function* onDeletePlans_myPlans({ payload: id }) {
        try {
          const response = yield call(delPlan, id)
          yield put(deletePlans_MyPlansSuccess(response))
        } catch (error) {
          yield put(deletePlans_MyPlansFail(error))
        }
      }
function* onUpdatePlans_myPlans({ payload: plan }) {
        try {
          const response = yield call(updatePlan, plan)
          yield put(updatePlans_MyPlansSuccess(response))
        } catch (error) {
          yield put(updatePlans_MyPlansFail(error))
        }
      }
function* fetchPlans() {
    try {
        const response = yield call(getPlans)
        yield put(getPlansSuccess(response));
    } catch (error) {
        yield put(getPlansFail(error));
    }
}

function* onDeletePlans({payload:id}){
    try{
        const response = yield call(delPlan, id)
        yield put(deletePlanSuccess(response));
    } catch (error) {
        yield put(deletePlanFail(error));
    }
}

function* onAddPlan({payload:plan}){
    try{
        const response = yield call(addPlan, plan)
        yield put(addPlanSuccess(response));
    } catch (error) {
        yield put(addPlanFail(error));
    }
}

function* onUpdatePlan({payload:plan}){
    try{
        const response = yield call(updatePlan, plan)
        yield put(updatePlanSuccess(response));
    } catch (error) {
        yield put(updatePlanFail(error));
    }
}

export function* plansSaga() {
    yield takeEvery(GET_PLANS, fetchPlans);
    yield takeEvery(DELETE_PLAN, onDeletePlans);
    yield takeEvery(ADD_PLAN, onAddPlan);
    yield takeEvery(UPDATE_PLAN, onUpdatePlan);
    yield takeEvery(GET_PLANS_MY_PLANS, fetchPlans_myPlans);
    yield takeEvery(ADD_PLANS_MY_PLANS, onAddPlans_myPlans);
    yield takeEvery(DELETE_PLANS_MY_PLANS, onDeletePlans_myPlans);
    yield takeEvery(UPDATE_PLANS_MY_PLANS, onUpdatePlans_myPlans);
    yield takeEvery(GET_PLAN_DETAIL, fetchPlansDetail);
    yield takeEvery(GET_USER_BY_MAIL, getUserByMail);
    yield takeEvery(GET_PLANS_BY_BRANCH, fetchPlans_byBranch);
    yield takeEvery(GET_BRANCH_PLAN, fetchBranch_byPlan);
    yield takeEvery(GET_PLANS_DOWN, fetchPlanDown);
    yield takeEvery(UPDATE_PLAN_ACTIVE, onUpdatePlanActive);
    
    

    
}

export default plansSaga;
