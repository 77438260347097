import {
    GET_DEV_DUTYS,
    GET_DEV_DUTYS_SUCCESS,
    GET_DEV_DUTYS_FAIL,
    GET_DEV_DUTY_DETAIL,
    GET_DEV_DUTY_DETAIL_SUCCESS,
    GET_DEV_DUTY_DETAIL_FAIL,
    ADD_DEV_DUTY,
    ADD_DEV_DUTY_SUCCESS,
    ADD_DEV_DUTY_FAIL,
    UPDATE_DEV_DUTY,
    UPDATE_DEV_DUTY_SUCCESS,
    UPDATE_DEV_DUTY_FAIL,
    DELETE_DEV_DUTY,
    DELETE_DEV_DUTY_SUCCESS,
    DELETE_DEV_DUTY_FAIL,
    RESET_DEV_DUTY,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const DevDutys = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_DEV_DUTYS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_DEV_DUTY_SUCCESS:
            return {
                ...state,
                data: state.data.filter(devDuty => devDuty._id.toString() !== action.payload.data.toString())
            }
        case ADD_DEV_DUTY_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_DEV_DUTY_SUCCESS:
            return {
                ...state,
                data: state.data.map(devDuty =>
                    devDuty._id.toString() === action.payload.data._id.toString() ?
                        { devDuty, ...action.payload.data } :
                        devDuty
                )
            }

        //FAILS
        case GET_DEV_DUTYS_FAIL:
        case DELETE_DEV_DUTY_FAIL:
        case ADD_DEV_DUTY_FAIL:
        case UPDATE_DEV_DUTY_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_DEV_DUTY: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default DevDutys;