import {
    GET_APP_FORMS,
    GET_APP_FORMS_SUCCESS,
    GET_APP_FORMS_FAIL,
    GET_APP_FORM_DETAIL,
    GET_APP_FORM_DETAIL_SUCCESS,
    GET_APP_FORM_DETAIL_FAIL,
    ADD_APP_FORM,
    ADD_APP_FORM_SUCCESS, 
    ADD_APP_FORM_FAIL,
    UPDATE_APP_FORM,
    UPDATE_APP_FORM_SUCCESS,
    UPDATE_APP_FORM_FAIL,
    STATUS_UPDATE_APP_FORM,
    STATUS_UPDATE_APP_FORM_SUCCESS, 
    STATUS_UPDATE_APP_FORM_FAIL,
    REJECT_APP_FORM,
    REJECT_APP_FORM_SUCCESS,
    REJECT_APP_FORM_FAIL,
    FIRED_APP_FORM,
    FIRED_APP_FORM_SUCCESS,
    FIRED_APP_FORM_FAIL,
    ACCEPT_APP_FORM, 
    ACCEPT_APP_FORM_SUCCESS,
    ACCEPT_APP_FORM_FAIL,
    DESC_UPDATE_APP_FORM, 
    DESC_UPDATE_APP_FORM_SUCCESS,
    DESC_UPDATE_APP_FORM_FAIL,
    DELETE_APP_FORM,
    DELETE_APP_FORM_SUCCESS,
    DELETE_APP_FORM_FAIL,
    RESET_APP_FORM,
    GET_MY_FORMS,
    GET_MY_FORMS_SUCCESS,
    GET_MY_FORMS_FAIL,
    GET_MY_FORMS_STATUS_FALSE,
    GET_MY_FORMS_STATUS_FALSE_SUCCESS,
    GET_MY_FORMS_STATUS_FALSE_FAIL,
    GET_MY_FORMS_STATUS_TRUE,
    GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    GET_MY_FORMS_STATUS_TRUE_FAIL,
    UPDATE_MY_FORMS,
    UPDATE_MY_FORMS_SUCCESS, 
    UPDATE_MY_FORMS_FAIL,
    UPDATE_MY_FORMS_STATUS_FALSE,
    UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_FALSE_FAIL,
    UPDATE_MY_FORMS_STATUS_TRUE,
    UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    UPDATE_MY_FORMS_STATUS_TRUE_FAIL,
    DELETE_MY_FORMS,
    DELETE_MY_FORMS_SUCCESS, 
    DELETE_MY_FORMS_FAIL,
    DELETE_MY_FORM_STATUS_TRUE,
    DELETE_MY_FORM_STATUS_TRUE_SUCCESS,
    DELETE_MY_FORM_STATUS_TRUE_FAIL,
    DELETE_MY_FORM_STATUS_FALSE,
    DELETE_MY_FORM_STATUS_FALSE_SUCCESS,
    DELETE_MY_FORM_STATUS_FALSE_FAIL,
    GET_FORMS_DOWN,
    GET_FORMS_DOWN_SUCCESS, 
    GET_FORMS_DOWN_FAIL 
} from "./actionTypes"; 

export const resetAppForm = () => ({
    type: RESET_APP_FORM,
});
export const getFormsDown = (branchId) => ({
    type: GET_FORMS_DOWN,  
    payload:branchId 
}); 
export const getFormsDownSuccess = (data) => ({
    type: GET_FORMS_DOWN_SUCCESS,
    payload: data
}); 
export const getFormsDownFail = (error) => ({
    type: GET_FORMS_DOWN_FAIL, 
    payload: error,
});
//MyForms
export const getMyForms = (id) => ({
    type: GET_MY_FORMS,  
    payload:id
}); 
export const getMyFormsSuccess = (data) => ({
    type: GET_MY_FORMS_SUCCESS,
    payload: data
}); 
export const getMyFormsFail = (error) => ({
    type: GET_MY_FORMS_FAIL, 
    payload: error,
});
export const getMyFormsStatusFalse = (id) => ({
    type: GET_MY_FORMS_STATUS_FALSE,  
    payload:id
}); 
export const getMyFormsStatusFalseSuccess = (data) => ({
    type: GET_MY_FORMS_STATUS_FALSE_SUCCESS, 
    payload: data
});
export const getMyFormsStatusFalseFail = (error) => ({
    type: GET_MY_FORMS_STATUS_FALSE_FAIL, 
    payload: error, 
});
export const getMyFormsStatusTrue = (id) => ({
    type: GET_MY_FORMS_STATUS_TRUE,  
    payload:id
}); 
export const getMyFormsStatusTrueSuccess = (data) => ({
    type: GET_MY_FORMS_STATUS_TRUE_SUCCESS,
    payload: data
});
export const getMyFormsStatusTrueFail = (error) => ({
    type: GET_MY_FORMS_STATUS_TRUE_FAIL, 
    payload: error, 
});
export const updateMyForm = (form) => ({
    type: UPDATE_MY_FORMS,
    payload:form
});
export const updateMyFormSuccess = (data) => ({
    type: UPDATE_MY_FORMS_SUCCESS,
    payload: data
});
export const updateMyFormFail = (error) => ({
    type: UPDATE_MY_FORMS_FAIL,
    payload: error,
});
export const updateMyFormStatusFalse = (form) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE,
    payload:form
});
export const updateMyFormStatusFalseSuccess = (data) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE_SUCCESS,
    payload: data
});
export const updateMyFormStatusFalseFail = (error) => ({
    type: UPDATE_MY_FORMS_STATUS_FALSE_FAIL, 
    payload: error,
});
export const updateMyFormStatusTrue = (form) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE,
    payload:form
});
export const updateMyFormStatusTrueSuccess = (data) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE_SUCCESS,
    payload: data
});
export const updateMyFormStatusTrueFail = (error) => ({
    type: UPDATE_MY_FORMS_STATUS_TRUE_FAIL, 
    payload: error,
});
export const deleteMyForm = (id) => ({ 
    type: DELETE_MY_FORMS,
    payload:id
});
export const deleteMyFormSuccess = (data) => ({ 
    type: DELETE_MY_FORMS_SUCCESS, 
    payload: data
});
export const deleteMyFormFail = (error) => ({
    type: DELETE_MY_FORMS_FAIL,
    payload: error,
});
export const deleteMyFormStatusTrue = (id) => ({ 
    type: DELETE_MY_FORM_STATUS_TRUE,
    payload:id
});
export const deleteMyFormStatusTrueSuccess = (data) => ({ 
    type: DELETE_MY_FORM_STATUS_TRUE_SUCCESS, 
    payload: data
});
export const deleteMyFormStatusTrueFail = (error) => ({
    type: DELETE_MY_FORM_STATUS_TRUE_FAIL,
    payload: error,
});
export const deleteMyFormStatusFalse = (id) => ({ 
    type: DELETE_MY_FORM_STATUS_FALSE,
    payload:id
});
export const deleteMyFormStatusFalseSuccess = (data) => ({ 
    type: DELETE_MY_FORM_STATUS_FALSE_SUCCESS, 
    payload: data
});
export const deleteMyFormStatusFalseFail = (error) => ({
    type: DELETE_MY_FORM_STATUS_FALSE_FAIL,
    payload: error,
});
//
export const getAppForms = () => ({
    type: GET_APP_FORMS,
});

export const getAppFormsSuccess = (appForms) => ({
    type: GET_APP_FORMS_SUCCESS,
    payload: appForms,
});


export const getAppFormsFail = (error) => ({
    type: GET_APP_FORMS_FAIL,
    payload: error,
});

export const deleteAppForm = (id) => ({
    type:  DELETE_APP_FORM,
    payload: id
});

export const deleteAppFormSuccess = (data) => ({
    type: DELETE_APP_FORM_SUCCESS,
    payload: data
});

export const deleteAppFormFail = (error) => ({
    type: DELETE_APP_FORM_FAIL,
    payload: error,
});


export const addAppForm = (appForm,files) => ({
    type: ADD_APP_FORM,
    payload: appForm,
    files: files
});

export const addAppFormSuccess = (data) => ({
    type: ADD_APP_FORM_SUCCESS,
    payload: data
});

export const addAppFormFail = (error) => ({
    type: ADD_APP_FORM_FAIL,
    payload: error,
});


export const updateAppForm = (appForm) => ({
    type: UPDATE_APP_FORM,
    payload: appForm
});

export const updateAppFormSuccess = (data) => ({
    type: UPDATE_APP_FORM_SUCCESS,
    payload: data
});

export const updateAppFormFail = (error) => ({
    type: UPDATE_APP_FORM_FAIL,
    payload: error,
});
export const statusUpdateAppForm = (appForm) => ({
    type: STATUS_UPDATE_APP_FORM,
    payload: appForm
});

export const statusUpdateAppFormSuccess = (data) => ({
    type: STATUS_UPDATE_APP_FORM_SUCCESS,
    payload: data
});

export const statusUpdateAppFormFail = (error) => ({
    type: STATUS_UPDATE_APP_FORM_FAIL,
    payload: error, 
});
export const firedAppForm = (appForm) => ({
    type: FIRED_APP_FORM,
    payload: appForm
}); 

export const firedAppFormSuccess = (data) => ({
    type: FIRED_APP_FORM_SUCCESS,
    payload: data
});

export const firedAppFormFail = (error) => ({
    type: FIRED_APP_FORM_FAIL,
    payload: error, 
});
export const rejectAppForm = (appForm) => ({
    type: REJECT_APP_FORM,
    payload: appForm
});

export const rejectAppFormSuccess = (data) => ({
    type: REJECT_APP_FORM_SUCCESS,
    payload: data
});

export const rejectAppFormFail = (error) => ({
    type: REJECT_APP_FORM_FAIL,
    payload: error, 
});
export const acceptAppForm = (appForm) => ({
    type: ACCEPT_APP_FORM,
    payload: appForm
});

export const acceptAppFormSuccess = (data) => ({
    type: ACCEPT_APP_FORM_SUCCESS,
    payload: data
}); 

export const acceptAppFormFail = (error) => ({
    type: ACCEPT_APP_FORM_FAIL,
    payload: error, 
});
export const descUpdateAppForm = (appForm) => ({
    type: DESC_UPDATE_APP_FORM,
    payload: appForm
});

export const descUpdateAppFormSuccess = (data) => ({
    type:  DESC_UPDATE_APP_FORM_SUCCESS,
    payload: data
});

export const descUpdateAppFormFail = (error) => ({
    type:  DESC_UPDATE_APP_FORM_FAIL,
    payload: error, 
});