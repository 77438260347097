/** Get SECTOR */
export const GET_SECTORS = "GET_SECTORS";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_FAIL = "GET_SECTORS_FAIL";

export const GET_SECTOR_DETAIL = "GET_SECTOR_DETAIL";
export const GET_SECTOR_DETAIL_SUCCESS = "GET_SECTOR_DETAIL_SUCCESS";
export const GET_SECTOR_DETAIL_FAIL = "GET_SECTOR_DETAIL_FAIL";


export const ADD_SECTOR = "ADD_SECTOR";
export const ADD_SECTOR_SUCCESS = "ADD_SECTOR_SUCCESS";
export const ADD_SECTOR_FAIL = "ADD_SECTOR_FAIL";

export const UPDATE_SECTOR = "UPDATE_SECTOR";
export const UPDATE_SECTOR_SUCCESS = "UPDATE_SECTOR_SUCCESS";
export const UPDATE_SECTOR_FAIL = "UPDATE_SECTOR_FAIL";

export const DELETE_SECTOR = "DELETE_SECTOR";
export const DELETE_SECTOR_SUCCESS = "DELETE_SECTOR_SUCCESS";
export const DELETE_SECTOR_FAIL = "DELETE_SECTOR_FAIL"; 



export const RESET_SECTOR= "RESET_SECTOR";