/** Get DEV_PROGRAM */
export const GET_DEV_PROGRAMS = "GET_DEV_PROGRAMS";
export const GET_DEV_PROGRAMS_SUCCESS = "GET_DEV_PROGRAMS_SUCCESS";
export const GET_DEV_PROGRAMS_FAIL = "GET_DEV_PROGRAMS_FAIL";

export const GET_DEV_PROGRAM_DETAIL = "GET_DEV_PROGRAM_DETAIL";
export const GET_DEV_PROGRAM_DETAIL_SUCCESS = "GET_DEV_PROGRAM_DETAIL_SUCCESS";
export const GET_DEV_PROGRAM_DETAIL_FAIL = "GET_DEV_PROGRAM_DETAIL_FAIL";


export const ADD_DEV_PROGRAM = "ADD_DEV_PROGRAM";
export const ADD_DEV_PROGRAM_SUCCESS = "ADD_DEV_PROGRAM_SUCCESS";
export const ADD_DEV_PROGRAM_FAIL = "ADD_DEV_PROGRAM_FAIL";

export const UPDATE_DEV_PROGRAM = "UPDATE_DEV_PROGRAM";
export const UPDATE_DEV_PROGRAM_SUCCESS = "UPDATE_DEV_PROGRAM_SUCCESS";
export const UPDATE_DEV_PROGRAM_FAIL = "UPDATE_DEV_PROGRAM_FAIL";

export const DELETE_DEV_PROGRAM = "DELETE_DEV_PROGRAM";
export const DELETE_DEV_PROGRAM_SUCCESS = "DELETE_DEV_PROGRAM_SUCCESS";
export const DELETE_DEV_PROGRAM_FAIL = "DELETE_DEV_PROGRAM_FAIL"; 



export const RESET_DEV_PROGRAM= "RESET_DEV_PROGRAM";