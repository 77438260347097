import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"

import Breadcrumb from "../../components/Common/Breadcrumb"

import imgFinished from "./../../assets/images/finished_logo.png"
import footerIcon from "./../../assets/images/appforms/buqrda_is_var_0.png"
import { defaultsDeep, isEmpty, map } from "lodash"

import "./datatables.scss"

const PlanFinished = props => {
  const dispatch = useDispatch()
  const [modalInputs, setModalInputs] = useState(false)
  const [quertSTR, setQuerySTR] = useState([])

  const {
    match: { params },
  } = props

  useEffect(() => {
    //console.log(props)
    let tagArr = []
    if (!isEmpty(location.search)) {
      let tagArrSplit = location.search.replace("?url=", "");
      // let tagArrSplit = location.search.replace("?", "").split("&")
      // for (let tIndex in tagArrSplit) {
      //   let tagKeyVal = tagArrSplit[tIndex].split("=")
      //   if (tagKeyVal.length == 2) {
      //     let istag = {}
      //     istag[tagKeyVal[0]] = tagKeyVal[1]
      //     tagArr.push(istag)
      //   }
      // }
      //setQuerySTR(tagArr)
      setQuerySTR(tagArrSplit)
    }
  }, [dispatch])

  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    try { 
      const shareLink = `${process.env.REACT_APP_DOMAIN}/ik/${params.id}?share=friend`

      console.log("Congrats! Your browser supports Web Share API", shareLink)
      await navigator
        .share({
          title: "Bu QR'da iş var",
          text: "Bu QR'da iş var",
          url: shareLink,
        })
        .then(() => {
          console.log("Sharing successfull")
        })
        .catch(() => {
          console.log("Sharing failed")
        })
    } catch (err) {
      console.log("Sorry! Your browser does not support Web Share API", err)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("APPNAME")}</title>
        </MetaTags>
        <Container fluid>
          <Card className="p-4">
            <CardBody className="text-success text-center">
              {/* <h2>{props.t("SuccessIK_Title")}</h2> */}
              <img
                src={imgFinished}
                style={{ height: 100, width: "100%", objectFit: "contain" }}
              />
              <h5 className="mt-5 mb-5">{props.t("SuccessSurvey_Message")}</h5>

             
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PlanFinished))
