import {
    GET_FOREIGN_LANGUAGES,
    GET_FOREIGN_LANGUAGES_SUCCESS,
    GET_FOREIGN_LANGUAGES_FAIL,
    GET_FOREIGN_LANGUAGE_DETAIL,
    GET_FOREIGN_LANGUAGE_DETAIL_SUCCESS,
    GET_FOREIGN_LANGUAGE_DETAIL_FAIL,
    ADD_FOREIGN_LANGUAGE,
    ADD_FOREIGN_LANGUAGE_SUCCESS,
    ADD_FOREIGN_LANGUAGE_FAIL,
    UPDATE_FOREIGN_LANGUAGE,
    UPDATE_FOREIGN_LANGUAGE_SUCCESS,
    UPDATE_FOREIGN_LANGUAGE_FAIL,
    DELETE_FOREIGN_LANGUAGE,
    DELETE_FOREIGN_LANGUAGE_SUCCESS,
    DELETE_FOREIGN_LANGUAGE_FAIL,
    RESET_FOREIGN_LANGUAGE,

} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
   
};

const ForeignLanguages = (state = INIT_STATE, action) => {
    switch (action.type) {
     
        case GET_FOREIGN_LANGUAGES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(foreignLanguage => foreignLanguage._id.toString() !== action.payload.data.toString())
            }
        case ADD_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_FOREIGN_LANGUAGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(foreignLanguage =>
                    foreignLanguage._id.toString() === action.payload.data._id.toString() ?
                        { foreignLanguage, ...action.payload.data } :
                        foreignLanguage
                )
            }

        //FAILS
        case GET_FOREIGN_LANGUAGES_FAIL:
        case DELETE_FOREIGN_LANGUAGE_FAIL:
        case ADD_FOREIGN_LANGUAGE_FAIL:
        case UPDATE_FOREIGN_LANGUAGE_FAIL:
     
            return {
                ...state,
                error: action.payload
            }

        case RESET_FOREIGN_LANGUAGE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default ForeignLanguages;