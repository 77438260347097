import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_REQUEST_REASONS, DELETE_REQUEST_REASON, ADD_REQUEST_REASON, UPDATE_REQUEST_REASON,GET_REQUEST_REASONS_MY_REQUEST_REASONS} from "./actionTypes";
import {
    getRequestReasonsSuccess, getRequestReasonsFail,
    deleteRequestReasonSuccess, deleteRequestReasonFail,
    updateRequestReasonSuccess, updateRequestReasonFail,
    addRequestReasonSuccess, addRequestReasonFail,
   
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getRequestReasons, //API CONNECTION
    delRequestReason,
    addRequestReason,
    updateRequestReason,
    
}
    from "../../helpers/fakebackend_helper"; 


function* fetchRequestReasons() {
    try {
        const response = yield call(getRequestReasons)
        yield put(getRequestReasonsSuccess(response));
    } catch (error) {
        yield put(getRequestReasonsFail(error));
    }
}

function* onDeleteRequestReasons({payload:id}){
    try{
        const response = yield call(delRequestReason, id)
        yield put(deleteRequestReasonSuccess(response));
    } catch (error) {
        yield put(deleteRequestReasonFail(error));
    }
}

function* onAddRequestReason({payload:requestReason}){
    try{
        const response = yield call(addRequestReason, requestReason)
        yield put(addRequestReasonSuccess(response));
    } catch (error) {
        yield put(addRequestReasonFail(error));
    }
}

function* onUpdateRequestReason({payload:requestReason}){
    try{
        const response = yield call(updateRequestReason, requestReason)
        yield put(updateRequestReasonSuccess(response));
    } catch (error) {
        yield put(updateRequestReasonFail(error));
    }
}

export function* requestReasonsSaga() {
    yield takeEvery(GET_REQUEST_REASONS, fetchRequestReasons);
    yield takeEvery(DELETE_REQUEST_REASON, onDeleteRequestReasons);
    yield takeEvery(ADD_REQUEST_REASON, onAddRequestReason);
    yield takeEvery(UPDATE_REQUEST_REASON, onUpdateRequestReason);
   
    
}

export default requestReasonsSaga;
